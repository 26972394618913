import { Vector2d } from "MarkupTypes";
import { useEffect, useState } from "react";
import { rectangleToPoly } from "../../hooks/annotationHelper";
import { MyRectangle } from "../../hooks/types";
import NewPolygon from "./lowlevel/NewPoly";
import DragDot from "./lowlevel/DragDot";
import { addVector, getPolygonConfig } from "./lowlevel/util";

type Props = {
  c: MyRectangle;
  selectedMarkupLabelId?: string | null;
  labelColor: string;
  temporary?: boolean;
  select?: (id: string) => void;
  isSelected?: boolean;
  isHighlighted: boolean;
  scale: number;
  update: (c: MyRectangle) => void;
};

export default function Rectangle({
  c,
  selectedMarkupLabelId = null,
  labelColor,
  temporary = false,
  select,
  isSelected = false,
  isHighlighted,
  update,
  scale,
}: Props) {
  const [topLeft, setTopLeft] = useState<Vector2d>({ x: 0, y: 0 });
  const [bottomRight, setBottomRight] = useState<Vector2d>({ x: 0, y: 0 });

  useEffect(() => {
    setTopLeft(c.topLeft);
  }, [c.topLeft, c.id]);

  useEffect(() => {
    setBottomRight(c.bottomRight);
  }, [c.bottomRight, c.id]);

  const getConfig = (isMouseOver: boolean) =>
    getPolygonConfig(
      c.annotationType,
      "Rectangle",
      isMouseOver,
      isSelected,
      isHighlighted,
      temporary,
      selectedMarkupLabelId === c.markupLabelId,
      labelColor,
      scale
    );

  const temporaryRectangle = { ...c, topLeft, bottomRight };

  const points = rectangleToPoly(temporaryRectangle);

  const movePolygon = (dist: Vector2d) =>
    update({
      ...c,
      topLeft: addVector(topLeft, dist),
      bottomRight: addVector(bottomRight, dist),
    });

  const endDrag = () => update(temporaryRectangle);

  const selectHandler = () => {
    if (select) {
      select(c.id);
    }
  };

  return (
    <>
      <NewPolygon
        points={points}
        getConfig={getConfig}
        select={selectHandler}
        isSelected={isSelected || isHighlighted}
        movePolygon={movePolygon}
      />
      {isSelected && (
        <>
          <DragDot
            scale={scale}
            position={topLeft}
            dragTo={setTopLeft}
            dragEnd={endDrag}
          />
          <DragDot
            scale={scale}
            position={bottomRight}
            dragTo={setBottomRight}
            dragEnd={endDrag}
          />
        </>
      )}
    </>
  );
}

Rectangle.defaultProps = {
  temporary: false,
  select: undefined,
  isSelected: false,
  selectedMarkupLabelId: null,
};
