import { useContext } from "react";
import { ResearchBoxContext } from "../../../../ctx/ResearchBoxProvider";
import { formatDate } from "../../../../utils/helpers";

export default function useImageInfo(currentNetworkId: string) {
  const { researchbox } = useContext(ResearchBoxContext);

  const [created, updated] = [
    researchbox?.createdAt,
    researchbox?.updatedAt,
  ].map(formatDate);

  const hasMarkup =
    researchbox?.containsMarkupForNetworkId.includes(currentNetworkId) ?? false;

  return { created, updated, hasMarkup, name: researchbox?.name ?? "" };
}

export type ImageInfo = ReturnType<typeof useImageInfo>;
