import {
  useAddPipelineModuleEdgeMutation,
  useDeletePipelineModuleEdgeMutation,
} from "../ts-clients/command";

type Props = {
  pipelineModuleEdgeId?: string; // Existiert die Kante bereits? Wenn ja, welche ID hat sie?
  pipelineModuleOutputId: string; // Von welchem Output soll die Kante starten?
  pipelineModuleInputId: string; // Zu welchem Input soll die Kante gehen?
};

function useUpdatePipelineModuleEdge() {
  const [del] = useDeletePipelineModuleEdgeMutation();
  const [add] = useAddPipelineModuleEdgeMutation();

  return ({
    pipelineModuleInputId,
    pipelineModuleOutputId,
    pipelineModuleEdgeId,
  }: Props) => {
    del({
      variables: {
        input: {
          ids: [pipelineModuleEdgeId || ""],
        },
      },
    });
    add({
      variables: {
        input: {
          pipelineModuleOutputId,
          pipelineModuleInputId,
        },
      },
    });
  };
}

export default function useAddPipelineModuleEdge() {
  const [addPipelineModuleEdge] = useAddPipelineModuleEdgeMutation();
  const updatePipelineModuleEdge = useUpdatePipelineModuleEdge();
  return ({
    pipelineModuleOutputId,
    pipelineModuleInputId,
    pipelineModuleEdgeId,
  }: Props) => {
    if (pipelineModuleEdgeId) {
      updatePipelineModuleEdge({
        pipelineModuleEdgeId,
        pipelineModuleInputId,
        pipelineModuleOutputId,
      });
    } else {
      addPipelineModuleEdge({
        variables: {
          input: { pipelineModuleInputId, pipelineModuleOutputId },
        },
      });
    }
  };
}
