import { PrefillData } from "FormTypes";
import { useEffect } from "react";
import useHook from "../../../hooks/moduleCreators-new-2021-04/PMContrastEnhancementCreate";
import ModuleForm from "./forms/FormPipelineModuleContrastEnhancement";

type Props = {
  datasetId: string | null;
  onCancel: () => unknown;
  prefillData: PrefillData | null;
};

export default function PMContrastEnhancementCreate({
  datasetId,
  onCancel,
  prefillData,
}: Props) {
  const { create, moduleData, setModuleData, setBaseParams } = useHook();

  const onSubmit = () => {
    create(datasetId);
    onCancel();
  };

  useEffect(() => {
    if (prefillData !== null) {
      setBaseParams({
        ...moduleData,
        moduleDescription: prefillData.description,
        moduleName: prefillData.name,
      });
      setModuleData({
        ...moduleData,
        pretrainedNetworkModuleId: prefillData.pretrainedNetworkModuleId,
      });
    }
  }, [moduleData, prefillData, setBaseParams, setModuleData]);

  return (
    <ModuleForm
      moduleData={moduleData}
      onCancel={onCancel}
      onSave={onSubmit}
      setBaseParams={setBaseParams}
      setData={setModuleData}
    />
  );
}
