// @ts-ignore
import { LocationDescriptorObject } from "history";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getCurrentUser } from "../state/loginState";
import useSessionStorage from "../state/useSessionStorage";
import { getPath } from "../utils/routeManager";

export default function useLastLocation() {
  const defaultLocation = useMemo(
    () =>
      ({
        pathname: getPath("Dashboard", {}),
      } as LocationDescriptorObject),
    []
  );
  const history = useHistory();

  const [lastLocation, setLastLocation] = useSessionStorage(
    "lasturl",
    defaultLocation
  );

  const [lastUser, setLastUser] = useSessionStorage("lastuser", "");

  const user = useSelector(getCurrentUser);
  const currentUserName = user.username || "";

  useEffect(() => {
    if (currentUserName !== "" && lastUser !== currentUserName) {
      setLastLocation({ pathname: "/" });
      setLastUser(currentUserName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserName]);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/") {
      setLastLocation({ pathname: location.pathname, search: location.search });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, location.pathname]);

  const restoreLastLocation = () => {
    if (
      lastLocation &&
      (location.pathname !== lastLocation.pathname ||
        (lastLocation.search && location.search !== lastLocation.search))
    ) {
      history.push(lastLocation);
    } else if (lastLocation.pathname === "/") {
      history.push(defaultLocation);
    }
  };

  return restoreLastLocation;
}
