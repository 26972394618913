export type CellData = {
  Peaks: number[];
  IsOkay: boolean;
  Data: number[];
  FirstIdx: number;
};

const parseCCCheckerResultJson = (json: string): Array<CellData> => {
  return JSON.parse(json ?? "[]");
};

export { parseCCCheckerResultJson };
