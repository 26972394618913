import { useQueryNetworkDetailsForDatasetQuery } from "../ts-clients/query";
import { Network } from "../types/extractedTypes";

export default function useQueryNetworkDetailsForDataset(
  datasetId?: string
): Network[] {
  const { data } = useQueryNetworkDetailsForDatasetQuery({
    variables: {
      filter: datasetId ? { id: { eq: datasetId } } : undefined,
      networkFilter: undefined,
    },
    fetchPolicy: "cache-and-network",
    pollInterval: 5000,
  });

  const datasets = data?.queryDataset ?? [];
  const networkModules = datasets
    .flatMap((d) => d?.pipeline?.modules ?? [])
    .flatMap((m) =>
      m.__typename === "PipelineModuleNetworkImageClassification" ||
      m.__typename === "PipelineModuleNetworkImageSegmentation" ||
      m.__typename === "PipelineModuleNetworkImageObjectDetection"
        ? m
        : []
    );

  return networkModules;
}
