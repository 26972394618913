import { flatten } from "lodash";
import Plot from "react-plotly.js";
import { parseCCCheckerResultJson } from "./util";
import { CcCheckerDatapointType } from "../../../types/extractedTypes";

type Props = {
  d: CcCheckerDatapointType;
};

export default function DataPointImage({ d }: Props) {
  const cells = parseCCCheckerResultJson(d.groups);
  const graphdata = flatten(cells.map((c) => c.Data));
  const peaklist = flatten(cells.map((c, i) => c.Peaks.map((x) => x - i)));

  return (
    <Plot
      style={{ width: "100%", height: "100%" }}
      data={[
        {
          // main data
          x: graphdata.map((_, i) => i * 2.5),
          y: graphdata,
          name: "By",
          type: "scatter",
          mode: "lines",
        },
        {
          // peak data
          x: peaklist.map((x) => x * 2.5),
          y: peaklist.map((x) => graphdata[x]),
          name: "Peaks",
          type: "scatter",
          mode: "markers",
          marker: { color: "red", size: 4 },
        },
      ]}
      layout={{
        margin: {
          t: 30,
          l: 45,
          r: 30,
          b: 35,
        },
        xaxis: { title: "s [mm]" },
        yaxis: { title: "By [\u00b5T]" },
        showlegend: false,
      }}
      config={{
        showLink: false,
        editable: false,
        displayModeBar: false,
        displaylogo: false,
        // responsive: true,
      }}
    />
  );
}
