import {
  PipelineModuleContrastEnhancementProps as Props,
  BaseParams,
} from "ModuleTypes";
import { useState } from "react";
import { useAddPipelineModuleContrastEnhancementMutation } from "../../ts-clients/command";
import { pipelineModuleContrastEnhancementDefaults } from "./defaults";
import { useAddDatasetMutation } from "../../ts-clients/command";

export default function useCreatePipelineModuleCCChecker() {
  const [moduleData, setModuleData] = useState<Props>(
    pipelineModuleContrastEnhancementDefaults()
  );
  const [m] = useAddPipelineModuleContrastEnhancementMutation();
  const [addDataset] = useAddDatasetMutation();

  const create = async (datasetId: string | null) => {
    // if datasetId === null, create dataset first
    // get pipelineID from dataset

    // if datasetId === null, create dataset first
    // get pipelineID from dataset
    const save = (datasetId2: string) => {
      m({
        variables: {
          // @ts-ignore
          input: { moduleData, datasetId: datasetId2 },
        },
      });
    };

    if (datasetId === null) {
      const result = await addDataset({
        variables: {
          input: {
            name: `Dataset ${moduleData.moduleName}`,
            description: "",
          },
        },
      });

      save(result.data?.addDataset?.datasetId ?? "");
    } else {
      save(datasetId);
    }
  };

  const setBaseParams = ({ moduleDescription, moduleName }: BaseParams) => {
    setModuleData((md) => ({ ...md, moduleName, moduleDescription }));
  };

  return { create, moduleData, setModuleData, setBaseParams };
}
