import { useEffect, useState } from "react";
import { omit } from "ramda";
import {
  Header,
  Divider,
  Form,
  Button,
  Input,
  Checkbox,
  Label,
} from "semantic-ui-react";

import { useSubscribeDatapointModuleParamsSubscription } from "../../../ts-clients/query";

import {
  useUpdatePipelineModuleContrastEnhancementMutation,
  useUpdatePipelineModuleCcCheckerMutation,
} from "../../../ts-clients/command";
// useAddPipelineModuleJobRequestCcCheckerMutation,
// useAddPipelineModuleJobRequestContrastEnhancementMutation,

type Props = {
  datapointId: string;
};

type ParameterTypes = number | string | boolean;

export default function ParameterForm({ datapointId }: Props) {
  const { data: datapointModuleParams, loading } =
    useSubscribeDatapointModuleParamsSubscription({
      variables: {
        datapointId,
      },
    });

  const datapoint = (datapointModuleParams?.queryDatapoint || [undefined])[0];
  const mod = datapoint?.producedBy.module;

  const [currentParameters, setCurrentParameters] = useState<{
    [key: string]: ParameterTypes;
  }>({});

  // const [addCCCheckerJobRequest] =
  //   useAddPipelineModuleJobRequestCcCheckerMutation({
  //     variables: {
  //       input: {
  //         ...currentParameters,
  //         researchBoxIds: [datapoint?.researchBox.id || ""],
  //         pipelineModuleId: mod?.id || "",
  //       },
  //     },
  //   });

  // const [addContrastEnhancementJobRequest] =
  //   useAddPipelineModuleJobRequestContrastEnhancementMutation({
  //     variables: {
  //       input: {
  //         ...currentParameters,
  //         researchBoxIds: [datapoint?.researchBox.id || ""],
  //         pipelineModuleId: mod?.id || "",
  //       },
  //     },
  //   });

  const [updateCCCheckerParams] = useUpdatePipelineModuleCcCheckerMutation({
    variables: {
      input: {
        filter: {
          moduleId: mod?.id || "",
        },
        ...currentParameters,
      },
    },
  });

  const [updateContrastEnhancementParams] =
    useUpdatePipelineModuleContrastEnhancementMutation({
      variables: {
        input: {
          filter: {
            moduleId: mod?.id || "",
          },
          ...currentParameters,
        },
      },
    });

  const testLocal = () => {
    if (mod?.__typename === "PipelineModuleCCChecker") {
      // addCCCheckerJobRequest();
    } else if (mod?.__typename === "PipelineModuleContrastEnhancement") {
      // addContrastEnhancementJobRequest();
    }
  };

  const applyGlobal = () => {
    if (mod?.__typename === "PipelineModuleCCChecker") {
      updateCCCheckerParams();
    } else if (mod?.__typename === "PipelineModuleContrastEnhancement") {
      updateContrastEnhancementParams();
    }
  };

  useEffect(() => {
    setCurrentParameters(omit(["id", "__typename"], mod));
  }, [mod]);

  const paramCount = Object.keys(currentParameters).length;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateParameter = (key: string, value: any) => {
    const previousValue = currentParameters[key];
    if (typeof previousValue === "boolean") {
      setCurrentParameters({ ...currentParameters, [key]: value as boolean });
    } else if (typeof previousValue === "string") {
      setCurrentParameters({ ...currentParameters, [key]: value as string });
    } else if (Number.isInteger(value)) {
      setCurrentParameters({
        ...currentParameters,
        [key]: parseInt(value, 10),
      });
    } else {
      setCurrentParameters({ ...currentParameters, [key]: parseFloat(value) });
    }
  };

  return paramCount > 0 ? (
    <>
      <Header as="h4">Module Parameter Settings</Header>
      <Divider />
      <Form>
        {Object.keys(currentParameters).map((key) => {
          const currentValue = currentParameters[key];

          if (typeof currentValue === "boolean") {
            return (
              <Checkbox
                key={key}
                checked={currentValue as boolean}
                label={key}
                onChange={(_, data) => updateParameter(key, data.value)}
              />
            );
          }
          return (
            <Input
              key={key}
              value={currentValue}
              type={typeof currentValue}
              onChange={(_, data) => updateParameter(key, data.value)}
            >
              <Label basic>{key}</Label>
              <input />
            </Input>
          );
        })}
        <Button
          color="green"
          content="test config here"
          fluid
          loading={loading}
          disabled={loading}
          onClick={testLocal}
        />
        <br />
        <Button
          color="red"
          disabled={loading}
          content="apply to whole dataset"
          fluid
          loading={loading}
          onClick={applyGlobal}
        />
        <br />
        {/* <Button
          disabled={typeof reset === 'undefined'}
          color="yellow"
          content="reset to default"
          fluid
          onClick={reset}
        /> */}
      </Form>
    </>
  ) : null;
}
