import ImageInfo from "./Tabs/ImageInfoTab";
import { ImageDatapointType } from "../../../../types/extractedTypes";

type Props = {
  image: ImageDatapointType;
  currentNetworkId: string;
};

export default function MarkupToolRightMenuWithoutTraining({
  image,
  currentNetworkId,
}: Props) {
  return (
    <>
      <ImageInfo image={image} currentNetworkId={currentNetworkId} />
    </>
  );
}
