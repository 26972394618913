import { useState } from "react";
import {
  Button,
  Table,
  Image,
  Popup,
  Modal,
  Grid,
  Confirm,
  Icon,
  Menu,
} from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { formatDate } from "../../../utils/helpers";
import {
  useAddPretrainedNetworkMutation,
  useDeletePipelineModuleMutation,
  useDeletePretrainedNetworkMutation,
} from "../../../ts-clients/command";

export type NetworkData = {
  type: string;
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  description: string;
  owner: string;
  thumbnail: string;
  parentId: string;
};

type Props = {
  pretrained: boolean;
  networks: NetworkData[];
  refetch: () => void;
};

function FileUploadModal(props: {
  id: string;
  name: string;
  refetch: () => void;
}) {
  const { id, name, refetch } = props;

  const [openBool, setOpenBool] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState<Array<File>>([]);

  const [addPretrainedMutation] = useAddPretrainedNetworkMutation();

  const addPretrainedNetwork = (networkId: string, thumbnail: File) => {
    setOpenBool(false);
    addPretrainedMutation({
      variables: {
        input: {
          networkId,
          thumbnail,
        },
      },
    })
      .then(refetch)
      .catch(console.log);
  };

  const onDrop = (acceptedFiles: Array<File>) => {
    setDroppedFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept:
      "image/png, image/jpeg, image/x-tiff, image/tiff, image/bmp, image/webp, application/zip",
    noClick: true,
  });

  return (
    <>
      <Button
        icon="paper plane"
        color="blue"
        fluid
        onClick={() => setOpenBool(true)}
      />
      <Modal open={openBool}>
        <Modal.Header>{`Publish Pretrained Network from ${name}`}</Modal.Header>
        <Modal.Content>
          <Grid divided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column>
                <Menu.Item name="upload" onClick={open}>
                  <div {...getRootProps()}>
                    <Icon name="upload" size="big" />
                    <input {...getInputProps()} />
                  </div>
                  Upload
                </Menu.Item>
              </Grid.Column>

              <Grid.Column>
                <h3>Please Select a Thumbnail</h3>Please select an image file
                from your file system to provide a thumbnail.
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenBool(false)} basic>
            Cancel
          </Button>
          <Popup
            hoverable
            trigger={
              <span>
                <Button
                  onClick={() => addPretrainedNetwork(id, droppedFiles[0])}
                  color="blue"
                  icon="paper plane"
                  content="Publish"
                  disabled={droppedFiles.length !== 1}
                />
              </span>
            }
            content="An image needs to be selected as the thumbnail."
          />
        </Modal.Actions>
      </Modal>
    </>
  );
}

function NetworkDeletionConfirmBox(props: {
  id: string;
  name: string;
  refetch: () => void;
}) {
  const { id, name, refetch } = props;

  const [openBool, setOpenBool] = useState(false);

  const [deleteModuleMutation] = useDeletePipelineModuleMutation();
  const closeAndDelete = () => {
    setOpenBool(false);
    deleteModuleMutation({
      variables: {
        input: {
          ids: [id],
        },
      },
    })
      .then(refetch)
      .catch(console.log);
  };

  return (
    <>
      <Button
        icon="delete"
        color="red"
        onClick={() => setOpenBool(true)}
        fluid
      />
      <Confirm
        header={`Delete Network ${name}?`}
        content="Are you sure you want to delete this network?"
        cancelButton="Cancel"
        confirmButton="Delete Network"
        open={openBool}
        onCancel={() => setOpenBool(false)}
        onConfirm={closeAndDelete}
      />
    </>
  );
}

function PretrainedDeletionConfirmBox(props: {
  id: string;
  name: string;
  refetch: () => void;
}) {
  const { id, name, refetch } = props;

  const [openBool, setOpenBool] = useState(false);

  const [deleteModuleMutation] = useDeletePretrainedNetworkMutation();
  const closeAndDelete = () => {
    setOpenBool(false);
    deleteModuleMutation({
      variables: {
        input: {
          ids: [id],
        },
      },
    })
      .then(refetch)
      .catch(console.log);
  };

  return (
    <>
      <Button
        icon="minus"
        color="red"
        onClick={() => setOpenBool(true)}
        fluid
      />
      <Confirm
        header={`Delete Pretrained Network of ${name}?`}
        content="Are you sure you want to delete this pretrained network? The parent network will not be deleted."
        cancelButton="Cancel"
        confirmButton="Delete Pretrained Network"
        open={openBool}
        onCancel={() => setOpenBool(false)}
        onConfirm={closeAndDelete}
      />
    </>
  );
}

export function NetworkList({ networks, pretrained, refetch }: Props) {
  return (
    <>
      <Table compact celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Owner</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Last Changed</Table.HeaderCell>
            {pretrained && (
              <Table.HeaderCell textAlign="center">Thumbnail</Table.HeaderCell>
            )}
            {!pretrained && (
              <Table.HeaderCell textAlign="center">
                Publish Pretrained
              </Table.HeaderCell>
            )}
            <Table.HeaderCell textAlign="center">
              {pretrained ? "Delete Pretrained" : "Delete Network"}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {networks.map((n) => (
            <Table.Row key={n.id}>
              <Table.Cell>{n.owner}</Table.Cell>
              <Table.Cell>{n.name}</Table.Cell>
              <Table.Cell>{n.description}</Table.Cell>
              <Table.Cell>{n.type}</Table.Cell>
              <Table.Cell>{formatDate(n.createdAt).date}</Table.Cell>
              <Table.Cell>{formatDate(n.updatedAt).date}</Table.Cell>
              {pretrained && (
                <Table.Cell textAlign="center">
                  <Popup
                    hoverable
                    trigger={<Image src={n.thumbnail} size="mini" centered />}
                    content={<Image src={n.thumbnail} size="medium" />}
                  />
                </Table.Cell>
              )}
              {!pretrained && (
                <Table.Cell textAlign="center">
                  <FileUploadModal id={n.id} refetch={refetch} name={n.name} />
                </Table.Cell>
              )}
              <Table.Cell textAlign="center">
                {pretrained ? (
                  <PretrainedDeletionConfirmBox
                    id={n.id}
                    refetch={refetch}
                    name={n.name}
                  />
                ) : (
                  <NetworkDeletionConfirmBox
                    id={n.id}
                    refetch={refetch}
                    name={n.name}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
