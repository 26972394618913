/* eslint-disable react/jsx-curly-newline */
import { Modal, Form, Button } from "semantic-ui-react";
import useDatasetCopy from "./hooks/useDatasetCopy";
import { Dataset } from "./types";

type Props = {
  hide: () => void;
  visible: boolean;
  dataset: Dataset;
  targetUsername: string;
};

export default function DatasetCopyModal({
  hide,
  visible,
  dataset,
  targetUsername,
}: Props) {
  const { copy, copyData, setCopyData } = useDatasetCopy(
    dataset.id,
    targetUsername,
    {
      targetDatasetDescription: dataset.description,
      targetDatasetName: dataset.name,
    }
  );
  const runCopy = () => copy().then(hide).catch(console.log);

  return (
    <Modal
      onClose={hide}
      open={visible}
      onCancel={hide}
      as={Form}
      onSubmit={runCopy}
    >
      <Modal.Header>Copy Dataset</Modal.Header>
      <Modal.Content>
        <Form.Input
          label="Target Dataset Name"
          placeholder="Target Dataset name"
          value={copyData.targetDatasetName || ""}
          onChange={(_, { value: targetDatasetName }) =>
            setCopyData((v) => ({ ...v, targetDatasetName }))
          }
        />
        <Form.TextArea
          label="Target Dataset Description"
          placeholder="Optional Dataset description ..."
          value={copyData.targetDatasetDescription || ""}
          onChange={(_, { value }) =>
            setCopyData((v) => ({
              ...v,
              targetDatasetDescription: value as string,
            }))
          }
        />
      </Modal.Content>
      <Modal.Actions>
        <Button content="OK" positive />
        <Button content="Cancel" basic onClick={hide} />
      </Modal.Actions>
    </Modal>
  );
}
