import { ImageSize, Vector2d } from "MarkupTypes";
import { useState } from "react";

export default function useStageState() {
  const [currentOffset, setOffset] = useState<Vector2d>();

  const [canvasSize, resizeCanvas] = useState<ImageSize>({
    width: 0,
    height: 0,
  });

  const [mouseDown, setMouseDown] = useState(false);

  const [mouseOnCanvas, setMouseOnCanvas] = useState(false);

  return {
    resizeCanvas,
    currentOffset,
    setOffset,
    canvasSize,
    setMouseDown,
    mouseOnCanvas,
    mouseDown,
    setMouseOnCanvas,
  };
}
