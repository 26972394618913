import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The DateTime scalar type represents date and time as a string in RFC3339 format.
   * For example: "1985-04-12T23:20:50.52Z" represents 20 minutes and 50.52 seconds
   * after the 23rd hour of April 12th, 1985 in UTC.
   */
  DateTime: string;
  /**
   * The Int64 scalar type represents a signed 64‐bit numeric non‐fractional value.
   * Int64 can represent values in range [-(2^63),(2^63 - 1)].
   */
  Int64: any;
};
















export enum AccessRights {
  Read = 'Read',
  Write = 'Write'
}

export type AccessRights_Hash = {
  eq?: Maybe<AccessRights>;
  in?: Maybe<Array<Maybe<AccessRights>>>;
};

export type Acl = {
  __typename?: 'ACL';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  hasAccess: User;
  rights: Array<AccessRights>;
};


export type AclHasAccessArgs = {
  filter?: Maybe<UserFilter>;
};

export type AclAggregateResult = {
  __typename?: 'ACLAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
};

export type AclFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  rights?: Maybe<AccessRights_Hash>;
  has?: Maybe<Array<Maybe<AclHasFilter>>>;
  and?: Maybe<Array<Maybe<AclFilter>>>;
  or?: Maybe<Array<Maybe<AclFilter>>>;
  not?: Maybe<AclFilter>;
};

export enum AclHasFilter {
  Id = 'id',
  HasAccess = 'hasAccess',
  Rights = 'rights'
}

export type AclOrder = {
  asc?: Maybe<AclOrderable>;
  desc?: Maybe<AclOrderable>;
  then?: Maybe<AclOrder>;
};

export enum AclOrderable {
  Id = 'id'
}

export type AclRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  hasAccess?: Maybe<UserRef>;
  rights?: Maybe<Array<AccessRights>>;
};

export type AddAppStateInput = {
  id: Scalars['String'];
  migrations: Array<MigrationRef>;
};

export type AddAppStatePayload = {
  __typename?: 'AddAppStatePayload';
  appState?: Maybe<Array<Maybe<AppState>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddAppStatePayloadAppStateArgs = {
  filter?: Maybe<AppStateFilter>;
  order?: Maybe<AppStateOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddBinaryInput = {
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type AddBinaryPayload = {
  __typename?: 'AddBinaryPayload';
  binary?: Maybe<Array<Maybe<Binary>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddBinaryPayloadBinaryArgs = {
  filter?: Maybe<BinaryFilter>;
  order?: Maybe<BinaryOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddBoundingBoxAnnotationInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  label: MarkupLabelRef;
  topLeft: Point2DRef;
  bottomRight: Point2DRef;
  annotationType: AnnotationType;
};

export type AddBoundingBoxAnnotationPayload = {
  __typename?: 'AddBoundingBoxAnnotationPayload';
  boundingBoxAnnotation?: Maybe<Array<Maybe<BoundingBoxAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddBoundingBoxAnnotationPayloadBoundingBoxAnnotationArgs = {
  filter?: Maybe<BoundingBoxAnnotationFilter>;
  order?: Maybe<BoundingBoxAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddBoundingBoxPredictionInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  label: MarkupLabelRef;
  topLeft: Point2DRef;
  bottomRight: Point2DRef;
  probability: Scalars['Float'];
};

export type AddBoundingBoxPredictionPayload = {
  __typename?: 'AddBoundingBoxPredictionPayload';
  boundingBoxPrediction?: Maybe<Array<Maybe<BoundingBoxPrediction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddBoundingBoxPredictionPayloadBoundingBoxPredictionArgs = {
  filter?: Maybe<BoundingBoxPredictionFilter>;
  order?: Maybe<BoundingBoxPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddCcCheckerResultInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy: PipelineModuleDatapointOutputRef;
  researchBox: ResearchBoxRef;
  groups: Scalars['String'];
  isOkay: Scalars['Boolean'];
};

export type AddCcCheckerResultPayload = {
  __typename?: 'AddCCCheckerResultPayload';
  cCCheckerResult?: Maybe<Array<Maybe<CcCheckerResult>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddCcCheckerResultPayloadCcCheckerResultArgs = {
  filter?: Maybe<CcCheckerResultFilter>;
  order?: Maybe<CcCheckerResultOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddCircleAnnotationInput = {
  id: Scalars['String'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabelRef;
  annotationType: AnnotationType;
  markup: SegmentationMarkupRef;
  center: Point2DRef;
  radius: Scalars['Int'];
};

export type AddCircleAnnotationPayload = {
  __typename?: 'AddCircleAnnotationPayload';
  circleAnnotation?: Maybe<Array<Maybe<CircleAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddCircleAnnotationPayloadCircleAnnotationArgs = {
  filter?: Maybe<CircleAnnotationFilter>;
  order?: Maybe<CircleAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddClassificationMarkupInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<ImageRef>;
  subset: SubsetType;
  network: NetworkModuleRef;
  networkId: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  annotations: Array<ScalarAnnotationRef>;
};

export type AddClassificationMarkupPayload = {
  __typename?: 'AddClassificationMarkupPayload';
  classificationMarkup?: Maybe<Array<Maybe<ClassificationMarkup>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddClassificationMarkupPayloadClassificationMarkupArgs = {
  filter?: Maybe<ClassificationMarkupFilter>;
  order?: Maybe<ClassificationMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddClassificationPredictionInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<ImageRef>;
  network: NetworkModuleRef;
  networkId: Scalars['String'];
  predictions: Array<ScalarPredictionRef>;
};

export type AddClassificationPredictionPayload = {
  __typename?: 'AddClassificationPredictionPayload';
  classificationPrediction?: Maybe<Array<Maybe<ClassificationPrediction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddClassificationPredictionPayloadClassificationPredictionArgs = {
  filter?: Maybe<ClassificationPredictionFilter>;
  order?: Maybe<ClassificationPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddDatasetInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  user: UserRef;
  name: Scalars['String'];
  description: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  pipeline?: Maybe<PipelineRef>;
  objects: Array<FolderOrResearchBoxRef>;
};

export type AddDatasetPayload = {
  __typename?: 'AddDatasetPayload';
  dataset?: Maybe<Array<Maybe<Dataset>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddDatasetPayloadDatasetArgs = {
  filter?: Maybe<DatasetFilter>;
  order?: Maybe<DatasetOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddDenkboxInput = {
  _placeholder?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  dockerId: Scalars['String'];
  host: Scalars['String'];
  gpus: Array<GpuRef>;
  jobs: Array<DenkjobRef>;
};

export type AddDenkboxPayload = {
  __typename?: 'AddDenkboxPayload';
  denkbox?: Maybe<Array<Maybe<Denkbox>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddDenkboxPayloadDenkboxArgs = {
  filter?: Maybe<DenkboxFilter>;
  order?: Maybe<DenkboxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddDenkjobInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  denkbox?: Maybe<DenkboxRef>;
  command: DenkjobCommand;
  state?: Maybe<DenkjobState>;
  failCounter: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  jobType: DenkjobType;
  tensorboard?: Maybe<Scalars['String']>;
  module: NetworkModuleRef;
  researchBoxIds: Array<Scalars['String']>;
};

export type AddDenkjobPayload = {
  __typename?: 'AddDenkjobPayload';
  denkjob?: Maybe<Array<Maybe<Denkjob>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddDenkjobPayloadDenkjobArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddDenktesterNotificationInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  read: Scalars['Boolean'];
  title: Scalars['String'];
  message: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  currentProgress: Scalars['Int'];
  maxProgress: Scalars['Int'];
  network: NetworkModuleRef;
  networkId: Scalars['String'];
};

export type AddDenktesterNotificationPayload = {
  __typename?: 'AddDenktesterNotificationPayload';
  denktesterNotification?: Maybe<Array<Maybe<DenktesterNotification>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddDenktesterNotificationPayloadDenktesterNotificationArgs = {
  filter?: Maybe<DenktesterNotificationFilter>;
  order?: Maybe<DenktesterNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddEncryptedBinaryInput = {
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type AddEncryptedBinaryPayload = {
  __typename?: 'AddEncryptedBinaryPayload';
  encryptedBinary?: Maybe<Array<Maybe<EncryptedBinary>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddEncryptedBinaryPayloadEncryptedBinaryArgs = {
  filter?: Maybe<EncryptedBinaryFilter>;
  order?: Maybe<EncryptedBinaryOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddFolderInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  typeForSorting: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<FolderRef>;
  dataset?: Maybe<DatasetRef>;
  parentIds: Scalars['String'];
  description: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  objects: Array<FolderOrResearchBoxRef>;
};

export type AddFolderPayload = {
  __typename?: 'AddFolderPayload';
  folder?: Maybe<Array<Maybe<Folder>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddFolderPayloadFolderArgs = {
  filter?: Maybe<FolderFilter>;
  order?: Maybe<FolderOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddGpuInput = {
  _placeholder?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  index: Scalars['Int'];
  maxMemory: Scalars['Int'];
  freeMemory: Scalars['Int'];
  usage: Scalars['Int'];
  denkbox: DenkboxRef;
};

export type AddGpuPayload = {
  __typename?: 'AddGpuPayload';
  gpu?: Maybe<Array<Maybe<Gpu>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddGpuPayloadGpuArgs = {
  filter?: Maybe<GpuFilter>;
  order?: Maybe<GpuOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddImageInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy: PipelineModuleDatapointOutputRef;
  researchBox: ResearchBoxRef;
  thumbnail: Scalars['String'];
  height: Scalars['Int'];
  width: Scalars['Int'];
  resolutions: Array<ImageResolutionRef>;
  filename: Scalars['String'];
  metadata: Array<ImageMetadataFieldRef>;
  markups: Array<ImageMarkupRef>;
  predictions: Array<ImagePredictionRef>;
};

export type AddImageMetadataFieldInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
  image: ImageRef;
};

export type AddImageMetadataFieldPayload = {
  __typename?: 'AddImageMetadataFieldPayload';
  imageMetadataField?: Maybe<Array<Maybe<ImageMetadataField>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddImageMetadataFieldPayloadImageMetadataFieldArgs = {
  filter?: Maybe<ImageMetadataFieldFilter>;
  order?: Maybe<ImageMetadataFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddImagePayload = {
  __typename?: 'AddImagePayload';
  image?: Maybe<Array<Maybe<Image>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddImagePayloadImageArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddImageResolutionInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  image: ImageRef;
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type AddImageResolutionPayload = {
  __typename?: 'AddImageResolutionPayload';
  imageResolution?: Maybe<Array<Maybe<ImageResolution>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddImageResolutionPayloadImageResolutionArgs = {
  filter?: Maybe<ImageResolutionFilter>;
  order?: Maybe<ImageResolutionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddInstanceSegmentationMarkupInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<ImageRef>;
  subset: SubsetType;
  network: NetworkModuleRef;
  networkId: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  _placeholder: Scalars['String'];
};

export type AddInstanceSegmentationMarkupPayload = {
  __typename?: 'AddInstanceSegmentationMarkupPayload';
  instanceSegmentationMarkup?: Maybe<Array<Maybe<InstanceSegmentationMarkup>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddInstanceSegmentationMarkupPayloadInstanceSegmentationMarkupArgs = {
  filter?: Maybe<InstanceSegmentationMarkupFilter>;
  order?: Maybe<InstanceSegmentationMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddInstanceSegmentationPredictionInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<ImageRef>;
  subset: SubsetType;
  network: NetworkModuleRef;
  networkId: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  _placeholder: Scalars['String'];
};

export type AddInstanceSegmentationPredictionPayload = {
  __typename?: 'AddInstanceSegmentationPredictionPayload';
  instanceSegmentationPrediction?: Maybe<Array<Maybe<InstanceSegmentationPrediction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddInstanceSegmentationPredictionPayloadInstanceSegmentationPredictionArgs = {
  filter?: Maybe<InstanceSegmentationPredictionFilter>;
  order?: Maybe<InstanceSegmentationPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddMagicwandAnnotationInput = {
  id: Scalars['String'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabelRef;
  annotationType: AnnotationType;
  markup: SegmentationMarkupRef;
  topLeft: Point2DRef;
  bottomRight: Point2DRef;
  center: Point2DRef;
  threshold: Scalars['Int'];
  dataURL: Scalars['String'];
};

export type AddMagicwandAnnotationPayload = {
  __typename?: 'AddMagicwandAnnotationPayload';
  magicwandAnnotation?: Maybe<Array<Maybe<MagicwandAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddMagicwandAnnotationPayloadMagicwandAnnotationArgs = {
  filter?: Maybe<MagicwandAnnotationFilter>;
  order?: Maybe<MagicwandAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddMagneticFieldScanInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy: PipelineModuleDatapointOutputRef;
  researchBox: ResearchBoxRef;
  width: Scalars['Int'];
  height: Scalars['Int'];
  hasBaseline: Scalars['Boolean'];
  startIndices: Array<Scalars['Int']>;
};

export type AddMagneticFieldScanPayload = {
  __typename?: 'AddMagneticFieldScanPayload';
  magneticFieldScan?: Maybe<Array<Maybe<MagneticFieldScan>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddMagneticFieldScanPayloadMagneticFieldScanArgs = {
  filter?: Maybe<MagneticFieldScanFilter>;
  order?: Maybe<MagneticFieldScanOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddMaintenanceNotificationInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  read: Scalars['Boolean'];
  title: Scalars['String'];
  message: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  finished: Scalars['Boolean'];
  type: MaintenanceType;
};

export type AddMaintenanceNotificationPayload = {
  __typename?: 'AddMaintenanceNotificationPayload';
  maintenanceNotification?: Maybe<Array<Maybe<MaintenanceNotification>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddMaintenanceNotificationPayloadMaintenanceNotificationArgs = {
  filter?: Maybe<MaintenanceNotificationFilter>;
  order?: Maybe<MaintenanceNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddMarkupLabelInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  idx: Scalars['Int'];
  name: Scalars['String'];
  color: Scalars['String'];
  network: NetworkModuleRef;
  positiveMarkupCount: Scalars['Int'];
  negativeMarkupCount: Scalars['Int'];
};

export type AddMarkupLabelPayload = {
  __typename?: 'AddMarkupLabelPayload';
  markupLabel?: Maybe<Array<Maybe<MarkupLabel>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddMarkupLabelPayloadMarkupLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
  order?: Maybe<MarkupLabelOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddMetricInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  currentValue: Scalars['Float'];
  bestValue: Scalars['Float'];
  history: Scalars['String'];
  show: Scalars['Boolean'];
  inferenceThreshold?: Maybe<Scalars['Float']>;
  label?: Maybe<MarkupLabelRef>;
  isNetworkScore: Scalars['Boolean'];
};

export type AddMetricPayload = {
  __typename?: 'AddMetricPayload';
  metric?: Maybe<Array<Maybe<Metric>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddMetricPayloadMetricArgs = {
  filter?: Maybe<MetricFilter>;
  order?: Maybe<MetricOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddMigrationInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  appState: AppStateRef;
  step: Scalars['Int'];
  note: Scalars['String'];
};

export type AddMigrationPayload = {
  __typename?: 'AddMigrationPayload';
  migration?: Maybe<Array<Maybe<Migration>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddMigrationPayloadMigrationArgs = {
  filter?: Maybe<MigrationFilter>;
  order?: Maybe<MigrationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddNetworkConfigInput = {
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  batchSize?: Maybe<Scalars['Int']>;
  trainingUsage?: Maybe<Scalars['Int']>;
  inferenceUsage?: Maybe<Scalars['Int']>;
  network: NetworkModuleRef;
};

export type AddNetworkConfigPayload = {
  __typename?: 'AddNetworkConfigPayload';
  networkConfig?: Maybe<Array<Maybe<NetworkConfig>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddNetworkConfigPayloadNetworkConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
  order?: Maybe<NetworkConfigOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddNetworkSnapshotInput = {
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  pytorchBinary: BinaryRef;
  onnxBinary: EncryptedBinaryRef;
  metrics: Array<MetricRef>;
  network: NetworkModuleRef;
};

export type AddNetworkSnapshotPayload = {
  __typename?: 'AddNetworkSnapshotPayload';
  networkSnapshot?: Maybe<Array<Maybe<NetworkSnapshot>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddNetworkSnapshotPayloadNetworkSnapshotArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
  order?: Maybe<NetworkSnapshotOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddObjectDetectionMarkupInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<ImageRef>;
  subset: SubsetType;
  network: NetworkModuleRef;
  networkId: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  annotations: Array<BoundingBoxAnnotationRef>;
};

export type AddObjectDetectionMarkupPayload = {
  __typename?: 'AddObjectDetectionMarkupPayload';
  objectDetectionMarkup?: Maybe<Array<Maybe<ObjectDetectionMarkup>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddObjectDetectionMarkupPayloadObjectDetectionMarkupArgs = {
  filter?: Maybe<ObjectDetectionMarkupFilter>;
  order?: Maybe<ObjectDetectionMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddObjectDetectionPredictionInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<ImageRef>;
  network: NetworkModuleRef;
  networkId: Scalars['String'];
  predictions: Array<BoundingBoxPredictionRef>;
};

export type AddObjectDetectionPredictionPayload = {
  __typename?: 'AddObjectDetectionPredictionPayload';
  objectDetectionPrediction?: Maybe<Array<Maybe<ObjectDetectionPrediction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddObjectDetectionPredictionPayloadObjectDetectionPredictionArgs = {
  filter?: Maybe<ObjectDetectionPredictionFilter>;
  order?: Maybe<ObjectDetectionPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddOneOffNotificationInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  read: Scalars['Boolean'];
  title: Scalars['String'];
  message: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type AddOneOffNotificationPayload = {
  __typename?: 'AddOneOffNotificationPayload';
  oneOffNotification?: Maybe<Array<Maybe<OneOffNotification>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddOneOffNotificationPayloadOneOffNotificationArgs = {
  filter?: Maybe<OneOffNotificationFilter>;
  order?: Maybe<OneOffNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPenAnnotationInput = {
  id: Scalars['String'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabelRef;
  annotationType: AnnotationType;
  markup: SegmentationMarkupRef;
  topLeft: Point2DRef;
  bottomRight: Point2DRef;
  thickness: Scalars['Int'];
  points: Array<Point2DRef>;
  dataURL: Scalars['String'];
};

export type AddPenAnnotationPayload = {
  __typename?: 'AddPenAnnotationPayload';
  penAnnotation?: Maybe<Array<Maybe<PenAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPenAnnotationPayloadPenAnnotationArgs = {
  filter?: Maybe<PenAnnotationFilter>;
  order?: Maybe<PenAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  dataset: DatasetRef;
  enabled: Scalars['Boolean'];
  modules?: Maybe<Array<PipelineModuleRef>>;
  edges: Array<PipelineModuleEdgeRef>;
};

export type AddPipelineModuleArtifactOutputInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  internalName: Scalars['String'];
  dataType: DataType;
  module: PipelineModuleRef;
  edges: Array<PipelineModuleEdgeRef>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type AddPipelineModuleArtifactOutputPayload = {
  __typename?: 'AddPipelineModuleArtifactOutputPayload';
  pipelineModuleArtifactOutput?: Maybe<Array<Maybe<PipelineModuleArtifactOutput>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleArtifactOutputPayloadPipelineModuleArtifactOutputArgs = {
  filter?: Maybe<PipelineModuleArtifactOutputFilter>;
  order?: Maybe<PipelineModuleArtifactOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleBMobileInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInputRef>;
  outputs: Array<PipelineModuleOutputRef>;
  layoutPosition: PositionRef;
  pipeline: PipelineRef;
  producedTags: Array<ProductionTagRef>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type AddPipelineModuleBMobilePayload = {
  __typename?: 'AddPipelineModuleBMobilePayload';
  pipelineModuleBMobile?: Maybe<Array<Maybe<PipelineModuleBMobile>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleBMobilePayloadPipelineModuleBMobileArgs = {
  filter?: Maybe<PipelineModuleBMobileFilter>;
  order?: Maybe<PipelineModuleBMobileOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleCcCheckerInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInputRef>;
  outputs: Array<PipelineModuleOutputRef>;
  layoutPosition: PositionRef;
  pipeline: PipelineRef;
  producedTags: Array<ProductionTagRef>;
  busBarCountPerCell: Scalars['Int'];
  maxShift: Scalars['Int'];
  minCorrValue: Scalars['Float'];
  cutOffRate: Scalars['Float'];
  minPeakHeightPercentage: Scalars['Float'];
  minPeakDistance: Scalars['Float'];
  minPeakProminence: Scalars['Float'];
};

export type AddPipelineModuleCcCheckerPayload = {
  __typename?: 'AddPipelineModuleCCCheckerPayload';
  pipelineModuleCCChecker?: Maybe<Array<Maybe<PipelineModuleCcChecker>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleCcCheckerPayloadPipelineModuleCcCheckerArgs = {
  filter?: Maybe<PipelineModuleCcCheckerFilter>;
  order?: Maybe<PipelineModuleCcCheckerOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleContrastEnhancementInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInputRef>;
  outputs: Array<PipelineModuleOutputRef>;
  layoutPosition: PositionRef;
  pipeline: PipelineRef;
  producedTags: Array<ProductionTagRef>;
  clipLimit: Scalars['Float'];
  tileSize: Scalars['Int'];
};

export type AddPipelineModuleContrastEnhancementPayload = {
  __typename?: 'AddPipelineModuleContrastEnhancementPayload';
  pipelineModuleContrastEnhancement?: Maybe<Array<Maybe<PipelineModuleContrastEnhancement>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleContrastEnhancementPayloadPipelineModuleContrastEnhancementArgs = {
  filter?: Maybe<PipelineModuleContrastEnhancementFilter>;
  order?: Maybe<PipelineModuleContrastEnhancementOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleDatapointOutputInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  internalName: Scalars['String'];
  dataType: DataType;
  module: PipelineModuleRef;
  edges: Array<PipelineModuleEdgeRef>;
  datapoints: Array<DatapointRef>;
};

export type AddPipelineModuleDatapointOutputPayload = {
  __typename?: 'AddPipelineModuleDatapointOutputPayload';
  pipelineModuleDatapointOutput?: Maybe<Array<Maybe<PipelineModuleDatapointOutput>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleDatapointOutputPayloadPipelineModuleDatapointOutputArgs = {
  filter?: Maybe<PipelineModuleDatapointOutputFilter>;
  order?: Maybe<PipelineModuleDatapointOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleEdgeInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  pipeline: PipelineRef;
  from: PipelineModuleOutputRef;
  to: PipelineModuleInputRef;
};

export type AddPipelineModuleEdgePayload = {
  __typename?: 'AddPipelineModuleEdgePayload';
  pipelineModuleEdge?: Maybe<Array<Maybe<PipelineModuleEdge>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleEdgePayloadPipelineModuleEdgeArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
  order?: Maybe<PipelineModuleEdgeOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleInputInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  inputType: PipelineModuleInputType;
  dataType: DataType;
  module: PipelineModuleRef;
  edges: Array<PipelineModuleEdgeRef>;
};

export type AddPipelineModuleInputPayload = {
  __typename?: 'AddPipelineModuleInputPayload';
  pipelineModuleInput?: Maybe<Array<Maybe<PipelineModuleInput>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleInputPayloadPipelineModuleInputArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleJobRequestCcCheckerInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  module: PipelineModuleRef;
  researchBoxes: Array<ResearchBoxRef>;
  busBarCountPerCell: Scalars['Int'];
  maxShift: Scalars['Int'];
  minCorrValue: Scalars['Float'];
  cutOffRate: Scalars['Float'];
  minPeakHeightPercentage: Scalars['Float'];
  minPeakDistance: Scalars['Float'];
  minPeakProminence: Scalars['Float'];
};

export type AddPipelineModuleJobRequestCcCheckerPayload = {
  __typename?: 'AddPipelineModuleJobRequestCCCheckerPayload';
  pipelineModuleJobRequestCCChecker?: Maybe<Array<Maybe<PipelineModuleJobRequestCcChecker>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleJobRequestCcCheckerPayloadPipelineModuleJobRequestCcCheckerArgs = {
  filter?: Maybe<PipelineModuleJobRequestCcCheckerFilter>;
  order?: Maybe<PipelineModuleJobRequestCcCheckerOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleJobRequestContrastEnhancementInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  module: PipelineModuleRef;
  researchBoxes: Array<ResearchBoxRef>;
  clipLimit: Scalars['Float'];
  tileSize: Scalars['Int'];
};

export type AddPipelineModuleJobRequestContrastEnhancementPayload = {
  __typename?: 'AddPipelineModuleJobRequestContrastEnhancementPayload';
  pipelineModuleJobRequestContrastEnhancement?: Maybe<Array<Maybe<PipelineModuleJobRequestContrastEnhancement>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleJobRequestContrastEnhancementPayloadPipelineModuleJobRequestContrastEnhancementArgs = {
  filter?: Maybe<PipelineModuleJobRequestContrastEnhancementFilter>;
  order?: Maybe<PipelineModuleJobRequestContrastEnhancementOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleNetworkImageAnomalyDetectionInput = {
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabelRef>;
  markups: Array<ImageMarkupRef>;
  predictions: Array<ImagePredictionRef>;
  snapshots: Array<NetworkSnapshotRef>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs: Array<DenkjobRef>;
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInputRef>;
  outputs: Array<PipelineModuleOutputRef>;
  layoutPosition: PositionRef;
  pipeline: PipelineRef;
  producedTags: Array<ProductionTagRef>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type AddPipelineModuleNetworkImageAnomalyDetectionPayload = {
  __typename?: 'AddPipelineModuleNetworkImageAnomalyDetectionPayload';
  pipelineModuleNetworkImageAnomalyDetection?: Maybe<Array<Maybe<PipelineModuleNetworkImageAnomalyDetection>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleNetworkImageAnomalyDetectionPayloadPipelineModuleNetworkImageAnomalyDetectionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageAnomalyDetectionFilter>;
  order?: Maybe<PipelineModuleNetworkImageAnomalyDetectionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleNetworkImageClassificationInput = {
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabelRef>;
  markups: Array<ImageMarkupRef>;
  predictions: Array<ImagePredictionRef>;
  snapshots: Array<NetworkSnapshotRef>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs: Array<DenkjobRef>;
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInputRef>;
  outputs: Array<PipelineModuleOutputRef>;
  layoutPosition: PositionRef;
  pipeline: PipelineRef;
  producedTags: Array<ProductionTagRef>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type AddPipelineModuleNetworkImageClassificationPayload = {
  __typename?: 'AddPipelineModuleNetworkImageClassificationPayload';
  pipelineModuleNetworkImageClassification?: Maybe<Array<Maybe<PipelineModuleNetworkImageClassification>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleNetworkImageClassificationPayloadPipelineModuleNetworkImageClassificationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageClassificationFilter>;
  order?: Maybe<PipelineModuleNetworkImageClassificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleNetworkImageInstanceSegmentationInput = {
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabelRef>;
  markups: Array<ImageMarkupRef>;
  predictions: Array<ImagePredictionRef>;
  snapshots: Array<NetworkSnapshotRef>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs: Array<DenkjobRef>;
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInputRef>;
  outputs: Array<PipelineModuleOutputRef>;
  layoutPosition: PositionRef;
  pipeline: PipelineRef;
  producedTags: Array<ProductionTagRef>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type AddPipelineModuleNetworkImageInstanceSegmentationPayload = {
  __typename?: 'AddPipelineModuleNetworkImageInstanceSegmentationPayload';
  pipelineModuleNetworkImageInstanceSegmentation?: Maybe<Array<Maybe<PipelineModuleNetworkImageInstanceSegmentation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleNetworkImageInstanceSegmentationPayloadPipelineModuleNetworkImageInstanceSegmentationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageInstanceSegmentationFilter>;
  order?: Maybe<PipelineModuleNetworkImageInstanceSegmentationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleNetworkImageObjectCountingInput = {
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabelRef>;
  markups: Array<ImageMarkupRef>;
  predictions: Array<ImagePredictionRef>;
  snapshots: Array<NetworkSnapshotRef>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs: Array<DenkjobRef>;
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInputRef>;
  outputs: Array<PipelineModuleOutputRef>;
  layoutPosition: PositionRef;
  pipeline: PipelineRef;
  producedTags: Array<ProductionTagRef>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type AddPipelineModuleNetworkImageObjectCountingPayload = {
  __typename?: 'AddPipelineModuleNetworkImageObjectCountingPayload';
  pipelineModuleNetworkImageObjectCounting?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectCounting>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleNetworkImageObjectCountingPayloadPipelineModuleNetworkImageObjectCountingArgs = {
  filter?: Maybe<PipelineModuleNetworkImageObjectCountingFilter>;
  order?: Maybe<PipelineModuleNetworkImageObjectCountingOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleNetworkImageObjectDetectionInput = {
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabelRef>;
  markups: Array<ImageMarkupRef>;
  predictions: Array<ImagePredictionRef>;
  snapshots: Array<NetworkSnapshotRef>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs: Array<DenkjobRef>;
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInputRef>;
  outputs: Array<PipelineModuleOutputRef>;
  layoutPosition: PositionRef;
  pipeline: PipelineRef;
  producedTags: Array<ProductionTagRef>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type AddPipelineModuleNetworkImageObjectDetectionPayload = {
  __typename?: 'AddPipelineModuleNetworkImageObjectDetectionPayload';
  pipelineModuleNetworkImageObjectDetection?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectDetection>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleNetworkImageObjectDetectionPayloadPipelineModuleNetworkImageObjectDetectionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageObjectDetectionFilter>;
  order?: Maybe<PipelineModuleNetworkImageObjectDetectionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleNetworkImageOcrInput = {
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabelRef>;
  markups: Array<ImageMarkupRef>;
  predictions: Array<ImagePredictionRef>;
  snapshots: Array<NetworkSnapshotRef>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs: Array<DenkjobRef>;
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInputRef>;
  outputs: Array<PipelineModuleOutputRef>;
  layoutPosition: PositionRef;
  pipeline: PipelineRef;
  producedTags: Array<ProductionTagRef>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type AddPipelineModuleNetworkImageOcrPayload = {
  __typename?: 'AddPipelineModuleNetworkImageOCRPayload';
  pipelineModuleNetworkImageOCR?: Maybe<Array<Maybe<PipelineModuleNetworkImageOcr>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleNetworkImageOcrPayloadPipelineModuleNetworkImageOcrArgs = {
  filter?: Maybe<PipelineModuleNetworkImageOcrFilter>;
  order?: Maybe<PipelineModuleNetworkImageOcrOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleNetworkImageSegmentationInput = {
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabelRef>;
  markups: Array<ImageMarkupRef>;
  predictions: Array<ImagePredictionRef>;
  snapshots: Array<NetworkSnapshotRef>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs: Array<DenkjobRef>;
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInputRef>;
  outputs: Array<PipelineModuleOutputRef>;
  layoutPosition: PositionRef;
  pipeline: PipelineRef;
  producedTags: Array<ProductionTagRef>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type AddPipelineModuleNetworkImageSegmentationPayload = {
  __typename?: 'AddPipelineModuleNetworkImageSegmentationPayload';
  pipelineModuleNetworkImageSegmentation?: Maybe<Array<Maybe<PipelineModuleNetworkImageSegmentation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleNetworkImageSegmentationPayloadPipelineModuleNetworkImageSegmentationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageSegmentationFilter>;
  order?: Maybe<PipelineModuleNetworkImageSegmentationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleNetworkImageSuperResolutionInput = {
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabelRef>;
  markups: Array<ImageMarkupRef>;
  predictions: Array<ImagePredictionRef>;
  snapshots: Array<NetworkSnapshotRef>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs: Array<DenkjobRef>;
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInputRef>;
  outputs: Array<PipelineModuleOutputRef>;
  layoutPosition: PositionRef;
  pipeline: PipelineRef;
  producedTags: Array<ProductionTagRef>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type AddPipelineModuleNetworkImageSuperResolutionPayload = {
  __typename?: 'AddPipelineModuleNetworkImageSuperResolutionPayload';
  pipelineModuleNetworkImageSuperResolution?: Maybe<Array<Maybe<PipelineModuleNetworkImageSuperResolution>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleNetworkImageSuperResolutionPayloadPipelineModuleNetworkImageSuperResolutionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageSuperResolutionFilter>;
  order?: Maybe<PipelineModuleNetworkImageSuperResolutionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelineModuleUploadInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInputRef>;
  outputs: Array<PipelineModuleOutputRef>;
  layoutPosition: PositionRef;
  pipeline: PipelineRef;
  producedTags: Array<ProductionTagRef>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type AddPipelineModuleUploadPayload = {
  __typename?: 'AddPipelineModuleUploadPayload';
  pipelineModuleUpload?: Maybe<Array<Maybe<PipelineModuleUpload>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelineModuleUploadPayloadPipelineModuleUploadArgs = {
  filter?: Maybe<PipelineModuleUploadFilter>;
  order?: Maybe<PipelineModuleUploadOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPipelinePayload = {
  __typename?: 'AddPipelinePayload';
  pipeline?: Maybe<Array<Maybe<Pipeline>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPipelinePayloadPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
  order?: Maybe<PipelineOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPixelAnnotationInput = {
  id: Scalars['String'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabelRef;
  annotationType: AnnotationType;
  markup: SegmentationMarkupRef;
  topLeft: Point2DRef;
  bottomRight: Point2DRef;
  dataURL: Scalars['String'];
};

export type AddPixelAnnotationPayload = {
  __typename?: 'AddPixelAnnotationPayload';
  pixelAnnotation?: Maybe<Array<Maybe<PixelAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPixelAnnotationPayloadPixelAnnotationArgs = {
  filter?: Maybe<PixelAnnotationFilter>;
  order?: Maybe<PixelAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPoint2DInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  idx: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type AddPoint2DPayload = {
  __typename?: 'AddPoint2DPayload';
  point2D?: Maybe<Array<Maybe<Point2D>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPoint2DPayloadPoint2DArgs = {
  filter?: Maybe<Point2DFilter>;
  order?: Maybe<Point2DOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPolygonAnnotationInput = {
  id: Scalars['String'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabelRef;
  annotationType: AnnotationType;
  markup: SegmentationMarkupRef;
  points: Array<Point2DRef>;
};

export type AddPolygonAnnotationPayload = {
  __typename?: 'AddPolygonAnnotationPayload';
  polygonAnnotation?: Maybe<Array<Maybe<PolygonAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPolygonAnnotationPayloadPolygonAnnotationArgs = {
  filter?: Maybe<PolygonAnnotationFilter>;
  order?: Maybe<PolygonAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddPretrainedNetworkInput = {
  _placeholder?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  networkModule: NetworkModuleRef;
  thumbnail: Scalars['String'];
};

export type AddPretrainedNetworkPayload = {
  __typename?: 'AddPretrainedNetworkPayload';
  pretrainedNetwork?: Maybe<Array<Maybe<PretrainedNetwork>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddPretrainedNetworkPayloadPretrainedNetworkArgs = {
  filter?: Maybe<PretrainedNetworkFilter>;
  order?: Maybe<PretrainedNetworkOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddProductionTagInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  moduleId: Scalars['String'];
  module: PipelineModuleRef;
  moduleParams: Scalars['String'];
  producedResearchBox: ResearchBoxRef;
  producedResearchBoxId: Scalars['String'];
};

export type AddProductionTagPayload = {
  __typename?: 'AddProductionTagPayload';
  productionTag?: Maybe<Array<Maybe<ProductionTag>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddProductionTagPayloadProductionTagArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddProgressNotificationInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  read: Scalars['Boolean'];
  title: Scalars['String'];
  message: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  currentProgress: Scalars['Int'];
  maxProgress: Scalars['Int'];
};

export type AddProgressNotificationPayload = {
  __typename?: 'AddProgressNotificationPayload';
  progressNotification?: Maybe<Array<Maybe<ProgressNotification>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddProgressNotificationPayloadProgressNotificationArgs = {
  filter?: Maybe<ProgressNotificationFilter>;
  order?: Maybe<ProgressNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddRectangleAnnotationInput = {
  id: Scalars['String'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabelRef;
  annotationType: AnnotationType;
  markup: SegmentationMarkupRef;
  topLeft: Point2DRef;
  bottomRight: Point2DRef;
};

export type AddRectangleAnnotationPayload = {
  __typename?: 'AddRectangleAnnotationPayload';
  rectangleAnnotation?: Maybe<Array<Maybe<RectangleAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddRectangleAnnotationPayloadRectangleAnnotationArgs = {
  filter?: Maybe<RectangleAnnotationFilter>;
  order?: Maybe<RectangleAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddResearchBoxInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  typeForSorting: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<FolderRef>;
  dataset?: Maybe<DatasetRef>;
  parentIds: Scalars['String'];
  datapoints?: Maybe<Array<DatapointRef>>;
  containsMarkupForNetworkId: Array<Scalars['String']>;
  containsPositiveMarkupForLabelId: Array<Scalars['String']>;
  containsNegativeMarkupForLabelId: Array<Scalars['String']>;
  productionTags: Array<ProductionTagRef>;
};

export type AddResearchBoxPayload = {
  __typename?: 'AddResearchBoxPayload';
  researchBox?: Maybe<Array<Maybe<ResearchBox>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddResearchBoxPayloadResearchBoxArgs = {
  filter?: Maybe<ResearchBoxFilter>;
  order?: Maybe<ResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddSausageAnnotationInput = {
  id: Scalars['String'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabelRef;
  annotationType: AnnotationType;
  markup: SegmentationMarkupRef;
  topLeft: Point2DRef;
  bottomRight: Point2DRef;
  points: Array<Point2DRef>;
  radius: Scalars['Int'];
  dataURL: Scalars['String'];
};

export type AddSausageAnnotationPayload = {
  __typename?: 'AddSausageAnnotationPayload';
  sausageAnnotation?: Maybe<Array<Maybe<SausageAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddSausageAnnotationPayloadSausageAnnotationArgs = {
  filter?: Maybe<SausageAnnotationFilter>;
  order?: Maybe<SausageAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddScalarAnnotationInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  label: MarkupLabelRef;
  value: Scalars['Float'];
};

export type AddScalarAnnotationPayload = {
  __typename?: 'AddScalarAnnotationPayload';
  scalarAnnotation?: Maybe<Array<Maybe<ScalarAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddScalarAnnotationPayloadScalarAnnotationArgs = {
  filter?: Maybe<ScalarAnnotationFilter>;
  order?: Maybe<ScalarAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddScalarPredictionInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  label: MarkupLabelRef;
  probability: Scalars['Float'];
};

export type AddScalarPredictionPayload = {
  __typename?: 'AddScalarPredictionPayload';
  scalarPrediction?: Maybe<Array<Maybe<ScalarPrediction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddScalarPredictionPayloadScalarPredictionArgs = {
  filter?: Maybe<ScalarPredictionFilter>;
  order?: Maybe<ScalarPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddSegmentationMap2DInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  thumbnail: Scalars['String'];
  label: MarkupLabelRef;
};

export type AddSegmentationMap2DPayload = {
  __typename?: 'AddSegmentationMap2DPayload';
  segmentationMap2D?: Maybe<Array<Maybe<SegmentationMap2D>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddSegmentationMap2DPayloadSegmentationMap2DArgs = {
  filter?: Maybe<SegmentationMap2DFilter>;
  order?: Maybe<SegmentationMap2DOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddSegmentationMarkupInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<ImageRef>;
  subset: SubsetType;
  network: NetworkModuleRef;
  networkId: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  annotations: Array<SegmentationImageAnnotationRef>;
  segmentationMaps: Array<SegmentationMap2DRef>;
};

export type AddSegmentationMarkupPayload = {
  __typename?: 'AddSegmentationMarkupPayload';
  segmentationMarkup?: Maybe<Array<Maybe<SegmentationMarkup>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddSegmentationMarkupPayloadSegmentationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
  order?: Maybe<SegmentationMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddSegmentationPredictionInput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<ImageRef>;
  network: NetworkModuleRef;
  networkId: Scalars['String'];
  objects: Array<SegmentationPredictionObjectRef>;
  segmentationMaps: Array<SegmentationMap2DRef>;
};

export type AddSegmentationPredictionObjectInput = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  label: MarkupLabelRef;
  probability: Scalars['Float'];
  topLeft: Point2DRef;
  bottomRight: Point2DRef;
  dataURL: Scalars['String'];
};

export type AddSegmentationPredictionObjectPayload = {
  __typename?: 'AddSegmentationPredictionObjectPayload';
  segmentationPredictionObject?: Maybe<Array<Maybe<SegmentationPredictionObject>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddSegmentationPredictionObjectPayloadSegmentationPredictionObjectArgs = {
  filter?: Maybe<SegmentationPredictionObjectFilter>;
  order?: Maybe<SegmentationPredictionObjectOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddSegmentationPredictionPayload = {
  __typename?: 'AddSegmentationPredictionPayload';
  segmentationPrediction?: Maybe<Array<Maybe<SegmentationPrediction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddSegmentationPredictionPayloadSegmentationPredictionArgs = {
  filter?: Maybe<SegmentationPredictionFilter>;
  order?: Maybe<SegmentationPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddUserInput = {
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
  lastSyncedAt: Scalars['DateTime'];
  password: Scalars['String'];
  email: Scalars['String'];
  role: Role;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  allowedFeatures: Array<UiFeature>;
  blockedFeatures: Array<UiFeature>;
  vendor?: Maybe<UserRef>;
  subAccounts: Array<UserRef>;
  datasets: Array<DatasetRef>;
  networkKey: Scalars['String'];
  trainingQuota: Scalars['Int'];
  inferenceQuota: Scalars['Int'];
};

export type AddUserPayload = {
  __typename?: 'AddUserPayload';
  user?: Maybe<Array<Maybe<User>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type AddUserPayloadUserArgs = {
  filter?: Maybe<UserFilter>;
  order?: Maybe<UserOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum AnnotationType {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
  Ignore = 'IGNORE'
}

export type AppState = {
  __typename?: 'AppState';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  migrations: Array<Migration>;
  migrationsAggregate?: Maybe<MigrationAggregateResult>;
};


export type AppStateMigrationsArgs = {
  filter?: Maybe<MigrationFilter>;
  order?: Maybe<MigrationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type AppStateMigrationsAggregateArgs = {
  filter?: Maybe<MigrationFilter>;
};

export type AppStateAggregateResult = {
  __typename?: 'AppStateAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
};

export type AppStateFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<AppStateHasFilter>>>;
  and?: Maybe<Array<Maybe<AppStateFilter>>>;
  or?: Maybe<Array<Maybe<AppStateFilter>>>;
  not?: Maybe<AppStateFilter>;
};

export enum AppStateHasFilter {
  Id = 'id',
  Migrations = 'migrations'
}

export type AppStateOrder = {
  asc?: Maybe<AppStateOrderable>;
  desc?: Maybe<AppStateOrderable>;
  then?: Maybe<AppStateOrder>;
};

export enum AppStateOrderable {
  Id = 'id'
}

export type AppStatePatch = {
  migrations?: Maybe<Array<MigrationRef>>;
};

export type AppStateRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  migrations?: Maybe<Array<MigrationRef>>;
};

export type AuthRule = {
  and?: Maybe<Array<Maybe<AuthRule>>>;
  or?: Maybe<Array<Maybe<AuthRule>>>;
  not?: Maybe<AuthRule>;
  rule?: Maybe<Scalars['String']>;
};

export enum BackendReachable {
  Yes = 'YES',
  No = 'NO',
  Undecided = 'UNDECIDED'
}

export type Backup = {
  __typename?: 'Backup';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type Binary = Cuid & Ownable & Timestamped & {
  __typename?: 'Binary';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  url: Scalars['String'];
};

export type BinaryAggregateResult = {
  __typename?: 'BinaryAggregateResult';
  count?: Maybe<Scalars['Int']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
};

export type BinaryFilter = {
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<BinaryHasFilter>>>;
  and?: Maybe<Array<Maybe<BinaryFilter>>>;
  or?: Maybe<Array<Maybe<BinaryFilter>>>;
  not?: Maybe<BinaryFilter>;
};

export enum BinaryHasFilter {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Id = 'id'
}

export type BinaryOrder = {
  asc?: Maybe<BinaryOrderable>;
  desc?: Maybe<BinaryOrderable>;
  then?: Maybe<BinaryOrder>;
};

export enum BinaryOrderable {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Id = 'id'
}

export type BinaryPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type BinaryRef = {
  rootID?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
};

export type BoundingBoxAnnotation = Cuid & Ownable & {
  __typename?: 'BoundingBoxAnnotation';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  label: MarkupLabel;
  topLeft: Point2D;
  bottomRight: Point2D;
  annotationType: AnnotationType;
};


export type BoundingBoxAnnotationLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type BoundingBoxAnnotationTopLeftArgs = {
  filter?: Maybe<Point2DFilter>;
};


export type BoundingBoxAnnotationBottomRightArgs = {
  filter?: Maybe<Point2DFilter>;
};

export type BoundingBoxAnnotationAggregateResult = {
  __typename?: 'BoundingBoxAnnotationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
};

export type BoundingBoxAnnotationFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<BoundingBoxAnnotationHasFilter>>>;
  and?: Maybe<Array<Maybe<BoundingBoxAnnotationFilter>>>;
  or?: Maybe<Array<Maybe<BoundingBoxAnnotationFilter>>>;
  not?: Maybe<BoundingBoxAnnotationFilter>;
};

export enum BoundingBoxAnnotationHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  Id = 'id',
  Label = 'label',
  TopLeft = 'topLeft',
  BottomRight = 'bottomRight',
  AnnotationType = 'annotationType'
}

export type BoundingBoxAnnotationOrder = {
  asc?: Maybe<BoundingBoxAnnotationOrderable>;
  desc?: Maybe<BoundingBoxAnnotationOrderable>;
  then?: Maybe<BoundingBoxAnnotationOrder>;
};

export enum BoundingBoxAnnotationOrderable {
  Owner = 'owner',
  Id = 'id'
}

export type BoundingBoxAnnotationPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  annotationType?: Maybe<AnnotationType>;
};

export type BoundingBoxAnnotationRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<MarkupLabelRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  annotationType?: Maybe<AnnotationType>;
};

export type BoundingBoxPrediction = Cuid & Ownable & {
  __typename?: 'BoundingBoxPrediction';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  label: MarkupLabel;
  topLeft: Point2D;
  bottomRight: Point2D;
  probability: Scalars['Float'];
};


export type BoundingBoxPredictionLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type BoundingBoxPredictionTopLeftArgs = {
  filter?: Maybe<Point2DFilter>;
};


export type BoundingBoxPredictionBottomRightArgs = {
  filter?: Maybe<Point2DFilter>;
};

export type BoundingBoxPredictionAggregateResult = {
  __typename?: 'BoundingBoxPredictionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  probabilityMin?: Maybe<Scalars['Float']>;
  probabilityMax?: Maybe<Scalars['Float']>;
  probabilitySum?: Maybe<Scalars['Float']>;
  probabilityAvg?: Maybe<Scalars['Float']>;
};

export type BoundingBoxPredictionFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<BoundingBoxPredictionHasFilter>>>;
  and?: Maybe<Array<Maybe<BoundingBoxPredictionFilter>>>;
  or?: Maybe<Array<Maybe<BoundingBoxPredictionFilter>>>;
  not?: Maybe<BoundingBoxPredictionFilter>;
};

export enum BoundingBoxPredictionHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  Id = 'id',
  Label = 'label',
  TopLeft = 'topLeft',
  BottomRight = 'bottomRight',
  Probability = 'probability'
}

export type BoundingBoxPredictionOrder = {
  asc?: Maybe<BoundingBoxPredictionOrderable>;
  desc?: Maybe<BoundingBoxPredictionOrderable>;
  then?: Maybe<BoundingBoxPredictionOrder>;
};

export enum BoundingBoxPredictionOrderable {
  Owner = 'owner',
  Id = 'id',
  Probability = 'probability'
}

export type BoundingBoxPredictionPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  probability?: Maybe<Scalars['Float']>;
};

export type BoundingBoxPredictionRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<MarkupLabelRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  probability?: Maybe<Scalars['Float']>;
};

export type CcCheckerResult = Datapoint & {
  __typename?: 'CCCheckerResult';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy: PipelineModuleDatapointOutput;
  researchBox: ResearchBox;
  groups: Scalars['String'];
  isOkay: Scalars['Boolean'];
};


export type CcCheckerResultProducedByArgs = {
  filter?: Maybe<PipelineModuleDatapointOutputFilter>;
};


export type CcCheckerResultResearchBoxArgs = {
  filter?: Maybe<ResearchBoxFilter>;
};

export type CcCheckerResultAggregateResult = {
  __typename?: 'CCCheckerResultAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  groupsMin?: Maybe<Scalars['String']>;
  groupsMax?: Maybe<Scalars['String']>;
};

export type CcCheckerResultFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<CcCheckerResultHasFilter>>>;
  and?: Maybe<Array<Maybe<CcCheckerResultFilter>>>;
  or?: Maybe<Array<Maybe<CcCheckerResultFilter>>>;
  not?: Maybe<CcCheckerResultFilter>;
};

export enum CcCheckerResultHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  ProducedBy = 'producedBy',
  ResearchBox = 'researchBox',
  Groups = 'groups',
  IsOkay = 'isOkay'
}

export type CcCheckerResultOrder = {
  asc?: Maybe<CcCheckerResultOrderable>;
  desc?: Maybe<CcCheckerResultOrderable>;
  then?: Maybe<CcCheckerResultOrder>;
};

export enum CcCheckerResultOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Groups = 'groups'
}

export type CcCheckerResultPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy?: Maybe<PipelineModuleDatapointOutputRef>;
  researchBox?: Maybe<ResearchBoxRef>;
  groups?: Maybe<Scalars['String']>;
  isOkay?: Maybe<Scalars['Boolean']>;
};

export type CcCheckerResultRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy?: Maybe<PipelineModuleDatapointOutputRef>;
  researchBox?: Maybe<ResearchBoxRef>;
  groups?: Maybe<Scalars['String']>;
  isOkay?: Maybe<Scalars['Boolean']>;
};

export type CircleAnnotation = SegmentationImageAnnotation & {
  __typename?: 'CircleAnnotation';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabel;
  annotationType: AnnotationType;
  markup: SegmentationMarkup;
  center: Point2D;
  radius: Scalars['Int'];
};


export type CircleAnnotationLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type CircleAnnotationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
};


export type CircleAnnotationCenterArgs = {
  filter?: Maybe<Point2DFilter>;
};

export type CircleAnnotationAggregateResult = {
  __typename?: 'CircleAnnotationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  radiusMin?: Maybe<Scalars['Int']>;
  radiusMax?: Maybe<Scalars['Int']>;
  radiusSum?: Maybe<Scalars['Int']>;
  radiusAvg?: Maybe<Scalars['Float']>;
};

export type CircleAnnotationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<CircleAnnotationHasFilter>>>;
  and?: Maybe<Array<Maybe<CircleAnnotationFilter>>>;
  or?: Maybe<Array<Maybe<CircleAnnotationFilter>>>;
  not?: Maybe<CircleAnnotationFilter>;
};

export enum CircleAnnotationHasFilter {
  Id = 'id',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Label = 'label',
  AnnotationType = 'annotationType',
  Markup = 'markup',
  Center = 'center',
  Radius = 'radius'
}

export type CircleAnnotationOrder = {
  asc?: Maybe<CircleAnnotationOrderable>;
  desc?: Maybe<CircleAnnotationOrderable>;
  then?: Maybe<CircleAnnotationOrder>;
};

export enum CircleAnnotationOrderable {
  Id = 'id',
  Owner = 'owner',
  Radius = 'radius'
}

export type CircleAnnotationPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  center?: Maybe<Point2DRef>;
  radius?: Maybe<Scalars['Int']>;
};

export type CircleAnnotationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  center?: Maybe<Point2DRef>;
  radius?: Maybe<Scalars['Int']>;
};

export type ClassificationMarkup = ImageMarkup & {
  __typename?: 'ClassificationMarkup';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<Image>;
  subset: SubsetType;
  network: NetworkModule;
  networkId: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  annotations: Array<ScalarAnnotation>;
  imagesAggregate?: Maybe<ImageAggregateResult>;
  annotationsAggregate?: Maybe<ScalarAnnotationAggregateResult>;
};


export type ClassificationMarkupImagesArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ClassificationMarkupNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};


export type ClassificationMarkupAnnotationsArgs = {
  filter?: Maybe<ScalarAnnotationFilter>;
  order?: Maybe<ScalarAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ClassificationMarkupImagesAggregateArgs = {
  filter?: Maybe<ImageFilter>;
};


export type ClassificationMarkupAnnotationsAggregateArgs = {
  filter?: Maybe<ScalarAnnotationFilter>;
};

export type ClassificationMarkupAggregateResult = {
  __typename?: 'ClassificationMarkupAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  networkIdMin?: Maybe<Scalars['String']>;
  networkIdMax?: Maybe<Scalars['String']>;
  widthMin?: Maybe<Scalars['Int']>;
  widthMax?: Maybe<Scalars['Int']>;
  widthSum?: Maybe<Scalars['Int']>;
  widthAvg?: Maybe<Scalars['Float']>;
  heightMin?: Maybe<Scalars['Int']>;
  heightMax?: Maybe<Scalars['Int']>;
  heightSum?: Maybe<Scalars['Int']>;
  heightAvg?: Maybe<Scalars['Float']>;
};

export type ClassificationMarkupFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  subset?: Maybe<SubsetType_Hash>;
  networkId?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<ClassificationMarkupHasFilter>>>;
  and?: Maybe<Array<Maybe<ClassificationMarkupFilter>>>;
  or?: Maybe<Array<Maybe<ClassificationMarkupFilter>>>;
  not?: Maybe<ClassificationMarkupFilter>;
};

export enum ClassificationMarkupHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Images = 'images',
  Subset = 'subset',
  Network = 'network',
  NetworkId = 'networkId',
  Width = 'width',
  Height = 'height',
  Annotations = 'annotations'
}

export type ClassificationMarkupOrder = {
  asc?: Maybe<ClassificationMarkupOrderable>;
  desc?: Maybe<ClassificationMarkupOrderable>;
  then?: Maybe<ClassificationMarkupOrder>;
};

export enum ClassificationMarkupOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  NetworkId = 'networkId',
  Width = 'width',
  Height = 'height'
}

export type ClassificationMarkupPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  subset?: Maybe<SubsetType>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  annotations?: Maybe<Array<ScalarAnnotationRef>>;
};

export type ClassificationMarkupRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  subset?: Maybe<SubsetType>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  annotations?: Maybe<Array<ScalarAnnotationRef>>;
};

export type ClassificationPrediction = ImagePrediction & {
  __typename?: 'ClassificationPrediction';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<Image>;
  network: NetworkModule;
  networkId: Scalars['String'];
  predictions: Array<ScalarPrediction>;
  imagesAggregate?: Maybe<ImageAggregateResult>;
  predictionsAggregate?: Maybe<ScalarPredictionAggregateResult>;
};


export type ClassificationPredictionImagesArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ClassificationPredictionNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};


export type ClassificationPredictionPredictionsArgs = {
  filter?: Maybe<ScalarPredictionFilter>;
  order?: Maybe<ScalarPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ClassificationPredictionImagesAggregateArgs = {
  filter?: Maybe<ImageFilter>;
};


export type ClassificationPredictionPredictionsAggregateArgs = {
  filter?: Maybe<ScalarPredictionFilter>;
};

export type ClassificationPredictionAggregateResult = {
  __typename?: 'ClassificationPredictionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  networkIdMin?: Maybe<Scalars['String']>;
  networkIdMax?: Maybe<Scalars['String']>;
};

export type ClassificationPredictionFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  networkId?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<ClassificationPredictionHasFilter>>>;
  and?: Maybe<Array<Maybe<ClassificationPredictionFilter>>>;
  or?: Maybe<Array<Maybe<ClassificationPredictionFilter>>>;
  not?: Maybe<ClassificationPredictionFilter>;
};

export enum ClassificationPredictionHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Images = 'images',
  Network = 'network',
  NetworkId = 'networkId',
  Predictions = 'predictions'
}

export type ClassificationPredictionOrder = {
  asc?: Maybe<ClassificationPredictionOrderable>;
  desc?: Maybe<ClassificationPredictionOrderable>;
  then?: Maybe<ClassificationPredictionOrder>;
};

export enum ClassificationPredictionOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  NetworkId = 'networkId'
}

export type ClassificationPredictionPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  predictions?: Maybe<Array<ScalarPredictionRef>>;
};

export type ClassificationPredictionRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  predictions?: Maybe<Array<ScalarPredictionRef>>;
};

export type ContainsFilter = {
  point?: Maybe<PointRef>;
  polygon?: Maybe<PolygonRef>;
};

export type Cuid = {
  id: Scalars['String'];
  rootID: Scalars['ID'];
};

export type CuidAggregateResult = {
  __typename?: 'CuidAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
};

export type CuidFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<CuidHasFilter>>>;
  and?: Maybe<Array<Maybe<CuidFilter>>>;
  or?: Maybe<Array<Maybe<CuidFilter>>>;
  not?: Maybe<CuidFilter>;
};

export enum CuidHasFilter {
  Id = 'id'
}

export type CuidOrder = {
  asc?: Maybe<CuidOrderable>;
  desc?: Maybe<CuidOrderable>;
  then?: Maybe<CuidOrder>;
};

export enum CuidOrderable {
  Id = 'id'
}

export type CuidRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export type CustomHttp = {
  url: Scalars['String'];
  method: HttpMethod;
  body?: Maybe<Scalars['String']>;
  graphql?: Maybe<Scalars['String']>;
  mode?: Maybe<Mode>;
  forwardHeaders?: Maybe<Array<Scalars['String']>>;
  secretHeaders?: Maybe<Array<Scalars['String']>>;
  introspectionHeaders?: Maybe<Array<Scalars['String']>>;
  skipIntrospection?: Maybe<Scalars['Boolean']>;
};

export type Datapoint = {
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy: PipelineModuleDatapointOutput;
  researchBox: ResearchBox;
};


export type DatapointProducedByArgs = {
  filter?: Maybe<PipelineModuleDatapointOutputFilter>;
};


export type DatapointResearchBoxArgs = {
  filter?: Maybe<ResearchBoxFilter>;
};

export type DatapointAggregateResult = {
  __typename?: 'DatapointAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
};

export type DatapointFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<DatapointHasFilter>>>;
  and?: Maybe<Array<Maybe<DatapointFilter>>>;
  or?: Maybe<Array<Maybe<DatapointFilter>>>;
  not?: Maybe<DatapointFilter>;
};

export enum DatapointHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  ProducedBy = 'producedBy',
  ResearchBox = 'researchBox'
}

export type DatapointOrder = {
  asc?: Maybe<DatapointOrderable>;
  desc?: Maybe<DatapointOrderable>;
  then?: Maybe<DatapointOrder>;
};

export enum DatapointOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner'
}

export type DatapointPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy?: Maybe<PipelineModuleDatapointOutputRef>;
  researchBox?: Maybe<ResearchBoxRef>;
};

export type DatapointRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export type Dataset = Cuid & Timestamped & Ownable & {
  __typename?: 'Dataset';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  rootID: Scalars['ID'];
  user: User;
  name: Scalars['String'];
  description: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  pipeline?: Maybe<Pipeline>;
  objects: Array<FolderOrResearchBox>;
  aggregateImageResolutions: Array<WidthHeight>;
  aggregateDatapointTypes: Array<Scalars['String']>;
  objectsAggregate?: Maybe<FolderOrResearchBoxAggregateResult>;
};


export type DatasetUserArgs = {
  filter?: Maybe<UserFilter>;
};


export type DatasetPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type DatasetObjectsArgs = {
  filter?: Maybe<FolderOrResearchBoxFilter>;
  order?: Maybe<FolderOrResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type DatasetObjectsAggregateArgs = {
  filter?: Maybe<FolderOrResearchBoxFilter>;
};

export type DatasetAggregateResult = {
  __typename?: 'DatasetAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  thumbnailMin?: Maybe<Scalars['String']>;
  thumbnailMax?: Maybe<Scalars['String']>;
};

export type DatasetFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  has?: Maybe<Array<Maybe<DatasetHasFilter>>>;
  and?: Maybe<Array<Maybe<DatasetFilter>>>;
  or?: Maybe<Array<Maybe<DatasetFilter>>>;
  not?: Maybe<DatasetFilter>;
};

export enum DatasetHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  User = 'user',
  Name = 'name',
  Description = 'description',
  Thumbnail = 'thumbnail',
  Pipeline = 'pipeline',
  Objects = 'objects'
}

export type DatasetOrder = {
  asc?: Maybe<DatasetOrderable>;
  desc?: Maybe<DatasetOrderable>;
  then?: Maybe<DatasetOrder>;
};

export enum DatasetOrderable {
  Owner = 'owner',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Name = 'name',
  Description = 'description',
  Thumbnail = 'thumbnail'
}

export type DatasetPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserRef>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  pipeline?: Maybe<PipelineRef>;
  objects?: Maybe<Array<FolderOrResearchBoxRef>>;
};

export type DatasetRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  user?: Maybe<UserRef>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  pipeline?: Maybe<PipelineRef>;
  objects?: Maybe<Array<FolderOrResearchBoxRef>>;
};

export enum DataSource {
  User = 'USER',
  Pipeline = 'PIPELINE'
}

export enum DataType {
  Image = 'Image',
  MagneticFieldScan = 'MagneticFieldScan',
  SegmentationMarkup = 'SegmentationMarkup',
  ObjectDetectionMarkup = 'ObjectDetectionMarkup',
  ClassificationMarkup = 'ClassificationMarkup',
  Metric = 'Metric',
  ImageSegmentationNetworkSnapshotPytorch = 'ImageSegmentationNetworkSnapshotPytorch',
  ImageClassificationNetworkSnapshotPytorch = 'ImageClassificationNetworkSnapshotPytorch',
  ImageAnomalyDetectionNetworkSnapshotPytorch = 'ImageAnomalyDetectionNetworkSnapshotPytorch',
  ImageSegmentationNetworkSnapshotOnnx = 'ImageSegmentationNetworkSnapshotOnnx',
  ImageClassificationNetworkSnapshotOnnx = 'ImageClassificationNetworkSnapshotOnnx',
  ImageAnomalyDetectionNetworkSnapshotOnnx = 'ImageAnomalyDetectionNetworkSnapshotOnnx',
  CcCheckerResult = 'CCCheckerResult',
  ImageObjectDetectionNetworkSnapshotPytorch = 'ImageObjectDetectionNetworkSnapshotPytorch',
  ImageObjectDetectionNetworkSnapshotOnnx = 'ImageObjectDetectionNetworkSnapshotOnnx'
}

export type DataType_Hash = {
  eq?: Maybe<DataType>;
  in?: Maybe<Array<Maybe<DataType>>>;
};


export type DateTimeFilter = {
  eq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  le?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  ge?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  between?: Maybe<DateTimeRange>;
};

export type DateTimeRange = {
  min: Scalars['DateTime'];
  max: Scalars['DateTime'];
};

export type DeleteAppStatePayload = {
  __typename?: 'DeleteAppStatePayload';
  appState?: Maybe<Array<Maybe<AppState>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteAppStatePayloadAppStateArgs = {
  filter?: Maybe<AppStateFilter>;
  order?: Maybe<AppStateOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteBinaryPayload = {
  __typename?: 'DeleteBinaryPayload';
  binary?: Maybe<Array<Maybe<Binary>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteBinaryPayloadBinaryArgs = {
  filter?: Maybe<BinaryFilter>;
  order?: Maybe<BinaryOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteBoundingBoxAnnotationPayload = {
  __typename?: 'DeleteBoundingBoxAnnotationPayload';
  boundingBoxAnnotation?: Maybe<Array<Maybe<BoundingBoxAnnotation>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteBoundingBoxAnnotationPayloadBoundingBoxAnnotationArgs = {
  filter?: Maybe<BoundingBoxAnnotationFilter>;
  order?: Maybe<BoundingBoxAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteBoundingBoxPredictionPayload = {
  __typename?: 'DeleteBoundingBoxPredictionPayload';
  boundingBoxPrediction?: Maybe<Array<Maybe<BoundingBoxPrediction>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteBoundingBoxPredictionPayloadBoundingBoxPredictionArgs = {
  filter?: Maybe<BoundingBoxPredictionFilter>;
  order?: Maybe<BoundingBoxPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteCcCheckerResultPayload = {
  __typename?: 'DeleteCCCheckerResultPayload';
  cCCheckerResult?: Maybe<Array<Maybe<CcCheckerResult>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteCcCheckerResultPayloadCcCheckerResultArgs = {
  filter?: Maybe<CcCheckerResultFilter>;
  order?: Maybe<CcCheckerResultOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteCircleAnnotationPayload = {
  __typename?: 'DeleteCircleAnnotationPayload';
  circleAnnotation?: Maybe<Array<Maybe<CircleAnnotation>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteCircleAnnotationPayloadCircleAnnotationArgs = {
  filter?: Maybe<CircleAnnotationFilter>;
  order?: Maybe<CircleAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteClassificationMarkupPayload = {
  __typename?: 'DeleteClassificationMarkupPayload';
  classificationMarkup?: Maybe<Array<Maybe<ClassificationMarkup>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteClassificationMarkupPayloadClassificationMarkupArgs = {
  filter?: Maybe<ClassificationMarkupFilter>;
  order?: Maybe<ClassificationMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteClassificationPredictionPayload = {
  __typename?: 'DeleteClassificationPredictionPayload';
  classificationPrediction?: Maybe<Array<Maybe<ClassificationPrediction>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteClassificationPredictionPayloadClassificationPredictionArgs = {
  filter?: Maybe<ClassificationPredictionFilter>;
  order?: Maybe<ClassificationPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteCuidPayload = {
  __typename?: 'DeleteCuidPayload';
  cuid?: Maybe<Array<Maybe<Cuid>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteCuidPayloadCuidArgs = {
  filter?: Maybe<CuidFilter>;
  order?: Maybe<CuidOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteDatapointPayload = {
  __typename?: 'DeleteDatapointPayload';
  datapoint?: Maybe<Array<Maybe<Datapoint>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteDatapointPayloadDatapointArgs = {
  filter?: Maybe<DatapointFilter>;
  order?: Maybe<DatapointOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteDatasetPayload = {
  __typename?: 'DeleteDatasetPayload';
  dataset?: Maybe<Array<Maybe<Dataset>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteDatasetPayloadDatasetArgs = {
  filter?: Maybe<DatasetFilter>;
  order?: Maybe<DatasetOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteDenkboxPayload = {
  __typename?: 'DeleteDenkboxPayload';
  denkbox?: Maybe<Array<Maybe<Denkbox>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteDenkboxPayloadDenkboxArgs = {
  filter?: Maybe<DenkboxFilter>;
  order?: Maybe<DenkboxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteDenkjobPayload = {
  __typename?: 'DeleteDenkjobPayload';
  denkjob?: Maybe<Array<Maybe<Denkjob>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteDenkjobPayloadDenkjobArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteDenktesterNotificationPayload = {
  __typename?: 'DeleteDenktesterNotificationPayload';
  denktesterNotification?: Maybe<Array<Maybe<DenktesterNotification>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteDenktesterNotificationPayloadDenktesterNotificationArgs = {
  filter?: Maybe<DenktesterNotificationFilter>;
  order?: Maybe<DenktesterNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteEncryptedBinaryPayload = {
  __typename?: 'DeleteEncryptedBinaryPayload';
  encryptedBinary?: Maybe<Array<Maybe<EncryptedBinary>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteEncryptedBinaryPayloadEncryptedBinaryArgs = {
  filter?: Maybe<EncryptedBinaryFilter>;
  order?: Maybe<EncryptedBinaryOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteFolderOrResearchBoxPayload = {
  __typename?: 'DeleteFolderOrResearchBoxPayload';
  folderOrResearchBox?: Maybe<Array<Maybe<FolderOrResearchBox>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteFolderOrResearchBoxPayloadFolderOrResearchBoxArgs = {
  filter?: Maybe<FolderOrResearchBoxFilter>;
  order?: Maybe<FolderOrResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteFolderPayload = {
  __typename?: 'DeleteFolderPayload';
  folder?: Maybe<Array<Maybe<Folder>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteFolderPayloadFolderArgs = {
  filter?: Maybe<FolderFilter>;
  order?: Maybe<FolderOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteGpuPayload = {
  __typename?: 'DeleteGpuPayload';
  gpu?: Maybe<Array<Maybe<Gpu>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteGpuPayloadGpuArgs = {
  filter?: Maybe<GpuFilter>;
  order?: Maybe<GpuOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteImageMarkupPayload = {
  __typename?: 'DeleteImageMarkupPayload';
  imageMarkup?: Maybe<Array<Maybe<ImageMarkup>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteImageMarkupPayloadImageMarkupArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteImageMetadataFieldPayload = {
  __typename?: 'DeleteImageMetadataFieldPayload';
  imageMetadataField?: Maybe<Array<Maybe<ImageMetadataField>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteImageMetadataFieldPayloadImageMetadataFieldArgs = {
  filter?: Maybe<ImageMetadataFieldFilter>;
  order?: Maybe<ImageMetadataFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteImagePayload = {
  __typename?: 'DeleteImagePayload';
  image?: Maybe<Array<Maybe<Image>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteImagePayloadImageArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteImagePredictionPayload = {
  __typename?: 'DeleteImagePredictionPayload';
  imagePrediction?: Maybe<Array<Maybe<ImagePrediction>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteImagePredictionPayloadImagePredictionArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteImageResolutionPayload = {
  __typename?: 'DeleteImageResolutionPayload';
  imageResolution?: Maybe<Array<Maybe<ImageResolution>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteImageResolutionPayloadImageResolutionArgs = {
  filter?: Maybe<ImageResolutionFilter>;
  order?: Maybe<ImageResolutionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteInstanceSegmentationMarkupPayload = {
  __typename?: 'DeleteInstanceSegmentationMarkupPayload';
  instanceSegmentationMarkup?: Maybe<Array<Maybe<InstanceSegmentationMarkup>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteInstanceSegmentationMarkupPayloadInstanceSegmentationMarkupArgs = {
  filter?: Maybe<InstanceSegmentationMarkupFilter>;
  order?: Maybe<InstanceSegmentationMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteInstanceSegmentationPredictionPayload = {
  __typename?: 'DeleteInstanceSegmentationPredictionPayload';
  instanceSegmentationPrediction?: Maybe<Array<Maybe<InstanceSegmentationPrediction>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteInstanceSegmentationPredictionPayloadInstanceSegmentationPredictionArgs = {
  filter?: Maybe<InstanceSegmentationPredictionFilter>;
  order?: Maybe<InstanceSegmentationPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteMagicwandAnnotationPayload = {
  __typename?: 'DeleteMagicwandAnnotationPayload';
  magicwandAnnotation?: Maybe<Array<Maybe<MagicwandAnnotation>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteMagicwandAnnotationPayloadMagicwandAnnotationArgs = {
  filter?: Maybe<MagicwandAnnotationFilter>;
  order?: Maybe<MagicwandAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteMagneticFieldScanPayload = {
  __typename?: 'DeleteMagneticFieldScanPayload';
  magneticFieldScan?: Maybe<Array<Maybe<MagneticFieldScan>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteMagneticFieldScanPayloadMagneticFieldScanArgs = {
  filter?: Maybe<MagneticFieldScanFilter>;
  order?: Maybe<MagneticFieldScanOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteMaintenanceNotificationPayload = {
  __typename?: 'DeleteMaintenanceNotificationPayload';
  maintenanceNotification?: Maybe<Array<Maybe<MaintenanceNotification>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteMaintenanceNotificationPayloadMaintenanceNotificationArgs = {
  filter?: Maybe<MaintenanceNotificationFilter>;
  order?: Maybe<MaintenanceNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteMarkupLabelPayload = {
  __typename?: 'DeleteMarkupLabelPayload';
  markupLabel?: Maybe<Array<Maybe<MarkupLabel>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteMarkupLabelPayloadMarkupLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
  order?: Maybe<MarkupLabelOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteMetricPayload = {
  __typename?: 'DeleteMetricPayload';
  metric?: Maybe<Array<Maybe<Metric>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteMetricPayloadMetricArgs = {
  filter?: Maybe<MetricFilter>;
  order?: Maybe<MetricOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteMigrationPayload = {
  __typename?: 'DeleteMigrationPayload';
  migration?: Maybe<Array<Maybe<Migration>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteMigrationPayloadMigrationArgs = {
  filter?: Maybe<MigrationFilter>;
  order?: Maybe<MigrationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteModuleJobRequestPayload = {
  __typename?: 'DeleteModuleJobRequestPayload';
  moduleJobRequest?: Maybe<Array<Maybe<ModuleJobRequest>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteModuleJobRequestPayloadModuleJobRequestArgs = {
  filter?: Maybe<ModuleJobRequestFilter>;
  order?: Maybe<ModuleJobRequestOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteNetworkConfigPayload = {
  __typename?: 'DeleteNetworkConfigPayload';
  networkConfig?: Maybe<Array<Maybe<NetworkConfig>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteNetworkConfigPayloadNetworkConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
  order?: Maybe<NetworkConfigOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteNetworkModulePayload = {
  __typename?: 'DeleteNetworkModulePayload';
  networkModule?: Maybe<Array<Maybe<NetworkModule>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteNetworkModulePayloadNetworkModuleArgs = {
  filter?: Maybe<NetworkModuleFilter>;
  order?: Maybe<NetworkModuleOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteNetworkSnapshotPayload = {
  __typename?: 'DeleteNetworkSnapshotPayload';
  networkSnapshot?: Maybe<Array<Maybe<NetworkSnapshot>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteNetworkSnapshotPayloadNetworkSnapshotArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
  order?: Maybe<NetworkSnapshotOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteNotificationPayload = {
  __typename?: 'DeleteNotificationPayload';
  notification?: Maybe<Array<Maybe<Notification>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteNotificationPayloadNotificationArgs = {
  filter?: Maybe<NotificationFilter>;
  order?: Maybe<NotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteObjectDetectionMarkupPayload = {
  __typename?: 'DeleteObjectDetectionMarkupPayload';
  objectDetectionMarkup?: Maybe<Array<Maybe<ObjectDetectionMarkup>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteObjectDetectionMarkupPayloadObjectDetectionMarkupArgs = {
  filter?: Maybe<ObjectDetectionMarkupFilter>;
  order?: Maybe<ObjectDetectionMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteObjectDetectionPredictionPayload = {
  __typename?: 'DeleteObjectDetectionPredictionPayload';
  objectDetectionPrediction?: Maybe<Array<Maybe<ObjectDetectionPrediction>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteObjectDetectionPredictionPayloadObjectDetectionPredictionArgs = {
  filter?: Maybe<ObjectDetectionPredictionFilter>;
  order?: Maybe<ObjectDetectionPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteOneOffNotificationPayload = {
  __typename?: 'DeleteOneOffNotificationPayload';
  oneOffNotification?: Maybe<Array<Maybe<OneOffNotification>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteOneOffNotificationPayloadOneOffNotificationArgs = {
  filter?: Maybe<OneOffNotificationFilter>;
  order?: Maybe<OneOffNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteOwnablePayload = {
  __typename?: 'DeleteOwnablePayload';
  ownable?: Maybe<Array<Maybe<Ownable>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteOwnablePayloadOwnableArgs = {
  filter?: Maybe<OwnableFilter>;
  order?: Maybe<OwnableOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePenAnnotationPayload = {
  __typename?: 'DeletePenAnnotationPayload';
  penAnnotation?: Maybe<Array<Maybe<PenAnnotation>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePenAnnotationPayloadPenAnnotationArgs = {
  filter?: Maybe<PenAnnotationFilter>;
  order?: Maybe<PenAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleArtifactOutputPayload = {
  __typename?: 'DeletePipelineModuleArtifactOutputPayload';
  pipelineModuleArtifactOutput?: Maybe<Array<Maybe<PipelineModuleArtifactOutput>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleArtifactOutputPayloadPipelineModuleArtifactOutputArgs = {
  filter?: Maybe<PipelineModuleArtifactOutputFilter>;
  order?: Maybe<PipelineModuleArtifactOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleBMobilePayload = {
  __typename?: 'DeletePipelineModuleBMobilePayload';
  pipelineModuleBMobile?: Maybe<Array<Maybe<PipelineModuleBMobile>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleBMobilePayloadPipelineModuleBMobileArgs = {
  filter?: Maybe<PipelineModuleBMobileFilter>;
  order?: Maybe<PipelineModuleBMobileOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleCcCheckerPayload = {
  __typename?: 'DeletePipelineModuleCCCheckerPayload';
  pipelineModuleCCChecker?: Maybe<Array<Maybe<PipelineModuleCcChecker>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleCcCheckerPayloadPipelineModuleCcCheckerArgs = {
  filter?: Maybe<PipelineModuleCcCheckerFilter>;
  order?: Maybe<PipelineModuleCcCheckerOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleContrastEnhancementPayload = {
  __typename?: 'DeletePipelineModuleContrastEnhancementPayload';
  pipelineModuleContrastEnhancement?: Maybe<Array<Maybe<PipelineModuleContrastEnhancement>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleContrastEnhancementPayloadPipelineModuleContrastEnhancementArgs = {
  filter?: Maybe<PipelineModuleContrastEnhancementFilter>;
  order?: Maybe<PipelineModuleContrastEnhancementOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleDatapointOutputPayload = {
  __typename?: 'DeletePipelineModuleDatapointOutputPayload';
  pipelineModuleDatapointOutput?: Maybe<Array<Maybe<PipelineModuleDatapointOutput>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleDatapointOutputPayloadPipelineModuleDatapointOutputArgs = {
  filter?: Maybe<PipelineModuleDatapointOutputFilter>;
  order?: Maybe<PipelineModuleDatapointOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleEdgePayload = {
  __typename?: 'DeletePipelineModuleEdgePayload';
  pipelineModuleEdge?: Maybe<Array<Maybe<PipelineModuleEdge>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleEdgePayloadPipelineModuleEdgeArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
  order?: Maybe<PipelineModuleEdgeOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleInputPayload = {
  __typename?: 'DeletePipelineModuleInputPayload';
  pipelineModuleInput?: Maybe<Array<Maybe<PipelineModuleInput>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleInputPayloadPipelineModuleInputArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleJobRequestCcCheckerPayload = {
  __typename?: 'DeletePipelineModuleJobRequestCCCheckerPayload';
  pipelineModuleJobRequestCCChecker?: Maybe<Array<Maybe<PipelineModuleJobRequestCcChecker>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleJobRequestCcCheckerPayloadPipelineModuleJobRequestCcCheckerArgs = {
  filter?: Maybe<PipelineModuleJobRequestCcCheckerFilter>;
  order?: Maybe<PipelineModuleJobRequestCcCheckerOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleJobRequestContrastEnhancementPayload = {
  __typename?: 'DeletePipelineModuleJobRequestContrastEnhancementPayload';
  pipelineModuleJobRequestContrastEnhancement?: Maybe<Array<Maybe<PipelineModuleJobRequestContrastEnhancement>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleJobRequestContrastEnhancementPayloadPipelineModuleJobRequestContrastEnhancementArgs = {
  filter?: Maybe<PipelineModuleJobRequestContrastEnhancementFilter>;
  order?: Maybe<PipelineModuleJobRequestContrastEnhancementOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleNetworkImageAnomalyDetectionPayload = {
  __typename?: 'DeletePipelineModuleNetworkImageAnomalyDetectionPayload';
  pipelineModuleNetworkImageAnomalyDetection?: Maybe<Array<Maybe<PipelineModuleNetworkImageAnomalyDetection>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleNetworkImageAnomalyDetectionPayloadPipelineModuleNetworkImageAnomalyDetectionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageAnomalyDetectionFilter>;
  order?: Maybe<PipelineModuleNetworkImageAnomalyDetectionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleNetworkImageClassificationPayload = {
  __typename?: 'DeletePipelineModuleNetworkImageClassificationPayload';
  pipelineModuleNetworkImageClassification?: Maybe<Array<Maybe<PipelineModuleNetworkImageClassification>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleNetworkImageClassificationPayloadPipelineModuleNetworkImageClassificationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageClassificationFilter>;
  order?: Maybe<PipelineModuleNetworkImageClassificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleNetworkImageInstanceSegmentationPayload = {
  __typename?: 'DeletePipelineModuleNetworkImageInstanceSegmentationPayload';
  pipelineModuleNetworkImageInstanceSegmentation?: Maybe<Array<Maybe<PipelineModuleNetworkImageInstanceSegmentation>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleNetworkImageInstanceSegmentationPayloadPipelineModuleNetworkImageInstanceSegmentationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageInstanceSegmentationFilter>;
  order?: Maybe<PipelineModuleNetworkImageInstanceSegmentationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleNetworkImageObjectCountingPayload = {
  __typename?: 'DeletePipelineModuleNetworkImageObjectCountingPayload';
  pipelineModuleNetworkImageObjectCounting?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectCounting>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleNetworkImageObjectCountingPayloadPipelineModuleNetworkImageObjectCountingArgs = {
  filter?: Maybe<PipelineModuleNetworkImageObjectCountingFilter>;
  order?: Maybe<PipelineModuleNetworkImageObjectCountingOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleNetworkImageObjectDetectionPayload = {
  __typename?: 'DeletePipelineModuleNetworkImageObjectDetectionPayload';
  pipelineModuleNetworkImageObjectDetection?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectDetection>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleNetworkImageObjectDetectionPayloadPipelineModuleNetworkImageObjectDetectionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageObjectDetectionFilter>;
  order?: Maybe<PipelineModuleNetworkImageObjectDetectionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleNetworkImageOcrPayload = {
  __typename?: 'DeletePipelineModuleNetworkImageOCRPayload';
  pipelineModuleNetworkImageOCR?: Maybe<Array<Maybe<PipelineModuleNetworkImageOcr>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleNetworkImageOcrPayloadPipelineModuleNetworkImageOcrArgs = {
  filter?: Maybe<PipelineModuleNetworkImageOcrFilter>;
  order?: Maybe<PipelineModuleNetworkImageOcrOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleNetworkImageSegmentationPayload = {
  __typename?: 'DeletePipelineModuleNetworkImageSegmentationPayload';
  pipelineModuleNetworkImageSegmentation?: Maybe<Array<Maybe<PipelineModuleNetworkImageSegmentation>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleNetworkImageSegmentationPayloadPipelineModuleNetworkImageSegmentationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageSegmentationFilter>;
  order?: Maybe<PipelineModuleNetworkImageSegmentationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleNetworkImageSuperResolutionPayload = {
  __typename?: 'DeletePipelineModuleNetworkImageSuperResolutionPayload';
  pipelineModuleNetworkImageSuperResolution?: Maybe<Array<Maybe<PipelineModuleNetworkImageSuperResolution>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleNetworkImageSuperResolutionPayloadPipelineModuleNetworkImageSuperResolutionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageSuperResolutionFilter>;
  order?: Maybe<PipelineModuleNetworkImageSuperResolutionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleOutputPayload = {
  __typename?: 'DeletePipelineModuleOutputPayload';
  pipelineModuleOutput?: Maybe<Array<Maybe<PipelineModuleOutput>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleOutputPayloadPipelineModuleOutputArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModulePayload = {
  __typename?: 'DeletePipelineModulePayload';
  pipelineModule?: Maybe<Array<Maybe<PipelineModule>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModulePayloadPipelineModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
  order?: Maybe<PipelineModuleOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelineModuleUploadPayload = {
  __typename?: 'DeletePipelineModuleUploadPayload';
  pipelineModuleUpload?: Maybe<Array<Maybe<PipelineModuleUpload>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelineModuleUploadPayloadPipelineModuleUploadArgs = {
  filter?: Maybe<PipelineModuleUploadFilter>;
  order?: Maybe<PipelineModuleUploadOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePipelinePayload = {
  __typename?: 'DeletePipelinePayload';
  pipeline?: Maybe<Array<Maybe<Pipeline>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePipelinePayloadPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
  order?: Maybe<PipelineOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePixelAnnotationPayload = {
  __typename?: 'DeletePixelAnnotationPayload';
  pixelAnnotation?: Maybe<Array<Maybe<PixelAnnotation>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePixelAnnotationPayloadPixelAnnotationArgs = {
  filter?: Maybe<PixelAnnotationFilter>;
  order?: Maybe<PixelAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePoint2DPayload = {
  __typename?: 'DeletePoint2DPayload';
  point2D?: Maybe<Array<Maybe<Point2D>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePoint2DPayloadPoint2DArgs = {
  filter?: Maybe<Point2DFilter>;
  order?: Maybe<Point2DOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePolygonAnnotationPayload = {
  __typename?: 'DeletePolygonAnnotationPayload';
  polygonAnnotation?: Maybe<Array<Maybe<PolygonAnnotation>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePolygonAnnotationPayloadPolygonAnnotationArgs = {
  filter?: Maybe<PolygonAnnotationFilter>;
  order?: Maybe<PolygonAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeletePretrainedNetworkPayload = {
  __typename?: 'DeletePretrainedNetworkPayload';
  pretrainedNetwork?: Maybe<Array<Maybe<PretrainedNetwork>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeletePretrainedNetworkPayloadPretrainedNetworkArgs = {
  filter?: Maybe<PretrainedNetworkFilter>;
  order?: Maybe<PretrainedNetworkOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteProductionTagPayload = {
  __typename?: 'DeleteProductionTagPayload';
  productionTag?: Maybe<Array<Maybe<ProductionTag>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteProductionTagPayloadProductionTagArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteProgressNotificationPayload = {
  __typename?: 'DeleteProgressNotificationPayload';
  progressNotification?: Maybe<Array<Maybe<ProgressNotification>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteProgressNotificationPayloadProgressNotificationArgs = {
  filter?: Maybe<ProgressNotificationFilter>;
  order?: Maybe<ProgressNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteReadOnlyPayload = {
  __typename?: 'DeleteReadOnlyPayload';
  readOnly?: Maybe<Array<Maybe<ReadOnly>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteReadOnlyPayloadReadOnlyArgs = {
  filter?: Maybe<ReadOnlyFilter>;
  order?: Maybe<ReadOnlyOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteRectangleAnnotationPayload = {
  __typename?: 'DeleteRectangleAnnotationPayload';
  rectangleAnnotation?: Maybe<Array<Maybe<RectangleAnnotation>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteRectangleAnnotationPayloadRectangleAnnotationArgs = {
  filter?: Maybe<RectangleAnnotationFilter>;
  order?: Maybe<RectangleAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteResearchBoxPayload = {
  __typename?: 'DeleteResearchBoxPayload';
  researchBox?: Maybe<Array<Maybe<ResearchBox>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteResearchBoxPayloadResearchBoxArgs = {
  filter?: Maybe<ResearchBoxFilter>;
  order?: Maybe<ResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteSausageAnnotationPayload = {
  __typename?: 'DeleteSausageAnnotationPayload';
  sausageAnnotation?: Maybe<Array<Maybe<SausageAnnotation>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteSausageAnnotationPayloadSausageAnnotationArgs = {
  filter?: Maybe<SausageAnnotationFilter>;
  order?: Maybe<SausageAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteScalarAnnotationPayload = {
  __typename?: 'DeleteScalarAnnotationPayload';
  scalarAnnotation?: Maybe<Array<Maybe<ScalarAnnotation>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteScalarAnnotationPayloadScalarAnnotationArgs = {
  filter?: Maybe<ScalarAnnotationFilter>;
  order?: Maybe<ScalarAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteScalarPredictionPayload = {
  __typename?: 'DeleteScalarPredictionPayload';
  scalarPrediction?: Maybe<Array<Maybe<ScalarPrediction>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteScalarPredictionPayloadScalarPredictionArgs = {
  filter?: Maybe<ScalarPredictionFilter>;
  order?: Maybe<ScalarPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteSegmentationImageAnnotationPayload = {
  __typename?: 'DeleteSegmentationImageAnnotationPayload';
  segmentationImageAnnotation?: Maybe<Array<Maybe<SegmentationImageAnnotation>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteSegmentationImageAnnotationPayloadSegmentationImageAnnotationArgs = {
  filter?: Maybe<SegmentationImageAnnotationFilter>;
  order?: Maybe<SegmentationImageAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteSegmentationMap2DPayload = {
  __typename?: 'DeleteSegmentationMap2DPayload';
  segmentationMap2D?: Maybe<Array<Maybe<SegmentationMap2D>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteSegmentationMap2DPayloadSegmentationMap2DArgs = {
  filter?: Maybe<SegmentationMap2DFilter>;
  order?: Maybe<SegmentationMap2DOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteSegmentationMarkupPayload = {
  __typename?: 'DeleteSegmentationMarkupPayload';
  segmentationMarkup?: Maybe<Array<Maybe<SegmentationMarkup>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteSegmentationMarkupPayloadSegmentationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
  order?: Maybe<SegmentationMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteSegmentationPredictionObjectPayload = {
  __typename?: 'DeleteSegmentationPredictionObjectPayload';
  segmentationPredictionObject?: Maybe<Array<Maybe<SegmentationPredictionObject>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteSegmentationPredictionObjectPayloadSegmentationPredictionObjectArgs = {
  filter?: Maybe<SegmentationPredictionObjectFilter>;
  order?: Maybe<SegmentationPredictionObjectOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteSegmentationPredictionPayload = {
  __typename?: 'DeleteSegmentationPredictionPayload';
  segmentationPrediction?: Maybe<Array<Maybe<SegmentationPrediction>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteSegmentationPredictionPayloadSegmentationPredictionArgs = {
  filter?: Maybe<SegmentationPredictionFilter>;
  order?: Maybe<SegmentationPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteTimestampedPayload = {
  __typename?: 'DeleteTimestampedPayload';
  timestamped?: Maybe<Array<Maybe<Timestamped>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteTimestampedPayloadTimestampedArgs = {
  filter?: Maybe<TimestampedFilter>;
  order?: Maybe<TimestampedOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  user?: Maybe<Array<Maybe<User>>>;
  msg?: Maybe<Scalars['String']>;
  numUids?: Maybe<Scalars['Int']>;
};


export type DeleteUserPayloadUserArgs = {
  filter?: Maybe<UserFilter>;
  order?: Maybe<UserOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type Denkbox = Cuid & Timestamped & ReadOnly & {
  __typename?: 'Denkbox';
  _placeholder?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  rootID: Scalars['ID'];
  dockerId: Scalars['String'];
  host: Scalars['String'];
  gpus: Array<Gpu>;
  jobs: Array<Denkjob>;
  gpusAggregate?: Maybe<GpuAggregateResult>;
  jobsAggregate?: Maybe<DenkjobAggregateResult>;
};


export type DenkboxGpusArgs = {
  filter?: Maybe<GpuFilter>;
  order?: Maybe<GpuOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type DenkboxJobsArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type DenkboxGpusAggregateArgs = {
  filter?: Maybe<GpuFilter>;
};


export type DenkboxJobsAggregateArgs = {
  filter?: Maybe<DenkjobFilter>;
};

export type DenkboxAggregateResult = {
  __typename?: 'DenkboxAggregateResult';
  count?: Maybe<Scalars['Int']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  dockerIdMin?: Maybe<Scalars['String']>;
  dockerIdMax?: Maybe<Scalars['String']>;
  hostMin?: Maybe<Scalars['String']>;
  hostMax?: Maybe<Scalars['String']>;
};

export type DenkboxFilter = {
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  dockerId?: Maybe<StringHashFilter>;
  host?: Maybe<StringRegExpFilter>;
  has?: Maybe<Array<Maybe<DenkboxHasFilter>>>;
  and?: Maybe<Array<Maybe<DenkboxFilter>>>;
  or?: Maybe<Array<Maybe<DenkboxFilter>>>;
  not?: Maybe<DenkboxFilter>;
};

export enum DenkboxHasFilter {
  Placeholder = '_placeholder',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  DockerId = 'dockerId',
  Host = 'host',
  Gpus = 'gpus',
  Jobs = 'jobs'
}

export type DenkboxOrder = {
  asc?: Maybe<DenkboxOrderable>;
  desc?: Maybe<DenkboxOrderable>;
  then?: Maybe<DenkboxOrder>;
};

export enum DenkboxOrderable {
  Placeholder = '_placeholder',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  DockerId = 'dockerId',
  Host = 'host'
}

export type DenkboxPatch = {
  _placeholder?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dockerId?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  gpus?: Maybe<Array<GpuRef>>;
  jobs?: Maybe<Array<DenkjobRef>>;
};

export type DenkboxRef = {
  rootID?: Maybe<Scalars['ID']>;
  _placeholder?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  dockerId?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  gpus?: Maybe<Array<GpuRef>>;
  jobs?: Maybe<Array<DenkjobRef>>;
};

export type Denkjob = Cuid & Timestamped & Ownable & {
  __typename?: 'Denkjob';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  rootID: Scalars['ID'];
  denkbox?: Maybe<Denkbox>;
  command: DenkjobCommand;
  state?: Maybe<DenkjobState>;
  failCounter: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  jobType: DenkjobType;
  tensorboard?: Maybe<Scalars['String']>;
  module: NetworkModule;
  researchBoxIds: Array<Scalars['String']>;
};


export type DenkjobDenkboxArgs = {
  filter?: Maybe<DenkboxFilter>;
};


export type DenkjobModuleArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};

export type DenkjobAggregateResult = {
  __typename?: 'DenkjobAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  failCounterMin?: Maybe<Scalars['Int']>;
  failCounterMax?: Maybe<Scalars['Int']>;
  failCounterSum?: Maybe<Scalars['Int']>;
  failCounterAvg?: Maybe<Scalars['Float']>;
  messageMin?: Maybe<Scalars['String']>;
  messageMax?: Maybe<Scalars['String']>;
  tensorboardMin?: Maybe<Scalars['String']>;
  tensorboardMax?: Maybe<Scalars['String']>;
};

export enum DenkjobCommand {
  Start = 'START',
  Stop = 'STOP'
}

export type DenkjobCommand_Hash = {
  eq?: Maybe<DenkjobCommand>;
  in?: Maybe<Array<Maybe<DenkjobCommand>>>;
};

export type DenkjobFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  command?: Maybe<DenkjobCommand_Hash>;
  state?: Maybe<DenkjobState_Hash>;
  failCounter?: Maybe<IntFilter>;
  message?: Maybe<StringFullTextFilter_StringRegExpFilter>;
  jobType?: Maybe<DenkjobType_Hash>;
  tensorboard?: Maybe<StringTermFilter>;
  has?: Maybe<Array<Maybe<DenkjobHasFilter>>>;
  and?: Maybe<Array<Maybe<DenkjobFilter>>>;
  or?: Maybe<Array<Maybe<DenkjobFilter>>>;
  not?: Maybe<DenkjobFilter>;
};

export enum DenkjobHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Denkbox = 'denkbox',
  Command = 'command',
  State = 'state',
  FailCounter = 'failCounter',
  Message = 'message',
  JobType = 'jobType',
  Tensorboard = 'tensorboard',
  Module = 'module',
  ResearchBoxIds = 'researchBoxIds'
}

export type DenkjobOrder = {
  asc?: Maybe<DenkjobOrderable>;
  desc?: Maybe<DenkjobOrderable>;
  then?: Maybe<DenkjobOrder>;
};

export enum DenkjobOrderable {
  Owner = 'owner',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  FailCounter = 'failCounter',
  Message = 'message',
  Tensorboard = 'tensorboard'
}

export type DenkjobPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  denkbox?: Maybe<DenkboxRef>;
  command?: Maybe<DenkjobCommand>;
  state?: Maybe<DenkjobState>;
  failCounter?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  jobType?: Maybe<DenkjobType>;
  tensorboard?: Maybe<Scalars['String']>;
  module?: Maybe<NetworkModuleRef>;
  researchBoxIds?: Maybe<Array<Scalars['String']>>;
};

export type DenkjobRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  denkbox?: Maybe<DenkboxRef>;
  command?: Maybe<DenkjobCommand>;
  state?: Maybe<DenkjobState>;
  failCounter?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  jobType?: Maybe<DenkjobType>;
  tensorboard?: Maybe<Scalars['String']>;
  module?: Maybe<NetworkModuleRef>;
  researchBoxIds?: Maybe<Array<Scalars['String']>>;
};

export enum DenkjobState {
  Starting = 'STARTING',
  Running = 'RUNNING',
  Stopping = 'STOPPING',
  Failed = 'FAILED',
  Finished = 'FINISHED'
}

export type DenkjobState_Hash = {
  eq?: Maybe<DenkjobState>;
  in?: Maybe<Array<Maybe<DenkjobState>>>;
};

export enum DenkjobType {
  DenKtrainer = 'DENKtrainer',
  DenKpredictor = 'DENKpredictor',
  DenKtester = 'DENKtester'
}

export type DenkjobType_Hash = {
  eq?: Maybe<DenkjobType>;
  in?: Maybe<Array<Maybe<DenkjobType>>>;
};

export type DenktesterNotification = Notification & {
  __typename?: 'DenktesterNotification';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  read: Scalars['Boolean'];
  title: Scalars['String'];
  message: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  currentProgress: Scalars['Int'];
  maxProgress: Scalars['Int'];
  network: NetworkModule;
  networkId: Scalars['String'];
};


export type DenktesterNotificationNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};

export type DenktesterNotificationAggregateResult = {
  __typename?: 'DenktesterNotificationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  titleMin?: Maybe<Scalars['String']>;
  titleMax?: Maybe<Scalars['String']>;
  messageMin?: Maybe<Scalars['String']>;
  messageMax?: Maybe<Scalars['String']>;
  errorMin?: Maybe<Scalars['String']>;
  errorMax?: Maybe<Scalars['String']>;
  currentProgressMin?: Maybe<Scalars['Int']>;
  currentProgressMax?: Maybe<Scalars['Int']>;
  currentProgressSum?: Maybe<Scalars['Int']>;
  currentProgressAvg?: Maybe<Scalars['Float']>;
  maxProgressMin?: Maybe<Scalars['Int']>;
  maxProgressMax?: Maybe<Scalars['Int']>;
  maxProgressSum?: Maybe<Scalars['Int']>;
  maxProgressAvg?: Maybe<Scalars['Float']>;
  networkIdMin?: Maybe<Scalars['String']>;
  networkIdMax?: Maybe<Scalars['String']>;
};

export type DenktesterNotificationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  read?: Maybe<Scalars['Boolean']>;
  networkId?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<DenktesterNotificationHasFilter>>>;
  and?: Maybe<Array<Maybe<DenktesterNotificationFilter>>>;
  or?: Maybe<Array<Maybe<DenktesterNotificationFilter>>>;
  not?: Maybe<DenktesterNotificationFilter>;
};

export enum DenktesterNotificationHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Read = 'read',
  Title = 'title',
  Message = 'message',
  Error = 'error',
  CurrentProgress = 'currentProgress',
  MaxProgress = 'maxProgress',
  Network = 'network',
  NetworkId = 'networkId'
}

export type DenktesterNotificationOrder = {
  asc?: Maybe<DenktesterNotificationOrderable>;
  desc?: Maybe<DenktesterNotificationOrderable>;
  then?: Maybe<DenktesterNotificationOrder>;
};

export enum DenktesterNotificationOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Title = 'title',
  Message = 'message',
  Error = 'error',
  CurrentProgress = 'currentProgress',
  MaxProgress = 'maxProgress',
  NetworkId = 'networkId'
}

export type DenktesterNotificationPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  currentProgress?: Maybe<Scalars['Int']>;
  maxProgress?: Maybe<Scalars['Int']>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
};

export type DenktesterNotificationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  currentProgress?: Maybe<Scalars['Int']>;
  maxProgress?: Maybe<Scalars['Int']>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
};

export enum DgraphIndex {
  Int = 'int',
  Int64 = 'int64',
  Float = 'float',
  Bool = 'bool',
  Hash = 'hash',
  Exact = 'exact',
  Term = 'term',
  Fulltext = 'fulltext',
  Trigram = 'trigram',
  Regexp = 'regexp',
  Year = 'year',
  Month = 'month',
  Day = 'day',
  Hour = 'hour',
  Geo = 'geo'
}

export type EncryptedBinary = Cuid & Ownable & Timestamped & {
  __typename?: 'EncryptedBinary';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  url: Scalars['String'];
};

export type EncryptedBinaryAggregateResult = {
  __typename?: 'EncryptedBinaryAggregateResult';
  count?: Maybe<Scalars['Int']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
};

export type EncryptedBinaryFilter = {
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<EncryptedBinaryHasFilter>>>;
  and?: Maybe<Array<Maybe<EncryptedBinaryFilter>>>;
  or?: Maybe<Array<Maybe<EncryptedBinaryFilter>>>;
  not?: Maybe<EncryptedBinaryFilter>;
};

export enum EncryptedBinaryHasFilter {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Id = 'id'
}

export type EncryptedBinaryOrder = {
  asc?: Maybe<EncryptedBinaryOrderable>;
  desc?: Maybe<EncryptedBinaryOrderable>;
  then?: Maybe<EncryptedBinaryOrder>;
};

export enum EncryptedBinaryOrderable {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Id = 'id'
}

export type EncryptedBinaryPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type EncryptedBinaryRef = {
  rootID?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
};

export type FloatFilter = {
  eq?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  between?: Maybe<FloatRange>;
};

export type FloatRange = {
  min: Scalars['Float'];
  max: Scalars['Float'];
};

export type Folder = FolderOrResearchBox & {
  __typename?: 'Folder';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  typeForSorting: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<Folder>;
  dataset?: Maybe<Dataset>;
  parentIds: Scalars['String'];
  description: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  objects: Array<FolderOrResearchBox>;
  aggregateImageResolutions: Array<WidthHeight>;
  aggregateDatapointTypes: Array<Scalars['String']>;
  objectsAggregate?: Maybe<FolderOrResearchBoxAggregateResult>;
};


export type FolderParentArgs = {
  filter?: Maybe<FolderFilter>;
};


export type FolderDatasetArgs = {
  filter?: Maybe<DatasetFilter>;
};


export type FolderObjectsArgs = {
  filter?: Maybe<FolderOrResearchBoxFilter>;
  order?: Maybe<FolderOrResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type FolderObjectsAggregateArgs = {
  filter?: Maybe<FolderOrResearchBoxFilter>;
};

export type FolderAggregateResult = {
  __typename?: 'FolderAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  typeForSortingMin?: Maybe<Scalars['String']>;
  typeForSortingMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  parentIdsMin?: Maybe<Scalars['String']>;
  parentIdsMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  stateMin?: Maybe<Scalars['String']>;
  stateMax?: Maybe<Scalars['String']>;
};

export type FolderFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  typeForSorting?: Maybe<StringHashFilter>;
  name?: Maybe<StringHashFilter_StringRegExpFilter>;
  parentIds?: Maybe<StringHashFilter_StringRegExpFilter>;
  description?: Maybe<StringHashFilter_StringRegExpFilter>;
  has?: Maybe<Array<Maybe<FolderHasFilter>>>;
  and?: Maybe<Array<Maybe<FolderFilter>>>;
  or?: Maybe<Array<Maybe<FolderFilter>>>;
  not?: Maybe<FolderFilter>;
};

export enum FolderHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  TypeForSorting = 'typeForSorting',
  Name = 'name',
  Parent = 'parent',
  Dataset = 'dataset',
  ParentIds = 'parentIds',
  Description = 'description',
  State = 'state',
  Objects = 'objects'
}

export type FolderOrder = {
  asc?: Maybe<FolderOrderable>;
  desc?: Maybe<FolderOrderable>;
  then?: Maybe<FolderOrder>;
};

export enum FolderOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  TypeForSorting = 'typeForSorting',
  Name = 'name',
  ParentIds = 'parentIds',
  Description = 'description',
  State = 'state'
}

export type FolderOrResearchBox = {
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  typeForSorting: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<Folder>;
  dataset?: Maybe<Dataset>;
  parentIds: Scalars['String'];
};


export type FolderOrResearchBoxParentArgs = {
  filter?: Maybe<FolderFilter>;
};


export type FolderOrResearchBoxDatasetArgs = {
  filter?: Maybe<DatasetFilter>;
};

export type FolderOrResearchBoxAggregateResult = {
  __typename?: 'FolderOrResearchBoxAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  typeForSortingMin?: Maybe<Scalars['String']>;
  typeForSortingMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  parentIdsMin?: Maybe<Scalars['String']>;
  parentIdsMax?: Maybe<Scalars['String']>;
};

export type FolderOrResearchBoxFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  typeForSorting?: Maybe<StringHashFilter>;
  name?: Maybe<StringHashFilter_StringRegExpFilter>;
  parentIds?: Maybe<StringHashFilter_StringRegExpFilter>;
  has?: Maybe<Array<Maybe<FolderOrResearchBoxHasFilter>>>;
  and?: Maybe<Array<Maybe<FolderOrResearchBoxFilter>>>;
  or?: Maybe<Array<Maybe<FolderOrResearchBoxFilter>>>;
  not?: Maybe<FolderOrResearchBoxFilter>;
};

export enum FolderOrResearchBoxHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  TypeForSorting = 'typeForSorting',
  Name = 'name',
  Parent = 'parent',
  Dataset = 'dataset',
  ParentIds = 'parentIds'
}

export type FolderOrResearchBoxOrder = {
  asc?: Maybe<FolderOrResearchBoxOrderable>;
  desc?: Maybe<FolderOrResearchBoxOrderable>;
  then?: Maybe<FolderOrResearchBoxOrder>;
};

export enum FolderOrResearchBoxOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  TypeForSorting = 'typeForSorting',
  Name = 'name',
  ParentIds = 'parentIds'
}

export type FolderOrResearchBoxPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  typeForSorting?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<FolderRef>;
  dataset?: Maybe<DatasetRef>;
  parentIds?: Maybe<Scalars['String']>;
};

export type FolderOrResearchBoxRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export enum FolderOrResearchBoxType {
  Folder = 'FOLDER',
  Researchbox = 'RESEARCHBOX'
}

export type FolderPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  typeForSorting?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<FolderRef>;
  dataset?: Maybe<DatasetRef>;
  parentIds?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  objects?: Maybe<Array<FolderOrResearchBoxRef>>;
};

export type FolderRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  typeForSorting?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<FolderRef>;
  dataset?: Maybe<DatasetRef>;
  parentIds?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  objects?: Maybe<Array<FolderOrResearchBoxRef>>;
};

export type GenerateMutationParams = {
  add?: Maybe<Scalars['Boolean']>;
  update?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type GenerateQueryParams = {
  get?: Maybe<Scalars['Boolean']>;
  query?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['Boolean']>;
  aggregate?: Maybe<Scalars['Boolean']>;
};

export type Gpu = Cuid & Timestamped & ReadOnly & {
  __typename?: 'Gpu';
  _placeholder?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  rootID: Scalars['ID'];
  index: Scalars['Int'];
  maxMemory: Scalars['Int'];
  freeMemory: Scalars['Int'];
  usage: Scalars['Int'];
  denkbox: Denkbox;
};


export type GpuDenkboxArgs = {
  filter?: Maybe<DenkboxFilter>;
};

export type GpuAggregateResult = {
  __typename?: 'GpuAggregateResult';
  count?: Maybe<Scalars['Int']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  indexMin?: Maybe<Scalars['Int']>;
  indexMax?: Maybe<Scalars['Int']>;
  indexSum?: Maybe<Scalars['Int']>;
  indexAvg?: Maybe<Scalars['Float']>;
  maxMemoryMin?: Maybe<Scalars['Int']>;
  maxMemoryMax?: Maybe<Scalars['Int']>;
  maxMemorySum?: Maybe<Scalars['Int']>;
  maxMemoryAvg?: Maybe<Scalars['Float']>;
  freeMemoryMin?: Maybe<Scalars['Int']>;
  freeMemoryMax?: Maybe<Scalars['Int']>;
  freeMemorySum?: Maybe<Scalars['Int']>;
  freeMemoryAvg?: Maybe<Scalars['Float']>;
  usageMin?: Maybe<Scalars['Int']>;
  usageMax?: Maybe<Scalars['Int']>;
  usageSum?: Maybe<Scalars['Int']>;
  usageAvg?: Maybe<Scalars['Float']>;
};

export type GpuFilter = {
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<GpuHasFilter>>>;
  and?: Maybe<Array<Maybe<GpuFilter>>>;
  or?: Maybe<Array<Maybe<GpuFilter>>>;
  not?: Maybe<GpuFilter>;
};

export enum GpuHasFilter {
  Placeholder = '_placeholder',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Index = 'index',
  MaxMemory = 'maxMemory',
  FreeMemory = 'freeMemory',
  Usage = 'usage',
  Denkbox = 'denkbox'
}

export type GpuOrder = {
  asc?: Maybe<GpuOrderable>;
  desc?: Maybe<GpuOrderable>;
  then?: Maybe<GpuOrder>;
};

export enum GpuOrderable {
  Placeholder = '_placeholder',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Index = 'index',
  MaxMemory = 'maxMemory',
  FreeMemory = 'freeMemory',
  Usage = 'usage'
}

export type GpuPatch = {
  _placeholder?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  index?: Maybe<Scalars['Int']>;
  maxMemory?: Maybe<Scalars['Int']>;
  freeMemory?: Maybe<Scalars['Int']>;
  usage?: Maybe<Scalars['Int']>;
  denkbox?: Maybe<DenkboxRef>;
};

export type GpuRef = {
  rootID?: Maybe<Scalars['ID']>;
  _placeholder?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  maxMemory?: Maybe<Scalars['Int']>;
  freeMemory?: Maybe<Scalars['Int']>;
  usage?: Maybe<Scalars['Int']>;
  denkbox?: Maybe<DenkboxRef>;
};

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE'
}

export type Image = Datapoint & {
  __typename?: 'Image';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy: PipelineModuleDatapointOutput;
  researchBox: ResearchBox;
  binaryURL: Scalars['String'];
  thumbnail: Scalars['String'];
  height: Scalars['Int'];
  width: Scalars['Int'];
  resolutions: Array<ImageResolution>;
  filename: Scalars['String'];
  metadata: Array<ImageMetadataField>;
  markups: Array<ImageMarkup>;
  predictions: Array<ImagePrediction>;
  resolutionsAggregate?: Maybe<ImageResolutionAggregateResult>;
  metadataAggregate?: Maybe<ImageMetadataFieldAggregateResult>;
  markupsAggregate?: Maybe<ImageMarkupAggregateResult>;
  predictionsAggregate?: Maybe<ImagePredictionAggregateResult>;
};


export type ImageProducedByArgs = {
  filter?: Maybe<PipelineModuleDatapointOutputFilter>;
};


export type ImageResearchBoxArgs = {
  filter?: Maybe<ResearchBoxFilter>;
};


export type ImageResolutionsArgs = {
  filter?: Maybe<ImageResolutionFilter>;
  order?: Maybe<ImageResolutionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ImageMetadataArgs = {
  filter?: Maybe<ImageMetadataFieldFilter>;
  order?: Maybe<ImageMetadataFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ImageMarkupsArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ImagePredictionsArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ImageResolutionsAggregateArgs = {
  filter?: Maybe<ImageResolutionFilter>;
};


export type ImageMetadataAggregateArgs = {
  filter?: Maybe<ImageMetadataFieldFilter>;
};


export type ImageMarkupsAggregateArgs = {
  filter?: Maybe<ImageMarkupFilter>;
};


export type ImagePredictionsAggregateArgs = {
  filter?: Maybe<ImagePredictionFilter>;
};

export type ImageAggregateResult = {
  __typename?: 'ImageAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  thumbnailMin?: Maybe<Scalars['String']>;
  thumbnailMax?: Maybe<Scalars['String']>;
  heightMin?: Maybe<Scalars['Int']>;
  heightMax?: Maybe<Scalars['Int']>;
  heightSum?: Maybe<Scalars['Int']>;
  heightAvg?: Maybe<Scalars['Float']>;
  widthMin?: Maybe<Scalars['Int']>;
  widthMax?: Maybe<Scalars['Int']>;
  widthSum?: Maybe<Scalars['Int']>;
  widthAvg?: Maybe<Scalars['Float']>;
  filenameMin?: Maybe<Scalars['String']>;
  filenameMax?: Maybe<Scalars['String']>;
};

export type ImageFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<StringFullTextFilter_StringHashFilter_StringRegExpFilter>;
  has?: Maybe<Array<Maybe<ImageHasFilter>>>;
  and?: Maybe<Array<Maybe<ImageFilter>>>;
  or?: Maybe<Array<Maybe<ImageFilter>>>;
  not?: Maybe<ImageFilter>;
};

export enum ImageHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  ProducedBy = 'producedBy',
  ResearchBox = 'researchBox',
  Thumbnail = 'thumbnail',
  Height = 'height',
  Width = 'width',
  Resolutions = 'resolutions',
  Filename = 'filename',
  Metadata = 'metadata',
  Markups = 'markups',
  Predictions = 'predictions'
}

export type ImageMarkup = {
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<Image>;
  subset: SubsetType;
  network: NetworkModule;
  networkId: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  imagesAggregate?: Maybe<ImageAggregateResult>;
};


export type ImageMarkupImagesArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ImageMarkupNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};


export type ImageMarkupImagesAggregateArgs = {
  filter?: Maybe<ImageFilter>;
};

export type ImageMarkupAggregateResult = {
  __typename?: 'ImageMarkupAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  networkIdMin?: Maybe<Scalars['String']>;
  networkIdMax?: Maybe<Scalars['String']>;
  widthMin?: Maybe<Scalars['Int']>;
  widthMax?: Maybe<Scalars['Int']>;
  widthSum?: Maybe<Scalars['Int']>;
  widthAvg?: Maybe<Scalars['Float']>;
  heightMin?: Maybe<Scalars['Int']>;
  heightMax?: Maybe<Scalars['Int']>;
  heightSum?: Maybe<Scalars['Int']>;
  heightAvg?: Maybe<Scalars['Float']>;
};

export type ImageMarkupFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  subset?: Maybe<SubsetType_Hash>;
  networkId?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<ImageMarkupHasFilter>>>;
  and?: Maybe<Array<Maybe<ImageMarkupFilter>>>;
  or?: Maybe<Array<Maybe<ImageMarkupFilter>>>;
  not?: Maybe<ImageMarkupFilter>;
};

export enum ImageMarkupHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Images = 'images',
  Subset = 'subset',
  Network = 'network',
  NetworkId = 'networkId',
  Width = 'width',
  Height = 'height'
}

export type ImageMarkupOrder = {
  asc?: Maybe<ImageMarkupOrderable>;
  desc?: Maybe<ImageMarkupOrderable>;
  then?: Maybe<ImageMarkupOrder>;
};

export enum ImageMarkupOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  NetworkId = 'networkId',
  Width = 'width',
  Height = 'height'
}

export type ImageMarkupPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  subset?: Maybe<SubsetType>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type ImageMarkupRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export type ImageMetadataField = Cuid & Timestamped & Ownable & {
  __typename?: 'ImageMetadataField';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  rootID: Scalars['ID'];
  key: Scalars['String'];
  value: Scalars['String'];
  image: Image;
};


export type ImageMetadataFieldImageArgs = {
  filter?: Maybe<ImageFilter>;
};

export type ImageMetadataFieldAggregateResult = {
  __typename?: 'ImageMetadataFieldAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  keyMin?: Maybe<Scalars['String']>;
  keyMax?: Maybe<Scalars['String']>;
  valueMin?: Maybe<Scalars['String']>;
  valueMax?: Maybe<Scalars['String']>;
};

export type ImageMetadataFieldFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<ImageMetadataFieldHasFilter>>>;
  and?: Maybe<Array<Maybe<ImageMetadataFieldFilter>>>;
  or?: Maybe<Array<Maybe<ImageMetadataFieldFilter>>>;
  not?: Maybe<ImageMetadataFieldFilter>;
};

export enum ImageMetadataFieldHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Key = 'key',
  Value = 'value',
  Image = 'image'
}

export type ImageMetadataFieldOrder = {
  asc?: Maybe<ImageMetadataFieldOrderable>;
  desc?: Maybe<ImageMetadataFieldOrderable>;
  then?: Maybe<ImageMetadataFieldOrder>;
};

export enum ImageMetadataFieldOrderable {
  Owner = 'owner',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Key = 'key',
  Value = 'value'
}

export type ImageMetadataFieldPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  image?: Maybe<ImageRef>;
};

export type ImageMetadataFieldRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  image?: Maybe<ImageRef>;
};

export type ImageOrder = {
  asc?: Maybe<ImageOrderable>;
  desc?: Maybe<ImageOrderable>;
  then?: Maybe<ImageOrder>;
};

export enum ImageOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Thumbnail = 'thumbnail',
  Height = 'height',
  Width = 'width',
  Filename = 'filename'
}

export type ImagePatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy?: Maybe<PipelineModuleDatapointOutputRef>;
  researchBox?: Maybe<ResearchBoxRef>;
  thumbnail?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  resolutions?: Maybe<Array<ImageResolutionRef>>;
  filename?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<ImageMetadataFieldRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
};

export type ImagePrediction = {
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<Image>;
  network: NetworkModule;
  networkId: Scalars['String'];
  imagesAggregate?: Maybe<ImageAggregateResult>;
};


export type ImagePredictionImagesArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ImagePredictionNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};


export type ImagePredictionImagesAggregateArgs = {
  filter?: Maybe<ImageFilter>;
};

export type ImagePredictionAggregateResult = {
  __typename?: 'ImagePredictionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  networkIdMin?: Maybe<Scalars['String']>;
  networkIdMax?: Maybe<Scalars['String']>;
};

export type ImagePredictionFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  networkId?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<ImagePredictionHasFilter>>>;
  and?: Maybe<Array<Maybe<ImagePredictionFilter>>>;
  or?: Maybe<Array<Maybe<ImagePredictionFilter>>>;
  not?: Maybe<ImagePredictionFilter>;
};

export enum ImagePredictionHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Images = 'images',
  Network = 'network',
  NetworkId = 'networkId'
}

export type ImagePredictionOrder = {
  asc?: Maybe<ImagePredictionOrderable>;
  desc?: Maybe<ImagePredictionOrderable>;
  then?: Maybe<ImagePredictionOrder>;
};

export enum ImagePredictionOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  NetworkId = 'networkId'
}

export type ImagePredictionPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
};

export type ImagePredictionRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export type ImageRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy?: Maybe<PipelineModuleDatapointOutputRef>;
  researchBox?: Maybe<ResearchBoxRef>;
  thumbnail?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  resolutions?: Maybe<Array<ImageResolutionRef>>;
  filename?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<ImageMetadataFieldRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
};

export type ImageResolution = Cuid & Timestamped & Ownable & {
  __typename?: 'ImageResolution';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  rootID: Scalars['ID'];
  binaryURL: Scalars['String'];
  image: Image;
  height: Scalars['Int'];
  width: Scalars['Int'];
};


export type ImageResolutionImageArgs = {
  filter?: Maybe<ImageFilter>;
};

export type ImageResolutionAggregateResult = {
  __typename?: 'ImageResolutionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  heightMin?: Maybe<Scalars['Int']>;
  heightMax?: Maybe<Scalars['Int']>;
  heightSum?: Maybe<Scalars['Int']>;
  heightAvg?: Maybe<Scalars['Float']>;
  widthMin?: Maybe<Scalars['Int']>;
  widthMax?: Maybe<Scalars['Int']>;
  widthSum?: Maybe<Scalars['Int']>;
  widthAvg?: Maybe<Scalars['Float']>;
};

export type ImageResolutionFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<ImageResolutionHasFilter>>>;
  and?: Maybe<Array<Maybe<ImageResolutionFilter>>>;
  or?: Maybe<Array<Maybe<ImageResolutionFilter>>>;
  not?: Maybe<ImageResolutionFilter>;
};

export enum ImageResolutionHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Image = 'image',
  Height = 'height',
  Width = 'width'
}

export type ImageResolutionOrder = {
  asc?: Maybe<ImageResolutionOrderable>;
  desc?: Maybe<ImageResolutionOrderable>;
  then?: Maybe<ImageResolutionOrder>;
};

export enum ImageResolutionOrderable {
  Owner = 'owner',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Height = 'height',
  Width = 'width'
}

export type ImageResolutionPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  image?: Maybe<ImageRef>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type ImageResolutionRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<ImageRef>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type InstanceSegmentationMarkup = ImageMarkup & {
  __typename?: 'InstanceSegmentationMarkup';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<Image>;
  subset: SubsetType;
  network: NetworkModule;
  networkId: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  _placeholder: Scalars['String'];
  imagesAggregate?: Maybe<ImageAggregateResult>;
};


export type InstanceSegmentationMarkupImagesArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type InstanceSegmentationMarkupNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};


export type InstanceSegmentationMarkupImagesAggregateArgs = {
  filter?: Maybe<ImageFilter>;
};

export type InstanceSegmentationMarkupAggregateResult = {
  __typename?: 'InstanceSegmentationMarkupAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  networkIdMin?: Maybe<Scalars['String']>;
  networkIdMax?: Maybe<Scalars['String']>;
  widthMin?: Maybe<Scalars['Int']>;
  widthMax?: Maybe<Scalars['Int']>;
  widthSum?: Maybe<Scalars['Int']>;
  widthAvg?: Maybe<Scalars['Float']>;
  heightMin?: Maybe<Scalars['Int']>;
  heightMax?: Maybe<Scalars['Int']>;
  heightSum?: Maybe<Scalars['Int']>;
  heightAvg?: Maybe<Scalars['Float']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type InstanceSegmentationMarkupFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  subset?: Maybe<SubsetType_Hash>;
  networkId?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<InstanceSegmentationMarkupHasFilter>>>;
  and?: Maybe<Array<Maybe<InstanceSegmentationMarkupFilter>>>;
  or?: Maybe<Array<Maybe<InstanceSegmentationMarkupFilter>>>;
  not?: Maybe<InstanceSegmentationMarkupFilter>;
};

export enum InstanceSegmentationMarkupHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Images = 'images',
  Subset = 'subset',
  Network = 'network',
  NetworkId = 'networkId',
  Width = 'width',
  Height = 'height',
  Placeholder = '_placeholder'
}

export type InstanceSegmentationMarkupOrder = {
  asc?: Maybe<InstanceSegmentationMarkupOrderable>;
  desc?: Maybe<InstanceSegmentationMarkupOrderable>;
  then?: Maybe<InstanceSegmentationMarkupOrder>;
};

export enum InstanceSegmentationMarkupOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  NetworkId = 'networkId',
  Width = 'width',
  Height = 'height',
  Placeholder = '_placeholder'
}

export type InstanceSegmentationMarkupPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  subset?: Maybe<SubsetType>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type InstanceSegmentationMarkupRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  subset?: Maybe<SubsetType>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type InstanceSegmentationPrediction = ImageMarkup & {
  __typename?: 'InstanceSegmentationPrediction';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<Image>;
  subset: SubsetType;
  network: NetworkModule;
  networkId: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  _placeholder: Scalars['String'];
  imagesAggregate?: Maybe<ImageAggregateResult>;
};


export type InstanceSegmentationPredictionImagesArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type InstanceSegmentationPredictionNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};


export type InstanceSegmentationPredictionImagesAggregateArgs = {
  filter?: Maybe<ImageFilter>;
};

export type InstanceSegmentationPredictionAggregateResult = {
  __typename?: 'InstanceSegmentationPredictionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  networkIdMin?: Maybe<Scalars['String']>;
  networkIdMax?: Maybe<Scalars['String']>;
  widthMin?: Maybe<Scalars['Int']>;
  widthMax?: Maybe<Scalars['Int']>;
  widthSum?: Maybe<Scalars['Int']>;
  widthAvg?: Maybe<Scalars['Float']>;
  heightMin?: Maybe<Scalars['Int']>;
  heightMax?: Maybe<Scalars['Int']>;
  heightSum?: Maybe<Scalars['Int']>;
  heightAvg?: Maybe<Scalars['Float']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type InstanceSegmentationPredictionFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  subset?: Maybe<SubsetType_Hash>;
  networkId?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<InstanceSegmentationPredictionHasFilter>>>;
  and?: Maybe<Array<Maybe<InstanceSegmentationPredictionFilter>>>;
  or?: Maybe<Array<Maybe<InstanceSegmentationPredictionFilter>>>;
  not?: Maybe<InstanceSegmentationPredictionFilter>;
};

export enum InstanceSegmentationPredictionHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Images = 'images',
  Subset = 'subset',
  Network = 'network',
  NetworkId = 'networkId',
  Width = 'width',
  Height = 'height',
  Placeholder = '_placeholder'
}

export type InstanceSegmentationPredictionOrder = {
  asc?: Maybe<InstanceSegmentationPredictionOrderable>;
  desc?: Maybe<InstanceSegmentationPredictionOrderable>;
  then?: Maybe<InstanceSegmentationPredictionOrder>;
};

export enum InstanceSegmentationPredictionOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  NetworkId = 'networkId',
  Width = 'width',
  Height = 'height',
  Placeholder = '_placeholder'
}

export type InstanceSegmentationPredictionPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  subset?: Maybe<SubsetType>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type InstanceSegmentationPredictionRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  subset?: Maybe<SubsetType>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  _placeholder?: Maybe<Scalars['String']>;
};


export type Int64Filter = {
  eq?: Maybe<Scalars['Int64']>;
  in?: Maybe<Array<Maybe<Scalars['Int64']>>>;
  le?: Maybe<Scalars['Int64']>;
  lt?: Maybe<Scalars['Int64']>;
  ge?: Maybe<Scalars['Int64']>;
  gt?: Maybe<Scalars['Int64']>;
  between?: Maybe<Int64Range>;
};

export type Int64Range = {
  min: Scalars['Int64'];
  max: Scalars['Int64'];
};

export type IntersectsFilter = {
  polygon?: Maybe<PolygonRef>;
  multiPolygon?: Maybe<MultiPolygonRef>;
};

export type IntFilter = {
  eq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  between?: Maybe<IntRange>;
};

export type IntRange = {
  min: Scalars['Int'];
  max: Scalars['Int'];
};

export enum IoType {
  Input = 'INPUT',
  Output = 'OUTPUT'
}

export type LabelFilterInput = {
  labels: Array<Scalars['String']>;
  includes: LabelIncludeFilterInput;
};

export type LabelIncludeFilterInput = {
  positives: Scalars['Boolean'];
  negatives: Scalars['Boolean'];
  unclassified: Scalars['Boolean'];
};

export type ListBackupsPayload = {
  __typename?: 'ListBackupsPayload';
  backups: Array<Backup>;
};

export enum LocationMode {
  Put = 'PUT',
  Get = 'GET'
}

export type MagicwandAnnotation = SegmentationImageAnnotation & {
  __typename?: 'MagicwandAnnotation';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabel;
  annotationType: AnnotationType;
  markup: SegmentationMarkup;
  topLeft: Point2D;
  bottomRight: Point2D;
  center: Point2D;
  threshold: Scalars['Int'];
  dataURL: Scalars['String'];
};


export type MagicwandAnnotationLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type MagicwandAnnotationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
};


export type MagicwandAnnotationTopLeftArgs = {
  filter?: Maybe<Point2DFilter>;
};


export type MagicwandAnnotationBottomRightArgs = {
  filter?: Maybe<Point2DFilter>;
};


export type MagicwandAnnotationCenterArgs = {
  filter?: Maybe<Point2DFilter>;
};

export type MagicwandAnnotationAggregateResult = {
  __typename?: 'MagicwandAnnotationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  thresholdMin?: Maybe<Scalars['Int']>;
  thresholdMax?: Maybe<Scalars['Int']>;
  thresholdSum?: Maybe<Scalars['Int']>;
  thresholdAvg?: Maybe<Scalars['Float']>;
  dataURLMin?: Maybe<Scalars['String']>;
  dataURLMax?: Maybe<Scalars['String']>;
};

export type MagicwandAnnotationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<MagicwandAnnotationHasFilter>>>;
  and?: Maybe<Array<Maybe<MagicwandAnnotationFilter>>>;
  or?: Maybe<Array<Maybe<MagicwandAnnotationFilter>>>;
  not?: Maybe<MagicwandAnnotationFilter>;
};

export enum MagicwandAnnotationHasFilter {
  Id = 'id',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Label = 'label',
  AnnotationType = 'annotationType',
  Markup = 'markup',
  TopLeft = 'topLeft',
  BottomRight = 'bottomRight',
  Center = 'center',
  Threshold = 'threshold',
  DataUrl = 'dataURL'
}

export type MagicwandAnnotationOrder = {
  asc?: Maybe<MagicwandAnnotationOrderable>;
  desc?: Maybe<MagicwandAnnotationOrderable>;
  then?: Maybe<MagicwandAnnotationOrder>;
};

export enum MagicwandAnnotationOrderable {
  Id = 'id',
  Owner = 'owner',
  Threshold = 'threshold',
  DataUrl = 'dataURL'
}

export type MagicwandAnnotationPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  center?: Maybe<Point2DRef>;
  threshold?: Maybe<Scalars['Int']>;
  dataURL?: Maybe<Scalars['String']>;
};

export type MagicwandAnnotationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  center?: Maybe<Point2DRef>;
  threshold?: Maybe<Scalars['Int']>;
  dataURL?: Maybe<Scalars['String']>;
};

export type MagneticFieldScan = Datapoint & {
  __typename?: 'MagneticFieldScan';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy: PipelineModuleDatapointOutput;
  researchBox: ResearchBox;
  binaryURL: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  hasBaseline: Scalars['Boolean'];
  startIndices: Array<Scalars['Int']>;
};


export type MagneticFieldScanProducedByArgs = {
  filter?: Maybe<PipelineModuleDatapointOutputFilter>;
};


export type MagneticFieldScanResearchBoxArgs = {
  filter?: Maybe<ResearchBoxFilter>;
};

export type MagneticFieldScanAggregateResult = {
  __typename?: 'MagneticFieldScanAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  widthMin?: Maybe<Scalars['Int']>;
  widthMax?: Maybe<Scalars['Int']>;
  widthSum?: Maybe<Scalars['Int']>;
  widthAvg?: Maybe<Scalars['Float']>;
  heightMin?: Maybe<Scalars['Int']>;
  heightMax?: Maybe<Scalars['Int']>;
  heightSum?: Maybe<Scalars['Int']>;
  heightAvg?: Maybe<Scalars['Float']>;
};

export type MagneticFieldScanFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<MagneticFieldScanHasFilter>>>;
  and?: Maybe<Array<Maybe<MagneticFieldScanFilter>>>;
  or?: Maybe<Array<Maybe<MagneticFieldScanFilter>>>;
  not?: Maybe<MagneticFieldScanFilter>;
};

export enum MagneticFieldScanHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  ProducedBy = 'producedBy',
  ResearchBox = 'researchBox',
  Width = 'width',
  Height = 'height',
  HasBaseline = 'hasBaseline',
  StartIndices = 'startIndices'
}

export type MagneticFieldScanOrder = {
  asc?: Maybe<MagneticFieldScanOrderable>;
  desc?: Maybe<MagneticFieldScanOrderable>;
  then?: Maybe<MagneticFieldScanOrder>;
};

export enum MagneticFieldScanOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Width = 'width',
  Height = 'height'
}

export type MagneticFieldScanPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy?: Maybe<PipelineModuleDatapointOutputRef>;
  researchBox?: Maybe<ResearchBoxRef>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  hasBaseline?: Maybe<Scalars['Boolean']>;
  startIndices?: Maybe<Array<Scalars['Int']>>;
};

export type MagneticFieldScanRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  producedBy?: Maybe<PipelineModuleDatapointOutputRef>;
  researchBox?: Maybe<ResearchBoxRef>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  hasBaseline?: Maybe<Scalars['Boolean']>;
  startIndices?: Maybe<Array<Scalars['Int']>>;
};

export type MaintenanceNotification = Notification & {
  __typename?: 'MaintenanceNotification';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  read: Scalars['Boolean'];
  title: Scalars['String'];
  message: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  finished: Scalars['Boolean'];
  type: MaintenanceType;
};

export type MaintenanceNotificationAggregateResult = {
  __typename?: 'MaintenanceNotificationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  titleMin?: Maybe<Scalars['String']>;
  titleMax?: Maybe<Scalars['String']>;
  messageMin?: Maybe<Scalars['String']>;
  messageMax?: Maybe<Scalars['String']>;
  errorMin?: Maybe<Scalars['String']>;
  errorMax?: Maybe<Scalars['String']>;
};

export type MaintenanceNotificationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  read?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<MaintenanceNotificationHasFilter>>>;
  and?: Maybe<Array<Maybe<MaintenanceNotificationFilter>>>;
  or?: Maybe<Array<Maybe<MaintenanceNotificationFilter>>>;
  not?: Maybe<MaintenanceNotificationFilter>;
};

export enum MaintenanceNotificationHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Read = 'read',
  Title = 'title',
  Message = 'message',
  Error = 'error',
  Finished = 'finished',
  Type = 'type'
}

export type MaintenanceNotificationOrder = {
  asc?: Maybe<MaintenanceNotificationOrderable>;
  desc?: Maybe<MaintenanceNotificationOrderable>;
  then?: Maybe<MaintenanceNotificationOrder>;
};

export enum MaintenanceNotificationOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Title = 'title',
  Message = 'message',
  Error = 'error'
}

export type MaintenanceNotificationPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MaintenanceType>;
};

export type MaintenanceNotificationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MaintenanceType>;
};

export enum MaintenanceType {
  Backup = 'BACKUP',
  Restore = 'RESTORE',
  DeleteBackup = 'DELETE_BACKUP'
}

export type MarkupLabel = Cuid & Ownable & {
  __typename?: 'MarkupLabel';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  idx: Scalars['Int'];
  name: Scalars['String'];
  color: Scalars['String'];
  network: NetworkModule;
  positiveMarkupCount: Scalars['Int'];
  negativeMarkupCount: Scalars['Int'];
};


export type MarkupLabelNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};

export type MarkupLabelAggregateResult = {
  __typename?: 'MarkupLabelAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  idxMin?: Maybe<Scalars['Int']>;
  idxMax?: Maybe<Scalars['Int']>;
  idxSum?: Maybe<Scalars['Int']>;
  idxAvg?: Maybe<Scalars['Float']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  colorMin?: Maybe<Scalars['String']>;
  colorMax?: Maybe<Scalars['String']>;
  positiveMarkupCountMin?: Maybe<Scalars['Int']>;
  positiveMarkupCountMax?: Maybe<Scalars['Int']>;
  positiveMarkupCountSum?: Maybe<Scalars['Int']>;
  positiveMarkupCountAvg?: Maybe<Scalars['Float']>;
  negativeMarkupCountMin?: Maybe<Scalars['Int']>;
  negativeMarkupCountMax?: Maybe<Scalars['Int']>;
  negativeMarkupCountSum?: Maybe<Scalars['Int']>;
  negativeMarkupCountAvg?: Maybe<Scalars['Float']>;
};

export type MarkupLabelFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<MarkupLabelHasFilter>>>;
  and?: Maybe<Array<Maybe<MarkupLabelFilter>>>;
  or?: Maybe<Array<Maybe<MarkupLabelFilter>>>;
  not?: Maybe<MarkupLabelFilter>;
};

export enum MarkupLabelHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  Id = 'id',
  Idx = 'idx',
  Name = 'name',
  Color = 'color',
  Network = 'network',
  PositiveMarkupCount = 'positiveMarkupCount',
  NegativeMarkupCount = 'negativeMarkupCount'
}

export type MarkupLabelOrder = {
  asc?: Maybe<MarkupLabelOrderable>;
  desc?: Maybe<MarkupLabelOrderable>;
  then?: Maybe<MarkupLabelOrder>;
};

export enum MarkupLabelOrderable {
  Owner = 'owner',
  Id = 'id',
  Idx = 'idx',
  Name = 'name',
  Color = 'color',
  PositiveMarkupCount = 'positiveMarkupCount',
  NegativeMarkupCount = 'negativeMarkupCount'
}

export type MarkupLabelPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  idx?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  network?: Maybe<NetworkModuleRef>;
  positiveMarkupCount?: Maybe<Scalars['Int']>;
  negativeMarkupCount?: Maybe<Scalars['Int']>;
};

export type MarkupLabelRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  idx?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  network?: Maybe<NetworkModuleRef>;
  positiveMarkupCount?: Maybe<Scalars['Int']>;
  negativeMarkupCount?: Maybe<Scalars['Int']>;
};

export type Metric = Cuid & Timestamped & Ownable & {
  __typename?: 'Metric';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  rootID: Scalars['ID'];
  name: Scalars['String'];
  currentValue: Scalars['Float'];
  bestValue: Scalars['Float'];
  history: Scalars['String'];
  show: Scalars['Boolean'];
  inferenceThreshold?: Maybe<Scalars['Float']>;
  label?: Maybe<MarkupLabel>;
  isNetworkScore: Scalars['Boolean'];
};


export type MetricLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};

export type MetricAggregateResult = {
  __typename?: 'MetricAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  currentValueMin?: Maybe<Scalars['Float']>;
  currentValueMax?: Maybe<Scalars['Float']>;
  currentValueSum?: Maybe<Scalars['Float']>;
  currentValueAvg?: Maybe<Scalars['Float']>;
  bestValueMin?: Maybe<Scalars['Float']>;
  bestValueMax?: Maybe<Scalars['Float']>;
  bestValueSum?: Maybe<Scalars['Float']>;
  bestValueAvg?: Maybe<Scalars['Float']>;
  historyMin?: Maybe<Scalars['String']>;
  historyMax?: Maybe<Scalars['String']>;
  inferenceThresholdMin?: Maybe<Scalars['Float']>;
  inferenceThresholdMax?: Maybe<Scalars['Float']>;
  inferenceThresholdSum?: Maybe<Scalars['Float']>;
  inferenceThresholdAvg?: Maybe<Scalars['Float']>;
};

export type MetricFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<StringHashFilter>;
  currentValue?: Maybe<FloatFilter>;
  bestValue?: Maybe<FloatFilter>;
  show?: Maybe<Scalars['Boolean']>;
  inferenceThreshold?: Maybe<FloatFilter>;
  has?: Maybe<Array<Maybe<MetricHasFilter>>>;
  and?: Maybe<Array<Maybe<MetricFilter>>>;
  or?: Maybe<Array<Maybe<MetricFilter>>>;
  not?: Maybe<MetricFilter>;
};

export enum MetricHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Name = 'name',
  CurrentValue = 'currentValue',
  BestValue = 'bestValue',
  History = 'history',
  Show = 'show',
  InferenceThreshold = 'inferenceThreshold',
  Label = 'label',
  IsNetworkScore = 'isNetworkScore'
}

export type MetricOrder = {
  asc?: Maybe<MetricOrderable>;
  desc?: Maybe<MetricOrderable>;
  then?: Maybe<MetricOrder>;
};

export enum MetricOrderable {
  Owner = 'owner',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Name = 'name',
  CurrentValue = 'currentValue',
  BestValue = 'bestValue',
  History = 'history',
  InferenceThreshold = 'inferenceThreshold'
}

export type MetricPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  currentValue?: Maybe<Scalars['Float']>;
  bestValue?: Maybe<Scalars['Float']>;
  history?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
  inferenceThreshold?: Maybe<Scalars['Float']>;
  label?: Maybe<MarkupLabelRef>;
  isNetworkScore?: Maybe<Scalars['Boolean']>;
};

export type MetricRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  currentValue?: Maybe<Scalars['Float']>;
  bestValue?: Maybe<Scalars['Float']>;
  history?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
  inferenceThreshold?: Maybe<Scalars['Float']>;
  label?: Maybe<MarkupLabelRef>;
  isNetworkScore?: Maybe<Scalars['Boolean']>;
};

export type Migration = {
  __typename?: 'Migration';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  appState: AppState;
  step: Scalars['Int'];
  note: Scalars['String'];
};


export type MigrationAppStateArgs = {
  filter?: Maybe<AppStateFilter>;
};

export type MigrationAggregateResult = {
  __typename?: 'MigrationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  stepMin?: Maybe<Scalars['Int']>;
  stepMax?: Maybe<Scalars['Int']>;
  stepSum?: Maybe<Scalars['Int']>;
  stepAvg?: Maybe<Scalars['Float']>;
  noteMin?: Maybe<Scalars['String']>;
  noteMax?: Maybe<Scalars['String']>;
};

export type MigrationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  step?: Maybe<IntFilter>;
  note?: Maybe<StringTermFilter>;
  has?: Maybe<Array<Maybe<MigrationHasFilter>>>;
  and?: Maybe<Array<Maybe<MigrationFilter>>>;
  or?: Maybe<Array<Maybe<MigrationFilter>>>;
  not?: Maybe<MigrationFilter>;
};

export enum MigrationHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  AppState = 'appState',
  Step = 'step',
  Note = 'note'
}

export type MigrationOrder = {
  asc?: Maybe<MigrationOrderable>;
  desc?: Maybe<MigrationOrderable>;
  then?: Maybe<MigrationOrder>;
};

export enum MigrationOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  Step = 'step',
  Note = 'note'
}

export type MigrationPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  appState?: Maybe<AppStateRef>;
  step?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
};

export type MigrationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  appState?: Maybe<AppStateRef>;
  step?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
};

export enum Mode {
  Batch = 'BATCH',
  Single = 'SINGLE'
}

export type ModuleJobRequest = {
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  module: PipelineModule;
  researchBoxes: Array<ResearchBox>;
  researchBoxesAggregate?: Maybe<ResearchBoxAggregateResult>;
};


export type ModuleJobRequestModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
};


export type ModuleJobRequestResearchBoxesArgs = {
  filter?: Maybe<ResearchBoxFilter>;
  order?: Maybe<ResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ModuleJobRequestResearchBoxesAggregateArgs = {
  filter?: Maybe<ResearchBoxFilter>;
};

export type ModuleJobRequestAggregateResult = {
  __typename?: 'ModuleJobRequestAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
};

export type ModuleJobRequestFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<ModuleJobRequestHasFilter>>>;
  and?: Maybe<Array<Maybe<ModuleJobRequestFilter>>>;
  or?: Maybe<Array<Maybe<ModuleJobRequestFilter>>>;
  not?: Maybe<ModuleJobRequestFilter>;
};

export enum ModuleJobRequestHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Module = 'module',
  ResearchBoxes = 'researchBoxes'
}

export type ModuleJobRequestOrder = {
  asc?: Maybe<ModuleJobRequestOrderable>;
  desc?: Maybe<ModuleJobRequestOrderable>;
  then?: Maybe<ModuleJobRequestOrder>;
};

export enum ModuleJobRequestOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner'
}

export type ModuleJobRequestPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  module?: Maybe<PipelineModuleRef>;
  researchBoxes?: Maybe<Array<ResearchBoxRef>>;
};

export type ModuleJobRequestRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export type MultiPolygon = {
  __typename?: 'MultiPolygon';
  polygons: Array<Polygon>;
};

export type MultiPolygonRef = {
  polygons: Array<PolygonRef>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addMigration?: Maybe<AddMigrationPayload>;
  updateMigration?: Maybe<UpdateMigrationPayload>;
  deleteMigration?: Maybe<DeleteMigrationPayload>;
  addAppState?: Maybe<AddAppStatePayload>;
  updateAppState?: Maybe<UpdateAppStatePayload>;
  deleteAppState?: Maybe<DeleteAppStatePayload>;
  deleteCuid?: Maybe<DeleteCuidPayload>;
  updateOwnable?: Maybe<UpdateOwnablePayload>;
  deleteOwnable?: Maybe<DeleteOwnablePayload>;
  updateReadOnly?: Maybe<UpdateReadOnlyPayload>;
  deleteReadOnly?: Maybe<DeleteReadOnlyPayload>;
  updateTimestamped?: Maybe<UpdateTimestampedPayload>;
  deleteTimestamped?: Maybe<DeleteTimestampedPayload>;
  updateNotification?: Maybe<UpdateNotificationPayload>;
  deleteNotification?: Maybe<DeleteNotificationPayload>;
  addProgressNotification?: Maybe<AddProgressNotificationPayload>;
  updateProgressNotification?: Maybe<UpdateProgressNotificationPayload>;
  deleteProgressNotification?: Maybe<DeleteProgressNotificationPayload>;
  addDenktesterNotification?: Maybe<AddDenktesterNotificationPayload>;
  updateDenktesterNotification?: Maybe<UpdateDenktesterNotificationPayload>;
  deleteDenktesterNotification?: Maybe<DeleteDenktesterNotificationPayload>;
  addMaintenanceNotification?: Maybe<AddMaintenanceNotificationPayload>;
  updateMaintenanceNotification?: Maybe<UpdateMaintenanceNotificationPayload>;
  deleteMaintenanceNotification?: Maybe<DeleteMaintenanceNotificationPayload>;
  addOneOffNotification?: Maybe<AddOneOffNotificationPayload>;
  updateOneOffNotification?: Maybe<UpdateOneOffNotificationPayload>;
  deleteOneOffNotification?: Maybe<DeleteOneOffNotificationPayload>;
  addUser?: Maybe<AddUserPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  addDataset?: Maybe<AddDatasetPayload>;
  updateDataset?: Maybe<UpdateDatasetPayload>;
  deleteDataset?: Maybe<DeleteDatasetPayload>;
  updateFolderOrResearchBox?: Maybe<UpdateFolderOrResearchBoxPayload>;
  deleteFolderOrResearchBox?: Maybe<DeleteFolderOrResearchBoxPayload>;
  addFolder?: Maybe<AddFolderPayload>;
  updateFolder?: Maybe<UpdateFolderPayload>;
  deleteFolder?: Maybe<DeleteFolderPayload>;
  addResearchBox?: Maybe<AddResearchBoxPayload>;
  updateResearchBox?: Maybe<UpdateResearchBoxPayload>;
  deleteResearchBox?: Maybe<DeleteResearchBoxPayload>;
  addProductionTag?: Maybe<AddProductionTagPayload>;
  updateProductionTag?: Maybe<UpdateProductionTagPayload>;
  deleteProductionTag?: Maybe<DeleteProductionTagPayload>;
  updateDatapoint?: Maybe<UpdateDatapointPayload>;
  deleteDatapoint?: Maybe<DeleteDatapointPayload>;
  addCCCheckerResult?: Maybe<AddCcCheckerResultPayload>;
  updateCCCheckerResult?: Maybe<UpdateCcCheckerResultPayload>;
  deleteCCCheckerResult?: Maybe<DeleteCcCheckerResultPayload>;
  addImageResolution?: Maybe<AddImageResolutionPayload>;
  updateImageResolution?: Maybe<UpdateImageResolutionPayload>;
  deleteImageResolution?: Maybe<DeleteImageResolutionPayload>;
  addImageMetadataField?: Maybe<AddImageMetadataFieldPayload>;
  updateImageMetadataField?: Maybe<UpdateImageMetadataFieldPayload>;
  deleteImageMetadataField?: Maybe<DeleteImageMetadataFieldPayload>;
  addImage?: Maybe<AddImagePayload>;
  updateImage?: Maybe<UpdateImagePayload>;
  deleteImage?: Maybe<DeleteImagePayload>;
  addMagneticFieldScan?: Maybe<AddMagneticFieldScanPayload>;
  updateMagneticFieldScan?: Maybe<UpdateMagneticFieldScanPayload>;
  deleteMagneticFieldScan?: Maybe<DeleteMagneticFieldScanPayload>;
  addMarkupLabel?: Maybe<AddMarkupLabelPayload>;
  updateMarkupLabel?: Maybe<UpdateMarkupLabelPayload>;
  deleteMarkupLabel?: Maybe<DeleteMarkupLabelPayload>;
  updateSegmentationImageAnnotation?: Maybe<UpdateSegmentationImageAnnotationPayload>;
  deleteSegmentationImageAnnotation?: Maybe<DeleteSegmentationImageAnnotationPayload>;
  addPoint2D?: Maybe<AddPoint2DPayload>;
  updatePoint2D?: Maybe<UpdatePoint2DPayload>;
  deletePoint2D?: Maybe<DeletePoint2DPayload>;
  addCircleAnnotation?: Maybe<AddCircleAnnotationPayload>;
  updateCircleAnnotation?: Maybe<UpdateCircleAnnotationPayload>;
  deleteCircleAnnotation?: Maybe<DeleteCircleAnnotationPayload>;
  addPolygonAnnotation?: Maybe<AddPolygonAnnotationPayload>;
  updatePolygonAnnotation?: Maybe<UpdatePolygonAnnotationPayload>;
  deletePolygonAnnotation?: Maybe<DeletePolygonAnnotationPayload>;
  addPenAnnotation?: Maybe<AddPenAnnotationPayload>;
  updatePenAnnotation?: Maybe<UpdatePenAnnotationPayload>;
  deletePenAnnotation?: Maybe<DeletePenAnnotationPayload>;
  addRectangleAnnotation?: Maybe<AddRectangleAnnotationPayload>;
  updateRectangleAnnotation?: Maybe<UpdateRectangleAnnotationPayload>;
  deleteRectangleAnnotation?: Maybe<DeleteRectangleAnnotationPayload>;
  addSausageAnnotation?: Maybe<AddSausageAnnotationPayload>;
  updateSausageAnnotation?: Maybe<UpdateSausageAnnotationPayload>;
  deleteSausageAnnotation?: Maybe<DeleteSausageAnnotationPayload>;
  addMagicwandAnnotation?: Maybe<AddMagicwandAnnotationPayload>;
  updateMagicwandAnnotation?: Maybe<UpdateMagicwandAnnotationPayload>;
  deleteMagicwandAnnotation?: Maybe<DeleteMagicwandAnnotationPayload>;
  addPixelAnnotation?: Maybe<AddPixelAnnotationPayload>;
  updatePixelAnnotation?: Maybe<UpdatePixelAnnotationPayload>;
  deletePixelAnnotation?: Maybe<DeletePixelAnnotationPayload>;
  addBoundingBoxAnnotation?: Maybe<AddBoundingBoxAnnotationPayload>;
  updateBoundingBoxAnnotation?: Maybe<UpdateBoundingBoxAnnotationPayload>;
  deleteBoundingBoxAnnotation?: Maybe<DeleteBoundingBoxAnnotationPayload>;
  addBoundingBoxPrediction?: Maybe<AddBoundingBoxPredictionPayload>;
  updateBoundingBoxPrediction?: Maybe<UpdateBoundingBoxPredictionPayload>;
  deleteBoundingBoxPrediction?: Maybe<DeleteBoundingBoxPredictionPayload>;
  addScalarAnnotation?: Maybe<AddScalarAnnotationPayload>;
  updateScalarAnnotation?: Maybe<UpdateScalarAnnotationPayload>;
  deleteScalarAnnotation?: Maybe<DeleteScalarAnnotationPayload>;
  addScalarPrediction?: Maybe<AddScalarPredictionPayload>;
  updateScalarPrediction?: Maybe<UpdateScalarPredictionPayload>;
  deleteScalarPrediction?: Maybe<DeleteScalarPredictionPayload>;
  updateImageMarkup?: Maybe<UpdateImageMarkupPayload>;
  deleteImageMarkup?: Maybe<DeleteImageMarkupPayload>;
  updateImagePrediction?: Maybe<UpdateImagePredictionPayload>;
  deleteImagePrediction?: Maybe<DeleteImagePredictionPayload>;
  addSegmentationMap2D?: Maybe<AddSegmentationMap2DPayload>;
  updateSegmentationMap2D?: Maybe<UpdateSegmentationMap2DPayload>;
  deleteSegmentationMap2D?: Maybe<DeleteSegmentationMap2DPayload>;
  addSegmentationMarkup?: Maybe<AddSegmentationMarkupPayload>;
  updateSegmentationMarkup?: Maybe<UpdateSegmentationMarkupPayload>;
  deleteSegmentationMarkup?: Maybe<DeleteSegmentationMarkupPayload>;
  addSegmentationPredictionObject?: Maybe<AddSegmentationPredictionObjectPayload>;
  updateSegmentationPredictionObject?: Maybe<UpdateSegmentationPredictionObjectPayload>;
  deleteSegmentationPredictionObject?: Maybe<DeleteSegmentationPredictionObjectPayload>;
  addSegmentationPrediction?: Maybe<AddSegmentationPredictionPayload>;
  updateSegmentationPrediction?: Maybe<UpdateSegmentationPredictionPayload>;
  deleteSegmentationPrediction?: Maybe<DeleteSegmentationPredictionPayload>;
  addClassificationMarkup?: Maybe<AddClassificationMarkupPayload>;
  updateClassificationMarkup?: Maybe<UpdateClassificationMarkupPayload>;
  deleteClassificationMarkup?: Maybe<DeleteClassificationMarkupPayload>;
  addClassificationPrediction?: Maybe<AddClassificationPredictionPayload>;
  updateClassificationPrediction?: Maybe<UpdateClassificationPredictionPayload>;
  deleteClassificationPrediction?: Maybe<DeleteClassificationPredictionPayload>;
  addInstanceSegmentationMarkup?: Maybe<AddInstanceSegmentationMarkupPayload>;
  updateInstanceSegmentationMarkup?: Maybe<UpdateInstanceSegmentationMarkupPayload>;
  deleteInstanceSegmentationMarkup?: Maybe<DeleteInstanceSegmentationMarkupPayload>;
  addInstanceSegmentationPrediction?: Maybe<AddInstanceSegmentationPredictionPayload>;
  updateInstanceSegmentationPrediction?: Maybe<UpdateInstanceSegmentationPredictionPayload>;
  deleteInstanceSegmentationPrediction?: Maybe<DeleteInstanceSegmentationPredictionPayload>;
  addObjectDetectionMarkup?: Maybe<AddObjectDetectionMarkupPayload>;
  updateObjectDetectionMarkup?: Maybe<UpdateObjectDetectionMarkupPayload>;
  deleteObjectDetectionMarkup?: Maybe<DeleteObjectDetectionMarkupPayload>;
  addObjectDetectionPrediction?: Maybe<AddObjectDetectionPredictionPayload>;
  updateObjectDetectionPrediction?: Maybe<UpdateObjectDetectionPredictionPayload>;
  deleteObjectDetectionPrediction?: Maybe<DeleteObjectDetectionPredictionPayload>;
  updateModuleJobRequest?: Maybe<UpdateModuleJobRequestPayload>;
  deleteModuleJobRequest?: Maybe<DeleteModuleJobRequestPayload>;
  addPipeline?: Maybe<AddPipelinePayload>;
  updatePipeline?: Maybe<UpdatePipelinePayload>;
  deletePipeline?: Maybe<DeletePipelinePayload>;
  updatePipelineModuleOutput?: Maybe<UpdatePipelineModuleOutputPayload>;
  deletePipelineModuleOutput?: Maybe<DeletePipelineModuleOutputPayload>;
  addPipelineModuleDatapointOutput?: Maybe<AddPipelineModuleDatapointOutputPayload>;
  updatePipelineModuleDatapointOutput?: Maybe<UpdatePipelineModuleDatapointOutputPayload>;
  deletePipelineModuleDatapointOutput?: Maybe<DeletePipelineModuleDatapointOutputPayload>;
  addPipelineModuleArtifactOutput?: Maybe<AddPipelineModuleArtifactOutputPayload>;
  updatePipelineModuleArtifactOutput?: Maybe<UpdatePipelineModuleArtifactOutputPayload>;
  deletePipelineModuleArtifactOutput?: Maybe<DeletePipelineModuleArtifactOutputPayload>;
  addPipelineModuleInput?: Maybe<AddPipelineModuleInputPayload>;
  updatePipelineModuleInput?: Maybe<UpdatePipelineModuleInputPayload>;
  deletePipelineModuleInput?: Maybe<DeletePipelineModuleInputPayload>;
  addPipelineModuleEdge?: Maybe<AddPipelineModuleEdgePayload>;
  updatePipelineModuleEdge?: Maybe<UpdatePipelineModuleEdgePayload>;
  deletePipelineModuleEdge?: Maybe<DeletePipelineModuleEdgePayload>;
  updatePipelineModule?: Maybe<UpdatePipelineModulePayload>;
  deletePipelineModule?: Maybe<DeletePipelineModulePayload>;
  addPipelineModuleUpload?: Maybe<AddPipelineModuleUploadPayload>;
  updatePipelineModuleUpload?: Maybe<UpdatePipelineModuleUploadPayload>;
  deletePipelineModuleUpload?: Maybe<DeletePipelineModuleUploadPayload>;
  addPipelineModuleBMobile?: Maybe<AddPipelineModuleBMobilePayload>;
  updatePipelineModuleBMobile?: Maybe<UpdatePipelineModuleBMobilePayload>;
  deletePipelineModuleBMobile?: Maybe<DeletePipelineModuleBMobilePayload>;
  addPipelineModuleCCChecker?: Maybe<AddPipelineModuleCcCheckerPayload>;
  updatePipelineModuleCCChecker?: Maybe<UpdatePipelineModuleCcCheckerPayload>;
  deletePipelineModuleCCChecker?: Maybe<DeletePipelineModuleCcCheckerPayload>;
  addPipelineModuleJobRequestCCChecker?: Maybe<AddPipelineModuleJobRequestCcCheckerPayload>;
  updatePipelineModuleJobRequestCCChecker?: Maybe<UpdatePipelineModuleJobRequestCcCheckerPayload>;
  deletePipelineModuleJobRequestCCChecker?: Maybe<DeletePipelineModuleJobRequestCcCheckerPayload>;
  addPipelineModuleContrastEnhancement?: Maybe<AddPipelineModuleContrastEnhancementPayload>;
  updatePipelineModuleContrastEnhancement?: Maybe<UpdatePipelineModuleContrastEnhancementPayload>;
  deletePipelineModuleContrastEnhancement?: Maybe<DeletePipelineModuleContrastEnhancementPayload>;
  addPipelineModuleJobRequestContrastEnhancement?: Maybe<AddPipelineModuleJobRequestContrastEnhancementPayload>;
  updatePipelineModuleJobRequestContrastEnhancement?: Maybe<UpdatePipelineModuleJobRequestContrastEnhancementPayload>;
  deletePipelineModuleJobRequestContrastEnhancement?: Maybe<DeletePipelineModuleJobRequestContrastEnhancementPayload>;
  addGpu?: Maybe<AddGpuPayload>;
  updateGpu?: Maybe<UpdateGpuPayload>;
  deleteGpu?: Maybe<DeleteGpuPayload>;
  addDenkbox?: Maybe<AddDenkboxPayload>;
  updateDenkbox?: Maybe<UpdateDenkboxPayload>;
  deleteDenkbox?: Maybe<DeleteDenkboxPayload>;
  addDenkjob?: Maybe<AddDenkjobPayload>;
  updateDenkjob?: Maybe<UpdateDenkjobPayload>;
  deleteDenkjob?: Maybe<DeleteDenkjobPayload>;
  addMetric?: Maybe<AddMetricPayload>;
  updateMetric?: Maybe<UpdateMetricPayload>;
  deleteMetric?: Maybe<DeleteMetricPayload>;
  addBinary?: Maybe<AddBinaryPayload>;
  updateBinary?: Maybe<UpdateBinaryPayload>;
  deleteBinary?: Maybe<DeleteBinaryPayload>;
  addEncryptedBinary?: Maybe<AddEncryptedBinaryPayload>;
  updateEncryptedBinary?: Maybe<UpdateEncryptedBinaryPayload>;
  deleteEncryptedBinary?: Maybe<DeleteEncryptedBinaryPayload>;
  addNetworkSnapshot?: Maybe<AddNetworkSnapshotPayload>;
  updateNetworkSnapshot?: Maybe<UpdateNetworkSnapshotPayload>;
  deleteNetworkSnapshot?: Maybe<DeleteNetworkSnapshotPayload>;
  addNetworkConfig?: Maybe<AddNetworkConfigPayload>;
  updateNetworkConfig?: Maybe<UpdateNetworkConfigPayload>;
  deleteNetworkConfig?: Maybe<DeleteNetworkConfigPayload>;
  updateNetworkModule?: Maybe<UpdateNetworkModulePayload>;
  deleteNetworkModule?: Maybe<DeleteNetworkModulePayload>;
  addPipelineModuleNetworkImageSegmentation?: Maybe<AddPipelineModuleNetworkImageSegmentationPayload>;
  updatePipelineModuleNetworkImageSegmentation?: Maybe<UpdatePipelineModuleNetworkImageSegmentationPayload>;
  deletePipelineModuleNetworkImageSegmentation?: Maybe<DeletePipelineModuleNetworkImageSegmentationPayload>;
  addPipelineModuleNetworkImageClassification?: Maybe<AddPipelineModuleNetworkImageClassificationPayload>;
  updatePipelineModuleNetworkImageClassification?: Maybe<UpdatePipelineModuleNetworkImageClassificationPayload>;
  deletePipelineModuleNetworkImageClassification?: Maybe<DeletePipelineModuleNetworkImageClassificationPayload>;
  addPipelineModuleNetworkImageObjectDetection?: Maybe<AddPipelineModuleNetworkImageObjectDetectionPayload>;
  updatePipelineModuleNetworkImageObjectDetection?: Maybe<UpdatePipelineModuleNetworkImageObjectDetectionPayload>;
  deletePipelineModuleNetworkImageObjectDetection?: Maybe<DeletePipelineModuleNetworkImageObjectDetectionPayload>;
  addPipelineModuleNetworkImageInstanceSegmentation?: Maybe<AddPipelineModuleNetworkImageInstanceSegmentationPayload>;
  updatePipelineModuleNetworkImageInstanceSegmentation?: Maybe<UpdatePipelineModuleNetworkImageInstanceSegmentationPayload>;
  deletePipelineModuleNetworkImageInstanceSegmentation?: Maybe<DeletePipelineModuleNetworkImageInstanceSegmentationPayload>;
  addPipelineModuleNetworkImageSuperResolution?: Maybe<AddPipelineModuleNetworkImageSuperResolutionPayload>;
  updatePipelineModuleNetworkImageSuperResolution?: Maybe<UpdatePipelineModuleNetworkImageSuperResolutionPayload>;
  deletePipelineModuleNetworkImageSuperResolution?: Maybe<DeletePipelineModuleNetworkImageSuperResolutionPayload>;
  addPipelineModuleNetworkImageOCR?: Maybe<AddPipelineModuleNetworkImageOcrPayload>;
  updatePipelineModuleNetworkImageOCR?: Maybe<UpdatePipelineModuleNetworkImageOcrPayload>;
  deletePipelineModuleNetworkImageOCR?: Maybe<DeletePipelineModuleNetworkImageOcrPayload>;
  addPipelineModuleNetworkImageAnomalyDetection?: Maybe<AddPipelineModuleNetworkImageAnomalyDetectionPayload>;
  updatePipelineModuleNetworkImageAnomalyDetection?: Maybe<UpdatePipelineModuleNetworkImageAnomalyDetectionPayload>;
  deletePipelineModuleNetworkImageAnomalyDetection?: Maybe<DeletePipelineModuleNetworkImageAnomalyDetectionPayload>;
  addPipelineModuleNetworkImageObjectCounting?: Maybe<AddPipelineModuleNetworkImageObjectCountingPayload>;
  updatePipelineModuleNetworkImageObjectCounting?: Maybe<UpdatePipelineModuleNetworkImageObjectCountingPayload>;
  deletePipelineModuleNetworkImageObjectCounting?: Maybe<DeletePipelineModuleNetworkImageObjectCountingPayload>;
  addPretrainedNetwork?: Maybe<AddPretrainedNetworkPayload>;
  updatePretrainedNetwork?: Maybe<UpdatePretrainedNetworkPayload>;
  deletePretrainedNetwork?: Maybe<DeletePretrainedNetworkPayload>;
};


export type MutationAddMigrationArgs = {
  input: Array<AddMigrationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMigrationArgs = {
  input: UpdateMigrationInput;
};


export type MutationDeleteMigrationArgs = {
  filter: MigrationFilter;
};


export type MutationAddAppStateArgs = {
  input: Array<AddAppStateInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateAppStateArgs = {
  input: UpdateAppStateInput;
};


export type MutationDeleteAppStateArgs = {
  filter: AppStateFilter;
};


export type MutationDeleteCuidArgs = {
  filter: CuidFilter;
};


export type MutationUpdateOwnableArgs = {
  input: UpdateOwnableInput;
};


export type MutationDeleteOwnableArgs = {
  filter: OwnableFilter;
};


export type MutationUpdateReadOnlyArgs = {
  input: UpdateReadOnlyInput;
};


export type MutationDeleteReadOnlyArgs = {
  filter: ReadOnlyFilter;
};


export type MutationUpdateTimestampedArgs = {
  input: UpdateTimestampedInput;
};


export type MutationDeleteTimestampedArgs = {
  filter: TimestampedFilter;
};


export type MutationUpdateNotificationArgs = {
  input: UpdateNotificationInput;
};


export type MutationDeleteNotificationArgs = {
  filter: NotificationFilter;
};


export type MutationAddProgressNotificationArgs = {
  input: Array<AddProgressNotificationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateProgressNotificationArgs = {
  input: UpdateProgressNotificationInput;
};


export type MutationDeleteProgressNotificationArgs = {
  filter: ProgressNotificationFilter;
};


export type MutationAddDenktesterNotificationArgs = {
  input: Array<AddDenktesterNotificationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateDenktesterNotificationArgs = {
  input: UpdateDenktesterNotificationInput;
};


export type MutationDeleteDenktesterNotificationArgs = {
  filter: DenktesterNotificationFilter;
};


export type MutationAddMaintenanceNotificationArgs = {
  input: Array<AddMaintenanceNotificationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMaintenanceNotificationArgs = {
  input: UpdateMaintenanceNotificationInput;
};


export type MutationDeleteMaintenanceNotificationArgs = {
  filter: MaintenanceNotificationFilter;
};


export type MutationAddOneOffNotificationArgs = {
  input: Array<AddOneOffNotificationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateOneOffNotificationArgs = {
  input: UpdateOneOffNotificationInput;
};


export type MutationDeleteOneOffNotificationArgs = {
  filter: OneOffNotificationFilter;
};


export type MutationAddUserArgs = {
  input: Array<AddUserInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationDeleteUserArgs = {
  filter: UserFilter;
};


export type MutationAddDatasetArgs = {
  input: Array<AddDatasetInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateDatasetArgs = {
  input: UpdateDatasetInput;
};


export type MutationDeleteDatasetArgs = {
  filter: DatasetFilter;
};


export type MutationUpdateFolderOrResearchBoxArgs = {
  input: UpdateFolderOrResearchBoxInput;
};


export type MutationDeleteFolderOrResearchBoxArgs = {
  filter: FolderOrResearchBoxFilter;
};


export type MutationAddFolderArgs = {
  input: Array<AddFolderInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateFolderArgs = {
  input: UpdateFolderInput;
};


export type MutationDeleteFolderArgs = {
  filter: FolderFilter;
};


export type MutationAddResearchBoxArgs = {
  input: Array<AddResearchBoxInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateResearchBoxArgs = {
  input: UpdateResearchBoxInput;
};


export type MutationDeleteResearchBoxArgs = {
  filter: ResearchBoxFilter;
};


export type MutationAddProductionTagArgs = {
  input: Array<AddProductionTagInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateProductionTagArgs = {
  input: UpdateProductionTagInput;
};


export type MutationDeleteProductionTagArgs = {
  filter: ProductionTagFilter;
};


export type MutationUpdateDatapointArgs = {
  input: UpdateDatapointInput;
};


export type MutationDeleteDatapointArgs = {
  filter: DatapointFilter;
};


export type MutationAddCcCheckerResultArgs = {
  input: Array<AddCcCheckerResultInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateCcCheckerResultArgs = {
  input: UpdateCcCheckerResultInput;
};


export type MutationDeleteCcCheckerResultArgs = {
  filter: CcCheckerResultFilter;
};


export type MutationAddImageResolutionArgs = {
  input: Array<AddImageResolutionInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateImageResolutionArgs = {
  input: UpdateImageResolutionInput;
};


export type MutationDeleteImageResolutionArgs = {
  filter: ImageResolutionFilter;
};


export type MutationAddImageMetadataFieldArgs = {
  input: Array<AddImageMetadataFieldInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateImageMetadataFieldArgs = {
  input: UpdateImageMetadataFieldInput;
};


export type MutationDeleteImageMetadataFieldArgs = {
  filter: ImageMetadataFieldFilter;
};


export type MutationAddImageArgs = {
  input: Array<AddImageInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateImageArgs = {
  input: UpdateImageInput;
};


export type MutationDeleteImageArgs = {
  filter: ImageFilter;
};


export type MutationAddMagneticFieldScanArgs = {
  input: Array<AddMagneticFieldScanInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMagneticFieldScanArgs = {
  input: UpdateMagneticFieldScanInput;
};


export type MutationDeleteMagneticFieldScanArgs = {
  filter: MagneticFieldScanFilter;
};


export type MutationAddMarkupLabelArgs = {
  input: Array<AddMarkupLabelInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMarkupLabelArgs = {
  input: UpdateMarkupLabelInput;
};


export type MutationDeleteMarkupLabelArgs = {
  filter: MarkupLabelFilter;
};


export type MutationUpdateSegmentationImageAnnotationArgs = {
  input: UpdateSegmentationImageAnnotationInput;
};


export type MutationDeleteSegmentationImageAnnotationArgs = {
  filter: SegmentationImageAnnotationFilter;
};


export type MutationAddPoint2DArgs = {
  input: Array<AddPoint2DInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePoint2DArgs = {
  input: UpdatePoint2DInput;
};


export type MutationDeletePoint2DArgs = {
  filter: Point2DFilter;
};


export type MutationAddCircleAnnotationArgs = {
  input: Array<AddCircleAnnotationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateCircleAnnotationArgs = {
  input: UpdateCircleAnnotationInput;
};


export type MutationDeleteCircleAnnotationArgs = {
  filter: CircleAnnotationFilter;
};


export type MutationAddPolygonAnnotationArgs = {
  input: Array<AddPolygonAnnotationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePolygonAnnotationArgs = {
  input: UpdatePolygonAnnotationInput;
};


export type MutationDeletePolygonAnnotationArgs = {
  filter: PolygonAnnotationFilter;
};


export type MutationAddPenAnnotationArgs = {
  input: Array<AddPenAnnotationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePenAnnotationArgs = {
  input: UpdatePenAnnotationInput;
};


export type MutationDeletePenAnnotationArgs = {
  filter: PenAnnotationFilter;
};


export type MutationAddRectangleAnnotationArgs = {
  input: Array<AddRectangleAnnotationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateRectangleAnnotationArgs = {
  input: UpdateRectangleAnnotationInput;
};


export type MutationDeleteRectangleAnnotationArgs = {
  filter: RectangleAnnotationFilter;
};


export type MutationAddSausageAnnotationArgs = {
  input: Array<AddSausageAnnotationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateSausageAnnotationArgs = {
  input: UpdateSausageAnnotationInput;
};


export type MutationDeleteSausageAnnotationArgs = {
  filter: SausageAnnotationFilter;
};


export type MutationAddMagicwandAnnotationArgs = {
  input: Array<AddMagicwandAnnotationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMagicwandAnnotationArgs = {
  input: UpdateMagicwandAnnotationInput;
};


export type MutationDeleteMagicwandAnnotationArgs = {
  filter: MagicwandAnnotationFilter;
};


export type MutationAddPixelAnnotationArgs = {
  input: Array<AddPixelAnnotationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePixelAnnotationArgs = {
  input: UpdatePixelAnnotationInput;
};


export type MutationDeletePixelAnnotationArgs = {
  filter: PixelAnnotationFilter;
};


export type MutationAddBoundingBoxAnnotationArgs = {
  input: Array<AddBoundingBoxAnnotationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateBoundingBoxAnnotationArgs = {
  input: UpdateBoundingBoxAnnotationInput;
};


export type MutationDeleteBoundingBoxAnnotationArgs = {
  filter: BoundingBoxAnnotationFilter;
};


export type MutationAddBoundingBoxPredictionArgs = {
  input: Array<AddBoundingBoxPredictionInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateBoundingBoxPredictionArgs = {
  input: UpdateBoundingBoxPredictionInput;
};


export type MutationDeleteBoundingBoxPredictionArgs = {
  filter: BoundingBoxPredictionFilter;
};


export type MutationAddScalarAnnotationArgs = {
  input: Array<AddScalarAnnotationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateScalarAnnotationArgs = {
  input: UpdateScalarAnnotationInput;
};


export type MutationDeleteScalarAnnotationArgs = {
  filter: ScalarAnnotationFilter;
};


export type MutationAddScalarPredictionArgs = {
  input: Array<AddScalarPredictionInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateScalarPredictionArgs = {
  input: UpdateScalarPredictionInput;
};


export type MutationDeleteScalarPredictionArgs = {
  filter: ScalarPredictionFilter;
};


export type MutationUpdateImageMarkupArgs = {
  input: UpdateImageMarkupInput;
};


export type MutationDeleteImageMarkupArgs = {
  filter: ImageMarkupFilter;
};


export type MutationUpdateImagePredictionArgs = {
  input: UpdateImagePredictionInput;
};


export type MutationDeleteImagePredictionArgs = {
  filter: ImagePredictionFilter;
};


export type MutationAddSegmentationMap2DArgs = {
  input: Array<AddSegmentationMap2DInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateSegmentationMap2DArgs = {
  input: UpdateSegmentationMap2DInput;
};


export type MutationDeleteSegmentationMap2DArgs = {
  filter: SegmentationMap2DFilter;
};


export type MutationAddSegmentationMarkupArgs = {
  input: Array<AddSegmentationMarkupInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateSegmentationMarkupArgs = {
  input: UpdateSegmentationMarkupInput;
};


export type MutationDeleteSegmentationMarkupArgs = {
  filter: SegmentationMarkupFilter;
};


export type MutationAddSegmentationPredictionObjectArgs = {
  input: Array<AddSegmentationPredictionObjectInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateSegmentationPredictionObjectArgs = {
  input: UpdateSegmentationPredictionObjectInput;
};


export type MutationDeleteSegmentationPredictionObjectArgs = {
  filter: SegmentationPredictionObjectFilter;
};


export type MutationAddSegmentationPredictionArgs = {
  input: Array<AddSegmentationPredictionInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateSegmentationPredictionArgs = {
  input: UpdateSegmentationPredictionInput;
};


export type MutationDeleteSegmentationPredictionArgs = {
  filter: SegmentationPredictionFilter;
};


export type MutationAddClassificationMarkupArgs = {
  input: Array<AddClassificationMarkupInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateClassificationMarkupArgs = {
  input: UpdateClassificationMarkupInput;
};


export type MutationDeleteClassificationMarkupArgs = {
  filter: ClassificationMarkupFilter;
};


export type MutationAddClassificationPredictionArgs = {
  input: Array<AddClassificationPredictionInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateClassificationPredictionArgs = {
  input: UpdateClassificationPredictionInput;
};


export type MutationDeleteClassificationPredictionArgs = {
  filter: ClassificationPredictionFilter;
};


export type MutationAddInstanceSegmentationMarkupArgs = {
  input: Array<AddInstanceSegmentationMarkupInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateInstanceSegmentationMarkupArgs = {
  input: UpdateInstanceSegmentationMarkupInput;
};


export type MutationDeleteInstanceSegmentationMarkupArgs = {
  filter: InstanceSegmentationMarkupFilter;
};


export type MutationAddInstanceSegmentationPredictionArgs = {
  input: Array<AddInstanceSegmentationPredictionInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateInstanceSegmentationPredictionArgs = {
  input: UpdateInstanceSegmentationPredictionInput;
};


export type MutationDeleteInstanceSegmentationPredictionArgs = {
  filter: InstanceSegmentationPredictionFilter;
};


export type MutationAddObjectDetectionMarkupArgs = {
  input: Array<AddObjectDetectionMarkupInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateObjectDetectionMarkupArgs = {
  input: UpdateObjectDetectionMarkupInput;
};


export type MutationDeleteObjectDetectionMarkupArgs = {
  filter: ObjectDetectionMarkupFilter;
};


export type MutationAddObjectDetectionPredictionArgs = {
  input: Array<AddObjectDetectionPredictionInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateObjectDetectionPredictionArgs = {
  input: UpdateObjectDetectionPredictionInput;
};


export type MutationDeleteObjectDetectionPredictionArgs = {
  filter: ObjectDetectionPredictionFilter;
};


export type MutationUpdateModuleJobRequestArgs = {
  input: UpdateModuleJobRequestInput;
};


export type MutationDeleteModuleJobRequestArgs = {
  filter: ModuleJobRequestFilter;
};


export type MutationAddPipelineArgs = {
  input: Array<AddPipelineInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineArgs = {
  input: UpdatePipelineInput;
};


export type MutationDeletePipelineArgs = {
  filter: PipelineFilter;
};


export type MutationUpdatePipelineModuleOutputArgs = {
  input: UpdatePipelineModuleOutputInput;
};


export type MutationDeletePipelineModuleOutputArgs = {
  filter: PipelineModuleOutputFilter;
};


export type MutationAddPipelineModuleDatapointOutputArgs = {
  input: Array<AddPipelineModuleDatapointOutputInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleDatapointOutputArgs = {
  input: UpdatePipelineModuleDatapointOutputInput;
};


export type MutationDeletePipelineModuleDatapointOutputArgs = {
  filter: PipelineModuleDatapointOutputFilter;
};


export type MutationAddPipelineModuleArtifactOutputArgs = {
  input: Array<AddPipelineModuleArtifactOutputInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleArtifactOutputArgs = {
  input: UpdatePipelineModuleArtifactOutputInput;
};


export type MutationDeletePipelineModuleArtifactOutputArgs = {
  filter: PipelineModuleArtifactOutputFilter;
};


export type MutationAddPipelineModuleInputArgs = {
  input: Array<AddPipelineModuleInputInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleInputArgs = {
  input: UpdatePipelineModuleInputInput;
};


export type MutationDeletePipelineModuleInputArgs = {
  filter: PipelineModuleInputFilter;
};


export type MutationAddPipelineModuleEdgeArgs = {
  input: Array<AddPipelineModuleEdgeInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleEdgeArgs = {
  input: UpdatePipelineModuleEdgeInput;
};


export type MutationDeletePipelineModuleEdgeArgs = {
  filter: PipelineModuleEdgeFilter;
};


export type MutationUpdatePipelineModuleArgs = {
  input: UpdatePipelineModuleInput;
};


export type MutationDeletePipelineModuleArgs = {
  filter: PipelineModuleFilter;
};


export type MutationAddPipelineModuleUploadArgs = {
  input: Array<AddPipelineModuleUploadInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleUploadArgs = {
  input: UpdatePipelineModuleUploadInput;
};


export type MutationDeletePipelineModuleUploadArgs = {
  filter: PipelineModuleUploadFilter;
};


export type MutationAddPipelineModuleBMobileArgs = {
  input: Array<AddPipelineModuleBMobileInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleBMobileArgs = {
  input: UpdatePipelineModuleBMobileInput;
};


export type MutationDeletePipelineModuleBMobileArgs = {
  filter: PipelineModuleBMobileFilter;
};


export type MutationAddPipelineModuleCcCheckerArgs = {
  input: Array<AddPipelineModuleCcCheckerInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleCcCheckerArgs = {
  input: UpdatePipelineModuleCcCheckerInput;
};


export type MutationDeletePipelineModuleCcCheckerArgs = {
  filter: PipelineModuleCcCheckerFilter;
};


export type MutationAddPipelineModuleJobRequestCcCheckerArgs = {
  input: Array<AddPipelineModuleJobRequestCcCheckerInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleJobRequestCcCheckerArgs = {
  input: UpdatePipelineModuleJobRequestCcCheckerInput;
};


export type MutationDeletePipelineModuleJobRequestCcCheckerArgs = {
  filter: PipelineModuleJobRequestCcCheckerFilter;
};


export type MutationAddPipelineModuleContrastEnhancementArgs = {
  input: Array<AddPipelineModuleContrastEnhancementInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleContrastEnhancementArgs = {
  input: UpdatePipelineModuleContrastEnhancementInput;
};


export type MutationDeletePipelineModuleContrastEnhancementArgs = {
  filter: PipelineModuleContrastEnhancementFilter;
};


export type MutationAddPipelineModuleJobRequestContrastEnhancementArgs = {
  input: Array<AddPipelineModuleJobRequestContrastEnhancementInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleJobRequestContrastEnhancementArgs = {
  input: UpdatePipelineModuleJobRequestContrastEnhancementInput;
};


export type MutationDeletePipelineModuleJobRequestContrastEnhancementArgs = {
  filter: PipelineModuleJobRequestContrastEnhancementFilter;
};


export type MutationAddGpuArgs = {
  input: Array<AddGpuInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateGpuArgs = {
  input: UpdateGpuInput;
};


export type MutationDeleteGpuArgs = {
  filter: GpuFilter;
};


export type MutationAddDenkboxArgs = {
  input: Array<AddDenkboxInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateDenkboxArgs = {
  input: UpdateDenkboxInput;
};


export type MutationDeleteDenkboxArgs = {
  filter: DenkboxFilter;
};


export type MutationAddDenkjobArgs = {
  input: Array<AddDenkjobInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateDenkjobArgs = {
  input: UpdateDenkjobInput;
};


export type MutationDeleteDenkjobArgs = {
  filter: DenkjobFilter;
};


export type MutationAddMetricArgs = {
  input: Array<AddMetricInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMetricArgs = {
  input: UpdateMetricInput;
};


export type MutationDeleteMetricArgs = {
  filter: MetricFilter;
};


export type MutationAddBinaryArgs = {
  input: Array<AddBinaryInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateBinaryArgs = {
  input: UpdateBinaryInput;
};


export type MutationDeleteBinaryArgs = {
  filter: BinaryFilter;
};


export type MutationAddEncryptedBinaryArgs = {
  input: Array<AddEncryptedBinaryInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateEncryptedBinaryArgs = {
  input: UpdateEncryptedBinaryInput;
};


export type MutationDeleteEncryptedBinaryArgs = {
  filter: EncryptedBinaryFilter;
};


export type MutationAddNetworkSnapshotArgs = {
  input: Array<AddNetworkSnapshotInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateNetworkSnapshotArgs = {
  input: UpdateNetworkSnapshotInput;
};


export type MutationDeleteNetworkSnapshotArgs = {
  filter: NetworkSnapshotFilter;
};


export type MutationAddNetworkConfigArgs = {
  input: Array<AddNetworkConfigInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateNetworkConfigArgs = {
  input: UpdateNetworkConfigInput;
};


export type MutationDeleteNetworkConfigArgs = {
  filter: NetworkConfigFilter;
};


export type MutationUpdateNetworkModuleArgs = {
  input: UpdateNetworkModuleInput;
};


export type MutationDeleteNetworkModuleArgs = {
  filter: NetworkModuleFilter;
};


export type MutationAddPipelineModuleNetworkImageSegmentationArgs = {
  input: Array<AddPipelineModuleNetworkImageSegmentationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleNetworkImageSegmentationArgs = {
  input: UpdatePipelineModuleNetworkImageSegmentationInput;
};


export type MutationDeletePipelineModuleNetworkImageSegmentationArgs = {
  filter: PipelineModuleNetworkImageSegmentationFilter;
};


export type MutationAddPipelineModuleNetworkImageClassificationArgs = {
  input: Array<AddPipelineModuleNetworkImageClassificationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleNetworkImageClassificationArgs = {
  input: UpdatePipelineModuleNetworkImageClassificationInput;
};


export type MutationDeletePipelineModuleNetworkImageClassificationArgs = {
  filter: PipelineModuleNetworkImageClassificationFilter;
};


export type MutationAddPipelineModuleNetworkImageObjectDetectionArgs = {
  input: Array<AddPipelineModuleNetworkImageObjectDetectionInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleNetworkImageObjectDetectionArgs = {
  input: UpdatePipelineModuleNetworkImageObjectDetectionInput;
};


export type MutationDeletePipelineModuleNetworkImageObjectDetectionArgs = {
  filter: PipelineModuleNetworkImageObjectDetectionFilter;
};


export type MutationAddPipelineModuleNetworkImageInstanceSegmentationArgs = {
  input: Array<AddPipelineModuleNetworkImageInstanceSegmentationInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleNetworkImageInstanceSegmentationArgs = {
  input: UpdatePipelineModuleNetworkImageInstanceSegmentationInput;
};


export type MutationDeletePipelineModuleNetworkImageInstanceSegmentationArgs = {
  filter: PipelineModuleNetworkImageInstanceSegmentationFilter;
};


export type MutationAddPipelineModuleNetworkImageSuperResolutionArgs = {
  input: Array<AddPipelineModuleNetworkImageSuperResolutionInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleNetworkImageSuperResolutionArgs = {
  input: UpdatePipelineModuleNetworkImageSuperResolutionInput;
};


export type MutationDeletePipelineModuleNetworkImageSuperResolutionArgs = {
  filter: PipelineModuleNetworkImageSuperResolutionFilter;
};


export type MutationAddPipelineModuleNetworkImageOcrArgs = {
  input: Array<AddPipelineModuleNetworkImageOcrInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleNetworkImageOcrArgs = {
  input: UpdatePipelineModuleNetworkImageOcrInput;
};


export type MutationDeletePipelineModuleNetworkImageOcrArgs = {
  filter: PipelineModuleNetworkImageOcrFilter;
};


export type MutationAddPipelineModuleNetworkImageAnomalyDetectionArgs = {
  input: Array<AddPipelineModuleNetworkImageAnomalyDetectionInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleNetworkImageAnomalyDetectionArgs = {
  input: UpdatePipelineModuleNetworkImageAnomalyDetectionInput;
};


export type MutationDeletePipelineModuleNetworkImageAnomalyDetectionArgs = {
  filter: PipelineModuleNetworkImageAnomalyDetectionFilter;
};


export type MutationAddPipelineModuleNetworkImageObjectCountingArgs = {
  input: Array<AddPipelineModuleNetworkImageObjectCountingInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePipelineModuleNetworkImageObjectCountingArgs = {
  input: UpdatePipelineModuleNetworkImageObjectCountingInput;
};


export type MutationDeletePipelineModuleNetworkImageObjectCountingArgs = {
  filter: PipelineModuleNetworkImageObjectCountingFilter;
};


export type MutationAddPretrainedNetworkArgs = {
  input: Array<AddPretrainedNetworkInput>;
  upsert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePretrainedNetworkArgs = {
  input: UpdatePretrainedNetworkInput;
};


export type MutationDeletePretrainedNetworkArgs = {
  filter: PretrainedNetworkFilter;
};

export type NearFilter = {
  distance: Scalars['Float'];
  coordinate: PointRef;
};

export type NetworkConfig = Cuid & Ownable & Timestamped & {
  __typename?: 'NetworkConfig';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  batchSize?: Maybe<Scalars['Int']>;
  trainingUsage?: Maybe<Scalars['Int']>;
  inferenceUsage?: Maybe<Scalars['Int']>;
  network: NetworkModule;
};


export type NetworkConfigNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};

export type NetworkConfigAggregateResult = {
  __typename?: 'NetworkConfigAggregateResult';
  count?: Maybe<Scalars['Int']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  batchSizeMin?: Maybe<Scalars['Int']>;
  batchSizeMax?: Maybe<Scalars['Int']>;
  batchSizeSum?: Maybe<Scalars['Int']>;
  batchSizeAvg?: Maybe<Scalars['Float']>;
  trainingUsageMin?: Maybe<Scalars['Int']>;
  trainingUsageMax?: Maybe<Scalars['Int']>;
  trainingUsageSum?: Maybe<Scalars['Int']>;
  trainingUsageAvg?: Maybe<Scalars['Float']>;
  inferenceUsageMin?: Maybe<Scalars['Int']>;
  inferenceUsageMax?: Maybe<Scalars['Int']>;
  inferenceUsageSum?: Maybe<Scalars['Int']>;
  inferenceUsageAvg?: Maybe<Scalars['Float']>;
};

export type NetworkConfigFilter = {
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<NetworkConfigHasFilter>>>;
  and?: Maybe<Array<Maybe<NetworkConfigFilter>>>;
  or?: Maybe<Array<Maybe<NetworkConfigFilter>>>;
  not?: Maybe<NetworkConfigFilter>;
};

export enum NetworkConfigHasFilter {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Id = 'id',
  BatchSize = 'batchSize',
  TrainingUsage = 'trainingUsage',
  InferenceUsage = 'inferenceUsage',
  Network = 'network'
}

export type NetworkConfigOrder = {
  asc?: Maybe<NetworkConfigOrderable>;
  desc?: Maybe<NetworkConfigOrderable>;
  then?: Maybe<NetworkConfigOrder>;
};

export enum NetworkConfigOrderable {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Id = 'id',
  BatchSize = 'batchSize',
  TrainingUsage = 'trainingUsage',
  InferenceUsage = 'inferenceUsage'
}

export type NetworkConfigPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  batchSize?: Maybe<Scalars['Int']>;
  trainingUsage?: Maybe<Scalars['Int']>;
  inferenceUsage?: Maybe<Scalars['Int']>;
  network?: Maybe<NetworkModuleRef>;
};

export type NetworkConfigRef = {
  rootID?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  batchSize?: Maybe<Scalars['Int']>;
  trainingUsage?: Maybe<Scalars['Int']>;
  inferenceUsage?: Maybe<Scalars['Int']>;
  network?: Maybe<NetworkModuleRef>;
};

export type NetworkModule = {
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabel>;
  markups: Array<ImageMarkup>;
  predictions: Array<ImagePrediction>;
  snapshots: Array<NetworkSnapshot>;
  config?: Maybe<NetworkConfig>;
  denkjobs: Array<Denkjob>;
  classLabelsAggregate?: Maybe<MarkupLabelAggregateResult>;
  markupsAggregate?: Maybe<ImageMarkupAggregateResult>;
  predictionsAggregate?: Maybe<ImagePredictionAggregateResult>;
  snapshotsAggregate?: Maybe<NetworkSnapshotAggregateResult>;
  denkjobsAggregate?: Maybe<DenkjobAggregateResult>;
};


export type NetworkModuleClassLabelsArgs = {
  filter?: Maybe<MarkupLabelFilter>;
  order?: Maybe<MarkupLabelOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type NetworkModuleMarkupsArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type NetworkModulePredictionsArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type NetworkModuleSnapshotsArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
  order?: Maybe<NetworkSnapshotOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type NetworkModuleConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
};


export type NetworkModuleDenkjobsArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type NetworkModuleClassLabelsAggregateArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type NetworkModuleMarkupsAggregateArgs = {
  filter?: Maybe<ImageMarkupFilter>;
};


export type NetworkModulePredictionsAggregateArgs = {
  filter?: Maybe<ImagePredictionFilter>;
};


export type NetworkModuleSnapshotsAggregateArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
};


export type NetworkModuleDenkjobsAggregateArgs = {
  filter?: Maybe<DenkjobFilter>;
};

export type NetworkModuleAggregateResult = {
  __typename?: 'NetworkModuleAggregateResult';
  count?: Maybe<Scalars['Int']>;
  networkModuleIdMin?: Maybe<Scalars['String']>;
  networkModuleIdMax?: Maybe<Scalars['String']>;
  networkModuleOwnerMin?: Maybe<Scalars['String']>;
  networkModuleOwnerMax?: Maybe<Scalars['String']>;
};

export type NetworkModuleFilter = {
  networkModuleId?: Maybe<StringHashFilter>;
  networkModuleOwner?: Maybe<StringHashFilter>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<NetworkModuleHasFilter>>>;
  and?: Maybe<Array<Maybe<NetworkModuleFilter>>>;
  or?: Maybe<Array<Maybe<NetworkModuleFilter>>>;
  not?: Maybe<NetworkModuleFilter>;
};

export enum NetworkModuleHasFilter {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  NetworkModuleIsPublic = 'networkModuleIsPublic',
  ClassLabels = 'classLabels',
  Markups = 'markups',
  Predictions = 'predictions',
  Snapshots = 'snapshots',
  Config = 'config',
  Denkjobs = 'denkjobs'
}

export type NetworkModuleOrder = {
  asc?: Maybe<NetworkModuleOrderable>;
  desc?: Maybe<NetworkModuleOrderable>;
  then?: Maybe<NetworkModuleOrder>;
};

export enum NetworkModuleOrderable {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner'
}

export type NetworkModulePatch = {
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
};

export type NetworkModuleRef = {
  networkModuleId: Scalars['String'];
};

export type NetworkSnapshot = Cuid & Ownable & Timestamped & {
  __typename?: 'NetworkSnapshot';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  pytorchBinary: Binary;
  onnxBinary: EncryptedBinary;
  metrics: Array<Metric>;
  network: NetworkModule;
  metricsAggregate?: Maybe<MetricAggregateResult>;
};


export type NetworkSnapshotPytorchBinaryArgs = {
  filter?: Maybe<BinaryFilter>;
};


export type NetworkSnapshotOnnxBinaryArgs = {
  filter?: Maybe<EncryptedBinaryFilter>;
};


export type NetworkSnapshotMetricsArgs = {
  filter?: Maybe<MetricFilter>;
  order?: Maybe<MetricOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type NetworkSnapshotNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};


export type NetworkSnapshotMetricsAggregateArgs = {
  filter?: Maybe<MetricFilter>;
};

export type NetworkSnapshotAggregateResult = {
  __typename?: 'NetworkSnapshotAggregateResult';
  count?: Maybe<Scalars['Int']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
};

export type NetworkSnapshotFilter = {
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<NetworkSnapshotHasFilter>>>;
  and?: Maybe<Array<Maybe<NetworkSnapshotFilter>>>;
  or?: Maybe<Array<Maybe<NetworkSnapshotFilter>>>;
  not?: Maybe<NetworkSnapshotFilter>;
};

export enum NetworkSnapshotHasFilter {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Id = 'id',
  PytorchBinary = 'pytorchBinary',
  OnnxBinary = 'onnxBinary',
  Metrics = 'metrics',
  Network = 'network'
}

export type NetworkSnapshotOrder = {
  asc?: Maybe<NetworkSnapshotOrderable>;
  desc?: Maybe<NetworkSnapshotOrderable>;
  then?: Maybe<NetworkSnapshotOrder>;
};

export enum NetworkSnapshotOrderable {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Id = 'id'
}

export type NetworkSnapshotPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  pytorchBinary?: Maybe<BinaryRef>;
  onnxBinary?: Maybe<EncryptedBinaryRef>;
  metrics?: Maybe<Array<MetricRef>>;
  network?: Maybe<NetworkModuleRef>;
};

export type NetworkSnapshotRef = {
  rootID?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pytorchBinary?: Maybe<BinaryRef>;
  onnxBinary?: Maybe<EncryptedBinaryRef>;
  metrics?: Maybe<Array<MetricRef>>;
  network?: Maybe<NetworkModuleRef>;
};

export type Notification = {
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  read: Scalars['Boolean'];
  title: Scalars['String'];
  message: Scalars['String'];
  error?: Maybe<Scalars['String']>;
};

export type NotificationAggregateResult = {
  __typename?: 'NotificationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  titleMin?: Maybe<Scalars['String']>;
  titleMax?: Maybe<Scalars['String']>;
  messageMin?: Maybe<Scalars['String']>;
  messageMax?: Maybe<Scalars['String']>;
  errorMin?: Maybe<Scalars['String']>;
  errorMax?: Maybe<Scalars['String']>;
};

export type NotificationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  read?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<NotificationHasFilter>>>;
  and?: Maybe<Array<Maybe<NotificationFilter>>>;
  or?: Maybe<Array<Maybe<NotificationFilter>>>;
  not?: Maybe<NotificationFilter>;
};

export enum NotificationHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Read = 'read',
  Title = 'title',
  Message = 'message',
  Error = 'error'
}

export type NotificationOrder = {
  asc?: Maybe<NotificationOrderable>;
  desc?: Maybe<NotificationOrderable>;
  then?: Maybe<NotificationOrder>;
};

export enum NotificationOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Title = 'title',
  Message = 'message',
  Error = 'error'
}

export type NotificationPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type NotificationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export type ObjectDetectionMarkup = ImageMarkup & {
  __typename?: 'ObjectDetectionMarkup';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<Image>;
  subset: SubsetType;
  network: NetworkModule;
  networkId: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  annotations: Array<BoundingBoxAnnotation>;
  imagesAggregate?: Maybe<ImageAggregateResult>;
  annotationsAggregate?: Maybe<BoundingBoxAnnotationAggregateResult>;
};


export type ObjectDetectionMarkupImagesArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ObjectDetectionMarkupNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};


export type ObjectDetectionMarkupAnnotationsArgs = {
  filter?: Maybe<BoundingBoxAnnotationFilter>;
  order?: Maybe<BoundingBoxAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ObjectDetectionMarkupImagesAggregateArgs = {
  filter?: Maybe<ImageFilter>;
};


export type ObjectDetectionMarkupAnnotationsAggregateArgs = {
  filter?: Maybe<BoundingBoxAnnotationFilter>;
};

export type ObjectDetectionMarkupAggregateResult = {
  __typename?: 'ObjectDetectionMarkupAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  networkIdMin?: Maybe<Scalars['String']>;
  networkIdMax?: Maybe<Scalars['String']>;
  widthMin?: Maybe<Scalars['Int']>;
  widthMax?: Maybe<Scalars['Int']>;
  widthSum?: Maybe<Scalars['Int']>;
  widthAvg?: Maybe<Scalars['Float']>;
  heightMin?: Maybe<Scalars['Int']>;
  heightMax?: Maybe<Scalars['Int']>;
  heightSum?: Maybe<Scalars['Int']>;
  heightAvg?: Maybe<Scalars['Float']>;
};

export type ObjectDetectionMarkupFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  subset?: Maybe<SubsetType_Hash>;
  networkId?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<ObjectDetectionMarkupHasFilter>>>;
  and?: Maybe<Array<Maybe<ObjectDetectionMarkupFilter>>>;
  or?: Maybe<Array<Maybe<ObjectDetectionMarkupFilter>>>;
  not?: Maybe<ObjectDetectionMarkupFilter>;
};

export enum ObjectDetectionMarkupHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Images = 'images',
  Subset = 'subset',
  Network = 'network',
  NetworkId = 'networkId',
  Width = 'width',
  Height = 'height',
  Annotations = 'annotations'
}

export type ObjectDetectionMarkupOrder = {
  asc?: Maybe<ObjectDetectionMarkupOrderable>;
  desc?: Maybe<ObjectDetectionMarkupOrderable>;
  then?: Maybe<ObjectDetectionMarkupOrder>;
};

export enum ObjectDetectionMarkupOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  NetworkId = 'networkId',
  Width = 'width',
  Height = 'height'
}

export type ObjectDetectionMarkupPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  subset?: Maybe<SubsetType>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  annotations?: Maybe<Array<BoundingBoxAnnotationRef>>;
};

export type ObjectDetectionMarkupRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  subset?: Maybe<SubsetType>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  annotations?: Maybe<Array<BoundingBoxAnnotationRef>>;
};

export type ObjectDetectionPrediction = ImagePrediction & {
  __typename?: 'ObjectDetectionPrediction';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<Image>;
  network: NetworkModule;
  networkId: Scalars['String'];
  predictions: Array<BoundingBoxPrediction>;
  imagesAggregate?: Maybe<ImageAggregateResult>;
  predictionsAggregate?: Maybe<BoundingBoxPredictionAggregateResult>;
};


export type ObjectDetectionPredictionImagesArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ObjectDetectionPredictionNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};


export type ObjectDetectionPredictionPredictionsArgs = {
  filter?: Maybe<BoundingBoxPredictionFilter>;
  order?: Maybe<BoundingBoxPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ObjectDetectionPredictionImagesAggregateArgs = {
  filter?: Maybe<ImageFilter>;
};


export type ObjectDetectionPredictionPredictionsAggregateArgs = {
  filter?: Maybe<BoundingBoxPredictionFilter>;
};

export type ObjectDetectionPredictionAggregateResult = {
  __typename?: 'ObjectDetectionPredictionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  networkIdMin?: Maybe<Scalars['String']>;
  networkIdMax?: Maybe<Scalars['String']>;
};

export type ObjectDetectionPredictionFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  networkId?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<ObjectDetectionPredictionHasFilter>>>;
  and?: Maybe<Array<Maybe<ObjectDetectionPredictionFilter>>>;
  or?: Maybe<Array<Maybe<ObjectDetectionPredictionFilter>>>;
  not?: Maybe<ObjectDetectionPredictionFilter>;
};

export enum ObjectDetectionPredictionHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Images = 'images',
  Network = 'network',
  NetworkId = 'networkId',
  Predictions = 'predictions'
}

export type ObjectDetectionPredictionOrder = {
  asc?: Maybe<ObjectDetectionPredictionOrderable>;
  desc?: Maybe<ObjectDetectionPredictionOrderable>;
  then?: Maybe<ObjectDetectionPredictionOrder>;
};

export enum ObjectDetectionPredictionOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  NetworkId = 'networkId'
}

export type ObjectDetectionPredictionPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  predictions?: Maybe<Array<BoundingBoxPredictionRef>>;
};

export type ObjectDetectionPredictionRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  predictions?: Maybe<Array<BoundingBoxPredictionRef>>;
};

export type OneOffNotification = Notification & {
  __typename?: 'OneOffNotification';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  read: Scalars['Boolean'];
  title: Scalars['String'];
  message: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type OneOffNotificationAggregateResult = {
  __typename?: 'OneOffNotificationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  titleMin?: Maybe<Scalars['String']>;
  titleMax?: Maybe<Scalars['String']>;
  messageMin?: Maybe<Scalars['String']>;
  messageMax?: Maybe<Scalars['String']>;
  errorMin?: Maybe<Scalars['String']>;
  errorMax?: Maybe<Scalars['String']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type OneOffNotificationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  read?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<OneOffNotificationHasFilter>>>;
  and?: Maybe<Array<Maybe<OneOffNotificationFilter>>>;
  or?: Maybe<Array<Maybe<OneOffNotificationFilter>>>;
  not?: Maybe<OneOffNotificationFilter>;
};

export enum OneOffNotificationHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Read = 'read',
  Title = 'title',
  Message = 'message',
  Error = 'error',
  Placeholder = '_placeholder'
}

export type OneOffNotificationOrder = {
  asc?: Maybe<OneOffNotificationOrderable>;
  desc?: Maybe<OneOffNotificationOrderable>;
  then?: Maybe<OneOffNotificationOrder>;
};

export enum OneOffNotificationOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Title = 'title',
  Message = 'message',
  Error = 'error',
  Placeholder = '_placeholder'
}

export type OneOffNotificationPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type OneOffNotificationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type Ownable = {
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type OwnableAggregateResult = {
  __typename?: 'OwnableAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
};

export type OwnableFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<OwnableHasFilter>>>;
  and?: Maybe<Array<Maybe<OwnableFilter>>>;
  or?: Maybe<Array<Maybe<OwnableFilter>>>;
  not?: Maybe<OwnableFilter>;
};

export enum OwnableHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic'
}

export type OwnableOrder = {
  asc?: Maybe<OwnableOrderable>;
  desc?: Maybe<OwnableOrderable>;
  then?: Maybe<OwnableOrder>;
};

export enum OwnableOrderable {
  Owner = 'owner'
}

export type OwnablePatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type PenAnnotation = SegmentationImageAnnotation & {
  __typename?: 'PenAnnotation';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabel;
  annotationType: AnnotationType;
  markup: SegmentationMarkup;
  topLeft: Point2D;
  bottomRight: Point2D;
  thickness: Scalars['Int'];
  points: Array<Point2D>;
  dataURL: Scalars['String'];
  pointsAggregate?: Maybe<Point2DAggregateResult>;
};


export type PenAnnotationLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type PenAnnotationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
};


export type PenAnnotationTopLeftArgs = {
  filter?: Maybe<Point2DFilter>;
};


export type PenAnnotationBottomRightArgs = {
  filter?: Maybe<Point2DFilter>;
};


export type PenAnnotationPointsArgs = {
  filter?: Maybe<Point2DFilter>;
  order?: Maybe<Point2DOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PenAnnotationPointsAggregateArgs = {
  filter?: Maybe<Point2DFilter>;
};

export type PenAnnotationAggregateResult = {
  __typename?: 'PenAnnotationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  thicknessMin?: Maybe<Scalars['Int']>;
  thicknessMax?: Maybe<Scalars['Int']>;
  thicknessSum?: Maybe<Scalars['Int']>;
  thicknessAvg?: Maybe<Scalars['Float']>;
  dataURLMin?: Maybe<Scalars['String']>;
  dataURLMax?: Maybe<Scalars['String']>;
};

export type PenAnnotationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<PenAnnotationHasFilter>>>;
  and?: Maybe<Array<Maybe<PenAnnotationFilter>>>;
  or?: Maybe<Array<Maybe<PenAnnotationFilter>>>;
  not?: Maybe<PenAnnotationFilter>;
};

export enum PenAnnotationHasFilter {
  Id = 'id',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Label = 'label',
  AnnotationType = 'annotationType',
  Markup = 'markup',
  TopLeft = 'topLeft',
  BottomRight = 'bottomRight',
  Thickness = 'thickness',
  Points = 'points',
  DataUrl = 'dataURL'
}

export type PenAnnotationOrder = {
  asc?: Maybe<PenAnnotationOrderable>;
  desc?: Maybe<PenAnnotationOrderable>;
  then?: Maybe<PenAnnotationOrder>;
};

export enum PenAnnotationOrderable {
  Id = 'id',
  Owner = 'owner',
  Thickness = 'thickness',
  DataUrl = 'dataURL'
}

export type PenAnnotationPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  thickness?: Maybe<Scalars['Int']>;
  points?: Maybe<Array<Point2DRef>>;
  dataURL?: Maybe<Scalars['String']>;
};

export type PenAnnotationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  thickness?: Maybe<Scalars['Int']>;
  points?: Maybe<Array<Point2DRef>>;
  dataURL?: Maybe<Scalars['String']>;
};

export type Pipeline = Cuid & Timestamped & Ownable & {
  __typename?: 'Pipeline';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  rootID: Scalars['ID'];
  dataset: Dataset;
  enabled: Scalars['Boolean'];
  modules?: Maybe<Array<PipelineModule>>;
  edges: Array<PipelineModuleEdge>;
  modulesAggregate?: Maybe<PipelineModuleAggregateResult>;
  edgesAggregate?: Maybe<PipelineModuleEdgeAggregateResult>;
};


export type PipelineDatasetArgs = {
  filter?: Maybe<DatasetFilter>;
};


export type PipelineModulesArgs = {
  filter?: Maybe<PipelineModuleFilter>;
  order?: Maybe<PipelineModuleOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineEdgesArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
  order?: Maybe<PipelineModuleEdgeOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModulesAggregateArgs = {
  filter?: Maybe<PipelineModuleFilter>;
};


export type PipelineEdgesAggregateArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
};

export type PipelineAggregateResult = {
  __typename?: 'PipelineAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
};

export type PipelineFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<PipelineHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineFilter>>>;
  or?: Maybe<Array<Maybe<PipelineFilter>>>;
  not?: Maybe<PipelineFilter>;
};

export enum PipelineHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Dataset = 'dataset',
  Enabled = 'enabled',
  Modules = 'modules',
  Edges = 'edges'
}

export enum PipelineJobState {
  Start = 'START',
  Starting = 'STARTING',
  Running = 'RUNNING',
  Stop = 'STOP',
  Stopping = 'STOPPING',
  Stopped = 'STOPPED',
  Failed = 'FAILED',
  Finished = 'FINISHED'
}

export type PipelineModule = {
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInput>;
  outputs: Array<PipelineModuleOutput>;
  layoutPosition: Position;
  pipeline: Pipeline;
  producedTags: Array<ProductionTag>;
  inputsAggregate?: Maybe<PipelineModuleInputAggregateResult>;
  outputsAggregate?: Maybe<PipelineModuleOutputAggregateResult>;
  producedTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type PipelineModuleInputsArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleOutputsArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleLayoutPositionArgs = {
  filter?: Maybe<PositionFilter>;
};


export type PipelineModulePipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleProducedTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleInputsAggregateArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type PipelineModuleOutputsAggregateArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleProducedTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type PipelineModuleAggregateResult = {
  __typename?: 'PipelineModuleAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleArtifactOutput = PipelineModuleOutput & {
  __typename?: 'PipelineModuleArtifactOutput';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  internalName: Scalars['String'];
  dataType: DataType;
  module: PipelineModule;
  edges: Array<PipelineModuleEdge>;
  _placeholder?: Maybe<Scalars['String']>;
  edgesAggregate?: Maybe<PipelineModuleEdgeAggregateResult>;
};


export type PipelineModuleArtifactOutputModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
};


export type PipelineModuleArtifactOutputEdgesArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
  order?: Maybe<PipelineModuleEdgeOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleArtifactOutputEdgesAggregateArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
};

export type PipelineModuleArtifactOutputAggregateResult = {
  __typename?: 'PipelineModuleArtifactOutputAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  internalNameMin?: Maybe<Scalars['String']>;
  internalNameMax?: Maybe<Scalars['String']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleArtifactOutputFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  dataType?: Maybe<DataType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleArtifactOutputHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleArtifactOutputFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleArtifactOutputFilter>>>;
  not?: Maybe<PipelineModuleArtifactOutputFilter>;
};

export enum PipelineModuleArtifactOutputHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  InternalName = 'internalName',
  DataType = 'dataType',
  Module = 'module',
  Edges = 'edges',
  Placeholder = '_placeholder'
}

export type PipelineModuleArtifactOutputOrder = {
  asc?: Maybe<PipelineModuleArtifactOutputOrderable>;
  desc?: Maybe<PipelineModuleArtifactOutputOrderable>;
  then?: Maybe<PipelineModuleArtifactOutputOrder>;
};

export enum PipelineModuleArtifactOutputOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  InternalName = 'internalName',
  Placeholder = '_placeholder'
}

export type PipelineModuleArtifactOutputPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  dataType?: Maybe<DataType>;
  module?: Maybe<PipelineModuleRef>;
  edges?: Maybe<Array<PipelineModuleEdgeRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleArtifactOutputRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  dataType?: Maybe<DataType>;
  module?: Maybe<PipelineModuleRef>;
  edges?: Maybe<Array<PipelineModuleEdgeRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleBMobile = PipelineModule & {
  __typename?: 'PipelineModuleBMobile';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInput>;
  outputs: Array<PipelineModuleOutput>;
  layoutPosition: Position;
  pipeline: Pipeline;
  producedTags: Array<ProductionTag>;
  _placeholder?: Maybe<Scalars['String']>;
  inputsAggregate?: Maybe<PipelineModuleInputAggregateResult>;
  outputsAggregate?: Maybe<PipelineModuleOutputAggregateResult>;
  producedTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type PipelineModuleBMobileInputsArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleBMobileOutputsArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleBMobileLayoutPositionArgs = {
  filter?: Maybe<PositionFilter>;
};


export type PipelineModuleBMobilePipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleBMobileProducedTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleBMobileInputsAggregateArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type PipelineModuleBMobileOutputsAggregateArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleBMobileProducedTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type PipelineModuleBMobileAggregateResult = {
  __typename?: 'PipelineModuleBMobileAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleBMobileFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  workerType?: Maybe<PipelineModuleWorkerType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleBMobileHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleBMobileFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleBMobileFilter>>>;
  not?: Maybe<PipelineModuleBMobileFilter>;
};

export enum PipelineModuleBMobileHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  Description = 'description',
  WorkerType = 'workerType',
  Inputs = 'inputs',
  Outputs = 'outputs',
  LayoutPosition = 'layoutPosition',
  Pipeline = 'pipeline',
  ProducedTags = 'producedTags',
  Placeholder = '_placeholder'
}

export type PipelineModuleBMobileOrder = {
  asc?: Maybe<PipelineModuleBMobileOrderable>;
  desc?: Maybe<PipelineModuleBMobileOrderable>;
  then?: Maybe<PipelineModuleBMobileOrder>;
};

export enum PipelineModuleBMobileOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  Description = 'description',
  Placeholder = '_placeholder'
}

export type PipelineModuleBMobilePatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleBMobileRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleCcChecker = PipelineModule & {
  __typename?: 'PipelineModuleCCChecker';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInput>;
  outputs: Array<PipelineModuleOutput>;
  layoutPosition: Position;
  pipeline: Pipeline;
  producedTags: Array<ProductionTag>;
  busBarCountPerCell: Scalars['Int'];
  maxShift: Scalars['Int'];
  minCorrValue: Scalars['Float'];
  cutOffRate: Scalars['Float'];
  minPeakHeightPercentage: Scalars['Float'];
  minPeakDistance: Scalars['Float'];
  minPeakProminence: Scalars['Float'];
  inputsAggregate?: Maybe<PipelineModuleInputAggregateResult>;
  outputsAggregate?: Maybe<PipelineModuleOutputAggregateResult>;
  producedTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type PipelineModuleCcCheckerInputsArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleCcCheckerOutputsArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleCcCheckerLayoutPositionArgs = {
  filter?: Maybe<PositionFilter>;
};


export type PipelineModuleCcCheckerPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleCcCheckerProducedTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleCcCheckerInputsAggregateArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type PipelineModuleCcCheckerOutputsAggregateArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleCcCheckerProducedTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type PipelineModuleCcCheckerAggregateResult = {
  __typename?: 'PipelineModuleCCCheckerAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  busBarCountPerCellMin?: Maybe<Scalars['Int']>;
  busBarCountPerCellMax?: Maybe<Scalars['Int']>;
  busBarCountPerCellSum?: Maybe<Scalars['Int']>;
  busBarCountPerCellAvg?: Maybe<Scalars['Float']>;
  maxShiftMin?: Maybe<Scalars['Int']>;
  maxShiftMax?: Maybe<Scalars['Int']>;
  maxShiftSum?: Maybe<Scalars['Int']>;
  maxShiftAvg?: Maybe<Scalars['Float']>;
  minCorrValueMin?: Maybe<Scalars['Float']>;
  minCorrValueMax?: Maybe<Scalars['Float']>;
  minCorrValueSum?: Maybe<Scalars['Float']>;
  minCorrValueAvg?: Maybe<Scalars['Float']>;
  cutOffRateMin?: Maybe<Scalars['Float']>;
  cutOffRateMax?: Maybe<Scalars['Float']>;
  cutOffRateSum?: Maybe<Scalars['Float']>;
  cutOffRateAvg?: Maybe<Scalars['Float']>;
  minPeakHeightPercentageMin?: Maybe<Scalars['Float']>;
  minPeakHeightPercentageMax?: Maybe<Scalars['Float']>;
  minPeakHeightPercentageSum?: Maybe<Scalars['Float']>;
  minPeakHeightPercentageAvg?: Maybe<Scalars['Float']>;
  minPeakDistanceMin?: Maybe<Scalars['Float']>;
  minPeakDistanceMax?: Maybe<Scalars['Float']>;
  minPeakDistanceSum?: Maybe<Scalars['Float']>;
  minPeakDistanceAvg?: Maybe<Scalars['Float']>;
  minPeakProminenceMin?: Maybe<Scalars['Float']>;
  minPeakProminenceMax?: Maybe<Scalars['Float']>;
  minPeakProminenceSum?: Maybe<Scalars['Float']>;
  minPeakProminenceAvg?: Maybe<Scalars['Float']>;
};

export type PipelineModuleCcCheckerFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  workerType?: Maybe<PipelineModuleWorkerType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleCcCheckerHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleCcCheckerFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleCcCheckerFilter>>>;
  not?: Maybe<PipelineModuleCcCheckerFilter>;
};

export enum PipelineModuleCcCheckerHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  Description = 'description',
  WorkerType = 'workerType',
  Inputs = 'inputs',
  Outputs = 'outputs',
  LayoutPosition = 'layoutPosition',
  Pipeline = 'pipeline',
  ProducedTags = 'producedTags',
  BusBarCountPerCell = 'busBarCountPerCell',
  MaxShift = 'maxShift',
  MinCorrValue = 'minCorrValue',
  CutOffRate = 'cutOffRate',
  MinPeakHeightPercentage = 'minPeakHeightPercentage',
  MinPeakDistance = 'minPeakDistance',
  MinPeakProminence = 'minPeakProminence'
}

export type PipelineModuleCcCheckerOrder = {
  asc?: Maybe<PipelineModuleCcCheckerOrderable>;
  desc?: Maybe<PipelineModuleCcCheckerOrderable>;
  then?: Maybe<PipelineModuleCcCheckerOrder>;
};

export enum PipelineModuleCcCheckerOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  Description = 'description',
  BusBarCountPerCell = 'busBarCountPerCell',
  MaxShift = 'maxShift',
  MinCorrValue = 'minCorrValue',
  CutOffRate = 'cutOffRate',
  MinPeakHeightPercentage = 'minPeakHeightPercentage',
  MinPeakDistance = 'minPeakDistance',
  MinPeakProminence = 'minPeakProminence'
}

export type PipelineModuleCcCheckerPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  busBarCountPerCell?: Maybe<Scalars['Int']>;
  maxShift?: Maybe<Scalars['Int']>;
  minCorrValue?: Maybe<Scalars['Float']>;
  cutOffRate?: Maybe<Scalars['Float']>;
  minPeakHeightPercentage?: Maybe<Scalars['Float']>;
  minPeakDistance?: Maybe<Scalars['Float']>;
  minPeakProminence?: Maybe<Scalars['Float']>;
};

export type PipelineModuleCcCheckerRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  busBarCountPerCell?: Maybe<Scalars['Int']>;
  maxShift?: Maybe<Scalars['Int']>;
  minCorrValue?: Maybe<Scalars['Float']>;
  cutOffRate?: Maybe<Scalars['Float']>;
  minPeakHeightPercentage?: Maybe<Scalars['Float']>;
  minPeakDistance?: Maybe<Scalars['Float']>;
  minPeakProminence?: Maybe<Scalars['Float']>;
};

export type PipelineModuleContrastEnhancement = PipelineModule & {
  __typename?: 'PipelineModuleContrastEnhancement';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInput>;
  outputs: Array<PipelineModuleOutput>;
  layoutPosition: Position;
  pipeline: Pipeline;
  producedTags: Array<ProductionTag>;
  clipLimit: Scalars['Float'];
  tileSize: Scalars['Int'];
  inputsAggregate?: Maybe<PipelineModuleInputAggregateResult>;
  outputsAggregate?: Maybe<PipelineModuleOutputAggregateResult>;
  producedTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type PipelineModuleContrastEnhancementInputsArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleContrastEnhancementOutputsArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleContrastEnhancementLayoutPositionArgs = {
  filter?: Maybe<PositionFilter>;
};


export type PipelineModuleContrastEnhancementPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleContrastEnhancementProducedTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleContrastEnhancementInputsAggregateArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type PipelineModuleContrastEnhancementOutputsAggregateArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleContrastEnhancementProducedTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type PipelineModuleContrastEnhancementAggregateResult = {
  __typename?: 'PipelineModuleContrastEnhancementAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  clipLimitMin?: Maybe<Scalars['Float']>;
  clipLimitMax?: Maybe<Scalars['Float']>;
  clipLimitSum?: Maybe<Scalars['Float']>;
  clipLimitAvg?: Maybe<Scalars['Float']>;
  tileSizeMin?: Maybe<Scalars['Int']>;
  tileSizeMax?: Maybe<Scalars['Int']>;
  tileSizeSum?: Maybe<Scalars['Int']>;
  tileSizeAvg?: Maybe<Scalars['Float']>;
};

export type PipelineModuleContrastEnhancementFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  workerType?: Maybe<PipelineModuleWorkerType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleContrastEnhancementHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleContrastEnhancementFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleContrastEnhancementFilter>>>;
  not?: Maybe<PipelineModuleContrastEnhancementFilter>;
};

export enum PipelineModuleContrastEnhancementHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  Description = 'description',
  WorkerType = 'workerType',
  Inputs = 'inputs',
  Outputs = 'outputs',
  LayoutPosition = 'layoutPosition',
  Pipeline = 'pipeline',
  ProducedTags = 'producedTags',
  ClipLimit = 'clipLimit',
  TileSize = 'tileSize'
}

export type PipelineModuleContrastEnhancementOrder = {
  asc?: Maybe<PipelineModuleContrastEnhancementOrderable>;
  desc?: Maybe<PipelineModuleContrastEnhancementOrderable>;
  then?: Maybe<PipelineModuleContrastEnhancementOrder>;
};

export enum PipelineModuleContrastEnhancementOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  Description = 'description',
  ClipLimit = 'clipLimit',
  TileSize = 'tileSize'
}

export type PipelineModuleContrastEnhancementPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  clipLimit?: Maybe<Scalars['Float']>;
  tileSize?: Maybe<Scalars['Int']>;
};

export type PipelineModuleContrastEnhancementRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  clipLimit?: Maybe<Scalars['Float']>;
  tileSize?: Maybe<Scalars['Int']>;
};

export type PipelineModuleDatapointOutput = PipelineModuleOutput & {
  __typename?: 'PipelineModuleDatapointOutput';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  internalName: Scalars['String'];
  dataType: DataType;
  module: PipelineModule;
  edges: Array<PipelineModuleEdge>;
  datapoints: Array<Datapoint>;
  edgesAggregate?: Maybe<PipelineModuleEdgeAggregateResult>;
  datapointsAggregate?: Maybe<DatapointAggregateResult>;
};


export type PipelineModuleDatapointOutputModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
};


export type PipelineModuleDatapointOutputEdgesArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
  order?: Maybe<PipelineModuleEdgeOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleDatapointOutputDatapointsArgs = {
  filter?: Maybe<DatapointFilter>;
  order?: Maybe<DatapointOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleDatapointOutputEdgesAggregateArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
};


export type PipelineModuleDatapointOutputDatapointsAggregateArgs = {
  filter?: Maybe<DatapointFilter>;
};

export type PipelineModuleDatapointOutputAggregateResult = {
  __typename?: 'PipelineModuleDatapointOutputAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  internalNameMin?: Maybe<Scalars['String']>;
  internalNameMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleDatapointOutputFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  dataType?: Maybe<DataType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleDatapointOutputHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleDatapointOutputFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleDatapointOutputFilter>>>;
  not?: Maybe<PipelineModuleDatapointOutputFilter>;
};

export enum PipelineModuleDatapointOutputHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  InternalName = 'internalName',
  DataType = 'dataType',
  Module = 'module',
  Edges = 'edges',
  Datapoints = 'datapoints'
}

export type PipelineModuleDatapointOutputOrder = {
  asc?: Maybe<PipelineModuleDatapointOutputOrderable>;
  desc?: Maybe<PipelineModuleDatapointOutputOrderable>;
  then?: Maybe<PipelineModuleDatapointOutputOrder>;
};

export enum PipelineModuleDatapointOutputOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  InternalName = 'internalName'
}

export type PipelineModuleDatapointOutputPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  dataType?: Maybe<DataType>;
  module?: Maybe<PipelineModuleRef>;
  edges?: Maybe<Array<PipelineModuleEdgeRef>>;
  datapoints?: Maybe<Array<DatapointRef>>;
};

export type PipelineModuleDatapointOutputRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  dataType?: Maybe<DataType>;
  module?: Maybe<PipelineModuleRef>;
  edges?: Maybe<Array<PipelineModuleEdgeRef>>;
  datapoints?: Maybe<Array<DatapointRef>>;
};

export type PipelineModuleEdge = Cuid & Timestamped & Ownable & {
  __typename?: 'PipelineModuleEdge';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  rootID: Scalars['ID'];
  pipeline: Pipeline;
  from: PipelineModuleOutput;
  to: PipelineModuleInput;
};


export type PipelineModuleEdgePipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleEdgeFromArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleEdgeToArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};

export type PipelineModuleEdgeAggregateResult = {
  __typename?: 'PipelineModuleEdgeAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleEdgeFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<PipelineModuleEdgeHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleEdgeFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleEdgeFilter>>>;
  not?: Maybe<PipelineModuleEdgeFilter>;
};

export enum PipelineModuleEdgeHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Pipeline = 'pipeline',
  From = 'from',
  To = 'to'
}

export type PipelineModuleEdgeOrder = {
  asc?: Maybe<PipelineModuleEdgeOrderable>;
  desc?: Maybe<PipelineModuleEdgeOrderable>;
  then?: Maybe<PipelineModuleEdgeOrder>;
};

export enum PipelineModuleEdgeOrderable {
  Owner = 'owner',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id'
}

export type PipelineModuleEdgePatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pipeline?: Maybe<PipelineRef>;
  from?: Maybe<PipelineModuleOutputRef>;
  to?: Maybe<PipelineModuleInputRef>;
};

export type PipelineModuleEdgeRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pipeline?: Maybe<PipelineRef>;
  from?: Maybe<PipelineModuleOutputRef>;
  to?: Maybe<PipelineModuleInputRef>;
};

export type PipelineModuleFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  workerType?: Maybe<PipelineModuleWorkerType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleFilter>>>;
  not?: Maybe<PipelineModuleFilter>;
};

export enum PipelineModuleHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  Description = 'description',
  WorkerType = 'workerType',
  Inputs = 'inputs',
  Outputs = 'outputs',
  LayoutPosition = 'layoutPosition',
  Pipeline = 'pipeline',
  ProducedTags = 'producedTags'
}

export type PipelineModuleInput = Cuid & Timestamped & Ownable & {
  __typename?: 'PipelineModuleInput';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  rootID: Scalars['ID'];
  name: Scalars['String'];
  inputType: PipelineModuleInputType;
  dataType: DataType;
  module: PipelineModule;
  edges: Array<PipelineModuleEdge>;
  edgesAggregate?: Maybe<PipelineModuleEdgeAggregateResult>;
};


export type PipelineModuleInputModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
};


export type PipelineModuleInputEdgesArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
  order?: Maybe<PipelineModuleEdgeOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleInputEdgesAggregateArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
};

export type PipelineModuleInputAggregateResult = {
  __typename?: 'PipelineModuleInputAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleInputFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<PipelineModuleInputHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleInputFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleInputFilter>>>;
  not?: Maybe<PipelineModuleInputFilter>;
};

export enum PipelineModuleInputHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Name = 'name',
  InputType = 'inputType',
  DataType = 'dataType',
  Module = 'module',
  Edges = 'edges'
}

export type PipelineModuleInputOrder = {
  asc?: Maybe<PipelineModuleInputOrderable>;
  desc?: Maybe<PipelineModuleInputOrderable>;
  then?: Maybe<PipelineModuleInputOrder>;
};

export enum PipelineModuleInputOrderable {
  Owner = 'owner',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Name = 'name'
}

export type PipelineModuleInputPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  inputType?: Maybe<PipelineModuleInputType>;
  dataType?: Maybe<DataType>;
  module?: Maybe<PipelineModuleRef>;
  edges?: Maybe<Array<PipelineModuleEdgeRef>>;
};

export type PipelineModuleInputRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  inputType?: Maybe<PipelineModuleInputType>;
  dataType?: Maybe<DataType>;
  module?: Maybe<PipelineModuleRef>;
  edges?: Maybe<Array<PipelineModuleEdgeRef>>;
};

export enum PipelineModuleInputType {
  Datapoint = 'DATAPOINT',
  Artifact = 'ARTIFACT'
}

export type PipelineModuleJobRequestCcChecker = ModuleJobRequest & {
  __typename?: 'PipelineModuleJobRequestCCChecker';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  module: PipelineModule;
  researchBoxes: Array<ResearchBox>;
  busBarCountPerCell: Scalars['Int'];
  maxShift: Scalars['Int'];
  minCorrValue: Scalars['Float'];
  cutOffRate: Scalars['Float'];
  minPeakHeightPercentage: Scalars['Float'];
  minPeakDistance: Scalars['Float'];
  minPeakProminence: Scalars['Float'];
  researchBoxesAggregate?: Maybe<ResearchBoxAggregateResult>;
};


export type PipelineModuleJobRequestCcCheckerModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
};


export type PipelineModuleJobRequestCcCheckerResearchBoxesArgs = {
  filter?: Maybe<ResearchBoxFilter>;
  order?: Maybe<ResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleJobRequestCcCheckerResearchBoxesAggregateArgs = {
  filter?: Maybe<ResearchBoxFilter>;
};

export type PipelineModuleJobRequestCcCheckerAggregateResult = {
  __typename?: 'PipelineModuleJobRequestCCCheckerAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  busBarCountPerCellMin?: Maybe<Scalars['Int']>;
  busBarCountPerCellMax?: Maybe<Scalars['Int']>;
  busBarCountPerCellSum?: Maybe<Scalars['Int']>;
  busBarCountPerCellAvg?: Maybe<Scalars['Float']>;
  maxShiftMin?: Maybe<Scalars['Int']>;
  maxShiftMax?: Maybe<Scalars['Int']>;
  maxShiftSum?: Maybe<Scalars['Int']>;
  maxShiftAvg?: Maybe<Scalars['Float']>;
  minCorrValueMin?: Maybe<Scalars['Float']>;
  minCorrValueMax?: Maybe<Scalars['Float']>;
  minCorrValueSum?: Maybe<Scalars['Float']>;
  minCorrValueAvg?: Maybe<Scalars['Float']>;
  cutOffRateMin?: Maybe<Scalars['Float']>;
  cutOffRateMax?: Maybe<Scalars['Float']>;
  cutOffRateSum?: Maybe<Scalars['Float']>;
  cutOffRateAvg?: Maybe<Scalars['Float']>;
  minPeakHeightPercentageMin?: Maybe<Scalars['Float']>;
  minPeakHeightPercentageMax?: Maybe<Scalars['Float']>;
  minPeakHeightPercentageSum?: Maybe<Scalars['Float']>;
  minPeakHeightPercentageAvg?: Maybe<Scalars['Float']>;
  minPeakDistanceMin?: Maybe<Scalars['Float']>;
  minPeakDistanceMax?: Maybe<Scalars['Float']>;
  minPeakDistanceSum?: Maybe<Scalars['Float']>;
  minPeakDistanceAvg?: Maybe<Scalars['Float']>;
  minPeakProminenceMin?: Maybe<Scalars['Float']>;
  minPeakProminenceMax?: Maybe<Scalars['Float']>;
  minPeakProminenceSum?: Maybe<Scalars['Float']>;
  minPeakProminenceAvg?: Maybe<Scalars['Float']>;
};

export type PipelineModuleJobRequestCcCheckerFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<PipelineModuleJobRequestCcCheckerHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleJobRequestCcCheckerFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleJobRequestCcCheckerFilter>>>;
  not?: Maybe<PipelineModuleJobRequestCcCheckerFilter>;
};

export enum PipelineModuleJobRequestCcCheckerHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Module = 'module',
  ResearchBoxes = 'researchBoxes',
  BusBarCountPerCell = 'busBarCountPerCell',
  MaxShift = 'maxShift',
  MinCorrValue = 'minCorrValue',
  CutOffRate = 'cutOffRate',
  MinPeakHeightPercentage = 'minPeakHeightPercentage',
  MinPeakDistance = 'minPeakDistance',
  MinPeakProminence = 'minPeakProminence'
}

export type PipelineModuleJobRequestCcCheckerOrder = {
  asc?: Maybe<PipelineModuleJobRequestCcCheckerOrderable>;
  desc?: Maybe<PipelineModuleJobRequestCcCheckerOrderable>;
  then?: Maybe<PipelineModuleJobRequestCcCheckerOrder>;
};

export enum PipelineModuleJobRequestCcCheckerOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  BusBarCountPerCell = 'busBarCountPerCell',
  MaxShift = 'maxShift',
  MinCorrValue = 'minCorrValue',
  CutOffRate = 'cutOffRate',
  MinPeakHeightPercentage = 'minPeakHeightPercentage',
  MinPeakDistance = 'minPeakDistance',
  MinPeakProminence = 'minPeakProminence'
}

export type PipelineModuleJobRequestCcCheckerPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  module?: Maybe<PipelineModuleRef>;
  researchBoxes?: Maybe<Array<ResearchBoxRef>>;
  busBarCountPerCell?: Maybe<Scalars['Int']>;
  maxShift?: Maybe<Scalars['Int']>;
  minCorrValue?: Maybe<Scalars['Float']>;
  cutOffRate?: Maybe<Scalars['Float']>;
  minPeakHeightPercentage?: Maybe<Scalars['Float']>;
  minPeakDistance?: Maybe<Scalars['Float']>;
  minPeakProminence?: Maybe<Scalars['Float']>;
};

export type PipelineModuleJobRequestCcCheckerRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  module?: Maybe<PipelineModuleRef>;
  researchBoxes?: Maybe<Array<ResearchBoxRef>>;
  busBarCountPerCell?: Maybe<Scalars['Int']>;
  maxShift?: Maybe<Scalars['Int']>;
  minCorrValue?: Maybe<Scalars['Float']>;
  cutOffRate?: Maybe<Scalars['Float']>;
  minPeakHeightPercentage?: Maybe<Scalars['Float']>;
  minPeakDistance?: Maybe<Scalars['Float']>;
  minPeakProminence?: Maybe<Scalars['Float']>;
};

export type PipelineModuleJobRequestContrastEnhancement = ModuleJobRequest & {
  __typename?: 'PipelineModuleJobRequestContrastEnhancement';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  module: PipelineModule;
  researchBoxes: Array<ResearchBox>;
  clipLimit: Scalars['Float'];
  tileSize: Scalars['Int'];
  researchBoxesAggregate?: Maybe<ResearchBoxAggregateResult>;
};


export type PipelineModuleJobRequestContrastEnhancementModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
};


export type PipelineModuleJobRequestContrastEnhancementResearchBoxesArgs = {
  filter?: Maybe<ResearchBoxFilter>;
  order?: Maybe<ResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleJobRequestContrastEnhancementResearchBoxesAggregateArgs = {
  filter?: Maybe<ResearchBoxFilter>;
};

export type PipelineModuleJobRequestContrastEnhancementAggregateResult = {
  __typename?: 'PipelineModuleJobRequestContrastEnhancementAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  clipLimitMin?: Maybe<Scalars['Float']>;
  clipLimitMax?: Maybe<Scalars['Float']>;
  clipLimitSum?: Maybe<Scalars['Float']>;
  clipLimitAvg?: Maybe<Scalars['Float']>;
  tileSizeMin?: Maybe<Scalars['Int']>;
  tileSizeMax?: Maybe<Scalars['Int']>;
  tileSizeSum?: Maybe<Scalars['Int']>;
  tileSizeAvg?: Maybe<Scalars['Float']>;
};

export type PipelineModuleJobRequestContrastEnhancementFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<PipelineModuleJobRequestContrastEnhancementHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleJobRequestContrastEnhancementFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleJobRequestContrastEnhancementFilter>>>;
  not?: Maybe<PipelineModuleJobRequestContrastEnhancementFilter>;
};

export enum PipelineModuleJobRequestContrastEnhancementHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Module = 'module',
  ResearchBoxes = 'researchBoxes',
  ClipLimit = 'clipLimit',
  TileSize = 'tileSize'
}

export type PipelineModuleJobRequestContrastEnhancementOrder = {
  asc?: Maybe<PipelineModuleJobRequestContrastEnhancementOrderable>;
  desc?: Maybe<PipelineModuleJobRequestContrastEnhancementOrderable>;
  then?: Maybe<PipelineModuleJobRequestContrastEnhancementOrder>;
};

export enum PipelineModuleJobRequestContrastEnhancementOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  ClipLimit = 'clipLimit',
  TileSize = 'tileSize'
}

export type PipelineModuleJobRequestContrastEnhancementPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  module?: Maybe<PipelineModuleRef>;
  researchBoxes?: Maybe<Array<ResearchBoxRef>>;
  clipLimit?: Maybe<Scalars['Float']>;
  tileSize?: Maybe<Scalars['Int']>;
};

export type PipelineModuleJobRequestContrastEnhancementRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  module?: Maybe<PipelineModuleRef>;
  researchBoxes?: Maybe<Array<ResearchBoxRef>>;
  clipLimit?: Maybe<Scalars['Float']>;
  tileSize?: Maybe<Scalars['Int']>;
};

export type PipelineModuleNetworkImageAnomalyDetection = PipelineModule & NetworkModule & {
  __typename?: 'PipelineModuleNetworkImageAnomalyDetection';
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabel>;
  markups: Array<ImageMarkup>;
  predictions: Array<ImagePrediction>;
  snapshots: Array<NetworkSnapshot>;
  config?: Maybe<NetworkConfig>;
  denkjobs: Array<Denkjob>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInput>;
  outputs: Array<PipelineModuleOutput>;
  layoutPosition: Position;
  pipeline: Pipeline;
  producedTags: Array<ProductionTag>;
  _placeholder?: Maybe<Scalars['String']>;
  classLabelsAggregate?: Maybe<MarkupLabelAggregateResult>;
  markupsAggregate?: Maybe<ImageMarkupAggregateResult>;
  predictionsAggregate?: Maybe<ImagePredictionAggregateResult>;
  snapshotsAggregate?: Maybe<NetworkSnapshotAggregateResult>;
  denkjobsAggregate?: Maybe<DenkjobAggregateResult>;
  inputsAggregate?: Maybe<PipelineModuleInputAggregateResult>;
  outputsAggregate?: Maybe<PipelineModuleOutputAggregateResult>;
  producedTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type PipelineModuleNetworkImageAnomalyDetectionClassLabelsArgs = {
  filter?: Maybe<MarkupLabelFilter>;
  order?: Maybe<MarkupLabelOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageAnomalyDetectionMarkupsArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageAnomalyDetectionPredictionsArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageAnomalyDetectionSnapshotsArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
  order?: Maybe<NetworkSnapshotOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageAnomalyDetectionConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
};


export type PipelineModuleNetworkImageAnomalyDetectionDenkjobsArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageAnomalyDetectionInputsArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageAnomalyDetectionOutputsArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageAnomalyDetectionLayoutPositionArgs = {
  filter?: Maybe<PositionFilter>;
};


export type PipelineModuleNetworkImageAnomalyDetectionPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleNetworkImageAnomalyDetectionProducedTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageAnomalyDetectionClassLabelsAggregateArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type PipelineModuleNetworkImageAnomalyDetectionMarkupsAggregateArgs = {
  filter?: Maybe<ImageMarkupFilter>;
};


export type PipelineModuleNetworkImageAnomalyDetectionPredictionsAggregateArgs = {
  filter?: Maybe<ImagePredictionFilter>;
};


export type PipelineModuleNetworkImageAnomalyDetectionSnapshotsAggregateArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
};


export type PipelineModuleNetworkImageAnomalyDetectionDenkjobsAggregateArgs = {
  filter?: Maybe<DenkjobFilter>;
};


export type PipelineModuleNetworkImageAnomalyDetectionInputsAggregateArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type PipelineModuleNetworkImageAnomalyDetectionOutputsAggregateArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleNetworkImageAnomalyDetectionProducedTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type PipelineModuleNetworkImageAnomalyDetectionAggregateResult = {
  __typename?: 'PipelineModuleNetworkImageAnomalyDetectionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  networkModuleIdMin?: Maybe<Scalars['String']>;
  networkModuleIdMax?: Maybe<Scalars['String']>;
  networkModuleOwnerMin?: Maybe<Scalars['String']>;
  networkModuleOwnerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageAnomalyDetectionFilter = {
  networkModuleId?: Maybe<StringHashFilter>;
  networkModuleOwner?: Maybe<StringHashFilter>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  workerType?: Maybe<PipelineModuleWorkerType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleNetworkImageAnomalyDetectionHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleNetworkImageAnomalyDetectionFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleNetworkImageAnomalyDetectionFilter>>>;
  not?: Maybe<PipelineModuleNetworkImageAnomalyDetectionFilter>;
};

export enum PipelineModuleNetworkImageAnomalyDetectionHasFilter {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  NetworkModuleIsPublic = 'networkModuleIsPublic',
  ClassLabels = 'classLabels',
  Markups = 'markups',
  Predictions = 'predictions',
  Snapshots = 'snapshots',
  Config = 'config',
  Denkjobs = 'denkjobs',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  Description = 'description',
  WorkerType = 'workerType',
  Inputs = 'inputs',
  Outputs = 'outputs',
  LayoutPosition = 'layoutPosition',
  Pipeline = 'pipeline',
  ProducedTags = 'producedTags',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageAnomalyDetectionOrder = {
  asc?: Maybe<PipelineModuleNetworkImageAnomalyDetectionOrderable>;
  desc?: Maybe<PipelineModuleNetworkImageAnomalyDetectionOrderable>;
  then?: Maybe<PipelineModuleNetworkImageAnomalyDetectionOrder>;
};

export enum PipelineModuleNetworkImageAnomalyDetectionOrderable {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  Description = 'description',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageAnomalyDetectionPatch = {
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageAnomalyDetectionRef = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageClassification = PipelineModule & NetworkModule & {
  __typename?: 'PipelineModuleNetworkImageClassification';
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabel>;
  markups: Array<ImageMarkup>;
  predictions: Array<ImagePrediction>;
  snapshots: Array<NetworkSnapshot>;
  config?: Maybe<NetworkConfig>;
  denkjobs: Array<Denkjob>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInput>;
  outputs: Array<PipelineModuleOutput>;
  layoutPosition: Position;
  pipeline: Pipeline;
  producedTags: Array<ProductionTag>;
  _placeholder?: Maybe<Scalars['String']>;
  classLabelsAggregate?: Maybe<MarkupLabelAggregateResult>;
  markupsAggregate?: Maybe<ImageMarkupAggregateResult>;
  predictionsAggregate?: Maybe<ImagePredictionAggregateResult>;
  snapshotsAggregate?: Maybe<NetworkSnapshotAggregateResult>;
  denkjobsAggregate?: Maybe<DenkjobAggregateResult>;
  inputsAggregate?: Maybe<PipelineModuleInputAggregateResult>;
  outputsAggregate?: Maybe<PipelineModuleOutputAggregateResult>;
  producedTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type PipelineModuleNetworkImageClassificationClassLabelsArgs = {
  filter?: Maybe<MarkupLabelFilter>;
  order?: Maybe<MarkupLabelOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageClassificationMarkupsArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageClassificationPredictionsArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageClassificationSnapshotsArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
  order?: Maybe<NetworkSnapshotOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageClassificationConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
};


export type PipelineModuleNetworkImageClassificationDenkjobsArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageClassificationInputsArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageClassificationOutputsArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageClassificationLayoutPositionArgs = {
  filter?: Maybe<PositionFilter>;
};


export type PipelineModuleNetworkImageClassificationPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleNetworkImageClassificationProducedTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageClassificationClassLabelsAggregateArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type PipelineModuleNetworkImageClassificationMarkupsAggregateArgs = {
  filter?: Maybe<ImageMarkupFilter>;
};


export type PipelineModuleNetworkImageClassificationPredictionsAggregateArgs = {
  filter?: Maybe<ImagePredictionFilter>;
};


export type PipelineModuleNetworkImageClassificationSnapshotsAggregateArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
};


export type PipelineModuleNetworkImageClassificationDenkjobsAggregateArgs = {
  filter?: Maybe<DenkjobFilter>;
};


export type PipelineModuleNetworkImageClassificationInputsAggregateArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type PipelineModuleNetworkImageClassificationOutputsAggregateArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleNetworkImageClassificationProducedTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type PipelineModuleNetworkImageClassificationAggregateResult = {
  __typename?: 'PipelineModuleNetworkImageClassificationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  networkModuleIdMin?: Maybe<Scalars['String']>;
  networkModuleIdMax?: Maybe<Scalars['String']>;
  networkModuleOwnerMin?: Maybe<Scalars['String']>;
  networkModuleOwnerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageClassificationFilter = {
  networkModuleId?: Maybe<StringHashFilter>;
  networkModuleOwner?: Maybe<StringHashFilter>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  workerType?: Maybe<PipelineModuleWorkerType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleNetworkImageClassificationHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleNetworkImageClassificationFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleNetworkImageClassificationFilter>>>;
  not?: Maybe<PipelineModuleNetworkImageClassificationFilter>;
};

export enum PipelineModuleNetworkImageClassificationHasFilter {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  NetworkModuleIsPublic = 'networkModuleIsPublic',
  ClassLabels = 'classLabels',
  Markups = 'markups',
  Predictions = 'predictions',
  Snapshots = 'snapshots',
  Config = 'config',
  Denkjobs = 'denkjobs',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  Description = 'description',
  WorkerType = 'workerType',
  Inputs = 'inputs',
  Outputs = 'outputs',
  LayoutPosition = 'layoutPosition',
  Pipeline = 'pipeline',
  ProducedTags = 'producedTags',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageClassificationOrder = {
  asc?: Maybe<PipelineModuleNetworkImageClassificationOrderable>;
  desc?: Maybe<PipelineModuleNetworkImageClassificationOrderable>;
  then?: Maybe<PipelineModuleNetworkImageClassificationOrder>;
};

export enum PipelineModuleNetworkImageClassificationOrderable {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  Description = 'description',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageClassificationPatch = {
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageClassificationRef = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageInstanceSegmentation = PipelineModule & NetworkModule & {
  __typename?: 'PipelineModuleNetworkImageInstanceSegmentation';
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabel>;
  markups: Array<ImageMarkup>;
  predictions: Array<ImagePrediction>;
  snapshots: Array<NetworkSnapshot>;
  config?: Maybe<NetworkConfig>;
  denkjobs: Array<Denkjob>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInput>;
  outputs: Array<PipelineModuleOutput>;
  layoutPosition: Position;
  pipeline: Pipeline;
  producedTags: Array<ProductionTag>;
  _placeholder?: Maybe<Scalars['String']>;
  classLabelsAggregate?: Maybe<MarkupLabelAggregateResult>;
  markupsAggregate?: Maybe<ImageMarkupAggregateResult>;
  predictionsAggregate?: Maybe<ImagePredictionAggregateResult>;
  snapshotsAggregate?: Maybe<NetworkSnapshotAggregateResult>;
  denkjobsAggregate?: Maybe<DenkjobAggregateResult>;
  inputsAggregate?: Maybe<PipelineModuleInputAggregateResult>;
  outputsAggregate?: Maybe<PipelineModuleOutputAggregateResult>;
  producedTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type PipelineModuleNetworkImageInstanceSegmentationClassLabelsArgs = {
  filter?: Maybe<MarkupLabelFilter>;
  order?: Maybe<MarkupLabelOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageInstanceSegmentationMarkupsArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageInstanceSegmentationPredictionsArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageInstanceSegmentationSnapshotsArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
  order?: Maybe<NetworkSnapshotOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageInstanceSegmentationConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
};


export type PipelineModuleNetworkImageInstanceSegmentationDenkjobsArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageInstanceSegmentationInputsArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageInstanceSegmentationOutputsArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageInstanceSegmentationLayoutPositionArgs = {
  filter?: Maybe<PositionFilter>;
};


export type PipelineModuleNetworkImageInstanceSegmentationPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleNetworkImageInstanceSegmentationProducedTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageInstanceSegmentationClassLabelsAggregateArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type PipelineModuleNetworkImageInstanceSegmentationMarkupsAggregateArgs = {
  filter?: Maybe<ImageMarkupFilter>;
};


export type PipelineModuleNetworkImageInstanceSegmentationPredictionsAggregateArgs = {
  filter?: Maybe<ImagePredictionFilter>;
};


export type PipelineModuleNetworkImageInstanceSegmentationSnapshotsAggregateArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
};


export type PipelineModuleNetworkImageInstanceSegmentationDenkjobsAggregateArgs = {
  filter?: Maybe<DenkjobFilter>;
};


export type PipelineModuleNetworkImageInstanceSegmentationInputsAggregateArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type PipelineModuleNetworkImageInstanceSegmentationOutputsAggregateArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleNetworkImageInstanceSegmentationProducedTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type PipelineModuleNetworkImageInstanceSegmentationAggregateResult = {
  __typename?: 'PipelineModuleNetworkImageInstanceSegmentationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  networkModuleIdMin?: Maybe<Scalars['String']>;
  networkModuleIdMax?: Maybe<Scalars['String']>;
  networkModuleOwnerMin?: Maybe<Scalars['String']>;
  networkModuleOwnerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageInstanceSegmentationFilter = {
  networkModuleId?: Maybe<StringHashFilter>;
  networkModuleOwner?: Maybe<StringHashFilter>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  workerType?: Maybe<PipelineModuleWorkerType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleNetworkImageInstanceSegmentationHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleNetworkImageInstanceSegmentationFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleNetworkImageInstanceSegmentationFilter>>>;
  not?: Maybe<PipelineModuleNetworkImageInstanceSegmentationFilter>;
};

export enum PipelineModuleNetworkImageInstanceSegmentationHasFilter {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  NetworkModuleIsPublic = 'networkModuleIsPublic',
  ClassLabels = 'classLabels',
  Markups = 'markups',
  Predictions = 'predictions',
  Snapshots = 'snapshots',
  Config = 'config',
  Denkjobs = 'denkjobs',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  Description = 'description',
  WorkerType = 'workerType',
  Inputs = 'inputs',
  Outputs = 'outputs',
  LayoutPosition = 'layoutPosition',
  Pipeline = 'pipeline',
  ProducedTags = 'producedTags',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageInstanceSegmentationOrder = {
  asc?: Maybe<PipelineModuleNetworkImageInstanceSegmentationOrderable>;
  desc?: Maybe<PipelineModuleNetworkImageInstanceSegmentationOrderable>;
  then?: Maybe<PipelineModuleNetworkImageInstanceSegmentationOrder>;
};

export enum PipelineModuleNetworkImageInstanceSegmentationOrderable {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  Description = 'description',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageInstanceSegmentationPatch = {
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageInstanceSegmentationRef = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageObjectCounting = PipelineModule & NetworkModule & {
  __typename?: 'PipelineModuleNetworkImageObjectCounting';
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabel>;
  markups: Array<ImageMarkup>;
  predictions: Array<ImagePrediction>;
  snapshots: Array<NetworkSnapshot>;
  config?: Maybe<NetworkConfig>;
  denkjobs: Array<Denkjob>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInput>;
  outputs: Array<PipelineModuleOutput>;
  layoutPosition: Position;
  pipeline: Pipeline;
  producedTags: Array<ProductionTag>;
  _placeholder?: Maybe<Scalars['String']>;
  classLabelsAggregate?: Maybe<MarkupLabelAggregateResult>;
  markupsAggregate?: Maybe<ImageMarkupAggregateResult>;
  predictionsAggregate?: Maybe<ImagePredictionAggregateResult>;
  snapshotsAggregate?: Maybe<NetworkSnapshotAggregateResult>;
  denkjobsAggregate?: Maybe<DenkjobAggregateResult>;
  inputsAggregate?: Maybe<PipelineModuleInputAggregateResult>;
  outputsAggregate?: Maybe<PipelineModuleOutputAggregateResult>;
  producedTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type PipelineModuleNetworkImageObjectCountingClassLabelsArgs = {
  filter?: Maybe<MarkupLabelFilter>;
  order?: Maybe<MarkupLabelOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectCountingMarkupsArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectCountingPredictionsArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectCountingSnapshotsArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
  order?: Maybe<NetworkSnapshotOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectCountingConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
};


export type PipelineModuleNetworkImageObjectCountingDenkjobsArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectCountingInputsArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectCountingOutputsArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectCountingLayoutPositionArgs = {
  filter?: Maybe<PositionFilter>;
};


export type PipelineModuleNetworkImageObjectCountingPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleNetworkImageObjectCountingProducedTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectCountingClassLabelsAggregateArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type PipelineModuleNetworkImageObjectCountingMarkupsAggregateArgs = {
  filter?: Maybe<ImageMarkupFilter>;
};


export type PipelineModuleNetworkImageObjectCountingPredictionsAggregateArgs = {
  filter?: Maybe<ImagePredictionFilter>;
};


export type PipelineModuleNetworkImageObjectCountingSnapshotsAggregateArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
};


export type PipelineModuleNetworkImageObjectCountingDenkjobsAggregateArgs = {
  filter?: Maybe<DenkjobFilter>;
};


export type PipelineModuleNetworkImageObjectCountingInputsAggregateArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type PipelineModuleNetworkImageObjectCountingOutputsAggregateArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleNetworkImageObjectCountingProducedTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type PipelineModuleNetworkImageObjectCountingAggregateResult = {
  __typename?: 'PipelineModuleNetworkImageObjectCountingAggregateResult';
  count?: Maybe<Scalars['Int']>;
  networkModuleIdMin?: Maybe<Scalars['String']>;
  networkModuleIdMax?: Maybe<Scalars['String']>;
  networkModuleOwnerMin?: Maybe<Scalars['String']>;
  networkModuleOwnerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageObjectCountingFilter = {
  networkModuleId?: Maybe<StringHashFilter>;
  networkModuleOwner?: Maybe<StringHashFilter>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  workerType?: Maybe<PipelineModuleWorkerType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectCountingHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectCountingFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectCountingFilter>>>;
  not?: Maybe<PipelineModuleNetworkImageObjectCountingFilter>;
};

export enum PipelineModuleNetworkImageObjectCountingHasFilter {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  NetworkModuleIsPublic = 'networkModuleIsPublic',
  ClassLabels = 'classLabels',
  Markups = 'markups',
  Predictions = 'predictions',
  Snapshots = 'snapshots',
  Config = 'config',
  Denkjobs = 'denkjobs',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  Description = 'description',
  WorkerType = 'workerType',
  Inputs = 'inputs',
  Outputs = 'outputs',
  LayoutPosition = 'layoutPosition',
  Pipeline = 'pipeline',
  ProducedTags = 'producedTags',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageObjectCountingOrder = {
  asc?: Maybe<PipelineModuleNetworkImageObjectCountingOrderable>;
  desc?: Maybe<PipelineModuleNetworkImageObjectCountingOrderable>;
  then?: Maybe<PipelineModuleNetworkImageObjectCountingOrder>;
};

export enum PipelineModuleNetworkImageObjectCountingOrderable {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  Description = 'description',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageObjectCountingPatch = {
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageObjectCountingRef = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageObjectDetection = PipelineModule & NetworkModule & {
  __typename?: 'PipelineModuleNetworkImageObjectDetection';
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabel>;
  markups: Array<ImageMarkup>;
  predictions: Array<ImagePrediction>;
  snapshots: Array<NetworkSnapshot>;
  config?: Maybe<NetworkConfig>;
  denkjobs: Array<Denkjob>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInput>;
  outputs: Array<PipelineModuleOutput>;
  layoutPosition: Position;
  pipeline: Pipeline;
  producedTags: Array<ProductionTag>;
  _placeholder?: Maybe<Scalars['String']>;
  classLabelsAggregate?: Maybe<MarkupLabelAggregateResult>;
  markupsAggregate?: Maybe<ImageMarkupAggregateResult>;
  predictionsAggregate?: Maybe<ImagePredictionAggregateResult>;
  snapshotsAggregate?: Maybe<NetworkSnapshotAggregateResult>;
  denkjobsAggregate?: Maybe<DenkjobAggregateResult>;
  inputsAggregate?: Maybe<PipelineModuleInputAggregateResult>;
  outputsAggregate?: Maybe<PipelineModuleOutputAggregateResult>;
  producedTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type PipelineModuleNetworkImageObjectDetectionClassLabelsArgs = {
  filter?: Maybe<MarkupLabelFilter>;
  order?: Maybe<MarkupLabelOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectDetectionMarkupsArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectDetectionPredictionsArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectDetectionSnapshotsArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
  order?: Maybe<NetworkSnapshotOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectDetectionConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
};


export type PipelineModuleNetworkImageObjectDetectionDenkjobsArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectDetectionInputsArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectDetectionOutputsArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectDetectionLayoutPositionArgs = {
  filter?: Maybe<PositionFilter>;
};


export type PipelineModuleNetworkImageObjectDetectionPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleNetworkImageObjectDetectionProducedTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageObjectDetectionClassLabelsAggregateArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type PipelineModuleNetworkImageObjectDetectionMarkupsAggregateArgs = {
  filter?: Maybe<ImageMarkupFilter>;
};


export type PipelineModuleNetworkImageObjectDetectionPredictionsAggregateArgs = {
  filter?: Maybe<ImagePredictionFilter>;
};


export type PipelineModuleNetworkImageObjectDetectionSnapshotsAggregateArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
};


export type PipelineModuleNetworkImageObjectDetectionDenkjobsAggregateArgs = {
  filter?: Maybe<DenkjobFilter>;
};


export type PipelineModuleNetworkImageObjectDetectionInputsAggregateArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type PipelineModuleNetworkImageObjectDetectionOutputsAggregateArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleNetworkImageObjectDetectionProducedTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type PipelineModuleNetworkImageObjectDetectionAggregateResult = {
  __typename?: 'PipelineModuleNetworkImageObjectDetectionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  networkModuleIdMin?: Maybe<Scalars['String']>;
  networkModuleIdMax?: Maybe<Scalars['String']>;
  networkModuleOwnerMin?: Maybe<Scalars['String']>;
  networkModuleOwnerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageObjectDetectionFilter = {
  networkModuleId?: Maybe<StringHashFilter>;
  networkModuleOwner?: Maybe<StringHashFilter>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  workerType?: Maybe<PipelineModuleWorkerType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectDetectionHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectDetectionFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectDetectionFilter>>>;
  not?: Maybe<PipelineModuleNetworkImageObjectDetectionFilter>;
};

export enum PipelineModuleNetworkImageObjectDetectionHasFilter {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  NetworkModuleIsPublic = 'networkModuleIsPublic',
  ClassLabels = 'classLabels',
  Markups = 'markups',
  Predictions = 'predictions',
  Snapshots = 'snapshots',
  Config = 'config',
  Denkjobs = 'denkjobs',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  Description = 'description',
  WorkerType = 'workerType',
  Inputs = 'inputs',
  Outputs = 'outputs',
  LayoutPosition = 'layoutPosition',
  Pipeline = 'pipeline',
  ProducedTags = 'producedTags',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageObjectDetectionOrder = {
  asc?: Maybe<PipelineModuleNetworkImageObjectDetectionOrderable>;
  desc?: Maybe<PipelineModuleNetworkImageObjectDetectionOrderable>;
  then?: Maybe<PipelineModuleNetworkImageObjectDetectionOrder>;
};

export enum PipelineModuleNetworkImageObjectDetectionOrderable {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  Description = 'description',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageObjectDetectionPatch = {
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageObjectDetectionRef = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageOcr = PipelineModule & NetworkModule & {
  __typename?: 'PipelineModuleNetworkImageOCR';
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabel>;
  markups: Array<ImageMarkup>;
  predictions: Array<ImagePrediction>;
  snapshots: Array<NetworkSnapshot>;
  config?: Maybe<NetworkConfig>;
  denkjobs: Array<Denkjob>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInput>;
  outputs: Array<PipelineModuleOutput>;
  layoutPosition: Position;
  pipeline: Pipeline;
  producedTags: Array<ProductionTag>;
  _placeholder?: Maybe<Scalars['String']>;
  classLabelsAggregate?: Maybe<MarkupLabelAggregateResult>;
  markupsAggregate?: Maybe<ImageMarkupAggregateResult>;
  predictionsAggregate?: Maybe<ImagePredictionAggregateResult>;
  snapshotsAggregate?: Maybe<NetworkSnapshotAggregateResult>;
  denkjobsAggregate?: Maybe<DenkjobAggregateResult>;
  inputsAggregate?: Maybe<PipelineModuleInputAggregateResult>;
  outputsAggregate?: Maybe<PipelineModuleOutputAggregateResult>;
  producedTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type PipelineModuleNetworkImageOcrClassLabelsArgs = {
  filter?: Maybe<MarkupLabelFilter>;
  order?: Maybe<MarkupLabelOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageOcrMarkupsArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageOcrPredictionsArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageOcrSnapshotsArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
  order?: Maybe<NetworkSnapshotOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageOcrConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
};


export type PipelineModuleNetworkImageOcrDenkjobsArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageOcrInputsArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageOcrOutputsArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageOcrLayoutPositionArgs = {
  filter?: Maybe<PositionFilter>;
};


export type PipelineModuleNetworkImageOcrPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleNetworkImageOcrProducedTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageOcrClassLabelsAggregateArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type PipelineModuleNetworkImageOcrMarkupsAggregateArgs = {
  filter?: Maybe<ImageMarkupFilter>;
};


export type PipelineModuleNetworkImageOcrPredictionsAggregateArgs = {
  filter?: Maybe<ImagePredictionFilter>;
};


export type PipelineModuleNetworkImageOcrSnapshotsAggregateArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
};


export type PipelineModuleNetworkImageOcrDenkjobsAggregateArgs = {
  filter?: Maybe<DenkjobFilter>;
};


export type PipelineModuleNetworkImageOcrInputsAggregateArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type PipelineModuleNetworkImageOcrOutputsAggregateArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleNetworkImageOcrProducedTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type PipelineModuleNetworkImageOcrAggregateResult = {
  __typename?: 'PipelineModuleNetworkImageOCRAggregateResult';
  count?: Maybe<Scalars['Int']>;
  networkModuleIdMin?: Maybe<Scalars['String']>;
  networkModuleIdMax?: Maybe<Scalars['String']>;
  networkModuleOwnerMin?: Maybe<Scalars['String']>;
  networkModuleOwnerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageOcrFilter = {
  networkModuleId?: Maybe<StringHashFilter>;
  networkModuleOwner?: Maybe<StringHashFilter>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  workerType?: Maybe<PipelineModuleWorkerType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleNetworkImageOcrHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleNetworkImageOcrFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleNetworkImageOcrFilter>>>;
  not?: Maybe<PipelineModuleNetworkImageOcrFilter>;
};

export enum PipelineModuleNetworkImageOcrHasFilter {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  NetworkModuleIsPublic = 'networkModuleIsPublic',
  ClassLabels = 'classLabels',
  Markups = 'markups',
  Predictions = 'predictions',
  Snapshots = 'snapshots',
  Config = 'config',
  Denkjobs = 'denkjobs',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  Description = 'description',
  WorkerType = 'workerType',
  Inputs = 'inputs',
  Outputs = 'outputs',
  LayoutPosition = 'layoutPosition',
  Pipeline = 'pipeline',
  ProducedTags = 'producedTags',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageOcrOrder = {
  asc?: Maybe<PipelineModuleNetworkImageOcrOrderable>;
  desc?: Maybe<PipelineModuleNetworkImageOcrOrderable>;
  then?: Maybe<PipelineModuleNetworkImageOcrOrder>;
};

export enum PipelineModuleNetworkImageOcrOrderable {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  Description = 'description',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageOcrPatch = {
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageOcrRef = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageSegmentation = PipelineModule & NetworkModule & {
  __typename?: 'PipelineModuleNetworkImageSegmentation';
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabel>;
  markups: Array<ImageMarkup>;
  predictions: Array<ImagePrediction>;
  snapshots: Array<NetworkSnapshot>;
  config?: Maybe<NetworkConfig>;
  denkjobs: Array<Denkjob>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInput>;
  outputs: Array<PipelineModuleOutput>;
  layoutPosition: Position;
  pipeline: Pipeline;
  producedTags: Array<ProductionTag>;
  _placeholder?: Maybe<Scalars['String']>;
  classLabelsAggregate?: Maybe<MarkupLabelAggregateResult>;
  markupsAggregate?: Maybe<ImageMarkupAggregateResult>;
  predictionsAggregate?: Maybe<ImagePredictionAggregateResult>;
  snapshotsAggregate?: Maybe<NetworkSnapshotAggregateResult>;
  denkjobsAggregate?: Maybe<DenkjobAggregateResult>;
  inputsAggregate?: Maybe<PipelineModuleInputAggregateResult>;
  outputsAggregate?: Maybe<PipelineModuleOutputAggregateResult>;
  producedTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type PipelineModuleNetworkImageSegmentationClassLabelsArgs = {
  filter?: Maybe<MarkupLabelFilter>;
  order?: Maybe<MarkupLabelOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSegmentationMarkupsArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSegmentationPredictionsArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSegmentationSnapshotsArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
  order?: Maybe<NetworkSnapshotOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSegmentationConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
};


export type PipelineModuleNetworkImageSegmentationDenkjobsArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSegmentationInputsArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSegmentationOutputsArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSegmentationLayoutPositionArgs = {
  filter?: Maybe<PositionFilter>;
};


export type PipelineModuleNetworkImageSegmentationPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleNetworkImageSegmentationProducedTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSegmentationClassLabelsAggregateArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type PipelineModuleNetworkImageSegmentationMarkupsAggregateArgs = {
  filter?: Maybe<ImageMarkupFilter>;
};


export type PipelineModuleNetworkImageSegmentationPredictionsAggregateArgs = {
  filter?: Maybe<ImagePredictionFilter>;
};


export type PipelineModuleNetworkImageSegmentationSnapshotsAggregateArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
};


export type PipelineModuleNetworkImageSegmentationDenkjobsAggregateArgs = {
  filter?: Maybe<DenkjobFilter>;
};


export type PipelineModuleNetworkImageSegmentationInputsAggregateArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type PipelineModuleNetworkImageSegmentationOutputsAggregateArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleNetworkImageSegmentationProducedTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type PipelineModuleNetworkImageSegmentationAggregateResult = {
  __typename?: 'PipelineModuleNetworkImageSegmentationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  networkModuleIdMin?: Maybe<Scalars['String']>;
  networkModuleIdMax?: Maybe<Scalars['String']>;
  networkModuleOwnerMin?: Maybe<Scalars['String']>;
  networkModuleOwnerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageSegmentationFilter = {
  networkModuleId?: Maybe<StringHashFilter>;
  networkModuleOwner?: Maybe<StringHashFilter>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  workerType?: Maybe<PipelineModuleWorkerType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleNetworkImageSegmentationHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleNetworkImageSegmentationFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleNetworkImageSegmentationFilter>>>;
  not?: Maybe<PipelineModuleNetworkImageSegmentationFilter>;
};

export enum PipelineModuleNetworkImageSegmentationHasFilter {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  NetworkModuleIsPublic = 'networkModuleIsPublic',
  ClassLabels = 'classLabels',
  Markups = 'markups',
  Predictions = 'predictions',
  Snapshots = 'snapshots',
  Config = 'config',
  Denkjobs = 'denkjobs',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  Description = 'description',
  WorkerType = 'workerType',
  Inputs = 'inputs',
  Outputs = 'outputs',
  LayoutPosition = 'layoutPosition',
  Pipeline = 'pipeline',
  ProducedTags = 'producedTags',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageSegmentationOrder = {
  asc?: Maybe<PipelineModuleNetworkImageSegmentationOrderable>;
  desc?: Maybe<PipelineModuleNetworkImageSegmentationOrderable>;
  then?: Maybe<PipelineModuleNetworkImageSegmentationOrder>;
};

export enum PipelineModuleNetworkImageSegmentationOrderable {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  Description = 'description',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageSegmentationPatch = {
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageSegmentationRef = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageSuperResolution = PipelineModule & NetworkModule & {
  __typename?: 'PipelineModuleNetworkImageSuperResolution';
  networkModuleId: Scalars['String'];
  networkModuleOwner: Scalars['String'];
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels: Array<MarkupLabel>;
  markups: Array<ImageMarkup>;
  predictions: Array<ImagePrediction>;
  snapshots: Array<NetworkSnapshot>;
  config?: Maybe<NetworkConfig>;
  denkjobs: Array<Denkjob>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInput>;
  outputs: Array<PipelineModuleOutput>;
  layoutPosition: Position;
  pipeline: Pipeline;
  producedTags: Array<ProductionTag>;
  _placeholder?: Maybe<Scalars['String']>;
  classLabelsAggregate?: Maybe<MarkupLabelAggregateResult>;
  markupsAggregate?: Maybe<ImageMarkupAggregateResult>;
  predictionsAggregate?: Maybe<ImagePredictionAggregateResult>;
  snapshotsAggregate?: Maybe<NetworkSnapshotAggregateResult>;
  denkjobsAggregate?: Maybe<DenkjobAggregateResult>;
  inputsAggregate?: Maybe<PipelineModuleInputAggregateResult>;
  outputsAggregate?: Maybe<PipelineModuleOutputAggregateResult>;
  producedTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type PipelineModuleNetworkImageSuperResolutionClassLabelsArgs = {
  filter?: Maybe<MarkupLabelFilter>;
  order?: Maybe<MarkupLabelOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSuperResolutionMarkupsArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSuperResolutionPredictionsArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSuperResolutionSnapshotsArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
  order?: Maybe<NetworkSnapshotOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSuperResolutionConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
};


export type PipelineModuleNetworkImageSuperResolutionDenkjobsArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSuperResolutionInputsArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSuperResolutionOutputsArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSuperResolutionLayoutPositionArgs = {
  filter?: Maybe<PositionFilter>;
};


export type PipelineModuleNetworkImageSuperResolutionPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleNetworkImageSuperResolutionProducedTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleNetworkImageSuperResolutionClassLabelsAggregateArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type PipelineModuleNetworkImageSuperResolutionMarkupsAggregateArgs = {
  filter?: Maybe<ImageMarkupFilter>;
};


export type PipelineModuleNetworkImageSuperResolutionPredictionsAggregateArgs = {
  filter?: Maybe<ImagePredictionFilter>;
};


export type PipelineModuleNetworkImageSuperResolutionSnapshotsAggregateArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
};


export type PipelineModuleNetworkImageSuperResolutionDenkjobsAggregateArgs = {
  filter?: Maybe<DenkjobFilter>;
};


export type PipelineModuleNetworkImageSuperResolutionInputsAggregateArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type PipelineModuleNetworkImageSuperResolutionOutputsAggregateArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleNetworkImageSuperResolutionProducedTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type PipelineModuleNetworkImageSuperResolutionAggregateResult = {
  __typename?: 'PipelineModuleNetworkImageSuperResolutionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  networkModuleIdMin?: Maybe<Scalars['String']>;
  networkModuleIdMax?: Maybe<Scalars['String']>;
  networkModuleOwnerMin?: Maybe<Scalars['String']>;
  networkModuleOwnerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageSuperResolutionFilter = {
  networkModuleId?: Maybe<StringHashFilter>;
  networkModuleOwner?: Maybe<StringHashFilter>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  workerType?: Maybe<PipelineModuleWorkerType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleNetworkImageSuperResolutionHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleNetworkImageSuperResolutionFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleNetworkImageSuperResolutionFilter>>>;
  not?: Maybe<PipelineModuleNetworkImageSuperResolutionFilter>;
};

export enum PipelineModuleNetworkImageSuperResolutionHasFilter {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  NetworkModuleIsPublic = 'networkModuleIsPublic',
  ClassLabels = 'classLabels',
  Markups = 'markups',
  Predictions = 'predictions',
  Snapshots = 'snapshots',
  Config = 'config',
  Denkjobs = 'denkjobs',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  Description = 'description',
  WorkerType = 'workerType',
  Inputs = 'inputs',
  Outputs = 'outputs',
  LayoutPosition = 'layoutPosition',
  Pipeline = 'pipeline',
  ProducedTags = 'producedTags',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageSuperResolutionOrder = {
  asc?: Maybe<PipelineModuleNetworkImageSuperResolutionOrderable>;
  desc?: Maybe<PipelineModuleNetworkImageSuperResolutionOrderable>;
  then?: Maybe<PipelineModuleNetworkImageSuperResolutionOrder>;
};

export enum PipelineModuleNetworkImageSuperResolutionOrderable {
  NetworkModuleId = 'networkModuleId',
  NetworkModuleOwner = 'networkModuleOwner',
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  Description = 'description',
  Placeholder = '_placeholder'
}

export type PipelineModuleNetworkImageSuperResolutionPatch = {
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleNetworkImageSuperResolutionRef = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  networkModuleOwner?: Maybe<Scalars['String']>;
  networkModuleIsPublic?: Maybe<Scalars['Boolean']>;
  classLabels?: Maybe<Array<MarkupLabelRef>>;
  markups?: Maybe<Array<ImageMarkupRef>>;
  predictions?: Maybe<Array<ImagePredictionRef>>;
  snapshots?: Maybe<Array<NetworkSnapshotRef>>;
  config?: Maybe<NetworkConfigRef>;
  denkjobs?: Maybe<Array<DenkjobRef>>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleOrder = {
  asc?: Maybe<PipelineModuleOrderable>;
  desc?: Maybe<PipelineModuleOrderable>;
  then?: Maybe<PipelineModuleOrder>;
};

export enum PipelineModuleOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  Description = 'description'
}

export type PipelineModuleOutput = {
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  internalName: Scalars['String'];
  dataType: DataType;
  module: PipelineModule;
  edges: Array<PipelineModuleEdge>;
  edgesAggregate?: Maybe<PipelineModuleEdgeAggregateResult>;
};


export type PipelineModuleOutputModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
};


export type PipelineModuleOutputEdgesArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
  order?: Maybe<PipelineModuleEdgeOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleOutputEdgesAggregateArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
};

export type PipelineModuleOutputAggregateResult = {
  __typename?: 'PipelineModuleOutputAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  internalNameMin?: Maybe<Scalars['String']>;
  internalNameMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleOutputFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  dataType?: Maybe<DataType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleOutputHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleOutputFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleOutputFilter>>>;
  not?: Maybe<PipelineModuleOutputFilter>;
};

export enum PipelineModuleOutputHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  InternalName = 'internalName',
  DataType = 'dataType',
  Module = 'module',
  Edges = 'edges'
}

export type PipelineModuleOutputOrder = {
  asc?: Maybe<PipelineModuleOutputOrderable>;
  desc?: Maybe<PipelineModuleOutputOrderable>;
  then?: Maybe<PipelineModuleOutputOrder>;
};

export enum PipelineModuleOutputOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  InternalName = 'internalName'
}

export type PipelineModuleOutputPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  dataType?: Maybe<DataType>;
  module?: Maybe<PipelineModuleRef>;
  edges?: Maybe<Array<PipelineModuleEdgeRef>>;
};

export type PipelineModuleOutputRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export type PipelineModulePatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
};

export type PipelineModuleRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export type PipelineModuleUpload = PipelineModule & {
  __typename?: 'PipelineModuleUpload';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description: Scalars['String'];
  workerType: PipelineModuleWorkerType;
  inputs: Array<PipelineModuleInput>;
  outputs: Array<PipelineModuleOutput>;
  layoutPosition: Position;
  pipeline: Pipeline;
  producedTags: Array<ProductionTag>;
  _placeholder?: Maybe<Scalars['String']>;
  inputsAggregate?: Maybe<PipelineModuleInputAggregateResult>;
  outputsAggregate?: Maybe<PipelineModuleOutputAggregateResult>;
  producedTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type PipelineModuleUploadInputsArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleUploadOutputsArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleUploadLayoutPositionArgs = {
  filter?: Maybe<PositionFilter>;
};


export type PipelineModuleUploadPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type PipelineModuleUploadProducedTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PipelineModuleUploadInputsAggregateArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type PipelineModuleUploadOutputsAggregateArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type PipelineModuleUploadProducedTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type PipelineModuleUploadAggregateResult = {
  __typename?: 'PipelineModuleUploadAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  descriptionMin?: Maybe<Scalars['String']>;
  descriptionMax?: Maybe<Scalars['String']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type PipelineModuleUploadFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringHashFilter>;
  description?: Maybe<StringFullTextFilter>;
  workerType?: Maybe<PipelineModuleWorkerType_Hash>;
  has?: Maybe<Array<Maybe<PipelineModuleUploadHasFilter>>>;
  and?: Maybe<Array<Maybe<PipelineModuleUploadFilter>>>;
  or?: Maybe<Array<Maybe<PipelineModuleUploadFilter>>>;
  not?: Maybe<PipelineModuleUploadFilter>;
};

export enum PipelineModuleUploadHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Name = 'name',
  Description = 'description',
  WorkerType = 'workerType',
  Inputs = 'inputs',
  Outputs = 'outputs',
  LayoutPosition = 'layoutPosition',
  Pipeline = 'pipeline',
  ProducedTags = 'producedTags',
  Placeholder = '_placeholder'
}

export type PipelineModuleUploadOrder = {
  asc?: Maybe<PipelineModuleUploadOrderable>;
  desc?: Maybe<PipelineModuleUploadOrderable>;
  then?: Maybe<PipelineModuleUploadOrder>;
};

export enum PipelineModuleUploadOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Name = 'name',
  Description = 'description',
  Placeholder = '_placeholder'
}

export type PipelineModuleUploadPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export type PipelineModuleUploadRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  workerType?: Maybe<PipelineModuleWorkerType>;
  inputs?: Maybe<Array<PipelineModuleInputRef>>;
  outputs?: Maybe<Array<PipelineModuleOutputRef>>;
  layoutPosition?: Maybe<PositionRef>;
  pipeline?: Maybe<PipelineRef>;
  producedTags?: Maybe<Array<ProductionTagRef>>;
  _placeholder?: Maybe<Scalars['String']>;
};

export enum PipelineModuleWorkerType {
  None = 'NONE',
  Go = 'GO',
  Denkbox = 'DENKBOX'
}

export type PipelineModuleWorkerType_Hash = {
  eq?: Maybe<PipelineModuleWorkerType>;
  in?: Maybe<Array<Maybe<PipelineModuleWorkerType>>>;
};

export type PipelineOrder = {
  asc?: Maybe<PipelineOrderable>;
  desc?: Maybe<PipelineOrderable>;
  then?: Maybe<PipelineOrder>;
};

export enum PipelineOrderable {
  Owner = 'owner',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id'
}

export type PipelinePatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dataset?: Maybe<DatasetRef>;
  enabled?: Maybe<Scalars['Boolean']>;
  modules?: Maybe<Array<PipelineModuleRef>>;
  edges?: Maybe<Array<PipelineModuleEdgeRef>>;
};

export type PipelineRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  dataset?: Maybe<DatasetRef>;
  enabled?: Maybe<Scalars['Boolean']>;
  modules?: Maybe<Array<PipelineModuleRef>>;
  edges?: Maybe<Array<PipelineModuleEdgeRef>>;
};

export type PixelAnnotation = SegmentationImageAnnotation & {
  __typename?: 'PixelAnnotation';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabel;
  annotationType: AnnotationType;
  markup: SegmentationMarkup;
  topLeft: Point2D;
  bottomRight: Point2D;
  dataURL: Scalars['String'];
};


export type PixelAnnotationLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type PixelAnnotationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
};


export type PixelAnnotationTopLeftArgs = {
  filter?: Maybe<Point2DFilter>;
};


export type PixelAnnotationBottomRightArgs = {
  filter?: Maybe<Point2DFilter>;
};

export type PixelAnnotationAggregateResult = {
  __typename?: 'PixelAnnotationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  dataURLMin?: Maybe<Scalars['String']>;
  dataURLMax?: Maybe<Scalars['String']>;
};

export type PixelAnnotationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<PixelAnnotationHasFilter>>>;
  and?: Maybe<Array<Maybe<PixelAnnotationFilter>>>;
  or?: Maybe<Array<Maybe<PixelAnnotationFilter>>>;
  not?: Maybe<PixelAnnotationFilter>;
};

export enum PixelAnnotationHasFilter {
  Id = 'id',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Label = 'label',
  AnnotationType = 'annotationType',
  Markup = 'markup',
  TopLeft = 'topLeft',
  BottomRight = 'bottomRight',
  DataUrl = 'dataURL'
}

export type PixelAnnotationOrder = {
  asc?: Maybe<PixelAnnotationOrderable>;
  desc?: Maybe<PixelAnnotationOrderable>;
  then?: Maybe<PixelAnnotationOrder>;
};

export enum PixelAnnotationOrderable {
  Id = 'id',
  Owner = 'owner',
  DataUrl = 'dataURL'
}

export type PixelAnnotationPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  dataURL?: Maybe<Scalars['String']>;
};

export type PixelAnnotationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  dataURL?: Maybe<Scalars['String']>;
};

export type Point = {
  __typename?: 'Point';
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
};

export type Point2D = Cuid & Ownable & {
  __typename?: 'Point2D';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  idx: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type Point2DAggregateResult = {
  __typename?: 'Point2DAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  idxMin?: Maybe<Scalars['Int']>;
  idxMax?: Maybe<Scalars['Int']>;
  idxSum?: Maybe<Scalars['Int']>;
  idxAvg?: Maybe<Scalars['Float']>;
  xMin?: Maybe<Scalars['Int']>;
  xMax?: Maybe<Scalars['Int']>;
  xSum?: Maybe<Scalars['Int']>;
  xAvg?: Maybe<Scalars['Float']>;
  yMin?: Maybe<Scalars['Int']>;
  yMax?: Maybe<Scalars['Int']>;
  ySum?: Maybe<Scalars['Int']>;
  yAvg?: Maybe<Scalars['Float']>;
};

export type Point2DFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<Point2DHasFilter>>>;
  and?: Maybe<Array<Maybe<Point2DFilter>>>;
  or?: Maybe<Array<Maybe<Point2DFilter>>>;
  not?: Maybe<Point2DFilter>;
};

export enum Point2DHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  Id = 'id',
  Idx = 'idx',
  X = 'x',
  Y = 'y'
}

export type Point2DOrder = {
  asc?: Maybe<Point2DOrderable>;
  desc?: Maybe<Point2DOrderable>;
  then?: Maybe<Point2DOrder>;
};

export enum Point2DOrderable {
  Owner = 'owner',
  Id = 'id',
  Idx = 'idx',
  X = 'x',
  Y = 'y'
}

export type Point2DPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  idx?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export type Point2DRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  idx?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export type PointGeoFilter = {
  near?: Maybe<NearFilter>;
  within?: Maybe<WithinFilter>;
};

export type PointList = {
  __typename?: 'PointList';
  points: Array<Point>;
};

export type PointListRef = {
  points: Array<PointRef>;
};

export type PointRef = {
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
};

export type Polygon = {
  __typename?: 'Polygon';
  coordinates: Array<PointList>;
};

export type PolygonAnnotation = SegmentationImageAnnotation & {
  __typename?: 'PolygonAnnotation';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabel;
  annotationType: AnnotationType;
  markup: SegmentationMarkup;
  points: Array<Point2D>;
  pointsAggregate?: Maybe<Point2DAggregateResult>;
};


export type PolygonAnnotationLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type PolygonAnnotationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
};


export type PolygonAnnotationPointsArgs = {
  filter?: Maybe<Point2DFilter>;
  order?: Maybe<Point2DOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type PolygonAnnotationPointsAggregateArgs = {
  filter?: Maybe<Point2DFilter>;
};

export type PolygonAnnotationAggregateResult = {
  __typename?: 'PolygonAnnotationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
};

export type PolygonAnnotationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<PolygonAnnotationHasFilter>>>;
  and?: Maybe<Array<Maybe<PolygonAnnotationFilter>>>;
  or?: Maybe<Array<Maybe<PolygonAnnotationFilter>>>;
  not?: Maybe<PolygonAnnotationFilter>;
};

export enum PolygonAnnotationHasFilter {
  Id = 'id',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Label = 'label',
  AnnotationType = 'annotationType',
  Markup = 'markup',
  Points = 'points'
}

export type PolygonAnnotationOrder = {
  asc?: Maybe<PolygonAnnotationOrderable>;
  desc?: Maybe<PolygonAnnotationOrderable>;
  then?: Maybe<PolygonAnnotationOrder>;
};

export enum PolygonAnnotationOrderable {
  Id = 'id',
  Owner = 'owner'
}

export type PolygonAnnotationPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  points?: Maybe<Array<Point2DRef>>;
};

export type PolygonAnnotationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  points?: Maybe<Array<Point2DRef>>;
};

export type PolygonGeoFilter = {
  near?: Maybe<NearFilter>;
  within?: Maybe<WithinFilter>;
  contains?: Maybe<ContainsFilter>;
  intersects?: Maybe<IntersectsFilter>;
};

export type PolygonRef = {
  coordinates: Array<PointListRef>;
};

export type Position = {
  __typename?: 'Position';
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PositionAggregateResult = {
  __typename?: 'PositionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  xMin?: Maybe<Scalars['Int']>;
  xMax?: Maybe<Scalars['Int']>;
  xSum?: Maybe<Scalars['Int']>;
  xAvg?: Maybe<Scalars['Float']>;
  yMin?: Maybe<Scalars['Int']>;
  yMax?: Maybe<Scalars['Int']>;
  ySum?: Maybe<Scalars['Int']>;
  yAvg?: Maybe<Scalars['Float']>;
};

export type PositionFilter = {
  has?: Maybe<Array<Maybe<PositionHasFilter>>>;
  and?: Maybe<Array<Maybe<PositionFilter>>>;
  or?: Maybe<Array<Maybe<PositionFilter>>>;
  not?: Maybe<PositionFilter>;
};

export enum PositionHasFilter {
  X = 'x',
  Y = 'y'
}

export type PositionOrder = {
  asc?: Maybe<PositionOrderable>;
  desc?: Maybe<PositionOrderable>;
  then?: Maybe<PositionOrder>;
};

export enum PositionOrderable {
  X = 'x',
  Y = 'y'
}

export type PositionRef = {
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export type PretrainedNetwork = Cuid & Timestamped & ReadOnly & {
  __typename?: 'PretrainedNetwork';
  _placeholder?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  rootID: Scalars['ID'];
  networkModule: NetworkModule;
  thumbnail: Scalars['String'];
};


export type PretrainedNetworkNetworkModuleArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};

export type PretrainedNetworkAggregateResult = {
  __typename?: 'PretrainedNetworkAggregateResult';
  count?: Maybe<Scalars['Int']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  thumbnailMin?: Maybe<Scalars['String']>;
  thumbnailMax?: Maybe<Scalars['String']>;
};

export type PretrainedNetworkFilter = {
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<PretrainedNetworkHasFilter>>>;
  and?: Maybe<Array<Maybe<PretrainedNetworkFilter>>>;
  or?: Maybe<Array<Maybe<PretrainedNetworkFilter>>>;
  not?: Maybe<PretrainedNetworkFilter>;
};

export enum PretrainedNetworkHasFilter {
  Placeholder = '_placeholder',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  NetworkModule = 'networkModule',
  Thumbnail = 'thumbnail'
}

export type PretrainedNetworkOrder = {
  asc?: Maybe<PretrainedNetworkOrderable>;
  desc?: Maybe<PretrainedNetworkOrderable>;
  then?: Maybe<PretrainedNetworkOrder>;
};

export enum PretrainedNetworkOrderable {
  Placeholder = '_placeholder',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Thumbnail = 'thumbnail'
}

export type PretrainedNetworkPatch = {
  _placeholder?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  networkModule?: Maybe<NetworkModuleRef>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type PretrainedNetworkRef = {
  rootID?: Maybe<Scalars['ID']>;
  _placeholder?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  networkModule?: Maybe<NetworkModuleRef>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type ProductionTag = Cuid & Timestamped & Ownable & {
  __typename?: 'ProductionTag';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  rootID: Scalars['ID'];
  moduleId: Scalars['String'];
  module: PipelineModule;
  moduleParams: Scalars['String'];
  producedResearchBox: ResearchBox;
  producedResearchBoxId: Scalars['String'];
};


export type ProductionTagModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
};


export type ProductionTagProducedResearchBoxArgs = {
  filter?: Maybe<ResearchBoxFilter>;
};

export type ProductionTagAggregateResult = {
  __typename?: 'ProductionTagAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  moduleIdMin?: Maybe<Scalars['String']>;
  moduleIdMax?: Maybe<Scalars['String']>;
  moduleParamsMin?: Maybe<Scalars['String']>;
  moduleParamsMax?: Maybe<Scalars['String']>;
  producedResearchBoxIdMin?: Maybe<Scalars['String']>;
  producedResearchBoxIdMax?: Maybe<Scalars['String']>;
};

export type ProductionTagFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  moduleId?: Maybe<StringHashFilter>;
  moduleParams?: Maybe<StringHashFilter>;
  producedResearchBoxId?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<ProductionTagHasFilter>>>;
  and?: Maybe<Array<Maybe<ProductionTagFilter>>>;
  or?: Maybe<Array<Maybe<ProductionTagFilter>>>;
  not?: Maybe<ProductionTagFilter>;
};

export enum ProductionTagHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  ModuleId = 'moduleId',
  Module = 'module',
  ModuleParams = 'moduleParams',
  ProducedResearchBox = 'producedResearchBox',
  ProducedResearchBoxId = 'producedResearchBoxId'
}

export type ProductionTagOrder = {
  asc?: Maybe<ProductionTagOrderable>;
  desc?: Maybe<ProductionTagOrderable>;
  then?: Maybe<ProductionTagOrder>;
};

export enum ProductionTagOrderable {
  Owner = 'owner',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  ModuleId = 'moduleId',
  ModuleParams = 'moduleParams',
  ProducedResearchBoxId = 'producedResearchBoxId'
}

export type ProductionTagPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  moduleId?: Maybe<Scalars['String']>;
  module?: Maybe<PipelineModuleRef>;
  moduleParams?: Maybe<Scalars['String']>;
  producedResearchBox?: Maybe<ResearchBoxRef>;
  producedResearchBoxId?: Maybe<Scalars['String']>;
};

export type ProductionTagRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  moduleId?: Maybe<Scalars['String']>;
  module?: Maybe<PipelineModuleRef>;
  moduleParams?: Maybe<Scalars['String']>;
  producedResearchBox?: Maybe<ResearchBoxRef>;
  producedResearchBoxId?: Maybe<Scalars['String']>;
};

export type ProgressNotification = Notification & {
  __typename?: 'ProgressNotification';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  read: Scalars['Boolean'];
  title: Scalars['String'];
  message: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  currentProgress: Scalars['Int'];
  maxProgress: Scalars['Int'];
};

export type ProgressNotificationAggregateResult = {
  __typename?: 'ProgressNotificationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  titleMin?: Maybe<Scalars['String']>;
  titleMax?: Maybe<Scalars['String']>;
  messageMin?: Maybe<Scalars['String']>;
  messageMax?: Maybe<Scalars['String']>;
  errorMin?: Maybe<Scalars['String']>;
  errorMax?: Maybe<Scalars['String']>;
  currentProgressMin?: Maybe<Scalars['Int']>;
  currentProgressMax?: Maybe<Scalars['Int']>;
  currentProgressSum?: Maybe<Scalars['Int']>;
  currentProgressAvg?: Maybe<Scalars['Float']>;
  maxProgressMin?: Maybe<Scalars['Int']>;
  maxProgressMax?: Maybe<Scalars['Int']>;
  maxProgressSum?: Maybe<Scalars['Int']>;
  maxProgressAvg?: Maybe<Scalars['Float']>;
};

export type ProgressNotificationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  read?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<ProgressNotificationHasFilter>>>;
  and?: Maybe<Array<Maybe<ProgressNotificationFilter>>>;
  or?: Maybe<Array<Maybe<ProgressNotificationFilter>>>;
  not?: Maybe<ProgressNotificationFilter>;
};

export enum ProgressNotificationHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Read = 'read',
  Title = 'title',
  Message = 'message',
  Error = 'error',
  CurrentProgress = 'currentProgress',
  MaxProgress = 'maxProgress'
}

export type ProgressNotificationOrder = {
  asc?: Maybe<ProgressNotificationOrderable>;
  desc?: Maybe<ProgressNotificationOrderable>;
  then?: Maybe<ProgressNotificationOrder>;
};

export enum ProgressNotificationOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  Title = 'title',
  Message = 'message',
  Error = 'error',
  CurrentProgress = 'currentProgress',
  MaxProgress = 'maxProgress'
}

export type ProgressNotificationPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  currentProgress?: Maybe<Scalars['Int']>;
  maxProgress?: Maybe<Scalars['Int']>;
};

export type ProgressNotificationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  currentProgress?: Maybe<Scalars['Int']>;
  maxProgress?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  queryFilteredFoldersAndResearchBoxes?: Maybe<QueryFilteredFoldersAndResearchBoxesPayload>;
  queryBuildInfo?: Maybe<QueryBuildInfoPayload>;
  listBackups?: Maybe<ListBackupsPayload>;
  getMigration?: Maybe<Migration>;
  queryMigration?: Maybe<Array<Maybe<Migration>>>;
  aggregateMigration?: Maybe<MigrationAggregateResult>;
  getAppState?: Maybe<AppState>;
  queryAppState?: Maybe<Array<Maybe<AppState>>>;
  aggregateAppState?: Maybe<AppStateAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getCuid?: Maybe<Cuid>;
  queryCuid?: Maybe<Array<Maybe<Cuid>>>;
  aggregateCuid?: Maybe<CuidAggregateResult>;
  queryOwnable?: Maybe<Array<Maybe<Ownable>>>;
  aggregateOwnable?: Maybe<OwnableAggregateResult>;
  queryReadOnly?: Maybe<Array<Maybe<ReadOnly>>>;
  aggregateReadOnly?: Maybe<ReadOnlyAggregateResult>;
  queryTimestamped?: Maybe<Array<Maybe<Timestamped>>>;
  aggregateTimestamped?: Maybe<TimestampedAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getNotification?: Maybe<Notification>;
  queryNotification?: Maybe<Array<Maybe<Notification>>>;
  aggregateNotification?: Maybe<NotificationAggregateResult>;
  getProgressNotification?: Maybe<ProgressNotification>;
  queryProgressNotification?: Maybe<Array<Maybe<ProgressNotification>>>;
  aggregateProgressNotification?: Maybe<ProgressNotificationAggregateResult>;
  getDenktesterNotification?: Maybe<DenktesterNotification>;
  queryDenktesterNotification?: Maybe<Array<Maybe<DenktesterNotification>>>;
  aggregateDenktesterNotification?: Maybe<DenktesterNotificationAggregateResult>;
  getMaintenanceNotification?: Maybe<MaintenanceNotification>;
  queryMaintenanceNotification?: Maybe<Array<Maybe<MaintenanceNotification>>>;
  aggregateMaintenanceNotification?: Maybe<MaintenanceNotificationAggregateResult>;
  getOneOffNotification?: Maybe<OneOffNotification>;
  queryOneOffNotification?: Maybe<Array<Maybe<OneOffNotification>>>;
  aggregateOneOffNotification?: Maybe<OneOffNotificationAggregateResult>;
  getUser?: Maybe<User>;
  queryUser?: Maybe<Array<Maybe<User>>>;
  aggregateUser?: Maybe<UserAggregateResult>;
  getDataset?: Maybe<Dataset>;
  queryDataset?: Maybe<Array<Maybe<Dataset>>>;
  aggregateDataset?: Maybe<DatasetAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getFolderOrResearchBox?: Maybe<FolderOrResearchBox>;
  queryFolderOrResearchBox?: Maybe<Array<Maybe<FolderOrResearchBox>>>;
  aggregateFolderOrResearchBox?: Maybe<FolderOrResearchBoxAggregateResult>;
  getFolder?: Maybe<Folder>;
  queryFolder?: Maybe<Array<Maybe<Folder>>>;
  aggregateFolder?: Maybe<FolderAggregateResult>;
  getResearchBox?: Maybe<ResearchBox>;
  queryResearchBox?: Maybe<Array<Maybe<ResearchBox>>>;
  aggregateResearchBox?: Maybe<ResearchBoxAggregateResult>;
  getProductionTag?: Maybe<ProductionTag>;
  queryProductionTag?: Maybe<Array<Maybe<ProductionTag>>>;
  aggregateProductionTag?: Maybe<ProductionTagAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getDatapoint?: Maybe<Datapoint>;
  queryDatapoint?: Maybe<Array<Maybe<Datapoint>>>;
  aggregateDatapoint?: Maybe<DatapointAggregateResult>;
  getCCCheckerResult?: Maybe<CcCheckerResult>;
  queryCCCheckerResult?: Maybe<Array<Maybe<CcCheckerResult>>>;
  aggregateCCCheckerResult?: Maybe<CcCheckerResultAggregateResult>;
  getImageResolution?: Maybe<ImageResolution>;
  queryImageResolution?: Maybe<Array<Maybe<ImageResolution>>>;
  aggregateImageResolution?: Maybe<ImageResolutionAggregateResult>;
  getImageMetadataField?: Maybe<ImageMetadataField>;
  queryImageMetadataField?: Maybe<Array<Maybe<ImageMetadataField>>>;
  aggregateImageMetadataField?: Maybe<ImageMetadataFieldAggregateResult>;
  getImage?: Maybe<Image>;
  queryImage?: Maybe<Array<Maybe<Image>>>;
  aggregateImage?: Maybe<ImageAggregateResult>;
  getMagneticFieldScan?: Maybe<MagneticFieldScan>;
  queryMagneticFieldScan?: Maybe<Array<Maybe<MagneticFieldScan>>>;
  aggregateMagneticFieldScan?: Maybe<MagneticFieldScanAggregateResult>;
  getMarkupLabel?: Maybe<MarkupLabel>;
  queryMarkupLabel?: Maybe<Array<Maybe<MarkupLabel>>>;
  aggregateMarkupLabel?: Maybe<MarkupLabelAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getSegmentationImageAnnotation?: Maybe<SegmentationImageAnnotation>;
  querySegmentationImageAnnotation?: Maybe<Array<Maybe<SegmentationImageAnnotation>>>;
  aggregateSegmentationImageAnnotation?: Maybe<SegmentationImageAnnotationAggregateResult>;
  getPoint2D?: Maybe<Point2D>;
  queryPoint2D?: Maybe<Array<Maybe<Point2D>>>;
  aggregatePoint2D?: Maybe<Point2DAggregateResult>;
  getCircleAnnotation?: Maybe<CircleAnnotation>;
  queryCircleAnnotation?: Maybe<Array<Maybe<CircleAnnotation>>>;
  aggregateCircleAnnotation?: Maybe<CircleAnnotationAggregateResult>;
  getPolygonAnnotation?: Maybe<PolygonAnnotation>;
  queryPolygonAnnotation?: Maybe<Array<Maybe<PolygonAnnotation>>>;
  aggregatePolygonAnnotation?: Maybe<PolygonAnnotationAggregateResult>;
  getPenAnnotation?: Maybe<PenAnnotation>;
  queryPenAnnotation?: Maybe<Array<Maybe<PenAnnotation>>>;
  aggregatePenAnnotation?: Maybe<PenAnnotationAggregateResult>;
  getRectangleAnnotation?: Maybe<RectangleAnnotation>;
  queryRectangleAnnotation?: Maybe<Array<Maybe<RectangleAnnotation>>>;
  aggregateRectangleAnnotation?: Maybe<RectangleAnnotationAggregateResult>;
  getSausageAnnotation?: Maybe<SausageAnnotation>;
  querySausageAnnotation?: Maybe<Array<Maybe<SausageAnnotation>>>;
  aggregateSausageAnnotation?: Maybe<SausageAnnotationAggregateResult>;
  getMagicwandAnnotation?: Maybe<MagicwandAnnotation>;
  queryMagicwandAnnotation?: Maybe<Array<Maybe<MagicwandAnnotation>>>;
  aggregateMagicwandAnnotation?: Maybe<MagicwandAnnotationAggregateResult>;
  getPixelAnnotation?: Maybe<PixelAnnotation>;
  queryPixelAnnotation?: Maybe<Array<Maybe<PixelAnnotation>>>;
  aggregatePixelAnnotation?: Maybe<PixelAnnotationAggregateResult>;
  getBoundingBoxAnnotation?: Maybe<BoundingBoxAnnotation>;
  queryBoundingBoxAnnotation?: Maybe<Array<Maybe<BoundingBoxAnnotation>>>;
  aggregateBoundingBoxAnnotation?: Maybe<BoundingBoxAnnotationAggregateResult>;
  getBoundingBoxPrediction?: Maybe<BoundingBoxPrediction>;
  queryBoundingBoxPrediction?: Maybe<Array<Maybe<BoundingBoxPrediction>>>;
  aggregateBoundingBoxPrediction?: Maybe<BoundingBoxPredictionAggregateResult>;
  getScalarAnnotation?: Maybe<ScalarAnnotation>;
  queryScalarAnnotation?: Maybe<Array<Maybe<ScalarAnnotation>>>;
  aggregateScalarAnnotation?: Maybe<ScalarAnnotationAggregateResult>;
  getScalarPrediction?: Maybe<ScalarPrediction>;
  queryScalarPrediction?: Maybe<Array<Maybe<ScalarPrediction>>>;
  aggregateScalarPrediction?: Maybe<ScalarPredictionAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getImageMarkup?: Maybe<ImageMarkup>;
  queryImageMarkup?: Maybe<Array<Maybe<ImageMarkup>>>;
  aggregateImageMarkup?: Maybe<ImageMarkupAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getImagePrediction?: Maybe<ImagePrediction>;
  queryImagePrediction?: Maybe<Array<Maybe<ImagePrediction>>>;
  aggregateImagePrediction?: Maybe<ImagePredictionAggregateResult>;
  getSegmentationMap2D?: Maybe<SegmentationMap2D>;
  querySegmentationMap2D?: Maybe<Array<Maybe<SegmentationMap2D>>>;
  aggregateSegmentationMap2D?: Maybe<SegmentationMap2DAggregateResult>;
  getSegmentationMarkup?: Maybe<SegmentationMarkup>;
  querySegmentationMarkup?: Maybe<Array<Maybe<SegmentationMarkup>>>;
  aggregateSegmentationMarkup?: Maybe<SegmentationMarkupAggregateResult>;
  getSegmentationPredictionObject?: Maybe<SegmentationPredictionObject>;
  querySegmentationPredictionObject?: Maybe<Array<Maybe<SegmentationPredictionObject>>>;
  aggregateSegmentationPredictionObject?: Maybe<SegmentationPredictionObjectAggregateResult>;
  getSegmentationPrediction?: Maybe<SegmentationPrediction>;
  querySegmentationPrediction?: Maybe<Array<Maybe<SegmentationPrediction>>>;
  aggregateSegmentationPrediction?: Maybe<SegmentationPredictionAggregateResult>;
  getClassificationMarkup?: Maybe<ClassificationMarkup>;
  queryClassificationMarkup?: Maybe<Array<Maybe<ClassificationMarkup>>>;
  aggregateClassificationMarkup?: Maybe<ClassificationMarkupAggregateResult>;
  getClassificationPrediction?: Maybe<ClassificationPrediction>;
  queryClassificationPrediction?: Maybe<Array<Maybe<ClassificationPrediction>>>;
  aggregateClassificationPrediction?: Maybe<ClassificationPredictionAggregateResult>;
  getInstanceSegmentationMarkup?: Maybe<InstanceSegmentationMarkup>;
  queryInstanceSegmentationMarkup?: Maybe<Array<Maybe<InstanceSegmentationMarkup>>>;
  aggregateInstanceSegmentationMarkup?: Maybe<InstanceSegmentationMarkupAggregateResult>;
  getInstanceSegmentationPrediction?: Maybe<InstanceSegmentationPrediction>;
  queryInstanceSegmentationPrediction?: Maybe<Array<Maybe<InstanceSegmentationPrediction>>>;
  aggregateInstanceSegmentationPrediction?: Maybe<InstanceSegmentationPredictionAggregateResult>;
  getObjectDetectionMarkup?: Maybe<ObjectDetectionMarkup>;
  queryObjectDetectionMarkup?: Maybe<Array<Maybe<ObjectDetectionMarkup>>>;
  aggregateObjectDetectionMarkup?: Maybe<ObjectDetectionMarkupAggregateResult>;
  getObjectDetectionPrediction?: Maybe<ObjectDetectionPrediction>;
  queryObjectDetectionPrediction?: Maybe<Array<Maybe<ObjectDetectionPrediction>>>;
  aggregateObjectDetectionPrediction?: Maybe<ObjectDetectionPredictionAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getModuleJobRequest?: Maybe<ModuleJobRequest>;
  queryModuleJobRequest?: Maybe<Array<Maybe<ModuleJobRequest>>>;
  aggregateModuleJobRequest?: Maybe<ModuleJobRequestAggregateResult>;
  getPipeline?: Maybe<Pipeline>;
  queryPipeline?: Maybe<Array<Maybe<Pipeline>>>;
  aggregatePipeline?: Maybe<PipelineAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getPipelineModuleOutput?: Maybe<PipelineModuleOutput>;
  queryPipelineModuleOutput?: Maybe<Array<Maybe<PipelineModuleOutput>>>;
  aggregatePipelineModuleOutput?: Maybe<PipelineModuleOutputAggregateResult>;
  getPipelineModuleDatapointOutput?: Maybe<PipelineModuleDatapointOutput>;
  queryPipelineModuleDatapointOutput?: Maybe<Array<Maybe<PipelineModuleDatapointOutput>>>;
  aggregatePipelineModuleDatapointOutput?: Maybe<PipelineModuleDatapointOutputAggregateResult>;
  getPipelineModuleArtifactOutput?: Maybe<PipelineModuleArtifactOutput>;
  queryPipelineModuleArtifactOutput?: Maybe<Array<Maybe<PipelineModuleArtifactOutput>>>;
  aggregatePipelineModuleArtifactOutput?: Maybe<PipelineModuleArtifactOutputAggregateResult>;
  getPipelineModuleInput?: Maybe<PipelineModuleInput>;
  queryPipelineModuleInput?: Maybe<Array<Maybe<PipelineModuleInput>>>;
  aggregatePipelineModuleInput?: Maybe<PipelineModuleInputAggregateResult>;
  getPipelineModuleEdge?: Maybe<PipelineModuleEdge>;
  queryPipelineModuleEdge?: Maybe<Array<Maybe<PipelineModuleEdge>>>;
  aggregatePipelineModuleEdge?: Maybe<PipelineModuleEdgeAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getPipelineModule?: Maybe<PipelineModule>;
  queryPipelineModule?: Maybe<Array<Maybe<PipelineModule>>>;
  aggregatePipelineModule?: Maybe<PipelineModuleAggregateResult>;
  getPipelineModuleUpload?: Maybe<PipelineModuleUpload>;
  queryPipelineModuleUpload?: Maybe<Array<Maybe<PipelineModuleUpload>>>;
  aggregatePipelineModuleUpload?: Maybe<PipelineModuleUploadAggregateResult>;
  getPipelineModuleBMobile?: Maybe<PipelineModuleBMobile>;
  queryPipelineModuleBMobile?: Maybe<Array<Maybe<PipelineModuleBMobile>>>;
  aggregatePipelineModuleBMobile?: Maybe<PipelineModuleBMobileAggregateResult>;
  getPipelineModuleCCChecker?: Maybe<PipelineModuleCcChecker>;
  queryPipelineModuleCCChecker?: Maybe<Array<Maybe<PipelineModuleCcChecker>>>;
  aggregatePipelineModuleCCChecker?: Maybe<PipelineModuleCcCheckerAggregateResult>;
  getPipelineModuleJobRequestCCChecker?: Maybe<PipelineModuleJobRequestCcChecker>;
  queryPipelineModuleJobRequestCCChecker?: Maybe<Array<Maybe<PipelineModuleJobRequestCcChecker>>>;
  aggregatePipelineModuleJobRequestCCChecker?: Maybe<PipelineModuleJobRequestCcCheckerAggregateResult>;
  getPipelineModuleContrastEnhancement?: Maybe<PipelineModuleContrastEnhancement>;
  queryPipelineModuleContrastEnhancement?: Maybe<Array<Maybe<PipelineModuleContrastEnhancement>>>;
  aggregatePipelineModuleContrastEnhancement?: Maybe<PipelineModuleContrastEnhancementAggregateResult>;
  getPipelineModuleJobRequestContrastEnhancement?: Maybe<PipelineModuleJobRequestContrastEnhancement>;
  queryPipelineModuleJobRequestContrastEnhancement?: Maybe<Array<Maybe<PipelineModuleJobRequestContrastEnhancement>>>;
  aggregatePipelineModuleJobRequestContrastEnhancement?: Maybe<PipelineModuleJobRequestContrastEnhancementAggregateResult>;
  getGpu?: Maybe<Gpu>;
  queryGpu?: Maybe<Array<Maybe<Gpu>>>;
  aggregateGpu?: Maybe<GpuAggregateResult>;
  getDenkbox?: Maybe<Denkbox>;
  queryDenkbox?: Maybe<Array<Maybe<Denkbox>>>;
  aggregateDenkbox?: Maybe<DenkboxAggregateResult>;
  getDenkjob?: Maybe<Denkjob>;
  queryDenkjob?: Maybe<Array<Maybe<Denkjob>>>;
  aggregateDenkjob?: Maybe<DenkjobAggregateResult>;
  getMetric?: Maybe<Metric>;
  queryMetric?: Maybe<Array<Maybe<Metric>>>;
  aggregateMetric?: Maybe<MetricAggregateResult>;
  getBinary?: Maybe<Binary>;
  queryBinary?: Maybe<Array<Maybe<Binary>>>;
  aggregateBinary?: Maybe<BinaryAggregateResult>;
  getEncryptedBinary?: Maybe<EncryptedBinary>;
  queryEncryptedBinary?: Maybe<Array<Maybe<EncryptedBinary>>>;
  aggregateEncryptedBinary?: Maybe<EncryptedBinaryAggregateResult>;
  getNetworkSnapshot?: Maybe<NetworkSnapshot>;
  queryNetworkSnapshot?: Maybe<Array<Maybe<NetworkSnapshot>>>;
  aggregateNetworkSnapshot?: Maybe<NetworkSnapshotAggregateResult>;
  getNetworkConfig?: Maybe<NetworkConfig>;
  queryNetworkConfig?: Maybe<Array<Maybe<NetworkConfig>>>;
  aggregateNetworkConfig?: Maybe<NetworkConfigAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getNetworkModule?: Maybe<NetworkModule>;
  queryNetworkModule?: Maybe<Array<Maybe<NetworkModule>>>;
  aggregateNetworkModule?: Maybe<NetworkModuleAggregateResult>;
  getPipelineModuleNetworkImageSegmentation?: Maybe<PipelineModuleNetworkImageSegmentation>;
  queryPipelineModuleNetworkImageSegmentation?: Maybe<Array<Maybe<PipelineModuleNetworkImageSegmentation>>>;
  aggregatePipelineModuleNetworkImageSegmentation?: Maybe<PipelineModuleNetworkImageSegmentationAggregateResult>;
  getPipelineModuleNetworkImageClassification?: Maybe<PipelineModuleNetworkImageClassification>;
  queryPipelineModuleNetworkImageClassification?: Maybe<Array<Maybe<PipelineModuleNetworkImageClassification>>>;
  aggregatePipelineModuleNetworkImageClassification?: Maybe<PipelineModuleNetworkImageClassificationAggregateResult>;
  getPipelineModuleNetworkImageObjectDetection?: Maybe<PipelineModuleNetworkImageObjectDetection>;
  queryPipelineModuleNetworkImageObjectDetection?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectDetection>>>;
  aggregatePipelineModuleNetworkImageObjectDetection?: Maybe<PipelineModuleNetworkImageObjectDetectionAggregateResult>;
  getPipelineModuleNetworkImageInstanceSegmentation?: Maybe<PipelineModuleNetworkImageInstanceSegmentation>;
  queryPipelineModuleNetworkImageInstanceSegmentation?: Maybe<Array<Maybe<PipelineModuleNetworkImageInstanceSegmentation>>>;
  aggregatePipelineModuleNetworkImageInstanceSegmentation?: Maybe<PipelineModuleNetworkImageInstanceSegmentationAggregateResult>;
  getPipelineModuleNetworkImageSuperResolution?: Maybe<PipelineModuleNetworkImageSuperResolution>;
  queryPipelineModuleNetworkImageSuperResolution?: Maybe<Array<Maybe<PipelineModuleNetworkImageSuperResolution>>>;
  aggregatePipelineModuleNetworkImageSuperResolution?: Maybe<PipelineModuleNetworkImageSuperResolutionAggregateResult>;
  getPipelineModuleNetworkImageOCR?: Maybe<PipelineModuleNetworkImageOcr>;
  queryPipelineModuleNetworkImageOCR?: Maybe<Array<Maybe<PipelineModuleNetworkImageOcr>>>;
  aggregatePipelineModuleNetworkImageOCR?: Maybe<PipelineModuleNetworkImageOcrAggregateResult>;
  getPipelineModuleNetworkImageAnomalyDetection?: Maybe<PipelineModuleNetworkImageAnomalyDetection>;
  queryPipelineModuleNetworkImageAnomalyDetection?: Maybe<Array<Maybe<PipelineModuleNetworkImageAnomalyDetection>>>;
  aggregatePipelineModuleNetworkImageAnomalyDetection?: Maybe<PipelineModuleNetworkImageAnomalyDetectionAggregateResult>;
  getPipelineModuleNetworkImageObjectCounting?: Maybe<PipelineModuleNetworkImageObjectCounting>;
  queryPipelineModuleNetworkImageObjectCounting?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectCounting>>>;
  aggregatePipelineModuleNetworkImageObjectCounting?: Maybe<PipelineModuleNetworkImageObjectCountingAggregateResult>;
  getPretrainedNetwork?: Maybe<PretrainedNetwork>;
  queryPretrainedNetwork?: Maybe<Array<Maybe<PretrainedNetwork>>>;
  aggregatePretrainedNetwork?: Maybe<PretrainedNetworkAggregateResult>;
};


export type QueryQueryFilteredFoldersAndResearchBoxesArgs = {
  input: QueryFilteredFoldersAndResearchBoxesInput;
};


export type QueryGetMigrationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryMigrationArgs = {
  filter?: Maybe<MigrationFilter>;
  order?: Maybe<MigrationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateMigrationArgs = {
  filter?: Maybe<MigrationFilter>;
};


export type QueryGetAppStateArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryAppStateArgs = {
  filter?: Maybe<AppStateFilter>;
  order?: Maybe<AppStateOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateAppStateArgs = {
  filter?: Maybe<AppStateFilter>;
};


export type QueryGetCuidArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryCuidArgs = {
  filter?: Maybe<CuidFilter>;
  order?: Maybe<CuidOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateCuidArgs = {
  filter?: Maybe<CuidFilter>;
};


export type QueryQueryOwnableArgs = {
  filter?: Maybe<OwnableFilter>;
  order?: Maybe<OwnableOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateOwnableArgs = {
  filter?: Maybe<OwnableFilter>;
};


export type QueryQueryReadOnlyArgs = {
  filter?: Maybe<ReadOnlyFilter>;
  order?: Maybe<ReadOnlyOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateReadOnlyArgs = {
  filter?: Maybe<ReadOnlyFilter>;
};


export type QueryQueryTimestampedArgs = {
  filter?: Maybe<TimestampedFilter>;
  order?: Maybe<TimestampedOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateTimestampedArgs = {
  filter?: Maybe<TimestampedFilter>;
};


export type QueryGetNotificationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryNotificationArgs = {
  filter?: Maybe<NotificationFilter>;
  order?: Maybe<NotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateNotificationArgs = {
  filter?: Maybe<NotificationFilter>;
};


export type QueryGetProgressNotificationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryProgressNotificationArgs = {
  filter?: Maybe<ProgressNotificationFilter>;
  order?: Maybe<ProgressNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateProgressNotificationArgs = {
  filter?: Maybe<ProgressNotificationFilter>;
};


export type QueryGetDenktesterNotificationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryDenktesterNotificationArgs = {
  filter?: Maybe<DenktesterNotificationFilter>;
  order?: Maybe<DenktesterNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateDenktesterNotificationArgs = {
  filter?: Maybe<DenktesterNotificationFilter>;
};


export type QueryGetMaintenanceNotificationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryMaintenanceNotificationArgs = {
  filter?: Maybe<MaintenanceNotificationFilter>;
  order?: Maybe<MaintenanceNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateMaintenanceNotificationArgs = {
  filter?: Maybe<MaintenanceNotificationFilter>;
};


export type QueryGetOneOffNotificationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryOneOffNotificationArgs = {
  filter?: Maybe<OneOffNotificationFilter>;
  order?: Maybe<OneOffNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateOneOffNotificationArgs = {
  filter?: Maybe<OneOffNotificationFilter>;
};


export type QueryGetUserArgs = {
  username: Scalars['String'];
};


export type QueryQueryUserArgs = {
  filter?: Maybe<UserFilter>;
  order?: Maybe<UserOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateUserArgs = {
  filter?: Maybe<UserFilter>;
};


export type QueryGetDatasetArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryDatasetArgs = {
  filter?: Maybe<DatasetFilter>;
  order?: Maybe<DatasetOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateDatasetArgs = {
  filter?: Maybe<DatasetFilter>;
};


export type QueryGetFolderOrResearchBoxArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryFolderOrResearchBoxArgs = {
  filter?: Maybe<FolderOrResearchBoxFilter>;
  order?: Maybe<FolderOrResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateFolderOrResearchBoxArgs = {
  filter?: Maybe<FolderOrResearchBoxFilter>;
};


export type QueryGetFolderArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryFolderArgs = {
  filter?: Maybe<FolderFilter>;
  order?: Maybe<FolderOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateFolderArgs = {
  filter?: Maybe<FolderFilter>;
};


export type QueryGetResearchBoxArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryResearchBoxArgs = {
  filter?: Maybe<ResearchBoxFilter>;
  order?: Maybe<ResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateResearchBoxArgs = {
  filter?: Maybe<ResearchBoxFilter>;
};


export type QueryGetProductionTagArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryProductionTagArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateProductionTagArgs = {
  filter?: Maybe<ProductionTagFilter>;
};


export type QueryGetDatapointArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryDatapointArgs = {
  filter?: Maybe<DatapointFilter>;
  order?: Maybe<DatapointOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateDatapointArgs = {
  filter?: Maybe<DatapointFilter>;
};


export type QueryGetCcCheckerResultArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryCcCheckerResultArgs = {
  filter?: Maybe<CcCheckerResultFilter>;
  order?: Maybe<CcCheckerResultOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateCcCheckerResultArgs = {
  filter?: Maybe<CcCheckerResultFilter>;
};


export type QueryGetImageResolutionArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryImageResolutionArgs = {
  filter?: Maybe<ImageResolutionFilter>;
  order?: Maybe<ImageResolutionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateImageResolutionArgs = {
  filter?: Maybe<ImageResolutionFilter>;
};


export type QueryGetImageMetadataFieldArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryImageMetadataFieldArgs = {
  filter?: Maybe<ImageMetadataFieldFilter>;
  order?: Maybe<ImageMetadataFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateImageMetadataFieldArgs = {
  filter?: Maybe<ImageMetadataFieldFilter>;
};


export type QueryGetImageArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryImageArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateImageArgs = {
  filter?: Maybe<ImageFilter>;
};


export type QueryGetMagneticFieldScanArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryMagneticFieldScanArgs = {
  filter?: Maybe<MagneticFieldScanFilter>;
  order?: Maybe<MagneticFieldScanOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateMagneticFieldScanArgs = {
  filter?: Maybe<MagneticFieldScanFilter>;
};


export type QueryGetMarkupLabelArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryMarkupLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
  order?: Maybe<MarkupLabelOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateMarkupLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type QueryGetSegmentationImageAnnotationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQuerySegmentationImageAnnotationArgs = {
  filter?: Maybe<SegmentationImageAnnotationFilter>;
  order?: Maybe<SegmentationImageAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateSegmentationImageAnnotationArgs = {
  filter?: Maybe<SegmentationImageAnnotationFilter>;
};


export type QueryGetPoint2DArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPoint2DArgs = {
  filter?: Maybe<Point2DFilter>;
  order?: Maybe<Point2DOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePoint2DArgs = {
  filter?: Maybe<Point2DFilter>;
};


export type QueryGetCircleAnnotationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryCircleAnnotationArgs = {
  filter?: Maybe<CircleAnnotationFilter>;
  order?: Maybe<CircleAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateCircleAnnotationArgs = {
  filter?: Maybe<CircleAnnotationFilter>;
};


export type QueryGetPolygonAnnotationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPolygonAnnotationArgs = {
  filter?: Maybe<PolygonAnnotationFilter>;
  order?: Maybe<PolygonAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePolygonAnnotationArgs = {
  filter?: Maybe<PolygonAnnotationFilter>;
};


export type QueryGetPenAnnotationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPenAnnotationArgs = {
  filter?: Maybe<PenAnnotationFilter>;
  order?: Maybe<PenAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePenAnnotationArgs = {
  filter?: Maybe<PenAnnotationFilter>;
};


export type QueryGetRectangleAnnotationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryRectangleAnnotationArgs = {
  filter?: Maybe<RectangleAnnotationFilter>;
  order?: Maybe<RectangleAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateRectangleAnnotationArgs = {
  filter?: Maybe<RectangleAnnotationFilter>;
};


export type QueryGetSausageAnnotationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQuerySausageAnnotationArgs = {
  filter?: Maybe<SausageAnnotationFilter>;
  order?: Maybe<SausageAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateSausageAnnotationArgs = {
  filter?: Maybe<SausageAnnotationFilter>;
};


export type QueryGetMagicwandAnnotationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryMagicwandAnnotationArgs = {
  filter?: Maybe<MagicwandAnnotationFilter>;
  order?: Maybe<MagicwandAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateMagicwandAnnotationArgs = {
  filter?: Maybe<MagicwandAnnotationFilter>;
};


export type QueryGetPixelAnnotationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPixelAnnotationArgs = {
  filter?: Maybe<PixelAnnotationFilter>;
  order?: Maybe<PixelAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePixelAnnotationArgs = {
  filter?: Maybe<PixelAnnotationFilter>;
};


export type QueryGetBoundingBoxAnnotationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryBoundingBoxAnnotationArgs = {
  filter?: Maybe<BoundingBoxAnnotationFilter>;
  order?: Maybe<BoundingBoxAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateBoundingBoxAnnotationArgs = {
  filter?: Maybe<BoundingBoxAnnotationFilter>;
};


export type QueryGetBoundingBoxPredictionArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryBoundingBoxPredictionArgs = {
  filter?: Maybe<BoundingBoxPredictionFilter>;
  order?: Maybe<BoundingBoxPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateBoundingBoxPredictionArgs = {
  filter?: Maybe<BoundingBoxPredictionFilter>;
};


export type QueryGetScalarAnnotationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryScalarAnnotationArgs = {
  filter?: Maybe<ScalarAnnotationFilter>;
  order?: Maybe<ScalarAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateScalarAnnotationArgs = {
  filter?: Maybe<ScalarAnnotationFilter>;
};


export type QueryGetScalarPredictionArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryScalarPredictionArgs = {
  filter?: Maybe<ScalarPredictionFilter>;
  order?: Maybe<ScalarPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateScalarPredictionArgs = {
  filter?: Maybe<ScalarPredictionFilter>;
};


export type QueryGetImageMarkupArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryImageMarkupArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateImageMarkupArgs = {
  filter?: Maybe<ImageMarkupFilter>;
};


export type QueryGetImagePredictionArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryImagePredictionArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateImagePredictionArgs = {
  filter?: Maybe<ImagePredictionFilter>;
};


export type QueryGetSegmentationMap2DArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQuerySegmentationMap2DArgs = {
  filter?: Maybe<SegmentationMap2DFilter>;
  order?: Maybe<SegmentationMap2DOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateSegmentationMap2DArgs = {
  filter?: Maybe<SegmentationMap2DFilter>;
};


export type QueryGetSegmentationMarkupArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQuerySegmentationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
  order?: Maybe<SegmentationMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateSegmentationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
};


export type QueryGetSegmentationPredictionObjectArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQuerySegmentationPredictionObjectArgs = {
  filter?: Maybe<SegmentationPredictionObjectFilter>;
  order?: Maybe<SegmentationPredictionObjectOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateSegmentationPredictionObjectArgs = {
  filter?: Maybe<SegmentationPredictionObjectFilter>;
};


export type QueryGetSegmentationPredictionArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQuerySegmentationPredictionArgs = {
  filter?: Maybe<SegmentationPredictionFilter>;
  order?: Maybe<SegmentationPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateSegmentationPredictionArgs = {
  filter?: Maybe<SegmentationPredictionFilter>;
};


export type QueryGetClassificationMarkupArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryClassificationMarkupArgs = {
  filter?: Maybe<ClassificationMarkupFilter>;
  order?: Maybe<ClassificationMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateClassificationMarkupArgs = {
  filter?: Maybe<ClassificationMarkupFilter>;
};


export type QueryGetClassificationPredictionArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryClassificationPredictionArgs = {
  filter?: Maybe<ClassificationPredictionFilter>;
  order?: Maybe<ClassificationPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateClassificationPredictionArgs = {
  filter?: Maybe<ClassificationPredictionFilter>;
};


export type QueryGetInstanceSegmentationMarkupArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryInstanceSegmentationMarkupArgs = {
  filter?: Maybe<InstanceSegmentationMarkupFilter>;
  order?: Maybe<InstanceSegmentationMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateInstanceSegmentationMarkupArgs = {
  filter?: Maybe<InstanceSegmentationMarkupFilter>;
};


export type QueryGetInstanceSegmentationPredictionArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryInstanceSegmentationPredictionArgs = {
  filter?: Maybe<InstanceSegmentationPredictionFilter>;
  order?: Maybe<InstanceSegmentationPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateInstanceSegmentationPredictionArgs = {
  filter?: Maybe<InstanceSegmentationPredictionFilter>;
};


export type QueryGetObjectDetectionMarkupArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryObjectDetectionMarkupArgs = {
  filter?: Maybe<ObjectDetectionMarkupFilter>;
  order?: Maybe<ObjectDetectionMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateObjectDetectionMarkupArgs = {
  filter?: Maybe<ObjectDetectionMarkupFilter>;
};


export type QueryGetObjectDetectionPredictionArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryObjectDetectionPredictionArgs = {
  filter?: Maybe<ObjectDetectionPredictionFilter>;
  order?: Maybe<ObjectDetectionPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateObjectDetectionPredictionArgs = {
  filter?: Maybe<ObjectDetectionPredictionFilter>;
};


export type QueryGetModuleJobRequestArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryModuleJobRequestArgs = {
  filter?: Maybe<ModuleJobRequestFilter>;
  order?: Maybe<ModuleJobRequestOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateModuleJobRequestArgs = {
  filter?: Maybe<ModuleJobRequestFilter>;
};


export type QueryGetPipelineArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
  order?: Maybe<PipelineOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type QueryGetPipelineModuleOutputArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleOutputArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleOutputArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type QueryGetPipelineModuleDatapointOutputArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleDatapointOutputArgs = {
  filter?: Maybe<PipelineModuleDatapointOutputFilter>;
  order?: Maybe<PipelineModuleDatapointOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleDatapointOutputArgs = {
  filter?: Maybe<PipelineModuleDatapointOutputFilter>;
};


export type QueryGetPipelineModuleArtifactOutputArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleArtifactOutputArgs = {
  filter?: Maybe<PipelineModuleArtifactOutputFilter>;
  order?: Maybe<PipelineModuleArtifactOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleArtifactOutputArgs = {
  filter?: Maybe<PipelineModuleArtifactOutputFilter>;
};


export type QueryGetPipelineModuleInputArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleInputArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleInputArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
};


export type QueryGetPipelineModuleEdgeArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleEdgeArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
  order?: Maybe<PipelineModuleEdgeOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleEdgeArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
};


export type QueryGetPipelineModuleArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
  order?: Maybe<PipelineModuleOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
};


export type QueryGetPipelineModuleUploadArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleUploadArgs = {
  filter?: Maybe<PipelineModuleUploadFilter>;
  order?: Maybe<PipelineModuleUploadOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleUploadArgs = {
  filter?: Maybe<PipelineModuleUploadFilter>;
};


export type QueryGetPipelineModuleBMobileArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleBMobileArgs = {
  filter?: Maybe<PipelineModuleBMobileFilter>;
  order?: Maybe<PipelineModuleBMobileOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleBMobileArgs = {
  filter?: Maybe<PipelineModuleBMobileFilter>;
};


export type QueryGetPipelineModuleCcCheckerArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleCcCheckerArgs = {
  filter?: Maybe<PipelineModuleCcCheckerFilter>;
  order?: Maybe<PipelineModuleCcCheckerOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleCcCheckerArgs = {
  filter?: Maybe<PipelineModuleCcCheckerFilter>;
};


export type QueryGetPipelineModuleJobRequestCcCheckerArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleJobRequestCcCheckerArgs = {
  filter?: Maybe<PipelineModuleJobRequestCcCheckerFilter>;
  order?: Maybe<PipelineModuleJobRequestCcCheckerOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleJobRequestCcCheckerArgs = {
  filter?: Maybe<PipelineModuleJobRequestCcCheckerFilter>;
};


export type QueryGetPipelineModuleContrastEnhancementArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleContrastEnhancementArgs = {
  filter?: Maybe<PipelineModuleContrastEnhancementFilter>;
  order?: Maybe<PipelineModuleContrastEnhancementOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleContrastEnhancementArgs = {
  filter?: Maybe<PipelineModuleContrastEnhancementFilter>;
};


export type QueryGetPipelineModuleJobRequestContrastEnhancementArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleJobRequestContrastEnhancementArgs = {
  filter?: Maybe<PipelineModuleJobRequestContrastEnhancementFilter>;
  order?: Maybe<PipelineModuleJobRequestContrastEnhancementOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleJobRequestContrastEnhancementArgs = {
  filter?: Maybe<PipelineModuleJobRequestContrastEnhancementFilter>;
};


export type QueryGetGpuArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryGpuArgs = {
  filter?: Maybe<GpuFilter>;
  order?: Maybe<GpuOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateGpuArgs = {
  filter?: Maybe<GpuFilter>;
};


export type QueryGetDenkboxArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryDenkboxArgs = {
  filter?: Maybe<DenkboxFilter>;
  order?: Maybe<DenkboxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateDenkboxArgs = {
  filter?: Maybe<DenkboxFilter>;
};


export type QueryGetDenkjobArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryDenkjobArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateDenkjobArgs = {
  filter?: Maybe<DenkjobFilter>;
};


export type QueryGetMetricArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryMetricArgs = {
  filter?: Maybe<MetricFilter>;
  order?: Maybe<MetricOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateMetricArgs = {
  filter?: Maybe<MetricFilter>;
};


export type QueryGetBinaryArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryBinaryArgs = {
  filter?: Maybe<BinaryFilter>;
  order?: Maybe<BinaryOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateBinaryArgs = {
  filter?: Maybe<BinaryFilter>;
};


export type QueryGetEncryptedBinaryArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryEncryptedBinaryArgs = {
  filter?: Maybe<EncryptedBinaryFilter>;
  order?: Maybe<EncryptedBinaryOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateEncryptedBinaryArgs = {
  filter?: Maybe<EncryptedBinaryFilter>;
};


export type QueryGetNetworkSnapshotArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryNetworkSnapshotArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
  order?: Maybe<NetworkSnapshotOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateNetworkSnapshotArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
};


export type QueryGetNetworkConfigArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryNetworkConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
  order?: Maybe<NetworkConfigOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateNetworkConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
};


export type QueryGetNetworkModuleArgs = {
  networkModuleId: Scalars['String'];
};


export type QueryQueryNetworkModuleArgs = {
  filter?: Maybe<NetworkModuleFilter>;
  order?: Maybe<NetworkModuleOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregateNetworkModuleArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};


export type QueryGetPipelineModuleNetworkImageSegmentationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleNetworkImageSegmentationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageSegmentationFilter>;
  order?: Maybe<PipelineModuleNetworkImageSegmentationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleNetworkImageSegmentationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageSegmentationFilter>;
};


export type QueryGetPipelineModuleNetworkImageClassificationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleNetworkImageClassificationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageClassificationFilter>;
  order?: Maybe<PipelineModuleNetworkImageClassificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleNetworkImageClassificationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageClassificationFilter>;
};


export type QueryGetPipelineModuleNetworkImageObjectDetectionArgs = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleNetworkImageObjectDetectionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageObjectDetectionFilter>;
  order?: Maybe<PipelineModuleNetworkImageObjectDetectionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleNetworkImageObjectDetectionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageObjectDetectionFilter>;
};


export type QueryGetPipelineModuleNetworkImageInstanceSegmentationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleNetworkImageInstanceSegmentationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageInstanceSegmentationFilter>;
  order?: Maybe<PipelineModuleNetworkImageInstanceSegmentationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleNetworkImageInstanceSegmentationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageInstanceSegmentationFilter>;
};


export type QueryGetPipelineModuleNetworkImageSuperResolutionArgs = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleNetworkImageSuperResolutionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageSuperResolutionFilter>;
  order?: Maybe<PipelineModuleNetworkImageSuperResolutionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleNetworkImageSuperResolutionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageSuperResolutionFilter>;
};


export type QueryGetPipelineModuleNetworkImageOcrArgs = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleNetworkImageOcrArgs = {
  filter?: Maybe<PipelineModuleNetworkImageOcrFilter>;
  order?: Maybe<PipelineModuleNetworkImageOcrOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleNetworkImageOcrArgs = {
  filter?: Maybe<PipelineModuleNetworkImageOcrFilter>;
};


export type QueryGetPipelineModuleNetworkImageAnomalyDetectionArgs = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleNetworkImageAnomalyDetectionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageAnomalyDetectionFilter>;
  order?: Maybe<PipelineModuleNetworkImageAnomalyDetectionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleNetworkImageAnomalyDetectionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageAnomalyDetectionFilter>;
};


export type QueryGetPipelineModuleNetworkImageObjectCountingArgs = {
  rootID?: Maybe<Scalars['ID']>;
  networkModuleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPipelineModuleNetworkImageObjectCountingArgs = {
  filter?: Maybe<PipelineModuleNetworkImageObjectCountingFilter>;
  order?: Maybe<PipelineModuleNetworkImageObjectCountingOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePipelineModuleNetworkImageObjectCountingArgs = {
  filter?: Maybe<PipelineModuleNetworkImageObjectCountingFilter>;
};


export type QueryGetPretrainedNetworkArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryQueryPretrainedNetworkArgs = {
  filter?: Maybe<PretrainedNetworkFilter>;
  order?: Maybe<PretrainedNetworkOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAggregatePretrainedNetworkArgs = {
  filter?: Maybe<PretrainedNetworkFilter>;
};

export type QueryBuildInfoPayload = {
  __typename?: 'QueryBuildInfoPayload';
  gitCommitHash: Scalars['String'];
  versionTag: Scalars['String'];
  buildDate: Scalars['String'];
};

export type QueryFilteredFoldersAndResearchBoxesInput = {
  datasetId: Scalars['String'];
  folderIds: Array<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  moduleIds?: Maybe<Array<Scalars['String']>>;
  labels?: Maybe<LabelFilterInput>;
  excludeFolders?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<QueryFilteredFoldersAndResearchBoxesSortInput>;
};

export type QueryFilteredFoldersAndResearchBoxesPayload = {
  __typename?: 'QueryFilteredFoldersAndResearchBoxesPayload';
  foldersAndResearchBoxIds: Array<Scalars['String']>;
  totalCount: Scalars['Int'];
};

export enum QueryFilteredFoldersAndResearchBoxesSortField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Type = 'TYPE'
}

export type QueryFilteredFoldersAndResearchBoxesSortInput = {
  by: QueryFilteredFoldersAndResearchBoxesSortField;
  method: QueryFilteredFoldersAndResearchBoxesSortMethod;
};

export enum QueryFilteredFoldersAndResearchBoxesSortMethod {
  Asc = 'ASC',
  Dsc = 'DSC'
}

export type ReadOnly = {
  _placeholder?: Maybe<Scalars['String']>;
};

export type ReadOnlyAggregateResult = {
  __typename?: 'ReadOnlyAggregateResult';
  count?: Maybe<Scalars['Int']>;
  _placeholderMin?: Maybe<Scalars['String']>;
  _placeholderMax?: Maybe<Scalars['String']>;
};

export type ReadOnlyFilter = {
  has?: Maybe<Array<Maybe<ReadOnlyHasFilter>>>;
  and?: Maybe<Array<Maybe<ReadOnlyFilter>>>;
  or?: Maybe<Array<Maybe<ReadOnlyFilter>>>;
  not?: Maybe<ReadOnlyFilter>;
};

export enum ReadOnlyHasFilter {
  Placeholder = '_placeholder'
}

export type ReadOnlyOrder = {
  asc?: Maybe<ReadOnlyOrderable>;
  desc?: Maybe<ReadOnlyOrderable>;
  then?: Maybe<ReadOnlyOrder>;
};

export enum ReadOnlyOrderable {
  Placeholder = '_placeholder'
}

export type ReadOnlyPatch = {
  _placeholder?: Maybe<Scalars['String']>;
};

export type RectangleAnnotation = SegmentationImageAnnotation & {
  __typename?: 'RectangleAnnotation';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabel;
  annotationType: AnnotationType;
  markup: SegmentationMarkup;
  topLeft: Point2D;
  bottomRight: Point2D;
};


export type RectangleAnnotationLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type RectangleAnnotationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
};


export type RectangleAnnotationTopLeftArgs = {
  filter?: Maybe<Point2DFilter>;
};


export type RectangleAnnotationBottomRightArgs = {
  filter?: Maybe<Point2DFilter>;
};

export type RectangleAnnotationAggregateResult = {
  __typename?: 'RectangleAnnotationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
};

export type RectangleAnnotationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<RectangleAnnotationHasFilter>>>;
  and?: Maybe<Array<Maybe<RectangleAnnotationFilter>>>;
  or?: Maybe<Array<Maybe<RectangleAnnotationFilter>>>;
  not?: Maybe<RectangleAnnotationFilter>;
};

export enum RectangleAnnotationHasFilter {
  Id = 'id',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Label = 'label',
  AnnotationType = 'annotationType',
  Markup = 'markup',
  TopLeft = 'topLeft',
  BottomRight = 'bottomRight'
}

export type RectangleAnnotationOrder = {
  asc?: Maybe<RectangleAnnotationOrderable>;
  desc?: Maybe<RectangleAnnotationOrderable>;
  then?: Maybe<RectangleAnnotationOrder>;
};

export enum RectangleAnnotationOrderable {
  Id = 'id',
  Owner = 'owner'
}

export type RectangleAnnotationPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
};

export type RectangleAnnotationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
};

export type ResearchBox = FolderOrResearchBox & {
  __typename?: 'ResearchBox';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  typeForSorting: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<Folder>;
  dataset?: Maybe<Dataset>;
  parentIds: Scalars['String'];
  datapoints?: Maybe<Array<Datapoint>>;
  containsMarkupForNetworkId: Array<Scalars['String']>;
  containsPositiveMarkupForLabelId: Array<Scalars['String']>;
  containsNegativeMarkupForLabelId: Array<Scalars['String']>;
  productionTags: Array<ProductionTag>;
  datapointsAggregate?: Maybe<DatapointAggregateResult>;
  productionTagsAggregate?: Maybe<ProductionTagAggregateResult>;
};


export type ResearchBoxParentArgs = {
  filter?: Maybe<FolderFilter>;
};


export type ResearchBoxDatasetArgs = {
  filter?: Maybe<DatasetFilter>;
};


export type ResearchBoxDatapointsArgs = {
  filter?: Maybe<DatapointFilter>;
  order?: Maybe<DatapointOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ResearchBoxProductionTagsArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ResearchBoxDatapointsAggregateArgs = {
  filter?: Maybe<DatapointFilter>;
};


export type ResearchBoxProductionTagsAggregateArgs = {
  filter?: Maybe<ProductionTagFilter>;
};

export type ResearchBoxAggregateResult = {
  __typename?: 'ResearchBoxAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  typeForSortingMin?: Maybe<Scalars['String']>;
  typeForSortingMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  parentIdsMin?: Maybe<Scalars['String']>;
  parentIdsMax?: Maybe<Scalars['String']>;
};

export type ResearchBoxFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  typeForSorting?: Maybe<StringHashFilter>;
  name?: Maybe<StringHashFilter_StringRegExpFilter>;
  parentIds?: Maybe<StringHashFilter_StringRegExpFilter>;
  containsMarkupForNetworkId?: Maybe<StringHashFilter>;
  containsPositiveMarkupForLabelId?: Maybe<StringHashFilter>;
  containsNegativeMarkupForLabelId?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<ResearchBoxHasFilter>>>;
  and?: Maybe<Array<Maybe<ResearchBoxFilter>>>;
  or?: Maybe<Array<Maybe<ResearchBoxFilter>>>;
  not?: Maybe<ResearchBoxFilter>;
};

export enum ResearchBoxHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  TypeForSorting = 'typeForSorting',
  Name = 'name',
  Parent = 'parent',
  Dataset = 'dataset',
  ParentIds = 'parentIds',
  Datapoints = 'datapoints',
  ContainsMarkupForNetworkId = 'containsMarkupForNetworkId',
  ContainsPositiveMarkupForLabelId = 'containsPositiveMarkupForLabelId',
  ContainsNegativeMarkupForLabelId = 'containsNegativeMarkupForLabelId',
  ProductionTags = 'productionTags'
}

export type ResearchBoxOrder = {
  asc?: Maybe<ResearchBoxOrderable>;
  desc?: Maybe<ResearchBoxOrderable>;
  then?: Maybe<ResearchBoxOrder>;
};

export enum ResearchBoxOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  TypeForSorting = 'typeForSorting',
  Name = 'name',
  ParentIds = 'parentIds'
}

export type ResearchBoxPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  typeForSorting?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<FolderRef>;
  dataset?: Maybe<DatasetRef>;
  parentIds?: Maybe<Scalars['String']>;
  datapoints?: Maybe<Array<DatapointRef>>;
  containsMarkupForNetworkId?: Maybe<Array<Scalars['String']>>;
  containsPositiveMarkupForLabelId?: Maybe<Array<Scalars['String']>>;
  containsNegativeMarkupForLabelId?: Maybe<Array<Scalars['String']>>;
  productionTags?: Maybe<Array<ProductionTagRef>>;
};

export type ResearchBoxRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  typeForSorting?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<FolderRef>;
  dataset?: Maybe<DatasetRef>;
  parentIds?: Maybe<Scalars['String']>;
  datapoints?: Maybe<Array<DatapointRef>>;
  containsMarkupForNetworkId?: Maybe<Array<Scalars['String']>>;
  containsPositiveMarkupForLabelId?: Maybe<Array<Scalars['String']>>;
  containsNegativeMarkupForLabelId?: Maybe<Array<Scalars['String']>>;
  productionTags?: Maybe<Array<ProductionTagRef>>;
};

export enum Role {
  Unauthorized = 'UNAUTHORIZED',
  Tenant = 'TENANT',
  Vendor = 'VENDOR',
  Admin = 'ADMIN'
}

export type SausageAnnotation = SegmentationImageAnnotation & {
  __typename?: 'SausageAnnotation';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabel;
  annotationType: AnnotationType;
  markup: SegmentationMarkup;
  topLeft: Point2D;
  bottomRight: Point2D;
  points: Array<Point2D>;
  radius: Scalars['Int'];
  dataURL: Scalars['String'];
  pointsAggregate?: Maybe<Point2DAggregateResult>;
};


export type SausageAnnotationLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type SausageAnnotationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
};


export type SausageAnnotationTopLeftArgs = {
  filter?: Maybe<Point2DFilter>;
};


export type SausageAnnotationBottomRightArgs = {
  filter?: Maybe<Point2DFilter>;
};


export type SausageAnnotationPointsArgs = {
  filter?: Maybe<Point2DFilter>;
  order?: Maybe<Point2DOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SausageAnnotationPointsAggregateArgs = {
  filter?: Maybe<Point2DFilter>;
};

export type SausageAnnotationAggregateResult = {
  __typename?: 'SausageAnnotationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  radiusMin?: Maybe<Scalars['Int']>;
  radiusMax?: Maybe<Scalars['Int']>;
  radiusSum?: Maybe<Scalars['Int']>;
  radiusAvg?: Maybe<Scalars['Float']>;
  dataURLMin?: Maybe<Scalars['String']>;
  dataURLMax?: Maybe<Scalars['String']>;
};

export type SausageAnnotationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<SausageAnnotationHasFilter>>>;
  and?: Maybe<Array<Maybe<SausageAnnotationFilter>>>;
  or?: Maybe<Array<Maybe<SausageAnnotationFilter>>>;
  not?: Maybe<SausageAnnotationFilter>;
};

export enum SausageAnnotationHasFilter {
  Id = 'id',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Label = 'label',
  AnnotationType = 'annotationType',
  Markup = 'markup',
  TopLeft = 'topLeft',
  BottomRight = 'bottomRight',
  Points = 'points',
  Radius = 'radius',
  DataUrl = 'dataURL'
}

export type SausageAnnotationOrder = {
  asc?: Maybe<SausageAnnotationOrderable>;
  desc?: Maybe<SausageAnnotationOrderable>;
  then?: Maybe<SausageAnnotationOrder>;
};

export enum SausageAnnotationOrderable {
  Id = 'id',
  Owner = 'owner',
  Radius = 'radius',
  DataUrl = 'dataURL'
}

export type SausageAnnotationPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  points?: Maybe<Array<Point2DRef>>;
  radius?: Maybe<Scalars['Int']>;
  dataURL?: Maybe<Scalars['String']>;
};

export type SausageAnnotationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  points?: Maybe<Array<Point2DRef>>;
  radius?: Maybe<Scalars['Int']>;
  dataURL?: Maybe<Scalars['String']>;
};

export type ScalarAnnotation = Cuid & Ownable & {
  __typename?: 'ScalarAnnotation';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  label: MarkupLabel;
  value: Scalars['Float'];
};


export type ScalarAnnotationLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};

export type ScalarAnnotationAggregateResult = {
  __typename?: 'ScalarAnnotationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  valueMin?: Maybe<Scalars['Float']>;
  valueMax?: Maybe<Scalars['Float']>;
  valueSum?: Maybe<Scalars['Float']>;
  valueAvg?: Maybe<Scalars['Float']>;
};

export type ScalarAnnotationFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<ScalarAnnotationHasFilter>>>;
  and?: Maybe<Array<Maybe<ScalarAnnotationFilter>>>;
  or?: Maybe<Array<Maybe<ScalarAnnotationFilter>>>;
  not?: Maybe<ScalarAnnotationFilter>;
};

export enum ScalarAnnotationHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  Id = 'id',
  Label = 'label',
  Value = 'value'
}

export type ScalarAnnotationOrder = {
  asc?: Maybe<ScalarAnnotationOrderable>;
  desc?: Maybe<ScalarAnnotationOrderable>;
  then?: Maybe<ScalarAnnotationOrder>;
};

export enum ScalarAnnotationOrderable {
  Owner = 'owner',
  Id = 'id',
  Value = 'value'
}

export type ScalarAnnotationPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  value?: Maybe<Scalars['Float']>;
};

export type ScalarAnnotationRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<MarkupLabelRef>;
  value?: Maybe<Scalars['Float']>;
};

export type ScalarPrediction = Cuid & Ownable & {
  __typename?: 'ScalarPrediction';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  label: MarkupLabel;
  probability: Scalars['Float'];
};


export type ScalarPredictionLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};

export type ScalarPredictionAggregateResult = {
  __typename?: 'ScalarPredictionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  probabilityMin?: Maybe<Scalars['Float']>;
  probabilityMax?: Maybe<Scalars['Float']>;
  probabilitySum?: Maybe<Scalars['Float']>;
  probabilityAvg?: Maybe<Scalars['Float']>;
};

export type ScalarPredictionFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<ScalarPredictionHasFilter>>>;
  and?: Maybe<Array<Maybe<ScalarPredictionFilter>>>;
  or?: Maybe<Array<Maybe<ScalarPredictionFilter>>>;
  not?: Maybe<ScalarPredictionFilter>;
};

export enum ScalarPredictionHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  Id = 'id',
  Label = 'label',
  Probability = 'probability'
}

export type ScalarPredictionOrder = {
  asc?: Maybe<ScalarPredictionOrderable>;
  desc?: Maybe<ScalarPredictionOrderable>;
  then?: Maybe<ScalarPredictionOrder>;
};

export enum ScalarPredictionOrderable {
  Owner = 'owner',
  Id = 'id',
  Probability = 'probability'
}

export type ScalarPredictionPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  probability?: Maybe<Scalars['Float']>;
};

export type ScalarPredictionRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<MarkupLabelRef>;
  probability?: Maybe<Scalars['Float']>;
};

export type SegmentationImageAnnotation = {
  id: Scalars['String'];
  rootID: Scalars['ID'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  label: MarkupLabel;
  annotationType: AnnotationType;
  markup: SegmentationMarkup;
};


export type SegmentationImageAnnotationLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type SegmentationImageAnnotationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
};

export type SegmentationImageAnnotationAggregateResult = {
  __typename?: 'SegmentationImageAnnotationAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
};

export type SegmentationImageAnnotationFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  has?: Maybe<Array<Maybe<SegmentationImageAnnotationHasFilter>>>;
  and?: Maybe<Array<Maybe<SegmentationImageAnnotationFilter>>>;
  or?: Maybe<Array<Maybe<SegmentationImageAnnotationFilter>>>;
  not?: Maybe<SegmentationImageAnnotationFilter>;
};

export enum SegmentationImageAnnotationHasFilter {
  Id = 'id',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Label = 'label',
  AnnotationType = 'annotationType',
  Markup = 'markup'
}

export type SegmentationImageAnnotationOrder = {
  asc?: Maybe<SegmentationImageAnnotationOrderable>;
  desc?: Maybe<SegmentationImageAnnotationOrderable>;
  then?: Maybe<SegmentationImageAnnotationOrder>;
};

export enum SegmentationImageAnnotationOrderable {
  Id = 'id',
  Owner = 'owner'
}

export type SegmentationImageAnnotationPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  label?: Maybe<MarkupLabelRef>;
  annotationType?: Maybe<AnnotationType>;
  markup?: Maybe<SegmentationMarkupRef>;
};

export type SegmentationImageAnnotationRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export type SegmentationMap2D = Cuid & Ownable & {
  __typename?: 'SegmentationMap2D';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  rootID: Scalars['ID'];
  binaryURL: Scalars['String'];
  thumbnail: Scalars['String'];
  label: MarkupLabel;
};


export type SegmentationMap2DLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};

export type SegmentationMap2DAggregateResult = {
  __typename?: 'SegmentationMap2DAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  thumbnailMin?: Maybe<Scalars['String']>;
  thumbnailMax?: Maybe<Scalars['String']>;
};

export type SegmentationMap2DFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<SegmentationMap2DHasFilter>>>;
  and?: Maybe<Array<Maybe<SegmentationMap2DFilter>>>;
  or?: Maybe<Array<Maybe<SegmentationMap2DFilter>>>;
  not?: Maybe<SegmentationMap2DFilter>;
};

export enum SegmentationMap2DHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  Id = 'id',
  Thumbnail = 'thumbnail',
  Label = 'label'
}

export type SegmentationMap2DOrder = {
  asc?: Maybe<SegmentationMap2DOrderable>;
  desc?: Maybe<SegmentationMap2DOrderable>;
  then?: Maybe<SegmentationMap2DOrder>;
};

export enum SegmentationMap2DOrderable {
  Owner = 'owner',
  Id = 'id',
  Thumbnail = 'thumbnail'
}

export type SegmentationMap2DPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  thumbnail?: Maybe<Scalars['String']>;
  label?: Maybe<MarkupLabelRef>;
};

export type SegmentationMap2DRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  label?: Maybe<MarkupLabelRef>;
};

export type SegmentationMarkup = ImageMarkup & {
  __typename?: 'SegmentationMarkup';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<Image>;
  subset: SubsetType;
  network: NetworkModule;
  networkId: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  annotations: Array<SegmentationImageAnnotation>;
  segmentationMaps: Array<SegmentationMap2D>;
  imagesAggregate?: Maybe<ImageAggregateResult>;
  annotationsAggregate?: Maybe<SegmentationImageAnnotationAggregateResult>;
  segmentationMapsAggregate?: Maybe<SegmentationMap2DAggregateResult>;
};


export type SegmentationMarkupImagesArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SegmentationMarkupNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};


export type SegmentationMarkupAnnotationsArgs = {
  filter?: Maybe<SegmentationImageAnnotationFilter>;
  order?: Maybe<SegmentationImageAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SegmentationMarkupSegmentationMapsArgs = {
  filter?: Maybe<SegmentationMap2DFilter>;
  order?: Maybe<SegmentationMap2DOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SegmentationMarkupImagesAggregateArgs = {
  filter?: Maybe<ImageFilter>;
};


export type SegmentationMarkupAnnotationsAggregateArgs = {
  filter?: Maybe<SegmentationImageAnnotationFilter>;
};


export type SegmentationMarkupSegmentationMapsAggregateArgs = {
  filter?: Maybe<SegmentationMap2DFilter>;
};

export type SegmentationMarkupAggregateResult = {
  __typename?: 'SegmentationMarkupAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  networkIdMin?: Maybe<Scalars['String']>;
  networkIdMax?: Maybe<Scalars['String']>;
  widthMin?: Maybe<Scalars['Int']>;
  widthMax?: Maybe<Scalars['Int']>;
  widthSum?: Maybe<Scalars['Int']>;
  widthAvg?: Maybe<Scalars['Float']>;
  heightMin?: Maybe<Scalars['Int']>;
  heightMax?: Maybe<Scalars['Int']>;
  heightSum?: Maybe<Scalars['Int']>;
  heightAvg?: Maybe<Scalars['Float']>;
};

export type SegmentationMarkupFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  subset?: Maybe<SubsetType_Hash>;
  networkId?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<SegmentationMarkupHasFilter>>>;
  and?: Maybe<Array<Maybe<SegmentationMarkupFilter>>>;
  or?: Maybe<Array<Maybe<SegmentationMarkupFilter>>>;
  not?: Maybe<SegmentationMarkupFilter>;
};

export enum SegmentationMarkupHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Images = 'images',
  Subset = 'subset',
  Network = 'network',
  NetworkId = 'networkId',
  Width = 'width',
  Height = 'height',
  Annotations = 'annotations',
  SegmentationMaps = 'segmentationMaps'
}

export type SegmentationMarkupOrder = {
  asc?: Maybe<SegmentationMarkupOrderable>;
  desc?: Maybe<SegmentationMarkupOrderable>;
  then?: Maybe<SegmentationMarkupOrder>;
};

export enum SegmentationMarkupOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  NetworkId = 'networkId',
  Width = 'width',
  Height = 'height'
}

export type SegmentationMarkupPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  subset?: Maybe<SubsetType>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  annotations?: Maybe<Array<SegmentationImageAnnotationRef>>;
  segmentationMaps?: Maybe<Array<SegmentationMap2DRef>>;
};

export type SegmentationMarkupRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  subset?: Maybe<SubsetType>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  annotations?: Maybe<Array<SegmentationImageAnnotationRef>>;
  segmentationMaps?: Maybe<Array<SegmentationMap2DRef>>;
};

export type SegmentationPrediction = ImagePrediction & {
  __typename?: 'SegmentationPrediction';
  id: Scalars['String'];
  rootID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  images: Array<Image>;
  network: NetworkModule;
  networkId: Scalars['String'];
  objects: Array<SegmentationPredictionObject>;
  segmentationMaps: Array<SegmentationMap2D>;
  imagesAggregate?: Maybe<ImageAggregateResult>;
  objectsAggregate?: Maybe<SegmentationPredictionObjectAggregateResult>;
  segmentationMapsAggregate?: Maybe<SegmentationMap2DAggregateResult>;
};


export type SegmentationPredictionImagesArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SegmentationPredictionNetworkArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};


export type SegmentationPredictionObjectsArgs = {
  filter?: Maybe<SegmentationPredictionObjectFilter>;
  order?: Maybe<SegmentationPredictionObjectOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SegmentationPredictionSegmentationMapsArgs = {
  filter?: Maybe<SegmentationMap2DFilter>;
  order?: Maybe<SegmentationMap2DOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SegmentationPredictionImagesAggregateArgs = {
  filter?: Maybe<ImageFilter>;
};


export type SegmentationPredictionObjectsAggregateArgs = {
  filter?: Maybe<SegmentationPredictionObjectFilter>;
};


export type SegmentationPredictionSegmentationMapsAggregateArgs = {
  filter?: Maybe<SegmentationMap2DFilter>;
};

export type SegmentationPredictionAggregateResult = {
  __typename?: 'SegmentationPredictionAggregateResult';
  count?: Maybe<Scalars['Int']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  networkIdMin?: Maybe<Scalars['String']>;
  networkIdMax?: Maybe<Scalars['String']>;
};

export type SegmentationPredictionFilter = {
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  networkId?: Maybe<StringHashFilter>;
  has?: Maybe<Array<Maybe<SegmentationPredictionHasFilter>>>;
  and?: Maybe<Array<Maybe<SegmentationPredictionFilter>>>;
  or?: Maybe<Array<Maybe<SegmentationPredictionFilter>>>;
  not?: Maybe<SegmentationPredictionFilter>;
};

export enum SegmentationPredictionHasFilter {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  IsPublic = 'isPublic',
  Images = 'images',
  Network = 'network',
  NetworkId = 'networkId',
  Objects = 'objects',
  SegmentationMaps = 'segmentationMaps'
}

export type SegmentationPredictionObject = Cuid & Timestamped & Ownable & {
  __typename?: 'SegmentationPredictionObject';
  owner: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['String'];
  rootID: Scalars['ID'];
  label: MarkupLabel;
  probability: Scalars['Float'];
  topLeft: Point2D;
  bottomRight: Point2D;
  dataURL: Scalars['String'];
};


export type SegmentationPredictionObjectLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
};


export type SegmentationPredictionObjectTopLeftArgs = {
  filter?: Maybe<Point2DFilter>;
};


export type SegmentationPredictionObjectBottomRightArgs = {
  filter?: Maybe<Point2DFilter>;
};

export type SegmentationPredictionObjectAggregateResult = {
  __typename?: 'SegmentationPredictionObjectAggregateResult';
  count?: Maybe<Scalars['Int']>;
  ownerMin?: Maybe<Scalars['String']>;
  ownerMax?: Maybe<Scalars['String']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  idMin?: Maybe<Scalars['String']>;
  idMax?: Maybe<Scalars['String']>;
  probabilityMin?: Maybe<Scalars['Float']>;
  probabilityMax?: Maybe<Scalars['Float']>;
  probabilitySum?: Maybe<Scalars['Float']>;
  probabilityAvg?: Maybe<Scalars['Float']>;
  dataURLMin?: Maybe<Scalars['String']>;
  dataURLMax?: Maybe<Scalars['String']>;
};

export type SegmentationPredictionObjectFilter = {
  owner?: Maybe<StringHashFilter>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringHashFilter>;
  rootID?: Maybe<Array<Scalars['ID']>>;
  has?: Maybe<Array<Maybe<SegmentationPredictionObjectHasFilter>>>;
  and?: Maybe<Array<Maybe<SegmentationPredictionObjectFilter>>>;
  or?: Maybe<Array<Maybe<SegmentationPredictionObjectFilter>>>;
  not?: Maybe<SegmentationPredictionObjectFilter>;
};

export enum SegmentationPredictionObjectHasFilter {
  Owner = 'owner',
  IsPublic = 'isPublic',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Label = 'label',
  Probability = 'probability',
  TopLeft = 'topLeft',
  BottomRight = 'bottomRight',
  DataUrl = 'dataURL'
}

export type SegmentationPredictionObjectOrder = {
  asc?: Maybe<SegmentationPredictionObjectOrderable>;
  desc?: Maybe<SegmentationPredictionObjectOrderable>;
  then?: Maybe<SegmentationPredictionObjectOrder>;
};

export enum SegmentationPredictionObjectOrderable {
  Owner = 'owner',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Id = 'id',
  Probability = 'probability',
  DataUrl = 'dataURL'
}

export type SegmentationPredictionObjectPatch = {
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<MarkupLabelRef>;
  probability?: Maybe<Scalars['Float']>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  dataURL?: Maybe<Scalars['String']>;
};

export type SegmentationPredictionObjectRef = {
  rootID?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<MarkupLabelRef>;
  probability?: Maybe<Scalars['Float']>;
  topLeft?: Maybe<Point2DRef>;
  bottomRight?: Maybe<Point2DRef>;
  dataURL?: Maybe<Scalars['String']>;
};

export type SegmentationPredictionOrder = {
  asc?: Maybe<SegmentationPredictionOrderable>;
  desc?: Maybe<SegmentationPredictionOrderable>;
  then?: Maybe<SegmentationPredictionOrder>;
};

export enum SegmentationPredictionOrderable {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Owner = 'owner',
  NetworkId = 'networkId'
}

export type SegmentationPredictionPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  objects?: Maybe<Array<SegmentationPredictionObjectRef>>;
  segmentationMaps?: Maybe<Array<SegmentationMap2DRef>>;
};

export type SegmentationPredictionRef = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageRef>>;
  network?: Maybe<NetworkModuleRef>;
  networkId?: Maybe<Scalars['String']>;
  objects?: Maybe<Array<SegmentationPredictionObjectRef>>;
  segmentationMaps?: Maybe<Array<SegmentationMap2DRef>>;
};

export type StringExactFilter = {
  eq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  between?: Maybe<StringRange>;
};

export type StringFullTextFilter = {
  alloftext?: Maybe<Scalars['String']>;
  anyoftext?: Maybe<Scalars['String']>;
};

export type StringFullTextFilter_StringHashFilter_StringRegExpFilter = {
  alloftext?: Maybe<Scalars['String']>;
  anyoftext?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  regexp?: Maybe<Scalars['String']>;
};

export type StringFullTextFilter_StringRegExpFilter = {
  alloftext?: Maybe<Scalars['String']>;
  anyoftext?: Maybe<Scalars['String']>;
  regexp?: Maybe<Scalars['String']>;
};

export type StringHashFilter = {
  eq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StringHashFilter_StringRegExpFilter = {
  eq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  regexp?: Maybe<Scalars['String']>;
};

export type StringRange = {
  min: Scalars['String'];
  max: Scalars['String'];
};

export type StringRegExpFilter = {
  regexp?: Maybe<Scalars['String']>;
};

export type StringTermFilter = {
  allofterms?: Maybe<Scalars['String']>;
  anyofterms?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getNotification?: Maybe<Notification>;
  queryNotification?: Maybe<Array<Maybe<Notification>>>;
  aggregateNotification?: Maybe<NotificationAggregateResult>;
  getUser?: Maybe<User>;
  queryUser?: Maybe<Array<Maybe<User>>>;
  aggregateUser?: Maybe<UserAggregateResult>;
  getDataset?: Maybe<Dataset>;
  queryDataset?: Maybe<Array<Maybe<Dataset>>>;
  aggregateDataset?: Maybe<DatasetAggregateResult>;
  getFolder?: Maybe<Folder>;
  queryFolder?: Maybe<Array<Maybe<Folder>>>;
  aggregateFolder?: Maybe<FolderAggregateResult>;
  getResearchBox?: Maybe<ResearchBox>;
  queryResearchBox?: Maybe<Array<Maybe<ResearchBox>>>;
  aggregateResearchBox?: Maybe<ResearchBoxAggregateResult>;
  getProductionTag?: Maybe<ProductionTag>;
  queryProductionTag?: Maybe<Array<Maybe<ProductionTag>>>;
  aggregateProductionTag?: Maybe<ProductionTagAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getDatapoint?: Maybe<Datapoint>;
  queryDatapoint?: Maybe<Array<Maybe<Datapoint>>>;
  aggregateDatapoint?: Maybe<DatapointAggregateResult>;
  getImage?: Maybe<Image>;
  queryImage?: Maybe<Array<Maybe<Image>>>;
  aggregateImage?: Maybe<ImageAggregateResult>;
  getMagneticFieldScan?: Maybe<MagneticFieldScan>;
  queryMagneticFieldScan?: Maybe<Array<Maybe<MagneticFieldScan>>>;
  aggregateMagneticFieldScan?: Maybe<MagneticFieldScanAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getSegmentationImageAnnotation?: Maybe<SegmentationImageAnnotation>;
  querySegmentationImageAnnotation?: Maybe<Array<Maybe<SegmentationImageAnnotation>>>;
  aggregateSegmentationImageAnnotation?: Maybe<SegmentationImageAnnotationAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getImageMarkup?: Maybe<ImageMarkup>;
  queryImageMarkup?: Maybe<Array<Maybe<ImageMarkup>>>;
  aggregateImageMarkup?: Maybe<ImageMarkupAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getImagePrediction?: Maybe<ImagePrediction>;
  queryImagePrediction?: Maybe<Array<Maybe<ImagePrediction>>>;
  aggregateImagePrediction?: Maybe<ImagePredictionAggregateResult>;
  getSegmentationMarkup?: Maybe<SegmentationMarkup>;
  querySegmentationMarkup?: Maybe<Array<Maybe<SegmentationMarkup>>>;
  aggregateSegmentationMarkup?: Maybe<SegmentationMarkupAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getModuleJobRequest?: Maybe<ModuleJobRequest>;
  queryModuleJobRequest?: Maybe<Array<Maybe<ModuleJobRequest>>>;
  aggregateModuleJobRequest?: Maybe<ModuleJobRequestAggregateResult>;
  getPipeline?: Maybe<Pipeline>;
  queryPipeline?: Maybe<Array<Maybe<Pipeline>>>;
  aggregatePipeline?: Maybe<PipelineAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getPipelineModuleOutput?: Maybe<PipelineModuleOutput>;
  queryPipelineModuleOutput?: Maybe<Array<Maybe<PipelineModuleOutput>>>;
  aggregatePipelineModuleOutput?: Maybe<PipelineModuleOutputAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getPipelineModule?: Maybe<PipelineModule>;
  queryPipelineModule?: Maybe<Array<Maybe<PipelineModule>>>;
  aggregatePipelineModule?: Maybe<PipelineModuleAggregateResult>;
  getPipelineModuleUpload?: Maybe<PipelineModuleUpload>;
  queryPipelineModuleUpload?: Maybe<Array<Maybe<PipelineModuleUpload>>>;
  aggregatePipelineModuleUpload?: Maybe<PipelineModuleUploadAggregateResult>;
  getPipelineModuleCCChecker?: Maybe<PipelineModuleCcChecker>;
  queryPipelineModuleCCChecker?: Maybe<Array<Maybe<PipelineModuleCcChecker>>>;
  aggregatePipelineModuleCCChecker?: Maybe<PipelineModuleCcCheckerAggregateResult>;
  getPipelineModuleContrastEnhancement?: Maybe<PipelineModuleContrastEnhancement>;
  queryPipelineModuleContrastEnhancement?: Maybe<Array<Maybe<PipelineModuleContrastEnhancement>>>;
  aggregatePipelineModuleContrastEnhancement?: Maybe<PipelineModuleContrastEnhancementAggregateResult>;
  getDenkbox?: Maybe<Denkbox>;
  queryDenkbox?: Maybe<Array<Maybe<Denkbox>>>;
  aggregateDenkbox?: Maybe<DenkboxAggregateResult>;
  getDenkjob?: Maybe<Denkjob>;
  queryDenkjob?: Maybe<Array<Maybe<Denkjob>>>;
  aggregateDenkjob?: Maybe<DenkjobAggregateResult>;
  /** @deprecated @id argument for get query on interface is being deprecated, it will be removed in v21.11.0, please update your query to not use that argument */
  getNetworkModule?: Maybe<NetworkModule>;
  queryNetworkModule?: Maybe<Array<Maybe<NetworkModule>>>;
  aggregateNetworkModule?: Maybe<NetworkModuleAggregateResult>;
};


export type SubscriptionGetNotificationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryNotificationArgs = {
  filter?: Maybe<NotificationFilter>;
  order?: Maybe<NotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateNotificationArgs = {
  filter?: Maybe<NotificationFilter>;
};


export type SubscriptionGetUserArgs = {
  username: Scalars['String'];
};


export type SubscriptionQueryUserArgs = {
  filter?: Maybe<UserFilter>;
  order?: Maybe<UserOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateUserArgs = {
  filter?: Maybe<UserFilter>;
};


export type SubscriptionGetDatasetArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryDatasetArgs = {
  filter?: Maybe<DatasetFilter>;
  order?: Maybe<DatasetOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateDatasetArgs = {
  filter?: Maybe<DatasetFilter>;
};


export type SubscriptionGetFolderArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryFolderArgs = {
  filter?: Maybe<FolderFilter>;
  order?: Maybe<FolderOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateFolderArgs = {
  filter?: Maybe<FolderFilter>;
};


export type SubscriptionGetResearchBoxArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryResearchBoxArgs = {
  filter?: Maybe<ResearchBoxFilter>;
  order?: Maybe<ResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateResearchBoxArgs = {
  filter?: Maybe<ResearchBoxFilter>;
};


export type SubscriptionGetProductionTagArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryProductionTagArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateProductionTagArgs = {
  filter?: Maybe<ProductionTagFilter>;
};


export type SubscriptionGetDatapointArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryDatapointArgs = {
  filter?: Maybe<DatapointFilter>;
  order?: Maybe<DatapointOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateDatapointArgs = {
  filter?: Maybe<DatapointFilter>;
};


export type SubscriptionGetImageArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryImageArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateImageArgs = {
  filter?: Maybe<ImageFilter>;
};


export type SubscriptionGetMagneticFieldScanArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryMagneticFieldScanArgs = {
  filter?: Maybe<MagneticFieldScanFilter>;
  order?: Maybe<MagneticFieldScanOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateMagneticFieldScanArgs = {
  filter?: Maybe<MagneticFieldScanFilter>;
};


export type SubscriptionGetSegmentationImageAnnotationArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQuerySegmentationImageAnnotationArgs = {
  filter?: Maybe<SegmentationImageAnnotationFilter>;
  order?: Maybe<SegmentationImageAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateSegmentationImageAnnotationArgs = {
  filter?: Maybe<SegmentationImageAnnotationFilter>;
};


export type SubscriptionGetImageMarkupArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryImageMarkupArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateImageMarkupArgs = {
  filter?: Maybe<ImageMarkupFilter>;
};


export type SubscriptionGetImagePredictionArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryImagePredictionArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateImagePredictionArgs = {
  filter?: Maybe<ImagePredictionFilter>;
};


export type SubscriptionGetSegmentationMarkupArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQuerySegmentationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
  order?: Maybe<SegmentationMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateSegmentationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
};


export type SubscriptionGetModuleJobRequestArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryModuleJobRequestArgs = {
  filter?: Maybe<ModuleJobRequestFilter>;
  order?: Maybe<ModuleJobRequestOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateModuleJobRequestArgs = {
  filter?: Maybe<ModuleJobRequestFilter>;
};


export type SubscriptionGetPipelineArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
  order?: Maybe<PipelineOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregatePipelineArgs = {
  filter?: Maybe<PipelineFilter>;
};


export type SubscriptionGetPipelineModuleOutputArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryPipelineModuleOutputArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregatePipelineModuleOutputArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
};


export type SubscriptionGetPipelineModuleArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryPipelineModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
  order?: Maybe<PipelineModuleOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregatePipelineModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
};


export type SubscriptionGetPipelineModuleUploadArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryPipelineModuleUploadArgs = {
  filter?: Maybe<PipelineModuleUploadFilter>;
  order?: Maybe<PipelineModuleUploadOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregatePipelineModuleUploadArgs = {
  filter?: Maybe<PipelineModuleUploadFilter>;
};


export type SubscriptionGetPipelineModuleCcCheckerArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryPipelineModuleCcCheckerArgs = {
  filter?: Maybe<PipelineModuleCcCheckerFilter>;
  order?: Maybe<PipelineModuleCcCheckerOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregatePipelineModuleCcCheckerArgs = {
  filter?: Maybe<PipelineModuleCcCheckerFilter>;
};


export type SubscriptionGetPipelineModuleContrastEnhancementArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryPipelineModuleContrastEnhancementArgs = {
  filter?: Maybe<PipelineModuleContrastEnhancementFilter>;
  order?: Maybe<PipelineModuleContrastEnhancementOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregatePipelineModuleContrastEnhancementArgs = {
  filter?: Maybe<PipelineModuleContrastEnhancementFilter>;
};


export type SubscriptionGetDenkboxArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryDenkboxArgs = {
  filter?: Maybe<DenkboxFilter>;
  order?: Maybe<DenkboxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateDenkboxArgs = {
  filter?: Maybe<DenkboxFilter>;
};


export type SubscriptionGetDenkjobArgs = {
  rootID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};


export type SubscriptionQueryDenkjobArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateDenkjobArgs = {
  filter?: Maybe<DenkjobFilter>;
};


export type SubscriptionGetNetworkModuleArgs = {
  networkModuleId: Scalars['String'];
};


export type SubscriptionQueryNetworkModuleArgs = {
  filter?: Maybe<NetworkModuleFilter>;
  order?: Maybe<NetworkModuleOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SubscriptionAggregateNetworkModuleArgs = {
  filter?: Maybe<NetworkModuleFilter>;
};

export enum SubsetType {
  Training = 'TRAINING',
  Validation = 'VALIDATION'
}

export type SubsetType_Hash = {
  eq?: Maybe<SubsetType>;
  in?: Maybe<Array<Maybe<SubsetType>>>;
};

export type Timestamped = {
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TimestampedAggregateResult = {
  __typename?: 'TimestampedAggregateResult';
  count?: Maybe<Scalars['Int']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
};

export type TimestampedFilter = {
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  has?: Maybe<Array<Maybe<TimestampedHasFilter>>>;
  and?: Maybe<Array<Maybe<TimestampedFilter>>>;
  or?: Maybe<Array<Maybe<TimestampedFilter>>>;
  not?: Maybe<TimestampedFilter>;
};

export enum TimestampedHasFilter {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type TimestampedOrder = {
  asc?: Maybe<TimestampedOrderable>;
  desc?: Maybe<TimestampedOrderable>;
  then?: Maybe<TimestampedOrder>;
};

export enum TimestampedOrderable {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type TimestampedPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TokenPayload = {
  __typename?: 'TokenPayload';
  token: Scalars['String'];
};

export enum UiFeature {
  NetworkMutate = 'NETWORK_MUTATE',
  NetworkTraining = 'NETWORK_TRAINING',
  NetworkDownload = 'NETWORK_DOWNLOAD',
  NetworkAdvanced = 'NETWORK_ADVANCED',
  MarkupMutate = 'MARKUP_MUTATE',
  MarkupPositiveNegativeIgnore = 'MARKUP_POSITIVE_NEGATIVE_IGNORE',
  UploadFiles = 'UPLOAD_FILES',
  ResearchboxMutate = 'RESEARCHBOX_MUTATE',
  FolderMutate = 'FOLDER_MUTATE',
  DatasetMutate = 'DATASET_MUTATE',
  FlowDesigner = 'FLOW_DESIGNER'
}

export type UpdateAppStateInput = {
  filter: AppStateFilter;
  set?: Maybe<AppStatePatch>;
  remove?: Maybe<AppStatePatch>;
};

export type UpdateAppStatePayload = {
  __typename?: 'UpdateAppStatePayload';
  appState?: Maybe<Array<Maybe<AppState>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateAppStatePayloadAppStateArgs = {
  filter?: Maybe<AppStateFilter>;
  order?: Maybe<AppStateOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateBinaryInput = {
  filter: BinaryFilter;
  set?: Maybe<BinaryPatch>;
  remove?: Maybe<BinaryPatch>;
};

export type UpdateBinaryPayload = {
  __typename?: 'UpdateBinaryPayload';
  binary?: Maybe<Array<Maybe<Binary>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateBinaryPayloadBinaryArgs = {
  filter?: Maybe<BinaryFilter>;
  order?: Maybe<BinaryOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateBoundingBoxAnnotationInput = {
  filter: BoundingBoxAnnotationFilter;
  set?: Maybe<BoundingBoxAnnotationPatch>;
  remove?: Maybe<BoundingBoxAnnotationPatch>;
};

export type UpdateBoundingBoxAnnotationPayload = {
  __typename?: 'UpdateBoundingBoxAnnotationPayload';
  boundingBoxAnnotation?: Maybe<Array<Maybe<BoundingBoxAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateBoundingBoxAnnotationPayloadBoundingBoxAnnotationArgs = {
  filter?: Maybe<BoundingBoxAnnotationFilter>;
  order?: Maybe<BoundingBoxAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateBoundingBoxPredictionInput = {
  filter: BoundingBoxPredictionFilter;
  set?: Maybe<BoundingBoxPredictionPatch>;
  remove?: Maybe<BoundingBoxPredictionPatch>;
};

export type UpdateBoundingBoxPredictionPayload = {
  __typename?: 'UpdateBoundingBoxPredictionPayload';
  boundingBoxPrediction?: Maybe<Array<Maybe<BoundingBoxPrediction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateBoundingBoxPredictionPayloadBoundingBoxPredictionArgs = {
  filter?: Maybe<BoundingBoxPredictionFilter>;
  order?: Maybe<BoundingBoxPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateCcCheckerResultInput = {
  filter: CcCheckerResultFilter;
  set?: Maybe<CcCheckerResultPatch>;
  remove?: Maybe<CcCheckerResultPatch>;
};

export type UpdateCcCheckerResultPayload = {
  __typename?: 'UpdateCCCheckerResultPayload';
  cCCheckerResult?: Maybe<Array<Maybe<CcCheckerResult>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateCcCheckerResultPayloadCcCheckerResultArgs = {
  filter?: Maybe<CcCheckerResultFilter>;
  order?: Maybe<CcCheckerResultOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateCircleAnnotationInput = {
  filter: CircleAnnotationFilter;
  set?: Maybe<CircleAnnotationPatch>;
  remove?: Maybe<CircleAnnotationPatch>;
};

export type UpdateCircleAnnotationPayload = {
  __typename?: 'UpdateCircleAnnotationPayload';
  circleAnnotation?: Maybe<Array<Maybe<CircleAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateCircleAnnotationPayloadCircleAnnotationArgs = {
  filter?: Maybe<CircleAnnotationFilter>;
  order?: Maybe<CircleAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateClassificationMarkupInput = {
  filter: ClassificationMarkupFilter;
  set?: Maybe<ClassificationMarkupPatch>;
  remove?: Maybe<ClassificationMarkupPatch>;
};

export type UpdateClassificationMarkupPayload = {
  __typename?: 'UpdateClassificationMarkupPayload';
  classificationMarkup?: Maybe<Array<Maybe<ClassificationMarkup>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateClassificationMarkupPayloadClassificationMarkupArgs = {
  filter?: Maybe<ClassificationMarkupFilter>;
  order?: Maybe<ClassificationMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateClassificationPredictionInput = {
  filter: ClassificationPredictionFilter;
  set?: Maybe<ClassificationPredictionPatch>;
  remove?: Maybe<ClassificationPredictionPatch>;
};

export type UpdateClassificationPredictionPayload = {
  __typename?: 'UpdateClassificationPredictionPayload';
  classificationPrediction?: Maybe<Array<Maybe<ClassificationPrediction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateClassificationPredictionPayloadClassificationPredictionArgs = {
  filter?: Maybe<ClassificationPredictionFilter>;
  order?: Maybe<ClassificationPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateDatapointInput = {
  filter: DatapointFilter;
  set?: Maybe<DatapointPatch>;
  remove?: Maybe<DatapointPatch>;
};

export type UpdateDatapointPayload = {
  __typename?: 'UpdateDatapointPayload';
  datapoint?: Maybe<Array<Maybe<Datapoint>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateDatapointPayloadDatapointArgs = {
  filter?: Maybe<DatapointFilter>;
  order?: Maybe<DatapointOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateDatasetInput = {
  filter: DatasetFilter;
  set?: Maybe<DatasetPatch>;
  remove?: Maybe<DatasetPatch>;
};

export type UpdateDatasetPayload = {
  __typename?: 'UpdateDatasetPayload';
  dataset?: Maybe<Array<Maybe<Dataset>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateDatasetPayloadDatasetArgs = {
  filter?: Maybe<DatasetFilter>;
  order?: Maybe<DatasetOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateDenkboxInput = {
  filter: DenkboxFilter;
  set?: Maybe<DenkboxPatch>;
  remove?: Maybe<DenkboxPatch>;
};

export type UpdateDenkboxPayload = {
  __typename?: 'UpdateDenkboxPayload';
  denkbox?: Maybe<Array<Maybe<Denkbox>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateDenkboxPayloadDenkboxArgs = {
  filter?: Maybe<DenkboxFilter>;
  order?: Maybe<DenkboxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateDenkjobInput = {
  filter: DenkjobFilter;
  set?: Maybe<DenkjobPatch>;
  remove?: Maybe<DenkjobPatch>;
};

export type UpdateDenkjobPayload = {
  __typename?: 'UpdateDenkjobPayload';
  denkjob?: Maybe<Array<Maybe<Denkjob>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateDenkjobPayloadDenkjobArgs = {
  filter?: Maybe<DenkjobFilter>;
  order?: Maybe<DenkjobOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateDenktesterNotificationInput = {
  filter: DenktesterNotificationFilter;
  set?: Maybe<DenktesterNotificationPatch>;
  remove?: Maybe<DenktesterNotificationPatch>;
};

export type UpdateDenktesterNotificationPayload = {
  __typename?: 'UpdateDenktesterNotificationPayload';
  denktesterNotification?: Maybe<Array<Maybe<DenktesterNotification>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateDenktesterNotificationPayloadDenktesterNotificationArgs = {
  filter?: Maybe<DenktesterNotificationFilter>;
  order?: Maybe<DenktesterNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateEncryptedBinaryInput = {
  filter: EncryptedBinaryFilter;
  set?: Maybe<EncryptedBinaryPatch>;
  remove?: Maybe<EncryptedBinaryPatch>;
};

export type UpdateEncryptedBinaryPayload = {
  __typename?: 'UpdateEncryptedBinaryPayload';
  encryptedBinary?: Maybe<Array<Maybe<EncryptedBinary>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateEncryptedBinaryPayloadEncryptedBinaryArgs = {
  filter?: Maybe<EncryptedBinaryFilter>;
  order?: Maybe<EncryptedBinaryOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateFolderInput = {
  filter: FolderFilter;
  set?: Maybe<FolderPatch>;
  remove?: Maybe<FolderPatch>;
};

export type UpdateFolderOrResearchBoxInput = {
  filter: FolderOrResearchBoxFilter;
  set?: Maybe<FolderOrResearchBoxPatch>;
  remove?: Maybe<FolderOrResearchBoxPatch>;
};

export type UpdateFolderOrResearchBoxPayload = {
  __typename?: 'UpdateFolderOrResearchBoxPayload';
  folderOrResearchBox?: Maybe<Array<Maybe<FolderOrResearchBox>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateFolderOrResearchBoxPayloadFolderOrResearchBoxArgs = {
  filter?: Maybe<FolderOrResearchBoxFilter>;
  order?: Maybe<FolderOrResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateFolderPayload = {
  __typename?: 'UpdateFolderPayload';
  folder?: Maybe<Array<Maybe<Folder>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateFolderPayloadFolderArgs = {
  filter?: Maybe<FolderFilter>;
  order?: Maybe<FolderOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateGpuInput = {
  filter: GpuFilter;
  set?: Maybe<GpuPatch>;
  remove?: Maybe<GpuPatch>;
};

export type UpdateGpuPayload = {
  __typename?: 'UpdateGpuPayload';
  gpu?: Maybe<Array<Maybe<Gpu>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateGpuPayloadGpuArgs = {
  filter?: Maybe<GpuFilter>;
  order?: Maybe<GpuOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateImageInput = {
  filter: ImageFilter;
  set?: Maybe<ImagePatch>;
  remove?: Maybe<ImagePatch>;
};

export type UpdateImageMarkupInput = {
  filter: ImageMarkupFilter;
  set?: Maybe<ImageMarkupPatch>;
  remove?: Maybe<ImageMarkupPatch>;
};

export type UpdateImageMarkupPayload = {
  __typename?: 'UpdateImageMarkupPayload';
  imageMarkup?: Maybe<Array<Maybe<ImageMarkup>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateImageMarkupPayloadImageMarkupArgs = {
  filter?: Maybe<ImageMarkupFilter>;
  order?: Maybe<ImageMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateImageMetadataFieldInput = {
  filter: ImageMetadataFieldFilter;
  set?: Maybe<ImageMetadataFieldPatch>;
  remove?: Maybe<ImageMetadataFieldPatch>;
};

export type UpdateImageMetadataFieldPayload = {
  __typename?: 'UpdateImageMetadataFieldPayload';
  imageMetadataField?: Maybe<Array<Maybe<ImageMetadataField>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateImageMetadataFieldPayloadImageMetadataFieldArgs = {
  filter?: Maybe<ImageMetadataFieldFilter>;
  order?: Maybe<ImageMetadataFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateImagePayload = {
  __typename?: 'UpdateImagePayload';
  image?: Maybe<Array<Maybe<Image>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateImagePayloadImageArgs = {
  filter?: Maybe<ImageFilter>;
  order?: Maybe<ImageOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateImagePredictionInput = {
  filter: ImagePredictionFilter;
  set?: Maybe<ImagePredictionPatch>;
  remove?: Maybe<ImagePredictionPatch>;
};

export type UpdateImagePredictionPayload = {
  __typename?: 'UpdateImagePredictionPayload';
  imagePrediction?: Maybe<Array<Maybe<ImagePrediction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateImagePredictionPayloadImagePredictionArgs = {
  filter?: Maybe<ImagePredictionFilter>;
  order?: Maybe<ImagePredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateImageResolutionInput = {
  filter: ImageResolutionFilter;
  set?: Maybe<ImageResolutionPatch>;
  remove?: Maybe<ImageResolutionPatch>;
};

export type UpdateImageResolutionPayload = {
  __typename?: 'UpdateImageResolutionPayload';
  imageResolution?: Maybe<Array<Maybe<ImageResolution>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateImageResolutionPayloadImageResolutionArgs = {
  filter?: Maybe<ImageResolutionFilter>;
  order?: Maybe<ImageResolutionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateInstanceSegmentationMarkupInput = {
  filter: InstanceSegmentationMarkupFilter;
  set?: Maybe<InstanceSegmentationMarkupPatch>;
  remove?: Maybe<InstanceSegmentationMarkupPatch>;
};

export type UpdateInstanceSegmentationMarkupPayload = {
  __typename?: 'UpdateInstanceSegmentationMarkupPayload';
  instanceSegmentationMarkup?: Maybe<Array<Maybe<InstanceSegmentationMarkup>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateInstanceSegmentationMarkupPayloadInstanceSegmentationMarkupArgs = {
  filter?: Maybe<InstanceSegmentationMarkupFilter>;
  order?: Maybe<InstanceSegmentationMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateInstanceSegmentationPredictionInput = {
  filter: InstanceSegmentationPredictionFilter;
  set?: Maybe<InstanceSegmentationPredictionPatch>;
  remove?: Maybe<InstanceSegmentationPredictionPatch>;
};

export type UpdateInstanceSegmentationPredictionPayload = {
  __typename?: 'UpdateInstanceSegmentationPredictionPayload';
  instanceSegmentationPrediction?: Maybe<Array<Maybe<InstanceSegmentationPrediction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateInstanceSegmentationPredictionPayloadInstanceSegmentationPredictionArgs = {
  filter?: Maybe<InstanceSegmentationPredictionFilter>;
  order?: Maybe<InstanceSegmentationPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateMagicwandAnnotationInput = {
  filter: MagicwandAnnotationFilter;
  set?: Maybe<MagicwandAnnotationPatch>;
  remove?: Maybe<MagicwandAnnotationPatch>;
};

export type UpdateMagicwandAnnotationPayload = {
  __typename?: 'UpdateMagicwandAnnotationPayload';
  magicwandAnnotation?: Maybe<Array<Maybe<MagicwandAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateMagicwandAnnotationPayloadMagicwandAnnotationArgs = {
  filter?: Maybe<MagicwandAnnotationFilter>;
  order?: Maybe<MagicwandAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateMagneticFieldScanInput = {
  filter: MagneticFieldScanFilter;
  set?: Maybe<MagneticFieldScanPatch>;
  remove?: Maybe<MagneticFieldScanPatch>;
};

export type UpdateMagneticFieldScanPayload = {
  __typename?: 'UpdateMagneticFieldScanPayload';
  magneticFieldScan?: Maybe<Array<Maybe<MagneticFieldScan>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateMagneticFieldScanPayloadMagneticFieldScanArgs = {
  filter?: Maybe<MagneticFieldScanFilter>;
  order?: Maybe<MagneticFieldScanOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateMaintenanceNotificationInput = {
  filter: MaintenanceNotificationFilter;
  set?: Maybe<MaintenanceNotificationPatch>;
  remove?: Maybe<MaintenanceNotificationPatch>;
};

export type UpdateMaintenanceNotificationPayload = {
  __typename?: 'UpdateMaintenanceNotificationPayload';
  maintenanceNotification?: Maybe<Array<Maybe<MaintenanceNotification>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateMaintenanceNotificationPayloadMaintenanceNotificationArgs = {
  filter?: Maybe<MaintenanceNotificationFilter>;
  order?: Maybe<MaintenanceNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateMarkupLabelInput = {
  filter: MarkupLabelFilter;
  set?: Maybe<MarkupLabelPatch>;
  remove?: Maybe<MarkupLabelPatch>;
};

export type UpdateMarkupLabelPayload = {
  __typename?: 'UpdateMarkupLabelPayload';
  markupLabel?: Maybe<Array<Maybe<MarkupLabel>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateMarkupLabelPayloadMarkupLabelArgs = {
  filter?: Maybe<MarkupLabelFilter>;
  order?: Maybe<MarkupLabelOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateMetricInput = {
  filter: MetricFilter;
  set?: Maybe<MetricPatch>;
  remove?: Maybe<MetricPatch>;
};

export type UpdateMetricPayload = {
  __typename?: 'UpdateMetricPayload';
  metric?: Maybe<Array<Maybe<Metric>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateMetricPayloadMetricArgs = {
  filter?: Maybe<MetricFilter>;
  order?: Maybe<MetricOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateMigrationInput = {
  filter: MigrationFilter;
  set?: Maybe<MigrationPatch>;
  remove?: Maybe<MigrationPatch>;
};

export type UpdateMigrationPayload = {
  __typename?: 'UpdateMigrationPayload';
  migration?: Maybe<Array<Maybe<Migration>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateMigrationPayloadMigrationArgs = {
  filter?: Maybe<MigrationFilter>;
  order?: Maybe<MigrationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateModuleJobRequestInput = {
  filter: ModuleJobRequestFilter;
  set?: Maybe<ModuleJobRequestPatch>;
  remove?: Maybe<ModuleJobRequestPatch>;
};

export type UpdateModuleJobRequestPayload = {
  __typename?: 'UpdateModuleJobRequestPayload';
  moduleJobRequest?: Maybe<Array<Maybe<ModuleJobRequest>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateModuleJobRequestPayloadModuleJobRequestArgs = {
  filter?: Maybe<ModuleJobRequestFilter>;
  order?: Maybe<ModuleJobRequestOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateNetworkConfigInput = {
  filter: NetworkConfigFilter;
  set?: Maybe<NetworkConfigPatch>;
  remove?: Maybe<NetworkConfigPatch>;
};

export type UpdateNetworkConfigPayload = {
  __typename?: 'UpdateNetworkConfigPayload';
  networkConfig?: Maybe<Array<Maybe<NetworkConfig>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateNetworkConfigPayloadNetworkConfigArgs = {
  filter?: Maybe<NetworkConfigFilter>;
  order?: Maybe<NetworkConfigOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateNetworkModuleInput = {
  filter: NetworkModuleFilter;
  set?: Maybe<NetworkModulePatch>;
  remove?: Maybe<NetworkModulePatch>;
};

export type UpdateNetworkModulePayload = {
  __typename?: 'UpdateNetworkModulePayload';
  networkModule?: Maybe<Array<Maybe<NetworkModule>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateNetworkModulePayloadNetworkModuleArgs = {
  filter?: Maybe<NetworkModuleFilter>;
  order?: Maybe<NetworkModuleOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateNetworkSnapshotInput = {
  filter: NetworkSnapshotFilter;
  set?: Maybe<NetworkSnapshotPatch>;
  remove?: Maybe<NetworkSnapshotPatch>;
};

export type UpdateNetworkSnapshotPayload = {
  __typename?: 'UpdateNetworkSnapshotPayload';
  networkSnapshot?: Maybe<Array<Maybe<NetworkSnapshot>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateNetworkSnapshotPayloadNetworkSnapshotArgs = {
  filter?: Maybe<NetworkSnapshotFilter>;
  order?: Maybe<NetworkSnapshotOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateNotificationInput = {
  filter: NotificationFilter;
  set?: Maybe<NotificationPatch>;
  remove?: Maybe<NotificationPatch>;
};

export type UpdateNotificationPayload = {
  __typename?: 'UpdateNotificationPayload';
  notification?: Maybe<Array<Maybe<Notification>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateNotificationPayloadNotificationArgs = {
  filter?: Maybe<NotificationFilter>;
  order?: Maybe<NotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateObjectDetectionMarkupInput = {
  filter: ObjectDetectionMarkupFilter;
  set?: Maybe<ObjectDetectionMarkupPatch>;
  remove?: Maybe<ObjectDetectionMarkupPatch>;
};

export type UpdateObjectDetectionMarkupPayload = {
  __typename?: 'UpdateObjectDetectionMarkupPayload';
  objectDetectionMarkup?: Maybe<Array<Maybe<ObjectDetectionMarkup>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateObjectDetectionMarkupPayloadObjectDetectionMarkupArgs = {
  filter?: Maybe<ObjectDetectionMarkupFilter>;
  order?: Maybe<ObjectDetectionMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateObjectDetectionPredictionInput = {
  filter: ObjectDetectionPredictionFilter;
  set?: Maybe<ObjectDetectionPredictionPatch>;
  remove?: Maybe<ObjectDetectionPredictionPatch>;
};

export type UpdateObjectDetectionPredictionPayload = {
  __typename?: 'UpdateObjectDetectionPredictionPayload';
  objectDetectionPrediction?: Maybe<Array<Maybe<ObjectDetectionPrediction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateObjectDetectionPredictionPayloadObjectDetectionPredictionArgs = {
  filter?: Maybe<ObjectDetectionPredictionFilter>;
  order?: Maybe<ObjectDetectionPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateOneOffNotificationInput = {
  filter: OneOffNotificationFilter;
  set?: Maybe<OneOffNotificationPatch>;
  remove?: Maybe<OneOffNotificationPatch>;
};

export type UpdateOneOffNotificationPayload = {
  __typename?: 'UpdateOneOffNotificationPayload';
  oneOffNotification?: Maybe<Array<Maybe<OneOffNotification>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateOneOffNotificationPayloadOneOffNotificationArgs = {
  filter?: Maybe<OneOffNotificationFilter>;
  order?: Maybe<OneOffNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateOwnableInput = {
  filter: OwnableFilter;
  set?: Maybe<OwnablePatch>;
  remove?: Maybe<OwnablePatch>;
};

export type UpdateOwnablePayload = {
  __typename?: 'UpdateOwnablePayload';
  ownable?: Maybe<Array<Maybe<Ownable>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateOwnablePayloadOwnableArgs = {
  filter?: Maybe<OwnableFilter>;
  order?: Maybe<OwnableOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePenAnnotationInput = {
  filter: PenAnnotationFilter;
  set?: Maybe<PenAnnotationPatch>;
  remove?: Maybe<PenAnnotationPatch>;
};

export type UpdatePenAnnotationPayload = {
  __typename?: 'UpdatePenAnnotationPayload';
  penAnnotation?: Maybe<Array<Maybe<PenAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePenAnnotationPayloadPenAnnotationArgs = {
  filter?: Maybe<PenAnnotationFilter>;
  order?: Maybe<PenAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineInput = {
  filter: PipelineFilter;
  set?: Maybe<PipelinePatch>;
  remove?: Maybe<PipelinePatch>;
};

export type UpdatePipelineModuleArtifactOutputInput = {
  filter: PipelineModuleArtifactOutputFilter;
  set?: Maybe<PipelineModuleArtifactOutputPatch>;
  remove?: Maybe<PipelineModuleArtifactOutputPatch>;
};

export type UpdatePipelineModuleArtifactOutputPayload = {
  __typename?: 'UpdatePipelineModuleArtifactOutputPayload';
  pipelineModuleArtifactOutput?: Maybe<Array<Maybe<PipelineModuleArtifactOutput>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleArtifactOutputPayloadPipelineModuleArtifactOutputArgs = {
  filter?: Maybe<PipelineModuleArtifactOutputFilter>;
  order?: Maybe<PipelineModuleArtifactOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleBMobileInput = {
  filter: PipelineModuleBMobileFilter;
  set?: Maybe<PipelineModuleBMobilePatch>;
  remove?: Maybe<PipelineModuleBMobilePatch>;
};

export type UpdatePipelineModuleBMobilePayload = {
  __typename?: 'UpdatePipelineModuleBMobilePayload';
  pipelineModuleBMobile?: Maybe<Array<Maybe<PipelineModuleBMobile>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleBMobilePayloadPipelineModuleBMobileArgs = {
  filter?: Maybe<PipelineModuleBMobileFilter>;
  order?: Maybe<PipelineModuleBMobileOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleCcCheckerInput = {
  filter: PipelineModuleCcCheckerFilter;
  set?: Maybe<PipelineModuleCcCheckerPatch>;
  remove?: Maybe<PipelineModuleCcCheckerPatch>;
};

export type UpdatePipelineModuleCcCheckerPayload = {
  __typename?: 'UpdatePipelineModuleCCCheckerPayload';
  pipelineModuleCCChecker?: Maybe<Array<Maybe<PipelineModuleCcChecker>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleCcCheckerPayloadPipelineModuleCcCheckerArgs = {
  filter?: Maybe<PipelineModuleCcCheckerFilter>;
  order?: Maybe<PipelineModuleCcCheckerOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleContrastEnhancementInput = {
  filter: PipelineModuleContrastEnhancementFilter;
  set?: Maybe<PipelineModuleContrastEnhancementPatch>;
  remove?: Maybe<PipelineModuleContrastEnhancementPatch>;
};

export type UpdatePipelineModuleContrastEnhancementPayload = {
  __typename?: 'UpdatePipelineModuleContrastEnhancementPayload';
  pipelineModuleContrastEnhancement?: Maybe<Array<Maybe<PipelineModuleContrastEnhancement>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleContrastEnhancementPayloadPipelineModuleContrastEnhancementArgs = {
  filter?: Maybe<PipelineModuleContrastEnhancementFilter>;
  order?: Maybe<PipelineModuleContrastEnhancementOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleDatapointOutputInput = {
  filter: PipelineModuleDatapointOutputFilter;
  set?: Maybe<PipelineModuleDatapointOutputPatch>;
  remove?: Maybe<PipelineModuleDatapointOutputPatch>;
};

export type UpdatePipelineModuleDatapointOutputPayload = {
  __typename?: 'UpdatePipelineModuleDatapointOutputPayload';
  pipelineModuleDatapointOutput?: Maybe<Array<Maybe<PipelineModuleDatapointOutput>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleDatapointOutputPayloadPipelineModuleDatapointOutputArgs = {
  filter?: Maybe<PipelineModuleDatapointOutputFilter>;
  order?: Maybe<PipelineModuleDatapointOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleEdgeInput = {
  filter: PipelineModuleEdgeFilter;
  set?: Maybe<PipelineModuleEdgePatch>;
  remove?: Maybe<PipelineModuleEdgePatch>;
};

export type UpdatePipelineModuleEdgePayload = {
  __typename?: 'UpdatePipelineModuleEdgePayload';
  pipelineModuleEdge?: Maybe<Array<Maybe<PipelineModuleEdge>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleEdgePayloadPipelineModuleEdgeArgs = {
  filter?: Maybe<PipelineModuleEdgeFilter>;
  order?: Maybe<PipelineModuleEdgeOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleInput = {
  filter: PipelineModuleFilter;
  set?: Maybe<PipelineModulePatch>;
  remove?: Maybe<PipelineModulePatch>;
};

export type UpdatePipelineModuleInputInput = {
  filter: PipelineModuleInputFilter;
  set?: Maybe<PipelineModuleInputPatch>;
  remove?: Maybe<PipelineModuleInputPatch>;
};

export type UpdatePipelineModuleInputPayload = {
  __typename?: 'UpdatePipelineModuleInputPayload';
  pipelineModuleInput?: Maybe<Array<Maybe<PipelineModuleInput>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleInputPayloadPipelineModuleInputArgs = {
  filter?: Maybe<PipelineModuleInputFilter>;
  order?: Maybe<PipelineModuleInputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleJobRequestCcCheckerInput = {
  filter: PipelineModuleJobRequestCcCheckerFilter;
  set?: Maybe<PipelineModuleJobRequestCcCheckerPatch>;
  remove?: Maybe<PipelineModuleJobRequestCcCheckerPatch>;
};

export type UpdatePipelineModuleJobRequestCcCheckerPayload = {
  __typename?: 'UpdatePipelineModuleJobRequestCCCheckerPayload';
  pipelineModuleJobRequestCCChecker?: Maybe<Array<Maybe<PipelineModuleJobRequestCcChecker>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleJobRequestCcCheckerPayloadPipelineModuleJobRequestCcCheckerArgs = {
  filter?: Maybe<PipelineModuleJobRequestCcCheckerFilter>;
  order?: Maybe<PipelineModuleJobRequestCcCheckerOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleJobRequestContrastEnhancementInput = {
  filter: PipelineModuleJobRequestContrastEnhancementFilter;
  set?: Maybe<PipelineModuleJobRequestContrastEnhancementPatch>;
  remove?: Maybe<PipelineModuleJobRequestContrastEnhancementPatch>;
};

export type UpdatePipelineModuleJobRequestContrastEnhancementPayload = {
  __typename?: 'UpdatePipelineModuleJobRequestContrastEnhancementPayload';
  pipelineModuleJobRequestContrastEnhancement?: Maybe<Array<Maybe<PipelineModuleJobRequestContrastEnhancement>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleJobRequestContrastEnhancementPayloadPipelineModuleJobRequestContrastEnhancementArgs = {
  filter?: Maybe<PipelineModuleJobRequestContrastEnhancementFilter>;
  order?: Maybe<PipelineModuleJobRequestContrastEnhancementOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleNetworkImageAnomalyDetectionInput = {
  filter: PipelineModuleNetworkImageAnomalyDetectionFilter;
  set?: Maybe<PipelineModuleNetworkImageAnomalyDetectionPatch>;
  remove?: Maybe<PipelineModuleNetworkImageAnomalyDetectionPatch>;
};

export type UpdatePipelineModuleNetworkImageAnomalyDetectionPayload = {
  __typename?: 'UpdatePipelineModuleNetworkImageAnomalyDetectionPayload';
  pipelineModuleNetworkImageAnomalyDetection?: Maybe<Array<Maybe<PipelineModuleNetworkImageAnomalyDetection>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleNetworkImageAnomalyDetectionPayloadPipelineModuleNetworkImageAnomalyDetectionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageAnomalyDetectionFilter>;
  order?: Maybe<PipelineModuleNetworkImageAnomalyDetectionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleNetworkImageClassificationInput = {
  filter: PipelineModuleNetworkImageClassificationFilter;
  set?: Maybe<PipelineModuleNetworkImageClassificationPatch>;
  remove?: Maybe<PipelineModuleNetworkImageClassificationPatch>;
};

export type UpdatePipelineModuleNetworkImageClassificationPayload = {
  __typename?: 'UpdatePipelineModuleNetworkImageClassificationPayload';
  pipelineModuleNetworkImageClassification?: Maybe<Array<Maybe<PipelineModuleNetworkImageClassification>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleNetworkImageClassificationPayloadPipelineModuleNetworkImageClassificationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageClassificationFilter>;
  order?: Maybe<PipelineModuleNetworkImageClassificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleNetworkImageInstanceSegmentationInput = {
  filter: PipelineModuleNetworkImageInstanceSegmentationFilter;
  set?: Maybe<PipelineModuleNetworkImageInstanceSegmentationPatch>;
  remove?: Maybe<PipelineModuleNetworkImageInstanceSegmentationPatch>;
};

export type UpdatePipelineModuleNetworkImageInstanceSegmentationPayload = {
  __typename?: 'UpdatePipelineModuleNetworkImageInstanceSegmentationPayload';
  pipelineModuleNetworkImageInstanceSegmentation?: Maybe<Array<Maybe<PipelineModuleNetworkImageInstanceSegmentation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleNetworkImageInstanceSegmentationPayloadPipelineModuleNetworkImageInstanceSegmentationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageInstanceSegmentationFilter>;
  order?: Maybe<PipelineModuleNetworkImageInstanceSegmentationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleNetworkImageObjectCountingInput = {
  filter: PipelineModuleNetworkImageObjectCountingFilter;
  set?: Maybe<PipelineModuleNetworkImageObjectCountingPatch>;
  remove?: Maybe<PipelineModuleNetworkImageObjectCountingPatch>;
};

export type UpdatePipelineModuleNetworkImageObjectCountingPayload = {
  __typename?: 'UpdatePipelineModuleNetworkImageObjectCountingPayload';
  pipelineModuleNetworkImageObjectCounting?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectCounting>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleNetworkImageObjectCountingPayloadPipelineModuleNetworkImageObjectCountingArgs = {
  filter?: Maybe<PipelineModuleNetworkImageObjectCountingFilter>;
  order?: Maybe<PipelineModuleNetworkImageObjectCountingOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleNetworkImageObjectDetectionInput = {
  filter: PipelineModuleNetworkImageObjectDetectionFilter;
  set?: Maybe<PipelineModuleNetworkImageObjectDetectionPatch>;
  remove?: Maybe<PipelineModuleNetworkImageObjectDetectionPatch>;
};

export type UpdatePipelineModuleNetworkImageObjectDetectionPayload = {
  __typename?: 'UpdatePipelineModuleNetworkImageObjectDetectionPayload';
  pipelineModuleNetworkImageObjectDetection?: Maybe<Array<Maybe<PipelineModuleNetworkImageObjectDetection>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleNetworkImageObjectDetectionPayloadPipelineModuleNetworkImageObjectDetectionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageObjectDetectionFilter>;
  order?: Maybe<PipelineModuleNetworkImageObjectDetectionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleNetworkImageOcrInput = {
  filter: PipelineModuleNetworkImageOcrFilter;
  set?: Maybe<PipelineModuleNetworkImageOcrPatch>;
  remove?: Maybe<PipelineModuleNetworkImageOcrPatch>;
};

export type UpdatePipelineModuleNetworkImageOcrPayload = {
  __typename?: 'UpdatePipelineModuleNetworkImageOCRPayload';
  pipelineModuleNetworkImageOCR?: Maybe<Array<Maybe<PipelineModuleNetworkImageOcr>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleNetworkImageOcrPayloadPipelineModuleNetworkImageOcrArgs = {
  filter?: Maybe<PipelineModuleNetworkImageOcrFilter>;
  order?: Maybe<PipelineModuleNetworkImageOcrOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleNetworkImageSegmentationInput = {
  filter: PipelineModuleNetworkImageSegmentationFilter;
  set?: Maybe<PipelineModuleNetworkImageSegmentationPatch>;
  remove?: Maybe<PipelineModuleNetworkImageSegmentationPatch>;
};

export type UpdatePipelineModuleNetworkImageSegmentationPayload = {
  __typename?: 'UpdatePipelineModuleNetworkImageSegmentationPayload';
  pipelineModuleNetworkImageSegmentation?: Maybe<Array<Maybe<PipelineModuleNetworkImageSegmentation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleNetworkImageSegmentationPayloadPipelineModuleNetworkImageSegmentationArgs = {
  filter?: Maybe<PipelineModuleNetworkImageSegmentationFilter>;
  order?: Maybe<PipelineModuleNetworkImageSegmentationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleNetworkImageSuperResolutionInput = {
  filter: PipelineModuleNetworkImageSuperResolutionFilter;
  set?: Maybe<PipelineModuleNetworkImageSuperResolutionPatch>;
  remove?: Maybe<PipelineModuleNetworkImageSuperResolutionPatch>;
};

export type UpdatePipelineModuleNetworkImageSuperResolutionPayload = {
  __typename?: 'UpdatePipelineModuleNetworkImageSuperResolutionPayload';
  pipelineModuleNetworkImageSuperResolution?: Maybe<Array<Maybe<PipelineModuleNetworkImageSuperResolution>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleNetworkImageSuperResolutionPayloadPipelineModuleNetworkImageSuperResolutionArgs = {
  filter?: Maybe<PipelineModuleNetworkImageSuperResolutionFilter>;
  order?: Maybe<PipelineModuleNetworkImageSuperResolutionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleOutputInput = {
  filter: PipelineModuleOutputFilter;
  set?: Maybe<PipelineModuleOutputPatch>;
  remove?: Maybe<PipelineModuleOutputPatch>;
};

export type UpdatePipelineModuleOutputPayload = {
  __typename?: 'UpdatePipelineModuleOutputPayload';
  pipelineModuleOutput?: Maybe<Array<Maybe<PipelineModuleOutput>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleOutputPayloadPipelineModuleOutputArgs = {
  filter?: Maybe<PipelineModuleOutputFilter>;
  order?: Maybe<PipelineModuleOutputOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModulePayload = {
  __typename?: 'UpdatePipelineModulePayload';
  pipelineModule?: Maybe<Array<Maybe<PipelineModule>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModulePayloadPipelineModuleArgs = {
  filter?: Maybe<PipelineModuleFilter>;
  order?: Maybe<PipelineModuleOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelineModuleUploadInput = {
  filter: PipelineModuleUploadFilter;
  set?: Maybe<PipelineModuleUploadPatch>;
  remove?: Maybe<PipelineModuleUploadPatch>;
};

export type UpdatePipelineModuleUploadPayload = {
  __typename?: 'UpdatePipelineModuleUploadPayload';
  pipelineModuleUpload?: Maybe<Array<Maybe<PipelineModuleUpload>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelineModuleUploadPayloadPipelineModuleUploadArgs = {
  filter?: Maybe<PipelineModuleUploadFilter>;
  order?: Maybe<PipelineModuleUploadOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePipelinePayload = {
  __typename?: 'UpdatePipelinePayload';
  pipeline?: Maybe<Array<Maybe<Pipeline>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePipelinePayloadPipelineArgs = {
  filter?: Maybe<PipelineFilter>;
  order?: Maybe<PipelineOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePixelAnnotationInput = {
  filter: PixelAnnotationFilter;
  set?: Maybe<PixelAnnotationPatch>;
  remove?: Maybe<PixelAnnotationPatch>;
};

export type UpdatePixelAnnotationPayload = {
  __typename?: 'UpdatePixelAnnotationPayload';
  pixelAnnotation?: Maybe<Array<Maybe<PixelAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePixelAnnotationPayloadPixelAnnotationArgs = {
  filter?: Maybe<PixelAnnotationFilter>;
  order?: Maybe<PixelAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePoint2DInput = {
  filter: Point2DFilter;
  set?: Maybe<Point2DPatch>;
  remove?: Maybe<Point2DPatch>;
};

export type UpdatePoint2DPayload = {
  __typename?: 'UpdatePoint2DPayload';
  point2D?: Maybe<Array<Maybe<Point2D>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePoint2DPayloadPoint2DArgs = {
  filter?: Maybe<Point2DFilter>;
  order?: Maybe<Point2DOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePolygonAnnotationInput = {
  filter: PolygonAnnotationFilter;
  set?: Maybe<PolygonAnnotationPatch>;
  remove?: Maybe<PolygonAnnotationPatch>;
};

export type UpdatePolygonAnnotationPayload = {
  __typename?: 'UpdatePolygonAnnotationPayload';
  polygonAnnotation?: Maybe<Array<Maybe<PolygonAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePolygonAnnotationPayloadPolygonAnnotationArgs = {
  filter?: Maybe<PolygonAnnotationFilter>;
  order?: Maybe<PolygonAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdatePretrainedNetworkInput = {
  filter: PretrainedNetworkFilter;
  set?: Maybe<PretrainedNetworkPatch>;
  remove?: Maybe<PretrainedNetworkPatch>;
};

export type UpdatePretrainedNetworkPayload = {
  __typename?: 'UpdatePretrainedNetworkPayload';
  pretrainedNetwork?: Maybe<Array<Maybe<PretrainedNetwork>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdatePretrainedNetworkPayloadPretrainedNetworkArgs = {
  filter?: Maybe<PretrainedNetworkFilter>;
  order?: Maybe<PretrainedNetworkOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateProductionTagInput = {
  filter: ProductionTagFilter;
  set?: Maybe<ProductionTagPatch>;
  remove?: Maybe<ProductionTagPatch>;
};

export type UpdateProductionTagPayload = {
  __typename?: 'UpdateProductionTagPayload';
  productionTag?: Maybe<Array<Maybe<ProductionTag>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateProductionTagPayloadProductionTagArgs = {
  filter?: Maybe<ProductionTagFilter>;
  order?: Maybe<ProductionTagOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateProgressNotificationInput = {
  filter: ProgressNotificationFilter;
  set?: Maybe<ProgressNotificationPatch>;
  remove?: Maybe<ProgressNotificationPatch>;
};

export type UpdateProgressNotificationPayload = {
  __typename?: 'UpdateProgressNotificationPayload';
  progressNotification?: Maybe<Array<Maybe<ProgressNotification>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateProgressNotificationPayloadProgressNotificationArgs = {
  filter?: Maybe<ProgressNotificationFilter>;
  order?: Maybe<ProgressNotificationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateReadOnlyInput = {
  filter: ReadOnlyFilter;
  set?: Maybe<ReadOnlyPatch>;
  remove?: Maybe<ReadOnlyPatch>;
};

export type UpdateReadOnlyPayload = {
  __typename?: 'UpdateReadOnlyPayload';
  readOnly?: Maybe<Array<Maybe<ReadOnly>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateReadOnlyPayloadReadOnlyArgs = {
  filter?: Maybe<ReadOnlyFilter>;
  order?: Maybe<ReadOnlyOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateRectangleAnnotationInput = {
  filter: RectangleAnnotationFilter;
  set?: Maybe<RectangleAnnotationPatch>;
  remove?: Maybe<RectangleAnnotationPatch>;
};

export type UpdateRectangleAnnotationPayload = {
  __typename?: 'UpdateRectangleAnnotationPayload';
  rectangleAnnotation?: Maybe<Array<Maybe<RectangleAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateRectangleAnnotationPayloadRectangleAnnotationArgs = {
  filter?: Maybe<RectangleAnnotationFilter>;
  order?: Maybe<RectangleAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateResearchBoxInput = {
  filter: ResearchBoxFilter;
  set?: Maybe<ResearchBoxPatch>;
  remove?: Maybe<ResearchBoxPatch>;
};

export type UpdateResearchBoxPayload = {
  __typename?: 'UpdateResearchBoxPayload';
  researchBox?: Maybe<Array<Maybe<ResearchBox>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateResearchBoxPayloadResearchBoxArgs = {
  filter?: Maybe<ResearchBoxFilter>;
  order?: Maybe<ResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateSausageAnnotationInput = {
  filter: SausageAnnotationFilter;
  set?: Maybe<SausageAnnotationPatch>;
  remove?: Maybe<SausageAnnotationPatch>;
};

export type UpdateSausageAnnotationPayload = {
  __typename?: 'UpdateSausageAnnotationPayload';
  sausageAnnotation?: Maybe<Array<Maybe<SausageAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateSausageAnnotationPayloadSausageAnnotationArgs = {
  filter?: Maybe<SausageAnnotationFilter>;
  order?: Maybe<SausageAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateScalarAnnotationInput = {
  filter: ScalarAnnotationFilter;
  set?: Maybe<ScalarAnnotationPatch>;
  remove?: Maybe<ScalarAnnotationPatch>;
};

export type UpdateScalarAnnotationPayload = {
  __typename?: 'UpdateScalarAnnotationPayload';
  scalarAnnotation?: Maybe<Array<Maybe<ScalarAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateScalarAnnotationPayloadScalarAnnotationArgs = {
  filter?: Maybe<ScalarAnnotationFilter>;
  order?: Maybe<ScalarAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateScalarPredictionInput = {
  filter: ScalarPredictionFilter;
  set?: Maybe<ScalarPredictionPatch>;
  remove?: Maybe<ScalarPredictionPatch>;
};

export type UpdateScalarPredictionPayload = {
  __typename?: 'UpdateScalarPredictionPayload';
  scalarPrediction?: Maybe<Array<Maybe<ScalarPrediction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateScalarPredictionPayloadScalarPredictionArgs = {
  filter?: Maybe<ScalarPredictionFilter>;
  order?: Maybe<ScalarPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateSegmentationImageAnnotationInput = {
  filter: SegmentationImageAnnotationFilter;
  set?: Maybe<SegmentationImageAnnotationPatch>;
  remove?: Maybe<SegmentationImageAnnotationPatch>;
};

export type UpdateSegmentationImageAnnotationPayload = {
  __typename?: 'UpdateSegmentationImageAnnotationPayload';
  segmentationImageAnnotation?: Maybe<Array<Maybe<SegmentationImageAnnotation>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateSegmentationImageAnnotationPayloadSegmentationImageAnnotationArgs = {
  filter?: Maybe<SegmentationImageAnnotationFilter>;
  order?: Maybe<SegmentationImageAnnotationOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateSegmentationMap2DInput = {
  filter: SegmentationMap2DFilter;
  set?: Maybe<SegmentationMap2DPatch>;
  remove?: Maybe<SegmentationMap2DPatch>;
};

export type UpdateSegmentationMap2DPayload = {
  __typename?: 'UpdateSegmentationMap2DPayload';
  segmentationMap2D?: Maybe<Array<Maybe<SegmentationMap2D>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateSegmentationMap2DPayloadSegmentationMap2DArgs = {
  filter?: Maybe<SegmentationMap2DFilter>;
  order?: Maybe<SegmentationMap2DOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateSegmentationMarkupInput = {
  filter: SegmentationMarkupFilter;
  set?: Maybe<SegmentationMarkupPatch>;
  remove?: Maybe<SegmentationMarkupPatch>;
};

export type UpdateSegmentationMarkupPayload = {
  __typename?: 'UpdateSegmentationMarkupPayload';
  segmentationMarkup?: Maybe<Array<Maybe<SegmentationMarkup>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateSegmentationMarkupPayloadSegmentationMarkupArgs = {
  filter?: Maybe<SegmentationMarkupFilter>;
  order?: Maybe<SegmentationMarkupOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateSegmentationPredictionInput = {
  filter: SegmentationPredictionFilter;
  set?: Maybe<SegmentationPredictionPatch>;
  remove?: Maybe<SegmentationPredictionPatch>;
};

export type UpdateSegmentationPredictionObjectInput = {
  filter: SegmentationPredictionObjectFilter;
  set?: Maybe<SegmentationPredictionObjectPatch>;
  remove?: Maybe<SegmentationPredictionObjectPatch>;
};

export type UpdateSegmentationPredictionObjectPayload = {
  __typename?: 'UpdateSegmentationPredictionObjectPayload';
  segmentationPredictionObject?: Maybe<Array<Maybe<SegmentationPredictionObject>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateSegmentationPredictionObjectPayloadSegmentationPredictionObjectArgs = {
  filter?: Maybe<SegmentationPredictionObjectFilter>;
  order?: Maybe<SegmentationPredictionObjectOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateSegmentationPredictionPayload = {
  __typename?: 'UpdateSegmentationPredictionPayload';
  segmentationPrediction?: Maybe<Array<Maybe<SegmentationPrediction>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateSegmentationPredictionPayloadSegmentationPredictionArgs = {
  filter?: Maybe<SegmentationPredictionFilter>;
  order?: Maybe<SegmentationPredictionOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateTimestampedInput = {
  filter: TimestampedFilter;
  set?: Maybe<TimestampedPatch>;
  remove?: Maybe<TimestampedPatch>;
};

export type UpdateTimestampedPayload = {
  __typename?: 'UpdateTimestampedPayload';
  timestamped?: Maybe<Array<Maybe<Timestamped>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateTimestampedPayloadTimestampedArgs = {
  filter?: Maybe<TimestampedFilter>;
  order?: Maybe<TimestampedOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UpdateUserInput = {
  filter: UserFilter;
  set?: Maybe<UserPatch>;
  remove?: Maybe<UserPatch>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user?: Maybe<Array<Maybe<User>>>;
  numUids?: Maybe<Scalars['Int']>;
};


export type UpdateUserPayloadUserArgs = {
  filter?: Maybe<UserFilter>;
  order?: Maybe<UserOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type User = Timestamped & {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
  lastSyncedAt: Scalars['DateTime'];
  password: Scalars['String'];
  email: Scalars['String'];
  role: Role;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  allowedFeatures: Array<UiFeature>;
  blockedFeatures: Array<UiFeature>;
  vendor?: Maybe<User>;
  subAccounts: Array<User>;
  datasets: Array<Dataset>;
  networkKey: Scalars['String'];
  trainingQuota: Scalars['Int'];
  inferenceQuota: Scalars['Int'];
  subAccountsAggregate?: Maybe<UserAggregateResult>;
  datasetsAggregate?: Maybe<DatasetAggregateResult>;
};


export type UserVendorArgs = {
  filter?: Maybe<UserFilter>;
};


export type UserSubAccountsArgs = {
  filter?: Maybe<UserFilter>;
  order?: Maybe<UserOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type UserDatasetsArgs = {
  filter?: Maybe<DatasetFilter>;
  order?: Maybe<DatasetOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type UserSubAccountsAggregateArgs = {
  filter?: Maybe<UserFilter>;
};


export type UserDatasetsAggregateArgs = {
  filter?: Maybe<DatasetFilter>;
};

export type UserAggregateResult = {
  __typename?: 'UserAggregateResult';
  count?: Maybe<Scalars['Int']>;
  createdAtMin?: Maybe<Scalars['DateTime']>;
  createdAtMax?: Maybe<Scalars['DateTime']>;
  updatedAtMin?: Maybe<Scalars['DateTime']>;
  updatedAtMax?: Maybe<Scalars['DateTime']>;
  usernameMin?: Maybe<Scalars['String']>;
  usernameMax?: Maybe<Scalars['String']>;
  lastSyncedAtMin?: Maybe<Scalars['DateTime']>;
  lastSyncedAtMax?: Maybe<Scalars['DateTime']>;
  passwordMin?: Maybe<Scalars['String']>;
  passwordMax?: Maybe<Scalars['String']>;
  emailMin?: Maybe<Scalars['String']>;
  emailMax?: Maybe<Scalars['String']>;
  nameMin?: Maybe<Scalars['String']>;
  nameMax?: Maybe<Scalars['String']>;
  surnameMin?: Maybe<Scalars['String']>;
  surnameMax?: Maybe<Scalars['String']>;
  phoneMin?: Maybe<Scalars['String']>;
  phoneMax?: Maybe<Scalars['String']>;
  networkKeyMin?: Maybe<Scalars['String']>;
  networkKeyMax?: Maybe<Scalars['String']>;
  trainingQuotaMin?: Maybe<Scalars['Int']>;
  trainingQuotaMax?: Maybe<Scalars['Int']>;
  trainingQuotaSum?: Maybe<Scalars['Int']>;
  trainingQuotaAvg?: Maybe<Scalars['Float']>;
  inferenceQuotaMin?: Maybe<Scalars['Int']>;
  inferenceQuotaMax?: Maybe<Scalars['Int']>;
  inferenceQuotaSum?: Maybe<Scalars['Int']>;
  inferenceQuotaAvg?: Maybe<Scalars['Float']>;
};

export type UserFilter = {
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  username?: Maybe<StringHashFilter>;
  lastSyncedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFullTextFilter>;
  surname?: Maybe<StringFullTextFilter>;
  phone?: Maybe<StringFullTextFilter>;
  has?: Maybe<Array<Maybe<UserHasFilter>>>;
  and?: Maybe<Array<Maybe<UserFilter>>>;
  or?: Maybe<Array<Maybe<UserFilter>>>;
  not?: Maybe<UserFilter>;
};

export enum UserHasFilter {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Username = 'username',
  LastSyncedAt = 'lastSyncedAt',
  Password = 'password',
  Email = 'email',
  Role = 'role',
  Name = 'name',
  Surname = 'surname',
  Phone = 'phone',
  AllowedFeatures = 'allowedFeatures',
  BlockedFeatures = 'blockedFeatures',
  Vendor = 'vendor',
  SubAccounts = 'subAccounts',
  Datasets = 'datasets',
  NetworkKey = 'networkKey',
  TrainingQuota = 'trainingQuota',
  InferenceQuota = 'inferenceQuota'
}

export type UserOrder = {
  asc?: Maybe<UserOrderable>;
  desc?: Maybe<UserOrderable>;
  then?: Maybe<UserOrder>;
};

export enum UserOrderable {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Username = 'username',
  LastSyncedAt = 'lastSyncedAt',
  Password = 'password',
  Email = 'email',
  Name = 'name',
  Surname = 'surname',
  Phone = 'phone',
  NetworkKey = 'networkKey',
  TrainingQuota = 'trainingQuota',
  InferenceQuota = 'inferenceQuota'
}

export type UserPatch = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  password?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  allowedFeatures?: Maybe<Array<UiFeature>>;
  blockedFeatures?: Maybe<Array<UiFeature>>;
  vendor?: Maybe<UserRef>;
  subAccounts?: Maybe<Array<UserRef>>;
  datasets?: Maybe<Array<DatasetRef>>;
  networkKey?: Maybe<Scalars['String']>;
  trainingQuota?: Maybe<Scalars['Int']>;
  inferenceQuota?: Maybe<Scalars['Int']>;
};

export type UserRef = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  password?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  allowedFeatures?: Maybe<Array<UiFeature>>;
  blockedFeatures?: Maybe<Array<UiFeature>>;
  vendor?: Maybe<UserRef>;
  subAccounts?: Maybe<Array<UserRef>>;
  datasets?: Maybe<Array<DatasetRef>>;
  networkKey?: Maybe<Scalars['String']>;
  trainingQuota?: Maybe<Scalars['Int']>;
  inferenceQuota?: Maybe<Scalars['Int']>;
};

export type WidthHeight = {
  __typename?: 'WidthHeight';
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export type WithinFilter = {
  polygon: PolygonRef;
};


export const CheckDatasetForMarkupDocument = gql`
    query CheckDatasetForMarkup($datasetId: String!) {
  queryImageMarkup(first: 1) @cascade {
    images {
      researchBox {
        dataset(filter: {id: {eq: $datasetId}}) {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useCheckDatasetForMarkupQuery__
 *
 * To run a query within a React component, call `useCheckDatasetForMarkupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDatasetForMarkupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDatasetForMarkupQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useCheckDatasetForMarkupQuery(baseOptions: Apollo.QueryHookOptions<CheckDatasetForMarkupQuery, CheckDatasetForMarkupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckDatasetForMarkupQuery, CheckDatasetForMarkupQueryVariables>(CheckDatasetForMarkupDocument, options);
      }
export function useCheckDatasetForMarkupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckDatasetForMarkupQuery, CheckDatasetForMarkupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckDatasetForMarkupQuery, CheckDatasetForMarkupQueryVariables>(CheckDatasetForMarkupDocument, options);
        }
export type CheckDatasetForMarkupQueryHookResult = ReturnType<typeof useCheckDatasetForMarkupQuery>;
export type CheckDatasetForMarkupLazyQueryHookResult = ReturnType<typeof useCheckDatasetForMarkupLazyQuery>;
export type CheckDatasetForMarkupQueryResult = Apollo.QueryResult<CheckDatasetForMarkupQuery, CheckDatasetForMarkupQueryVariables>;
export const GetObjectDetectionMarkupForImagesAndNetworkDocument = gql`
    query GetObjectDetectionMarkupForImagesAndNetwork($imageIds: [String!], $networkId: String) {
  queryImage(filter: {id: {in: $imageIds}}) {
    id
    width
    height
    predictions @cascade(fields: ["network"]) {
      id
      __typename
      network(filter: {networkModuleId: {eq: $networkId}}) {
        id: networkModuleId
      }
      ... on ObjectDetectionPrediction {
        predictions {
          topLeft {
            x
            y
          }
          bottomRight {
            x
            y
          }
          probability
          label {
            id
            name
            color
          }
        }
      }
    }
    markups @cascade(fields: ["network"]) {
      id
      updatedAt
      subset
      __typename
      network(filter: {networkModuleId: {eq: $networkId}}) {
        id: networkModuleId
      }
      ... on ObjectDetectionMarkup {
        annotations {
          id
          __typename
          annotationType
          label {
            id
            name
            color
          }
          topLeft {
            x
            y
          }
          bottomRight {
            x
            y
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetObjectDetectionMarkupForImagesAndNetworkQuery__
 *
 * To run a query within a React component, call `useGetObjectDetectionMarkupForImagesAndNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObjectDetectionMarkupForImagesAndNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObjectDetectionMarkupForImagesAndNetworkQuery({
 *   variables: {
 *      imageIds: // value for 'imageIds'
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useGetObjectDetectionMarkupForImagesAndNetworkQuery(baseOptions?: Apollo.QueryHookOptions<GetObjectDetectionMarkupForImagesAndNetworkQuery, GetObjectDetectionMarkupForImagesAndNetworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetObjectDetectionMarkupForImagesAndNetworkQuery, GetObjectDetectionMarkupForImagesAndNetworkQueryVariables>(GetObjectDetectionMarkupForImagesAndNetworkDocument, options);
      }
export function useGetObjectDetectionMarkupForImagesAndNetworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetObjectDetectionMarkupForImagesAndNetworkQuery, GetObjectDetectionMarkupForImagesAndNetworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetObjectDetectionMarkupForImagesAndNetworkQuery, GetObjectDetectionMarkupForImagesAndNetworkQueryVariables>(GetObjectDetectionMarkupForImagesAndNetworkDocument, options);
        }
export type GetObjectDetectionMarkupForImagesAndNetworkQueryHookResult = ReturnType<typeof useGetObjectDetectionMarkupForImagesAndNetworkQuery>;
export type GetObjectDetectionMarkupForImagesAndNetworkLazyQueryHookResult = ReturnType<typeof useGetObjectDetectionMarkupForImagesAndNetworkLazyQuery>;
export type GetObjectDetectionMarkupForImagesAndNetworkQueryResult = Apollo.QueryResult<GetObjectDetectionMarkupForImagesAndNetworkQuery, GetObjectDetectionMarkupForImagesAndNetworkQueryVariables>;
export const GetResearchBoxContentDocument = gql`
    query GetResearchBoxContent($researchBoxId: String!) {
  getResearchBox(id: $researchBoxId) {
    id
    name
    createdAt
    updatedAt
    dataset {
      id
      name
    }
    owner
    datapoints {
      id
      __typename
      createdAt
      updatedAt
      ... on Image {
        thumbnail
        binaryURL
        resolutions {
          height
          width
          id
          binaryURL
        }
        filename
        width
        height
        predictions {
          id
          __typename
          ... on SegmentationPrediction {
            segmentationMaps {
              thumbnail
              binaryURL
            }
          }
        }
        markups {
          id
          __typename
          ... on ClassificationMarkup {
            annotations {
              id
              label {
                name
                color
              }
              value
            }
          }
          ... on ObjectDetectionMarkup {
            annotations {
              id
              annotationType
              label {
                name
                color
              }
              topLeft {
                x
                y
              }
              bottomRight {
                x
                y
              }
            }
          }
          ... on SegmentationMarkup {
            annotations {
              id
              __typename
              annotationType
              label {
                name
                color
              }
              ... on CircleAnnotation {
                center {
                  x
                  y
                }
                radius
              }
              ... on PolygonAnnotation {
                points {
                  idx
                  x
                  y
                }
              }
              ... on RectangleAnnotation {
                topLeft {
                  x
                  y
                }
                bottomRight {
                  x
                  y
                }
              }
              ... on PenAnnotation {
                points {
                  x
                  y
                }
              }
              ... on SausageAnnotation {
                points {
                  idx
                  x
                  y
                }
                radius
              }
              ... on MagicwandAnnotation {
                center {
                  x
                  y
                }
                threshold
              }
              ... on PixelAnnotation {
                topLeft {
                  x
                  y
                }
                bottomRight {
                  x
                  y
                }
                dataURL
              }
            }
          }
        }
      }
      ... on CCCheckerResult {
        groups
        isOkay
      }
      ... on MagneticFieldScan {
        width
        height
        startIndices
        hasBaseline
        binaryURL
      }
      producedBy {
        dataType
        name
      }
    }
  }
}
    `;

/**
 * __useGetResearchBoxContentQuery__
 *
 * To run a query within a React component, call `useGetResearchBoxContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResearchBoxContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResearchBoxContentQuery({
 *   variables: {
 *      researchBoxId: // value for 'researchBoxId'
 *   },
 * });
 */
export function useGetResearchBoxContentQuery(baseOptions: Apollo.QueryHookOptions<GetResearchBoxContentQuery, GetResearchBoxContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResearchBoxContentQuery, GetResearchBoxContentQueryVariables>(GetResearchBoxContentDocument, options);
      }
export function useGetResearchBoxContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResearchBoxContentQuery, GetResearchBoxContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResearchBoxContentQuery, GetResearchBoxContentQueryVariables>(GetResearchBoxContentDocument, options);
        }
export type GetResearchBoxContentQueryHookResult = ReturnType<typeof useGetResearchBoxContentQuery>;
export type GetResearchBoxContentLazyQueryHookResult = ReturnType<typeof useGetResearchBoxContentLazyQuery>;
export type GetResearchBoxContentQueryResult = Apollo.QueryResult<GetResearchBoxContentQuery, GetResearchBoxContentQueryVariables>;
export const GetSegmentationMarkupsForImagesAndNetworkDocument = gql`
    query GetSegmentationMarkupsForImagesAndNetwork($imageIds: [String!], $networkId: String) {
  queryImage(filter: {id: {in: $imageIds}}) {
    id
    width
    height
    predictions @cascade(fields: ["network"]) {
      id
      __typename
      network(filter: {networkModuleId: {eq: $networkId}}) {
        id: networkModuleId
      }
      ... on SegmentationPrediction {
        objects {
          dataURL
          topLeft {
            x
            y
          }
          bottomRight {
            x
            y
          }
          probability
          label {
            id
            name
            color
          }
        }
        segmentationMaps {
          id
          thumbnail
          binaryURL
          label {
            id
            name
            color
          }
        }
      }
    }
    markups @cascade(fields: ["network"]) {
      id
      updatedAt
      subset
      __typename
      network(filter: {networkModuleId: {eq: $networkId}}) {
        id: networkModuleId
      }
      ... on SegmentationMarkup {
        annotations {
          id
          __typename
          annotationType
          label {
            id
            name
            color
          }
          ... on CircleAnnotation {
            center {
              x
              y
            }
            radius
          }
          ... on PolygonAnnotation {
            points {
              idx
              x
              y
            }
          }
          ... on RectangleAnnotation {
            topLeft {
              x
              y
            }
            bottomRight {
              x
              y
            }
          }
          ... on PenAnnotation {
            points {
              idx
              x
              y
            }
            thickness
            topLeft {
              x
              y
            }
            bottomRight {
              x
              y
            }
            dataURL
          }
          ... on SausageAnnotation {
            points {
              idx
              x
              y
            }
            radius
            topLeft {
              x
              y
            }
            bottomRight {
              x
              y
            }
            dataURL
          }
          ... on MagicwandAnnotation {
            center {
              x
              y
            }
            threshold
            topLeft {
              x
              y
            }
            bottomRight {
              x
              y
            }
            dataURL
          }
          ... on PixelAnnotation {
            topLeft {
              x
              y
            }
            bottomRight {
              x
              y
            }
            dataURL
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetSegmentationMarkupsForImagesAndNetworkQuery__
 *
 * To run a query within a React component, call `useGetSegmentationMarkupsForImagesAndNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentationMarkupsForImagesAndNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentationMarkupsForImagesAndNetworkQuery({
 *   variables: {
 *      imageIds: // value for 'imageIds'
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useGetSegmentationMarkupsForImagesAndNetworkQuery(baseOptions?: Apollo.QueryHookOptions<GetSegmentationMarkupsForImagesAndNetworkQuery, GetSegmentationMarkupsForImagesAndNetworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentationMarkupsForImagesAndNetworkQuery, GetSegmentationMarkupsForImagesAndNetworkQueryVariables>(GetSegmentationMarkupsForImagesAndNetworkDocument, options);
      }
export function useGetSegmentationMarkupsForImagesAndNetworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentationMarkupsForImagesAndNetworkQuery, GetSegmentationMarkupsForImagesAndNetworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentationMarkupsForImagesAndNetworkQuery, GetSegmentationMarkupsForImagesAndNetworkQueryVariables>(GetSegmentationMarkupsForImagesAndNetworkDocument, options);
        }
export type GetSegmentationMarkupsForImagesAndNetworkQueryHookResult = ReturnType<typeof useGetSegmentationMarkupsForImagesAndNetworkQuery>;
export type GetSegmentationMarkupsForImagesAndNetworkLazyQueryHookResult = ReturnType<typeof useGetSegmentationMarkupsForImagesAndNetworkLazyQuery>;
export type GetSegmentationMarkupsForImagesAndNetworkQueryResult = Apollo.QueryResult<GetSegmentationMarkupsForImagesAndNetworkQuery, GetSegmentationMarkupsForImagesAndNetworkQueryVariables>;
export const QueryDatasetFoldersAndResearchBoxesDocument = gql`
    query QueryDatasetFoldersAndResearchBoxes($datasetId: String!) {
  getDataset(id: $datasetId) {
    name
    objects {
      id
      name
      ... on Folder {
        description
        objectsAggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useQueryDatasetFoldersAndResearchBoxesQuery__
 *
 * To run a query within a React component, call `useQueryDatasetFoldersAndResearchBoxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryDatasetFoldersAndResearchBoxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryDatasetFoldersAndResearchBoxesQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useQueryDatasetFoldersAndResearchBoxesQuery(baseOptions: Apollo.QueryHookOptions<QueryDatasetFoldersAndResearchBoxesQuery, QueryDatasetFoldersAndResearchBoxesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryDatasetFoldersAndResearchBoxesQuery, QueryDatasetFoldersAndResearchBoxesQueryVariables>(QueryDatasetFoldersAndResearchBoxesDocument, options);
      }
export function useQueryDatasetFoldersAndResearchBoxesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryDatasetFoldersAndResearchBoxesQuery, QueryDatasetFoldersAndResearchBoxesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryDatasetFoldersAndResearchBoxesQuery, QueryDatasetFoldersAndResearchBoxesQueryVariables>(QueryDatasetFoldersAndResearchBoxesDocument, options);
        }
export type QueryDatasetFoldersAndResearchBoxesQueryHookResult = ReturnType<typeof useQueryDatasetFoldersAndResearchBoxesQuery>;
export type QueryDatasetFoldersAndResearchBoxesLazyQueryHookResult = ReturnType<typeof useQueryDatasetFoldersAndResearchBoxesLazyQuery>;
export type QueryDatasetFoldersAndResearchBoxesQueryResult = Apollo.QueryResult<QueryDatasetFoldersAndResearchBoxesQuery, QueryDatasetFoldersAndResearchBoxesQueryVariables>;
export const QueryFolderFilteredContentDocument = gql`
    query QueryFolderFilteredContent($folderIds: [String!]!, $filterName: String) {
  queryFolder(filter: {id: {in: $folderIds}}) {
    id
    name
    description
    objectsAggregate(filter: {name: {regexp: $filterName}}) {
      count
    }
  }
}
    `;

/**
 * __useQueryFolderFilteredContentQuery__
 *
 * To run a query within a React component, call `useQueryFolderFilteredContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFolderFilteredContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFolderFilteredContentQuery({
 *   variables: {
 *      folderIds: // value for 'folderIds'
 *      filterName: // value for 'filterName'
 *   },
 * });
 */
export function useQueryFolderFilteredContentQuery(baseOptions: Apollo.QueryHookOptions<QueryFolderFilteredContentQuery, QueryFolderFilteredContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryFolderFilteredContentQuery, QueryFolderFilteredContentQueryVariables>(QueryFolderFilteredContentDocument, options);
      }
export function useQueryFolderFilteredContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryFolderFilteredContentQuery, QueryFolderFilteredContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryFolderFilteredContentQuery, QueryFolderFilteredContentQueryVariables>(QueryFolderFilteredContentDocument, options);
        }
export type QueryFolderFilteredContentQueryHookResult = ReturnType<typeof useQueryFolderFilteredContentQuery>;
export type QueryFolderFilteredContentLazyQueryHookResult = ReturnType<typeof useQueryFolderFilteredContentLazyQuery>;
export type QueryFolderFilteredContentQueryResult = Apollo.QueryResult<QueryFolderFilteredContentQuery, QueryFolderFilteredContentQueryVariables>;
export const QueryFolderFoldersAndResearchBoxesDocument = gql`
    query QueryFolderFoldersAndResearchBoxes($folderId: String!) {
  getFolder(id: $folderId) {
    objects {
      id
      name
      ... on Folder {
        description
        objectsAggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useQueryFolderFoldersAndResearchBoxesQuery__
 *
 * To run a query within a React component, call `useQueryFolderFoldersAndResearchBoxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFolderFoldersAndResearchBoxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFolderFoldersAndResearchBoxesQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useQueryFolderFoldersAndResearchBoxesQuery(baseOptions: Apollo.QueryHookOptions<QueryFolderFoldersAndResearchBoxesQuery, QueryFolderFoldersAndResearchBoxesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryFolderFoldersAndResearchBoxesQuery, QueryFolderFoldersAndResearchBoxesQueryVariables>(QueryFolderFoldersAndResearchBoxesDocument, options);
      }
export function useQueryFolderFoldersAndResearchBoxesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryFolderFoldersAndResearchBoxesQuery, QueryFolderFoldersAndResearchBoxesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryFolderFoldersAndResearchBoxesQuery, QueryFolderFoldersAndResearchBoxesQueryVariables>(QueryFolderFoldersAndResearchBoxesDocument, options);
        }
export type QueryFolderFoldersAndResearchBoxesQueryHookResult = ReturnType<typeof useQueryFolderFoldersAndResearchBoxesQuery>;
export type QueryFolderFoldersAndResearchBoxesLazyQueryHookResult = ReturnType<typeof useQueryFolderFoldersAndResearchBoxesLazyQuery>;
export type QueryFolderFoldersAndResearchBoxesQueryResult = Apollo.QueryResult<QueryFolderFoldersAndResearchBoxesQuery, QueryFolderFoldersAndResearchBoxesQueryVariables>;
export const QueryFolderNamesDocument = gql`
    query QueryFolderNames($folderIds: [String!]!) {
  queryFolder(filter: {id: {in: $folderIds}}) {
    id
    name
  }
}
    `;

/**
 * __useQueryFolderNamesQuery__
 *
 * To run a query within a React component, call `useQueryFolderNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFolderNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFolderNamesQuery({
 *   variables: {
 *      folderIds: // value for 'folderIds'
 *   },
 * });
 */
export function useQueryFolderNamesQuery(baseOptions: Apollo.QueryHookOptions<QueryFolderNamesQuery, QueryFolderNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryFolderNamesQuery, QueryFolderNamesQueryVariables>(QueryFolderNamesDocument, options);
      }
export function useQueryFolderNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryFolderNamesQuery, QueryFolderNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryFolderNamesQuery, QueryFolderNamesQueryVariables>(QueryFolderNamesDocument, options);
        }
export type QueryFolderNamesQueryHookResult = ReturnType<typeof useQueryFolderNamesQuery>;
export type QueryFolderNamesLazyQueryHookResult = ReturnType<typeof useQueryFolderNamesLazyQuery>;
export type QueryFolderNamesQueryResult = Apollo.QueryResult<QueryFolderNamesQuery, QueryFolderNamesQueryVariables>;
export const QueryImageClassificationMarkupsDocument = gql`
    query QueryImageClassificationMarkups($imageIds: [String!]!, $networkId: String) {
  queryImage(filter: {id: {in: $imageIds}}) {
    width
    height
    predictions @cascade(fields: ["network"]) {
      id
      createdAt
      network(filter: {networkModuleId: {eq: $networkId}}) {
        id: networkModuleId
        ... on PipelineModule {
          name
        }
      }
      ... on ClassificationPrediction {
        __typename
        predictions {
          id
          label {
            id
            name
            color
          }
          probability
        }
      }
    }
    markups @cascade(fields: ["network"]) {
      id
      network(filter: {networkModuleId: {eq: $networkId}}) {
        id: networkModuleId
      }
      ... on ClassificationMarkup {
        subset
        annotations {
          id
          label {
            id
            idx
            color
            name
          }
          value
        }
      }
    }
  }
}
    `;

/**
 * __useQueryImageClassificationMarkupsQuery__
 *
 * To run a query within a React component, call `useQueryImageClassificationMarkupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryImageClassificationMarkupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryImageClassificationMarkupsQuery({
 *   variables: {
 *      imageIds: // value for 'imageIds'
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useQueryImageClassificationMarkupsQuery(baseOptions: Apollo.QueryHookOptions<QueryImageClassificationMarkupsQuery, QueryImageClassificationMarkupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryImageClassificationMarkupsQuery, QueryImageClassificationMarkupsQueryVariables>(QueryImageClassificationMarkupsDocument, options);
      }
export function useQueryImageClassificationMarkupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryImageClassificationMarkupsQuery, QueryImageClassificationMarkupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryImageClassificationMarkupsQuery, QueryImageClassificationMarkupsQueryVariables>(QueryImageClassificationMarkupsDocument, options);
        }
export type QueryImageClassificationMarkupsQueryHookResult = ReturnType<typeof useQueryImageClassificationMarkupsQuery>;
export type QueryImageClassificationMarkupsLazyQueryHookResult = ReturnType<typeof useQueryImageClassificationMarkupsLazyQuery>;
export type QueryImageClassificationMarkupsQueryResult = Apollo.QueryResult<QueryImageClassificationMarkupsQuery, QueryImageClassificationMarkupsQueryVariables>;
export const QueryNetworksForDatasetDocument = gql`
    query QueryNetworksForDataset($datasetId: String!) {
  getDataset(id: $datasetId) {
    id
    pipeline {
      id
      modules {
        ... on PipelineModuleNetworkImageSegmentation {
          id
          name
          description
          classLabels {
            id
            name
            color
          }
        }
        ... on PipelineModuleNetworkImageClassification {
          id
          name
          description
          classLabels {
            id
            name
            color
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQueryNetworksForDatasetQuery__
 *
 * To run a query within a React component, call `useQueryNetworksForDatasetQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryNetworksForDatasetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryNetworksForDatasetQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useQueryNetworksForDatasetQuery(baseOptions: Apollo.QueryHookOptions<QueryNetworksForDatasetQuery, QueryNetworksForDatasetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryNetworksForDatasetQuery, QueryNetworksForDatasetQueryVariables>(QueryNetworksForDatasetDocument, options);
      }
export function useQueryNetworksForDatasetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryNetworksForDatasetQuery, QueryNetworksForDatasetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryNetworksForDatasetQuery, QueryNetworksForDatasetQueryVariables>(QueryNetworksForDatasetDocument, options);
        }
export type QueryNetworksForDatasetQueryHookResult = ReturnType<typeof useQueryNetworksForDatasetQuery>;
export type QueryNetworksForDatasetLazyQueryHookResult = ReturnType<typeof useQueryNetworksForDatasetLazyQuery>;
export type QueryNetworksForDatasetQueryResult = Apollo.QueryResult<QueryNetworksForDatasetQuery, QueryNetworksForDatasetQueryVariables>;
export const GetDatasetPropertiesDocument = gql`
    query getDatasetProperties($datasetId: String!) {
  getDataset(id: $datasetId) {
    id
    aggregateDatapointTypes
    aggregateImageResolutions {
      width
      height
    }
  }
}
    `;

/**
 * __useGetDatasetPropertiesQuery__
 *
 * To run a query within a React component, call `useGetDatasetPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetPropertiesQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetDatasetPropertiesQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetPropertiesQuery, GetDatasetPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetPropertiesQuery, GetDatasetPropertiesQueryVariables>(GetDatasetPropertiesDocument, options);
      }
export function useGetDatasetPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetPropertiesQuery, GetDatasetPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetPropertiesQuery, GetDatasetPropertiesQueryVariables>(GetDatasetPropertiesDocument, options);
        }
export type GetDatasetPropertiesQueryHookResult = ReturnType<typeof useGetDatasetPropertiesQuery>;
export type GetDatasetPropertiesLazyQueryHookResult = ReturnType<typeof useGetDatasetPropertiesLazyQuery>;
export type GetDatasetPropertiesQueryResult = Apollo.QueryResult<GetDatasetPropertiesQuery, GetDatasetPropertiesQueryVariables>;
export const ListBackupsDocument = gql`
    query ListBackups {
  listBackups {
    backups {
      id
      createdAt
    }
  }
}
    `;

/**
 * __useListBackupsQuery__
 *
 * To run a query within a React component, call `useListBackupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBackupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBackupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListBackupsQuery(baseOptions?: Apollo.QueryHookOptions<ListBackupsQuery, ListBackupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBackupsQuery, ListBackupsQueryVariables>(ListBackupsDocument, options);
      }
export function useListBackupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBackupsQuery, ListBackupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBackupsQuery, ListBackupsQueryVariables>(ListBackupsDocument, options);
        }
export type ListBackupsQueryHookResult = ReturnType<typeof useListBackupsQuery>;
export type ListBackupsLazyQueryHookResult = ReturnType<typeof useListBackupsLazyQuery>;
export type ListBackupsQueryResult = Apollo.QueryResult<ListBackupsQuery, ListBackupsQueryVariables>;
export const UserWithDatasetsDocument = gql`
    query userWithDatasets($username: String!) {
  getUser(username: $username) {
    datasets {
      id
      name
      thumbnail
      description
      objectsAggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useUserWithDatasetsQuery__
 *
 * To run a query within a React component, call `useUserWithDatasetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithDatasetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithDatasetsQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserWithDatasetsQuery(baseOptions: Apollo.QueryHookOptions<UserWithDatasetsQuery, UserWithDatasetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWithDatasetsQuery, UserWithDatasetsQueryVariables>(UserWithDatasetsDocument, options);
      }
export function useUserWithDatasetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWithDatasetsQuery, UserWithDatasetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWithDatasetsQuery, UserWithDatasetsQueryVariables>(UserWithDatasetsDocument, options);
        }
export type UserWithDatasetsQueryHookResult = ReturnType<typeof useUserWithDatasetsQuery>;
export type UserWithDatasetsLazyQueryHookResult = ReturnType<typeof useUserWithDatasetsLazyQuery>;
export type UserWithDatasetsQueryResult = Apollo.QueryResult<UserWithDatasetsQuery, UserWithDatasetsQueryVariables>;
export const QueryFilteredFoldersAndResearchBoxIDsDocument = gql`
    query QueryFilteredFoldersAndResearchBoxIDs($input: QueryFilteredFoldersAndResearchBoxesInput!) {
  queryFilteredFoldersAndResearchBoxes(input: $input) {
    totalCount
    foldersAndResearchBoxIds
  }
}
    `;

/**
 * __useQueryFilteredFoldersAndResearchBoxIDsQuery__
 *
 * To run a query within a React component, call `useQueryFilteredFoldersAndResearchBoxIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFilteredFoldersAndResearchBoxIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFilteredFoldersAndResearchBoxIDsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFilteredFoldersAndResearchBoxIDsQuery(baseOptions: Apollo.QueryHookOptions<QueryFilteredFoldersAndResearchBoxIDsQuery, QueryFilteredFoldersAndResearchBoxIDsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryFilteredFoldersAndResearchBoxIDsQuery, QueryFilteredFoldersAndResearchBoxIDsQueryVariables>(QueryFilteredFoldersAndResearchBoxIDsDocument, options);
      }
export function useQueryFilteredFoldersAndResearchBoxIDsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryFilteredFoldersAndResearchBoxIDsQuery, QueryFilteredFoldersAndResearchBoxIDsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryFilteredFoldersAndResearchBoxIDsQuery, QueryFilteredFoldersAndResearchBoxIDsQueryVariables>(QueryFilteredFoldersAndResearchBoxIDsDocument, options);
        }
export type QueryFilteredFoldersAndResearchBoxIDsQueryHookResult = ReturnType<typeof useQueryFilteredFoldersAndResearchBoxIDsQuery>;
export type QueryFilteredFoldersAndResearchBoxIDsLazyQueryHookResult = ReturnType<typeof useQueryFilteredFoldersAndResearchBoxIDsLazyQuery>;
export type QueryFilteredFoldersAndResearchBoxIDsQueryResult = Apollo.QueryResult<QueryFilteredFoldersAndResearchBoxIDsQuery, QueryFilteredFoldersAndResearchBoxIDsQueryVariables>;
export const GetAllDenkBoxesDocument = gql`
    query getAllDenkBoxes {
  queryDenkbox {
    id
    dockerId
    gpus {
      maxMemory
      freeMemory
      usage
    }
    host
    jobs {
      id
    }
  }
}
    `;

/**
 * __useGetAllDenkBoxesQuery__
 *
 * To run a query within a React component, call `useGetAllDenkBoxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDenkBoxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDenkBoxesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDenkBoxesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDenkBoxesQuery, GetAllDenkBoxesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDenkBoxesQuery, GetAllDenkBoxesQueryVariables>(GetAllDenkBoxesDocument, options);
      }
export function useGetAllDenkBoxesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDenkBoxesQuery, GetAllDenkBoxesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDenkBoxesQuery, GetAllDenkBoxesQueryVariables>(GetAllDenkBoxesDocument, options);
        }
export type GetAllDenkBoxesQueryHookResult = ReturnType<typeof useGetAllDenkBoxesQuery>;
export type GetAllDenkBoxesLazyQueryHookResult = ReturnType<typeof useGetAllDenkBoxesLazyQuery>;
export type GetAllDenkBoxesQueryResult = Apollo.QueryResult<GetAllDenkBoxesQuery, GetAllDenkBoxesQueryVariables>;
export const GetUserByUsernameDocument = gql`
    query getUserByUsername($username: String!) {
  getUser(username: $username) {
    username
    createdAt
    email
    role
    name
    surname
    phone
    allowedFeatures
    blockedFeatures
    trainingQuota
    inferenceQuota
  }
}
    `;

/**
 * __useGetUserByUsernameQuery__
 *
 * To run a query within a React component, call `useGetUserByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserByUsernameQuery(baseOptions: Apollo.QueryHookOptions<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>(GetUserByUsernameDocument, options);
      }
export function useGetUserByUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>(GetUserByUsernameDocument, options);
        }
export type GetUserByUsernameQueryHookResult = ReturnType<typeof useGetUserByUsernameQuery>;
export type GetUserByUsernameLazyQueryHookResult = ReturnType<typeof useGetUserByUsernameLazyQuery>;
export type GetUserByUsernameQueryResult = Apollo.QueryResult<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>;
export const AllUsersDocument = gql`
    query allUsers {
  queryUser {
    username
    createdAt
    email
    role
    name
    surname
    phone
  }
}
    `;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const AllModulesDocument = gql`
    query allModules {
  queryPipelineModule {
    __typename
    id
    name
    createdAt
    updatedAt
    description
    owner
  }
}
    `;

/**
 * __useAllModulesQuery__
 *
 * To run a query within a React component, call `useAllModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllModulesQuery(baseOptions?: Apollo.QueryHookOptions<AllModulesQuery, AllModulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllModulesQuery, AllModulesQueryVariables>(AllModulesDocument, options);
      }
export function useAllModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllModulesQuery, AllModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllModulesQuery, AllModulesQueryVariables>(AllModulesDocument, options);
        }
export type AllModulesQueryHookResult = ReturnType<typeof useAllModulesQuery>;
export type AllModulesLazyQueryHookResult = ReturnType<typeof useAllModulesLazyQuery>;
export type AllModulesQueryResult = Apollo.QueryResult<AllModulesQuery, AllModulesQueryVariables>;
export const GetLastBtechResultDocument = gql`
    query GetLastBtechResult($datasetId: String!) {
  getDataset(id: $datasetId) {
    id
    objectsAggregate {
      count
    }
    objects(order: {desc: updatedAt}, first: 1, offset: 0, filter: {}) {
      ... on ResearchBox {
        id
        datapoints {
          id
          ... on CCCheckerResult {
            groups
            isOkay
          }
          ... on MagneticFieldScan {
            width
            height
            startIndices
            hasBaseline
            binaryURL
            updatedAt
          }
          producedBy {
            dataType
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetLastBtechResultQuery__
 *
 * To run a query within a React component, call `useGetLastBtechResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastBtechResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastBtechResultQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetLastBtechResultQuery(baseOptions: Apollo.QueryHookOptions<GetLastBtechResultQuery, GetLastBtechResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastBtechResultQuery, GetLastBtechResultQueryVariables>(GetLastBtechResultDocument, options);
      }
export function useGetLastBtechResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastBtechResultQuery, GetLastBtechResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastBtechResultQuery, GetLastBtechResultQueryVariables>(GetLastBtechResultDocument, options);
        }
export type GetLastBtechResultQueryHookResult = ReturnType<typeof useGetLastBtechResultQuery>;
export type GetLastBtechResultLazyQueryHookResult = ReturnType<typeof useGetLastBtechResultLazyQuery>;
export type GetLastBtechResultQueryResult = Apollo.QueryResult<GetLastBtechResultQuery, GetLastBtechResultQueryVariables>;
export const QueryImageMetadataDocument = gql`
    query QueryImageMetadata {
  queryImage {
    filename
    metadata {
      key
      value
      owner
    }
  }
}
    `;

/**
 * __useQueryImageMetadataQuery__
 *
 * To run a query within a React component, call `useQueryImageMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryImageMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryImageMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryImageMetadataQuery(baseOptions?: Apollo.QueryHookOptions<QueryImageMetadataQuery, QueryImageMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryImageMetadataQuery, QueryImageMetadataQueryVariables>(QueryImageMetadataDocument, options);
      }
export function useQueryImageMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryImageMetadataQuery, QueryImageMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryImageMetadataQuery, QueryImageMetadataQueryVariables>(QueryImageMetadataDocument, options);
        }
export type QueryImageMetadataQueryHookResult = ReturnType<typeof useQueryImageMetadataQuery>;
export type QueryImageMetadataLazyQueryHookResult = ReturnType<typeof useQueryImageMetadataLazyQuery>;
export type QueryImageMetadataQueryResult = Apollo.QueryResult<QueryImageMetadataQuery, QueryImageMetadataQueryVariables>;
export const GetDatapointDetailsDocument = gql`
    query getDatapointDetails($id: String!) {
  queryDatapoint(filter: {id: {eq: $id}}) {
    id
    createdAt
    updatedAt
    ... on Image {
      thumbnail
      resolutions {
        height
        width
        id
        binaryURL
      }
      binaryURL
      filename
      width
      height
    }
    ... on CCCheckerResult {
      groups
      isOkay
    }
    ... on MagneticFieldScan {
      width
      height
      startIndices
      hasBaseline
      binaryURL
    }
    researchBox {
      id
      name
    }
    producedBy {
      name
      dataType
      module {
        id
      }
    }
  }
}
    `;

/**
 * __useGetDatapointDetailsQuery__
 *
 * To run a query within a React component, call `useGetDatapointDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatapointDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatapointDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDatapointDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetDatapointDetailsQuery, GetDatapointDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatapointDetailsQuery, GetDatapointDetailsQueryVariables>(GetDatapointDetailsDocument, options);
      }
export function useGetDatapointDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatapointDetailsQuery, GetDatapointDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatapointDetailsQuery, GetDatapointDetailsQueryVariables>(GetDatapointDetailsDocument, options);
        }
export type GetDatapointDetailsQueryHookResult = ReturnType<typeof useGetDatapointDetailsQuery>;
export type GetDatapointDetailsLazyQueryHookResult = ReturnType<typeof useGetDatapointDetailsLazyQuery>;
export type GetDatapointDetailsQueryResult = Apollo.QueryResult<GetDatapointDetailsQuery, GetDatapointDetailsQueryVariables>;
export const AllMarkupModulesWithNetworksForDatasetDocument = gql`
    query AllMarkupModulesWithNetworksForDataset($datasetId: String!) {
  getDataset(id: $datasetId) {
    id
    pipeline {
      id
      modules {
        ... on PipelineModuleNetworkImageClassification {
          id
          name
          classLabels {
            id
            name
          }
        }
        ... on PipelineModuleNetworkImageSegmentation {
          id
          name
          classLabels {
            id
            name
          }
        }
        ... on PipelineModuleNetworkImageObjectDetection {
          id
          name
          classLabels {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAllMarkupModulesWithNetworksForDatasetQuery__
 *
 * To run a query within a React component, call `useAllMarkupModulesWithNetworksForDatasetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMarkupModulesWithNetworksForDatasetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMarkupModulesWithNetworksForDatasetQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useAllMarkupModulesWithNetworksForDatasetQuery(baseOptions: Apollo.QueryHookOptions<AllMarkupModulesWithNetworksForDatasetQuery, AllMarkupModulesWithNetworksForDatasetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllMarkupModulesWithNetworksForDatasetQuery, AllMarkupModulesWithNetworksForDatasetQueryVariables>(AllMarkupModulesWithNetworksForDatasetDocument, options);
      }
export function useAllMarkupModulesWithNetworksForDatasetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllMarkupModulesWithNetworksForDatasetQuery, AllMarkupModulesWithNetworksForDatasetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllMarkupModulesWithNetworksForDatasetQuery, AllMarkupModulesWithNetworksForDatasetQueryVariables>(AllMarkupModulesWithNetworksForDatasetDocument, options);
        }
export type AllMarkupModulesWithNetworksForDatasetQueryHookResult = ReturnType<typeof useAllMarkupModulesWithNetworksForDatasetQuery>;
export type AllMarkupModulesWithNetworksForDatasetLazyQueryHookResult = ReturnType<typeof useAllMarkupModulesWithNetworksForDatasetLazyQuery>;
export type AllMarkupModulesWithNetworksForDatasetQueryResult = Apollo.QueryResult<AllMarkupModulesWithNetworksForDatasetQuery, AllMarkupModulesWithNetworksForDatasetQueryVariables>;
export const FindDatasetByIdDocument = gql`
    query findDatasetById($datasetId: String!) {
  getDataset(id: $datasetId) {
    id
    name
    description
    objects {
      ... on ResearchBox {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useFindDatasetByIdQuery__
 *
 * To run a query within a React component, call `useFindDatasetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDatasetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDatasetByIdQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useFindDatasetByIdQuery(baseOptions: Apollo.QueryHookOptions<FindDatasetByIdQuery, FindDatasetByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindDatasetByIdQuery, FindDatasetByIdQueryVariables>(FindDatasetByIdDocument, options);
      }
export function useFindDatasetByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindDatasetByIdQuery, FindDatasetByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindDatasetByIdQuery, FindDatasetByIdQueryVariables>(FindDatasetByIdDocument, options);
        }
export type FindDatasetByIdQueryHookResult = ReturnType<typeof useFindDatasetByIdQuery>;
export type FindDatasetByIdLazyQueryHookResult = ReturnType<typeof useFindDatasetByIdLazyQuery>;
export type FindDatasetByIdQueryResult = Apollo.QueryResult<FindDatasetByIdQuery, FindDatasetByIdQueryVariables>;
export const GetDatasetNameDocument = gql`
    query GetDatasetName($datasetId: String!) {
  getDataset(id: $datasetId) {
    name
  }
}
    `;

/**
 * __useGetDatasetNameQuery__
 *
 * To run a query within a React component, call `useGetDatasetNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetNameQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetDatasetNameQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetNameQuery, GetDatasetNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetNameQuery, GetDatasetNameQueryVariables>(GetDatasetNameDocument, options);
      }
export function useGetDatasetNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetNameQuery, GetDatasetNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetNameQuery, GetDatasetNameQueryVariables>(GetDatasetNameDocument, options);
        }
export type GetDatasetNameQueryHookResult = ReturnType<typeof useGetDatasetNameQuery>;
export type GetDatasetNameLazyQueryHookResult = ReturnType<typeof useGetDatasetNameLazyQuery>;
export type GetDatasetNameQueryResult = Apollo.QueryResult<GetDatasetNameQuery, GetDatasetNameQueryVariables>;
export const QueryFilteredResearchBoxesDocument = gql`
    query queryFilteredResearchBoxes($filter: ResearchBoxFilter, $order: ResearchBoxOrder, $first: Int, $offset: Int) {
  aggregateResearchBox(filter: $filter) {
    count
  }
  queryResearchBox(filter: $filter, order: $order, first: $first, offset: $offset) {
    __typename
    id
    name
    createdAt
    updatedAt
    dataset {
      id
      name
    }
    parentIds
    owner
    containsMarkupForNetworkId
    containsPositiveMarkupForLabelId
    containsNegativeMarkupForLabelId
    datapoints {
      id
      createdAt
      updatedAt
      ... on Image {
        thumbnail
        binaryURL
        resolutions {
          height
          width
          id
          binaryURL
        }
        filename
        width
        height
        predictions {
          createdAt
          id
          __typename
          ... on SegmentationPrediction {
            segmentationMaps {
              thumbnail
              binaryURL
            }
          }
        }
        markups {
          id
          __typename
          ... on ClassificationMarkup {
            annotations {
              id
              label {
                name
                color
              }
              value
            }
          }
          ... on ObjectDetectionMarkup {
            annotations {
              id
              annotationType
              label {
                name
                color
              }
              topLeft {
                x
                y
              }
              bottomRight {
                x
                y
              }
            }
          }
          ... on SegmentationMarkup {
            segmentationMaps {
              thumbnail
              id
              label {
                id
                name
                color
              }
            }
            annotations {
              id
              __typename
              annotationType
              label {
                name
                color
              }
              ... on CircleAnnotation {
                center {
                  x
                  y
                }
                radius
              }
              ... on PolygonAnnotation {
                points {
                  idx
                  x
                  y
                }
              }
              ... on RectangleAnnotation {
                topLeft {
                  x
                  y
                }
                bottomRight {
                  x
                  y
                }
              }
              ... on PenAnnotation {
                points {
                  x
                  y
                }
              }
              ... on SausageAnnotation {
                points {
                  idx
                  x
                  y
                }
                radius
              }
              ... on MagicwandAnnotation {
                center {
                  x
                  y
                }
                threshold
              }
              ... on PixelAnnotation {
                topLeft {
                  x
                  y
                }
                bottomRight {
                  x
                  y
                }
                dataURL
              }
            }
          }
        }
      }
      ... on CCCheckerResult {
        groups
        isOkay
      }
      ... on MagneticFieldScan {
        width
        height
        startIndices
        hasBaseline
        binaryURL
      }
      producedBy {
        id
        dataType
        name
      }
    }
  }
}
    `;

/**
 * __useQueryFilteredResearchBoxesQuery__
 *
 * To run a query within a React component, call `useQueryFilteredResearchBoxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFilteredResearchBoxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFilteredResearchBoxesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useQueryFilteredResearchBoxesQuery(baseOptions?: Apollo.QueryHookOptions<QueryFilteredResearchBoxesQuery, QueryFilteredResearchBoxesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryFilteredResearchBoxesQuery, QueryFilteredResearchBoxesQueryVariables>(QueryFilteredResearchBoxesDocument, options);
      }
export function useQueryFilteredResearchBoxesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryFilteredResearchBoxesQuery, QueryFilteredResearchBoxesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryFilteredResearchBoxesQuery, QueryFilteredResearchBoxesQueryVariables>(QueryFilteredResearchBoxesDocument, options);
        }
export type QueryFilteredResearchBoxesQueryHookResult = ReturnType<typeof useQueryFilteredResearchBoxesQuery>;
export type QueryFilteredResearchBoxesLazyQueryHookResult = ReturnType<typeof useQueryFilteredResearchBoxesLazyQuery>;
export type QueryFilteredResearchBoxesQueryResult = Apollo.QueryResult<QueryFilteredResearchBoxesQuery, QueryFilteredResearchBoxesQueryVariables>;
export const QueryResearchBoxesDocument = gql`
    query queryResearchBoxes($ids: [String!]!) {
  queryResearchBox(filter: {id: {in: $ids}}) {
    __typename
    id
    name
    createdAt
    updatedAt
    dataset {
      id
      name
    }
    parentIds
    owner
    containsMarkupForNetworkId
    containsPositiveMarkupForLabelId
    containsNegativeMarkupForLabelId
    datapoints {
      id
      createdAt
      updatedAt
      ... on Image {
        thumbnail
        binaryURL
        resolutions {
          height
          width
          id
          binaryURL
        }
        filename
        width
        height
        predictions {
          createdAt
          id
          __typename
          ... on SegmentationPrediction {
            segmentationMaps {
              thumbnail
              binaryURL
            }
          }
        }
        markups {
          id
          __typename
          ... on ClassificationMarkup {
            annotations {
              id
              label {
                name
                color
              }
              value
            }
          }
          ... on ObjectDetectionMarkup {
            annotations {
              id
              annotationType
              label {
                name
                color
              }
              topLeft {
                x
                y
              }
              bottomRight {
                x
                y
              }
            }
          }
          ... on SegmentationMarkup {
            segmentationMaps {
              thumbnail
              id
              label {
                id
                name
                color
              }
            }
            annotations {
              id
              __typename
              annotationType
              label {
                name
                color
              }
              ... on CircleAnnotation {
                center {
                  x
                  y
                }
                radius
              }
              ... on PolygonAnnotation {
                points {
                  idx
                  x
                  y
                }
              }
              ... on RectangleAnnotation {
                topLeft {
                  x
                  y
                }
                bottomRight {
                  x
                  y
                }
              }
              ... on PenAnnotation {
                points {
                  x
                  y
                }
              }
              ... on SausageAnnotation {
                points {
                  idx
                  x
                  y
                }
                radius
              }
              ... on MagicwandAnnotation {
                center {
                  x
                  y
                }
                threshold
              }
              ... on PixelAnnotation {
                topLeft {
                  x
                  y
                }
                bottomRight {
                  x
                  y
                }
                dataURL
              }
            }
          }
        }
      }
      ... on CCCheckerResult {
        groups
        isOkay
      }
      ... on MagneticFieldScan {
        width
        height
        startIndices
        hasBaseline
        binaryURL
      }
      producedBy {
        id
        dataType
        name
      }
    }
  }
}
    `;

/**
 * __useQueryResearchBoxesQuery__
 *
 * To run a query within a React component, call `useQueryResearchBoxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryResearchBoxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryResearchBoxesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useQueryResearchBoxesQuery(baseOptions: Apollo.QueryHookOptions<QueryResearchBoxesQuery, QueryResearchBoxesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryResearchBoxesQuery, QueryResearchBoxesQueryVariables>(QueryResearchBoxesDocument, options);
      }
export function useQueryResearchBoxesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryResearchBoxesQuery, QueryResearchBoxesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryResearchBoxesQuery, QueryResearchBoxesQueryVariables>(QueryResearchBoxesDocument, options);
        }
export type QueryResearchBoxesQueryHookResult = ReturnType<typeof useQueryResearchBoxesQuery>;
export type QueryResearchBoxesLazyQueryHookResult = ReturnType<typeof useQueryResearchBoxesLazyQuery>;
export type QueryResearchBoxesQueryResult = Apollo.QueryResult<QueryResearchBoxesQuery, QueryResearchBoxesQueryVariables>;
export const QueryFilteredResearchBoxIdsDocument = gql`
    query queryFilteredResearchBoxIds($filter: ResearchBoxFilter) {
  queryResearchBox(filter: $filter) {
    id
  }
}
    `;

/**
 * __useQueryFilteredResearchBoxIdsQuery__
 *
 * To run a query within a React component, call `useQueryFilteredResearchBoxIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFilteredResearchBoxIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFilteredResearchBoxIdsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryFilteredResearchBoxIdsQuery(baseOptions?: Apollo.QueryHookOptions<QueryFilteredResearchBoxIdsQuery, QueryFilteredResearchBoxIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryFilteredResearchBoxIdsQuery, QueryFilteredResearchBoxIdsQueryVariables>(QueryFilteredResearchBoxIdsDocument, options);
      }
export function useQueryFilteredResearchBoxIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryFilteredResearchBoxIdsQuery, QueryFilteredResearchBoxIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryFilteredResearchBoxIdsQuery, QueryFilteredResearchBoxIdsQueryVariables>(QueryFilteredResearchBoxIdsDocument, options);
        }
export type QueryFilteredResearchBoxIdsQueryHookResult = ReturnType<typeof useQueryFilteredResearchBoxIdsQuery>;
export type QueryFilteredResearchBoxIdsLazyQueryHookResult = ReturnType<typeof useQueryFilteredResearchBoxIdsLazyQuery>;
export type QueryFilteredResearchBoxIdsQueryResult = Apollo.QueryResult<QueryFilteredResearchBoxIdsQuery, QueryFilteredResearchBoxIdsQueryVariables>;
export const QueryFilteredFoldersOrResearchBoxesDocument = gql`
    query queryFilteredFoldersOrResearchBoxes($filter: FolderOrResearchBoxFilter, $order: FolderOrResearchBoxOrder, $first: Int, $offset: Int) {
  aggregateFolderOrResearchBox(filter: $filter) {
    count
  }
  queryFolderOrResearchBox(filter: $filter, order: $order, first: $first, offset: $offset) {
    __typename
    id
    name
    createdAt
    parentIds
    ... on Folder {
      description
      state
      objects(filter: {typeForSorting: {eq: "ResearchBox"}}, first: 4) {
        ... on ResearchBox {
          datapoints(first: 1) {
            ... on Image {
              thumbnail
            }
          }
        }
      }
    }
    ... on ResearchBox {
      updatedAt
      dataset {
        id
        name
      }
      containsMarkupForNetworkId
      containsPositiveMarkupForLabelId
      containsNegativeMarkupForLabelId
      owner
      datapoints {
        id
        createdAt
        updatedAt
        ... on Image {
          thumbnail
          binaryURL
          resolutions {
            height
            width
            id
            binaryURL
          }
          filename
          width
          height
          predictions {
            createdAt
            id
            __typename
            ... on SegmentationPrediction {
              segmentationMaps {
                thumbnail
                binaryURL
              }
            }
          }
          markups {
            id
            __typename
            ... on ClassificationMarkup {
              annotations {
                id
                label {
                  name
                  color
                }
                value
              }
            }
            ... on ObjectDetectionMarkup {
              annotations {
                id
                annotationType
                label {
                  name
                  color
                }
                topLeft {
                  x
                  y
                }
                bottomRight {
                  x
                  y
                }
              }
            }
            ... on SegmentationMarkup {
              segmentationMaps {
                thumbnail
                id
                label {
                  id
                  name
                  color
                }
              }
              annotations {
                id
                __typename
                annotationType
                label {
                  name
                  color
                }
                ... on CircleAnnotation {
                  center {
                    x
                    y
                  }
                  radius
                }
                ... on PolygonAnnotation {
                  points {
                    idx
                    x
                    y
                  }
                }
                ... on RectangleAnnotation {
                  topLeft {
                    x
                    y
                  }
                  bottomRight {
                    x
                    y
                  }
                }
                ... on PenAnnotation {
                  points {
                    x
                    y
                  }
                }
                ... on SausageAnnotation {
                  points {
                    idx
                    x
                    y
                  }
                  radius
                }
                ... on MagicwandAnnotation {
                  center {
                    x
                    y
                  }
                  threshold
                }
                ... on PixelAnnotation {
                  topLeft {
                    x
                    y
                  }
                  bottomRight {
                    x
                    y
                  }
                  dataURL
                }
              }
            }
          }
        }
        ... on CCCheckerResult {
          groups
          isOkay
        }
        ... on MagneticFieldScan {
          width
          height
          startIndices
          hasBaseline
          binaryURL
        }
        producedBy {
          id
          dataType
          name
        }
      }
    }
  }
}
    `;

/**
 * __useQueryFilteredFoldersOrResearchBoxesQuery__
 *
 * To run a query within a React component, call `useQueryFilteredFoldersOrResearchBoxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFilteredFoldersOrResearchBoxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFilteredFoldersOrResearchBoxesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useQueryFilteredFoldersOrResearchBoxesQuery(baseOptions?: Apollo.QueryHookOptions<QueryFilteredFoldersOrResearchBoxesQuery, QueryFilteredFoldersOrResearchBoxesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryFilteredFoldersOrResearchBoxesQuery, QueryFilteredFoldersOrResearchBoxesQueryVariables>(QueryFilteredFoldersOrResearchBoxesDocument, options);
      }
export function useQueryFilteredFoldersOrResearchBoxesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryFilteredFoldersOrResearchBoxesQuery, QueryFilteredFoldersOrResearchBoxesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryFilteredFoldersOrResearchBoxesQuery, QueryFilteredFoldersOrResearchBoxesQueryVariables>(QueryFilteredFoldersOrResearchBoxesDocument, options);
        }
export type QueryFilteredFoldersOrResearchBoxesQueryHookResult = ReturnType<typeof useQueryFilteredFoldersOrResearchBoxesQuery>;
export type QueryFilteredFoldersOrResearchBoxesLazyQueryHookResult = ReturnType<typeof useQueryFilteredFoldersOrResearchBoxesLazyQuery>;
export type QueryFilteredFoldersOrResearchBoxesQueryResult = Apollo.QueryResult<QueryFilteredFoldersOrResearchBoxesQuery, QueryFilteredFoldersOrResearchBoxesQueryVariables>;
export const CheckResearchBoxLabelDocument = gql`
    query CheckResearchBoxLabel($label: String!) {
  aggregateResearchBox(filter: {name: {eq: $label}}) {
    count
  }
}
    `;

/**
 * __useCheckResearchBoxLabelQuery__
 *
 * To run a query within a React component, call `useCheckResearchBoxLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckResearchBoxLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckResearchBoxLabelQuery({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useCheckResearchBoxLabelQuery(baseOptions: Apollo.QueryHookOptions<CheckResearchBoxLabelQuery, CheckResearchBoxLabelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckResearchBoxLabelQuery, CheckResearchBoxLabelQueryVariables>(CheckResearchBoxLabelDocument, options);
      }
export function useCheckResearchBoxLabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckResearchBoxLabelQuery, CheckResearchBoxLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckResearchBoxLabelQuery, CheckResearchBoxLabelQueryVariables>(CheckResearchBoxLabelDocument, options);
        }
export type CheckResearchBoxLabelQueryHookResult = ReturnType<typeof useCheckResearchBoxLabelQuery>;
export type CheckResearchBoxLabelLazyQueryHookResult = ReturnType<typeof useCheckResearchBoxLabelLazyQuery>;
export type CheckResearchBoxLabelQueryResult = Apollo.QueryResult<CheckResearchBoxLabelQuery, CheckResearchBoxLabelQueryVariables>;
export const ResearchBoxesByDatasetIdDocument = gql`
    query ResearchBoxesByDatasetId($datasetIdRegexp: String!) {
  queryResearchBox(filter: {parentIds: {regexp: $datasetIdRegexp}}, first: 1) {
    id
    name
    createdAt
    updatedAt
    dataset {
      id
      name
    }
    parentIds
    owner
    datapoints {
      id
      createdAt
      updatedAt
      ... on Image {
        thumbnail
        binaryURL
        resolutions {
          height
          width
          id
          binaryURL
        }
        filename
        width
        height
        predictions {
          createdAt
          id
          __typename
          ... on SegmentationPrediction {
            segmentationMaps {
              thumbnail
              binaryURL
            }
          }
        }
        markups {
          id
          __typename
          ... on ClassificationMarkup {
            annotations {
              id
              label {
                name
                color
              }
              value
            }
          }
          ... on ObjectDetectionMarkup {
            annotations {
              id
              annotationType
              label {
                name
                color
              }
              topLeft {
                x
                y
              }
              bottomRight {
                x
                y
              }
            }
          }
          ... on SegmentationMarkup {
            segmentationMaps {
              thumbnail
              id
              label {
                id
                name
                color
              }
            }
            annotations {
              id
              __typename
              annotationType
              label {
                name
                color
              }
              ... on CircleAnnotation {
                center {
                  x
                  y
                }
                radius
              }
              ... on PolygonAnnotation {
                points {
                  idx
                  x
                  y
                }
              }
              ... on RectangleAnnotation {
                topLeft {
                  x
                  y
                }
                bottomRight {
                  x
                  y
                }
              }
              ... on PenAnnotation {
                points {
                  x
                  y
                }
              }
              ... on SausageAnnotation {
                points {
                  idx
                  x
                  y
                }
                radius
              }
              ... on MagicwandAnnotation {
                center {
                  x
                  y
                }
                threshold
              }
              ... on PixelAnnotation {
                topLeft {
                  x
                  y
                }
                bottomRight {
                  x
                  y
                }
                dataURL
              }
            }
          }
        }
      }
      ... on CCCheckerResult {
        groups
        isOkay
      }
      ... on MagneticFieldScan {
        width
        height
        startIndices
        hasBaseline
        binaryURL
      }
      producedBy {
        id
        dataType
        name
      }
    }
  }
}
    `;

/**
 * __useResearchBoxesByDatasetIdQuery__
 *
 * To run a query within a React component, call `useResearchBoxesByDatasetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useResearchBoxesByDatasetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResearchBoxesByDatasetIdQuery({
 *   variables: {
 *      datasetIdRegexp: // value for 'datasetIdRegexp'
 *   },
 * });
 */
export function useResearchBoxesByDatasetIdQuery(baseOptions: Apollo.QueryHookOptions<ResearchBoxesByDatasetIdQuery, ResearchBoxesByDatasetIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResearchBoxesByDatasetIdQuery, ResearchBoxesByDatasetIdQueryVariables>(ResearchBoxesByDatasetIdDocument, options);
      }
export function useResearchBoxesByDatasetIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResearchBoxesByDatasetIdQuery, ResearchBoxesByDatasetIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResearchBoxesByDatasetIdQuery, ResearchBoxesByDatasetIdQueryVariables>(ResearchBoxesByDatasetIdDocument, options);
        }
export type ResearchBoxesByDatasetIdQueryHookResult = ReturnType<typeof useResearchBoxesByDatasetIdQuery>;
export type ResearchBoxesByDatasetIdLazyQueryHookResult = ReturnType<typeof useResearchBoxesByDatasetIdLazyQuery>;
export type ResearchBoxesByDatasetIdQueryResult = Apollo.QueryResult<ResearchBoxesByDatasetIdQuery, ResearchBoxesByDatasetIdQueryVariables>;
export const GetPipelineDocument = gql`
    query GetPipeline($datasetId: String!) {
  getDataset(id: $datasetId) {
    owner
    pipeline {
      id
      modules {
        __typename
        id
        createdAt
        updatedAt
        name
        description
        owner
        inputs {
          id
          name
        }
        outputs {
          id
          name
        }
        layoutPosition {
          x
          y
        }
      }
    }
  }
}
    `;

/**
 * __useGetPipelineQuery__
 *
 * To run a query within a React component, call `useGetPipelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetPipelineQuery(baseOptions: Apollo.QueryHookOptions<GetPipelineQuery, GetPipelineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineQuery, GetPipelineQueryVariables>(GetPipelineDocument, options);
      }
export function useGetPipelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineQuery, GetPipelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineQuery, GetPipelineQueryVariables>(GetPipelineDocument, options);
        }
export type GetPipelineQueryHookResult = ReturnType<typeof useGetPipelineQuery>;
export type GetPipelineLazyQueryHookResult = ReturnType<typeof useGetPipelineLazyQuery>;
export type GetPipelineQueryResult = Apollo.QueryResult<GetPipelineQuery, GetPipelineQueryVariables>;
export const SubscribePipelineDocument = gql`
    subscription SubscribePipeline($datasetId: String!) {
  getDataset(id: $datasetId) {
    id
    pipeline {
      id
      modules {
        __typename
        id
        createdAt
        updatedAt
        name
        description
        owner
        inputs {
          id
          name
        }
        outputs {
          id
          name
        }
        layoutPosition {
          x
          y
        }
      }
      edges {
        id
        from {
          id
          module {
            id
          }
        }
        to {
          id
          module {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSubscribePipelineSubscription__
 *
 * To run a query within a React component, call `useSubscribePipelineSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribePipelineSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribePipelineSubscription({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useSubscribePipelineSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribePipelineSubscription, SubscribePipelineSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribePipelineSubscription, SubscribePipelineSubscriptionVariables>(SubscribePipelineDocument, options);
      }
export type SubscribePipelineSubscriptionHookResult = ReturnType<typeof useSubscribePipelineSubscription>;
export type SubscribePipelineSubscriptionResult = Apollo.SubscriptionResult<SubscribePipelineSubscription>;
export const GetPipelineModuleDocument = gql`
    query getPipelineModule($id: String!) {
  queryPipelineModule(filter: {id: {eq: $id}}) {
    __typename
    id
    createdAt
    updatedAt
    owner
    name
    pipeline {
      id
    }
    description
    inputs {
      id
      name
      edges {
        id
        from {
          id
        }
        to {
          id
        }
      }
    }
    outputs {
      id
      name
      edges {
        id
        from {
          id
        }
        to {
          id
        }
      }
    }
    layoutPosition {
      x
      y
    }
  }
}
    `;

/**
 * __useGetPipelineModuleQuery__
 *
 * To run a query within a React component, call `useGetPipelineModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineModuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPipelineModuleQuery(baseOptions: Apollo.QueryHookOptions<GetPipelineModuleQuery, GetPipelineModuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineModuleQuery, GetPipelineModuleQueryVariables>(GetPipelineModuleDocument, options);
      }
export function useGetPipelineModuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineModuleQuery, GetPipelineModuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineModuleQuery, GetPipelineModuleQueryVariables>(GetPipelineModuleDocument, options);
        }
export type GetPipelineModuleQueryHookResult = ReturnType<typeof useGetPipelineModuleQuery>;
export type GetPipelineModuleLazyQueryHookResult = ReturnType<typeof useGetPipelineModuleLazyQuery>;
export type GetPipelineModuleQueryResult = Apollo.QueryResult<GetPipelineModuleQuery, GetPipelineModuleQueryVariables>;
export const GetAllNamesAndDatatypesForDatasetDocument = gql`
    query GetAllNamesAndDatatypesForDataset($datasetId: String!) {
  getDataset(id: $datasetId) {
    id
    pipeline {
      id
      modules {
        id
        outputs {
          id
          name
          dataType
          ... on PipelineModuleDatapointOutput {
            datapoints(first: 1) {
              __typename
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllNamesAndDatatypesForDatasetQuery__
 *
 * To run a query within a React component, call `useGetAllNamesAndDatatypesForDatasetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNamesAndDatatypesForDatasetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNamesAndDatatypesForDatasetQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetAllNamesAndDatatypesForDatasetQuery(baseOptions: Apollo.QueryHookOptions<GetAllNamesAndDatatypesForDatasetQuery, GetAllNamesAndDatatypesForDatasetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllNamesAndDatatypesForDatasetQuery, GetAllNamesAndDatatypesForDatasetQueryVariables>(GetAllNamesAndDatatypesForDatasetDocument, options);
      }
export function useGetAllNamesAndDatatypesForDatasetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllNamesAndDatatypesForDatasetQuery, GetAllNamesAndDatatypesForDatasetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllNamesAndDatatypesForDatasetQuery, GetAllNamesAndDatatypesForDatasetQueryVariables>(GetAllNamesAndDatatypesForDatasetDocument, options);
        }
export type GetAllNamesAndDatatypesForDatasetQueryHookResult = ReturnType<typeof useGetAllNamesAndDatatypesForDatasetQuery>;
export type GetAllNamesAndDatatypesForDatasetLazyQueryHookResult = ReturnType<typeof useGetAllNamesAndDatatypesForDatasetLazyQuery>;
export type GetAllNamesAndDatatypesForDatasetQueryResult = Apollo.QueryResult<GetAllNamesAndDatatypesForDatasetQuery, GetAllNamesAndDatatypesForDatasetQueryVariables>;
export const GetPretrainedNetworkDocument = gql`
    query GetPretrainedNetwork($networkId: String) {
  getPretrainedNetwork(id: $networkId) {
    __typename
    id
    createdAt
    updatedAt
    thumbnail
    networkModule {
      classLabels {
        id
        name
        color
      }
      ... on PipelineModule {
        __typename
        id
        name
        createdAt
        updatedAt
        description
        owner
      }
    }
  }
}
    `;

/**
 * __useGetPretrainedNetworkQuery__
 *
 * To run a query within a React component, call `useGetPretrainedNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPretrainedNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPretrainedNetworkQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useGetPretrainedNetworkQuery(baseOptions?: Apollo.QueryHookOptions<GetPretrainedNetworkQuery, GetPretrainedNetworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPretrainedNetworkQuery, GetPretrainedNetworkQueryVariables>(GetPretrainedNetworkDocument, options);
      }
export function useGetPretrainedNetworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPretrainedNetworkQuery, GetPretrainedNetworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPretrainedNetworkQuery, GetPretrainedNetworkQueryVariables>(GetPretrainedNetworkDocument, options);
        }
export type GetPretrainedNetworkQueryHookResult = ReturnType<typeof useGetPretrainedNetworkQuery>;
export type GetPretrainedNetworkLazyQueryHookResult = ReturnType<typeof useGetPretrainedNetworkLazyQuery>;
export type GetPretrainedNetworkQueryResult = Apollo.QueryResult<GetPretrainedNetworkQuery, GetPretrainedNetworkQueryVariables>;
export const GetPretrainedNetworkForModuleDocument = gql`
    query GetPretrainedNetworkForModule($pipelineModuleId: String) {
  queryPretrainedNetwork @cascade(fields: ["networkModule"]) {
    __typename
    id
    createdAt
    updatedAt
    thumbnail
    networkModule(filter: {networkModuleId: {eq: $pipelineModuleId}}) {
      classLabels {
        id
        name
        color
      }
      ... on PipelineModule {
        __typename
        id
        name
        createdAt
        updatedAt
        description
        owner
      }
    }
  }
}
    `;

/**
 * __useGetPretrainedNetworkForModuleQuery__
 *
 * To run a query within a React component, call `useGetPretrainedNetworkForModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPretrainedNetworkForModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPretrainedNetworkForModuleQuery({
 *   variables: {
 *      pipelineModuleId: // value for 'pipelineModuleId'
 *   },
 * });
 */
export function useGetPretrainedNetworkForModuleQuery(baseOptions?: Apollo.QueryHookOptions<GetPretrainedNetworkForModuleQuery, GetPretrainedNetworkForModuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPretrainedNetworkForModuleQuery, GetPretrainedNetworkForModuleQueryVariables>(GetPretrainedNetworkForModuleDocument, options);
      }
export function useGetPretrainedNetworkForModuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPretrainedNetworkForModuleQuery, GetPretrainedNetworkForModuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPretrainedNetworkForModuleQuery, GetPretrainedNetworkForModuleQueryVariables>(GetPretrainedNetworkForModuleDocument, options);
        }
export type GetPretrainedNetworkForModuleQueryHookResult = ReturnType<typeof useGetPretrainedNetworkForModuleQuery>;
export type GetPretrainedNetworkForModuleLazyQueryHookResult = ReturnType<typeof useGetPretrainedNetworkForModuleLazyQuery>;
export type GetPretrainedNetworkForModuleQueryResult = Apollo.QueryResult<GetPretrainedNetworkForModuleQuery, GetPretrainedNetworkForModuleQueryVariables>;
export const GetDatasetIdByUsernameDocument = gql`
    query GetDatasetIdByUsername($username: String!) {
  getUser(username: $username) {
    username
    datasets {
      id
    }
  }
}
    `;

/**
 * __useGetDatasetIdByUsernameQuery__
 *
 * To run a query within a React component, call `useGetDatasetIdByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetIdByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetIdByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetDatasetIdByUsernameQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetIdByUsernameQuery, GetDatasetIdByUsernameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetIdByUsernameQuery, GetDatasetIdByUsernameQueryVariables>(GetDatasetIdByUsernameDocument, options);
      }
export function useGetDatasetIdByUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetIdByUsernameQuery, GetDatasetIdByUsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetIdByUsernameQuery, GetDatasetIdByUsernameQueryVariables>(GetDatasetIdByUsernameDocument, options);
        }
export type GetDatasetIdByUsernameQueryHookResult = ReturnType<typeof useGetDatasetIdByUsernameQuery>;
export type GetDatasetIdByUsernameLazyQueryHookResult = ReturnType<typeof useGetDatasetIdByUsernameLazyQuery>;
export type GetDatasetIdByUsernameQueryResult = Apollo.QueryResult<GetDatasetIdByUsernameQuery, GetDatasetIdByUsernameQueryVariables>;
export const QueryNetworksForUserHalmDocument = gql`
    query QueryNetworksForUserHalm($username: String!) {
  getUser(username: $username) {
    datasets(first: 1) {
      id
      pipeline {
        id
        modules {
          ... on PipelineModuleNetworkImageSegmentation {
            id
            name
            description
          }
          ... on PipelineModuleNetworkImageClassification {
            id
            name
            description
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQueryNetworksForUserHalmQuery__
 *
 * To run a query within a React component, call `useQueryNetworksForUserHalmQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryNetworksForUserHalmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryNetworksForUserHalmQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useQueryNetworksForUserHalmQuery(baseOptions: Apollo.QueryHookOptions<QueryNetworksForUserHalmQuery, QueryNetworksForUserHalmQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryNetworksForUserHalmQuery, QueryNetworksForUserHalmQueryVariables>(QueryNetworksForUserHalmDocument, options);
      }
export function useQueryNetworksForUserHalmLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryNetworksForUserHalmQuery, QueryNetworksForUserHalmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryNetworksForUserHalmQuery, QueryNetworksForUserHalmQueryVariables>(QueryNetworksForUserHalmDocument, options);
        }
export type QueryNetworksForUserHalmQueryHookResult = ReturnType<typeof useQueryNetworksForUserHalmQuery>;
export type QueryNetworksForUserHalmLazyQueryHookResult = ReturnType<typeof useQueryNetworksForUserHalmLazyQuery>;
export type QueryNetworksForUserHalmQueryResult = Apollo.QueryResult<QueryNetworksForUserHalmQuery, QueryNetworksForUserHalmQueryVariables>;
export const AllMarkupModulesDocument = gql`
    query allMarkupModules($pipelineId: String!) {
  getPipeline(id: $pipelineId) {
    modules {
      ... on PipelineModuleNetworkImageClassification {
        id
        name
        description
        inputs {
          id
          name
          dataType
          edges {
            from {
              id
              name
              dataType
              module {
                id
              }
            }
          }
        }
        outputs {
          id
          name
          dataType
          edges {
            to {
              id
              name
              dataType
              module {
                id
              }
            }
          }
        }
      }
      ... on PipelineModuleNetworkImageSegmentation {
        id
        name
        description
        inputs {
          id
          name
          dataType
          edges {
            from {
              id
              name
              dataType
              module {
                id
              }
            }
          }
        }
        outputs {
          id
          name
          dataType
          edges {
            to {
              id
              name
              dataType
              module {
                id
              }
            }
          }
        }
      }
      ... on PipelineModuleNetworkImageObjectDetection {
        id
        name
        description
        inputs {
          id
          name
          dataType
          edges {
            from {
              id
              name
              dataType
              module {
                id
              }
            }
          }
        }
        outputs {
          id
          name
          dataType
          edges {
            to {
              id
              name
              dataType
              module {
                id
              }
            }
          }
        }
      }
      ... on PipelineModuleNetworkImageInstanceSegmentation {
        id
        name
        description
        inputs {
          id
          name
          dataType
          edges {
            from {
              id
              name
              dataType
              module {
                id
              }
            }
          }
        }
        outputs {
          id
          name
          dataType
          edges {
            to {
              id
              name
              dataType
              module {
                id
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAllMarkupModulesQuery__
 *
 * To run a query within a React component, call `useAllMarkupModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMarkupModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMarkupModulesQuery({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useAllMarkupModulesQuery(baseOptions: Apollo.QueryHookOptions<AllMarkupModulesQuery, AllMarkupModulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllMarkupModulesQuery, AllMarkupModulesQueryVariables>(AllMarkupModulesDocument, options);
      }
export function useAllMarkupModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllMarkupModulesQuery, AllMarkupModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllMarkupModulesQuery, AllMarkupModulesQueryVariables>(AllMarkupModulesDocument, options);
        }
export type AllMarkupModulesQueryHookResult = ReturnType<typeof useAllMarkupModulesQuery>;
export type AllMarkupModulesLazyQueryHookResult = ReturnType<typeof useAllMarkupModulesLazyQuery>;
export type AllMarkupModulesQueryResult = Apollo.QueryResult<AllMarkupModulesQuery, AllMarkupModulesQueryVariables>;
export const FindpipelineIdByDatasetIdDocument = gql`
    query findpipelineIdByDatasetId($datasetId: String!) {
  getDataset(id: $datasetId) {
    id
    pipeline {
      id
    }
  }
}
    `;

/**
 * __useFindpipelineIdByDatasetIdQuery__
 *
 * To run a query within a React component, call `useFindpipelineIdByDatasetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindpipelineIdByDatasetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindpipelineIdByDatasetIdQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useFindpipelineIdByDatasetIdQuery(baseOptions: Apollo.QueryHookOptions<FindpipelineIdByDatasetIdQuery, FindpipelineIdByDatasetIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindpipelineIdByDatasetIdQuery, FindpipelineIdByDatasetIdQueryVariables>(FindpipelineIdByDatasetIdDocument, options);
      }
export function useFindpipelineIdByDatasetIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindpipelineIdByDatasetIdQuery, FindpipelineIdByDatasetIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindpipelineIdByDatasetIdQuery, FindpipelineIdByDatasetIdQueryVariables>(FindpipelineIdByDatasetIdDocument, options);
        }
export type FindpipelineIdByDatasetIdQueryHookResult = ReturnType<typeof useFindpipelineIdByDatasetIdQuery>;
export type FindpipelineIdByDatasetIdLazyQueryHookResult = ReturnType<typeof useFindpipelineIdByDatasetIdLazyQuery>;
export type FindpipelineIdByDatasetIdQueryResult = Apollo.QueryResult<FindpipelineIdByDatasetIdQuery, FindpipelineIdByDatasetIdQueryVariables>;
export const FindDatasetIdByPipelineIdDocument = gql`
    query findDatasetIdByPipelineId($pipelineId: String!) {
  getPipeline(id: $pipelineId) {
    id
    dataset {
      id
    }
  }
}
    `;

/**
 * __useFindDatasetIdByPipelineIdQuery__
 *
 * To run a query within a React component, call `useFindDatasetIdByPipelineIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDatasetIdByPipelineIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDatasetIdByPipelineIdQuery({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useFindDatasetIdByPipelineIdQuery(baseOptions: Apollo.QueryHookOptions<FindDatasetIdByPipelineIdQuery, FindDatasetIdByPipelineIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindDatasetIdByPipelineIdQuery, FindDatasetIdByPipelineIdQueryVariables>(FindDatasetIdByPipelineIdDocument, options);
      }
export function useFindDatasetIdByPipelineIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindDatasetIdByPipelineIdQuery, FindDatasetIdByPipelineIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindDatasetIdByPipelineIdQuery, FindDatasetIdByPipelineIdQueryVariables>(FindDatasetIdByPipelineIdDocument, options);
        }
export type FindDatasetIdByPipelineIdQueryHookResult = ReturnType<typeof useFindDatasetIdByPipelineIdQuery>;
export type FindDatasetIdByPipelineIdLazyQueryHookResult = ReturnType<typeof useFindDatasetIdByPipelineIdLazyQuery>;
export type FindDatasetIdByPipelineIdQueryResult = Apollo.QueryResult<FindDatasetIdByPipelineIdQuery, FindDatasetIdByPipelineIdQueryVariables>;
export const QueryUnreadNotificationsDocument = gql`
    query queryUnreadNotifications {
  queryNotification {
    __typename
    id
    createdAt
    updatedAt
    owner
    title
    message
    error
    read
    ... on ProgressNotification {
      currentProgress
      maxProgress
    }
    ... on MaintenanceNotification {
      finished
      type
    }
    ... on DenktesterNotification {
      currentProgress
      maxProgress
    }
  }
}
    `;

/**
 * __useQueryUnreadNotificationsQuery__
 *
 * To run a query within a React component, call `useQueryUnreadNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryUnreadNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryUnreadNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryUnreadNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<QueryUnreadNotificationsQuery, QueryUnreadNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryUnreadNotificationsQuery, QueryUnreadNotificationsQueryVariables>(QueryUnreadNotificationsDocument, options);
      }
export function useQueryUnreadNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryUnreadNotificationsQuery, QueryUnreadNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryUnreadNotificationsQuery, QueryUnreadNotificationsQueryVariables>(QueryUnreadNotificationsDocument, options);
        }
export type QueryUnreadNotificationsQueryHookResult = ReturnType<typeof useQueryUnreadNotificationsQuery>;
export type QueryUnreadNotificationsLazyQueryHookResult = ReturnType<typeof useQueryUnreadNotificationsLazyQuery>;
export type QueryUnreadNotificationsQueryResult = Apollo.QueryResult<QueryUnreadNotificationsQuery, QueryUnreadNotificationsQueryVariables>;
export const QueryBuildInfoDocument = gql`
    query QueryBuildInfo {
  queryBuildInfo {
    gitCommitHash
    versionTag
    buildDate
  }
}
    `;

/**
 * __useQueryBuildInfoQuery__
 *
 * To run a query within a React component, call `useQueryBuildInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBuildInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBuildInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryBuildInfoQuery(baseOptions?: Apollo.QueryHookOptions<QueryBuildInfoQuery, QueryBuildInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryBuildInfoQuery, QueryBuildInfoQueryVariables>(QueryBuildInfoDocument, options);
      }
export function useQueryBuildInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryBuildInfoQuery, QueryBuildInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryBuildInfoQuery, QueryBuildInfoQueryVariables>(QueryBuildInfoDocument, options);
        }
export type QueryBuildInfoQueryHookResult = ReturnType<typeof useQueryBuildInfoQuery>;
export type QueryBuildInfoLazyQueryHookResult = ReturnType<typeof useQueryBuildInfoLazyQuery>;
export type QueryBuildInfoQueryResult = Apollo.QueryResult<QueryBuildInfoQuery, QueryBuildInfoQueryVariables>;
export const QueryDenkTesterProgressDocument = gql`
    query queryDenkTesterProgress($networkId: String!) {
  queryDenktesterNotification(filter: {networkId: {eq: $networkId}}) {
    currentProgress
    maxProgress
    updatedAt
  }
}
    `;

/**
 * __useQueryDenkTesterProgressQuery__
 *
 * To run a query within a React component, call `useQueryDenkTesterProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryDenkTesterProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryDenkTesterProgressQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useQueryDenkTesterProgressQuery(baseOptions: Apollo.QueryHookOptions<QueryDenkTesterProgressQuery, QueryDenkTesterProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryDenkTesterProgressQuery, QueryDenkTesterProgressQueryVariables>(QueryDenkTesterProgressDocument, options);
      }
export function useQueryDenkTesterProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryDenkTesterProgressQuery, QueryDenkTesterProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryDenkTesterProgressQuery, QueryDenkTesterProgressQueryVariables>(QueryDenkTesterProgressDocument, options);
        }
export type QueryDenkTesterProgressQueryHookResult = ReturnType<typeof useQueryDenkTesterProgressQuery>;
export type QueryDenkTesterProgressLazyQueryHookResult = ReturnType<typeof useQueryDenkTesterProgressLazyQuery>;
export type QueryDenkTesterProgressQueryResult = Apollo.QueryResult<QueryDenkTesterProgressQuery, QueryDenkTesterProgressQueryVariables>;
export const GetPipelineModuleCcCheckerDocument = gql`
    query getPipelineModuleCCChecker($id: String!) {
  getPipelineModuleCCChecker(id: $id) {
    id
    name
    description
    busBarCountPerCell
    maxShift
    minCorrValue
    cutOffRate
    minPeakDistance
    minPeakProminence
    minPeakHeightPercentage
    layoutPosition {
      x
      y
    }
    name
    description
    pipeline {
      id
    }
    outputs {
      name
    }
  }
}
    `;

/**
 * __useGetPipelineModuleCcCheckerQuery__
 *
 * To run a query within a React component, call `useGetPipelineModuleCcCheckerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineModuleCcCheckerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineModuleCcCheckerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPipelineModuleCcCheckerQuery(baseOptions: Apollo.QueryHookOptions<GetPipelineModuleCcCheckerQuery, GetPipelineModuleCcCheckerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineModuleCcCheckerQuery, GetPipelineModuleCcCheckerQueryVariables>(GetPipelineModuleCcCheckerDocument, options);
      }
export function useGetPipelineModuleCcCheckerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineModuleCcCheckerQuery, GetPipelineModuleCcCheckerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineModuleCcCheckerQuery, GetPipelineModuleCcCheckerQueryVariables>(GetPipelineModuleCcCheckerDocument, options);
        }
export type GetPipelineModuleCcCheckerQueryHookResult = ReturnType<typeof useGetPipelineModuleCcCheckerQuery>;
export type GetPipelineModuleCcCheckerLazyQueryHookResult = ReturnType<typeof useGetPipelineModuleCcCheckerLazyQuery>;
export type GetPipelineModuleCcCheckerQueryResult = Apollo.QueryResult<GetPipelineModuleCcCheckerQuery, GetPipelineModuleCcCheckerQueryVariables>;
export const GetPipelineModuleContrastEnhancementDocument = gql`
    query getPipelineModuleContrastEnhancement($id: String!) {
  getPipelineModuleContrastEnhancement(id: $id) {
    id
    name
    description
    clipLimit
    tileSize
    layoutPosition {
      x
      y
    }
    name
    description
    pipeline {
      id
    }
    outputs {
      name
    }
  }
}
    `;

/**
 * __useGetPipelineModuleContrastEnhancementQuery__
 *
 * To run a query within a React component, call `useGetPipelineModuleContrastEnhancementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineModuleContrastEnhancementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineModuleContrastEnhancementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPipelineModuleContrastEnhancementQuery(baseOptions: Apollo.QueryHookOptions<GetPipelineModuleContrastEnhancementQuery, GetPipelineModuleContrastEnhancementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineModuleContrastEnhancementQuery, GetPipelineModuleContrastEnhancementQueryVariables>(GetPipelineModuleContrastEnhancementDocument, options);
      }
export function useGetPipelineModuleContrastEnhancementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineModuleContrastEnhancementQuery, GetPipelineModuleContrastEnhancementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineModuleContrastEnhancementQuery, GetPipelineModuleContrastEnhancementQueryVariables>(GetPipelineModuleContrastEnhancementDocument, options);
        }
export type GetPipelineModuleContrastEnhancementQueryHookResult = ReturnType<typeof useGetPipelineModuleContrastEnhancementQuery>;
export type GetPipelineModuleContrastEnhancementLazyQueryHookResult = ReturnType<typeof useGetPipelineModuleContrastEnhancementLazyQuery>;
export type GetPipelineModuleContrastEnhancementQueryResult = Apollo.QueryResult<GetPipelineModuleContrastEnhancementQuery, GetPipelineModuleContrastEnhancementQueryVariables>;
export const GetPipelineModuleNetworkImageClassificationDocument = gql`
    query getPipelineModuleNetworkImageClassification($id: String!) {
  getPipelineModuleNetworkImageClassification(id: $id) {
    id
    name
    description
    inputs {
      id
      edges {
        id
        from {
          id
        }
      }
    }
    layoutPosition {
      x
      y
    }
    name
    description
    pipeline {
      id
    }
    outputs {
      id
      name
    }
    classLabels {
      id
      name
      color
    }
  }
}
    `;

/**
 * __useGetPipelineModuleNetworkImageClassificationQuery__
 *
 * To run a query within a React component, call `useGetPipelineModuleNetworkImageClassificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineModuleNetworkImageClassificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineModuleNetworkImageClassificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPipelineModuleNetworkImageClassificationQuery(baseOptions: Apollo.QueryHookOptions<GetPipelineModuleNetworkImageClassificationQuery, GetPipelineModuleNetworkImageClassificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineModuleNetworkImageClassificationQuery, GetPipelineModuleNetworkImageClassificationQueryVariables>(GetPipelineModuleNetworkImageClassificationDocument, options);
      }
export function useGetPipelineModuleNetworkImageClassificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineModuleNetworkImageClassificationQuery, GetPipelineModuleNetworkImageClassificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineModuleNetworkImageClassificationQuery, GetPipelineModuleNetworkImageClassificationQueryVariables>(GetPipelineModuleNetworkImageClassificationDocument, options);
        }
export type GetPipelineModuleNetworkImageClassificationQueryHookResult = ReturnType<typeof useGetPipelineModuleNetworkImageClassificationQuery>;
export type GetPipelineModuleNetworkImageClassificationLazyQueryHookResult = ReturnType<typeof useGetPipelineModuleNetworkImageClassificationLazyQuery>;
export type GetPipelineModuleNetworkImageClassificationQueryResult = Apollo.QueryResult<GetPipelineModuleNetworkImageClassificationQuery, GetPipelineModuleNetworkImageClassificationQueryVariables>;
export const GetPipelineModuleNetworkImageObjectDetectionDocument = gql`
    query getPipelineModuleNetworkImageObjectDetection($id: String!) {
  getPipelineModuleNetworkImageObjectDetection(id: $id) {
    id
    name
    description
    inputs {
      edges {
        from {
          id
        }
      }
    }
    layoutPosition {
      x
      y
    }
    name
    description
    pipeline {
      id
    }
    outputs {
      name
    }
    classLabels {
      id
      name
      color
    }
  }
}
    `;

/**
 * __useGetPipelineModuleNetworkImageObjectDetectionQuery__
 *
 * To run a query within a React component, call `useGetPipelineModuleNetworkImageObjectDetectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineModuleNetworkImageObjectDetectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineModuleNetworkImageObjectDetectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPipelineModuleNetworkImageObjectDetectionQuery(baseOptions: Apollo.QueryHookOptions<GetPipelineModuleNetworkImageObjectDetectionQuery, GetPipelineModuleNetworkImageObjectDetectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineModuleNetworkImageObjectDetectionQuery, GetPipelineModuleNetworkImageObjectDetectionQueryVariables>(GetPipelineModuleNetworkImageObjectDetectionDocument, options);
      }
export function useGetPipelineModuleNetworkImageObjectDetectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineModuleNetworkImageObjectDetectionQuery, GetPipelineModuleNetworkImageObjectDetectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineModuleNetworkImageObjectDetectionQuery, GetPipelineModuleNetworkImageObjectDetectionQueryVariables>(GetPipelineModuleNetworkImageObjectDetectionDocument, options);
        }
export type GetPipelineModuleNetworkImageObjectDetectionQueryHookResult = ReturnType<typeof useGetPipelineModuleNetworkImageObjectDetectionQuery>;
export type GetPipelineModuleNetworkImageObjectDetectionLazyQueryHookResult = ReturnType<typeof useGetPipelineModuleNetworkImageObjectDetectionLazyQuery>;
export type GetPipelineModuleNetworkImageObjectDetectionQueryResult = Apollo.QueryResult<GetPipelineModuleNetworkImageObjectDetectionQuery, GetPipelineModuleNetworkImageObjectDetectionQueryVariables>;
export const GetPipelineModuleNetworkImageSegmentationDocument = gql`
    query getPipelineModuleNetworkImageSegmentation($id: String!) {
  getPipelineModuleNetworkImageSegmentation(id: $id) {
    id
    name
    description
    inputs {
      edges {
        from {
          id
        }
      }
    }
    layoutPosition {
      x
      y
    }
    name
    description
    pipeline {
      id
    }
    outputs {
      name
    }
    classLabels {
      id
      name
      color
    }
  }
}
    `;

/**
 * __useGetPipelineModuleNetworkImageSegmentationQuery__
 *
 * To run a query within a React component, call `useGetPipelineModuleNetworkImageSegmentationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineModuleNetworkImageSegmentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineModuleNetworkImageSegmentationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPipelineModuleNetworkImageSegmentationQuery(baseOptions: Apollo.QueryHookOptions<GetPipelineModuleNetworkImageSegmentationQuery, GetPipelineModuleNetworkImageSegmentationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineModuleNetworkImageSegmentationQuery, GetPipelineModuleNetworkImageSegmentationQueryVariables>(GetPipelineModuleNetworkImageSegmentationDocument, options);
      }
export function useGetPipelineModuleNetworkImageSegmentationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineModuleNetworkImageSegmentationQuery, GetPipelineModuleNetworkImageSegmentationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineModuleNetworkImageSegmentationQuery, GetPipelineModuleNetworkImageSegmentationQueryVariables>(GetPipelineModuleNetworkImageSegmentationDocument, options);
        }
export type GetPipelineModuleNetworkImageSegmentationQueryHookResult = ReturnType<typeof useGetPipelineModuleNetworkImageSegmentationQuery>;
export type GetPipelineModuleNetworkImageSegmentationLazyQueryHookResult = ReturnType<typeof useGetPipelineModuleNetworkImageSegmentationLazyQuery>;
export type GetPipelineModuleNetworkImageSegmentationQueryResult = Apollo.QueryResult<GetPipelineModuleNetworkImageSegmentationQuery, GetPipelineModuleNetworkImageSegmentationQueryVariables>;
export const GetPipelineModuleTypeDocument = gql`
    query getPipelineModuleType($id: String!) {
  queryPipelineModule(filter: {id: {eq: $id}}) {
    id
    __typename
  }
}
    `;

/**
 * __useGetPipelineModuleTypeQuery__
 *
 * To run a query within a React component, call `useGetPipelineModuleTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineModuleTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineModuleTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPipelineModuleTypeQuery(baseOptions: Apollo.QueryHookOptions<GetPipelineModuleTypeQuery, GetPipelineModuleTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineModuleTypeQuery, GetPipelineModuleTypeQueryVariables>(GetPipelineModuleTypeDocument, options);
      }
export function useGetPipelineModuleTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineModuleTypeQuery, GetPipelineModuleTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineModuleTypeQuery, GetPipelineModuleTypeQueryVariables>(GetPipelineModuleTypeDocument, options);
        }
export type GetPipelineModuleTypeQueryHookResult = ReturnType<typeof useGetPipelineModuleTypeQuery>;
export type GetPipelineModuleTypeLazyQueryHookResult = ReturnType<typeof useGetPipelineModuleTypeLazyQuery>;
export type GetPipelineModuleTypeQueryResult = Apollo.QueryResult<GetPipelineModuleTypeQuery, GetPipelineModuleTypeQueryVariables>;
export const GetNetworkModuleClassLabelsDocument = gql`
    query getNetworkModuleClassLabels($id: String!) {
  queryNetworkModule(filter: {networkModuleId: {eq: $id}}) {
    id: networkModuleId
    classLabels(order: {asc: idx}) {
      id
      name
      color
    }
  }
}
    `;

/**
 * __useGetNetworkModuleClassLabelsQuery__
 *
 * To run a query within a React component, call `useGetNetworkModuleClassLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetworkModuleClassLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetworkModuleClassLabelsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNetworkModuleClassLabelsQuery(baseOptions: Apollo.QueryHookOptions<GetNetworkModuleClassLabelsQuery, GetNetworkModuleClassLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNetworkModuleClassLabelsQuery, GetNetworkModuleClassLabelsQueryVariables>(GetNetworkModuleClassLabelsDocument, options);
      }
export function useGetNetworkModuleClassLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNetworkModuleClassLabelsQuery, GetNetworkModuleClassLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNetworkModuleClassLabelsQuery, GetNetworkModuleClassLabelsQueryVariables>(GetNetworkModuleClassLabelsDocument, options);
        }
export type GetNetworkModuleClassLabelsQueryHookResult = ReturnType<typeof useGetNetworkModuleClassLabelsQuery>;
export type GetNetworkModuleClassLabelsLazyQueryHookResult = ReturnType<typeof useGetNetworkModuleClassLabelsLazyQuery>;
export type GetNetworkModuleClassLabelsQueryResult = Apollo.QueryResult<GetNetworkModuleClassLabelsQuery, GetNetworkModuleClassLabelsQueryVariables>;
export const QueryPretrainedNetworkDocument = gql`
    query QueryPretrainedNetwork {
  queryPretrainedNetwork {
    __typename
    id
    createdAt
    updatedAt
    thumbnail
    networkModule {
      __typename
      ... on PipelineModule {
        id
        name
        createdAt
        updatedAt
        description
        owner
      }
    }
  }
}
    `;

/**
 * __useQueryPretrainedNetworkQuery__
 *
 * To run a query within a React component, call `useQueryPretrainedNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryPretrainedNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryPretrainedNetworkQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryPretrainedNetworkQuery(baseOptions?: Apollo.QueryHookOptions<QueryPretrainedNetworkQuery, QueryPretrainedNetworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryPretrainedNetworkQuery, QueryPretrainedNetworkQueryVariables>(QueryPretrainedNetworkDocument, options);
      }
export function useQueryPretrainedNetworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryPretrainedNetworkQuery, QueryPretrainedNetworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryPretrainedNetworkQuery, QueryPretrainedNetworkQueryVariables>(QueryPretrainedNetworkDocument, options);
        }
export type QueryPretrainedNetworkQueryHookResult = ReturnType<typeof useQueryPretrainedNetworkQuery>;
export type QueryPretrainedNetworkLazyQueryHookResult = ReturnType<typeof useQueryPretrainedNetworkLazyQuery>;
export type QueryPretrainedNetworkQueryResult = Apollo.QueryResult<QueryPretrainedNetworkQuery, QueryPretrainedNetworkQueryVariables>;
export const GetOutputsDocument = gql`
    query getOutputs($datasetId: String!) {
  getDataset(id: $datasetId) {
    id
    pipeline {
      id
      modules {
        id
        outputs {
          id
          name
          dataType
        }
      }
    }
  }
}
    `;

/**
 * __useGetOutputsQuery__
 *
 * To run a query within a React component, call `useGetOutputsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutputsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutputsQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetOutputsQuery(baseOptions: Apollo.QueryHookOptions<GetOutputsQuery, GetOutputsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOutputsQuery, GetOutputsQueryVariables>(GetOutputsDocument, options);
      }
export function useGetOutputsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutputsQuery, GetOutputsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOutputsQuery, GetOutputsQueryVariables>(GetOutputsDocument, options);
        }
export type GetOutputsQueryHookResult = ReturnType<typeof useGetOutputsQuery>;
export type GetOutputsLazyQueryHookResult = ReturnType<typeof useGetOutputsLazyQuery>;
export type GetOutputsQueryResult = Apollo.QueryResult<GetOutputsQuery, GetOutputsQueryVariables>;
export const SubscribeDatapointModuleParamsDocument = gql`
    subscription SubscribeDatapointModuleParams($datapointId: String!) {
  queryDatapoint(filter: {id: {eq: $datapointId}}) {
    researchBox {
      id
    }
    producedBy {
      module {
        __typename
        id
        ... on PipelineModuleContrastEnhancement {
          tileSize
          clipLimit
        }
        ... on PipelineModuleCCChecker {
          busBarCountPerCell
          maxShift
          minCorrValue
          cutOffRate
          minPeakDistance
          minPeakProminence
          minPeakHeightPercentage
        }
      }
    }
  }
}
    `;

/**
 * __useSubscribeDatapointModuleParamsSubscription__
 *
 * To run a query within a React component, call `useSubscribeDatapointModuleParamsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeDatapointModuleParamsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeDatapointModuleParamsSubscription({
 *   variables: {
 *      datapointId: // value for 'datapointId'
 *   },
 * });
 */
export function useSubscribeDatapointModuleParamsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeDatapointModuleParamsSubscription, SubscribeDatapointModuleParamsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeDatapointModuleParamsSubscription, SubscribeDatapointModuleParamsSubscriptionVariables>(SubscribeDatapointModuleParamsDocument, options);
      }
export type SubscribeDatapointModuleParamsSubscriptionHookResult = ReturnType<typeof useSubscribeDatapointModuleParamsSubscription>;
export type SubscribeDatapointModuleParamsSubscriptionResult = Apollo.SubscriptionResult<SubscribeDatapointModuleParamsSubscription>;
export const QueryHasImagePredictionDocument = gql`
    query QueryHasImagePrediction($networkId: String!, $researchBoxId: String!) {
  getResearchBox(id: $researchBoxId) {
    datapoints {
      ... on Image {
        predictions(filter: {networkId: {eq: $networkId}}) {
          id
          createdAt
        }
      }
    }
  }
}
    `;

/**
 * __useQueryHasImagePredictionQuery__
 *
 * To run a query within a React component, call `useQueryHasImagePredictionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryHasImagePredictionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryHasImagePredictionQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *      researchBoxId: // value for 'researchBoxId'
 *   },
 * });
 */
export function useQueryHasImagePredictionQuery(baseOptions: Apollo.QueryHookOptions<QueryHasImagePredictionQuery, QueryHasImagePredictionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryHasImagePredictionQuery, QueryHasImagePredictionQueryVariables>(QueryHasImagePredictionDocument, options);
      }
export function useQueryHasImagePredictionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryHasImagePredictionQuery, QueryHasImagePredictionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryHasImagePredictionQuery, QueryHasImagePredictionQueryVariables>(QueryHasImagePredictionDocument, options);
        }
export type QueryHasImagePredictionQueryHookResult = ReturnType<typeof useQueryHasImagePredictionQuery>;
export type QueryHasImagePredictionLazyQueryHookResult = ReturnType<typeof useQueryHasImagePredictionLazyQuery>;
export type QueryHasImagePredictionQueryResult = Apollo.QueryResult<QueryHasImagePredictionQuery, QueryHasImagePredictionQueryVariables>;
export const QueryNetworkDetailsForDatasetDocument = gql`
    query queryNetworkDetailsForDataset($filter: DatasetFilter, $networkFilter: PipelineModuleFilter) {
  queryDataset(filter: $filter) {
    id
    pipeline {
      id
      modules(filter: $networkFilter) {
        __typename
        id
        name
        description
        ... on NetworkModule {
          trainingMarkups: markupsAggregate(filter: {subset: {eq: TRAINING}}) {
            count
          }
          validationMarkups: markupsAggregate(filter: {subset: {eq: VALIDATION}}) {
            count
          }
          snapshots(order: {desc: createdAt}, first: 1) {
            id
            updatedAt
            onnxBinary {
              id
              url
            }
            metrics {
              id
              history
              inferenceThreshold
              name
              currentValue
              bestValue
              show
              isNetworkScore
              label {
                id
                idx
                color
                name
              }
            }
          }
          snapshotsAggregate {
            count
          }
          classLabels {
            id
            name
            color
            positiveMarkupCount
            negativeMarkupCount
          }
          denkjobs {
            id
            state
            failCounter
            jobType
            message
            createdAt
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQueryNetworkDetailsForDatasetQuery__
 *
 * To run a query within a React component, call `useQueryNetworkDetailsForDatasetQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryNetworkDetailsForDatasetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryNetworkDetailsForDatasetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      networkFilter: // value for 'networkFilter'
 *   },
 * });
 */
export function useQueryNetworkDetailsForDatasetQuery(baseOptions?: Apollo.QueryHookOptions<QueryNetworkDetailsForDatasetQuery, QueryNetworkDetailsForDatasetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryNetworkDetailsForDatasetQuery, QueryNetworkDetailsForDatasetQueryVariables>(QueryNetworkDetailsForDatasetDocument, options);
      }
export function useQueryNetworkDetailsForDatasetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryNetworkDetailsForDatasetQuery, QueryNetworkDetailsForDatasetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryNetworkDetailsForDatasetQuery, QueryNetworkDetailsForDatasetQueryVariables>(QueryNetworkDetailsForDatasetDocument, options);
        }
export type QueryNetworkDetailsForDatasetQueryHookResult = ReturnType<typeof useQueryNetworkDetailsForDatasetQuery>;
export type QueryNetworkDetailsForDatasetLazyQueryHookResult = ReturnType<typeof useQueryNetworkDetailsForDatasetLazyQuery>;
export type QueryNetworkDetailsForDatasetQueryResult = Apollo.QueryResult<QueryNetworkDetailsForDatasetQuery, QueryNetworkDetailsForDatasetQueryVariables>;
export const GetUploadModuleForDatasetDocument = gql`
    query GetUploadModuleForDataset($datasetId: String!) {
  queryPipelineModuleUpload @cascade {
    id
    outputs {
      id
      name
      dataType
    }
    pipeline {
      id
      dataset(filter: {id: {eq: $datasetId}}) {
        id
      }
    }
  }
}
    `;

/**
 * __useGetUploadModuleForDatasetQuery__
 *
 * To run a query within a React component, call `useGetUploadModuleForDatasetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadModuleForDatasetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadModuleForDatasetQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetUploadModuleForDatasetQuery(baseOptions: Apollo.QueryHookOptions<GetUploadModuleForDatasetQuery, GetUploadModuleForDatasetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUploadModuleForDatasetQuery, GetUploadModuleForDatasetQueryVariables>(GetUploadModuleForDatasetDocument, options);
      }
export function useGetUploadModuleForDatasetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploadModuleForDatasetQuery, GetUploadModuleForDatasetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUploadModuleForDatasetQuery, GetUploadModuleForDatasetQueryVariables>(GetUploadModuleForDatasetDocument, options);
        }
export type GetUploadModuleForDatasetQueryHookResult = ReturnType<typeof useGetUploadModuleForDatasetQuery>;
export type GetUploadModuleForDatasetLazyQueryHookResult = ReturnType<typeof useGetUploadModuleForDatasetLazyQuery>;
export type GetUploadModuleForDatasetQueryResult = Apollo.QueryResult<GetUploadModuleForDatasetQuery, GetUploadModuleForDatasetQueryVariables>;
export type CheckDatasetForMarkupQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type CheckDatasetForMarkupQuery = (
  { __typename?: 'Query' }
  & { queryImageMarkup?: Maybe<Array<Maybe<(
    { __typename?: 'SegmentationMarkup' }
    & { images: Array<(
      { __typename?: 'Image' }
      & { researchBox: (
        { __typename?: 'ResearchBox' }
        & { dataset?: Maybe<(
          { __typename?: 'Dataset' }
          & Pick<Dataset, 'id'>
        )> }
      ) }
    )> }
  ) | (
    { __typename?: 'ClassificationMarkup' }
    & { images: Array<(
      { __typename?: 'Image' }
      & { researchBox: (
        { __typename?: 'ResearchBox' }
        & { dataset?: Maybe<(
          { __typename?: 'Dataset' }
          & Pick<Dataset, 'id'>
        )> }
      ) }
    )> }
  ) | (
    { __typename?: 'InstanceSegmentationMarkup' }
    & { images: Array<(
      { __typename?: 'Image' }
      & { researchBox: (
        { __typename?: 'ResearchBox' }
        & { dataset?: Maybe<(
          { __typename?: 'Dataset' }
          & Pick<Dataset, 'id'>
        )> }
      ) }
    )> }
  ) | (
    { __typename?: 'InstanceSegmentationPrediction' }
    & { images: Array<(
      { __typename?: 'Image' }
      & { researchBox: (
        { __typename?: 'ResearchBox' }
        & { dataset?: Maybe<(
          { __typename?: 'Dataset' }
          & Pick<Dataset, 'id'>
        )> }
      ) }
    )> }
  ) | (
    { __typename?: 'ObjectDetectionMarkup' }
    & { images: Array<(
      { __typename?: 'Image' }
      & { researchBox: (
        { __typename?: 'ResearchBox' }
        & { dataset?: Maybe<(
          { __typename?: 'Dataset' }
          & Pick<Dataset, 'id'>
        )> }
      ) }
    )> }
  )>>> }
);

export type GetObjectDetectionMarkupForImagesAndNetworkQueryVariables = Exact<{
  imageIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  networkId?: Maybe<Scalars['String']>;
}>;


export type GetObjectDetectionMarkupForImagesAndNetworkQuery = (
  { __typename?: 'Query' }
  & { queryImage?: Maybe<Array<Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'width' | 'height'>
    & { predictions: Array<(
      { __typename: 'SegmentationPrediction' }
      & Pick<SegmentationPrediction, 'id'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename: 'ClassificationPrediction' }
      & Pick<ClassificationPrediction, 'id'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename: 'ObjectDetectionPrediction' }
      & Pick<ObjectDetectionPrediction, 'id'>
      & { predictions: Array<(
        { __typename?: 'BoundingBoxPrediction' }
        & Pick<BoundingBoxPrediction, 'probability'>
        & { topLeft: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), bottomRight: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), label: (
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        ) }
      )>, network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    )>, markups: Array<(
      { __typename: 'SegmentationMarkup' }
      & Pick<SegmentationMarkup, 'id' | 'updatedAt' | 'subset'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename: 'ClassificationMarkup' }
      & Pick<ClassificationMarkup, 'id' | 'updatedAt' | 'subset'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename: 'InstanceSegmentationMarkup' }
      & Pick<InstanceSegmentationMarkup, 'id' | 'updatedAt' | 'subset'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename: 'InstanceSegmentationPrediction' }
      & Pick<InstanceSegmentationPrediction, 'id' | 'updatedAt' | 'subset'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename: 'ObjectDetectionMarkup' }
      & Pick<ObjectDetectionMarkup, 'id' | 'updatedAt' | 'subset'>
      & { annotations: Array<(
        { __typename: 'BoundingBoxAnnotation' }
        & Pick<BoundingBoxAnnotation, 'id' | 'annotationType'>
        & { label: (
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        ), topLeft: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), bottomRight: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ) }
      )>, network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    )> }
  )>>> }
);

export type GetResearchBoxContentQueryVariables = Exact<{
  researchBoxId: Scalars['String'];
}>;


export type GetResearchBoxContentQuery = (
  { __typename?: 'Query' }
  & { getResearchBox?: Maybe<(
    { __typename?: 'ResearchBox' }
    & Pick<ResearchBox, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'owner'>
    & { dataset?: Maybe<(
      { __typename?: 'Dataset' }
      & Pick<Dataset, 'id' | 'name'>
    )>, datapoints?: Maybe<Array<(
      { __typename: 'Image' }
      & Pick<Image, 'thumbnail' | 'binaryURL' | 'filename' | 'width' | 'height' | 'id' | 'createdAt' | 'updatedAt'>
      & { resolutions: Array<(
        { __typename?: 'ImageResolution' }
        & Pick<ImageResolution, 'height' | 'width' | 'id' | 'binaryURL'>
      )>, predictions: Array<(
        { __typename: 'SegmentationPrediction' }
        & Pick<SegmentationPrediction, 'id'>
        & { segmentationMaps: Array<(
          { __typename?: 'SegmentationMap2D' }
          & Pick<SegmentationMap2D, 'thumbnail' | 'binaryURL'>
        )> }
      ) | (
        { __typename: 'ClassificationPrediction' }
        & Pick<ClassificationPrediction, 'id'>
      ) | (
        { __typename: 'ObjectDetectionPrediction' }
        & Pick<ObjectDetectionPrediction, 'id'>
      )>, markups: Array<(
        { __typename: 'SegmentationMarkup' }
        & Pick<SegmentationMarkup, 'id'>
        & { annotations: Array<(
          { __typename: 'CircleAnnotation' }
          & Pick<CircleAnnotation, 'radius' | 'id' | 'annotationType'>
          & { center: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PolygonAnnotation' }
          & Pick<PolygonAnnotation, 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'idx' | 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PenAnnotation' }
          & Pick<PenAnnotation, 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'RectangleAnnotation' }
          & Pick<RectangleAnnotation, 'id' | 'annotationType'>
          & { topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'SausageAnnotation' }
          & Pick<SausageAnnotation, 'radius' | 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'idx' | 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'MagicwandAnnotation' }
          & Pick<MagicwandAnnotation, 'threshold' | 'id' | 'annotationType'>
          & { center: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PixelAnnotation' }
          & Pick<PixelAnnotation, 'dataURL' | 'id' | 'annotationType'>
          & { topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        )> }
      ) | (
        { __typename: 'ClassificationMarkup' }
        & Pick<ClassificationMarkup, 'id'>
        & { annotations: Array<(
          { __typename?: 'ScalarAnnotation' }
          & Pick<ScalarAnnotation, 'id' | 'value'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        )> }
      ) | (
        { __typename: 'InstanceSegmentationMarkup' }
        & Pick<InstanceSegmentationMarkup, 'id'>
      ) | (
        { __typename: 'InstanceSegmentationPrediction' }
        & Pick<InstanceSegmentationPrediction, 'id'>
      ) | (
        { __typename: 'ObjectDetectionMarkup' }
        & Pick<ObjectDetectionMarkup, 'id'>
        & { annotations: Array<(
          { __typename?: 'BoundingBoxAnnotation' }
          & Pick<BoundingBoxAnnotation, 'id' | 'annotationType'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ), topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ) }
        )> }
      )>, producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'dataType' | 'name'>
      ) }
    ) | (
      { __typename: 'CCCheckerResult' }
      & Pick<CcCheckerResult, 'groups' | 'isOkay' | 'id' | 'createdAt' | 'updatedAt'>
      & { producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'dataType' | 'name'>
      ) }
    ) | (
      { __typename: 'MagneticFieldScan' }
      & Pick<MagneticFieldScan, 'width' | 'height' | 'startIndices' | 'hasBaseline' | 'binaryURL' | 'id' | 'createdAt' | 'updatedAt'>
      & { producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'dataType' | 'name'>
      ) }
    )>> }
  )> }
);

export type GetSegmentationMarkupsForImagesAndNetworkQueryVariables = Exact<{
  imageIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  networkId?: Maybe<Scalars['String']>;
}>;


export type GetSegmentationMarkupsForImagesAndNetworkQuery = (
  { __typename?: 'Query' }
  & { queryImage?: Maybe<Array<Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'width' | 'height'>
    & { predictions: Array<(
      { __typename: 'SegmentationPrediction' }
      & Pick<SegmentationPrediction, 'id'>
      & { objects: Array<(
        { __typename?: 'SegmentationPredictionObject' }
        & Pick<SegmentationPredictionObject, 'dataURL' | 'probability'>
        & { topLeft: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), bottomRight: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), label: (
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        ) }
      )>, segmentationMaps: Array<(
        { __typename?: 'SegmentationMap2D' }
        & Pick<SegmentationMap2D, 'id' | 'thumbnail' | 'binaryURL'>
        & { label: (
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        ) }
      )>, network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename: 'ClassificationPrediction' }
      & Pick<ClassificationPrediction, 'id'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename: 'ObjectDetectionPrediction' }
      & Pick<ObjectDetectionPrediction, 'id'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    )>, markups: Array<(
      { __typename: 'SegmentationMarkup' }
      & Pick<SegmentationMarkup, 'id' | 'updatedAt' | 'subset'>
      & { annotations: Array<(
        { __typename: 'CircleAnnotation' }
        & Pick<CircleAnnotation, 'radius' | 'id' | 'annotationType'>
        & { center: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), label: (
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        ) }
      ) | (
        { __typename: 'PolygonAnnotation' }
        & Pick<PolygonAnnotation, 'id' | 'annotationType'>
        & { points: Array<(
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'idx' | 'x' | 'y'>
        )>, label: (
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        ) }
      ) | (
        { __typename: 'PenAnnotation' }
        & Pick<PenAnnotation, 'thickness' | 'dataURL' | 'id' | 'annotationType'>
        & { points: Array<(
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'idx' | 'x' | 'y'>
        )>, topLeft: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), bottomRight: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), label: (
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        ) }
      ) | (
        { __typename: 'RectangleAnnotation' }
        & Pick<RectangleAnnotation, 'id' | 'annotationType'>
        & { topLeft: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), bottomRight: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), label: (
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        ) }
      ) | (
        { __typename: 'SausageAnnotation' }
        & Pick<SausageAnnotation, 'radius' | 'dataURL' | 'id' | 'annotationType'>
        & { points: Array<(
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'idx' | 'x' | 'y'>
        )>, topLeft: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), bottomRight: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), label: (
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        ) }
      ) | (
        { __typename: 'MagicwandAnnotation' }
        & Pick<MagicwandAnnotation, 'threshold' | 'dataURL' | 'id' | 'annotationType'>
        & { center: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), topLeft: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), bottomRight: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), label: (
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        ) }
      ) | (
        { __typename: 'PixelAnnotation' }
        & Pick<PixelAnnotation, 'dataURL' | 'id' | 'annotationType'>
        & { topLeft: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), bottomRight: (
          { __typename?: 'Point2D' }
          & Pick<Point2D, 'x' | 'y'>
        ), label: (
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        ) }
      )>, network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename: 'ClassificationMarkup' }
      & Pick<ClassificationMarkup, 'id' | 'updatedAt' | 'subset'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename: 'InstanceSegmentationMarkup' }
      & Pick<InstanceSegmentationMarkup, 'id' | 'updatedAt' | 'subset'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename: 'InstanceSegmentationPrediction' }
      & Pick<InstanceSegmentationPrediction, 'id' | 'updatedAt' | 'subset'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename: 'ObjectDetectionMarkup' }
      & Pick<ObjectDetectionMarkup, 'id' | 'updatedAt' | 'subset'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    )> }
  )>>> }
);

export type QueryDatasetFoldersAndResearchBoxesQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type QueryDatasetFoldersAndResearchBoxesQuery = (
  { __typename?: 'Query' }
  & { getDataset?: Maybe<(
    { __typename?: 'Dataset' }
    & Pick<Dataset, 'name'>
    & { objects: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'description' | 'id' | 'name'>
      & { objectsAggregate?: Maybe<(
        { __typename?: 'FolderOrResearchBoxAggregateResult' }
        & Pick<FolderOrResearchBoxAggregateResult, 'count'>
      )> }
    ) | (
      { __typename?: 'ResearchBox' }
      & Pick<ResearchBox, 'id' | 'name'>
    )> }
  )> }
);

export type QueryFolderFilteredContentQueryVariables = Exact<{
  folderIds: Array<Scalars['String']> | Scalars['String'];
  filterName?: Maybe<Scalars['String']>;
}>;


export type QueryFolderFilteredContentQuery = (
  { __typename?: 'Query' }
  & { queryFolder?: Maybe<Array<Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'description'>
    & { objectsAggregate?: Maybe<(
      { __typename?: 'FolderOrResearchBoxAggregateResult' }
      & Pick<FolderOrResearchBoxAggregateResult, 'count'>
    )> }
  )>>> }
);

export type QueryFolderFoldersAndResearchBoxesQueryVariables = Exact<{
  folderId: Scalars['String'];
}>;


export type QueryFolderFoldersAndResearchBoxesQuery = (
  { __typename?: 'Query' }
  & { getFolder?: Maybe<(
    { __typename?: 'Folder' }
    & { objects: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'description' | 'id' | 'name'>
      & { objectsAggregate?: Maybe<(
        { __typename?: 'FolderOrResearchBoxAggregateResult' }
        & Pick<FolderOrResearchBoxAggregateResult, 'count'>
      )> }
    ) | (
      { __typename?: 'ResearchBox' }
      & Pick<ResearchBox, 'id' | 'name'>
    )> }
  )> }
);

export type QueryFolderNamesQueryVariables = Exact<{
  folderIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type QueryFolderNamesQuery = (
  { __typename?: 'Query' }
  & { queryFolder?: Maybe<Array<Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'name'>
  )>>> }
);

export type QueryImageClassificationMarkupsQueryVariables = Exact<{
  imageIds: Array<Scalars['String']> | Scalars['String'];
  networkId?: Maybe<Scalars['String']>;
}>;


export type QueryImageClassificationMarkupsQuery = (
  { __typename?: 'Query' }
  & { queryImage?: Maybe<Array<Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'width' | 'height'>
    & { predictions: Array<(
      { __typename?: 'SegmentationPrediction' }
      & Pick<SegmentationPrediction, 'id' | 'createdAt'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'name'>
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'name'>
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'name'>
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'name'>
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'name'>
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'name'>
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'name'>
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'name'>
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename: 'ClassificationPrediction' }
      & Pick<ClassificationPrediction, 'id' | 'createdAt'>
      & { predictions: Array<(
        { __typename?: 'ScalarPrediction' }
        & Pick<ScalarPrediction, 'id' | 'probability'>
        & { label: (
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        ) }
      )>, network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'name'>
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'name'>
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'name'>
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'name'>
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'name'>
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'name'>
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'name'>
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'name'>
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename?: 'ObjectDetectionPrediction' }
      & Pick<ObjectDetectionPrediction, 'id' | 'createdAt'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'name'>
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'name'>
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'name'>
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'name'>
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'name'>
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'name'>
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'name'>
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'name'>
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    )>, markups: Array<(
      { __typename?: 'SegmentationMarkup' }
      & Pick<SegmentationMarkup, 'id'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename?: 'ClassificationMarkup' }
      & Pick<ClassificationMarkup, 'subset' | 'id'>
      & { annotations: Array<(
        { __typename?: 'ScalarAnnotation' }
        & Pick<ScalarAnnotation, 'id' | 'value'>
        & { label: (
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'idx' | 'color' | 'name'>
        ) }
      )>, network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename?: 'InstanceSegmentationMarkup' }
      & Pick<InstanceSegmentationMarkup, 'id'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename?: 'InstanceSegmentationPrediction' }
      & Pick<InstanceSegmentationPrediction, 'id'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    ) | (
      { __typename?: 'ObjectDetectionMarkup' }
      & Pick<ObjectDetectionMarkup, 'id'>
      & { network: (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
      ) }
    )> }
  )>>> }
);

export type QueryNetworksForDatasetQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type QueryNetworksForDatasetQuery = (
  { __typename?: 'Query' }
  & { getDataset?: Maybe<(
    { __typename?: 'Dataset' }
    & Pick<Dataset, 'id'>
    & { pipeline?: Maybe<(
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
      & { modules?: Maybe<Array<{ __typename?: 'PipelineModuleUpload' } | { __typename?: 'PipelineModuleBMobile' } | { __typename?: 'PipelineModuleCCChecker' } | { __typename?: 'PipelineModuleContrastEnhancement' } | (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'id' | 'name' | 'description'>
        & { classLabels: Array<(
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'id' | 'name' | 'description'>
        & { classLabels: Array<(
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color'>
        )> }
      ) | { __typename?: 'PipelineModuleNetworkImageObjectDetection' } | { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' } | { __typename?: 'PipelineModuleNetworkImageSuperResolution' } | { __typename?: 'PipelineModuleNetworkImageOCR' } | { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' } | { __typename?: 'PipelineModuleNetworkImageObjectCounting' }>> }
    )> }
  )> }
);

export type GetDatasetPropertiesQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetDatasetPropertiesQuery = (
  { __typename?: 'Query' }
  & { getDataset?: Maybe<(
    { __typename?: 'Dataset' }
    & Pick<Dataset, 'id' | 'aggregateDatapointTypes'>
    & { aggregateImageResolutions: Array<(
      { __typename?: 'WidthHeight' }
      & Pick<WidthHeight, 'width' | 'height'>
    )> }
  )> }
);

export type ListBackupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListBackupsQuery = (
  { __typename?: 'Query' }
  & { listBackups?: Maybe<(
    { __typename?: 'ListBackupsPayload' }
    & { backups: Array<(
      { __typename?: 'Backup' }
      & Pick<Backup, 'id' | 'createdAt'>
    )> }
  )> }
);

export type UserWithDatasetsQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type UserWithDatasetsQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & { datasets: Array<(
      { __typename?: 'Dataset' }
      & Pick<Dataset, 'id' | 'name' | 'thumbnail' | 'description'>
      & { objectsAggregate?: Maybe<(
        { __typename?: 'FolderOrResearchBoxAggregateResult' }
        & Pick<FolderOrResearchBoxAggregateResult, 'count'>
      )> }
    )> }
  )> }
);

export type QueryFilteredFoldersAndResearchBoxIDsQueryVariables = Exact<{
  input: QueryFilteredFoldersAndResearchBoxesInput;
}>;


export type QueryFilteredFoldersAndResearchBoxIDsQuery = (
  { __typename?: 'Query' }
  & { queryFilteredFoldersAndResearchBoxes?: Maybe<(
    { __typename?: 'QueryFilteredFoldersAndResearchBoxesPayload' }
    & Pick<QueryFilteredFoldersAndResearchBoxesPayload, 'totalCount' | 'foldersAndResearchBoxIds'>
  )> }
);

export type GetAllDenkBoxesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDenkBoxesQuery = (
  { __typename?: 'Query' }
  & { queryDenkbox?: Maybe<Array<Maybe<(
    { __typename?: 'Denkbox' }
    & Pick<Denkbox, 'id' | 'dockerId' | 'host'>
    & { gpus: Array<(
      { __typename?: 'Gpu' }
      & Pick<Gpu, 'maxMemory' | 'freeMemory' | 'usage'>
    )>, jobs: Array<(
      { __typename?: 'Denkjob' }
      & Pick<Denkjob, 'id'>
    )> }
  )>>> }
);

export type GetUserByUsernameQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type GetUserByUsernameQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'username' | 'createdAt' | 'email' | 'role' | 'name' | 'surname' | 'phone' | 'allowedFeatures' | 'blockedFeatures' | 'trainingQuota' | 'inferenceQuota'>
  )> }
);

export type AllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllUsersQuery = (
  { __typename?: 'Query' }
  & { queryUser?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'username' | 'createdAt' | 'email' | 'role' | 'name' | 'surname' | 'phone'>
  )>>> }
);

export type AllModulesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllModulesQuery = (
  { __typename?: 'Query' }
  & { queryPipelineModule?: Maybe<Array<Maybe<(
    { __typename: 'PipelineModuleUpload' }
    & Pick<PipelineModuleUpload, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
  ) | (
    { __typename: 'PipelineModuleBMobile' }
    & Pick<PipelineModuleBMobile, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
  ) | (
    { __typename: 'PipelineModuleCCChecker' }
    & Pick<PipelineModuleCcChecker, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
  ) | (
    { __typename: 'PipelineModuleContrastEnhancement' }
    & Pick<PipelineModuleContrastEnhancement, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageSegmentation' }
    & Pick<PipelineModuleNetworkImageSegmentation, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageClassification' }
    & Pick<PipelineModuleNetworkImageClassification, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageObjectDetection' }
    & Pick<PipelineModuleNetworkImageObjectDetection, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageInstanceSegmentation' }
    & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageSuperResolution' }
    & Pick<PipelineModuleNetworkImageSuperResolution, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageOCR' }
    & Pick<PipelineModuleNetworkImageOcr, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageAnomalyDetection' }
    & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageObjectCounting' }
    & Pick<PipelineModuleNetworkImageObjectCounting, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
  )>>> }
);

export type GetLastBtechResultQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetLastBtechResultQuery = (
  { __typename?: 'Query' }
  & { getDataset?: Maybe<(
    { __typename?: 'Dataset' }
    & Pick<Dataset, 'id'>
    & { objectsAggregate?: Maybe<(
      { __typename?: 'FolderOrResearchBoxAggregateResult' }
      & Pick<FolderOrResearchBoxAggregateResult, 'count'>
    )>, objects: Array<{ __typename?: 'Folder' } | (
      { __typename?: 'ResearchBox' }
      & Pick<ResearchBox, 'id'>
      & { datapoints?: Maybe<Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'id'>
        & { producedBy: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'dataType' | 'name'>
        ) }
      ) | (
        { __typename?: 'CCCheckerResult' }
        & Pick<CcCheckerResult, 'groups' | 'isOkay' | 'id'>
        & { producedBy: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'dataType' | 'name'>
        ) }
      ) | (
        { __typename?: 'MagneticFieldScan' }
        & Pick<MagneticFieldScan, 'width' | 'height' | 'startIndices' | 'hasBaseline' | 'binaryURL' | 'updatedAt' | 'id'>
        & { producedBy: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'dataType' | 'name'>
        ) }
      )>> }
    )> }
  )> }
);

export type QueryImageMetadataQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryImageMetadataQuery = (
  { __typename?: 'Query' }
  & { queryImage?: Maybe<Array<Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'filename'>
    & { metadata: Array<(
      { __typename?: 'ImageMetadataField' }
      & Pick<ImageMetadataField, 'key' | 'value' | 'owner'>
    )> }
  )>>> }
);

export type GetDatapointDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetDatapointDetailsQuery = (
  { __typename?: 'Query' }
  & { queryDatapoint?: Maybe<Array<Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'thumbnail' | 'binaryURL' | 'filename' | 'width' | 'height' | 'id' | 'createdAt' | 'updatedAt'>
    & { resolutions: Array<(
      { __typename?: 'ImageResolution' }
      & Pick<ImageResolution, 'height' | 'width' | 'id' | 'binaryURL'>
    )>, researchBox: (
      { __typename?: 'ResearchBox' }
      & Pick<ResearchBox, 'id' | 'name'>
    ), producedBy: (
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'name' | 'dataType'>
      & { module: (
        { __typename?: 'PipelineModuleUpload' }
        & Pick<PipelineModuleUpload, 'id'>
      ) | (
        { __typename?: 'PipelineModuleBMobile' }
        & Pick<PipelineModuleBMobile, 'id'>
      ) | (
        { __typename?: 'PipelineModuleCCChecker' }
        & Pick<PipelineModuleCcChecker, 'id'>
      ) | (
        { __typename?: 'PipelineModuleContrastEnhancement' }
        & Pick<PipelineModuleContrastEnhancement, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
      ) }
    ) }
  ) | (
    { __typename?: 'CCCheckerResult' }
    & Pick<CcCheckerResult, 'groups' | 'isOkay' | 'id' | 'createdAt' | 'updatedAt'>
    & { researchBox: (
      { __typename?: 'ResearchBox' }
      & Pick<ResearchBox, 'id' | 'name'>
    ), producedBy: (
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'name' | 'dataType'>
      & { module: (
        { __typename?: 'PipelineModuleUpload' }
        & Pick<PipelineModuleUpload, 'id'>
      ) | (
        { __typename?: 'PipelineModuleBMobile' }
        & Pick<PipelineModuleBMobile, 'id'>
      ) | (
        { __typename?: 'PipelineModuleCCChecker' }
        & Pick<PipelineModuleCcChecker, 'id'>
      ) | (
        { __typename?: 'PipelineModuleContrastEnhancement' }
        & Pick<PipelineModuleContrastEnhancement, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
      ) }
    ) }
  ) | (
    { __typename?: 'MagneticFieldScan' }
    & Pick<MagneticFieldScan, 'width' | 'height' | 'startIndices' | 'hasBaseline' | 'binaryURL' | 'id' | 'createdAt' | 'updatedAt'>
    & { researchBox: (
      { __typename?: 'ResearchBox' }
      & Pick<ResearchBox, 'id' | 'name'>
    ), producedBy: (
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'name' | 'dataType'>
      & { module: (
        { __typename?: 'PipelineModuleUpload' }
        & Pick<PipelineModuleUpload, 'id'>
      ) | (
        { __typename?: 'PipelineModuleBMobile' }
        & Pick<PipelineModuleBMobile, 'id'>
      ) | (
        { __typename?: 'PipelineModuleCCChecker' }
        & Pick<PipelineModuleCcChecker, 'id'>
      ) | (
        { __typename?: 'PipelineModuleContrastEnhancement' }
        & Pick<PipelineModuleContrastEnhancement, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
      ) }
    ) }
  )>>> }
);

export type AllMarkupModulesWithNetworksForDatasetQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type AllMarkupModulesWithNetworksForDatasetQuery = (
  { __typename?: 'Query' }
  & { getDataset?: Maybe<(
    { __typename?: 'Dataset' }
    & Pick<Dataset, 'id'>
    & { pipeline?: Maybe<(
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
      & { modules?: Maybe<Array<{ __typename?: 'PipelineModuleUpload' } | { __typename?: 'PipelineModuleBMobile' } | { __typename?: 'PipelineModuleCCChecker' } | { __typename?: 'PipelineModuleContrastEnhancement' } | (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'id' | 'name'>
        & { classLabels: Array<(
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'id' | 'name'>
        & { classLabels: Array<(
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'id' | 'name'>
        & { classLabels: Array<(
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name'>
        )> }
      ) | { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' } | { __typename?: 'PipelineModuleNetworkImageSuperResolution' } | { __typename?: 'PipelineModuleNetworkImageOCR' } | { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' } | { __typename?: 'PipelineModuleNetworkImageObjectCounting' }>> }
    )> }
  )> }
);

export type FindDatasetByIdQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type FindDatasetByIdQuery = (
  { __typename?: 'Query' }
  & { getDataset?: Maybe<(
    { __typename?: 'Dataset' }
    & Pick<Dataset, 'id' | 'name' | 'description'>
    & { objects: Array<{ __typename?: 'Folder' } | (
      { __typename?: 'ResearchBox' }
      & Pick<ResearchBox, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    )> }
  )> }
);

export type GetDatasetNameQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetDatasetNameQuery = (
  { __typename?: 'Query' }
  & { getDataset?: Maybe<(
    { __typename?: 'Dataset' }
    & Pick<Dataset, 'name'>
  )> }
);

export type QueryFilteredResearchBoxesQueryVariables = Exact<{
  filter?: Maybe<ResearchBoxFilter>;
  order?: Maybe<ResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type QueryFilteredResearchBoxesQuery = (
  { __typename?: 'Query' }
  & { aggregateResearchBox?: Maybe<(
    { __typename?: 'ResearchBoxAggregateResult' }
    & Pick<ResearchBoxAggregateResult, 'count'>
  )>, queryResearchBox?: Maybe<Array<Maybe<(
    { __typename: 'ResearchBox' }
    & Pick<ResearchBox, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'parentIds' | 'owner' | 'containsMarkupForNetworkId' | 'containsPositiveMarkupForLabelId' | 'containsNegativeMarkupForLabelId'>
    & { dataset?: Maybe<(
      { __typename?: 'Dataset' }
      & Pick<Dataset, 'id' | 'name'>
    )>, datapoints?: Maybe<Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'thumbnail' | 'binaryURL' | 'filename' | 'width' | 'height' | 'id' | 'createdAt' | 'updatedAt'>
      & { resolutions: Array<(
        { __typename?: 'ImageResolution' }
        & Pick<ImageResolution, 'height' | 'width' | 'id' | 'binaryURL'>
      )>, predictions: Array<(
        { __typename: 'SegmentationPrediction' }
        & Pick<SegmentationPrediction, 'createdAt' | 'id'>
        & { segmentationMaps: Array<(
          { __typename?: 'SegmentationMap2D' }
          & Pick<SegmentationMap2D, 'thumbnail' | 'binaryURL'>
        )> }
      ) | (
        { __typename: 'ClassificationPrediction' }
        & Pick<ClassificationPrediction, 'createdAt' | 'id'>
      ) | (
        { __typename: 'ObjectDetectionPrediction' }
        & Pick<ObjectDetectionPrediction, 'createdAt' | 'id'>
      )>, markups: Array<(
        { __typename: 'SegmentationMarkup' }
        & Pick<SegmentationMarkup, 'id'>
        & { segmentationMaps: Array<(
          { __typename?: 'SegmentationMap2D' }
          & Pick<SegmentationMap2D, 'thumbnail' | 'id'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'id' | 'name' | 'color'>
          ) }
        )>, annotations: Array<(
          { __typename: 'CircleAnnotation' }
          & Pick<CircleAnnotation, 'radius' | 'id' | 'annotationType'>
          & { center: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PolygonAnnotation' }
          & Pick<PolygonAnnotation, 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'idx' | 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PenAnnotation' }
          & Pick<PenAnnotation, 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'RectangleAnnotation' }
          & Pick<RectangleAnnotation, 'id' | 'annotationType'>
          & { topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'SausageAnnotation' }
          & Pick<SausageAnnotation, 'radius' | 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'idx' | 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'MagicwandAnnotation' }
          & Pick<MagicwandAnnotation, 'threshold' | 'id' | 'annotationType'>
          & { center: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PixelAnnotation' }
          & Pick<PixelAnnotation, 'dataURL' | 'id' | 'annotationType'>
          & { topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        )> }
      ) | (
        { __typename: 'ClassificationMarkup' }
        & Pick<ClassificationMarkup, 'id'>
        & { annotations: Array<(
          { __typename?: 'ScalarAnnotation' }
          & Pick<ScalarAnnotation, 'id' | 'value'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        )> }
      ) | (
        { __typename: 'InstanceSegmentationMarkup' }
        & Pick<InstanceSegmentationMarkup, 'id'>
      ) | (
        { __typename: 'InstanceSegmentationPrediction' }
        & Pick<InstanceSegmentationPrediction, 'id'>
      ) | (
        { __typename: 'ObjectDetectionMarkup' }
        & Pick<ObjectDetectionMarkup, 'id'>
        & { annotations: Array<(
          { __typename?: 'BoundingBoxAnnotation' }
          & Pick<BoundingBoxAnnotation, 'id' | 'annotationType'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ), topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ) }
        )> }
      )>, producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'dataType' | 'name'>
      ) }
    ) | (
      { __typename?: 'CCCheckerResult' }
      & Pick<CcCheckerResult, 'groups' | 'isOkay' | 'id' | 'createdAt' | 'updatedAt'>
      & { producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'dataType' | 'name'>
      ) }
    ) | (
      { __typename?: 'MagneticFieldScan' }
      & Pick<MagneticFieldScan, 'width' | 'height' | 'startIndices' | 'hasBaseline' | 'binaryURL' | 'id' | 'createdAt' | 'updatedAt'>
      & { producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'dataType' | 'name'>
      ) }
    )>> }
  )>>> }
);

export type QueryResearchBoxesQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type QueryResearchBoxesQuery = (
  { __typename?: 'Query' }
  & { queryResearchBox?: Maybe<Array<Maybe<(
    { __typename: 'ResearchBox' }
    & Pick<ResearchBox, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'parentIds' | 'owner' | 'containsMarkupForNetworkId' | 'containsPositiveMarkupForLabelId' | 'containsNegativeMarkupForLabelId'>
    & { dataset?: Maybe<(
      { __typename?: 'Dataset' }
      & Pick<Dataset, 'id' | 'name'>
    )>, datapoints?: Maybe<Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'thumbnail' | 'binaryURL' | 'filename' | 'width' | 'height' | 'id' | 'createdAt' | 'updatedAt'>
      & { resolutions: Array<(
        { __typename?: 'ImageResolution' }
        & Pick<ImageResolution, 'height' | 'width' | 'id' | 'binaryURL'>
      )>, predictions: Array<(
        { __typename: 'SegmentationPrediction' }
        & Pick<SegmentationPrediction, 'createdAt' | 'id'>
        & { segmentationMaps: Array<(
          { __typename?: 'SegmentationMap2D' }
          & Pick<SegmentationMap2D, 'thumbnail' | 'binaryURL'>
        )> }
      ) | (
        { __typename: 'ClassificationPrediction' }
        & Pick<ClassificationPrediction, 'createdAt' | 'id'>
      ) | (
        { __typename: 'ObjectDetectionPrediction' }
        & Pick<ObjectDetectionPrediction, 'createdAt' | 'id'>
      )>, markups: Array<(
        { __typename: 'SegmentationMarkup' }
        & Pick<SegmentationMarkup, 'id'>
        & { segmentationMaps: Array<(
          { __typename?: 'SegmentationMap2D' }
          & Pick<SegmentationMap2D, 'thumbnail' | 'id'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'id' | 'name' | 'color'>
          ) }
        )>, annotations: Array<(
          { __typename: 'CircleAnnotation' }
          & Pick<CircleAnnotation, 'radius' | 'id' | 'annotationType'>
          & { center: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PolygonAnnotation' }
          & Pick<PolygonAnnotation, 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'idx' | 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PenAnnotation' }
          & Pick<PenAnnotation, 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'RectangleAnnotation' }
          & Pick<RectangleAnnotation, 'id' | 'annotationType'>
          & { topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'SausageAnnotation' }
          & Pick<SausageAnnotation, 'radius' | 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'idx' | 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'MagicwandAnnotation' }
          & Pick<MagicwandAnnotation, 'threshold' | 'id' | 'annotationType'>
          & { center: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PixelAnnotation' }
          & Pick<PixelAnnotation, 'dataURL' | 'id' | 'annotationType'>
          & { topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        )> }
      ) | (
        { __typename: 'ClassificationMarkup' }
        & Pick<ClassificationMarkup, 'id'>
        & { annotations: Array<(
          { __typename?: 'ScalarAnnotation' }
          & Pick<ScalarAnnotation, 'id' | 'value'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        )> }
      ) | (
        { __typename: 'InstanceSegmentationMarkup' }
        & Pick<InstanceSegmentationMarkup, 'id'>
      ) | (
        { __typename: 'InstanceSegmentationPrediction' }
        & Pick<InstanceSegmentationPrediction, 'id'>
      ) | (
        { __typename: 'ObjectDetectionMarkup' }
        & Pick<ObjectDetectionMarkup, 'id'>
        & { annotations: Array<(
          { __typename?: 'BoundingBoxAnnotation' }
          & Pick<BoundingBoxAnnotation, 'id' | 'annotationType'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ), topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ) }
        )> }
      )>, producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'dataType' | 'name'>
      ) }
    ) | (
      { __typename?: 'CCCheckerResult' }
      & Pick<CcCheckerResult, 'groups' | 'isOkay' | 'id' | 'createdAt' | 'updatedAt'>
      & { producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'dataType' | 'name'>
      ) }
    ) | (
      { __typename?: 'MagneticFieldScan' }
      & Pick<MagneticFieldScan, 'width' | 'height' | 'startIndices' | 'hasBaseline' | 'binaryURL' | 'id' | 'createdAt' | 'updatedAt'>
      & { producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'dataType' | 'name'>
      ) }
    )>> }
  )>>> }
);

export type QueryFilteredResearchBoxIdsQueryVariables = Exact<{
  filter?: Maybe<ResearchBoxFilter>;
}>;


export type QueryFilteredResearchBoxIdsQuery = (
  { __typename?: 'Query' }
  & { queryResearchBox?: Maybe<Array<Maybe<(
    { __typename?: 'ResearchBox' }
    & Pick<ResearchBox, 'id'>
  )>>> }
);

export type QueryFilteredFoldersOrResearchBoxesQueryVariables = Exact<{
  filter?: Maybe<FolderOrResearchBoxFilter>;
  order?: Maybe<FolderOrResearchBoxOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type QueryFilteredFoldersOrResearchBoxesQuery = (
  { __typename?: 'Query' }
  & { aggregateFolderOrResearchBox?: Maybe<(
    { __typename?: 'FolderOrResearchBoxAggregateResult' }
    & Pick<FolderOrResearchBoxAggregateResult, 'count'>
  )>, queryFolderOrResearchBox?: Maybe<Array<Maybe<(
    { __typename: 'Folder' }
    & Pick<Folder, 'description' | 'state' | 'id' | 'name' | 'createdAt' | 'parentIds'>
    & { objects: Array<{ __typename?: 'Folder' } | (
      { __typename?: 'ResearchBox' }
      & { datapoints?: Maybe<Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'thumbnail'>
      ) | { __typename?: 'CCCheckerResult' } | { __typename?: 'MagneticFieldScan' }>> }
    )> }
  ) | (
    { __typename: 'ResearchBox' }
    & Pick<ResearchBox, 'updatedAt' | 'containsMarkupForNetworkId' | 'containsPositiveMarkupForLabelId' | 'containsNegativeMarkupForLabelId' | 'owner' | 'id' | 'name' | 'createdAt' | 'parentIds'>
    & { dataset?: Maybe<(
      { __typename?: 'Dataset' }
      & Pick<Dataset, 'id' | 'name'>
    )>, datapoints?: Maybe<Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'thumbnail' | 'binaryURL' | 'filename' | 'width' | 'height' | 'id' | 'createdAt' | 'updatedAt'>
      & { resolutions: Array<(
        { __typename?: 'ImageResolution' }
        & Pick<ImageResolution, 'height' | 'width' | 'id' | 'binaryURL'>
      )>, predictions: Array<(
        { __typename: 'SegmentationPrediction' }
        & Pick<SegmentationPrediction, 'createdAt' | 'id'>
        & { segmentationMaps: Array<(
          { __typename?: 'SegmentationMap2D' }
          & Pick<SegmentationMap2D, 'thumbnail' | 'binaryURL'>
        )> }
      ) | (
        { __typename: 'ClassificationPrediction' }
        & Pick<ClassificationPrediction, 'createdAt' | 'id'>
      ) | (
        { __typename: 'ObjectDetectionPrediction' }
        & Pick<ObjectDetectionPrediction, 'createdAt' | 'id'>
      )>, markups: Array<(
        { __typename: 'SegmentationMarkup' }
        & Pick<SegmentationMarkup, 'id'>
        & { segmentationMaps: Array<(
          { __typename?: 'SegmentationMap2D' }
          & Pick<SegmentationMap2D, 'thumbnail' | 'id'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'id' | 'name' | 'color'>
          ) }
        )>, annotations: Array<(
          { __typename: 'CircleAnnotation' }
          & Pick<CircleAnnotation, 'radius' | 'id' | 'annotationType'>
          & { center: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PolygonAnnotation' }
          & Pick<PolygonAnnotation, 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'idx' | 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PenAnnotation' }
          & Pick<PenAnnotation, 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'RectangleAnnotation' }
          & Pick<RectangleAnnotation, 'id' | 'annotationType'>
          & { topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'SausageAnnotation' }
          & Pick<SausageAnnotation, 'radius' | 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'idx' | 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'MagicwandAnnotation' }
          & Pick<MagicwandAnnotation, 'threshold' | 'id' | 'annotationType'>
          & { center: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PixelAnnotation' }
          & Pick<PixelAnnotation, 'dataURL' | 'id' | 'annotationType'>
          & { topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        )> }
      ) | (
        { __typename: 'ClassificationMarkup' }
        & Pick<ClassificationMarkup, 'id'>
        & { annotations: Array<(
          { __typename?: 'ScalarAnnotation' }
          & Pick<ScalarAnnotation, 'id' | 'value'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        )> }
      ) | (
        { __typename: 'InstanceSegmentationMarkup' }
        & Pick<InstanceSegmentationMarkup, 'id'>
      ) | (
        { __typename: 'InstanceSegmentationPrediction' }
        & Pick<InstanceSegmentationPrediction, 'id'>
      ) | (
        { __typename: 'ObjectDetectionMarkup' }
        & Pick<ObjectDetectionMarkup, 'id'>
        & { annotations: Array<(
          { __typename?: 'BoundingBoxAnnotation' }
          & Pick<BoundingBoxAnnotation, 'id' | 'annotationType'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ), topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ) }
        )> }
      )>, producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'dataType' | 'name'>
      ) }
    ) | (
      { __typename?: 'CCCheckerResult' }
      & Pick<CcCheckerResult, 'groups' | 'isOkay' | 'id' | 'createdAt' | 'updatedAt'>
      & { producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'dataType' | 'name'>
      ) }
    ) | (
      { __typename?: 'MagneticFieldScan' }
      & Pick<MagneticFieldScan, 'width' | 'height' | 'startIndices' | 'hasBaseline' | 'binaryURL' | 'id' | 'createdAt' | 'updatedAt'>
      & { producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'dataType' | 'name'>
      ) }
    )>> }
  )>>> }
);

export type CheckResearchBoxLabelQueryVariables = Exact<{
  label: Scalars['String'];
}>;


export type CheckResearchBoxLabelQuery = (
  { __typename?: 'Query' }
  & { aggregateResearchBox?: Maybe<(
    { __typename?: 'ResearchBoxAggregateResult' }
    & Pick<ResearchBoxAggregateResult, 'count'>
  )> }
);

export type ResearchBoxesByDatasetIdQueryVariables = Exact<{
  datasetIdRegexp: Scalars['String'];
}>;


export type ResearchBoxesByDatasetIdQuery = (
  { __typename?: 'Query' }
  & { queryResearchBox?: Maybe<Array<Maybe<(
    { __typename?: 'ResearchBox' }
    & Pick<ResearchBox, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'parentIds' | 'owner'>
    & { dataset?: Maybe<(
      { __typename?: 'Dataset' }
      & Pick<Dataset, 'id' | 'name'>
    )>, datapoints?: Maybe<Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'thumbnail' | 'binaryURL' | 'filename' | 'width' | 'height' | 'id' | 'createdAt' | 'updatedAt'>
      & { resolutions: Array<(
        { __typename?: 'ImageResolution' }
        & Pick<ImageResolution, 'height' | 'width' | 'id' | 'binaryURL'>
      )>, predictions: Array<(
        { __typename: 'SegmentationPrediction' }
        & Pick<SegmentationPrediction, 'createdAt' | 'id'>
        & { segmentationMaps: Array<(
          { __typename?: 'SegmentationMap2D' }
          & Pick<SegmentationMap2D, 'thumbnail' | 'binaryURL'>
        )> }
      ) | (
        { __typename: 'ClassificationPrediction' }
        & Pick<ClassificationPrediction, 'createdAt' | 'id'>
      ) | (
        { __typename: 'ObjectDetectionPrediction' }
        & Pick<ObjectDetectionPrediction, 'createdAt' | 'id'>
      )>, markups: Array<(
        { __typename: 'SegmentationMarkup' }
        & Pick<SegmentationMarkup, 'id'>
        & { segmentationMaps: Array<(
          { __typename?: 'SegmentationMap2D' }
          & Pick<SegmentationMap2D, 'thumbnail' | 'id'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'id' | 'name' | 'color'>
          ) }
        )>, annotations: Array<(
          { __typename: 'CircleAnnotation' }
          & Pick<CircleAnnotation, 'radius' | 'id' | 'annotationType'>
          & { center: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PolygonAnnotation' }
          & Pick<PolygonAnnotation, 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'idx' | 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PenAnnotation' }
          & Pick<PenAnnotation, 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'RectangleAnnotation' }
          & Pick<RectangleAnnotation, 'id' | 'annotationType'>
          & { topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'SausageAnnotation' }
          & Pick<SausageAnnotation, 'radius' | 'id' | 'annotationType'>
          & { points: Array<(
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'idx' | 'x' | 'y'>
          )>, label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'MagicwandAnnotation' }
          & Pick<MagicwandAnnotation, 'threshold' | 'id' | 'annotationType'>
          & { center: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        ) | (
          { __typename: 'PixelAnnotation' }
          & Pick<PixelAnnotation, 'dataURL' | 'id' | 'annotationType'>
          & { topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        )> }
      ) | (
        { __typename: 'ClassificationMarkup' }
        & Pick<ClassificationMarkup, 'id'>
        & { annotations: Array<(
          { __typename?: 'ScalarAnnotation' }
          & Pick<ScalarAnnotation, 'id' | 'value'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ) }
        )> }
      ) | (
        { __typename: 'InstanceSegmentationMarkup' }
        & Pick<InstanceSegmentationMarkup, 'id'>
      ) | (
        { __typename: 'InstanceSegmentationPrediction' }
        & Pick<InstanceSegmentationPrediction, 'id'>
      ) | (
        { __typename: 'ObjectDetectionMarkup' }
        & Pick<ObjectDetectionMarkup, 'id'>
        & { annotations: Array<(
          { __typename?: 'BoundingBoxAnnotation' }
          & Pick<BoundingBoxAnnotation, 'id' | 'annotationType'>
          & { label: (
            { __typename?: 'MarkupLabel' }
            & Pick<MarkupLabel, 'name' | 'color'>
          ), topLeft: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ), bottomRight: (
            { __typename?: 'Point2D' }
            & Pick<Point2D, 'x' | 'y'>
          ) }
        )> }
      )>, producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'dataType' | 'name'>
      ) }
    ) | (
      { __typename?: 'CCCheckerResult' }
      & Pick<CcCheckerResult, 'groups' | 'isOkay' | 'id' | 'createdAt' | 'updatedAt'>
      & { producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'dataType' | 'name'>
      ) }
    ) | (
      { __typename?: 'MagneticFieldScan' }
      & Pick<MagneticFieldScan, 'width' | 'height' | 'startIndices' | 'hasBaseline' | 'binaryURL' | 'id' | 'createdAt' | 'updatedAt'>
      & { producedBy: (
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'dataType' | 'name'>
      ) }
    )>> }
  )>>> }
);

export type GetPipelineQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetPipelineQuery = (
  { __typename?: 'Query' }
  & { getDataset?: Maybe<(
    { __typename?: 'Dataset' }
    & Pick<Dataset, 'owner'>
    & { pipeline?: Maybe<(
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
      & { modules?: Maybe<Array<(
        { __typename: 'PipelineModuleUpload' }
        & Pick<PipelineModuleUpload, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleBMobile' }
        & Pick<PipelineModuleBMobile, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleCCChecker' }
        & Pick<PipelineModuleCcChecker, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleContrastEnhancement' }
        & Pick<PipelineModuleContrastEnhancement, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      )>> }
    )> }
  )> }
);

export type SubscribePipelineSubscriptionVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type SubscribePipelineSubscription = (
  { __typename?: 'Subscription' }
  & { getDataset?: Maybe<(
    { __typename?: 'Dataset' }
    & Pick<Dataset, 'id'>
    & { pipeline?: Maybe<(
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
      & { modules?: Maybe<Array<(
        { __typename: 'PipelineModuleUpload' }
        & Pick<PipelineModuleUpload, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleBMobile' }
        & Pick<PipelineModuleBMobile, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleCCChecker' }
        & Pick<PipelineModuleCcChecker, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleContrastEnhancement' }
        & Pick<PipelineModuleContrastEnhancement, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      ) | (
        { __typename: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'owner'>
        & { inputs: Array<(
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id' | 'name'>
        )>, outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
        )>, layoutPosition: (
          { __typename?: 'Position' }
          & Pick<Position, 'x' | 'y'>
        ) }
      )>>, edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
          & { module: (
            { __typename?: 'PipelineModuleUpload' }
            & Pick<PipelineModuleUpload, 'id'>
          ) | (
            { __typename?: 'PipelineModuleBMobile' }
            & Pick<PipelineModuleBMobile, 'id'>
          ) | (
            { __typename?: 'PipelineModuleCCChecker' }
            & Pick<PipelineModuleCcChecker, 'id'>
          ) | (
            { __typename?: 'PipelineModuleContrastEnhancement' }
            & Pick<PipelineModuleContrastEnhancement, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageSegmentation' }
            & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageClassification' }
            & Pick<PipelineModuleNetworkImageClassification, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
            & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
            & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
            & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageOCR' }
            & Pick<PipelineModuleNetworkImageOcr, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
            & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
            & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
          ) }
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
          & { module: (
            { __typename?: 'PipelineModuleUpload' }
            & Pick<PipelineModuleUpload, 'id'>
          ) | (
            { __typename?: 'PipelineModuleBMobile' }
            & Pick<PipelineModuleBMobile, 'id'>
          ) | (
            { __typename?: 'PipelineModuleCCChecker' }
            & Pick<PipelineModuleCcChecker, 'id'>
          ) | (
            { __typename?: 'PipelineModuleContrastEnhancement' }
            & Pick<PipelineModuleContrastEnhancement, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageSegmentation' }
            & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageClassification' }
            & Pick<PipelineModuleNetworkImageClassification, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
            & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
            & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
            & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageOCR' }
            & Pick<PipelineModuleNetworkImageOcr, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
            & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
            & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
          ) }
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
          & { module: (
            { __typename?: 'PipelineModuleUpload' }
            & Pick<PipelineModuleUpload, 'id'>
          ) | (
            { __typename?: 'PipelineModuleBMobile' }
            & Pick<PipelineModuleBMobile, 'id'>
          ) | (
            { __typename?: 'PipelineModuleCCChecker' }
            & Pick<PipelineModuleCcChecker, 'id'>
          ) | (
            { __typename?: 'PipelineModuleContrastEnhancement' }
            & Pick<PipelineModuleContrastEnhancement, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageSegmentation' }
            & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageClassification' }
            & Pick<PipelineModuleNetworkImageClassification, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
            & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
            & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
            & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageOCR' }
            & Pick<PipelineModuleNetworkImageOcr, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
            & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
          ) | (
            { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
            & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
          ) }
        ) }
      )> }
    )> }
  )> }
);

export type GetPipelineModuleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPipelineModuleQuery = (
  { __typename?: 'Query' }
  & { queryPipelineModule?: Maybe<Array<Maybe<(
    { __typename: 'PipelineModuleUpload' }
    & Pick<PipelineModuleUpload, 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'name' | 'description'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & Pick<PipelineModuleInput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ) }
  ) | (
    { __typename: 'PipelineModuleBMobile' }
    & Pick<PipelineModuleBMobile, 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'name' | 'description'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & Pick<PipelineModuleInput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ) }
  ) | (
    { __typename: 'PipelineModuleCCChecker' }
    & Pick<PipelineModuleCcChecker, 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'name' | 'description'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & Pick<PipelineModuleInput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ) }
  ) | (
    { __typename: 'PipelineModuleContrastEnhancement' }
    & Pick<PipelineModuleContrastEnhancement, 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'name' | 'description'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & Pick<PipelineModuleInput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ) }
  ) | (
    { __typename: 'PipelineModuleNetworkImageSegmentation' }
    & Pick<PipelineModuleNetworkImageSegmentation, 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'name' | 'description'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & Pick<PipelineModuleInput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ) }
  ) | (
    { __typename: 'PipelineModuleNetworkImageClassification' }
    & Pick<PipelineModuleNetworkImageClassification, 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'name' | 'description'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & Pick<PipelineModuleInput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ) }
  ) | (
    { __typename: 'PipelineModuleNetworkImageObjectDetection' }
    & Pick<PipelineModuleNetworkImageObjectDetection, 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'name' | 'description'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & Pick<PipelineModuleInput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ) }
  ) | (
    { __typename: 'PipelineModuleNetworkImageInstanceSegmentation' }
    & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'name' | 'description'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & Pick<PipelineModuleInput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ) }
  ) | (
    { __typename: 'PipelineModuleNetworkImageSuperResolution' }
    & Pick<PipelineModuleNetworkImageSuperResolution, 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'name' | 'description'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & Pick<PipelineModuleInput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ) }
  ) | (
    { __typename: 'PipelineModuleNetworkImageOCR' }
    & Pick<PipelineModuleNetworkImageOcr, 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'name' | 'description'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & Pick<PipelineModuleInput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ) }
  ) | (
    { __typename: 'PipelineModuleNetworkImageAnomalyDetection' }
    & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'name' | 'description'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & Pick<PipelineModuleInput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ) }
  ) | (
    { __typename: 'PipelineModuleNetworkImageObjectCounting' }
    & Pick<PipelineModuleNetworkImageObjectCounting, 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'name' | 'description'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & Pick<PipelineModuleInput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ), to: (
          { __typename?: 'PipelineModuleInput' }
          & Pick<PipelineModuleInput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ) }
  )>>> }
);

export type GetAllNamesAndDatatypesForDatasetQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetAllNamesAndDatatypesForDatasetQuery = (
  { __typename?: 'Query' }
  & { getDataset?: Maybe<(
    { __typename?: 'Dataset' }
    & Pick<Dataset, 'id'>
    & { pipeline?: Maybe<(
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
      & { modules?: Maybe<Array<(
        { __typename?: 'PipelineModuleUpload' }
        & Pick<PipelineModuleUpload, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
          & { datapoints: Array<{ __typename: 'Image' } | { __typename: 'CCCheckerResult' } | { __typename: 'MagneticFieldScan' }> }
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleBMobile' }
        & Pick<PipelineModuleBMobile, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
          & { datapoints: Array<{ __typename: 'Image' } | { __typename: 'CCCheckerResult' } | { __typename: 'MagneticFieldScan' }> }
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleCCChecker' }
        & Pick<PipelineModuleCcChecker, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
          & { datapoints: Array<{ __typename: 'Image' } | { __typename: 'CCCheckerResult' } | { __typename: 'MagneticFieldScan' }> }
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleContrastEnhancement' }
        & Pick<PipelineModuleContrastEnhancement, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
          & { datapoints: Array<{ __typename: 'Image' } | { __typename: 'CCCheckerResult' } | { __typename: 'MagneticFieldScan' }> }
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
          & { datapoints: Array<{ __typename: 'Image' } | { __typename: 'CCCheckerResult' } | { __typename: 'MagneticFieldScan' }> }
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
          & { datapoints: Array<{ __typename: 'Image' } | { __typename: 'CCCheckerResult' } | { __typename: 'MagneticFieldScan' }> }
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
          & { datapoints: Array<{ __typename: 'Image' } | { __typename: 'CCCheckerResult' } | { __typename: 'MagneticFieldScan' }> }
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
          & { datapoints: Array<{ __typename: 'Image' } | { __typename: 'CCCheckerResult' } | { __typename: 'MagneticFieldScan' }> }
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
          & { datapoints: Array<{ __typename: 'Image' } | { __typename: 'CCCheckerResult' } | { __typename: 'MagneticFieldScan' }> }
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
          & { datapoints: Array<{ __typename: 'Image' } | { __typename: 'CCCheckerResult' } | { __typename: 'MagneticFieldScan' }> }
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
          & { datapoints: Array<{ __typename: 'Image' } | { __typename: 'CCCheckerResult' } | { __typename: 'MagneticFieldScan' }> }
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
          & { datapoints: Array<{ __typename: 'Image' } | { __typename: 'CCCheckerResult' } | { __typename: 'MagneticFieldScan' }> }
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      )>> }
    )> }
  )> }
);

export type GetPretrainedNetworkQueryVariables = Exact<{
  networkId?: Maybe<Scalars['String']>;
}>;


export type GetPretrainedNetworkQuery = (
  { __typename?: 'Query' }
  & { getPretrainedNetwork?: Maybe<(
    { __typename: 'PretrainedNetwork' }
    & Pick<PretrainedNetwork, 'id' | 'createdAt' | 'updatedAt' | 'thumbnail'>
    & { networkModule: (
      { __typename: 'PipelineModuleNetworkImageSegmentation' }
      & Pick<PipelineModuleNetworkImageSegmentation, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageClassification' }
      & Pick<PipelineModuleNetworkImageClassification, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageObjectDetection' }
      & Pick<PipelineModuleNetworkImageObjectDetection, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageInstanceSegmentation' }
      & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageSuperResolution' }
      & Pick<PipelineModuleNetworkImageSuperResolution, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageOCR' }
      & Pick<PipelineModuleNetworkImageOcr, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageAnomalyDetection' }
      & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageObjectCounting' }
      & Pick<PipelineModuleNetworkImageObjectCounting, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) }
  )> }
);

export type GetPretrainedNetworkForModuleQueryVariables = Exact<{
  pipelineModuleId?: Maybe<Scalars['String']>;
}>;


export type GetPretrainedNetworkForModuleQuery = (
  { __typename?: 'Query' }
  & { queryPretrainedNetwork?: Maybe<Array<Maybe<(
    { __typename: 'PretrainedNetwork' }
    & Pick<PretrainedNetwork, 'id' | 'createdAt' | 'updatedAt' | 'thumbnail'>
    & { networkModule: (
      { __typename: 'PipelineModuleNetworkImageSegmentation' }
      & Pick<PipelineModuleNetworkImageSegmentation, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageClassification' }
      & Pick<PipelineModuleNetworkImageClassification, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageObjectDetection' }
      & Pick<PipelineModuleNetworkImageObjectDetection, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageInstanceSegmentation' }
      & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageSuperResolution' }
      & Pick<PipelineModuleNetworkImageSuperResolution, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageOCR' }
      & Pick<PipelineModuleNetworkImageOcr, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageAnomalyDetection' }
      & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) | (
      { __typename: 'PipelineModuleNetworkImageObjectCounting' }
      & Pick<PipelineModuleNetworkImageObjectCounting, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
      & { classLabels: Array<(
        { __typename?: 'MarkupLabel' }
        & Pick<MarkupLabel, 'id' | 'name' | 'color'>
      )> }
    ) }
  )>>> }
);

export type GetDatasetIdByUsernameQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type GetDatasetIdByUsernameQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'username'>
    & { datasets: Array<(
      { __typename?: 'Dataset' }
      & Pick<Dataset, 'id'>
    )> }
  )> }
);

export type QueryNetworksForUserHalmQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type QueryNetworksForUserHalmQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & { datasets: Array<(
      { __typename?: 'Dataset' }
      & Pick<Dataset, 'id'>
      & { pipeline?: Maybe<(
        { __typename?: 'Pipeline' }
        & Pick<Pipeline, 'id'>
        & { modules?: Maybe<Array<{ __typename?: 'PipelineModuleUpload' } | { __typename?: 'PipelineModuleBMobile' } | { __typename?: 'PipelineModuleCCChecker' } | { __typename?: 'PipelineModuleContrastEnhancement' } | (
          { __typename?: 'PipelineModuleNetworkImageSegmentation' }
          & Pick<PipelineModuleNetworkImageSegmentation, 'id' | 'name' | 'description'>
        ) | (
          { __typename?: 'PipelineModuleNetworkImageClassification' }
          & Pick<PipelineModuleNetworkImageClassification, 'id' | 'name' | 'description'>
        ) | { __typename?: 'PipelineModuleNetworkImageObjectDetection' } | { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' } | { __typename?: 'PipelineModuleNetworkImageSuperResolution' } | { __typename?: 'PipelineModuleNetworkImageOCR' } | { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' } | { __typename?: 'PipelineModuleNetworkImageObjectCounting' }>> }
      )> }
    )> }
  )> }
);

export type AllMarkupModulesQueryVariables = Exact<{
  pipelineId: Scalars['String'];
}>;


export type AllMarkupModulesQuery = (
  { __typename?: 'Query' }
  & { getPipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & { modules?: Maybe<Array<{ __typename?: 'PipelineModuleUpload' } | { __typename?: 'PipelineModuleBMobile' } | { __typename?: 'PipelineModuleCCChecker' } | { __typename?: 'PipelineModuleContrastEnhancement' } | (
      { __typename?: 'PipelineModuleNetworkImageSegmentation' }
      & Pick<PipelineModuleNetworkImageSegmentation, 'id' | 'name' | 'description'>
      & { inputs: Array<(
        { __typename?: 'PipelineModuleInput' }
        & Pick<PipelineModuleInput, 'id' | 'name' | 'dataType'>
        & { edges: Array<(
          { __typename?: 'PipelineModuleEdge' }
          & { from: (
            { __typename?: 'PipelineModuleDatapointOutput' }
            & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) | (
            { __typename?: 'PipelineModuleArtifactOutput' }
            & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) }
        )> }
      )>, outputs: Array<(
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        & { edges: Array<(
          { __typename?: 'PipelineModuleEdge' }
          & { to: (
            { __typename?: 'PipelineModuleInput' }
            & Pick<PipelineModuleInput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) }
        )> }
      ) | (
        { __typename?: 'PipelineModuleArtifactOutput' }
        & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        & { edges: Array<(
          { __typename?: 'PipelineModuleEdge' }
          & { to: (
            { __typename?: 'PipelineModuleInput' }
            & Pick<PipelineModuleInput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'PipelineModuleNetworkImageClassification' }
      & Pick<PipelineModuleNetworkImageClassification, 'id' | 'name' | 'description'>
      & { inputs: Array<(
        { __typename?: 'PipelineModuleInput' }
        & Pick<PipelineModuleInput, 'id' | 'name' | 'dataType'>
        & { edges: Array<(
          { __typename?: 'PipelineModuleEdge' }
          & { from: (
            { __typename?: 'PipelineModuleDatapointOutput' }
            & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) | (
            { __typename?: 'PipelineModuleArtifactOutput' }
            & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) }
        )> }
      )>, outputs: Array<(
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        & { edges: Array<(
          { __typename?: 'PipelineModuleEdge' }
          & { to: (
            { __typename?: 'PipelineModuleInput' }
            & Pick<PipelineModuleInput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) }
        )> }
      ) | (
        { __typename?: 'PipelineModuleArtifactOutput' }
        & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        & { edges: Array<(
          { __typename?: 'PipelineModuleEdge' }
          & { to: (
            { __typename?: 'PipelineModuleInput' }
            & Pick<PipelineModuleInput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
      & Pick<PipelineModuleNetworkImageObjectDetection, 'id' | 'name' | 'description'>
      & { inputs: Array<(
        { __typename?: 'PipelineModuleInput' }
        & Pick<PipelineModuleInput, 'id' | 'name' | 'dataType'>
        & { edges: Array<(
          { __typename?: 'PipelineModuleEdge' }
          & { from: (
            { __typename?: 'PipelineModuleDatapointOutput' }
            & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) | (
            { __typename?: 'PipelineModuleArtifactOutput' }
            & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) }
        )> }
      )>, outputs: Array<(
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        & { edges: Array<(
          { __typename?: 'PipelineModuleEdge' }
          & { to: (
            { __typename?: 'PipelineModuleInput' }
            & Pick<PipelineModuleInput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) }
        )> }
      ) | (
        { __typename?: 'PipelineModuleArtifactOutput' }
        & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        & { edges: Array<(
          { __typename?: 'PipelineModuleEdge' }
          & { to: (
            { __typename?: 'PipelineModuleInput' }
            & Pick<PipelineModuleInput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
      & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id' | 'name' | 'description'>
      & { inputs: Array<(
        { __typename?: 'PipelineModuleInput' }
        & Pick<PipelineModuleInput, 'id' | 'name' | 'dataType'>
        & { edges: Array<(
          { __typename?: 'PipelineModuleEdge' }
          & { from: (
            { __typename?: 'PipelineModuleDatapointOutput' }
            & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) | (
            { __typename?: 'PipelineModuleArtifactOutput' }
            & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) }
        )> }
      )>, outputs: Array<(
        { __typename?: 'PipelineModuleDatapointOutput' }
        & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        & { edges: Array<(
          { __typename?: 'PipelineModuleEdge' }
          & { to: (
            { __typename?: 'PipelineModuleInput' }
            & Pick<PipelineModuleInput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) }
        )> }
      ) | (
        { __typename?: 'PipelineModuleArtifactOutput' }
        & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        & { edges: Array<(
          { __typename?: 'PipelineModuleEdge' }
          & { to: (
            { __typename?: 'PipelineModuleInput' }
            & Pick<PipelineModuleInput, 'id' | 'name' | 'dataType'>
            & { module: (
              { __typename?: 'PipelineModuleUpload' }
              & Pick<PipelineModuleUpload, 'id'>
            ) | (
              { __typename?: 'PipelineModuleBMobile' }
              & Pick<PipelineModuleBMobile, 'id'>
            ) | (
              { __typename?: 'PipelineModuleCCChecker' }
              & Pick<PipelineModuleCcChecker, 'id'>
            ) | (
              { __typename?: 'PipelineModuleContrastEnhancement' }
              & Pick<PipelineModuleContrastEnhancement, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSegmentation' }
              & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageClassification' }
              & Pick<PipelineModuleNetworkImageClassification, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
              & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
              & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
              & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageOCR' }
              & Pick<PipelineModuleNetworkImageOcr, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
              & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
            ) | (
              { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
              & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
            ) }
          ) }
        )> }
      )> }
    ) | { __typename?: 'PipelineModuleNetworkImageSuperResolution' } | { __typename?: 'PipelineModuleNetworkImageOCR' } | { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' } | { __typename?: 'PipelineModuleNetworkImageObjectCounting' }>> }
  )> }
);

export type FindpipelineIdByDatasetIdQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type FindpipelineIdByDatasetIdQuery = (
  { __typename?: 'Query' }
  & { getDataset?: Maybe<(
    { __typename?: 'Dataset' }
    & Pick<Dataset, 'id'>
    & { pipeline?: Maybe<(
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    )> }
  )> }
);

export type FindDatasetIdByPipelineIdQueryVariables = Exact<{
  pipelineId: Scalars['String'];
}>;


export type FindDatasetIdByPipelineIdQuery = (
  { __typename?: 'Query' }
  & { getPipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id'>
    & { dataset: (
      { __typename?: 'Dataset' }
      & Pick<Dataset, 'id'>
    ) }
  )> }
);

export type QueryUnreadNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryUnreadNotificationsQuery = (
  { __typename?: 'Query' }
  & { queryNotification?: Maybe<Array<Maybe<(
    { __typename: 'ProgressNotification' }
    & Pick<ProgressNotification, 'currentProgress' | 'maxProgress' | 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'title' | 'message' | 'error' | 'read'>
  ) | (
    { __typename: 'DenktesterNotification' }
    & Pick<DenktesterNotification, 'currentProgress' | 'maxProgress' | 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'title' | 'message' | 'error' | 'read'>
  ) | (
    { __typename: 'MaintenanceNotification' }
    & Pick<MaintenanceNotification, 'finished' | 'type' | 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'title' | 'message' | 'error' | 'read'>
  ) | (
    { __typename: 'OneOffNotification' }
    & Pick<OneOffNotification, 'id' | 'createdAt' | 'updatedAt' | 'owner' | 'title' | 'message' | 'error' | 'read'>
  )>>> }
);

export type QueryBuildInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryBuildInfoQuery = (
  { __typename?: 'Query' }
  & { queryBuildInfo?: Maybe<(
    { __typename?: 'QueryBuildInfoPayload' }
    & Pick<QueryBuildInfoPayload, 'gitCommitHash' | 'versionTag' | 'buildDate'>
  )> }
);

export type QueryDenkTesterProgressQueryVariables = Exact<{
  networkId: Scalars['String'];
}>;


export type QueryDenkTesterProgressQuery = (
  { __typename?: 'Query' }
  & { queryDenktesterNotification?: Maybe<Array<Maybe<(
    { __typename?: 'DenktesterNotification' }
    & Pick<DenktesterNotification, 'currentProgress' | 'maxProgress' | 'updatedAt'>
  )>>> }
);

export type GetPipelineModuleCcCheckerQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPipelineModuleCcCheckerQuery = (
  { __typename?: 'Query' }
  & { getPipelineModuleCCChecker?: Maybe<(
    { __typename?: 'PipelineModuleCCChecker' }
    & Pick<PipelineModuleCcChecker, 'id' | 'name' | 'description' | 'busBarCountPerCell' | 'maxShift' | 'minCorrValue' | 'cutOffRate' | 'minPeakDistance' | 'minPeakProminence' | 'minPeakHeightPercentage'>
    & { layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ), pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'name'>
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'name'>
    )> }
  )> }
);

export type GetPipelineModuleContrastEnhancementQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPipelineModuleContrastEnhancementQuery = (
  { __typename?: 'Query' }
  & { getPipelineModuleContrastEnhancement?: Maybe<(
    { __typename?: 'PipelineModuleContrastEnhancement' }
    & Pick<PipelineModuleContrastEnhancement, 'id' | 'name' | 'description' | 'clipLimit' | 'tileSize'>
    & { layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ), pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'name'>
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'name'>
    )> }
  )> }
);

export type GetPipelineModuleNetworkImageClassificationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPipelineModuleNetworkImageClassificationQuery = (
  { __typename?: 'Query' }
  & { getPipelineModuleNetworkImageClassification?: Maybe<(
    { __typename?: 'PipelineModuleNetworkImageClassification' }
    & Pick<PipelineModuleNetworkImageClassification, 'id' | 'name' | 'description'>
    & { inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & Pick<PipelineModuleInput, 'id'>
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & Pick<PipelineModuleEdge, 'id'>
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ), pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name'>
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name'>
    )>, classLabels: Array<(
      { __typename?: 'MarkupLabel' }
      & Pick<MarkupLabel, 'id' | 'name' | 'color'>
    )> }
  )> }
);

export type GetPipelineModuleNetworkImageObjectDetectionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPipelineModuleNetworkImageObjectDetectionQuery = (
  { __typename?: 'Query' }
  & { getPipelineModuleNetworkImageObjectDetection?: Maybe<(
    { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
    & Pick<PipelineModuleNetworkImageObjectDetection, 'id' | 'name' | 'description'>
    & { inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ), pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'name'>
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'name'>
    )>, classLabels: Array<(
      { __typename?: 'MarkupLabel' }
      & Pick<MarkupLabel, 'id' | 'name' | 'color'>
    )> }
  )> }
);

export type GetPipelineModuleNetworkImageSegmentationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPipelineModuleNetworkImageSegmentationQuery = (
  { __typename?: 'Query' }
  & { getPipelineModuleNetworkImageSegmentation?: Maybe<(
    { __typename?: 'PipelineModuleNetworkImageSegmentation' }
    & Pick<PipelineModuleNetworkImageSegmentation, 'id' | 'name' | 'description'>
    & { inputs: Array<(
      { __typename?: 'PipelineModuleInput' }
      & { edges: Array<(
        { __typename?: 'PipelineModuleEdge' }
        & { from: (
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id'>
        ) }
      )> }
    )>, layoutPosition: (
      { __typename?: 'Position' }
      & Pick<Position, 'x' | 'y'>
    ), pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'name'>
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'name'>
    )>, classLabels: Array<(
      { __typename?: 'MarkupLabel' }
      & Pick<MarkupLabel, 'id' | 'name' | 'color'>
    )> }
  )> }
);

export type GetPipelineModuleTypeQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPipelineModuleTypeQuery = (
  { __typename?: 'Query' }
  & { queryPipelineModule?: Maybe<Array<Maybe<(
    { __typename: 'PipelineModuleUpload' }
    & Pick<PipelineModuleUpload, 'id'>
  ) | (
    { __typename: 'PipelineModuleBMobile' }
    & Pick<PipelineModuleBMobile, 'id'>
  ) | (
    { __typename: 'PipelineModuleCCChecker' }
    & Pick<PipelineModuleCcChecker, 'id'>
  ) | (
    { __typename: 'PipelineModuleContrastEnhancement' }
    & Pick<PipelineModuleContrastEnhancement, 'id'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageSegmentation' }
    & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageClassification' }
    & Pick<PipelineModuleNetworkImageClassification, 'id'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageObjectDetection' }
    & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageInstanceSegmentation' }
    & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageSuperResolution' }
    & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageOCR' }
    & Pick<PipelineModuleNetworkImageOcr, 'id'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageAnomalyDetection' }
    & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
  ) | (
    { __typename: 'PipelineModuleNetworkImageObjectCounting' }
    & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
  )>>> }
);

export type GetNetworkModuleClassLabelsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetNetworkModuleClassLabelsQuery = (
  { __typename?: 'Query' }
  & { queryNetworkModule?: Maybe<Array<Maybe<(
    { __typename?: 'PipelineModuleNetworkImageSegmentation' }
    & { id: PipelineModuleNetworkImageSegmentation['networkModuleId'] }
    & { classLabels: Array<(
      { __typename?: 'MarkupLabel' }
      & Pick<MarkupLabel, 'id' | 'name' | 'color'>
    )> }
  ) | (
    { __typename?: 'PipelineModuleNetworkImageClassification' }
    & { id: PipelineModuleNetworkImageClassification['networkModuleId'] }
    & { classLabels: Array<(
      { __typename?: 'MarkupLabel' }
      & Pick<MarkupLabel, 'id' | 'name' | 'color'>
    )> }
  ) | (
    { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
    & { id: PipelineModuleNetworkImageObjectDetection['networkModuleId'] }
    & { classLabels: Array<(
      { __typename?: 'MarkupLabel' }
      & Pick<MarkupLabel, 'id' | 'name' | 'color'>
    )> }
  ) | (
    { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
    & { id: PipelineModuleNetworkImageInstanceSegmentation['networkModuleId'] }
    & { classLabels: Array<(
      { __typename?: 'MarkupLabel' }
      & Pick<MarkupLabel, 'id' | 'name' | 'color'>
    )> }
  ) | (
    { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
    & { id: PipelineModuleNetworkImageSuperResolution['networkModuleId'] }
    & { classLabels: Array<(
      { __typename?: 'MarkupLabel' }
      & Pick<MarkupLabel, 'id' | 'name' | 'color'>
    )> }
  ) | (
    { __typename?: 'PipelineModuleNetworkImageOCR' }
    & { id: PipelineModuleNetworkImageOcr['networkModuleId'] }
    & { classLabels: Array<(
      { __typename?: 'MarkupLabel' }
      & Pick<MarkupLabel, 'id' | 'name' | 'color'>
    )> }
  ) | (
    { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
    & { id: PipelineModuleNetworkImageAnomalyDetection['networkModuleId'] }
    & { classLabels: Array<(
      { __typename?: 'MarkupLabel' }
      & Pick<MarkupLabel, 'id' | 'name' | 'color'>
    )> }
  ) | (
    { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
    & { id: PipelineModuleNetworkImageObjectCounting['networkModuleId'] }
    & { classLabels: Array<(
      { __typename?: 'MarkupLabel' }
      & Pick<MarkupLabel, 'id' | 'name' | 'color'>
    )> }
  )>>> }
);

export type QueryPretrainedNetworkQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryPretrainedNetworkQuery = (
  { __typename?: 'Query' }
  & { queryPretrainedNetwork?: Maybe<Array<Maybe<(
    { __typename: 'PretrainedNetwork' }
    & Pick<PretrainedNetwork, 'id' | 'createdAt' | 'updatedAt' | 'thumbnail'>
    & { networkModule: (
      { __typename: 'PipelineModuleNetworkImageSegmentation' }
      & Pick<PipelineModuleNetworkImageSegmentation, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
    ) | (
      { __typename: 'PipelineModuleNetworkImageClassification' }
      & Pick<PipelineModuleNetworkImageClassification, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
    ) | (
      { __typename: 'PipelineModuleNetworkImageObjectDetection' }
      & Pick<PipelineModuleNetworkImageObjectDetection, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
    ) | (
      { __typename: 'PipelineModuleNetworkImageInstanceSegmentation' }
      & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
    ) | (
      { __typename: 'PipelineModuleNetworkImageSuperResolution' }
      & Pick<PipelineModuleNetworkImageSuperResolution, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
    ) | (
      { __typename: 'PipelineModuleNetworkImageOCR' }
      & Pick<PipelineModuleNetworkImageOcr, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
    ) | (
      { __typename: 'PipelineModuleNetworkImageAnomalyDetection' }
      & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
    ) | (
      { __typename: 'PipelineModuleNetworkImageObjectCounting' }
      & Pick<PipelineModuleNetworkImageObjectCounting, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'description' | 'owner'>
    ) }
  )>>> }
);

export type GetOutputsQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetOutputsQuery = (
  { __typename?: 'Query' }
  & { getDataset?: Maybe<(
    { __typename?: 'Dataset' }
    & Pick<Dataset, 'id'>
    & { pipeline?: Maybe<(
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
      & { modules?: Maybe<Array<(
        { __typename?: 'PipelineModuleUpload' }
        & Pick<PipelineModuleUpload, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleBMobile' }
        & Pick<PipelineModuleBMobile, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleCCChecker' }
        & Pick<PipelineModuleCcChecker, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleContrastEnhancement' }
        & Pick<PipelineModuleContrastEnhancement, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      ) | (
        { __typename?: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
        & { outputs: Array<(
          { __typename?: 'PipelineModuleDatapointOutput' }
          & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
        ) | (
          { __typename?: 'PipelineModuleArtifactOutput' }
          & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
        )> }
      )>> }
    )> }
  )> }
);

export type SubscribeDatapointModuleParamsSubscriptionVariables = Exact<{
  datapointId: Scalars['String'];
}>;


export type SubscribeDatapointModuleParamsSubscription = (
  { __typename?: 'Subscription' }
  & { queryDatapoint?: Maybe<Array<Maybe<(
    { __typename?: 'Image' }
    & { researchBox: (
      { __typename?: 'ResearchBox' }
      & Pick<ResearchBox, 'id'>
    ), producedBy: (
      { __typename?: 'PipelineModuleDatapointOutput' }
      & { module: (
        { __typename: 'PipelineModuleUpload' }
        & Pick<PipelineModuleUpload, 'id'>
      ) | (
        { __typename: 'PipelineModuleBMobile' }
        & Pick<PipelineModuleBMobile, 'id'>
      ) | (
        { __typename: 'PipelineModuleCCChecker' }
        & Pick<PipelineModuleCcChecker, 'busBarCountPerCell' | 'maxShift' | 'minCorrValue' | 'cutOffRate' | 'minPeakDistance' | 'minPeakProminence' | 'minPeakHeightPercentage' | 'id'>
      ) | (
        { __typename: 'PipelineModuleContrastEnhancement' }
        & Pick<PipelineModuleContrastEnhancement, 'tileSize' | 'clipLimit' | 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
      ) }
    ) }
  ) | (
    { __typename?: 'CCCheckerResult' }
    & { researchBox: (
      { __typename?: 'ResearchBox' }
      & Pick<ResearchBox, 'id'>
    ), producedBy: (
      { __typename?: 'PipelineModuleDatapointOutput' }
      & { module: (
        { __typename: 'PipelineModuleUpload' }
        & Pick<PipelineModuleUpload, 'id'>
      ) | (
        { __typename: 'PipelineModuleBMobile' }
        & Pick<PipelineModuleBMobile, 'id'>
      ) | (
        { __typename: 'PipelineModuleCCChecker' }
        & Pick<PipelineModuleCcChecker, 'busBarCountPerCell' | 'maxShift' | 'minCorrValue' | 'cutOffRate' | 'minPeakDistance' | 'minPeakProminence' | 'minPeakHeightPercentage' | 'id'>
      ) | (
        { __typename: 'PipelineModuleContrastEnhancement' }
        & Pick<PipelineModuleContrastEnhancement, 'tileSize' | 'clipLimit' | 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
      ) }
    ) }
  ) | (
    { __typename?: 'MagneticFieldScan' }
    & { researchBox: (
      { __typename?: 'ResearchBox' }
      & Pick<ResearchBox, 'id'>
    ), producedBy: (
      { __typename?: 'PipelineModuleDatapointOutput' }
      & { module: (
        { __typename: 'PipelineModuleUpload' }
        & Pick<PipelineModuleUpload, 'id'>
      ) | (
        { __typename: 'PipelineModuleBMobile' }
        & Pick<PipelineModuleBMobile, 'id'>
      ) | (
        { __typename: 'PipelineModuleCCChecker' }
        & Pick<PipelineModuleCcChecker, 'busBarCountPerCell' | 'maxShift' | 'minCorrValue' | 'cutOffRate' | 'minPeakDistance' | 'minPeakProminence' | 'minPeakHeightPercentage' | 'id'>
      ) | (
        { __typename: 'PipelineModuleContrastEnhancement' }
        & Pick<PipelineModuleContrastEnhancement, 'tileSize' | 'clipLimit' | 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'id'>
      ) }
    ) }
  )>>> }
);

export type QueryHasImagePredictionQueryVariables = Exact<{
  networkId: Scalars['String'];
  researchBoxId: Scalars['String'];
}>;


export type QueryHasImagePredictionQuery = (
  { __typename?: 'Query' }
  & { getResearchBox?: Maybe<(
    { __typename?: 'ResearchBox' }
    & { datapoints?: Maybe<Array<(
      { __typename?: 'Image' }
      & { predictions: Array<(
        { __typename?: 'SegmentationPrediction' }
        & Pick<SegmentationPrediction, 'id' | 'createdAt'>
      ) | (
        { __typename?: 'ClassificationPrediction' }
        & Pick<ClassificationPrediction, 'id' | 'createdAt'>
      ) | (
        { __typename?: 'ObjectDetectionPrediction' }
        & Pick<ObjectDetectionPrediction, 'id' | 'createdAt'>
      )> }
    ) | { __typename?: 'CCCheckerResult' } | { __typename?: 'MagneticFieldScan' }>> }
  )> }
);

export type QueryNetworkDetailsForDatasetQueryVariables = Exact<{
  filter?: Maybe<DatasetFilter>;
  networkFilter?: Maybe<PipelineModuleFilter>;
}>;


export type QueryNetworkDetailsForDatasetQuery = (
  { __typename?: 'Query' }
  & { queryDataset?: Maybe<Array<Maybe<(
    { __typename?: 'Dataset' }
    & Pick<Dataset, 'id'>
    & { pipeline?: Maybe<(
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
      & { modules?: Maybe<Array<(
        { __typename: 'PipelineModuleUpload' }
        & Pick<PipelineModuleUpload, 'id' | 'name' | 'description'>
      ) | (
        { __typename: 'PipelineModuleBMobile' }
        & Pick<PipelineModuleBMobile, 'id' | 'name' | 'description'>
      ) | (
        { __typename: 'PipelineModuleCCChecker' }
        & Pick<PipelineModuleCcChecker, 'id' | 'name' | 'description'>
      ) | (
        { __typename: 'PipelineModuleContrastEnhancement' }
        & Pick<PipelineModuleContrastEnhancement, 'id' | 'name' | 'description'>
      ) | (
        { __typename: 'PipelineModuleNetworkImageSegmentation' }
        & Pick<PipelineModuleNetworkImageSegmentation, 'id' | 'name' | 'description'>
        & { trainingMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, validationMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, snapshots: Array<(
          { __typename?: 'NetworkSnapshot' }
          & Pick<NetworkSnapshot, 'id' | 'updatedAt'>
          & { onnxBinary: (
            { __typename?: 'EncryptedBinary' }
            & Pick<EncryptedBinary, 'id' | 'url'>
          ), metrics: Array<(
            { __typename?: 'Metric' }
            & Pick<Metric, 'id' | 'history' | 'inferenceThreshold' | 'name' | 'currentValue' | 'bestValue' | 'show' | 'isNetworkScore'>
            & { label?: Maybe<(
              { __typename?: 'MarkupLabel' }
              & Pick<MarkupLabel, 'id' | 'idx' | 'color' | 'name'>
            )> }
          )> }
        )>, snapshotsAggregate?: Maybe<(
          { __typename?: 'NetworkSnapshotAggregateResult' }
          & Pick<NetworkSnapshotAggregateResult, 'count'>
        )>, classLabels: Array<(
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color' | 'positiveMarkupCount' | 'negativeMarkupCount'>
        )>, denkjobs: Array<(
          { __typename?: 'Denkjob' }
          & Pick<Denkjob, 'id' | 'state' | 'failCounter' | 'jobType' | 'message' | 'createdAt'>
        )> }
      ) | (
        { __typename: 'PipelineModuleNetworkImageClassification' }
        & Pick<PipelineModuleNetworkImageClassification, 'id' | 'name' | 'description'>
        & { trainingMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, validationMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, snapshots: Array<(
          { __typename?: 'NetworkSnapshot' }
          & Pick<NetworkSnapshot, 'id' | 'updatedAt'>
          & { onnxBinary: (
            { __typename?: 'EncryptedBinary' }
            & Pick<EncryptedBinary, 'id' | 'url'>
          ), metrics: Array<(
            { __typename?: 'Metric' }
            & Pick<Metric, 'id' | 'history' | 'inferenceThreshold' | 'name' | 'currentValue' | 'bestValue' | 'show' | 'isNetworkScore'>
            & { label?: Maybe<(
              { __typename?: 'MarkupLabel' }
              & Pick<MarkupLabel, 'id' | 'idx' | 'color' | 'name'>
            )> }
          )> }
        )>, snapshotsAggregate?: Maybe<(
          { __typename?: 'NetworkSnapshotAggregateResult' }
          & Pick<NetworkSnapshotAggregateResult, 'count'>
        )>, classLabels: Array<(
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color' | 'positiveMarkupCount' | 'negativeMarkupCount'>
        )>, denkjobs: Array<(
          { __typename?: 'Denkjob' }
          & Pick<Denkjob, 'id' | 'state' | 'failCounter' | 'jobType' | 'message' | 'createdAt'>
        )> }
      ) | (
        { __typename: 'PipelineModuleNetworkImageObjectDetection' }
        & Pick<PipelineModuleNetworkImageObjectDetection, 'id' | 'name' | 'description'>
        & { trainingMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, validationMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, snapshots: Array<(
          { __typename?: 'NetworkSnapshot' }
          & Pick<NetworkSnapshot, 'id' | 'updatedAt'>
          & { onnxBinary: (
            { __typename?: 'EncryptedBinary' }
            & Pick<EncryptedBinary, 'id' | 'url'>
          ), metrics: Array<(
            { __typename?: 'Metric' }
            & Pick<Metric, 'id' | 'history' | 'inferenceThreshold' | 'name' | 'currentValue' | 'bestValue' | 'show' | 'isNetworkScore'>
            & { label?: Maybe<(
              { __typename?: 'MarkupLabel' }
              & Pick<MarkupLabel, 'id' | 'idx' | 'color' | 'name'>
            )> }
          )> }
        )>, snapshotsAggregate?: Maybe<(
          { __typename?: 'NetworkSnapshotAggregateResult' }
          & Pick<NetworkSnapshotAggregateResult, 'count'>
        )>, classLabels: Array<(
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color' | 'positiveMarkupCount' | 'negativeMarkupCount'>
        )>, denkjobs: Array<(
          { __typename?: 'Denkjob' }
          & Pick<Denkjob, 'id' | 'state' | 'failCounter' | 'jobType' | 'message' | 'createdAt'>
        )> }
      ) | (
        { __typename: 'PipelineModuleNetworkImageInstanceSegmentation' }
        & Pick<PipelineModuleNetworkImageInstanceSegmentation, 'id' | 'name' | 'description'>
        & { trainingMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, validationMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, snapshots: Array<(
          { __typename?: 'NetworkSnapshot' }
          & Pick<NetworkSnapshot, 'id' | 'updatedAt'>
          & { onnxBinary: (
            { __typename?: 'EncryptedBinary' }
            & Pick<EncryptedBinary, 'id' | 'url'>
          ), metrics: Array<(
            { __typename?: 'Metric' }
            & Pick<Metric, 'id' | 'history' | 'inferenceThreshold' | 'name' | 'currentValue' | 'bestValue' | 'show' | 'isNetworkScore'>
            & { label?: Maybe<(
              { __typename?: 'MarkupLabel' }
              & Pick<MarkupLabel, 'id' | 'idx' | 'color' | 'name'>
            )> }
          )> }
        )>, snapshotsAggregate?: Maybe<(
          { __typename?: 'NetworkSnapshotAggregateResult' }
          & Pick<NetworkSnapshotAggregateResult, 'count'>
        )>, classLabels: Array<(
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color' | 'positiveMarkupCount' | 'negativeMarkupCount'>
        )>, denkjobs: Array<(
          { __typename?: 'Denkjob' }
          & Pick<Denkjob, 'id' | 'state' | 'failCounter' | 'jobType' | 'message' | 'createdAt'>
        )> }
      ) | (
        { __typename: 'PipelineModuleNetworkImageSuperResolution' }
        & Pick<PipelineModuleNetworkImageSuperResolution, 'id' | 'name' | 'description'>
        & { trainingMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, validationMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, snapshots: Array<(
          { __typename?: 'NetworkSnapshot' }
          & Pick<NetworkSnapshot, 'id' | 'updatedAt'>
          & { onnxBinary: (
            { __typename?: 'EncryptedBinary' }
            & Pick<EncryptedBinary, 'id' | 'url'>
          ), metrics: Array<(
            { __typename?: 'Metric' }
            & Pick<Metric, 'id' | 'history' | 'inferenceThreshold' | 'name' | 'currentValue' | 'bestValue' | 'show' | 'isNetworkScore'>
            & { label?: Maybe<(
              { __typename?: 'MarkupLabel' }
              & Pick<MarkupLabel, 'id' | 'idx' | 'color' | 'name'>
            )> }
          )> }
        )>, snapshotsAggregate?: Maybe<(
          { __typename?: 'NetworkSnapshotAggregateResult' }
          & Pick<NetworkSnapshotAggregateResult, 'count'>
        )>, classLabels: Array<(
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color' | 'positiveMarkupCount' | 'negativeMarkupCount'>
        )>, denkjobs: Array<(
          { __typename?: 'Denkjob' }
          & Pick<Denkjob, 'id' | 'state' | 'failCounter' | 'jobType' | 'message' | 'createdAt'>
        )> }
      ) | (
        { __typename: 'PipelineModuleNetworkImageOCR' }
        & Pick<PipelineModuleNetworkImageOcr, 'id' | 'name' | 'description'>
        & { trainingMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, validationMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, snapshots: Array<(
          { __typename?: 'NetworkSnapshot' }
          & Pick<NetworkSnapshot, 'id' | 'updatedAt'>
          & { onnxBinary: (
            { __typename?: 'EncryptedBinary' }
            & Pick<EncryptedBinary, 'id' | 'url'>
          ), metrics: Array<(
            { __typename?: 'Metric' }
            & Pick<Metric, 'id' | 'history' | 'inferenceThreshold' | 'name' | 'currentValue' | 'bestValue' | 'show' | 'isNetworkScore'>
            & { label?: Maybe<(
              { __typename?: 'MarkupLabel' }
              & Pick<MarkupLabel, 'id' | 'idx' | 'color' | 'name'>
            )> }
          )> }
        )>, snapshotsAggregate?: Maybe<(
          { __typename?: 'NetworkSnapshotAggregateResult' }
          & Pick<NetworkSnapshotAggregateResult, 'count'>
        )>, classLabels: Array<(
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color' | 'positiveMarkupCount' | 'negativeMarkupCount'>
        )>, denkjobs: Array<(
          { __typename?: 'Denkjob' }
          & Pick<Denkjob, 'id' | 'state' | 'failCounter' | 'jobType' | 'message' | 'createdAt'>
        )> }
      ) | (
        { __typename: 'PipelineModuleNetworkImageAnomalyDetection' }
        & Pick<PipelineModuleNetworkImageAnomalyDetection, 'id' | 'name' | 'description'>
        & { trainingMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, validationMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, snapshots: Array<(
          { __typename?: 'NetworkSnapshot' }
          & Pick<NetworkSnapshot, 'id' | 'updatedAt'>
          & { onnxBinary: (
            { __typename?: 'EncryptedBinary' }
            & Pick<EncryptedBinary, 'id' | 'url'>
          ), metrics: Array<(
            { __typename?: 'Metric' }
            & Pick<Metric, 'id' | 'history' | 'inferenceThreshold' | 'name' | 'currentValue' | 'bestValue' | 'show' | 'isNetworkScore'>
            & { label?: Maybe<(
              { __typename?: 'MarkupLabel' }
              & Pick<MarkupLabel, 'id' | 'idx' | 'color' | 'name'>
            )> }
          )> }
        )>, snapshotsAggregate?: Maybe<(
          { __typename?: 'NetworkSnapshotAggregateResult' }
          & Pick<NetworkSnapshotAggregateResult, 'count'>
        )>, classLabels: Array<(
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color' | 'positiveMarkupCount' | 'negativeMarkupCount'>
        )>, denkjobs: Array<(
          { __typename?: 'Denkjob' }
          & Pick<Denkjob, 'id' | 'state' | 'failCounter' | 'jobType' | 'message' | 'createdAt'>
        )> }
      ) | (
        { __typename: 'PipelineModuleNetworkImageObjectCounting' }
        & Pick<PipelineModuleNetworkImageObjectCounting, 'id' | 'name' | 'description'>
        & { trainingMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, validationMarkups?: Maybe<(
          { __typename?: 'ImageMarkupAggregateResult' }
          & Pick<ImageMarkupAggregateResult, 'count'>
        )>, snapshots: Array<(
          { __typename?: 'NetworkSnapshot' }
          & Pick<NetworkSnapshot, 'id' | 'updatedAt'>
          & { onnxBinary: (
            { __typename?: 'EncryptedBinary' }
            & Pick<EncryptedBinary, 'id' | 'url'>
          ), metrics: Array<(
            { __typename?: 'Metric' }
            & Pick<Metric, 'id' | 'history' | 'inferenceThreshold' | 'name' | 'currentValue' | 'bestValue' | 'show' | 'isNetworkScore'>
            & { label?: Maybe<(
              { __typename?: 'MarkupLabel' }
              & Pick<MarkupLabel, 'id' | 'idx' | 'color' | 'name'>
            )> }
          )> }
        )>, snapshotsAggregate?: Maybe<(
          { __typename?: 'NetworkSnapshotAggregateResult' }
          & Pick<NetworkSnapshotAggregateResult, 'count'>
        )>, classLabels: Array<(
          { __typename?: 'MarkupLabel' }
          & Pick<MarkupLabel, 'id' | 'name' | 'color' | 'positiveMarkupCount' | 'negativeMarkupCount'>
        )>, denkjobs: Array<(
          { __typename?: 'Denkjob' }
          & Pick<Denkjob, 'id' | 'state' | 'failCounter' | 'jobType' | 'message' | 'createdAt'>
        )> }
      )>> }
    )> }
  )>>> }
);

export type GetUploadModuleForDatasetQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetUploadModuleForDatasetQuery = (
  { __typename?: 'Query' }
  & { queryPipelineModuleUpload?: Maybe<Array<Maybe<(
    { __typename?: 'PipelineModuleUpload' }
    & Pick<PipelineModuleUpload, 'id'>
    & { outputs: Array<(
      { __typename?: 'PipelineModuleDatapointOutput' }
      & Pick<PipelineModuleDatapointOutput, 'id' | 'name' | 'dataType'>
    ) | (
      { __typename?: 'PipelineModuleArtifactOutput' }
      & Pick<PipelineModuleArtifactOutput, 'id' | 'name' | 'dataType'>
    )>, pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
      & { dataset: (
        { __typename?: 'Dataset' }
        & Pick<Dataset, 'id'>
      ) }
    ) }
  )>>> }
);
