import cuid from "cuid";
import { MouseMode, ToolType } from "MarkupTypes";
import { IDecodedPNG } from "fast-png/lib/types";
import {
  AddCircleAnnotationInput,
  AddPolygonAnnotationInput,
  AddRectangleAnnotationInput,
  AddSausageAnnotationInput,
  AddMagicwandAnnotationInput,
  AddPixelAnnotationInput,
  AddImageSegmentationMarkupInput,
  AddBoundingBoxAnnotationInput,
  AddPenAnnotationInput,
} from "../../../../ts-clients/command";
import { removeConsecutiveDuplicates } from "./utils";

export type AnnotationFlavour =
  | "Circle"
  | "Polygon"
  | "Rectangle"
  | "Sausage"
  | "MagicWand"
  | "Pixel"
  | "Pen"
  | "BoundingBox";

export type ScrollDirection = "up" | "down" | null;

export type MyID = {
  id: string;
  createdAt: string;
};

export type ObjectWithCreated = {
  id: string;
  createdAt: string;
};

export type Labeled = {
  markupLabelId: string;
  markupLabelName: string;
  markupLabelColor: string;
};

type ImageCache = {
  imageElement: HTMLImageElement | undefined;
  decodedData: IDecodedPNG | null;
};

type ThumbnailAnnotation = {
  thumbnailAnnotation: AddPixelAnnotationInput & { markupLabelColor: string };
};

export type LeanInteractionState = {
  mouseMode: MouseMode;
  mouseOnCanvas: boolean;
  oneClickShapes: boolean;
  selectedSegmentation2DTool: ToolType;
  paintActive: boolean;
  currentDataChangedByUser: boolean;
  scrollDirection: ScrollDirection;
  selectedAnnotationId: string | null;
  selectedAnnotationFlavour: AnnotationFlavour | null;
};

export type InteractionState = LeanInteractionState & {
  clickTime: number;
  enterTime: number;
  mouseDownTime: number;
  mouseUpTime: number;
};

export type MarkupToolInteraction =
  | "click"
  | "mouseDown"
  | "mouseUp"
  | "drag"
  | "enter"
  | "escape"
  | "wheel"
  | "delete";

export type InterActionAction =
  | "adjustBrushSize"
  | "applyCurrentMarkup"
  | "clickOnEmpty"
  | "createBoundingBoxFromCursor"
  | "createRectangleFromCursor"
  | "decreaseCircleRadius"
  | "deselectCurrentAnnotation"
  | "endSelect"
  | "increaseCircleRadius"
  | "magicWandDecreaseThrehold"
  | "magicWandIncreaseThrehold"
  | "magicWandSetCenter"
  | "makePaintCircleAtCursor"
  | "makePolygonCircleAtCursor"
  | "paintApplyPolygon"
  | "paintFinish"
  | "paintStart"
  | "polygonAddPoint"
  | "redo"
  | "resetCurrentData"
  | "resetPaint"
  | "resetSegmentation2DTool"
  | "sausageAddPoint"
  | "sausageDecreaseWidth"
  | "sausageIncreaseWidth"
  | "saveAndNext"
  | "selectedCircleDecreaseRadius"
  | "selectedCircleIncreaseRadius"
  | "selectedMagicWandDecreaseThreshold"
  | "selectedMagicWandIncreaseThreshold"
  | "selectedSausageDecreaseWidth"
  | "selectedSausageIncreaseWidth"
  | "setFirstBoundingBoxPoint"
  | "setFirstRectanglePoint"
  | "startSelect"
  | "toggleCursorHidden"
  | "togglePixelDrawMode"
  | "triggerDeleteAnnotation"
  | "triggerDeleteHighlightedAnnotations"
  | "undo"
  | "zoom-in"
  | "zoom-out";

export type Predicted = {
  isPrediction?: boolean;
  probability?: number;
};

export type ImageSegmentationAnnotationsAndPredictions =
  AddImageSegmentationMarkupInput & Predicted;

export type MyLabeledCircle = AddCircleAnnotationInput & Labeled & Predicted;
export type MyLabeledPolygon = AddPolygonAnnotationInput & Labeled & Predicted;
export type MyLabeledRectangle = AddRectangleAnnotationInput &
  Labeled &
  Predicted;
export type MyLabeledBoundingBox = AddBoundingBoxAnnotationInput &
  Labeled &
  Predicted;
export type MyLabeldSausage = AddSausageAnnotationInput & Labeled & Predicted;
export type MyLabeledMagicWand = AddMagicwandAnnotationInput &
  Labeled &
  Predicted;
export type MyLabeledPixels = AddPixelAnnotationInput & Labeled & Predicted;
export type MyLabeledPen = AddPenAnnotationInput & Labeled & Predicted;

export type MyCircle = MyLabeledCircle & MyID;
export type MyPolygon = MyLabeledPolygon & MyID;
export type MyRectangle = MyLabeledRectangle & MyID;
export type MyBoundingBox = MyLabeledBoundingBox & MyID;
export type MySausage = MyLabeldSausage & MyID;
export type MyMagicWand = MyLabeledMagicWand & MyID;
export type MyPixels = MyLabeledPixels &
  MyID &
  ImageCache &
  ThumbnailAnnotation;

export type MyPen = MyLabeledPen & MyID;

export type PlattformAnnotationsAndPredictions = {
  imageIds: string[];
  networkId: string;
  imageHeight: number;
  imageWidth: number;
  markupHeight: number;
  markupWidth: number;
  circleAnnotations: MyLabeledCircle[];
  polygonAnnotations: MyLabeledPolygon[];
  rectangleAnnotations: MyLabeledRectangle[];
  boundingBoxAnnotations: MyLabeledBoundingBox[];
  sausageAnnotations: MyLabeldSausage[];
  magicwandAnnotations: MyLabeledMagicWand[];
  pixelAnnotations: MyLabeledPixels[];
  penAnnotations: MyLabeledPen[];
};

export type MyAnnotations = {
  imageIds: string[];
  networkId: string;
  circleAnnotations: MyCircle[];
  polygonAnnotations: MyPolygon[];
  rectangleAnnotations: MyRectangle[];
  boundingBoxAnnotations: MyBoundingBox[];
  sausageAnnotations: MySausage[];
  magicwandAnnotations: MyMagicWand[];
  pixelAnnotations: MyPixels[];
  penAnnotations: MyPen[];
};

export type AnyAnnotation =
  | MyPolygon
  | MyCircle
  | MyRectangle
  | MyBoundingBox
  | MySausage
  | MyMagicWand
  | MyPixels;

export type LeanCircle = Omit<AddCircleAnnotationInput, "annotationType"> & {
  markupLabelColor: string;
};
export type LeanPolygon = Omit<AddPolygonAnnotationInput, "annotationType"> & {
  markupLabelColor: string;
};
export type LeanRectangle = Omit<
  AddRectangleAnnotationInput,
  "annotationType"
> & {
  markupLabelColor: string;
};
export type LeanBoundingBox = Omit<
  AddBoundingBoxAnnotationInput,
  "annotationType"
> & {
  markupLabelColor: string;
};
export type LeanSausage = Omit<AddSausageAnnotationInput, "annotationType"> & {
  markupLabelColor: string;
};
export type LeanMagicWand = Omit<
  AddMagicwandAnnotationInput,
  "annotationType"
> & {
  markupLabelColor: string;
};
export type LeanPen = Omit<AddPenAnnotationInput, "annotationType"> & {
  markupLabelColor: string;
};

export type CurrentDataType = {
  circle: LeanCircle;
  polygon: LeanPolygon;
  reactangle: LeanRectangle;
  boundingBox: LeanBoundingBox;
  sausage: LeanSausage;
  magicwand: LeanMagicWand;
  pixels: Omit<AddPixelAnnotationInput, "annotationType"> & {
    markupLabelColor: string;
  };
  pen: LeanPen;
};

export const makeMyCircle = (
  c: MyLabeledCircle,
  markupLabelId: string,
  markupLabelColor: string
): MyCircle => ({
  ...c,
  id: cuid(),
  createdAt: Date.now().toString(),
  markupLabelId,
  markupLabelColor,
  markupLabelName: c.markupLabelName,
  isPrediction: c.isPrediction || false,
  probability: c.probability || 1.0,
});

export const makeMyMagicWand = (
  c: MyLabeledMagicWand,
  markupLabelId: string,
  markupLabelColor: string
): MyMagicWand => ({
  ...c,
  id: cuid(),
  createdAt: Date.now().toString(),
  markupLabelId,
  markupLabelColor,
  markupLabelName: c.markupLabelName,
  isPrediction: c.isPrediction || false,
  probability: c.probability || 1.0,
});

export const makeMyPolygon = (
  c: MyLabeledPolygon,
  markupLabelId: string,
  markupLabelColor: string
): MyPolygon => ({
  ...c,
  points: removeConsecutiveDuplicates(c.points),
  id: cuid(),
  createdAt: Date.now().toString(),
  markupLabelId,
  markupLabelColor,
  markupLabelName: c.markupLabelName,
  isPrediction: c.isPrediction || false,
  probability: c.probability || 1.0,
});

export const makeMyRectangle = (
  c: MyLabeledRectangle,
  markupLabelId: string,
  markupLabelColor: string
): MyRectangle => ({
  ...c,
  id: cuid(),
  createdAt: Date.now().toString(),
  markupLabelId,
  markupLabelColor,
  markupLabelName: c.markupLabelName,
  isPrediction: c.isPrediction || false,
  probability: c.probability || 1.0,
});

export const makeMyBoundingBox = (
  c: MyLabeledBoundingBox,
  markupLabelId: string,
  markupLabelColor: string
): MyBoundingBox => ({
  ...c,
  id: cuid(),
  createdAt: Date.now().toString(),
  markupLabelId,
  markupLabelColor,
  markupLabelName: c.markupLabelName,
  isPrediction: c.isPrediction || false,
  probability: c.probability || 1.0,
});

export const makeMySausage = (
  c: MyLabeldSausage,
  markupLabelId: string,
  markupLabelColor: string
): MySausage => ({
  ...c,
  points: removeConsecutiveDuplicates(c.points),
  id: cuid(),
  createdAt: Date.now().toString(),
  markupLabelId,
  markupLabelColor,
  markupLabelName: c.markupLabelName,
  isPrediction: c.isPrediction || false,
  probability: c.probability || 1.0,
});

export const makeMyPen = (
  c: MyLabeledPen,
  markupLabelId: string,
  markupLabelColor: string
): MyPen => ({
  ...c,
  id: cuid(),
  createdAt: Date.now().toString(),
  markupLabelId,
  markupLabelColor,
  markupLabelName: c.markupLabelName,
  isPrediction: c.isPrediction || false,
  probability: c.probability || 1.0,
});

export const makeMyPixels = (
  c: MyLabeledPixels,
  markupLabelId: string,
  markupLabelColor: string
): MyPixels => ({
  ...c,
  id: cuid(),
  createdAt: Date.now().toString(),
  markupLabelId,
  markupLabelColor,
  markupLabelName: c.markupLabelName,
  imageElement: undefined,
  decodedData: null,
  thumbnailAnnotation: {
    annotationType: c.annotationType,
    bottomRight: c.bottomRight,
    topLeft: c.topLeft,
    dataURL: c.dataURL,
    markupLabelId: c.markupLabelId,
    markupLabelColor: c.markupLabelColor,
  },
  isPrediction: c.isPrediction || false,
  probability: c.probability || 1.0,
});

// export const assertLabelsExist = (
//   loadedAnnotations: AddImageSegmentationMarkupInput[],
//   ls: MarkupLabel[]
// ) => {
//   return ls.map((l, idx) => {
//     const data = loadedAnnotations.find((d) => d.markupLabelId === l.id);
//     return (
//       data || {
//         circleAnnotations: [],
//         index: idx,
//         magicwandAnnotations: [],
//         markupLabelId: l.id,
//         pixelAnnotations: [],
//         polygonAnnotations: [],
//         rectangleAnnotations: [],
//         sausageAnnotations: [],
//         penAnnotations: [],
//         dataURL: '',
//       }
//     );
//   });
// };

export const makeMyAnnotations = (
  maps: PlattformAnnotationsAndPredictions | undefined
): MyAnnotations => {
  return {
    imageIds: maps?.imageIds ?? [],
    networkId: maps?.networkId ?? "",
    circleAnnotations:
      maps?.circleAnnotations.map((c) =>
        makeMyCircle(c, c.markupLabelId, c.markupLabelColor)
      ) ?? [],
    polygonAnnotations:
      maps?.polygonAnnotations.map((c) =>
        makeMyPolygon(c, c.markupLabelId, c.markupLabelColor)
      ) ?? [],
    magicwandAnnotations:
      maps?.magicwandAnnotations.map((c) =>
        makeMyMagicWand(c, c.markupLabelId, c.markupLabelColor)
      ) ?? [],
    rectangleAnnotations:
      maps?.rectangleAnnotations.map((c) =>
        makeMyRectangle(c, c.markupLabelId, c.markupLabelColor)
      ) ?? [],
    boundingBoxAnnotations:
      maps?.boundingBoxAnnotations.map((c) =>
        makeMyBoundingBox(c, c.markupLabelId, c.markupLabelColor)
      ) ?? [],
    sausageAnnotations:
      maps?.sausageAnnotations.map((c) =>
        makeMySausage(c, c.markupLabelId, c.markupLabelColor)
      ) ?? [],
    pixelAnnotations:
      maps?.pixelAnnotations.map((c) =>
        makeMyPixels(c, c.markupLabelId, c.markupLabelColor)
      ) ?? [],
    penAnnotations:
      maps?.penAnnotations.map((c) =>
        makeMyPen(c, c.markupLabelId, c.markupLabelColor)
      ) ?? [],
  };
};
