import { History } from "history";
import { useSelector, useDispatch } from "react-redux";
import { Outputlist } from "../../state/datasetSettings";
import {
  ImageSize,
  Vector2d,
} from "../filemanagement/types/FileManagementTypes";
import { getPath, Context } from "../../utils/routeManager";
import { DatapointType } from "../../types/extractedTypes";
import { getPageSize, setPage } from "../../state/researchboxfilter";

type PageParams = {
  datasetId: string;
  listIndex: number;
  page: Page;
  context: Context;
};

export type Page = "overview" | "markup" | "back";
type Direction = "next" | "prev";

export type NavigationProps = {
  hasPrev: boolean;
  hasNext: boolean;
  goNext: () => void;
  goPrev: () => void;
  jumpTo: (listIndex: number) => void;
  goPage: (p: Page) => void;
  progress: {
    total: number;
    position: number;
  };
  datasetId: string;
  listIndex: number;
};

const makeLinks = (datasetId: string, listIndex: number, context: Context) => {
  return new Map<Page, () => string>([
    [
      "markup",
      () =>
        getPath("ResearchBoxDetails", {
          context,
          datasetId,
          listIndex,
          page: "markup",
        }),
    ],
    [
      "overview",
      () =>
        getPath("ResearchBoxDetails", {
          context,
          datasetId,
          listIndex,
          page: "overview",
        }),
    ],
    [
      "back",
      () =>
        getPath("ResearchBoxList", {
          datasetId,
          context: "researchboxlist",
        }),
    ],
  ]);
};

const go = (h: History, l: (() => string) | undefined, s?: string) => {
  if (l) {
    h.push({ pathname: l(), search: s });
  }
};

const makeListNavLinks =
  (p: PageParams, h: History, s?: string) => (d: Direction) => {
    const index = Number(p.listIndex.toString());
    go(
      h,
      makeLinks(
        p.datasetId,
        d === "next" ? index + 1 : index - 1,
        p.context
      ).get(p.page),
      s
    );
  };

const goPageLink =
  (pa: PageParams, h: History, s?: string) =>
  (p: Page): void =>
    go(h, makeLinks(pa.datasetId, pa.listIndex, pa.context).get(p), s);

const makeJumper =
  (pa: PageParams, h: History, s?: string) =>
  (listIndex: number): void =>
    go(h, makeLinks(pa.datasetId, listIndex, pa.context).get(pa.page), s);

// const getLink = (
//   datasetId: string,
//   listIndex: number,
//   page: Page,
//   context: Context
// ): string | null => {
//   const p = makeLinks(datasetId, listIndex, context).get(page);
//   return p ? p() : null;
// };

const GetPageSize = () => {
  const val = useSelector(getPageSize);
  return val;
};

const SetPage = (newPage: number) => {
  const dispatch = useDispatch();
  dispatch(setPage(newPage));
};

export const makeNaviation = (
  history: History,
  pageParams: PageParams,
  researchboxCount: number,
  search?: string
): NavigationProps => {
  const pp = makeListNavLinks(pageParams, history, search);
  const jmp = makeJumper(pageParams, history, search);
  const hasPrev = pageParams.listIndex > 0;
  const hasNext = pageParams.listIndex < researchboxCount - 1;

  const newPage = Math.floor(pageParams.listIndex / GetPageSize()) + 1;

  SetPage(newPage);

  const jumpTo = (listIndex: number) => {
    if (listIndex < 1) {
      jmp(0);
    } else if (listIndex > researchboxCount - 1) {
      jmp(researchboxCount - 1);
    } else {
      jmp(listIndex - 1);
    }
  };

  return {
    hasPrev,
    hasNext,
    goNext: () => hasNext && pp("next"),
    goPrev: () => hasPrev && pp("prev"),
    jumpTo,
    goPage: goPageLink(pageParams, history, search),
    progress: {
      total: researchboxCount,
      position: pageParams.listIndex,
    },
    datasetId: pageParams.datasetId,
    listIndex: pageParams.listIndex,
  };
};

const compareFkt = (prio: Outputlist) => (a: DatapointType, b: DatapointType) =>
  prio.findIndex((o) => o.label === a.producedBy.name) -
  prio.findIndex((o) => o.label === b.producedBy.name);

const sortDatapoints = (prio: Outputlist, datapoints: DatapointType[]) =>
  datapoints?.slice().sort(compareFkt(prio)) || [];

// a ResearchBox can have multiple datapoints
// we sort then by the given priority and return
// the one with the highest priority
export const getPreviewDataPoint = (
  prio: Outputlist,
  datapoints: DatapointType[]
) => {
  const d = sortDatapoints(prio, datapoints);
  return d.length > 0 ? d[0] : null;
};

const calcOrientation = (imageSize: ImageSize): string => {
  const aspectRatio = imageSize.width / imageSize.height;
  let orientation = "";

  if (aspectRatio > 1) {
    orientation = "landscape";
  } else if (aspectRatio < 1) {
    orientation = "portrait";
  } else {
    orientation = "square";
  }

  return orientation;
};

const isValidImageSize = (imageSize: ImageSize): boolean => {
  return imageSize.height > 0 && imageSize.width > 0;
};

const calcScaledImageSize = (
  canvasSize: ImageSize,
  imageSize: ImageSize
): ImageSize => {
  if (!isValidImageSize(imageSize)) {
    return {
      width: 0,
      height: 0,
    };
  }

  const orientation = calcOrientation(imageSize);
  const aspectRatio = imageSize.width / imageSize.height;

  let imageWidth = 0;
  let imageHeight = 0;

  if (orientation === "landscape") {
    imageWidth = canvasSize.width;
    imageHeight = canvasSize.width / aspectRatio;
  }

  if (orientation === "portrait") {
    imageWidth = canvasSize.width * aspectRatio;
    imageHeight = canvasSize.height;
  }

  if (orientation === "square") {
    imageWidth = canvasSize.width;
    imageHeight = canvasSize.height;
  }

  return {
    width: imageWidth,
    height: imageHeight,
  };
};

export const calcZoomFactor = (
  canvasSize: ImageSize,
  imageSize: ImageSize
): Vector2d => {
  if (
    canvasSize.width === 0 ||
    canvasSize.height === 0 ||
    imageSize.width === 0 ||
    imageSize.height === 0
  ) {
    return {
      x: 1,
      y: 1,
    };
  }

  const scaledImageSize = calcScaledImageSize(canvasSize, imageSize);

  const factor = Math.min(
    scaledImageSize.height / imageSize.height,
    scaledImageSize.width / imageSize.width
  );

  return {
    x: factor,
    y: factor,
  };
};
