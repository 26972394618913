import useSessionStorage from "./useSessionStorage";

export default function useMarkuptoolList() {
  const [markuptoolList, setMarkupToolList] = useSessionStorage<string[]>(
    "markuptoollist",
    []
  );

  const [currentListIndex, setCurrentListIndex] = useSessionStorage(
    "markuptoollistindex",
    0
  );

  return {
    markuptoolList,
    setMarkupToolList,
    currentListIndex,
    setCurrentListIndex,
  };
}
