/* eslint-disable react/jsx-one-expression-per-line */
import { useParams } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import MenuMain from "../../components/menus/menu.main";
import ResearchBoxListMenu from "./ResearchBoxListMenu";

type Params = {
  datasetId: string;
};

export default function FilesUploadPage() {
  const { datasetId } = useParams<Params>();

  return (
    <>
      <MenuMain />
      <ResearchBoxListMenu current="files" datasetId={datasetId} />
      <Menu
        fixed="right"
        vertical
        style={{
          padding: "15px",
          marginTop: 40,
          backgroundColor: "#f4f4f4",
          width: "18rem",
        }}
      />
    </>
  );
}
