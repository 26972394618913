/* eslint-disable react/jsx-curly-newline */
import { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Message } from "semantic-ui-react";
import { getCurrentUser } from "../../state/loginState";
import { useChangePassword } from "./hooks";

type FormData = {
  oldpw: string;
  newpw: string;
  newpw2: string;
};

const defaultFormData: FormData = {
  newpw: "",
  newpw2: "",
  oldpw: "",
};

const isPwStrongEnough = (pw: string) => pw.length >= 8;

export default function ChangePassword(): JSX.Element {
  const [formData, setFormData] = useState<FormData>(defaultFormData);
  const currentuser = useSelector(getCurrentUser);
  const pwStrongEnough = isPwStrongEnough(formData.newpw);
  const bothIdentical = formData.newpw === formData.newpw2;
  const newFormFilled = formData.newpw.length > 0 || formData.newpw2.length > 0;
  const submittable = bothIdentical && pwStrongEnough;

  const { save, success } = useChangePassword(
    currentuser?.username ?? "",
    formData.newpw
  );

  return (
    <Form onSubmit={() => save()}>
      <Message
        content="Your profile has been updated."
        positive
        hidden={!success}
      />
      <Message
        content="Your password is not strong enough."
        error
        hidden={pwStrongEnough}
      />
      <Message
        content="The password fields do not match."
        error
        hidden={bothIdentical || !newFormFilled}
      />
      <Form.Input
        label="Old Password"
        fluid
        icon="lock"
        type="password"
        iconPosition="left"
        value={formData.oldpw}
        onChange={(_, { value: oldpw }) =>
          setFormData((d) => ({ ...d, oldpw }))
        }
      />
      <Form.Input
        label="New Password"
        fluid
        error={!bothIdentical && !newFormFilled}
        icon="lock"
        type="password"
        iconPosition="left"
        value={formData.newpw}
        onChange={(_, { value: newpw }) =>
          setFormData((d) => ({ ...d, newpw }))
        }
      />
      <Form.Input
        label="Repeat  New Password"
        fluid
        error={!bothIdentical && !newFormFilled}
        icon="lock"
        type="password"
        iconPosition="left"
        value={formData.newpw2}
        onChange={(_, { value: newpw2 }) =>
          setFormData((d) => ({ ...d, newpw2 }))
        }
      />
      <div style={{ textAlign: "right" }}>
        <Form.Button
          type="submit"
          content="save"
          positive
          disabled={!submittable}
        />
      </div>
    </Form>
  );
}
