import { Point, ToolType } from "MarkupTypes";
import { last } from "ramda";
import { useCallback, useMemo, useState } from "react";
import { CurrentDataType } from "./types";
import { AddPoint2DInput } from "../../../../ts-clients/command";

import {
  circleToPoly,
  magicWandToPoly,
  rectangleToPoly,
  sausageToPoly,
} from "./annotationHelper";
import uglyStore from "../../../../state/uglyStore";
import useInterval from "../../../../hooks/useInterval";

export interface Annotations {
  applyMarkup: (tool: ToolType) => void;
  clearMarkup: () => void;
}

const pz: AddPoint2DInput = { x: 0, y: 0 };
const pmax: AddPoint2DInput = {
  x: Number.MAX_SAFE_INTEGER,
  y: Number.MAX_SAFE_INTEGER,
};
const pmin: AddPoint2DInput = {
  x: Number.MIN_SAFE_INTEGER,
  y: Number.MIN_SAFE_INTEGER,
};

const makeInitialData = (
  brushSize: number,
  magicWandThreshold: number,
  sausageSize: number,
  markupLabelId: string,
  markupLabelColor: string
) => {
  const initialData: CurrentDataType = {
    circle: {
      center: pz,
      radius: brushSize / 2,
      markupLabelId,
      markupLabelColor,
    },
    magicwand: {
      center: pz,
      threshold: magicWandThreshold,
      bottomRight: pmin,
      topLeft: pmax,
      markupLabelId,
      markupLabelColor,
      dataURL: "",
    },
    polygon: {
      points: [],
      markupLabelId,
      markupLabelColor,
    },
    reactangle: {
      bottomRight: pmin,
      topLeft: pmax,
      markupLabelId,
      markupLabelColor,
    },
    boundingBox: {
      bottomRight: pmin,
      topLeft: pmax,
      markupLabelId,
      markupLabelColor,
      dataURL: "",
    },
    sausage: {
      points: [],
      radius: sausageSize,
      bottomRight: pmin,
      topLeft: pmax,
      markupLabelId,
      markupLabelColor,
      dataURL: "",
    },
    pixels: {
      bottomRight: pmin,
      topLeft: pmax,
      markupLabelId,
      markupLabelColor,
      dataURL: "",
    },
    pen: {
      points: [],
      thickness: 10,
      bottomRight: pmin,
      topLeft: pmax,
      markupLabelId,
      markupLabelColor,
      dataURL: "",
    },
  };

  return initialData;
};
export default function useCurrentData(
  brushSize: number,
  magicWandThreshold: number,
  sausageSize: number,
  markupLabelId: string,
  markupLabelColor: string
) {
  const [currentData, setCurrentData] = useState<CurrentDataType>(
    makeInitialData(
      brushSize,
      magicWandThreshold,
      sausageSize,
      markupLabelId,
      markupLabelColor
    )
  );
  const [changedByUser, setChangedByUser] = useState(false);

  const reset = useCallback(() => {
    setChangedByUser(false);
    setCurrentData(
      makeInitialData(
        brushSize,
        magicWandThreshold,
        sausageSize,
        markupLabelId,
        markupLabelColor
      )
    );
  }, [
    brushSize,
    magicWandThreshold,
    markupLabelId,
    sausageSize,
    markupLabelColor,
  ]);

  const circleSet = useCallback(
    (center: AddPoint2DInput, radius: number) => {
      setCurrentData((d) => ({
        ...d,
        circle: {
          center,
          radius,
          markupLabelId,
          markupLabelColor,
        },
      }));
    },
    [markupLabelId, markupLabelColor]
  );

  const circleMove = useCallback(
    (center: AddPoint2DInput) =>
      setCurrentData((d) => ({ ...d, circle: { ...d.circle, center } })),
    []
  );

  const circleResize = useCallback(
    (diameter: number) =>
      setCurrentData((d) => ({
        ...d,
        circle: {
          ...d.circle,
          radius: diameter / 2,
        },
      })),
    []
  );

  const magicWandSet = useCallback(
    (center: AddPoint2DInput, threshold: number) => {
      setCurrentData((d) => ({
        ...d,
        magicwand: {
          center,
          threshold,
          dataURL: "",
          topLeft: { x: 0, y: 0 },
          bottomRight: { x: 0, y: 0 },
          markupLabelId,
          markupLabelColor,
        },
      }));
    },
    [markupLabelId, markupLabelColor]
  );

  const magicWandMove = useCallback((center: AddPoint2DInput) => {
    setCurrentData((d) => ({ ...d, magicwand: { ...d.magicwand, center } }));
  }, []);

  const magicWandIncreaseThrehold = useCallback(
    () =>
      setCurrentData((d) => ({
        ...d,
        magicwand: { ...d.magicwand, threshold: d.magicwand.threshold + 1 },
      })),
    []
  );

  const magicWandDecreaseThrehold = useCallback(
    () =>
      setCurrentData((d) => ({
        ...d,
        magicwand: {
          ...d.magicwand,
          threshold:
            d.magicwand.threshold > 1
              ? d.magicwand.threshold - 1
              : d.magicwand.threshold,
        },
      })),
    []
  );

  const sausageAddPoint = useCallback(
    (p: AddPoint2DInput) => {
      const lastpoint = last(currentData.sausage.points);
      if (
        lastpoint &&
        (lastpoint.x - p.x) ** 2 + (lastpoint.y - p.y) ** 2 < 25
      ) {
        return;
      }

      setCurrentData((d) => {
        const topLeft: Point = {
          x: p.x < d.sausage.topLeft.x ? p.x : d.sausage.topLeft.x,
          y: p.y < d.sausage.topLeft.y ? p.y : d.sausage.topLeft.y,
        };
        const bottomRight: Point = {
          x: p.x > d.sausage.bottomRight.x ? p.x : d.sausage.bottomRight.x,
          y: p.y > d.sausage.bottomRight.y ? p.y : d.sausage.bottomRight.y,
        };
        return {
          ...d,
          sausage: {
            ...d.sausage,
            points: [...d.sausage.points, p],
            topLeft,
            bottomRight,
          },
        };
      });
    },
    [currentData.sausage.points]
  );

  const sausageBigger = useCallback(
    () =>
      setCurrentData((d) => ({
        ...d,
        sausage: { ...d.sausage, radius: d.sausage.radius + 1 },
      })),
    []
  );

  const sausageSmaller = useCallback(
    () =>
      setCurrentData((d) => ({
        ...d,
        sausage: { ...d.sausage, radius: d.sausage.radius - 1 },
      })),
    []
  );

  const polygonAddPoint = useCallback(
    (p: AddPoint2DInput) =>
      setCurrentData((d) => ({
        ...d,
        polygon: { ...d.polygon, points: [...d.polygon.points, p] },
      })),
    []
  );

  const penAddPoint = useCallback(
    (p: AddPoint2DInput) =>
      setCurrentData((d) => ({
        ...d,
        pen: { ...d.pen, points: [...d.pen.points, p] },
      })),
    []
  );

  const rectangleStart = useCallback((p: AddPoint2DInput) => {
    setCurrentData((d) => ({
      ...d,
      reactangle: { ...d.reactangle, topLeft: p },
    }));
  }, []);

  const boundingBoxStart = useCallback((p: AddPoint2DInput) => {
    setCurrentData((d) => ({
      ...d,
      boundingBox: {
        ...d.reactangle,
        topLeft: p,
        dataURL: "",
      },
    }));
  }, []);

  const applyClick = useCallback(
    (tool: ToolType, position: AddPoint2DInput) => {
      setChangedByUser(true);
      switch (tool) {
        case "MagicWand":
          magicWandMove(position);
          break;
        case "Circle":
          circleMove(position);
          break;
        case "Polygon":
          polygonAddPoint(position);
          break;
        case "Sausage":
          sausageAddPoint(position);
          break;
        case "FreeForm":
          polygonAddPoint(position);
          break;
        case "Rectangle":
          rectangleStart(position);
          break;
        case "BoundingBox":
          boundingBoxStart(position);
          break;
        case "None":
          break;
        default:
          console.log("cannot apply click for tool type ", tool);
      }
    },
    [
      circleMove,
      magicWandMove,
      polygonAddPoint,
      rectangleStart,
      boundingBoxStart,
      sausageAddPoint,
    ]
  );

  const getAsPolygon = useCallback(
    (tool: ToolType, cusorPosition: AddPoint2DInput): AddPoint2DInput[] => {
      switch (tool) {
        case "MagicWand":
          return magicWandToPoly({ ...currentData.magicwand });
        case "Circle":
          return circleToPoly({
            ...currentData.circle,
            center: cusorPosition,
          });
        case "Polygon":
          return currentData.polygon.points;
        case "Sausage":
          return sausageToPoly({ ...currentData.sausage });
        case "FreeForm":
          return currentData.polygon.points;
        case "Rectangle":
          return rectangleToPoly({
            ...currentData.reactangle,
            bottomRight: cusorPosition,
          });
        case "None":
          return [];
        default:
          console.log("cannot generate polygon for tool type ", tool);
          return [];
      }
    },
    [
      currentData.circle,
      currentData.magicwand,
      currentData.polygon.points,
      currentData.reactangle,
      currentData.sausage,
    ]
  );

  const actions = useMemo(
    () => ({
      applyClick,
      circleMove,
      circleResize,
      circleSet,
      magicWandDecreaseThrehold,
      magicWandIncreaseThrehold,
      magicWandMove,
      magicWandSet,
      sausageAddPoint,
      penAddPoint,
      sausageBigger,
      sausageSmaller,
      polygonAddPoint,
      boundingBoxStart,
      reset,
      rectangleStart,
      getAsPolygon,
    }),
    [
      applyClick,
      circleMove,
      circleResize,
      circleSet,
      getAsPolygon,
      magicWandDecreaseThrehold,
      magicWandIncreaseThrehold,
      magicWandMove,
      magicWandSet,
      penAddPoint,
      polygonAddPoint,
      rectangleStart,
      boundingBoxStart,
      reset,
      sausageAddPoint,
      sausageBigger,
      sausageSmaller,
    ]
  );

  const [circleRadius, setCircleRadius] = useState(0);
  useInterval(() => {
    if (uglyStore && uglyStore.circleRadius !== circleRadius) {
      circleResize(uglyStore.circleRadius);
      setCircleRadius(uglyStore.circleRadius);
    }
  }, 100);

  const result = useMemo(
    () => [currentData, actions, changedByUser] as const,
    [actions, changedByUser, currentData]
  );

  return result;
}
type CurrentDataType2 = ReturnType<typeof useCurrentData>;
export type CurrentDataChangedByUser = CurrentDataType2[2];
export type CurrentDataActions = CurrentDataType2[1];
export type CurrentData = CurrentDataType2[0];
