import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

type StateType = {
  online: boolean;
  numberOfCachedMutations: number;
};

// user ui states w/o markuptool //
const name = "appState";
const initialState: StateType = {
  online: false,
  numberOfCachedMutations: 0,
};

const appSlice = createSlice({
  name,
  initialState,
  reducers: {
    setOnline: (state) => ({ ...state, online: true }),
    setOffline: (state) => ({ ...state, online: false }),
    setOnlineState: (state, action: PayloadAction<boolean>) => ({
      ...state,
      online: action.payload,
    }),
    setNumberOfCachedMutations: (state, action: PayloadAction<number>) => ({
      ...state,
      numberOfCachedMutations: action.payload,
    }),
  },
});

export const {
  setOnline,
  setOffline,
  setOnlineState,
  setNumberOfCachedMutations,
} = appSlice.actions;

export default appSlice.reducer;

export const isOnline = (state: RootState) => {
  return state.appState.online;
};

export const getNumberOfCachedMutations = (state: RootState) => {
  return state.appState.numberOfCachedMutations;
};
