/* eslint-disable react/jsx-one-expression-per-line */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import Plot from "react-plotly.js";
// import { Layout } from 'plotly.js';
import { Header, Segment, Button } from "semantic-ui-react";
import { getDevicePath } from "../../state/deviceControl";
import {
  useBTechSubscribeSensorDataSubscription,
  Vector3f,
} from "../../ts-clients/btech";

type PlotType = "heatmap" | "line";

const makeXAxis = (plotType: PlotType) => ({
  title: "s / mm",
  anchor: "y",
  showticklabels: plotType === "line",
});

const makeYAxis = (plotType: PlotType) => ({
  title: plotType === "heatmap" ? "s / arb. unit]" : "B [\u00b5T]",
  anchor: "x",
  autorange: plotType === "heatmap" ? "reversed" : true,
  showticklabels: plotType === "line",
});

const getAbsoluteValue = (
  bTSSD: { baseline: Vector3f[]; data: Vector3f[] },
  useBaseline_bool: boolean
) =>
  bTSSD.data.map((vector, index) =>
    Math.sqrt(
      (useBaseline_bool ? vector.x - bTSSD.baseline[index].x : vector.x) ** 2 +
        (useBaseline_bool ? vector.y - bTSSD.baseline[index].y : vector.y) **
          2 +
        (useBaseline_bool ? vector.z - bTSSD.baseline[index].z : vector.z) ** 2
    )
  );

const makeDimensionMaker =
  (
    bTSSD: { baseline: Vector3f[]; data: Vector3f[] },
    useBaseline_bool: boolean
  ) =>
  (dim: "x" | "y" | "z") =>
    bTSSD.data.map((vector, index) =>
      useBaseline_bool ? vector[dim] - bTSSD.baseline[index][dim] : vector[dim]
    );

const convertToMetric = (p: { y: number[] }) => p.y.map((_, i) => i * 2.5); // takes the y indices and converts them to metric x values

const initialPlotState = {
  field: {
    z: new Array<Array<number>>(64).fill(new Array<number>(64).fill(0)),
    type: "heatmap",
    zsmooth: "best",
    name: "‖B‖²",
    hoverinfo: "z+name",
    xaxis: "x",
    yaxis: "y",
  },
  bx: {
    x: new Array<number>(64).map((_, i) => i * 2.5),
    y: new Array<number>(64).fill(0),
    name: "Bx",
    xaxis: "x",
    yaxis: "y",
    line: { color: "red" },
  },
  by: {
    x: new Array<number>(64).map((_, i) => i * 2.5),
    y: new Array<number>(64).fill(0),
    name: "By",
    xaxis: "x",
    yaxis: "y",
    line: { color: "green" },
  },
  bz: {
    x: new Array<number>(64).map((_, i) => i * 2.5),
    y: new Array<number>(64).fill(0),
    name: "Bz",
    xaxis: "x",
    yaxis: "y",
    line: { color: "blue" },
  },
  autosize: true,
  margin: {
    t: 0,
    l: 0,
    r: 0,
    b: 0,
  },
};

export default function SensorLiveView() {
  const currentDevicePath = useSelector(getDevicePath);
  const { data, loading } = useBTechSubscribeSensorDataSubscription({
    variables: {
      device: currentDevicePath || "",
    },
    skip: !currentDevicePath,
  });

  const [revision, setRevision] = useState(0);
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const [plotType, setPlotType] = useState<PlotType>("line");
  const [subtractBaseline, setSubtractBaseline] = useState(false);
  const [isCalibrated, setCalibrated] = useState(false);

  // const [layout, setLayout] = useState<Layout>({
  const [layout, setLayout] = useState({
    datarevision: 0,
    xaxis: {
      title: "s [mm]",
      anchor: "y",
      showticklabels: true,
    },
    yaxis: {
      title: "B [\u00b5T]",
      anchor: "x",
      // autorange: true as boolean | 'reversed',
      autorange: true as boolean | string,
      showticklabels: true,
    },
  });

  useEffect(() => {
    setLayout({
      ...layout,
      xaxis: makeXAxis(plotType),
      yaxis: makeYAxis(plotType),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotType]);

  const increaseRevision = () => {
    setRevision(revision + 1);
    setLayout({
      ...layout,
      datarevision: revision + 1,
    });
  };

  const [line, setLine] = useState({
    // shows last set of datapoints with a red line
    type: "scatter",
    mode: "lines",
    line: { color: "red", width: 2.5 },
    x: [-1, 64],
    y: [0, 0],
    xaxis: "x",
    yaxis: "y",
  });

  const moveLine = (newPos: number) => {
    setLine({
      ...line,
      y: [newPos, newPos],
    });
  };

  if (
    !loading &&
    data !== undefined &&
    Date.now() - lastUpdate > (plotType === "heatmap" ? 100 : 10)
  ) {
    const { field, bx, by, bz } = initialPlotState;
    const currentIndex = revision % field.z.length;

    const { baseline } = data.bTechSubscribeSensorData;
    const couldUseBaseline = baseline.length > 0;
    const useBaseline = couldUseBaseline && subtractBaseline;

    const row = getAbsoluteValue(data.bTechSubscribeSensorData, useBaseline);
    field.z[currentIndex] = row;

    const makeDimension = makeDimensionMaker(
      data.bTechSubscribeSensorData,
      useBaseline
    );

    bx.y = makeDimension("x");
    by.y = makeDimension("y");
    bz.y = makeDimension("z");

    bx.x = convertToMetric(bx);
    by.x = convertToMetric(by);
    bz.x = convertToMetric(bz);

    moveLine(currentIndex);

    increaseRevision();

    setLastUpdate(Date.now());
    if (isCalibrated !== couldUseBaseline) {
      setCalibrated(couldUseBaseline);
    }
  }

  const onSwitchHeatmapAndLine = () => {
    if (plotType === "heatmap") {
      setPlotType("line");
    } else {
      setPlotType("heatmap");
    }
  };

  const onSubtractBaseline = () => {
    setSubtractBaseline((c) => !c);
    increaseRevision();
  };

  return (
    <Segment.Group>
      <Segment style={{ backgroundColor: "#f4f4f4" }}>
        <Header as="h2">
          Live View
          <Button.Group floated="right">
            <Button
              content={plotType === "heatmap" ? "Line-Plot" : "Heatmap"}
              onClick={onSwitchHeatmapAndLine}
            />
            <Button
              toggle
              content="subtract baseline"
              active={subtractBaseline}
              disabled={!isCalibrated}
              onClick={onSubtractBaseline}
            />
          </Button.Group>
        </Header>
      </Segment>
      <Segment style={{ height: 370 }}>
        PLOT
        {/* <Plot
          data={
            plotType === "heatmap"
              ? [plotState.field, line]
              : [plotState.bx, plotState.by, plotState.bz]
          }
          layout={layout}
          revision={revision}
          style={{ wídth: "100%", height: "100%" }}
        /> */}
      </Segment>
    </Segment.Group>
  );
}
