import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import { DenkjobState, DenkjobType } from "../../../../ts-clients/query";
import { Network } from "../../../../types/extractedTypes";

export function getTrainingState(network: Network) {
  const trainingStarting =
    network.denkjobs.findIndex(
      (denkjob) =>
        (denkjob?.jobType === DenkjobType.DenKtrainer ||
          denkjob?.jobType === DenkjobType.DenKtester) &&
        denkjob.state === DenkjobState.Starting
    ) >= 0;

  const trainingRunning =
    network.denkjobs.findIndex(
      (denkjob) =>
        (denkjob?.jobType === DenkjobType.DenKtrainer ||
          denkjob?.jobType === DenkjobType.DenKtester) &&
        denkjob.state === DenkjobState.Running
    ) >= 0;

  const trainingStopping =
    network.denkjobs.findIndex(
      (denkjob) =>
        denkjob?.jobType === DenkjobType.DenKtrainer &&
        denkjob.state === DenkjobState.Stopping
    ) >= 0;

  const trainingActive = trainingStarting || trainingRunning;

  const trainingAvailable =
    (network.trainingMarkups?.count ?? 0) >= 10 &&
    (network.validationMarkups?.count ?? 0) >= 2;

  const trainingRunColor: SemanticCOLORS = !trainingAvailable
    ? "grey"
    : trainingRunning
    ? "pink"
    : trainingStarting || trainingStopping
    ? "yellow"
    : "green";

  const trainingRunState = !trainingAvailable
    ? "not ready"
    : trainingRunning
    ? "running"
    : trainingStarting
    ? "starting"
    : trainingStopping
    ? "stopping"
    : "ready";

  const trainingRunStateLong = !trainingAvailable
    ? "The network cannot be trained yet. You probably need more annotated images."
    : trainingRunning
    ? "The training is currently running."
    : trainingStarting
    ? "Please wait while the training ist starting."
    : trainingStopping
    ? "Please wait while the training ist stopping."
    : "This network can be trained on your annotated data.";

  return {
    trainingRunState,
    trainingAvailable,
    trainingRunColor,
    trainingActive,
    trainingRunStateLong,
    trainingStarting,
    trainingRunning,
    trainingStopping,
  };
}
