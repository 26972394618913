import { useParams } from "react-router";
import { Card, Image, Grid } from "semantic-ui-react";
import { DatasetOverviewParams, useGoto } from "../../utils/routeManager";
import fileLogo from "./img/icon_upload_dark.svg";
import networkLogo from "./img/icon_neuralnet_colored.svg";

export default function DatasetOverviewIndex() {
  const { datasetId } = useParams<DatasetOverviewParams>();
  const goTo = useGoto();

  const showResearchBoxList = () =>
    goTo("ResearchBoxList", { datasetId, context: "researchboxlist" });

  const showNetworks = () => goTo("NetworkStats", { datasetId });

  return (
    <Grid centered style={{ marginTop: 30 }}>
      <Grid.Column style={{ width: 291 }}>
        <Card style={{ width: 291 }} onClick={showResearchBoxList}>
          <Image src={fileLogo} wrapped ui={false} />
          <Card.Content>
            <Card.Header>Files</Card.Header>
            <Card.Description>
              view, edit, annotate files in this dataset
            </Card.Description>
          </Card.Content>
        </Card>
      </Grid.Column>
      <Grid.Column style={{ width: 291 }}>
        <Card style={{ width: 291 }} onClick={showNetworks}>
          <Image src={networkLogo} wrapped ui={false} />
          <Card.Content>
            <Card.Header>Networks</Card.Header>
            <Card.Description>Manage Networks</Card.Description>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid>
  );
}
