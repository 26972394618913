import useHook from "../../../hooks/moduleCreators-new-2021-04/PMContrastEnhancementUpdate";
import ModuleForm from "./forms/FormPipelineModuleContrastEnhancement";

type Props = {
  moduleId: string;
  onCancel: () => unknown;
};

export default function PMContrastEnhancemenUpdate({
  moduleId,
  onCancel,
}: Props) {
  const { moduleData, setBaseParams, setModuleData, update } =
    useHook(moduleId);

  const onSubmit = () => {
    update();
    onCancel();
  };

  return (
    <ModuleForm
      moduleData={moduleData}
      onCancel={onCancel}
      onSave={onSubmit}
      setBaseParams={setBaseParams}
      setData={setModuleData}
    />
  );
}
