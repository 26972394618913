import { useContext } from "react";
import { Divider, Header } from "semantic-ui-react";
import { ResearchBoxContext } from "../../../../../ctx/ResearchBoxProvider";
import { formatDate } from "../../../../../utils/helpers";

export default function ImageInfo() {
  const { researchbox } = useContext(ResearchBoxContext);

  const [created, updated] = [
    researchbox?.createdAt,
    researchbox?.updatedAt,
  ].map(formatDate);

  return (
    <>
      <Header as="h4">Image</Header>
      <Divider />
      <p>
        <strong>Filename:</strong>
        <br />
        <span style={{ wordWrap: "break-word" }}>{researchbox?.name}</span>
      </p>
      <p>
        <strong>Created: {created.timeAgo}</strong>
        <br />
        {created.dateTime}
      </p>
      <p>
        <strong>Last Updated: {updated.timeAgo}</strong>
        <br />
        {updated.dateTime}
      </p>
      <p>
        <strong>State:</strong>
        <br />
        unbearbeitet / positiv / negativ markiert für das aktuelle netz
      </p>
    </>
  );
}
