import { Button, Dropdown, Menu } from "semantic-ui-react";
import { NavigationProps } from "../../../utils";
import { ImageInfo } from "../../hooks/useImageInfo";
import ImageStatus from "../../shared/LayerTools/ImageStatus";

type Props = {
  navigation: NavigationProps;
  saveVector: () => void;
  deleteVector: () => void;
  deleteResearchbox: () => void;
  imageInfo: ImageInfo;
  lastPredictionTime: string;
  subset: string;
};

export default function LayerTools({
  deleteResearchbox,
  navigation,
  saveVector,
  deleteVector,
  imageInfo,
  lastPredictionTime,
  subset,
}: Props) {
  const saveAndNext = () => {
    saveVector();
    navigation.goNext();
  };

  const handleClick = (a: "delete" | "clear") => {
    if (a === "clear") {
      deleteVector();
      navigation.goNext();
    }
    if (a === "delete") {
      deleteResearchbox();
    }
  };

  return (
    <Menu attached="top">
      <Menu.Menu position="right">
        <Menu.Item position="right">
          <ImageStatus
            lastPredictionTime={lastPredictionTime}
            imageInfo={imageInfo}
            subset={subset}
          />
        </Menu.Item>

        <Menu.Item position="right">
          <Button.Group color="green">
            <Button
              onClick={saveAndNext}
              icon="save"
              content="Save &amp; Next"
              style={{ fontWeight: "bold" }}
            />
            <Dropdown
              className="button icon"
              floating
              value=""
              options={[
                {
                  key: "clear",
                  text: "Clear & Next",
                  value: "clear",
                },
                {
                  key: "delete",
                  text: "Delete & Next",
                  value: "delete",
                },
              ]}
              trigger={<></>}
              onChange={(_, { value }) =>
                handleClick(value as "delete" | "clear")
              }
            />
          </Button.Group>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
}
