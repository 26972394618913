import { ModuleType } from "ModuleTypes";
import { Message } from "semantic-ui-react";
// import { getNiceModuleName } from '../shared';
import PMCCCheckerCreate from "./PMCCCheckerCreate";
import PMContrastEnhancementCreate from "./PMContrastEnhancementCreate";
import PMNetworkImageClassificationCreate from "./PMNetworkImageClassificationCreate";
import PMNetworkImageSegmentationCreate from "./PMNetworkImageSegmentationCreate";
import PMNetworkImageObjectDetectionCreate from "./PMNetworkImageObjectDetectionCreate";
import { PretrainedNetwork } from "../../../types/extractedTypes";
import { useEffect, useState } from "react";
import { PrefillData } from "FormTypes";

type Props = {
  onCancel: () => unknown;
  datasetId: string | null;
  moduleType: ModuleType;
  from: PretrainedNetwork | null;
};

export default function ModuleCreate({
  onCancel,
  datasetId,
  moduleType,
  from,
}: Props) {
  const [prefillData, setPrefillData] = useState<PrefillData | null>(null);

  useEffect(() => {
    if (from) {
      setPrefillData({
        description: from.networkModule.description,
        name: from.networkModule.name,
        pretrainedNetworkModuleId: from.networkModule.id,
        classLabels: from.networkModule.classLabels,
      });
    } else {
      setPrefillData({
        description: "",
        name: "",
        pretrainedNetworkModuleId: null,
        classLabels: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(from)]);

  return (
    <RenderModule
      onCancel={onCancel}
      datasetId={datasetId}
      moduleType={moduleType}
      prefillData={prefillData}
    />
  );
}

type RenderProps = {
  onCancel: () => unknown;
  datasetId: string | null;
  moduleType: ModuleType;
  prefillData: PrefillData | null;
};

function RenderModule({
  onCancel,
  datasetId,
  moduleType,
  prefillData,
}: RenderProps) {
  switch (moduleType) {
    case "PipelineModuleCCChecker":
      return <PMCCCheckerCreate datasetId={datasetId} onCancel={onCancel} />;
    case "PipelineModuleContrastEnhancement":
      return (
        <PMContrastEnhancementCreate
          datasetId={datasetId}
          onCancel={onCancel}
          prefillData={prefillData}
        />
      );
    case "PipelineModuleNetworkImageClassification":
      return (
        <PMNetworkImageClassificationCreate
          datasetId={datasetId}
          onCancel={onCancel}
          prefillData={prefillData}
        />
      );
    case "PipelineModuleNetworkImageSegmentation":
      return (
        <PMNetworkImageSegmentationCreate
          datasetId={datasetId}
          onCancel={onCancel}
          prefillData={prefillData}
        />
      );
    case "PipelineModuleNetworkImageObjectDetection":
      return (
        <PMNetworkImageObjectDetectionCreate
          datasetId={datasetId}
          onCancel={onCancel}
          prefillData={prefillData}
        />
      );
    default:
      return (
        <Message
          content={`No create renderer exists for module type ${moduleType}`}
          info
        />
      );
  }
}
