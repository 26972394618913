import { Card, Label } from "semantic-ui-react";
import { DatapointType } from "../../types/extractedTypes";

type RenderProps = {
  d: DatapointType;
};

export default function UnknownDataType({ d }: RenderProps) {
  return (
    <Card.Content>
      <Label
        key={d.id}
        content={`Cannot render ${d.producedBy?.dataType} datapoint yet.`}
      />
    </Card.Content>
  );
}
