import { useSelector } from "react-redux";
import { Button, Modal, Grid, Table, Image, Icon } from "semantic-ui-react";
import { getOutputDisplayPriority } from "../../state/datasetSettings";
import { FolderType, ResearchBoxType } from "../../types/extractedTypes";
import DatapointPreview from "../datapoint-viewer";
import { getPreviewDataPoint } from "../details-researchbox/utils";
import noimg from "../../resources/noimg.png";

type DeleteCandidateType = ResearchBoxType | FolderType | null;

export type Props = {
  deleteCandidates: DeleteCandidateType[];
  open: boolean;
};

export type Actions = {
  handleDelete: () => unknown;
  close: () => unknown;
};

export default function ResearchBoxDeleteModal({
  deleteCandidates,
  handleDelete,
  open,
  close,
}: Props & Actions) {
  const prio = useSelector(getOutputDisplayPriority);

  if (
    !deleteCandidates ||
    (deleteCandidates && deleteCandidates.length === 0)
  ) {
    return null;
  }

  const folders = deleteCandidates.flatMap((c) =>
    c?.__typename === "Folder" ? c : []
  );

  const files = deleteCandidates.flatMap((c) =>
    c?.__typename === "ResearchBox" ? c : []
  );

  const previewDatapoint =
    files.length > 0
      ? getPreviewDataPoint(prio, files[0].datapoints ?? [])
      : undefined;

  const candidateType =
    // eslint-disable-next-line no-nested-ternary
    folders.length > 0 && files.length > 0
      ? "object"
      : folders.length > 0
      ? "folder"
      : "file";

  const isMultipleSelection = deleteCandidates.length > 1;

  const isSingleFile = files.length === 1 && folders.length === 0;
  const isSingleFolder = folders.length === 1 && files.length === 0;

  const name = isMultipleSelection
    ? "Multiple"
    : deleteCandidates[0]?.name || "Unknown";

  return (
    <Modal open={open} onClose={close} closeIcon>
      <Modal.Header>
        {`Delete ${candidateType}${isMultipleSelection ? "s" : ""}`}
      </Modal.Header>
      <Modal.Content>
        <Grid divided="vertically">
          <Grid.Row columns={2}>
            <Grid.Column>
              {isSingleFile && previewDatapoint && (
                <DatapointPreview datapoint={previewDatapoint} />
              )}
              {!previewDatapoint && isSingleFile && !isMultipleSelection && (
                <Image src={noimg} />
              )}
              {isSingleFolder && <Icon name="folder" size="massive" />}
              {isMultipleSelection && <Icon name="box" size="massive" />}
            </Grid.Column>

            <Grid.Column>
              <h3 style={{ color: "#000000" }}>
                {`Do you really want to delete ${
                  isMultipleSelection ? "these" : "this"
                } ${candidateType}${isMultipleSelection ? "s" : ""}?`}
              </h3>

              <Table basic="very">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Name</Table.Cell>
                    <Table.Cell>
                      {name !== "" ? name : `Unnamed ${candidateType}`}
                    </Table.Cell>
                  </Table.Row>

                  {/* <Table.Row>
                    <Table.Cell>ID</Table.Cell>
                    <Table.Cell>{deleteCandidate.id}</Table.Cell>
                  </Table.Row> */}

                  {/* {deleteCandidate.__typename === 'ResearchBox' && (
                    <Table.Row>
                      <Table.Cell>Files</Table.Cell>
                      <Table.Cell>
                        {deleteCandidate.datapoints?.length ?? 0}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {deleteCandidate.__typename === 'Folder' && (
                    <>
                      <Table.Row>
                        <Table.Cell>Files</Table.Cell>
                        <Table.Cell>
                          {deleteCandidate.researchBoxesAggregate?.count ?? 0}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Folders</Table.Cell>
                        <Table.Cell>
                          {deleteCandidate.foldersAggregate?.count ?? 0}
                        </Table.Cell>
                      </Table.Row>
                    </>
                  )} */}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={close}>Cancel</Button>
        <Button
          onClick={handleDelete}
          color="red"
          icon="trash"
          content="Delete"
        />
      </Modal.Actions>
    </Modal>
  );
}
