import { UiFeature } from "../../../ts-clients/command";

const uiFeatures = [
  {
    key: UiFeature.DatasetMutate,
    text: "Mutate Datasets",
    value: UiFeature.DatasetMutate,
  },
  {
    key: UiFeature.FolderMutate,
    text: "Mutate Folders",
    value: UiFeature.FolderMutate,
  },
  {
    key: UiFeature.ResearchboxMutate,
    text: "Mutate ResearchBoxes",
    value: UiFeature.ResearchboxMutate,
  },
  {
    key: UiFeature.UploadFiles,
    text: "Upload Files",
    value: UiFeature.UploadFiles,
  },
  {
    key: UiFeature.NetworkMutate,
    text: "Mutate Networks",
    value: UiFeature.NetworkMutate,
  },
  {
    key: UiFeature.MarkupMutate,
    text: "Mutate Markups",
    value: UiFeature.MarkupMutate,
  },
  {
    key: UiFeature.MarkupPositiveNegativeIgnore,
    text: "Positive/Negative/Ignore Markups",
    value: UiFeature.MarkupPositiveNegativeIgnore,
  },
  {
    key: UiFeature.NetworkTraining,
    text: "Network Training",
    value: UiFeature.NetworkTraining,
  },
  {
    key: UiFeature.NetworkDownload,
    text: "Network Download",
    value: UiFeature.NetworkDownload,
  },
  {
    key: UiFeature.NetworkAdvanced,
    text: "Advanced Network Configuration",
    value: UiFeature.NetworkAdvanced,
  },
  {
    key: UiFeature.FlowDesigner,
    text: "Flow-Designer",
    value: UiFeature.FlowDesigner,
  },
];

export default uiFeatures;
