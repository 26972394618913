/* eslint-disable react/jsx-curly-newline */
import { useCallback, useEffect, useRef } from "react";
import Konva from "konva";
import useImage from "use-image";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Stage, Layer, Image } from "react-konva";
import * as Layers from "./Layers";
import useStage from "./hooks/useStageEditable";
import { useUrlFromSelectedResolution } from "../../../utils/helpers";
import useCursor from "./hooks/useCursor";
import { getPointerPosition } from "./hooks/helper";
import {
  getSelectedPolygonId,
  setSelectedPolygonId as setSetSelectedPolygonIdInState,
} from "../../details-researchbox/Markup/inputImage/redux-state";
import { ImageDatapointType } from "../../../types/extractedTypes";
import { InteractionHooksType } from "./hooks/useInteractionHooks";
import { CurrentData, CurrentDataActions } from "./hooks/useCurrentData";
import { LeanInteractionState, MarkupToolInteraction } from "./hooks/types";
import { Annotations2Type } from "./hooks/useAnnotations2";
import { KonvaEventObject } from "konva/lib/Node";
import { MarkupLabel, Point } from "MarkupTypes";
import { HighlightedIds } from "./hooks/useHighlighing";

const getStage = (e: KonvaEventObject<Event>) => {
  e.evt.preventDefault();
  return e.currentTarget.getStage();
};

type Props = {
  imageDatapoint: ImageDatapointType;
  annotations: Annotations2Type["annotations"];
  annotationActions: Annotations2Type["annotationActions"];
  segmentationsVisible: boolean;
  paintActive: string | null;
  setHook: InteractionHooksType["setHook"];
  updateState: (a: MarkupToolInteraction, s: LeanInteractionState) => void;
  currentDataChangedByUser: boolean;
  currentDataActions: CurrentDataActions;
  currentData: CurrentData;
  pixelCanvas: HTMLCanvasElement | undefined;
  highlightedStartingPoint: Point | null;
  highlightedIds: HighlightedIds;
  labelList: MarkupLabel[];
};

export default function ImageWithSegmentation2dEditable({
  imageDatapoint,
  annotations,
  annotationActions,
  segmentationsVisible,
  paintActive,
  setHook,
  updateState,
  currentDataChangedByUser,
  currentDataActions,
  currentData,
  pixelCanvas,
  highlightedStartingPoint,
  highlightedIds,
  labelList,
}: Props) {
  const divWrapper = useRef<HTMLDivElement>(null);

  const thumbnailData = imageDatapoint.thumbnail;

  const imagUrl = useUrlFromSelectedResolution(imageDatapoint);

  const imageSize = {
    width: imageDatapoint.width,
    height: imageDatapoint.height,
  };

  const [image] = useImage(imagUrl ?? "", "anonymous");

  const maybeStage = useRef<Konva.Stage>(null);

  const { setCursor } = useCursor();

  const selectedPolygonId = useSelector(getSelectedPolygonId);

  const selectedAnnotationFlavour =
    annotationActions.getAnnotationFlavour(selectedPolygonId);

  const {
    annotationType,
    canvasSize,
    mouseDown,
    mouseMode,
    mouseOnCanvas,
    resizeCanvas,
    selectedSegmentation2DTool,
    stageActions,
    stageEvents,
    scale,
    cursorType,
  } = useStage(
    maybeStage.current,
    paintActive !== null,
    updateState,
    currentDataChangedByUser,
    selectedAnnotationFlavour,
    imageSize
  );

  const currentColor =
    labelList.find((l) => l.id === annotations.markupLabelId)?.color ?? "#def";

  useEffect(() => {
    setHook("stageZoomIn", stageActions.zoomIn);
    setHook("stageZoomOut", stageActions.zoomOut);
    setHook("stageResetZoom", stageActions.resetZoom);
  }, [
    setHook,
    stageActions.resetZoom,
    stageActions.zoomIn,
    stageActions.zoomOut,
  ]);

  useEffect(() => {
    stageActions.resetZoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image?.id, maybeStage.current]);

  // when switching to another image, also reset any temporary drawings
  useEffect(() => {
    currentDataActions.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image?.id]);

  const [thumbnailImage] = useImage(thumbnailData);
  const showImage = image === undefined ? thumbnailImage : image;

  useEffect(() => {
    resizeCanvas({
      width: (divWrapper.current?.offsetWidth || 0) as number,
      height: (divWrapper.current?.offsetHeight || 0) as number,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divWrapper]);

  const onMouseMove = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const stage = getStage(e);
      const cursor = getPointerPosition(stage);
      setCursor(cursor);

      // some tools get points added while dragging the mouse
      if (
        mouseDown &&
        mouseMode !== "ctrl" &&
        ["Sausage", "FreeForm"].includes(selectedSegmentation2DTool)
      ) {
        currentDataActions.applyClick(selectedSegmentation2DTool, cursor);
      }
    },
    [
      currentDataActions,
      mouseDown,
      mouseMode,
      selectedSegmentation2DTool,
      setCursor,
    ]
  );

  const dispatch = useDispatch();

  const setSelectedPolygonId = useCallback((id: string | null) => {
    dispatch(setSetSelectedPolygonIdInState(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const resize = debounce(() => {
      resizeCanvas({
        width: divWrapper.current?.offsetWidth ?? 300,
        height: divWrapper.current?.offsetHeight ?? 300,
      });
    }, 200);
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resizeCanvas]);

  return (
    <div
      ref={divWrapper}
      style={{ height: "100%", width: "100%", overflow: "hidden" }}
    >
      <Stage
        ref={maybeStage}
        style={{
          cursor: cursorType,
        }}
        width={canvasSize.width}
        height={canvasSize.height}
        draggable={
          (selectedSegmentation2DTool === "None" || mouseMode === "ctrl") &&
          selectedPolygonId === null
        }
        onClick={stageEvents.onClick}
        onWheel={stageEvents.onWheel}
        onDragMove={stageEvents.onDragMove}
        onDblClick={stageEvents.onDoubleClick}
        onMouseMove={onMouseMove}
        onMouseDown={stageEvents.onMouseDown}
        onMouseUp={stageEvents.onMouseUp}
        onMouseLeave={stageEvents.onMouseLeave}
        onMouseEnter={stageEvents.onMouseEnter}
        // onDeleteCurrent={handle(actions.applyDeleteCurrent)}
      >
        <Layer>
          <Layers.URLImage image={showImage} size={imageSize} />
          {segmentationsVisible && (
            <Layers.AnnotationsEditable
              annotations={annotations}
              annotationActions={annotationActions}
              setActivePolygonId={setSelectedPolygonId}
              selectedPolygonId={selectedPolygonId}
              paintActiveAnnotationId={paintActive}
              scale={scale}
              highlightedIds={highlightedIds}
            />
          )}
        </Layer>
        <Layer>
          <Image image={pixelCanvas} />
          <Layers.CurrentData
            currentData={currentData}
            currentColor={currentColor}
            currentAnnotationType={annotationType}
            showCurrentAnnotation={currentDataChangedByUser}
            selectedSegmentation2DTool={selectedSegmentation2DTool}
          />
        </Layer>

        {selectedPolygonId === null && (
          <Layers.Brush
            tool={selectedSegmentation2DTool}
            showCurrentAnnotation={currentDataChangedByUser}
            currentData={currentData}
            visible={mouseOnCanvas}
            annotationType={annotationType}
            scale={scale}
            currentColor={currentColor}
            highlightStartingPoint={highlightedStartingPoint}
          />
        )}
      </Stage>
    </div>
  );
}
