/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role, UiFeature } from "../ts-clients/command";

import { RootState } from "./store";

type LoginState = "none" | "logged-in" | "invalid";

export type UserInfo = {
  username: string;
  role: Role;
  token: string;
  allowedFeatures: UiFeature[];
  blockedFeatures: UiFeature[];
};

type StateType = {
  currentUser: UserInfo;
  loginState: LoginState;
};

// user ui states w/o markuptool //
const name = "loginState";
const initialState: StateType = {
  currentUser: {
    role: Role.Unauthorized,
    token: "",
    username: "",
    allowedFeatures: [],
    blockedFeatures: [],
  },
  loginState: "none",
};

const appSlice = createSlice({
  name,
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<UserInfo>) => ({
      ...state,
      currentUser: action.payload,
    }),

    clearLogin: () => ({ ...initialState }),

    setLoginState: (state, action: PayloadAction<LoginState>) => ({
      ...state,
      loginState: action.payload,
    }),
  },
});

export const { setCurrentUser, clearLogin, setLoginState } = appSlice.actions;

export default appSlice.reducer;

export const getCurrentUser = (state: RootState) => state.login.currentUser;

export const isLoggedIn = (state: RootState) =>
  state.login.loginState === "logged-in";

export const isInvalid = (state: RootState) =>
  state.login.loginState === "invalid";

export const currentUserIsAdmin = (state: RootState): boolean =>
  state.login.currentUser?.role === Role.Admin;

export const currentUserIsVendor = (state: RootState): boolean =>
  state.login.currentUser?.role === Role.Vendor;

const canDo = (state: RootState, feature: UiFeature) =>
  !state.login.currentUser.blockedFeatures.includes(feature) &&
  (state.login.currentUser.allowedFeatures.length === 0 ||
    state.login.currentUser.allowedFeatures.includes(feature));

export const canUpload = (state: RootState) =>
  canDo(state, UiFeature.UploadFiles);

export const canMutateNetwork = (state: RootState) =>
  canDo(state, UiFeature.NetworkMutate);

export const canMutateMarkup = (state: RootState) =>
  canDo(state, UiFeature.MarkupMutate);

export const canUsePositiveNegativeIgnore = (state: RootState) =>
  canDo(state, UiFeature.MarkupPositiveNegativeIgnore);

export const canMutateDataset = (state: RootState) =>
  canDo(state, UiFeature.DatasetMutate);

export const canMutateResearchbox = (state: RootState) =>
  canDo(state, UiFeature.ResearchboxMutate);

export const canUseNetworkAdvanced = (state: RootState) =>
  canDo(state, UiFeature.NetworkAdvanced);

export const canUseFlowdesigner = (state: RootState) =>
  canDo(state, UiFeature.FlowDesigner);
