/* eslint-disable react/jsx-curly-newline */
import { Grid, Segment } from "semantic-ui-react";
import ToolsVector from "./ToolsVector";
import ImageZoomable from "../../../../datapoint-viewer/ImageWithSegmentation2d/ReadOnlyThumbnail";
import { ImageDatapointType } from "../../../../../types/extractedTypes";
import { NavigationProps } from "../../../utils";
import useVectorData from "./hooks/useVectorData";
import useGetSelectedImageResolution from "../../../../../hooks/useGetSelectedImageResolution";
import { MarkupLabel } from "MarkupTypes";
import LayerTools from "./LayerTools";
import useImageInfo from "../../hooks/useImageInfo";

type Props = {
  networkId: string;
  input: ImageDatapointType;
  selector: React.ReactNode;
  labelList: MarkupLabel[];
  navigation: NavigationProps;
  deleteResearchbox: () => void;
};

export default function VectorIndex({
  input,
  networkId,
  labelList,
  selector,
  navigation,
  deleteResearchbox,
}: Props) {
  const { selectedResolution } = useGetSelectedImageResolution({
    image: input,
  });

  const {
    enableAll,
    saveVector,
    disableAll,
    toggle,
    vector,
    deleteVector,
    lastPredictionTime,
    subset,
  } = useVectorData(input.id, networkId, selectedResolution, labelList);

  const imageInfo = useImageInfo(networkId);

  return (
    <Grid>
      <Grid.Column width="4">
        <Segment attached>{selector}</Segment>
        <ToolsVector
          vectorData={vector}
          toggleLabel={toggle}
          disableAll={disableAll}
          enableAll={enableAll}
        />
      </Grid.Column>
      <Grid.Column width="12">
        <LayerTools
          deleteResearchbox={deleteResearchbox}
          deleteVector={deleteVector}
          navigation={navigation}
          saveVector={saveVector}
          lastPredictionTime={lastPredictionTime}
          subset={subset ?? "unknown"}
          imageInfo={imageInfo}
        />
        <Segment style={{ height: "calc(100vh - 230px)" }} attached="bottom">
          <ImageZoomable datapoint={input} />
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
