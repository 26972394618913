/* eslint-disable react/jsx-one-expression-per-line */
import { useParams } from "react-router-dom";
import FlowDesigner from "../../features/flow-designer";
import ResearchBoxListMenu from "./ResearchBoxListMenu";
import Center from "../../features/filemanagement/DefaultPositioner";
import MenuMain from "../../components/menus/menu.main";

type Params = {
  datasetId: string;
};

export default function FlowContainer() {
  const { datasetId } = useParams<Params>();
  return (
    <>
      <MenuMain />
      <ResearchBoxListMenu
        current="flow-designer"
        datasetId={datasetId || ""}
      />
      <Center>
        <FlowDesigner datasetId={datasetId || ""} />
      </Center>
    </>
  );
}
