import { MouseMode } from "MarkupTypes";
import { useState, useEffect } from "react";

export default function useMouseMode() {
  const [mouseMode, setMouseMode] = useState<MouseMode>("default");

  const onKeyUp = () => {
    setMouseMode("default");
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Control") {
      setMouseMode("ctrl");
    } else if (event.key === "Alt" || event.key === "AltGraph") {
      setMouseMode("alt");
    } else if (event.key === "Shift") {
      setMouseMode("shift");
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    document.addEventListener("keyup", onKeyUp, false);
    return function cleanUp() {
      document.removeEventListener("keydown", onKeyDown, false);
      document.removeEventListener("keyup", onKeyUp, false);
    };
  }, []);

  return mouseMode;
}
