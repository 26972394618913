import { BaseParams } from "ModuleTypes";
import { useEffect, useState } from "react";
import { useGetPipelineModuleNetworkImageObjectDetectionQuery } from "../../ts-clients/query";
import { useUpdatePipelineModuleNetworkImageObjectDetectionMutation } from "../../ts-clients/command";
import { pipelineModuleNetworkImageObjectDetectionDefaults as defaults } from "./defaults";

export default function useUpdatePipelineModuleNetworkImageObjectDetection(
  pipelineModuleId: string
) {
  const [moduleData, setModuleData] = useState(defaults());
  const { data } = useGetPipelineModuleNetworkImageObjectDetectionQuery({
    variables: {
      id: pipelineModuleId,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const d = data?.getPipelineModuleNetworkImageObjectDetection;
    const outputs = d?.outputs ?? [];
    const firstOuputName = outputs.length > 0 ? outputs[0].name : "";
    setModuleData({
      moduleDescription: d?.description ?? "",
      moduleName: d?.name ?? "",
      pretrainedNetworkModuleId: pipelineModuleId,
      position: { x: d?.layoutPosition.x ?? 0, y: d?.layoutPosition.y ?? 0 },
      networkName: firstOuputName,
      classLabels:
        d?.classLabels.map((l) => ({
          id: l.id,
          name: l.name,
          color: l.color,
        })) ?? [],
      selectedPipelineModuleOutputIDs:
        d?.inputs.map((i) => i.edges.map((e) => e.from.id)).flat(2) ?? [],
    });
  }, [data, pipelineModuleId]);

  const setBaseParams = ({ moduleDescription, moduleName }: BaseParams) => {
    setModuleData((md) => ({ ...md, moduleName, moduleDescription }));
  };

  const [m] = useUpdatePipelineModuleNetworkImageObjectDetectionMutation();

  const update = () => {
    m({
      variables: {
        input: {
          filter: {
            moduleId: pipelineModuleId,
          },
          name: moduleData.moduleName,
          description: moduleData.moduleDescription,
          networkName: moduleData.networkName,
          classLabels: moduleData.classLabels
            .filter((c) => c.name !== "")
            .map((c) => ({
              id: c.id,
              name: c.name,
              color: c.color,
            })),
        },
      },
    });
  };

  return { update, moduleData, setModuleData, setBaseParams };
}
