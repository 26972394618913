import Admin from "../../features/admin";
import MenuMain from "../../components/menus/menu.main";

export default function HomePage() {
  return (
    <>
      <MenuMain />
      <Admin />
    </>
  );
}
