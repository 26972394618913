import { sort } from "ramda";
import { Point } from "MarkupTypes";
import {
  circleToPoly,
  magicWandToPoly,
  rectangleToPoly,
  sausageToPoly,
} from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks/annotationHelper";
import { AnnotationType } from "../../../../../ts-clients/command";
import { MyAnnotations } from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks/types";

export type AnnotationDataType = "image" | "polygon";

type MarkupLabelId = string;
export type DisplayItem = {
  id: string;
  points: Point[];
  previewAs: AnnotationDataType;
  dataURL?: string;
  active: boolean;
  sortInfo: string;
  labelColor: string;
  annotationType: AnnotationType;
  isPrediction?: boolean;
  probability?: number;
};
type MarkupListMap = Map<MarkupLabelId, DisplayItem[]>;

export const annotationDataToDisplayList = (
  annotationData: MyAnnotations | undefined,
  selectedPolygonID: string | null
): MarkupListMap => {
  const displayList = new Map<MarkupLabelId, DisplayItem[]>();

  if (annotationData === undefined) {
    return displayList;
  }

  annotationData?.circleAnnotations.forEach((a) => {
    displayList.set(a.markupLabelId, [
      ...(displayList.get(a.markupLabelId) ?? []),
      {
        id: a.id,
        labelColor: a.markupLabelColor,
        points: circleToPoly(a),
        previewAs: "polygon",
        active: a.id === selectedPolygonID,
        sortInfo: a.createdAt,
        annotationType: a.annotationType,
        isPrediction: a.isPrediction,
        probability: a.probability,
      },
    ]);
  });
  annotationData?.magicwandAnnotations.forEach((a) => {
    displayList.set(a.markupLabelId, [
      ...(displayList.get(a.markupLabelId) ?? []),
      {
        id: a.id,
        labelColor: a.markupLabelColor,
        points: magicWandToPoly(a),
        previewAs: "polygon",
        active: a.id === selectedPolygonID,
        sortInfo: a.createdAt,
        annotationType: a.annotationType,
        isPrediction: a.isPrediction,
        probability: a.probability,
      },
    ]);
  });

  annotationData?.polygonAnnotations.forEach((a) => {
    displayList.set(a.markupLabelId, [
      ...(displayList.get(a.markupLabelId) ?? []),
      {
        id: a.id,
        labelColor: a.markupLabelColor,
        points: a.points,
        previewAs: "polygon",
        active: a.id === selectedPolygonID,
        sortInfo: a.createdAt,
        annotationType: a.annotationType,
        isPrediction: a.isPrediction,
        probability: a.probability,
      },
    ]);
  });
  annotationData?.rectangleAnnotations.forEach((a) => {
    displayList.set(a.markupLabelId, [
      ...(displayList.get(a.markupLabelId) ?? []),
      {
        id: a.id,
        labelColor: a.markupLabelColor,
        points: rectangleToPoly(a),
        previewAs: "polygon",
        active: a.id === selectedPolygonID,
        sortInfo: a.createdAt,
        annotationType: a.annotationType,
        isPrediction: a.isPrediction,
        probability: a.probability,
      },
    ]);
  });
  annotationData?.boundingBoxAnnotations.forEach((a) => {
    displayList.set(a.markupLabelId, [
      ...(displayList.get(a.markupLabelId) ?? []),
      {
        id: a.id,
        labelColor: a.markupLabelColor,
        points: rectangleToPoly(a),
        previewAs: "polygon",
        active: a.id === selectedPolygonID,
        sortInfo: a.createdAt,
        annotationType: a.annotationType,
        isPrediction: a.isPrediction,
        probability: a.probability,
      },
    ]);
  });
  annotationData?.sausageAnnotations.forEach((a) => {
    displayList.set(a.markupLabelId, [
      ...(displayList.get(a.markupLabelId) ?? []),
      {
        id: a.id,
        labelColor: a.markupLabelColor,
        points: sausageToPoly(a),
        previewAs: "polygon",
        active: a.id === selectedPolygonID,
        sortInfo: a.createdAt,
        annotationType: a.annotationType,
        isPrediction: a.isPrediction,
        probability: a.probability,
      },
    ]);
  });
  annotationData?.pixelAnnotations.forEach((a) => {
    let dataURL = "";

    if (a.thumbnailAnnotation) {
      dataURL = a.thumbnailAnnotation.dataURL;
    } else {
      dataURL = a.dataURL;
    }
    displayList.set(a.markupLabelId, [
      ...(displayList.get(a.markupLabelId) ?? []),
      {
        id: a.id,
        labelColor: a.markupLabelColor,
        points: [],
        dataURL,
        previewAs: "image",
        active: a.id === selectedPolygonID,
        sortInfo: a.createdAt,
        annotationType: a.annotationType,
        isPrediction: a.isPrediction,
        probability: a.probability,
      },
    ]);
  });

  // sort the lists
  Array.from(displayList.keys()).forEach((markupLabelId) => {
    displayList.set(
      markupLabelId,
      sort((a: DisplayItem, b: DisplayItem) => {
        if (a.sortInfo > b.sortInfo) {
          return 1;
        }

        if (a.sortInfo < b.sortInfo) {
          return -1;
        }
        return 0;
      }, displayList.get(markupLabelId) ?? [])
    );
  });

  return displayList;
};
