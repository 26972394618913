import { useSelector } from "react-redux";
import { Form, Label } from "semantic-ui-react";
import { canMutateMarkup } from "../../../../../state/loginState";
import { VectorMarkup, VectorMarkupType } from "./types";

const getShortcutInfo = (index: number) => {
  const indexPlusOne = index + 1;
  if (indexPlusOne <= 10) {
    return `${indexPlusOne % 10}`;
  } else if (indexPlusOne <= 20) {
    return `Shift-${indexPlusOne % 10}`;
  }
  return "";
};

type MyLabelProps = {
  mode: VectorMarkupType;
  probability: number;
};

function MyLabel({ mode, probability }: MyLabelProps) {
  switch (mode) {
    case "predicted":
      return (
        <Label
          content={`${probability?.toFixed()}%`}
          icon="microchip"
          color="pink"
          size="small"
          style={{ width: 70 }}
        />
      );
    case "userAnnotated":
      return (
        <Label
          content="USER"
          icon="user"
          size="small"
          color="blue"
          style={{ width: 70 }}
        />
      );
    case "unmarked":
      return (
        <Label content="NONE" icon="minus" size="small" style={{ width: 70 }} />
      );
    default:
      return null;
  }
}

type VectorLabelProps = {
  vector: VectorMarkup;
  toggle: (id: string) => void;
  shortcutInfo: string;
};

function VectorLabel({ toggle, vector, shortcutInfo }: VectorLabelProps) {
  const { id, mode, active, name, probability } = vector;
  const isMarkupMutable = useSelector(canMutateMarkup);
  return (
    <Form.Field key={id}>
      <Form.Group>
        <MyLabel mode={mode} probability={probability ?? 0} />
        <Form.Checkbox
          toggle
          checked={active}
          label={name}
          disabled={!isMarkupMutable}
          onClick={() => toggle(id)}
          className={mode}
        />
        {shortcutInfo !== "" && <kbd>{shortcutInfo}</kbd>}
      </Form.Group>
    </Form.Field>
  );
}

type Props = {
  labelList: VectorMarkup[];
  toggle: (labelId: string) => void;
};
export default function LabelList({ labelList, toggle }: Props) {
  return (
    <>
      {labelList.map((l, idx) => (
        <VectorLabel
          key={l.id}
          toggle={toggle}
          vector={l}
          shortcutInfo={getShortcutInfo(idx)}
        />
      ))}
    </>
  );
}
