/* eslint-disable react/jsx-curly-newline */
import { Group } from "react-konva";
import { ToolType } from "MarkupTypes";
import * as AnnotationTypes from "./AnnotationTypes";
import { AnnotationType } from "../../../../ts-clients/command";
import { CurrentDataType } from "../hooks/types";

type Props = {
  currentData: CurrentDataType;
  currentAnnotationType: AnnotationType;
  showCurrentAnnotation: boolean;
  selectedSegmentation2DTool: ToolType;
  currentColor: string;
};

export default function CurrentDataIndex({
  currentData,
  showCurrentAnnotation,
  currentAnnotationType,
  selectedSegmentation2DTool,
  currentColor,
}: Props) {
  const dummy = {
    created: 0,
    id: "",
  };

  return (
    <Group>
      {showCurrentAnnotation && selectedSegmentation2DTool === "MagicWand" && (
        <AnnotationTypes.MagicWand
          // @ts-ignore
          c={{
            ...dummy,
            ...currentData.magicwand,
            annotationType: currentAnnotationType,
            markupLabelId: "moot",
            markupLabelColor: currentColor,
          }}
          update={() => {}}
          temporary
          scale={1}
          labelColor={currentColor}
        />
      )}
      {/*
        {showCurrentAnnotation && selectedSegmentation2DTool === 'Circle' && (
          <AnnotationTypes.Circle
            c={{
              ...currentData.circle,
              annotationType: currentAnnotationType,
            }}
            temporary
          />
        )} */}
      {/* {showCurrentAnnotation && selectedSegmentation2DTool === 'Rectangle' && (
        <AnnotationTypes.Rectangle
          c={{
            ...currentData.reactangle,
            annotationType: currentAnnotationType,
            markupLabelId: 'moot',
          }}
          temporary
        />
      )} */}
      {showCurrentAnnotation && selectedSegmentation2DTool === "Polygon" && (
        <AnnotationTypes.Polygon
          // @ts-ignore
          c={{
            ...dummy,
            ...currentData.polygon,
            annotationType: currentAnnotationType,
            markupLabelId: "moot",
            markupLabelColor: currentColor,
          }}
          update={() => {}}
          temporary
          scale={1}
          labelColor={currentColor}
        />
      )}
      {showCurrentAnnotation && selectedSegmentation2DTool === "Sausage" && (
        <AnnotationTypes.Sausage
          // @ts-ignore
          c={{
            ...dummy,
            ...currentData.sausage,
            annotationType: currentAnnotationType,
            markupLabelId: "moot",
            markupLabelColor: currentColor,
          }}
          update={() => {}}
          temporary
          scale={1}
          labelColor={currentColor}
        />
      )}
      {showCurrentAnnotation && selectedSegmentation2DTool === "FreeForm" && (
        <AnnotationTypes.FreeForm
          // @ts-ignore
          c={{
            ...dummy,
            ...currentData.polygon,
            annotationType: currentAnnotationType,
            markupLabelId: "moot",
            markupLabelColor: currentColor,
          }}
          temporary
          scale={1}
          labelColor={currentColor}
        />
      )}
    </Group>
  );
}
