import { useEffect, useState } from "react";
import { ImageSize } from "MarkupTypes";
import { Image } from "react-konva";
import uglyStore from "../../../../state/uglyStore";

type Props = {
  image: HTMLImageElement | undefined;
  size: ImageSize;
};

export default function URLImageIndex({ size, image }: Props) {
  // trick to reduce flicker
  const [currentImage, setCurentImage] = useState<
    HTMLImageElement | undefined
  >();

  // extract Image Data as Byte Array
  useEffect(() => {
    if (image === undefined) {
      return;
    }
    setCurentImage(image);

    const c = document.createElement("canvas");
    image.setAttribute("crossOrigin", "anonymous");
    const ctx = c.getContext("2d");
    c.width = image.width || image.naturalWidth;
    c.height = image.height || image.naturalHeight;
    if (ctx) {
      ctx.drawImage(image, 0, 0, c.width, c.height);
      const imageData = ctx.getImageData(0, 0, c.width, c.height);
      uglyStore.imageData.width = imageData.width;
      uglyStore.imageData.height = imageData.height;
      uglyStore.imageData.data = imageData.data;
      uglyStore.sourceImageSize = size;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <Image
      x={0}
      y={0}
      image={currentImage}
      width={size.width}
      height={size.height}
    />
  );
}
