import { Header, Button, Icon, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getPath } from "../../../../utils/routeManager";

type Props = {
  datasetId: string;
  userCanMutateNetworks: boolean;
  menuType: string;
};

export default function NoNetworksPlaceholder({
  menuType,
  userCanMutateNetworks,
  datasetId,
}: Props) {
  return (
    <Segment placeholder>
      <Header icon>
        <Icon name="paperclip" />
        No networks were created yet.
      </Header>
      <Button
        disabled={!userCanMutateNetworks}
        as={Link}
        to={getPath("NetworkAdd", { datasetId, menuType })}
        primary
      >
        Add Network
      </Button>
    </Segment>
  );
}
