/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import { useState } from "react";
import {
  Menu,
  Popup,
  List,
  Divider,
  Container,
  Message,
  Icon,
  Button,
} from "semantic-ui-react";
import styles from "../Menu.css";
import { Notification } from "../../../ts-clients/query";

import {
  useMarkAllAsRead,
  useNotifications,
  useDeleteNotifications,
} from "./hooks";
import NotificationEntry from "./NotificationEntry";

export default function Notifactions() {
  const notifications = useNotifications();
  const unreadNotificationAmount = notifications.filter((n) => !n?.read).length;
  const markAsRead = useMarkAllAsRead(notifications.map((d) => d?.id ?? ""));
  const deleteAll = useDeleteNotifications(
    notifications.map((d) => d?.id ?? "")
  );
  const [muted, setMuted] = useState(false);
  return (
    <Popup
      hoverable
      position="bottom right"
      trigger={
        <Menu.Item
          icon
          name="notifications"
          className={styles.mainMenuButton}
          onClick={() => setMuted(!muted)}
        >
          <div className="ui top dropdown">
            <Icon
              name={muted ? "bell slash" : "bell"}
              onClick={() => setMuted(!muted)}
            />
            {unreadNotificationAmount > 0 && (
              <div
                id="live_message_badge_main_header"
                className="floating ui blue label"
                style={{ padding: "2px 3px", top: "-10px", left: "32px" }}
              >
                <span className="live_message_badge">
                  {unreadNotificationAmount}
                </span>
              </div>
            )}
          </div>
        </Menu.Item>
      }
    >
      <Container
        style={{ width: "400px", overflow: "auto", maxHeight: "400px" }}
      >
        <Button
          basic
          size="mini"
          floated="left"
          color="red"
          onClick={deleteAll}
          content="dismiss all"
        />
        <Button
          basic
          size="mini"
          floated="right"
          color="blue"
          onClick={markAsRead}
          content="mark all as read"
        />
        <br />
        <Divider />
        <List>
          {notifications.map((n) => (
            <NotificationEntry
              key={n?.id}
              notification={n as unknown as Notification}
              notificationType={n?.__typename || "ProgressNotification"}
            />
          ))}
        </List>
        {notifications.length === 0 && (
          <Message icon positive>
            {" "}
            <Icon name="check" />{" "}
            <Message.Content>
              <Message.Header>All set!</Message.Header>
              no new notifications available.
            </Message.Content>
          </Message>
        )}
      </Container>
    </Popup>
  );
}
