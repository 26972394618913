import Konva from "konva";
import { useEffect, useMemo } from "react";
import { CursorType, ImageSize } from "MarkupTypes";
import { useSelector } from "react-redux";
import {
  getSelectedSegmentation2DTool,
  getAnnotationType,
  getSelectedPolygonId,
  isCursorHidden,
} from "../../../details-researchbox/Markup/inputImage/redux-state";
import { AnnotationFlavour, LeanInteractionState } from "./types";
import useStageState from "./useStageState";
import useKeyboard from "../../../../hooks/useKeyboard";
import useUIConfig from "../../../../state/useUIConfig";
import useMouseMode from "./useMouseMode";
import { UpdateStateType } from "./useInteractions";
import useZoom from "./useZoom";
import { KonvaEventObject } from "konva/lib/Node";

export default function useStage(
  stage: Konva.Stage | null,
  paintActive: boolean,
  updateState: UpdateStateType,
  currentDataChangedByUser: boolean,
  selectedAnnotationFlavour: AnnotationFlavour | null,
  imageSize: ImageSize
) {
  const selectedAnnotationId = useSelector(getSelectedPolygonId);
  const selectedSegmentation2DTool = useSelector(getSelectedSegmentation2DTool);
  const annotationType = useSelector(getAnnotationType);
  const cursorHidden = useSelector(isCursorHidden);

  const { getMarkupToolResetZoomOnDoubleClick } = useUIConfig();

  const {
    canvasSize,
    setOffset,
    setMouseDown,
    resizeCanvas,
    mouseOnCanvas,
    mouseDown,
    setMouseOnCanvas,
  } = useStageState();

  const { getMarkupToolOneClickShapes } = useUIConfig();
  const mouseMode = useMouseMode();

  const { resetZoom, scaleStage, scale } = useZoom(
    stage,
    canvasSize,
    imageSize
  );

  useEffect(() => {
    if (stage === null) {
      return;
    }
    stage.setSize(canvasSize);
  }, [canvasSize, stage]);

  const onMouseLeave = () => {
    setMouseOnCanvas(false);
    setMouseDown(false);
  };

  const onMouseEnter = () => {
    setMouseOnCanvas(true);
  };

  const environment: LeanInteractionState = {
    mouseMode,
    mouseOnCanvas,
    oneClickShapes: getMarkupToolOneClickShapes(),
    selectedSegmentation2DTool,
    paintActive,
    currentDataChangedByUser,
    scrollDirection: null,
    selectedAnnotationId,
    selectedAnnotationFlavour,
  };

  useKeyboard({
    onEnter: () => updateState("enter", environment),
    onEscape: () => updateState("escape", environment),
    onDelete: () => updateState("delete", environment),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onClick = (_e: KonvaEventObject<MouseEvent>) =>
    updateState("click", environment);

  const onWheel = (e: KonvaEventObject<WheelEvent>) => {
    e.evt.preventDefault();
    const { deltaY } = e.evt;
    updateState("wheel", {
      ...environment,
      scrollDirection: deltaY < 0 ? "up" : "down",
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDragMove = (_e: KonvaEventObject<DragEvent>) => {
    if (stage === null) {
      return;
    }
    setOffset({ x: stage.x(), y: stage.y() });
  };

  const onMouseDown = () => {
    setMouseDown(true);
    updateState("mouseDown", environment);
  };

  const onMouseUp = () => {
    setMouseDown(false);
    updateState("mouseUp", environment);
  };

  const onDoubleClick = () => {
    if (selectedSegmentation2DTool === "None") {
      if (getMarkupToolResetZoomOnDoubleClick() === true) {
        resetZoom();
      }
    }
  };

  const zoomIn = () => scaleStage(1.1);
  const zoomOut = () => scaleStage(1 / 1.1);

  const stageEvents = {
    onClick,
    onDoubleClick,
    onDragMove,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseUp,
    onWheel,
  };

  const cursorType = useMemo((): CursorType => {
    if (cursorHidden) {
      return "none";
    }
    if (selectedSegmentation2DTool === "None") {
      if (mouseDown) {
        return "grabbing";
      }
      return "grab";
    }
    if (selectedSegmentation2DTool === "Circle") {
      return "none";
    }

    if (mouseMode === "ctrl") {
      return "zoom-in";
    }

    return "crosshair";
  }, [mouseDown, mouseMode, selectedSegmentation2DTool, cursorHidden]);

  const stageActions = { zoomIn, zoomOut, resetZoom };

  return {
    annotationType,
    scale,
    canvasSize,
    mouseDown,
    mouseMode,
    mouseOnCanvas,
    resizeCanvas,
    selectedSegmentation2DTool,
    stageEvents,
    stageActions,
    cursorType,
  };
}
