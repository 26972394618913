import DatasetList from "../../features/dashboard";
import Center from "../../features/filemanagement/DefaultPositioner";
import MenuMain from "../../components/menus/menu.main";

export default function DatasetsContainer() {
  return (
    <>
      <MenuMain />
      <Center>
        <DatasetList />
      </Center>
    </>
  );
}
