/* eslint-disable prefer-destructuring */
import { useCallback, useState } from "react";
import { KonvaEventObject } from "konva/lib/Node";
import { Image as KonvaImage } from "react-konva";
// import { Vector2d } from "MarkupTypes";
import { MyPixels } from "../../hooks/types";
import { getPolygonConfig } from "./lowlevel/util";
// import DragDot from "./lowlevel/DragDot";

import { useGetPixelImageHook } from "../hooks/useGetPixelImage";

type Props = {
  c: MyPixels;
  selectedMarkupLabelId?: string | null;
  labelColor: string;
  select: (id: string | null) => void;
  isSelected?: boolean;
  isHighlighted: boolean;
  scale: number;
};

export default function Pixel({
  c,
  selectedMarkupLabelId,
  labelColor,
  select,
  isSelected = false,
  isHighlighted,
  scale,
}: Props) {
  const [isDragging, setDragging] = useState(false);
  // const [width, setWidth] = useState(c.bottomRight.x - c.topLeft.x);
  // const [height, setHeight] = useState(c.bottomRight.y - c.topLeft.y);

  const width = c.bottomRight.x - c.topLeft.x;
  const height = c.bottomRight.y - c.topLeft.y;

  const style = getPolygonConfig(
    c.annotationType,
    "Pixel",
    false,
    isSelected,
    isHighlighted,
    false,
    selectedMarkupLabelId === c.markupLabelId,
    labelColor,
    scale,
    c.isPrediction
  );

  const { image: resultImage, renderContext } = useGetPixelImageHook({
    annotationType: c.annotationType,
    width,
    height,
    isPrediction: c.isPrediction || false,
    color: style.fillColor,
    dataURL: c.dataURL,
  });

  const clickHandler = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (!renderContext) {
        return false;
      }
      const stage = e.target.getStage();
      if (stage === null) {
        return false;
      }

      const cursor = stage.getPointerPosition();
      if (cursor === null) {
        return false;
      }

      const transformation = stage.getTransform();
      const relativeCursor = {
        x: (cursor.x - transformation.m[4]) / transformation.m[0],
        y: (cursor.y - transformation.m[5]) / transformation.m[3],
      };
      const offset = {
        x: e.target.attrs.x,
        y: e.target.attrs.y,
      };
      const position = {
        x: relativeCursor.x - offset.x,
        y: relativeCursor.y - offset.y,
      };

      const pixel = renderContext
        .getImageData(position.x, position.y, 1, 1)
        .data.slice(0, 3)
        .reduce((prev, cur) => prev + cur, 0);
      return pixel > 0;
    },
    [renderContext]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const highlightHandler = useCallback((_e: KonvaEventObject<MouseEvent>) => {
    // deaktiviert wegen Performance-Bedenken
    // const activeArea = clickHandler(e);
    // setHighlighted(activeArea);
  }, []);

  const selectHandler = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const activeArea = clickHandler(e);
      if (activeArea) {
        select(c.id);
      } else {
        select(null);
      }
      // e.cancelBubble = true;
    },
    [c.id, clickHandler, select]
  );

  // const [selectionPoints, setSelectionPoints] = useState([
  //   { x: c.topLeft.x, y: c.topLeft.y },
  //   {
  //     x: c.bottomRight.x,
  //     y: c.bottomRight.y,
  //   },
  //   { x: c.topLeft.x, y: c.bottomRight.y },
  //   { x: c.bottomRight.x, y: c.topLeft.y },
  // ]);

  // const updateSelectionPoints = () => {
  //   setSelectionPoints([
  //     { x: c.topLeft.x, y: c.topLeft.y },
  //     {
  //       x: c.bottomRight.x,
  //       y: c.bottomRight.y,
  //     },
  //     { x: c.topLeft.x, y: c.bottomRight.y },
  //     { x: c.bottomRight.x, y: c.topLeft.y },
  //   ]);
  // };

  const dragHandler = useCallback(
    (e: KonvaEventObject<DragEvent>) => {
      c.bottomRight.x += e.target.x() - c.topLeft.x;
      c.bottomRight.y += e.target.y() - c.topLeft.y;
      c.topLeft.x = e.target.x();
      c.topLeft.y = e.target.y();
      // updateSelectionPoints();
      setDragging(false);
    },
    [c.bottomRight, c.topLeft]
  );

  // const dragPointHandler = (idx: number, p: Vector2d) => {
  //   if (idx === 0) {
  //     c.topLeft.x = p.x;
  //     c.topLeft.y = p.y;
  //   } else if (idx === 1) {
  //     c.bottomRight.x = p.x;
  //     c.bottomRight.y = p.y;
  //   } else if (idx === 2) {
  //     c.topLeft.x = p.x;
  //     c.bottomRight.y = p.y;
  //   } else if (idx === 3) {
  //     c.bottomRight.x = p.x;
  //     c.topLeft.y = p.y;
  //   }
  //   updateSelectionPoints();
  // };

  // const dragPointEnd = () => {
  //   setWidth(c.bottomRight.x - c.topLeft.x);
  //   setHeight(c.bottomRight.y - c.topLeft.y);
  // };

  return (
    <>
      <KonvaImage
        dash={isSelected && !isDragging ? style.dash : undefined}
        stroke={isSelected && !isDragging ? style.strokeColor : undefined}
        strokeWidth={isSelected && !isDragging ? style.strokeWidth : undefined}
        image={resultImage}
        x={c.topLeft.x}
        y={c.topLeft.y}
        width={c.bottomRight.x - c.topLeft.x}
        height={c.bottomRight.y - c.topLeft.y}
        opacity={style.opacity}
        onClick={selectHandler}
        draggable={isSelected}
        onMouseMove={highlightHandler}
        // onMouseLeave={() => setHighlighted(false)}
        onDragEnd={dragHandler}
        onDragStart={() => setDragging(true)}
      />
    </>
  );
}

Pixel.defaultProps = {
  isSelected: false,
  selectedMarkupLabelId: null,
};
