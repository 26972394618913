import { Group } from "react-konva";
import { useMemo } from "react";
import { useDeepCompareMemoize } from "use-deep-compare-effect";

import { Annotations2Type } from "../hooks/useAnnotations2";
import * as AnnotationTypes from "./AnnotationTypes";
import useAnnotationsEditable from "./hooks/useAnnotationsEditable";
import { HighlightedIds } from "../hooks/useHighlighing";

// smaller base -> smoother values, but more re-renders
function RoundFirstSignificantDown(value: number, base: number) {
  return base ** Math.floor(Math.log(value) / Math.log(base));
}

type Props = {
  annotations: Annotations2Type["annotations"];
  setActivePolygonId: (id: string | null) => void;
  paintActiveAnnotationId: string | null;
  selectedPolygonId: string | null;
  annotationActions: Annotations2Type["annotationActions"];
  scale: number;
  highlightedIds: HighlightedIds;
};

export default function SegmentationDataIndex({
  annotations,
  setActivePolygonId,
  paintActiveAnnotationId,
  selectedPolygonId,
  annotationActions,
  scale,
  highlightedIds,
}: Props) {
  const {
    setSelectedPixel,
    updateCircle,
    updateMagicWand,
    updatePolygon,
    updateRectangle,
    updateSausage,
    updateBoundingBox,
  } = useAnnotationsEditable({
    paintActiveAnnotationId,
    setActivePolygonId,
    selectedPolygonId,
    annotationActions,
    scale,
  });

  const memorizedPixelAnnotations = useDeepCompareMemoize(
    annotations.annotationData.pixelAnnotations
  );

  const sortedPixels = useMemo(
    () =>
      memorizedPixelAnnotations
        .filter((l) => l.id !== paintActiveAnnotationId)
        .sort(
          (a, b) =>
            +(a.id === selectedPolygonId) - +(b.id === selectedPolygonId)
        ),
    [memorizedPixelAnnotations, paintActiveAnnotationId, selectedPolygonId]
  );

  const memorizedPolygonAnnotations = useDeepCompareMemoize(
    annotations.annotationData.polygonAnnotations
  );

  const sortedPolygons = useMemo(
    () =>
      memorizedPolygonAnnotations
        .filter((l) => l.id !== paintActiveAnnotationId)
        .sort(
          (a, b) =>
            +(a.id === selectedPolygonId) - +(b.id === selectedPolygonId)
        ),
    [memorizedPolygonAnnotations, paintActiveAnnotationId, selectedPolygonId]
  );

  const memorizedCircleAnnotations = useDeepCompareMemoize(
    annotations.annotationData.circleAnnotations
  );

  const sortedCircles = useMemo(
    () =>
      memorizedCircleAnnotations
        .filter((l) => l.id !== paintActiveAnnotationId)
        .sort(
          (a, b) =>
            +(a.id === selectedPolygonId) - +(b.id === selectedPolygonId)
        ),
    [memorizedCircleAnnotations, paintActiveAnnotationId, selectedPolygonId]
  );

  const memorizedRectangleAnnotations = useDeepCompareMemoize(
    annotations.annotationData.rectangleAnnotations
  );

  const sortedRectangles = useMemo(
    () =>
      memorizedRectangleAnnotations
        .filter((l) => l.id !== paintActiveAnnotationId)
        .sort(
          (a, b) =>
            +(a.id === selectedPolygonId) - +(b.id === selectedPolygonId)
        ),
    [memorizedRectangleAnnotations, paintActiveAnnotationId, selectedPolygonId]
  );

  const memorizedSausageAnnotations = useDeepCompareMemoize(
    annotations.annotationData.sausageAnnotations
  );

  const sortedSausages = useMemo(
    () =>
      memorizedSausageAnnotations
        .filter((l) => l.id !== paintActiveAnnotationId)
        .sort(
          (a, b) =>
            +(a.id === selectedPolygonId) - +(b.id === selectedPolygonId)
        ),
    [memorizedSausageAnnotations, paintActiveAnnotationId, selectedPolygonId]
  );

  const memorizedMagicWandAnnotations = useDeepCompareMemoize(
    annotations.annotationData.magicwandAnnotations
  );

  const sortedMagicWands = useMemo(
    () =>
      memorizedMagicWandAnnotations
        .filter((l) => l.id !== paintActiveAnnotationId)
        .sort(
          (a, b) =>
            +(a.id === selectedPolygonId) - +(b.id === selectedPolygonId)
        ),
    [memorizedMagicWandAnnotations, paintActiveAnnotationId, selectedPolygonId]
  );

  const memorizedBoundingBoxAnnotations = useDeepCompareMemoize(
    annotations.annotationData.boundingBoxAnnotations
  );

  const sortedBoundingBoxes = useMemo(
    () =>
      memorizedBoundingBoxAnnotations
        .filter((l) => l.id !== paintActiveAnnotationId)
        .sort(
          (a, b) =>
            +(a.id === selectedPolygonId) - +(b.id === selectedPolygonId)
        ),
    [
      memorizedBoundingBoxAnnotations,
      paintActiveAnnotationId,
      selectedPolygonId,
    ]
  );

  const newScale = RoundFirstSignificantDown(scale, 3);

  return (
    <Group>
      {sortedPixels.map((c) => (
        <AnnotationTypes.Pixel
          key={c.id}
          c={c}
          selectedMarkupLabelId={annotations.markupLabelId}
          labelColor={c.markupLabelColor}
          select={setSelectedPixel}
          isSelected={selectedPolygonId === c.id}
          scale={newScale}
          isHighlighted={highlightedIds.includes(c.id)}
        />
      ))}
      {sortedCircles.map((c) => (
        <AnnotationTypes.Circle
          key={c.id}
          c={c}
          selectedMarkupLabelId={annotations.markupLabelId}
          labelColor={c.markupLabelColor}
          isSelected={selectedPolygonId === c.id}
          select={setSelectedPixel}
          update={updateCircle}
          scale={newScale}
          isHighlighted={highlightedIds.includes(c.id)}
        />
      ))}
      {sortedPolygons.map((c) => (
        <AnnotationTypes.Polygon
          key={c.id}
          c={c}
          selectedMarkupLabelId={annotations.markupLabelId}
          labelColor={c.markupLabelColor}
          isSelected={selectedPolygonId === c.id}
          select={setSelectedPixel}
          update={updatePolygon}
          scale={scale}
          isHighlighted={highlightedIds.includes(c.id)}
        />
      ))}
      {sortedMagicWands.map((c) => (
        <AnnotationTypes.MagicWand
          key={c.id}
          c={c}
          selectedMarkupLabelId={annotations.markupLabelId}
          labelColor={c.markupLabelColor}
          isSelected={selectedPolygonId === c.id}
          select={setSelectedPixel}
          update={updateMagicWand}
          scale={newScale}
          isHighlighted={highlightedIds.includes(c.id)}
        />
      ))}
      {sortedRectangles.map((c) => (
        <AnnotationTypes.Rectangle
          key={c.id}
          c={c}
          selectedMarkupLabelId={annotations.markupLabelId}
          labelColor={c.markupLabelColor}
          isSelected={selectedPolygonId === c.id}
          select={setSelectedPixel}
          update={updateRectangle}
          scale={newScale}
          isHighlighted={highlightedIds.includes(c.id)}
        />
      ))}
      {sortedSausages.map((c) => (
        <AnnotationTypes.Sausage
          key={c.id}
          c={c}
          selectedMarkupLabelId={annotations.markupLabelId}
          labelColor={c.markupLabelColor}
          isSelected={selectedPolygonId === c.id}
          select={setSelectedPixel}
          update={updateSausage}
          scale={newScale}
          isHighlighted={highlightedIds.includes(c.id)}
        />
      ))}
      {sortedBoundingBoxes.map((c) => (
        <AnnotationTypes.BoundingBox
          key={c.id}
          c={c}
          selectedMarkupLabelId={annotations.markupLabelId}
          labelColor={c.markupLabelColor}
          isSelected={selectedPolygonId === c.id}
          select={setSelectedPixel}
          update={updateBoundingBox}
          scale={newScale}
          isHighlighted={highlightedIds.includes(c.id)}
        />
      ))}
    </Group>
  );
}
