import { useEffect, useState } from "react";
import { Vector2d } from "MarkupTypes";
import { circleToPoly } from "../../hooks/annotationHelper";
import { MyCircle } from "../../hooks/types";
import Polygon from "./lowlevel/NewPoly";
import DragDot from "./lowlevel/DragDot";
import { addVector, getDistance, getPolygonConfig } from "./lowlevel/util";

type Props = {
  c: MyCircle;
  selectedMarkupLabelId?: string | null;
  labelColor: string;
  temporary?: boolean;
  select?: (id: string) => void;
  isSelected?: boolean;
  isHighlighted: boolean;
  update: (c: MyCircle) => void;
  scale: number;
};

export default function Circle({
  c,
  selectedMarkupLabelId = null,
  labelColor,
  temporary = false,
  select,
  isSelected = false,
  update,
  scale,
  isHighlighted,
}: Props) {
  const [radius, setRadius] = useState(0);

  useEffect(() => {
    setRadius(c.radius);
  }, [c.radius]);

  const [isDragging, setDragging] = useState(false);

  const circleWithCustomRadius = { ...c, radius };
  const points = circleToPoly(circleWithCustomRadius);
  const getConfig = (isMouseOver: boolean) =>
    getPolygonConfig(
      c.annotationType,
      "Circle",
      isMouseOver,
      isSelected,
      isHighlighted,
      temporary,
      selectedMarkupLabelId === c.markupLabelId,
      labelColor,
      scale
    );

  const movePolygon = (dist: Vector2d) =>
    update({ ...c, center: addVector(c.center, dist) });

  // TODO: radius und getDistance passen nicht zusamemn; der Punkt liegt nicht immer auf dem Rand des Kreises
  const changeRadiusTemporary = ({ x, y }: Vector2d) => {
    setRadius(getDistance(c.center.x, c.center.y, x, y));
  };

  const radiusPosition: Vector2d = {
    x: c.center.x + c.radius,
    y: c.center.y + c.radius * 0.000001, // the position is only updated correctly if it changes
  };

  const updateRadius = () => update(circleWithCustomRadius);

  const selectHandler = () => {
    if (select) {
      select(c.id);
    }
  };

  return (
    <>
      <Polygon
        points={points}
        getConfig={getConfig}
        select={selectHandler}
        isSelected={isSelected}
        movePolygon={movePolygon}
        setDragging={setDragging}
      />
      {isSelected && !isDragging && (
        <DragDot
          scale={scale}
          dragEnd={updateRadius}
          dragTo={changeRadiusTemporary}
          position={radiusPosition}
        />
      )}
    </>
  );
}

Circle.defaultProps = {
  temporary: false,
  isSelected: false,
  selectedMarkupLabelId: null,
  select: () => {},
};
