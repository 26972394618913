import { Vector2d } from "MarkupTypes";
import { AnnotationType } from "../../../../../../ts-clients/command";
import { hexToRgbaString, rgbToHex } from "../../../../../../utils/pixeltools";
import { AnnotationFlavour } from "../../../hooks/types";

export type GlobalCompositeOperationType =
  | ""
  | "source-over"
  | "source-in"
  | "source-out"
  | "source-atop"
  | "destination-over"
  | "destination-in"
  | "destination-out"
  | "destination-atop"
  | "lighter"
  | "copy"
  | "xor"
  | "multiply"
  | "screen"
  | "overlay"
  | "darken"
  | "lighten"
  | "color-dodge"
  | "color-burn"
  | "hard-light"
  | "soft-light"
  | "difference"
  | "exclusion"
  | "hue"
  | "saturation"
  | "color"
  | "luminosity";

export type Color = {
  red: number;
  green: number;
  blue: number;
};

export type PolygonConfig = {
  type: AnnotationType;
  fillColor: string;
  composition: GlobalCompositeOperationType;
  strokeColor: string;
  opacity: number;
  strokeWidth: number;
  dash: number[] | undefined;
  isPrediction: boolean;
};

const getOpacity = (
  selected: boolean,
  temporary: boolean,
  activeLayer: boolean,
  flavour: AnnotationFlavour
) => {
  if (flavour === "BoundingBox") {
    return 1;
  }
  if (!activeLayer) {
    return 0.3;
  }
  if (selected) {
    return 0.9;
  }
  if (temporary) {
    return 0.7;
  }
  return 0.6;
};

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let r = 0;
  let g = 0;
  let b = 0;
  if (result) {
    r = parseInt(result[1], 16);
    g = parseInt(result[2], 16);
    b = parseInt(result[3], 16);
  }
  return { r, g, b };
};

const getFillColor = (
  selected: boolean,
  highlighted: boolean,
  mouseOver: boolean,
  activeLayer: boolean,
  labelColor: string,
  isPrediction?: boolean
) => {
  let r = 0;
  let g = 0;
  let b = 0;

  const { r: rRet, g: gRet, b: bRet } = hexToRgb(labelColor);
  r = rRet;
  g = gRet;
  b = bRet;

  if (isPrediction) {
    r = 113;
    g = 48;
    b = 115;
  }

  if (activeLayer) {
    if (highlighted) {
      r = 255;
      g = 179;
      b = 0;
    }
    if (selected) {
      r *= 1.25;
      g *= 1.25;
      b *= 1.25;
    }
    if (mouseOver && !selected) {
      r *= 1.15;
      g *= 1.15;
      b *= 1.15;
    }
  }

  r = Math.min(255, Math.ceil(r));
  g = Math.min(255, Math.ceil(g));
  b = Math.min(255, Math.ceil(b));

  return rgbToHex(r, g, b);
};

const getComposition = (): GlobalCompositeOperationType => "";

const getStrokeColor = (flavour: AnnotationFlavour, labelColor: string) => {
  if (flavour === "BoundingBox") {
    return hexToRgbaString(labelColor, 0.6);
  }
  return "rgba(0,0,0,.6)";
};

export const getStrokeWidth = (scale: number, flavour: AnnotationFlavour) => {
  if (flavour === "BoundingBox") {
    return 2 / scale ** 0.85;
  }
  return 1 / scale ** 0.5;
};

export const getDash = (
  flavour: AnnotationFlavour,
  scale: number
): [number, number] | undefined => {
  if (flavour === "BoundingBox") {
    return undefined;
  }
  return [3 / scale ** 0.9, 2 / scale ** 0.9];
};

export const getPolygonConfig = (
  type: AnnotationType,
  flavour: AnnotationFlavour,
  mouseOver: boolean,
  selected: boolean,
  highlighted: boolean,
  temporary: boolean,
  activeLayer: boolean,
  labelColor: string,
  scale: number,
  isPrediction?: boolean,
  transparent?: boolean
): PolygonConfig => {
  return {
    fillColor:
      transparent || (flavour === "BoundingBox" && !highlighted)
        ? `${labelColor}10`
        : getFillColor(
            selected,
            highlighted,
            mouseOver,
            activeLayer,
            labelColor,
            isPrediction
          ),
    composition: getComposition(),
    strokeColor: getStrokeColor(flavour, labelColor),
    opacity: getOpacity(selected, temporary, activeLayer, flavour),
    strokeWidth: getStrokeWidth(scale, flavour),
    dash: getDash(flavour, scale),
    type,
    isPrediction: isPrediction || false,
  };
};

export const getDistance = (x1: number, y1: number, x2: number, y2: number) => {
  const y = x2 - x1;
  const x = y2 - y1;
  return Math.sqrt(x * x + y * y);
};

export const addVector = (v1: Vector2d, v2: Vector2d): Vector2d => ({
  x: v1.x + v2.x,
  y: v1.y + v2.y,
});
