import { useState } from "react";

export default function useDelay(delaytime: number) {
  const [delayActive, setDelayActive] = useState(false);
  const start = () => {
    setDelayActive(true);
    setTimeout(() => setDelayActive(false), delaytime);
  };
  return [delayActive, start] as const;
}
