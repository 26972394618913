/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import {
  getShowSegmentations,
  toggleShowSemgentations,
} from "../../../../state/researchboxfilter";
import { PartContext } from "./types";

type Props = {
  context: PartContext;
};

export default function MarkupSettings({ context }: Props) {
  const dispatch = useDispatch();
  const showSegmentations = useSelector(getShowSegmentations);

  if (context === "markuptool") {
    return null;
  }

  return (
    <Form.Field>
      <Form.Group grouped>
        {/* <Form.Checkbox
          label="Show Labels"
          checked={showLabels}
          onClick={() => dispatch(toggleShowLabels())}
        /> */}
        <Form.Checkbox
          label="Show Markups"
          checked={showSegmentations}
          onClick={() => dispatch(toggleShowSemgentations())}
        />
      </Form.Group>
    </Form.Field>
  );
}
