import { Icon } from "semantic-ui-react";
import { parseCCCheckerResultJson } from "./util";

type Props = {
  d: {
    groups: string;
    isOkay: boolean;
  };
};

type ResultStats = {
  errors: number;
  cells: number;
};

const okContent = (
  <span style={{ fontSize: 48 }}>
    <Icon name="check" size="big" />
    <br />
    <br />
    keine Fehler
  </span>
);

const notOkContent = (s: ResultStats) => (
  <div style={{ fontSize: 24 }}>
    <span style={{ fontSize: 48 }}>
      <Icon name="exclamation circle" />
      <br />
      <em>
        {s.errors} of {s.cells}
      </em>{" "}
      cells have errors
    </span>
  </div>
);

const getContent = (s: ResultStats) =>
  s.errors === 0 ? okContent : notOkContent(s);

export default function CcCheckerMetricPanel({ d }: Props) {
  const cells = parseCCCheckerResultJson(d.groups);
  const errorCount = cells.reduce(
    (c, item) => {
      return item.IsOkay
        ? { cells: c.cells + 1, errors: c.errors }
        : { cells: c.cells + 1, errors: c.errors + 1 };
    },
    { errors: 0, cells: 0 }
  );

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        lineHeight: 1.2,
        textAlign: "center",
        color: "white",
        backgroundColor: d.isOkay ? "rgb(131, 212, 137)" : "rgb(222, 126, 111)",
      }}
    >
      {getContent(errorCount)}
    </div>
  );
}
