/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { head, without } from "ramda";
import { setContext } from "@apollo/client/link/context";
import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { RootState, AppThunk } from "./store";

import globalStore from "./uglyStore";
import {
  // FileInfo,
  FlatFileInfo,
} from "../features/filemanagement/types/FileManagementTypes";
import {
  UploadFileDocument,
  UploadFileMutationResult,
  UploadFileMutationVariables,
} from "../ts-clients/command";
import flattenFileInfo from "../utils/upload";
import { getCurrentUser } from "./loginState";

type StateType = {
  imageLabel: string | null;
  failedQueue: FlatFileInfo[];
  successQueue: FlatFileInfo[];
  uploadProceessActive: boolean;
};

const name = "upload";
const initialState: StateType = {
  failedQueue: [],
  successQueue: [],
  uploadProceessActive: false,
  imageLabel: "image",
};

const appSlice = createSlice({
  name,
  initialState,
  reducers: {
    enableUploadProcess: (state) => ({
      ...state,
      uploadProceessActive: true,
    }),
    disableUploadProcess: (state) => ({
      ...state,
      uploadProceessActive: false,
    }),
    setImageLabel: (state, action: PayloadAction<string>) => ({
      ...state,
      imageLabel: action.payload,
    }),
    appendToFailedQueue: (state, action: PayloadAction<FlatFileInfo>) => ({
      ...state,
      failedQueue: [...state.failedQueue, action.payload],
    }),
    appendToSuccessQueue: (state, action: PayloadAction<FlatFileInfo>) => ({
      ...state,
      successQueue: [...state.successQueue, action.payload],
    }),
    clearSuccessQueue: (state) => ({
      ...state,
      successQueue: [],
    }),
  },
});

export const {
  appendToFailedQueue,
  appendToSuccessQueue,
  enableUploadProcess,
  disableUploadProcess,
  clearSuccessQueue,
  setImageLabel,
} = appSlice.actions;

export default appSlice.reducer;

export const getFailedQueueLength = (state: RootState) =>
  state.upload.failedQueue.length;
export const getSuccessQueueLength = (state: RootState) =>
  state.upload.successQueue.length;
export const isUploadProceessActive = (state: RootState) =>
  state.upload.uploadProceessActive;
export const getImageLabel = (state: RootState) => state.upload.imageLabel;

export const processUploadQueue = (): AppThunk => (dispatch, getState) => {
  const state = getState();

  if (!isUploadProceessActive(state)) {
    return;
  }
  const file = head(globalStore.uploadQueue);

  if (!file) {
    dispatch(disableUploadProcess());
  } else {
    const COMMAND_URL =
      process.env.REACT_APP_COMMAND_URL &&
      process.env.REACT_APP_COMMAND_URL !== ""
        ? process.env.REACT_APP_COMMAND_URL
        : "http://localhost:8085/command";

    const getAuthToken = () => getCurrentUser(state).token;

    const httpAuthLink = () =>
      setContext((_, { headers }) => ({
        headers: {
          ...headers,
          "X-Dug-Auth": getAuthToken(),
        },
      }));

    const link = from([httpAuthLink(), createUploadLink({ uri: COMMAND_URL })]);

    const client = new ApolloClient({
      cache: new InMemoryCache(),
      link,
    });
    const variables: UploadFileMutationVariables = {
      input: {
        file: {
          id: file.id,
          file: file.file,
          imageLabel: getImageLabel(state) || "",
        },
        datasetId: file.datasetId,
        folderId: file.folderId,
        notificationId: globalStore.notificationId,
      },
    };

    client
      .mutate({
        mutation: UploadFileDocument,
        variables,
      })
      .then((r) => {
        const { data, error } = r as UploadFileMutationResult;

        if (error) {
          throw new Error(error.message);
        }
        return data;
      })
      .then((data) => {
        if (data && !data.uploadFile?.success) {
          throw new Error(data.uploadFile?.message ?? "No message given.");
        }
        globalStore.uploadQueue = without([file], globalStore.uploadQueue);
        dispatch(appendToSuccessQueue(flattenFileInfo(file)));
        dispatch(processUploadQueue());
        return null;
      })
      .catch(() => {
        globalStore.uploadQueue = without([file], globalStore.uploadQueue);
        dispatch(appendToFailedQueue(flattenFileInfo(file)));
        dispatch(processUploadQueue());
      });
  }
};
