import { useCallback } from "react";
import { useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  getImageSettings,
  resolutionOptions,
} from "../state/dataTypeDisplaySettings";
import useMarkuptoolList from "../state/useMarkuptoolList";
import { useQueryResearchBoxesQuery } from "../ts-clients/query";

export default function useGetResearchBox() {
  const { selectedResolutionOption } = useSelector(getImageSettings);

  const { markuptoolList, currentListIndex } = useMarkuptoolList();

  const start = currentListIndex - 2 < 0 ? 0 : currentListIndex - 2;
  const end =
    currentListIndex + 2 >= markuptoolList.length
      ? markuptoolList.length - 1
      : currentListIndex + 2;

  const slice = markuptoolList.slice(start, end + 1);
  const { data, refetch } = useQueryResearchBoxesQuery({
    variables: {
      ids: slice,
    },
    skip: slice.length === 0,
    fetchPolicy: "cache-and-network",
  });

  const researchBoxes =
    data?.queryResearchBox?.flatMap((box) =>
      box?.__typename === "ResearchBox" ? box : []
    ) || [];

  useDeepCompareEffect(() => {
    researchBoxes?.forEach((rBox) =>
      rBox?.datapoints
        ?.flatMap((dp) => (dp.__typename === "Image" ? dp : []))
        .forEach((image) => {
          const img = new Image();
          const sortedResolutions = [...image.resolutions].sort(
            (a, b) => a.height * a.width - b.height * b.width
          );

          const options = resolutionOptions.slice(
            resolutionOptions.length - sortedResolutions.length
          );
          const selectedResolutionIndex = options.indexOf(
            selectedResolutionOption
          );
          const selectedResolution =
            sortedResolutions[
              selectedResolutionIndex >= 0 ? selectedResolutionIndex : 0
            ];

          img.src = selectedResolution.binaryURL;
        })
    );
  }, [researchBoxes, selectedResolutionOption]);

  const currentBoxIndex = currentListIndex >= 2 ? 2 : currentListIndex;

  const researchbox =
    currentBoxIndex < researchBoxes.length
      ? researchBoxes[currentBoxIndex]
      : undefined;

  const refetchCb = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    researchbox,
    researchboxCount: markuptoolList.length,
    refetch: refetchCb,
  };
}

export type useGetResearchBoxReturnType = ReturnType<typeof useGetResearchBox>;
