import { Container } from "semantic-ui-react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Workers from "./pages/Workers";
import Backups from "./pages/Backups";
import Users from "./pages/Users";
import Networks from "./pages/Networks";
import MetaData from "./pages/MetadataOverview";
import DatasetPositioner from "../filemanagement/DatasetPositioner";
import {
  currentUserIsAdmin,
  currentUserIsVendor,
} from "../../state/loginState";

export default function AdminRoot() {
  const { path } = useRouteMatch();

  const isAdmin = useSelector(currentUserIsAdmin);
  const isVendor = useSelector(currentUserIsVendor);

  return !isAdmin && !isVendor ? null : (
    <>
      <DatasetPositioner>
        <Switch>
          <Route exact path={path}>
            <Container>
              <h3>Please select one of the above.</h3>
            </Container>
          </Route>
          <Route path={`${path}/users`}>
            <Users />
          </Route>
          {isAdmin && (
            <Route path={`${path}/workers`}>
              <Workers />
            </Route>
          )}
          {isAdmin && (
            <Route path={`${path}/backups`}>
              <Backups />
            </Route>
          )}
          <Route path={`${path}/networks`}>
            <Networks />
          </Route>
          {isAdmin && (
            <Route path={`${path}/metadata`}>
              <MetaData />
            </Route>
          )}
        </Switch>
      </DatasetPositioner>
    </>
  );
}
