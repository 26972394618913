import { Vector2d } from "MarkupTypes";
import { useEffect, useState } from "react";
import { magicWandToPoly } from "../../hooks/annotationHelper";
import { MyMagicWand } from "../../hooks/types";
import Polygon from "./lowlevel/NewPoly";
import DragDot from "./lowlevel/DragDot";
import { getPolygonConfig } from "./lowlevel/util";

type Props = {
  c: MyMagicWand;
  selectedMarkupLabelId?: string | null;
  labelColor: string;
  temporary?: boolean;
  select?: (id: string) => void;
  isSelected?: boolean;
  isHighlighted: boolean;
  update: (c: MyMagicWand) => void;
  scale: number;
};

export default function MagicWand({
  c,
  selectedMarkupLabelId = null,
  labelColor,
  temporary = false,
  select,
  isSelected = false,
  update,
  scale,
  isHighlighted,
}: Props) {
  const [center, setMyCenter] = useState<Vector2d>({ x: 0, y: 0 });

  useEffect(() => {
    setMyCenter(c.center);
  }, [c.center]);

  const temporaryMagicWand = { ...c, center };

  const points = magicWandToPoly(temporaryMagicWand);

  const getConfig = (isMouseOver: boolean) =>
    getPolygonConfig(
      c.annotationType,
      "MagicWand",
      isMouseOver,
      isSelected,
      isHighlighted,
      temporary,
      selectedMarkupLabelId === c.markupLabelId,
      labelColor,
      scale
    );

  const dragEnd = () => update(temporaryMagicWand);

  const selectHandler = () => {
    if (select) {
      select(c.id);
    }
  };

  return (
    <>
      <Polygon
        points={points}
        getConfig={getConfig}
        select={selectHandler}
        isSelected={isSelected}
      />
      {isSelected && (
        <DragDot
          scale={scale}
          position={center}
          dragTo={setMyCenter}
          dragEnd={dragEnd}
        />
      )}
    </>
  );
}

MagicWand.defaultProps = {
  temporary: false,
  select: undefined,
  isSelected: false,
  selectedMarkupLabelId: null,
};
