import { Form, Segment, Menu } from "semantic-ui-react";
import useKeyboard from "../../../../../hooks/useKeyboard";
import LabelList from "./LabelList";
import { VectorMarkup } from "./types";

type Props = {
  vectorData: VectorMarkup[];
  toggleLabel: (labelId: string) => void;
  disableAll: () => void;
  enableAll: () => void;
};

export default function ToolsVector({
  vectorData,
  toggleLabel,
  disableAll,
  enableAll,
}: Props) {
  const selectNumber = (n: number, alt: boolean) => {
    const selected = alt ? 10 + n : n;
    // select label
    const label = vectorData[selected] ?? null;
    if (label !== null) {
      toggleLabel(label.id);
    }
  };

  useKeyboard({
    onShortcut: (k, alt) => {
      switch (k) {
        case "Digit1":
          selectNumber(0, alt);
          break;
        case "Digit2":
          selectNumber(1, alt);
          break;
        case "Digit3":
          selectNumber(2, alt);
          break;
        case "Digit4":
          selectNumber(3, alt);
          break;
        case "Digit5":
          selectNumber(4, alt);
          break;
        case "Digit6":
          selectNumber(5, alt);
          break;
        case "Digit7":
          selectNumber(6, alt);
          break;
        case "Digit8":
          selectNumber(7, alt);
          break;
        case "Digit9":
          selectNumber(8, alt);
          break;
        case "Digit0":
          selectNumber(9, alt);
          break;
        default:
          break;
      }
    },
  });

  return (
    <>
      <Menu attached text>
        <Menu.Item header content="activate groups" style={{ height: 42 }} />
        <Menu.Menu position="right">
          <Menu.Item content="all" icon="plus square" onClick={enableAll} />
          <Menu.Item content="none" icon="minus square" onClick={disableAll} />
        </Menu.Menu>
      </Menu>
      <Segment attached>
        <Form>
          <LabelList toggle={toggleLabel} labelList={vectorData} />
        </Form>
      </Segment>
    </>
  );
}
