import { Menu } from "semantic-ui-react";
import { Flags } from "react-feature-flags";
import { ImageDatapointType } from "../../../../types/extractedTypes";
import WithTraining from "./WithTraining";
import WithoutTraining from "./WithoutTraining";
import DenkboxProvider from "../../../../ctx/DenkboxProvider";

type Props = {
  image: ImageDatapointType;
  datasetId: string;
  listIndex: number;
  currentNetworkId: string;
};

export default function MarkupToolRightMenuIndex({
  image,
  datasetId,
  currentNetworkId,
}: Props) {
  return (
    <DenkboxProvider
      datasetId={datasetId}
      networkId={currentNetworkId}
      pollInterval={5000}
    >
      <Menu
        fixed="right"
        vertical
        style={{
          padding: "15px",
          marginTop: 40,
          backgroundColor: "#f4f4f4",
          width: "18rem",
        }}
      >
        <Flags
          authorizedFlags={["showTraining"]}
          renderOn={() => (
            <WithTraining currentNetworkId={currentNetworkId} image={image} />
          )}
          renderOff={() => (
            <WithoutTraining
              currentNetworkId={currentNetworkId}
              image={image}
            />
          )}
        />
      </Menu>
    </DenkboxProvider>
  );
}
