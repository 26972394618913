import { BaseParams } from "ModuleTypes";
import { useEffect, useState } from "react";
import { useGetPipelineModuleCcCheckerQuery } from "../../ts-clients/query";
import { useUpdatePipelineModuleCcCheckerMutation } from "../../ts-clients/command";
import { ccheckerDefaults } from "./defaults";

export default function useUpdatePipelineModuleCCChecker(
  pipelineModuleId: string
) {
  const [moduleData, setModuleData] = useState(ccheckerDefaults());
  const { data } = useGetPipelineModuleCcCheckerQuery({
    variables: {
      id: pipelineModuleId,
    },
  });

  useEffect(() => {
    const d = data?.getPipelineModuleCCChecker;
    const outputs = d?.outputs ?? [];
    const firstOuputName = outputs.length > 0 ? outputs[0].name : "";
    setModuleData({
      busBarCountPerCell: d?.busBarCountPerCell ?? 0,
      cccheckerResultName: firstOuputName,
      cutOffRate: d?.cutOffRate ?? 0,
      maxShift: d?.maxShift ?? 0,
      minCorrValue: d?.minCorrValue ?? 0,
      minPeakDistance: d?.minPeakDistance ?? 0,
      minPeakHeightPercentage: d?.minPeakHeightPercentage ?? 0,
      minPeakProminence: d?.minPeakProminence ?? 0,
      moduleDescription: d?.description ?? "",
      moduleName: d?.name ?? "",
      position: { x: d?.layoutPosition.x ?? 0, y: d?.layoutPosition.y ?? 0 },
      pretrainedNetworkModuleId: null,
    });
  }, [data]);

  const [m] = useUpdatePipelineModuleCcCheckerMutation();

  const update = () => {
    m({
      variables: {
        input: {
          filter: {
            moduleId: pipelineModuleId,
          },
          ...moduleData,
        },
      },
    });
  };

  const setBaseParams = ({ moduleDescription, moduleName }: BaseParams) => {
    setModuleData((md) => ({ ...md, moduleName, moduleDescription }));
  };

  return { update, moduleData, setModuleData, setBaseParams };
}
