import { useCallback, useEffect, useState } from "react";
import { useImageTools } from "./useImageTools";
import useLocalStorage from "../../../state/useLocalStorage";

type PatternType = "Negative" | "Ignore" | "AI";

const useGeneratePattern = () => {
  const { loading, wasm } = useImageTools();

  const [state, setState] = useLocalStorage<{ [key: string]: string }>(
    "annotation-tool-patterns",
    {}
  );

  const generate = useCallback(
    (
      type: PatternType,
      color: string,
      opacity = 127
    ): Promise<HTMLImageElement> => {
      const key = `${type}:${color}:${opacity}`;

      if (!(key in state)) {
        const ret = wasm?.generate_pattern(
          type === "AI" ? 2 : type === "Negative" ? 0 : 1,
          color,
          opacity || 127
        );
        const url = `data:image/png;base64,${ret}`;
        const newState = { ...state };
        newState[key] = url;
        setState(newState);
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            resolve(img);
          };
          img.src = url;
        });
      }

      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          resolve(img);
        };
        img.src = state[key];
      });
    },
    [wasm, state, setState]
  );

  return { loading, generate: !wasm ? undefined : generate };
};

export const useGeneratePatternHook = (
  type: PatternType,
  color: string,
  opacity = 127
) => {
  const [image, setImage] = useState<HTMLImageElement | undefined>();

  const { loading, generate } = useGeneratePattern();

  useEffect(() => {
    const make = async () => {
      if (generate) {
        const result = await generate(type, color, opacity);
        setImage(result);
      }
    };
    if (!loading && generate) {
      make();
    }
  }, [loading, generate, type, color, opacity]);

  return { image };
};

export default useGeneratePattern;
