/* eslint-disable react/jsx-curly-newline */
import { Fragment } from "react";
import { Stage, Layer, Line } from "react-konva";
import { Point } from "MarkupTypes";
import * as Layers from "./Layers";
import { ImageDatapointType } from "../../../types/extractedTypes";
import { InteractionHooksType } from "./hooks/useInteractionHooks";
import useStageReadonly from "./hooks/useStageReadonly";
import { MyAnnotations } from "./hooks/types";
import { PixelScaledWithColor } from "./Layers/AnnotationTypes";
// import Label from './Layers/AnnotationTypes/lowlevel/CanvasLabel';
import { Thumbnail } from "./hooks/useAnnotationThumbnails";

export const topLeftBottomRightToRectanglePoints = (
  points: Point[]
): number[] => {
  const newPoints: number[] = [
    points[0].x,
    points[0].y,
    points[1].x,
    points[0].y,
    points[1].x,
    points[1].y,
    points[0].x,
    points[1].y,
  ];
  return newPoints;
};

type Props = {
  datapoint: ImageDatapointType;
  annotations?: MyAnnotations;
  thumbnails?: Thumbnail[];
  draggable?: boolean;
  zoomable?: boolean;
  onClick?: () => void;
  setHook: InteractionHooksType["setHook"];
  callHook: InteractionHooksType["callHook"];
};

export default function ImageWithSegmentation2dReadOnly({
  datapoint,
  annotations,
  thumbnails,
  draggable,
  zoomable,
  onClick,
  setHook,
  callHook,
}: Props) {
  const {
    canvasSize,
    cursorType,
    stageEvents,
    maybeStage,
    divWrapper,
    showImage,
    imageSize,
  } = useStageReadonly(datapoint, setHook, callHook);

  const boundingBoxAnnotation =
    datapoint?.markups
      ?.flatMap((m) =>
        m.__typename === "ObjectDetectionMarkup" ? m.annotations : []
      )
      .flat(1) ?? [];

  return (
    <>
      <div
        ref={divWrapper}
        style={{ height: "100%", width: "100%", overflow: "hidden" }}
      >
        <Stage
          ref={maybeStage}
          style={{
            cursor: cursorType,
          }}
          width={canvasSize.width}
          height={canvasSize.height}
          draggable={draggable}
          onWheel={zoomable ? stageEvents.onWheel : () => {}}
          onMouseMove={stageEvents.onMouseMove}
          onDblClick={stageEvents.onDoubleClick}
          onClick={onClick}
        >
          <Layer>
            <Layers.URLImage image={showImage} size={imageSize} />
            {annotations !== undefined && <Layers.AnnotationsReadOnly />}
            {thumbnails !== undefined &&
              thumbnails.map((thumbnail) => (
                <Fragment key={thumbnail.id}>
                  <PixelScaledWithColor
                    thumbnail={thumbnail}
                    imageSize={imageSize}
                  />
                </Fragment>
              ))}
            {boundingBoxAnnotation !== [] &&
              boundingBoxAnnotation.map((bb) => (
                <Line
                  key={bb.id}
                  points={topLeftBottomRightToRectanglePoints([
                    bb.topLeft,
                    bb.bottomRight,
                  ])}
                  stroke={bb.label.color}
                  strokeWidth={3}
                  closed
                  fill={`${bb.label.color}30`}
                />
              ))}
          </Layer>
        </Stage>
      </div>
    </>
  );
}

ImageWithSegmentation2dReadOnly.defaultProps = {
  annotations: undefined,
  thumbnails: undefined,
  draggable: true,
  zoomable: true,
  onClick: undefined,
};
