/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import cuid from "cuid";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Flags } from "react-feature-flags";
import { Input, Icon, Menu, Popup, Button, Dropdown } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import globalStore from "../../state/uglyStore";
import { useAddProgressNotificationMutation } from "../../ts-clients/command";
import { FileInfo } from "../filemanagement/types/FileManagementTypes";
import FolderAdd from "./FolderAddModal";
import DirectoryNavigation from "./DirectoryNavigation";
import {
  clearSuccessQueue,
  enableUploadProcess,
  processUploadQueue,
} from "../../state/upload";
import { canUpload } from "../../state/loginState";
import useResearchBoxFilters from "./useResearchBoxFilters";
import useResearchBoxNavigation from "./hooks/useResearchBoxNavigation";
import { useHistory, useLocation } from "react-router";
import {
  getFilename,
  getViewType,
  PageSize,
  setPage,
  setViewType,
} from "../../state/researchboxfilter";
import { useUIType } from "../../hooksqueries";
import { useGoto } from "../../utils/routeManager";

type Props = {
  datasetId: string;
  folderIds: string[];
  objectsSelected?: boolean;
  handleDelete: () => void;
};

const toggle = (array: string[], value: string) => {
  const index = array.indexOf(value);
  const a = Array.from(array);
  if (index === -1) {
    a.push(value);
  } else {
    a.splice(index, 1);
  }
  return a;
};

export default function ListMenu({
  datasetId,
  folderIds,
  objectsSelected,
  handleDelete,
}: Props) {
  const [newFolderModalVisible, setNewFolderModalVisible] = useState(false);

  const dispatch = useDispatch();

  const userCanUpload = useSelector(canUpload);

  const [addProgressNotification] = useAddProgressNotificationMutation();

  const uploadStart = async (files: FileInfo[]) => {
    const { data } = await addProgressNotification({
      variables: {
        input: {
          title: "Upload",
          message: `Uploading ${files.length} Files.`,
          maxProgress: files.length,
        },
      },
    });
    globalStore.notificationId =
      data?.addProgressNotification?.notificationId || "";
    globalStore.uploadQueue = [...globalStore.uploadQueue, ...files];
    dispatch(clearSuccessQueue());
    dispatch(enableUploadProcess());
    dispatch(processUploadQueue());
  };

  const onDrop = (acceptedFiles: Array<File>) => {
    uploadStart(
      acceptedFiles.map((file) => ({
        datasetId,
        file,
        id: cuid(),
        folderId:
          folderIds.length > 0 ? folderIds[folderIds.length - 1] : undefined,
      }))
    );
  };

  const uiType = useUIType();

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept:
      "image/png, image/jpeg, image/x-tiff, image/tiff, image/bmp, image/webp, application/zip",
    noClick: true,
  });

  const {
    clearSearchFilename,
    setSearchFileName,
    doSetNetworks,
    networkOptions,
    selectedNetworks,
    doSetLabels,
    labelOptions,
    selectedLabelIds,
    includeNegatives,
    includePositives,
    includeUnclassified,
    toggleNegatives,
    togglePositives,
    toggleUnclassified,
  } = useResearchBoxFilters({
    context: "researchboxlist",
    datasetId,
  });

  const toggleNetworkFilter = (networkId: string) =>
    doSetNetworks(toggle(selectedNetworks, networkId));

  const toggleLabelFilter = (labelId: string) =>
    doSetLabels(toggle(selectedLabelIds, labelId));

  const history = useHistory();
  const location = useLocation();
  const goTo = useGoto();

  const viewType = useSelector(getViewType);

  const {
    currentPage,
    doSetPageSize,
    goNext,
    goPrev,
    isOnFirstPage,
    isOnLastPage,
    pageSizes,
    selectedPageSize,
    pages,
  } = useResearchBoxNavigation();

  const goUp = () => {
    history.push({
      pathname: location.pathname,
      search:
        folderIds.length > 1
          ? `?path=${folderIds.slice(0, folderIds.length - 1).join("-")}`
          : undefined,
    });
  };

  const filenameFilter = useSelector(getFilename);

  return (
    <>
      <FolderAdd
        visible={newFolderModalVisible}
        hide={() => setNewFolderModalVisible(false)}
        datasetId={datasetId}
        folderIds={folderIds}
      />

      <Menu icon fixed="top" style={{ top: 40 }}>
        <Menu.Item
          content="back"
          icon="arrow left"
          onClick={() =>
            isOnFirstPage
              ? folderIds.length === 0
                ? goTo("Dashboard", {})
                : goUp()
              : goPrev()
          }
        />
        <Dropdown item text={currentPage.toString()} search>
          <Dropdown.Menu>
            {pages.map((page) => (
              <Dropdown.Item
                key={page.key}
                onClick={() => {
                  dispatch(setPage(page.key));
                }}
              >
                {page.text}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Menu.Item
          content="next"
          icon="arrow right"
          disabled={isOnLastPage}
          onClick={goNext}
        />
        <Menu.Item
          size="small"
          onClick={goUp}
          disabled={folderIds.length === 0}
          icon="arrow up"
        />

        {uiType !== "halm" && (
          <Popup
            disabled={folderIds.length < 10}
            trigger={
              <Menu.Item
                welcome-tour-file-list="2"
                name="folder"
                onClick={() => setNewFolderModalVisible(true)}
                disabled={folderIds.length >= 10}
              >
                <Icon.Group size="large">
                  <Icon name="folder" />
                  <Icon corner="top right" name="add" />
                </Icon.Group>
                New Folder
              </Menu.Item>
            }
          >
            Maximum folder depth reached.
          </Popup>
        )}
        {userCanUpload && uiType !== "halm" && (
          <Menu.Item
            welcome-tour-file-list="1"
            name="upload"
            onClick={() => {
              open();
            }}
          >
            <div {...getRootProps()}>
              <Icon name="upload" size="large" />
              <input {...getInputProps()} />
            </div>
            Upload
          </Menu.Item>
        )}
        {/* <Menu.Item
          name="copy selected"
          onClick={() => {}}
          disabled={!objectsSelected}
        >
          <Icon name="copy" />
          Copy Selection
        </Menu.Item> */}
        <Menu.Item
          name="delete selected"
          onClick={handleDelete}
          disabled={!objectsSelected}
        >
          <Icon name="trash" size="large" />
          Delete Selection
        </Menu.Item>

        <Menu.Item>
          <Input
            placeholder="Filename..."
            onChange={(_, { value }) => setSearchFileName(value)}
            action={
              <Button basic icon="delete" onClick={clearSearchFilename} />
            }
            value={filenameFilter}
          />
        </Menu.Item>

        <Dropdown item text="More Filter Options">
          <Dropdown.Menu>
            <Flags
              authorizedFlags={["filterNetwork"]}
              renderOn={() => (
                <Dropdown item text="Networks" search>
                  <Dropdown.Menu>
                    {networkOptions.map((o) => (
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          toggleNetworkFilter(o.value);
                        }}
                        key={o.key}
                        content={o.text}
                        icon={o.icon}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            />
            <Flags
              authorizedFlags={["filterClassLabels"]}
              renderOn={() => (
                <Dropdown
                  item
                  text="Labels"
                  search
                  disabled={selectedNetworks.length === 0}
                >
                  <Dropdown.Menu>
                    {labelOptions.map((o) => (
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          toggleLabelFilter(o.value);
                        }}
                        key={o.key}
                        content={o.text}
                        icon={o.icon}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            />
            <Dropdown.Item
              disabled={selectedNetworks.length === 0}
              content="Include Positive"
              icon={includePositives ? "check" : "times"}
              onClick={togglePositives}
            />
            <Dropdown.Item
              disabled={selectedNetworks.length === 0}
              content="Include Negative"
              icon={includeNegatives ? "check" : "times"}
              onClick={toggleNegatives}
            />
            <Dropdown.Item
              disabled={selectedNetworks.length === 0}
              content="Only Unclassified"
              icon={includeUnclassified ? "check" : "times"}
              onClick={toggleUnclassified}
            />
          </Dropdown.Menu>
        </Dropdown>

        <Menu.Menu position="right" welcome-tour-file-list="3">
          <Dropdown item text="View Options" simple>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => dispatch(setViewType("icon-view"))}
                active={viewType === "icon-view"}
                icon="th large"
                text="Icon Name"
              />
              <Dropdown.Item
                name="list view"
                onClick={() => dispatch(setViewType("list-view"))}
                active={viewType === "list-view"}
              >
                <Icon name="list" />
                List View
              </Dropdown.Item>
              <Dropdown
                item
                selection
                direction="left"
                pointing="left"
                label="Page Size"
                placeholder="Page Size"
                onChange={(_, ddval) => doSetPageSize(ddval.value as PageSize)}
                options={pageSizes}
                text={`Page Size: ${selectedPageSize}`}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
      <div style={{ marginTop: 45, marginBottom: 21 }}>
        <DirectoryNavigation folderIds={folderIds} datasetId={datasetId} />
      </div>
    </>
  );
}

ListMenu.defaultProps = {
  objectsSelected: undefined,
};

// <Dropdown
//   item
//   label="Networks"
//   placeholder="Networks"
//   onChange={(_, { value }) => doSetNetworks(value as string[])}
//   multiple
//   selection
//   options={networkOptions}
//   value={selectedNetworks}
// />
