import { Button, Segment, Header, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ImageWithSegmentation2dPreview from "../../datapoint-viewer/ImageWithSegmentation2d/ReadOnlyFullDetails";
import { DetailsDatapointParams, getPath } from "../../../utils/routeManager";
import { ImageDatapointType } from "../../../types/extractedTypes";

export type Params = {
  page: DetailsDatapointParams;
  datapoint: ImageDatapointType;
  networkId: string;
};

export default function ImageDetails({ datapoint, page, networkId }: Params) {
  const backLink =
    page.context === "sensorimport"
      ? getPath("SensorImport", page)
      : getPath("ResearchBoxDetails", { ...page, page: "overview" });

  return (
    <div>
      <Header as="h1">
        Image
        <Button as={Link} floated="right" to={backLink} content="back" />
      </Header>
      <Divider />
      <Segment
        style={{
          width: "100%",
          height: "calc(100vh - 140px)",
          overflow: "hidden",
        }}
      >
        <ImageWithSegmentation2dPreview
          datapoint={datapoint}
          networkId={networkId}
        />
      </Segment>
    </div>
  );
}
