import { useEffect } from "react";
import { Progress, Message } from "semantic-ui-react";
import { useQueryDenkTesterProgressQuery } from "../../../../../../ts-clients/query";
import useFakeProgress from "./useFakeTimer";

const runningTime = 60 * 2;

type Props = {
  networkId: string;
};

export default function FakeTestProgressBar({ networkId }: Props) {
  const { data, startPolling, stopPolling } = useQueryDenkTesterProgressQuery({
    variables: {
      networkId,
    },
  });

  useEffect(() => {
    startPolling(5);
    return function cleanup() {
      stopPolling;
    };
  }, [startPolling, stopPolling]);

  const notifications = data?.queryDenktesterNotification ?? [];
  const stage = notifications.length
    ? notifications[0]?.currentProgress ?? 0
    : 0;

  const maxStage = notifications.length
    ? notifications[0]?.maxProgress ?? 2
    : 2;

  const { progress } = useFakeProgress(runningTime, stage);

  return (
    <Message>
      Preparing: Stage {stage + 1} / {maxStage + 1}
      <Progress total={runningTime} value={progress} size="tiny" />
    </Message>
  );
}
