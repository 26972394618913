/* eslint-disable react/jsx-one-expression-per-line */
import ResearchBoxList from "../../features/researchboxlist";
import ResearchBoxListMenu from "./ResearchBoxListMenu";
import { useParseRouteParams } from "../../utils/routeManager";
import MenuMain from "../../components/menus/menu.main";
import DefaultPositioner from "../../features/filemanagement/DefaultPositioner";

export default function ResearchBoxListPage() {
  const pageParams = useParseRouteParams("ResearchBoxList");

  return (
    <>
      <MenuMain />
      <ResearchBoxListMenu current="files" datasetId={pageParams.datasetId} />
      <DefaultPositioner>
        <ResearchBoxList params={pageParams} />
      </DefaultPositioner>
    </>
  );
}
