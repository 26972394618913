import { Form } from "semantic-ui-react";
import MagneticFieldDataSettings from "./MagneticFieldDataSettings";
import ResolutionSelector from "./ResolutionSelector";
import MarkupSettings from "./MarkupSettings";
import { PartList } from "../helper";
import { ImageResolution } from "../hooks";
import { PartContext } from "./types";

type Props = {
  parts: PartList;
  resolutions: ImageResolution[];
  context: PartContext;
};

export default function SettingsViewPartsIndex({
  parts,
  resolutions,
  context,
}: Props) {
  return (
    <Form>
      {parts.mf && <MagneticFieldDataSettings />}
      {parts.markup && <MarkupSettings context={context} />}
      {parts.image && resolutions.length > 0 && (
        <ResolutionSelector resolutions={resolutions} />
      )}
    </Form>
  );
}
