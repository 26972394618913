import { useRef, useState, useEffect, useMemo, useCallback } from "react";
import Konva from "konva";
import { CursorType, ImageSize } from "MarkupTypes";
import useImage from "use-image";
import { debounce } from "lodash";
import { KonvaEventObject } from "konva/lib/Node";
import { useUrlFromSelectedResolution } from "../../../../utils/helpers";
import useCursor from "./useCursor";
import { getPointerPosition } from "./helper";
import { ImageDatapointType } from "../../../../types/extractedTypes";
import { InteractionHooksType } from "./useInteractionHooks";
import useZoom from "./useZoom";
import useInteractionsReadOnly from "./useInteractionsReadOnly";
import { LeanInteractionState } from "./types";

const getStage = (e: KonvaEventObject<Event>) => {
  e.evt.preventDefault();
  return e.currentTarget.getStage();
};

export default function useStageReadOnly(
  imageDatapoint: ImageDatapointType,
  setHook: InteractionHooksType["setHook"],
  callHook: InteractionHooksType["callHook"]
) {
  const updateState = useInteractionsReadOnly({ callHook });

  const divWrapper = useRef<HTMLDivElement>(null);

  const thumbnailData = imageDatapoint.thumbnail;

  const imagUrl = useUrlFromSelectedResolution(imageDatapoint);

  const imageSize = {
    width: imageDatapoint.width,
    height: imageDatapoint.height,
  };

  const [image] = useImage(imagUrl ?? "", "anonymous");

  const maybeStage = useRef<Konva.Stage>(null);

  const { setCursor } = useCursor();

  const [canvasSize, resizeCanvas] = useState<ImageSize>({
    width: 0,
    height: 0,
  });

  const { resetZoom, scaleStage, scale } = useZoom(
    maybeStage.current,
    canvasSize,
    imageSize
  );

  const defaultEnvironment: LeanInteractionState = {
    currentDataChangedByUser: false,
    mouseMode: "default",
    mouseOnCanvas: true,
    oneClickShapes: false,
    paintActive: false,
    scrollDirection: "down",
    selectedAnnotationFlavour: null,
    selectedAnnotationId: "",
    selectedSegmentation2DTool: "None",
  };

  const onWheel = (e: KonvaEventObject<WheelEvent>) => {
    e.evt.preventDefault();
    const { deltaY } = e.evt;
    updateState("wheel", {
      ...defaultEnvironment,
      scrollDirection: deltaY < 0 ? "up" : "down",
    });
  };

  const onDoubleClick = () => {
    resetZoom();
  };
  const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    const stage = getStage(e);
    const cursor = getPointerPosition(stage);
    setCursor(cursor);
  };

  const stageEvents = {
    onClick: () => {},
    onWheel,
    onDragMove: () => {},
    onDoubleClick,
    onMouseDown: () => {},
    onMouseUp: () => {},
    onMouseLeave: () => {},
    onMouseEnter: () => {},
    onMouseMove,
  };

  const zoomIn = useCallback(() => scaleStage(1.1), [scaleStage]);
  const zoomOut = useCallback(() => scaleStage(1 / 1.1), [scaleStage]);

  const stageActions = useMemo(() => {
    return { zoomIn, zoomOut, resetZoom };
  }, [resetZoom, zoomIn, zoomOut]);

  const cursorType: CursorType = "default";

  useEffect(() => {
    setHook("stageZoomIn", stageActions.zoomIn);
    setHook("stageZoomOut", stageActions.zoomOut);
    setHook("stageResetZoom", stageActions.resetZoom);
  }, [
    setHook,
    stageActions.resetZoom,
    stageActions.zoomIn,
    stageActions.zoomOut,
  ]);

  useEffect(() => {
    stageActions.resetZoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image?.id, maybeStage.current]);

  const [thumbnailImage] = useImage(thumbnailData);
  const showImage = image === undefined ? thumbnailImage : image;

  useEffect(() => {
    resizeCanvas({
      width: (divWrapper.current?.offsetWidth || 0) as number,
      height: (divWrapper.current?.offsetHeight || 0) as number,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divWrapper]);

  useEffect(() => {
    const resize = debounce(() => {
      resizeCanvas({
        width: divWrapper.current?.offsetWidth ?? 300,
        height: divWrapper.current?.offsetHeight ?? 300,
      });
    }, 200);
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  });

  return {
    canvasSize,
    cursorType,
    scale,
    stageEvents,
    showImage,
    maybeStage,
    divWrapper,
    imageSize,
  };
}
