import { useHistory, useLocation } from "react-router";
import {
  useGetDatasetNameQuery,
  useQueryFolderNamesQuery,
} from "../../ts-clients/query";
import { useGoto } from "../../utils/routeManager";
import BreadcrumbSection from "./BreadCrumbSection";

type Props = {
  folderIds: string[];
  datasetId: string;
};

export default function DirectoryNavigation({ folderIds, datasetId }: Props) {
  const { data: folderNames } = useQueryFolderNamesQuery({
    variables: {
      folderIds,
    },
    skip: folderIds.length === 0,
  });

  const { data: datasetNameData } = useGetDatasetNameQuery({
    variables: {
      datasetId,
    },
    fetchPolicy: "cache-and-network",
  });

  const datasetName = datasetNameData?.getDataset?.name || "";

  const folders = [...(folderNames?.queryFolder || [])].sort(
    (a, b) => folderIds.indexOf(a?.id || "") - folderIds.indexOf(b?.id || "")
  );

  const goTo = useGoto();
  const history = useHistory();
  const location = useLocation();

  return (
    <div className="ui breadcrumb">
      <BreadcrumbSection
        key={datasetId}
        id={datasetId}
        isDataset
        name={datasetName}
        onClick={
          folderIds.length > 0
            ? () =>
                goTo("ResearchBoxList", {
                  context: "researchboxlist",
                  datasetId,
                })
            : undefined
        }
      />
      {folders.map((f, idx) => (
        <BreadcrumbSection
          key={f?.id || ""}
          id={f?.id || ""}
          isDataset={false}
          name={f?.name || ""}
          onClick={
            idx < folders.length - 1
              ? () => {
                  const currentIndex = folderIds.indexOf(f?.id || "");
                  const splicedArray = folderIds.slice(0, currentIndex + 1);
                  history.push({
                    pathname: location.pathname,
                    search:
                      splicedArray.length > 0
                        ? `?path=${splicedArray.join("-")}`
                        : undefined,
                  });
                }
              : undefined
          }
        />
      ))}
    </div>
  );
}
