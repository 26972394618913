import { Button } from "semantic-ui-react";
import { PixelDrawMode } from "MarkupTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  setPixelDrawMode,
  getPixelDrawMode,
} from "../../inputImage/redux-state";

export default function PolygonTypeSwitch() {
  const dispatch = useDispatch();
  const pixelDrawMode = useSelector(getPixelDrawMode);

  const set = (d: PixelDrawMode) => dispatch(setPixelDrawMode(d));

  return (
    <Button.Group fluid size="mini">
      <Button
        color={pixelDrawMode === "draw" ? "blue" : undefined}
        content="draw"
        active={pixelDrawMode === "draw"}
        onClick={() => set("draw")}
      />
      <Button
        color={pixelDrawMode === "erase" ? "blue" : undefined}
        content="erase"
        active={pixelDrawMode === "erase"}
        onClick={() => set("erase")}
      />
    </Button.Group>
  );
}
