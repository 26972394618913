/* eslint-disable react/jsx-one-expression-per-line */
import { ModuleType } from "ModuleTypes";
import { Header, Divider, Message, Button } from "semantic-ui-react";
import PMCCCheckerUpdate from "./PMCCCheckerUpdate";
import PMContrastEnhancementUpdate from "./PMContrastEnhancemenUpdate";
import PMNetworkImageClassificationUpdate from "./PMNetworkImageClassificationUpdate";
import PMNetworkImageSegmentationUpdate from "./PMNetworkImageSegmentationUpdate";
import PMNetworkImageObjectDetectionUpdate from "./PMNetworkImageObjectDetectionUpdate";
import { useGetPipelineModuleType } from "../../../hooks/moduleCreators-new-2021-04/shared";
import { getNiceModuleName } from "../shared";

type Props = {
  onCancel: () => unknown;
  moduleId: string;
  datasetId: string;
};

export default function ModuleUpdate({ onCancel, moduleId, datasetId }: Props) {
  const selectedModuleType = useGetPipelineModuleType(moduleId);
  const moduleName = getNiceModuleName(selectedModuleType);
  return (
    <>
      <Header as="h2">Update {moduleName}</Header>
      <Divider />
      <RenderModule
        datasetId={datasetId}
        onCancel={onCancel}
        moduleId={moduleId}
        moduleType={selectedModuleType}
      />
    </>
  );
}

type RenderProps = Props & {
  moduleType: ModuleType;
};

function RenderModule({
  onCancel,
  moduleId,
  moduleType,
  datasetId,
}: RenderProps) {
  switch (moduleType) {
    case "PipelineModuleCCChecker":
      return <PMCCCheckerUpdate moduleId={moduleId} onCancel={onCancel} />;
    case "PipelineModuleContrastEnhancement":
      return (
        <PMContrastEnhancementUpdate moduleId={moduleId} onCancel={onCancel} />
      );
    case "PipelineModuleNetworkImageClassification":
      return (
        <PMNetworkImageClassificationUpdate
          moduleId={moduleId}
          datasetId={datasetId}
          onCancel={onCancel}
        />
      );
    case "PipelineModuleNetworkImageSegmentation":
      return (
        <PMNetworkImageSegmentationUpdate
          moduleId={moduleId}
          datasetId={datasetId}
          onCancel={onCancel}
        />
      );
    case "PipelineModuleNetworkImageObjectDetection":
      return (
        <PMNetworkImageObjectDetectionUpdate
          moduleId={moduleId}
          dataSetId={datasetId}
          onCancel={onCancel}
        />
      );
    default:
      return (
        <>
          <Message
            content={`No create renderer exists for module type ${moduleType}`}
            info
          />
          <Button
            basic
            onClick={onCancel}
            content="go back"
            icon="arrow left"
          />
        </>
      );
  }
}
