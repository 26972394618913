/* eslint-disable react/jsx-curly-newline */
import { ReactNode } from "react";
import { MarkupLabel } from "MarkupTypes";
import { Grid, Segment } from "semantic-ui-react";
import MarkupTools from "../../shared/MarkupTools";
import ImageWithSegmentation2dEditable from "../../../../datapoint-viewer/ImageWithSegmentation2d/Editable";
import LayerTools from "../../shared/LayerTools";
import MarkupList from "../../shared/MarkupList";
import { NavigationProps } from "../../../utils";
import { ImageDatapointType } from "../../../../../types/extractedTypes";
import PixelDrawModeSwitch from "../../shared/PixelDrawModeSwitch";
import useMarkupTool from "./useMarkupTool";
import useImageInfo from "../../hooks/useImageInfo";

type Props = {
  image: ImageDatapointType;
  labelList: MarkupLabel[];
  selector: ReactNode;
  navigation: NavigationProps;
  networkId: string;
  deleteResearchbox: () => void;
};

export default function Segmentation2DIndex({
  image,
  labelList,
  selector,
  navigation,
  networkId,
  deleteResearchbox,
}: Props) {
  const {
    annotationActions,
    annotations,
    callHook,
    clearAndNext,
    currentData,
    currentDataActions,
    currentDataChangedByUser,
    paintActive,
    pixelActions,
    pixelCanvas,
    saveAndNext,
    segmentationsVisible,
    setHook,
    trigger,
    undoMeta,
    updateState,
    highlightedIds,
    highlightStartingPoint,
    lastPredictionTime,
    subset,
  } = useMarkupTool({
    image,
    labelList,
    navigation,
    networkId,
  });

  const imageInfo = useImageInfo(networkId);

  return (
    <>
      <Grid>
        <Grid.Column width="4">
          <Segment attached>{selector}</Segment>
          <div style={{ marginTop: 10 }}>
            <MarkupTools toolSet="objectDetection" />
            {paintActive !== null && <PixelDrawModeSwitch />}
          </div>
          <div
            style={{
              maxHeight: "calc(100vh - 428px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <MarkupList
              dataType="PipelineModuleNetworkImageObjectDetection"
              annotationData={annotations.annotationData}
              currentNetworkLabelId={annotations.markupLabelId}
              labelList={labelList}
              paintActive={paintActive}
              replacePolygonType={annotationActions.replaceAnnotationType}
              replacePolygonLabel={
                annotationActions.replaceAnnotationMarkupLabel
              }
              paintStart={pixelActions.paintStart}
              setCurrentNetworkLabelIdAndColor={
                annotationActions.setMarkupLabelIdAndColor
              }
              onDelete={annotationActions.annotationDelete}
              paintFinish={() => trigger("paintFinish")}
            />
          </div>
        </Grid.Column>
        <Grid.Column width="12">
          <LayerTools
            canRedo={undoMeta.canRedo}
            canUndo={undoMeta.canUndo}
            imageInfo={imageInfo}
            save={saveAndNext}
            clear={clearAndNext}
            del={deleteResearchbox}
            labelList={labelList}
            currentLabelId={annotations.markupLabelId}
            setCurrentNetworkLabelIdAndColor={
              annotationActions.setMarkupLabelIdAndColor
            }
            segmentationsVisible={segmentationsVisible}
            toggleSegmentationsVisible={
              annotationActions.toggleSegmentationsVisible
            }
            paintActive={paintActive}
            callHook={callHook}
            trigger={trigger}
            lastPredictionTime={lastPredictionTime}
            subset={subset ?? "unknown"}
          />
          <Segment
            style={{ height: "calc(100vh - 240px)", overflow: "hidden" }}
            attached="bottom"
          >
            <ImageWithSegmentation2dEditable
              setHook={setHook}
              imageDatapoint={image}
              labelList={labelList}
              annotations={annotations}
              annotationActions={annotationActions}
              segmentationsVisible={segmentationsVisible}
              updateState={updateState}
              currentDataChangedByUser={currentDataChangedByUser}
              currentDataActions={currentDataActions}
              currentData={currentData}
              paintActive={paintActive}
              pixelCanvas={pixelCanvas}
              highlightedStartingPoint={highlightStartingPoint}
              highlightedIds={highlightedIds}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
