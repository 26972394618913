/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import { useState } from "react";
import { Form, Button, Modal } from "semantic-ui-react";
import { useUIType } from "../../../hooksqueries";
import useDatasetCreate from "./hooks/useDatasetCreate";
import { FormData } from "./types";

const defaultFormData: FormData = {
  name: "",
  description: "",
};

const isDataValid = (f: FormData) => f.name.length > 0;

type Props = {
  message?: string;
  visible: boolean;
  hide: () => void;
  onCreate?: () => void;
};

const DatasetAdd = ({ message, visible, hide, onCreate }: Props) => {
  const [formData, setFormData] = useState<FormData>(defaultFormData);
  const addDataset = useDatasetCreate();

  const save = async () => {
    await addDataset(formData);
    setFormData(defaultFormData);
    hide();
    if (onCreate) {
      onCreate();
    }
  };

  const setName = (name: string) => setFormData((data) => ({ ...data, name }));
  const setDescription = (description: string) =>
    setFormData((data) => ({ ...data, description }));

  const uiType = useUIType();

  return (
    <Modal onClose={hide} open={visible} as={Form} onSubmit={save}>
      <Modal.Header>
        Create New {uiType !== "halm" ? "Dataset" : "Portfolio"}
      </Modal.Header>
      {message && <Modal.Content>{message}</Modal.Content>}
      <Modal.Content>
        <Form.Field>
          <label>Name*</label>
          <Form.Input
            placeholder={`Name of ${
              uiType !== "halm" ? "Dataset" : "Portfolio"
            }`}
            value={formData.name}
            onChange={(_, { value }) => setName(value)}
          />
        </Form.Field>
        <Form.TextArea
          label="Description"
          placeholder={`An optional description of the new ${
            uiType !== "halm" ? "Dataset" : "Portfolio"
          } ...`}
          value={formData.description}
          onChange={(_, { value }) =>
            setDescription(value as unknown as string)
          }
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={!isDataValid(formData)}
          positive
          content={`Create ${uiType !== "halm" ? "Dataset" : "Portfolio"}`}
        />
        <Button onClick={hide} content="Cancel" />
      </Modal.Actions>
    </Modal>
  );
};

DatasetAdd.defaultProps = {
  message: undefined,
  onCreate: undefined,
};

export default DatasetAdd;
