/* eslint-disable react/jsx-curly-newline */
import { useEffect } from "react";
import {
  Dropdown,
  Table,
  Container,
  Divider,
  Header,
  Icon,
  Button,
} from "semantic-ui-react";
import { useListBackupsQuery } from "../../../../ts-clients/query";
import {
  useRestoreMutation,
  useBackupMutation,
  useDeleteBackupMutation,
} from "../../../../ts-clients/command";

import { formatDate } from "../../../../utils/helpers";

export default function Backups() {
  const { data, startPolling, stopPolling } = useListBackupsQuery();

  const [backup] = useBackupMutation();
  const [restore] = useRestoreMutation();
  const [deleteBackup] = useDeleteBackupMutation();

  useEffect(() => {
    startPolling(2000);
    return function cleanup() {
      stopPolling();
    };
  });

  return (
    <Container style={{ marginTop: "25px" }}>
      {/* HEADER */}
      <Header as="h1">
        Backups
        <Button
          positive
          floated="right"
          icon="plus"
          content="Create Backup"
          onClick={() => backup()}
        />
      </Header>
      <Divider />
      <Table compact celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data?.listBackups?.backups?.map((b) => (
            <Table.Row key={b?.id}>
              <Table.Cell>{b?.id}</Table.Cell>
              <Table.Cell>{formatDate(b?.createdAt).dateTime}</Table.Cell>
              <Table.Cell>
                <Dropdown icon="ellipsis horizontal" labeled floating button>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        restore({
                          variables: {
                            input: {
                              backupId: b?.id || "",
                            },
                          },
                        });
                      }}
                    >
                      <Icon name="edit" />
                      restore
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        deleteBackup({
                          variables: { input: { ids: [b?.id || ""] } },
                        })
                      }
                    >
                      <Icon name="trash" />
                      delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Container>
  );

  // return shared.renderEmpty();
}
