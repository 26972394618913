import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  ImageDatapointType,
  ResearchBoxType,
} from "../../../../types/extractedTypes";
import { MarkupModule } from "./types";

export default function useInputDatapoint(
  markupModule: MarkupModule | null,
  researchBox: ResearchBoxType | null | undefined,
  inputDatapointNames: string[]
) {
  const [datapoint, setDatapoint] = useState<ImageDatapointType>();

  useDeepCompareEffect(() => {
    if (
      researchBox &&
      markupModule &&
      inputDatapointNames.length > 0 &&
      researchBox.datapoints &&
      researchBox.datapoints.length > 0
    ) {
      const inputDatapoints =
        researchBox?.datapoints?.filter(
          (d) => inputDatapointNames.includes(d.producedBy.name) ?? ""
        ) || [];
      const inputDatapoint =
        inputDatapoints.length > 0
          ? inputDatapoints[0]
          : researchBox.datapoints[0];
      if (inputDatapoint.__typename === "Image") {
        setDatapoint(inputDatapoint);
      }
    }
  }, [researchBox, markupModule, inputDatapointNames]);

  return datapoint;
}
