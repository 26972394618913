/* eslint react/jsx-props-no-spreading: off */
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "../components/PrivateRoute";
import App from "./containers/App";
import Dashboard from "./containers/Dashboard";
import ResearchBoxesPage from "./containers/ResearchBoxesPage";
import DatasetOverview from "./containers/DatasetOverview";
import Admin from "./containers/Admin";
import DatasetSettings from "./containers/DatasetSettings";
import SensorImport from "./containers/SensorImport";
import FilesUpload from "./containers/FileUploadPage";
import FlowContainer from "./containers/FlowContainer";
import ResearchBoxDetails from "./containers/ResearchBoxDetails";
import DetailsDatapoint from "./containers/DetailsDatapoint";
import Analyze from "./containers/Analyze";
import Networks from "./containers/Networks";
import NetworkCreateWizard from "./containers/NetworkCreateWizard";
import SensorWarning from "../features/import-sensor/SensorWarning";
import {
  getRoutePattern,
  RouteName,
  usePageChecker,
} from "../utils/routeManager";
import { isSensorWarningDisplayable } from "../state/deviceControl";

import useAutoLogout from "../hooks/useAutoLogout";
import useLastLocation from "../hooks/useLastLocation";
import Login from "../features/login/Login";

const autoRoutes = new Map<RouteName, () => unknown>([
  ["DatasetOverview", DatasetOverview],
  ["ResearchBoxList", ResearchBoxesPage],
  ["DatasetSettings", DatasetSettings],
  ["SensorImport", SensorImport],
  ["FlowDesigner", FlowContainer],
  ["FlowDesignerAddModule", FlowContainer],
  ["FlowDesignerEditModule", FlowContainer],
  ["ResearchBoxDetails", ResearchBoxDetails],
  ["DetailsDatapoint", DetailsDatapoint],
  ["UploadFromFileSystem", FilesUpload],
  ["Dashboard", Dashboard],
  ["Analyze", Analyze],
  ["NetworkAdd", Networks],
  ["NetworkEdit", Networks],
  ["NetworkCreateWizard", NetworkCreateWizard],
  ["NetworkStats", Networks],
]);

export default function Routes() {
  const isOnPage = usePageChecker();
  const mayShowWarning = useSelector(isSensorWarningDisplayable);
  const showWarning = mayShowWarning && !isOnPage("sensorimport");
  useAutoLogout();
  const gotoLastLocation = useLastLocation();

  return showWarning ? (
    <SensorWarning />
  ) : (
    <App>
      <Switch>
        {Array.from(autoRoutes.keys()).map((r) => (
          <PrivateRoute
            exact
            key={r}
            path={getRoutePattern(r)}
            // @ts-ignore
            component={
              autoRoutes.get(r) || <Login gotoLastLocation={gotoLastLocation} />
            }
          />
        ))}

        <PrivateRoute // @ts-ignore
          path="/admin"
          component={Admin}
        />
        <Route
          path="/"
          component={() => <Login gotoLastLocation={gotoLastLocation} />}
        />
      </Switch>
    </App>
  );
}
