import { decode, IDecodedPNG } from "fast-png";

type BTComponent = Array<Array<number>>;

export type BTechComponents = {
  x: BTComponent;
  y: BTComponent;
  z: BTComponent;
  abs: BTComponent;
};

export const emptyBTechData: BTechComponents = { x: [], y: [], z: [], abs: [] };

const emptyPngPromise = new Promise<IDecodedPNG>((resolve) => {
  resolve({
    height: 0,
    width: 0,
    data: new Uint16Array(),
    channels: 1,
    depth: 8,
    text: {},
  });
});

const fetchImage = (url: string) => {
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buffer) => decode(buffer))
    .catch(() => emptyPngPromise);
};

const convertImageToBTechComponents =
  (subtractBaseline: boolean, startRow: number | undefined) =>
  (png: IDecodedPNG | void): BTechComponents => {
    if (!png) {
      return emptyBTechData;
    }
    const { width, height, data, text } = png;
    if (width === 0 || height === 0) {
      return emptyBTechData;
    }

    const baselinePresent = "calibration" in text;

    const baseline =
      subtractBaseline && baselinePresent
        ? new Float32Array(
            Uint8Array.from(atob(text.calibration), (c) =>
              c.charCodeAt(0)
            ).buffer
          )
        : new Float32Array();

    const b = new Int16Array(data);

    const resultx = new Array<Array<number>>(height - (startRow || 0));
    const resulty = new Array<Array<number>>(height - (startRow || 0));
    const resultz = new Array<Array<number>>(height - (startRow || 0));
    const abs = new Array<Array<number>>(height - (startRow || 0));

    for (let y = startRow || 0; y < height; y += 1) {
      resultx[y - (startRow || 0)] = new Array<number>(width);
      resulty[y - (startRow || 0)] = new Array<number>(width);
      resultz[y - (startRow || 0)] = new Array<number>(width);
      abs[y - (startRow || 0)] = new Array<number>(width);
      for (let x = 0; x < width; x += 1) {
        const idx = (y * width + x) * 3;
        resultx[y - (startRow || 0)][x] =
          subtractBaseline && baselinePresent
            ? (b[idx] - baseline[x * 3]) / 10.0
            : b[idx] / 10.0;
        resulty[y - (startRow || 0)][x] =
          subtractBaseline && baselinePresent
            ? (b[idx + 1] - baseline[x * 3 + 1]) / 10.0
            : b[idx + 1] / 10.0;
        resultz[y - (startRow || 0)][x] =
          subtractBaseline && baselinePresent
            ? (b[idx + 2] - baseline[x * 3 + 2]) / 10.0
            : b[idx + 2] / 10.0;
        abs[y - (startRow || 0)][x] = Math.sqrt(
          resultx[y - (startRow || 0)][x] ** 2 +
            resulty[y - (startRow || 0)][x] ** 2 +
            resultz[y - (startRow || 0)][x] ** 2
        );
      }
    }

    return { x: resultx, y: resulty, z: resultz, abs };
  };

export const getBTechData = (
  url: string,
  subtractBaseline: boolean,
  startRow: number | undefined
) =>
  fetchImage(url).then(
    convertImageToBTechComponents(subtractBaseline, startRow)
  );
