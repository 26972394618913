import { useRouteMatch, Switch, Route } from "react-router-dom";
import AddUser from "../../shared/AddUser";
import EditUser from "../../shared/EditUser";
import ListUsers from "./ListUsers";

export default function AdminUsers() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/addUser`} component={AddUser} />
      <Route path={`${path}/editUser/:username`} component={EditUser} />
      <Route exact path={path} component={ListUsers} />
    </Switch>
  );
}
