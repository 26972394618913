import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Divider, Header } from "semantic-ui-react";
import * as shared from "../../shared/noDataStates";
import {
  useAllModulesQuery,
  useQueryPretrainedNetworkQuery,
} from "../../../../ts-clients/query";
import { useCreateTokenMutation } from "../../../../ts-clients/command";
import { NetworkData, NetworkList } from "../../shared/networkList";
import { getCurrentUser, setCurrentUser } from "../../../../state/loginState";

export default function ListNetworks() {
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const [createTokenMutation] = useCreateTokenMutation();
  const { data, loading, error, refetch, startPolling, stopPolling } =
    useAllModulesQuery();

  useEffect(() => {
    startPolling(2000);
    return function cleanup() {
      stopPolling();
    };
  });

  useEffect(() => {
    createTokenMutation({
      variables: {
        input: {
          username: currentUser.username,
          role: currentUser.role,
          queryAll: true,
        },
      },
    }).then((token) => {
      dispatch(
        setCurrentUser({
          ...currentUser,
          token: token.data?.createToken?.token ?? "",
        })
      );
    });

    return function cleanup() {
      createTokenMutation({
        variables: {
          input: {
            username: currentUser.username,
            role: currentUser.role,
            queryAll: false,
          },
        },
      }).then((token) => {
        dispatch(
          setCurrentUser({
            ...currentUser,
            token: token.data?.createToken?.token ?? "",
          })
        );
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTokenMutation, currentUser.username, dispatch]);

  const {
    data: dataPretrained,
    loading: loadingPretrained,
    error: errorPretrained,
    refetch: refetchPretrained,
    startPolling: startPollingPretrained,
    stopPolling: stopPollingPretrained,
  } = useQueryPretrainedNetworkQuery();

  useEffect(() => {
    startPollingPretrained(2000);
    return function cleanup() {
      stopPollingPretrained();
    };
  });

  const pretrainedNetworks: NetworkData[] =
    dataPretrained?.queryPretrainedNetwork?.map((n) => ({
      type: n?.networkModule?.__typename ?? "",
      id: n?.id ?? "",
      name: n?.networkModule?.name ?? "",
      createdAt: n?.networkModule?.createdAt ?? "",
      updatedAt: n?.networkModule?.updatedAt ?? "",
      description: n?.networkModule?.description ?? "",
      owner: n?.networkModule?.owner ?? "",
      thumbnail: n?.thumbnail ?? "",
      parentId: n?.networkModule?.id ?? "",
    })) ?? [];

  const allowedTypes = [
    "PipelineModuleNetworkImageClassification",
    "PipelineModuleNetworkImageSegmentation",
    "PipelineModuleNetworkImagObjectDetection",
  ];
  const pretrainedNetworkIds = pretrainedNetworks.map((n) => n.parentId);

  const networks: NetworkData[] =
    data?.queryPipelineModule?.map((n) => ({
      type: n?.__typename ?? "",
      id: n?.id ?? "",
      name: n?.name ?? "",
      createdAt: n?.createdAt ?? "",
      updatedAt: n?.updatedAt ?? "",
      description: n?.description ?? "",
      owner: n?.owner ?? "",
      thumbnail: "",
      parentId: "",
    })) ?? [];

  const networksFiltered = networks.filter(
    (n) => allowedTypes.includes(n.type) && !pretrainedNetworkIds.includes(n.id)
  );

  const refetchAll = () => {
    refetch();
    refetchPretrained();
  };

  return (
    <Container style={{ marginTop: "25px" }}>
      <Header as="h1">Networks</Header>
      <Divider />

      <Header as="h3">Without Pretrained Network</Header>
      {loading && shared.renderLoading()}
      {error && shared.renderError(error)}
      {networksFiltered.length > 0 ? (
        <NetworkList
          networks={networksFiltered}
          pretrained={false}
          refetch={refetchAll}
        />
      ) : (
        shared.renderEmpty()
      )}
      <Header as="h3">With Pretrained Network</Header>
      {loadingPretrained && shared.renderLoading()}
      {errorPretrained && shared.renderError(errorPretrained)}
      {pretrainedNetworks.length > 0 ? (
        <NetworkList
          networks={pretrainedNetworks}
          pretrained
          refetch={refetchAll}
        />
      ) : (
        shared.renderEmpty()
      )}
    </Container>
  );
}
