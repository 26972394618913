import { encode } from "fast-png";
import { AddPoint2DInput } from "../../../../ts-clients/command";

const ColorMapping = (idx: number, value: number, rgba: number[]) => {
  const c = idx % 4;
  let newValue = 0;
  if (c === 3) {
    // newValue = Math.min(255, Math.round((rgba[c] * value) / 255.0)); // gradient preserving mapping
    newValue = value === 0 ? 0 : rgba[c]; // binary mapping
  } else {
    newValue = rgba[c];
  }
  return newValue;
};

export const canvasToDataURL = (
  canvas: HTMLCanvasElement,
  inputWasRGB = false
) => {
  const ctx = canvas.getContext("2d");
  if (ctx) {
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let adjustedData: Uint8ClampedArray;
    if (inputWasRGB) {
      adjustedData = imageData.data.map((_, idx) =>
        ColorMapping(
          idx,
          imageData.data[idx - (idx % 4)], // points to the next alpha channel
          [244, 57, 151, 224]
        )
      );
    } else {
      adjustedData = imageData.data.map((_, idx) =>
        ColorMapping(
          idx,
          imageData.data[idx + 3 - (idx % 4)], // points to the next alpha channel
          [244, 57, 151, 224]
        )
      );
    }
    const img = {
      width: canvas.width,
      height: canvas.height,
      data: adjustedData,
      channels: 4,
    };
    const b64 = `data:image/png;base64,${Buffer.from(encode(img)).toString(
      "base64"
    )}`;
    return b64;
  }
  return null;
};

export const canvasToDataURLBinary = (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext("2d");
  if (ctx) {
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const oneChannel = imageData.data.filter((_, idx) => idx % 4 === 0);
    const oneChannelBinary = oneChannel.map((v) => (v === 0 ? 0 : 255));
    const img = {
      width: canvas.width,
      height: canvas.height,
      data: oneChannelBinary,
      channels: 1,
    };
    return `data:image/png;base64,${Buffer.from(encode(img)).toString(
      "base64"
    )}`;
  }
  return null;
};

export type DrawMode = "draw" | "erase";

export const drawPolygon = (
  canvas: HTMLCanvasElement,
  points: AddPoint2DInput[],
  mode: DrawMode,
  drawColor: string,
  offset?: AddPoint2DInput,
  drawOnBlack?: boolean
) => {
  if (points.length < 1) {
    return;
  }
  const ctx = canvas.getContext("2d");
  if (ctx) {
    if (drawOnBlack) {
      ctx.fillStyle = "#000000";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
    ctx.fillStyle = drawColor;
    ctx.beginPath();
    let drawPoints = points;
    if (offset) {
      drawPoints = points.map((p) => ({
        x: p.x + offset.x,
        y: p.y + offset.y,
      }));
    }
    const p = drawPoints[0];
    ctx.moveTo(p.x, p.y);
    drawPoints.forEach((pt) => ctx.lineTo(pt.x, pt.y));
    ctx.globalCompositeOperation =
      mode === "erase" ? "destination-out" : "source-over";
    ctx.fill();
  }
};

export const removeConsecutiveDuplicates = (
  ps: AddPoint2DInput[]
): AddPoint2DInput[] =>
  ps.reduce((prev, cur) => {
    const last = prev[prev.length - 1] ? prev[prev.length - 1] : null;
    return last !== null && last.x === cur.x && last.y === cur.y
      ? prev
      : [...prev, cur];
  }, [] as AddPoint2DInput[]);

export const polygonToDataURL = (poly: AddPoint2DInput[]) => {
  const topLeft = poly.reduce(
    (prev, curr) => {
      const ret = { ...prev };
      if (curr.x < prev.x) {
        ret.x = curr.x;
      }
      if (curr.y < prev.y) {
        ret.y = curr.y;
      }
      return ret;
    },
    {
      x: Number.MAX_SAFE_INTEGER,
      y: Number.MAX_SAFE_INTEGER,
    }
  );
  const bottomRight = poly.reduce(
    (prev, curr) => {
      const ret = { ...prev };
      if (curr.x > prev.x) {
        ret.x = curr.x;
      }
      if (curr.y > prev.y) {
        ret.y = curr.y;
      }
      return ret;
    },
    {
      x: 0,
      y: 0,
    }
  );
  const canvas = document.createElement("canvas");
  canvas.width = Math.round(bottomRight.x) - Math.round(topLeft.x);
  canvas.height = Math.round(bottomRight.y) - Math.round(topLeft.y);
  drawPolygon(
    canvas,
    poly,
    "draw",
    "#FFFFFF",
    { x: -topLeft.x, y: -topLeft.y },
    true
  );
  const dataURL = canvasToDataURLBinary(canvas) || "";

  return { topLeft, bottomRight, dataURL };
};
