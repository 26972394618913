import { Point } from "MarkupTypes";
import uglyStoreGlobalStore from "../../../../state/uglyStore";

export default function useCursor() {
  const setCursor = (p: Point) => {
    uglyStoreGlobalStore.cursor = p;
  };
  const getCursor = () => uglyStoreGlobalStore.cursor;
  return { getCursor, setCursor };
}
