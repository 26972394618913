/* eslint-disable react/jsx-curly-newline */
import { useEffect, useState } from "react";
import { Button, Form, Grid, Image, Segment, Message } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import useLogin from "./hooks";
import login_logo from "../../resources/halm-black.png";
import { isInvalid, isLoggedIn, setLoginState } from "../../state/loginState";
import useUIConfig from "../../state/useUIConfig";
import { useGoto } from "../../utils/routeManager";

type Props = {
  gotoLastLocation: () => void;
};

export default function Login({ gotoLastLocation }: Props) {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("user");
  const [password, setPassword] = useState("useruser");

  const login = useLogin(username, password);
  const loggedIn = useSelector(isLoggedIn);
  const { getRememberLastUrl } = useUIConfig();
  const goTo = useGoto();

  useEffect(() => {
    if (loggedIn) {
      if (getRememberLastUrl() === true) {
        gotoLastLocation();
      } else {
        goTo("Dashboard", {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  const invalid = useSelector(isInvalid);

  return (
    <div className="loginpage" style={{ height: "100vh" }}>
      <Grid
        columns={3}
        centered
        verticalAlign="middle"
        style={{ height: "96vh", margin: 0 }}
      >
        <Grid.Column>
          <Segment attached={invalid ? "top" : undefined}>
            <Image src={login_logo} style={{ height: "80px" }} />
            <br />
            <p style={{ fontSize: "14pt" }}>Log in to your DENKwelt Account:</p>
            <Form size="large" onSubmit={login}>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="Username"
                value={username}
                onChange={(_e, { value }) => setUsername(value)}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(_e, { value }) => setPassword(value)}
              />
              <br />
              <Button
                type="submit"
                color="green"
                size="large"
                fluid
                content="Login"
              />
              <br />
            </Form>
          </Segment>
          {invalid && (
            <Message
              attached="bottom"
              onDismiss={() => dispatch(setLoginState("none"))}
              negative
              content="Username or Password is wrong."
            />
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
}
