import uglyStore from "../../../../state/uglyStore";

export default function useCircleRadius() {
  const increaseCircleRadius = () => {
    uglyStore.smoothvalue.up = Date.now();
  };
  const decreaseCircleRadius = () => {
    uglyStore.smoothvalue.down = Date.now();
  };
  return { decreaseCircleRadius, increaseCircleRadius };
}
