/* eslint-disable react/no-unused-prop-types */
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getPath, DatasetSettingsPage } from "../../utils/routeManager";

type Props = {
  datasetId: string;
  active: "base" | "visualisation" | "ui";
};

type ItemData = {
  name: string;
  active: boolean;
  link: string;
};

const urlNames: Array<{ url: DatasetSettingsPage; name: string }> = [
  { url: "base", name: "Base Settings" },
  { url: "visualisation", name: "Visualisation" },
];

const makeLinks = ({ datasetId, active }: Props): Array<ItemData> =>
  urlNames.map((u) => ({
    name: u.name,
    active: active === u.url,
    link: getPath("DatasetSettings", { datasetId, page: u.url }),
  }));

const makeMenuItem = ({ link, active, name }: ItemData) => (
  <Menu.Item key={link} as={Link} to={link} active={active} content={name} />
);

export default function DatasetSettingsMenu(props: Props) {
  return (
    <>
      <h1>Dataset Settings</h1>
      <Menu>{makeLinks(props).map(makeMenuItem)}</Menu>
    </>
  );
}
