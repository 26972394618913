import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SensorState } from "DUG";
import { SensorImportParams } from "../utils/routeManager";
import { RootState } from "./store";

type FileNamePart = {
  id: string;
  type: "string" | "counter";
  value: string;
};

type StateType = {
  devicePath: string | null;
  sensorId: string | null;
  fileNameParts: FileNamePart[];
  currentScanId: string | undefined;
  currentResearchBoxId: string | undefined;
  validScan: boolean;
  sensorState: SensorState;
  routeInfo: SensorImportParams;
};

const name = "deviceControl";
const initialState: StateType = {
  devicePath: null,
  sensorId: null,
  fileNameParts: [],
  currentScanId: undefined,
  currentResearchBoxId: undefined,
  validScan: false,
  sensorState: "idle",
  routeInfo: {
    datasetId: "",
    context: "sensorimport",
  },
};

const appSlice = createSlice({
  name,
  initialState,
  reducers: {
    setDevicePath: (state, action: PayloadAction<string | null>) => ({
      ...state,
      devicePath: action.payload,
    }),
    setSensorId: (state, action: PayloadAction<string | null>) => ({
      ...state,
      sensorId: action.payload,
    }),
    setFileNameParts: (state, action: PayloadAction<FileNamePart[]>) => ({
      ...state,
      fileNameParts: action.payload,
    }),
    setCurrentScanId: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      currentScanId: action.payload,
    }),
    setCurrentResarchBoxId: (
      state,
      action: PayloadAction<string | undefined>
    ) => ({
      ...state,
      currentResearchBoxId: action.payload,
    }),
    setValidScan: (state, action: PayloadAction<boolean>) => ({
      ...state,
      validScan: action.payload,
    }),
    setSensorState: (state, action: PayloadAction<SensorState>) => ({
      ...state,
      sensorState: action.payload,
    }),
    setRouteInfo: (state, action: PayloadAction<SensorImportParams>) => ({
      ...state,
      routeInfo: action.payload,
    }),
  },
});

export const {
  setDevicePath,
  setSensorId,
  setFileNameParts,
  setCurrentScanId,
  setCurrentResarchBoxId,
  setValidScan,
  setSensorState,
  setRouteInfo,
} = appSlice.actions;

export default appSlice.reducer;

export const getDevicePath = (state: RootState) =>
  state.deviceControl.devicePath;

export const getFileNameParts = (state: RootState) =>
  state.deviceControl.fileNameParts;

export const getFileName = (state: RootState) =>
  state.deviceControl.fileNameParts.map((d) => d.value).join("-");

export const getSensorId = (state: RootState) => state.deviceControl.sensorId;

export const getCurrentScanId = (state: RootState) =>
  state.deviceControl.currentScanId;

export const getCurrentResearchBoxId = (state: RootState) =>
  state.deviceControl.currentResearchBoxId;

export const getSensorState = (state: RootState) =>
  state.deviceControl.sensorState;

export const getRouteInfo = (state: RootState) => state.deviceControl.routeInfo;

export const isSensorWarningDisplayable = (state: RootState) =>
  state.deviceControl.sensorState !== "idle" &&
  state.deviceControl.routeInfo.datasetId.length > 0;

export const isConnected = (state: RootState) =>
  state.deviceControl.sensorId != null;

export const isValidScan = (state: RootState) => state.deviceControl.validScan;
