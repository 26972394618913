/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
import { Grid, Table, Card } from "semantic-ui-react";
import "./style.css";

type Group = "annotation" | "navigation" | "editing";
type KeyDesc = {
  desc: string;
  key: string;
};

type GroupDesc = {
  group: Group;
  headline: string;
};

type KeyMap = Map<Group, KeyDesc[]>;

const keyMap: KeyMap = new Map([
  [
    "annotation",
    [
      { desc: "Select Circle Tool", key: "C" },
      { desc: "Select Sausage Tool", key: "S" },
      { desc: "Select Free Form Tool", key: "F" },
      { desc: "Select Magic Wand Tool", key: "M" },
      { desc: "Select Polygon Tool", key: "P" },
      { desc: "Select Bounding Box Tool", key: "O" },
      {
        desc: "Select Rectangle Tool",
        key: "R",
      },
      {
        desc: "Select Highlighting Tool",
        key: "I",
      },
    ],
  ],
  [
    "navigation",
    [
      { desc: "Undo", key: "Ctrl-Z" },
      { desc: "Redo", key: "Ctrl-Y" },
      {
        desc: "Delete all annotations on this image (not implemented yet)",
        key: "Ctrl-Backspace",
      },
      {
        desc: "Open a label with an index from 1 to 10",
        key: "{1..9..0}",
      },
      {
        desc: "Open a label with an index from 10 to 20",
        key: "Shift-{1..9..0}",
      },
      { desc: "Activate positive/negative/ignore markup mode", key: "Ctrl-N" },
      {
        desc: "Save and go to next image",
        key: "Ctrl-Enter",
      },
    ],
  ],
  [
    "editing",
    [
      { desc: "Delete the currently selected annotation", key: "Del" },
      { desc: "Toggle visibility of markup", key: "V" },
      { desc: "Reset zoom", key: "Z" },
      { desc: "Toggle edit/delete mode in pixel edit mode", key: "T" },
      {
        desc: "Enable pixel editing mode for currently selected annotation",
        key: "E",
      },
      { desc: "Toggle cursor visibility", key: "U" },
      { desc: "Show this help screen", key: "H" },
      { desc: "finish editing", key: "Enter" },
      { desc: "Cancel editing", key: "Esc" },
    ],
  ],
]);

const groups: Array<GroupDesc> = [
  {
    group: "annotation",
    headline: "Shortcuts for Annotation Creation Tools",
  },
  {
    group: "editing",
    headline: "Shortcuts while Editing",
  },
  {
    group: "navigation",
    headline: "Shortcuts for Naviation",
  },
];

export default function Shortcuts() {
  return (
    <div style={{ minHeight: 770, padding: "15px 0" }}>
      <Grid columns="2">
        {groups.map((g) => (
          <Grid.Column key={g.group}>
            {(keyMap.get(g.group) ?? []).length > 0 ? (
              <Card
                fluid
                header={g.headline}
                description={
                  <Table>
                    <Table.Body>
                      {(keyMap.get(g.group) ?? []).map((k) => (
                        <Table.Row key={k.key}>
                          <Table.Cell
                            style={{ width: "auto", whiteSpace: "nowrap" }}
                          >
                            <kbd>{k.key}</kbd>
                          </Table.Cell>
                          <Table.Cell style={{ width: "100%" }}>
                            {k.desc}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                }
                style={{ backgroundColor: "#f9fafb" }}
              />
            ) : null}
          </Grid.Column>
        ))}
      </Grid>
    </div>
  );
}
