import { useReducer, useCallback, useMemo } from "react";
import { MyAnnotations } from "./types";

type UndoState = {
  markupLabelId: string;
  markupLabelColor: string;
  annotationData: MyAnnotations;
};

interface Actions<T> {
  set: (newPresent: T) => void;
  replace: (newPresent: T) => void;
  reset: (newPresent: T) => void;
  undo: () => void;
  redo: () => void;
}

type Meta = {
  canUndo: boolean;
  canRedo: boolean;
  pastSteps: number;
  futureSteps: number;
};

interface State<T> {
  past: T[];
  present: T;
  future: T[];
}

const UNDO = "UNDO";
const REDO = "REDO";
const SET = "SET";
const REPLACE = "REPLACE";
const RESET = "RESET";

const initialState = {
  past: [],
  present: null,
  future: [],
};

type Action<T> =
  | { type: "UNDO" }
  | { type: "REDO" }
  | { type: "RESET"; newPresent: T }
  | { type: "SET"; newPresent: T }
  | { type: "REPLACE"; newPresent: T };

const reducer = (
  state: State<UndoState>,
  action: Action<UndoState>
): State<UndoState> => {
  const { past, present, future } = state;

  switch (action.type) {
    case UNDO: {
      const previous = past[past.length - 1];
      const newPast = past.slice(0, past.length - 1);

      return {
        past: newPast,
        present: previous,
        future: [present, ...future],
      };
    }

    case REDO: {
      const next = future[0];
      const newFuture = future.slice(1);

      return {
        past: [...past, present],
        present: next,
        future: newFuture,
      };
    }

    case SET: {
      const { newPresent } = action;

      if (newPresent === present) {
        return state;
      }
      return {
        past: [...past, present],
        present: newPresent,
        future: [],
      };
    }

    case REPLACE: {
      const { newPresent } = action;

      if (newPresent === present) {
        return state;
      }

      return {
        past,
        present: newPresent,
        future: [],
      };
    }

    case RESET: {
      const { newPresent } = action;

      return {
        past: [],
        present: newPresent,
        future: [],
      };
    }
    default:
      return state;
  }
};

// export default function useUndo<T>(initialPresent: T): [State<T>, Actions<T>];

const useUndo = (
  initialPresent: UndoState
): [State<UndoState>, Actions<UndoState>, Meta] => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    present: initialPresent,
  });

  const canUndo = state.past.length !== 0;
  const canRedo = state.future.length !== 0;
  const undo = useCallback(() => {
    if (canUndo) {
      dispatch({ type: UNDO });
    }
  }, [canUndo]);
  const redo = useCallback(() => {
    if (canRedo) {
      dispatch({ type: REDO });
    }
  }, [canRedo]);
  const set = useCallback(
    (newPresent) => dispatch({ type: SET, newPresent }),
    []
  );
  const replace = useCallback(
    (newPresent) => dispatch({ type: REPLACE, newPresent }),
    []
  );
  const reset = useCallback(
    (newPresent) => dispatch({ type: RESET, newPresent }),
    []
  );

  const pastSteps = state.past.length;
  const futureSteps = state.future.length;

  const actions = useMemo(
    () => ({ set, reset, replace, undo, redo }),
    [redo, replace, reset, set, undo]
  );

  const meta = useMemo(
    () => ({
      canUndo,
      canRedo,
      pastSteps,
      futureSteps,
    }),
    [canRedo, canUndo, futureSteps, pastSteps]
  );

  return [state, actions, meta];
};

export default useUndo;
