import { Header, Divider } from "semantic-ui-react";
import { DataType } from "../../../ts-clients/query";
import SettingsViewParts from "./parts";
import { getPartsForDataTypes } from "./helper";

export default function SettingsViewForSensorImport() {
  const partList = getPartsForDataTypes([DataType.MagneticFieldScan]);
  return (
    <>
      <Header as="h4">View Settings</Header>
      <Divider />
      <SettingsViewParts parts={partList} resolutions={[]} context="dataset" />
    </>
  );
}
