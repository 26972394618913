import { useDrop } from "react-dnd";
import {
  useMoveFolderMutation,
  useMoveResearchBoxMutation,
} from "../../ts-clients/command";
import { ResearchBoxOrFolderType } from "../../types/extractedTypes";

type Props = {
  id: string;
  name: string;
  isDataset: boolean;
  onClick?: () => void;
};

export default function BreadcrumbSection({
  id,
  name,
  isDataset,
  onClick,
}: Props) {
  const [moveFolder] = useMoveFolderMutation();
  const [moveResearchBox] = useMoveResearchBoxMutation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ["folder", "researchbox"],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    drop: (item: ResearchBoxOrFolderType, _monitor) => {
      if (item.__typename === "Folder") {
        moveFolder({
          variables: {
            input: {
              folderIds: [item.id],
              target: {
                folderId: isDataset ? undefined : id,
                datasetId: isDataset ? id : undefined,
              },
            },
          },
        });
      } else {
        moveResearchBox({
          variables: {
            input: {
              researchBoxIds: [item.id],
              target: {
                folderId: isDataset ? undefined : id,
                datasetId: isDataset ? id : undefined,
              },
            },
          },
        });
      }
    },
    collect: (monitor) => {
      return {
        canDrop: monitor.canDrop() && !!onClick,
        isOver: monitor.isOver() && monitor.canDrop() && !!onClick,
      };
    },
  }));

  if (onClick === undefined) {
    return (
      <div ref={drop} className="active section">
        {name}
      </div>
    );
  }

  return (
    <>
      <a
        ref={drop}
        className={canDrop ? "active section" : "section"}
        onClick={onClick}
        style={isOver ? { color: "violet" } : undefined}
      >
        {name}
      </a>
      {!!onClick && <i aria-hidden className="right angle icon divider"></i>}
    </>
  );
}
