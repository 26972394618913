import { Header, Table } from "semantic-ui-react";
import { NetworkStats } from "../../../../../../components/menus/settings-view/hooks/useDenkBox";

type Props = {
  stats: NetworkStats;
};

export default function Stats({ stats }: Props) {
  const { trainingCount, validationCount } = stats;
  return (
    <>
      <Header as="h5">Annotations</Header>
      <Table celled style={{ background: "rgba(0,0,0,0)" }} compact>
        <Table.Body>
          <Table.Row>
            <Table.Cell collapsing># in trainings set</Table.Cell>
            <Table.Cell collapsing textAlign="right">
              {`${trainingCount}`}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing># in validation set</Table.Cell>
            <Table.Cell collapsing textAlign="right">
              {`${validationCount}`}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}
