import { Modal, Button, Tab } from "semantic-ui-react";

import ChangePassword from "./ChangePassword";
import ChangeUI from "./ChangeUI";
import ChangeProfile from "./ChangeProfile";

export type Props = {
  handleClose: () => void;
  isModalOpen: boolean;
};

const panes = [
  {
    menuItem: "My Profile",
    render: function ChangeProfileItem() {
      return (
        <Tab.Pane>
          <ChangeProfile />
        </Tab.Pane>
      );
    },
  },

  {
    menuItem: "My Password",
    render: function ChangePasswordItem() {
      return (
        <Tab.Pane>
          <ChangePassword />
        </Tab.Pane>
      );
    },
  },

  {
    menuItem: "My UI Settings",
    render: function ChangeUIItem() {
      return (
        <Tab.Pane>
          <ChangeUI />
        </Tab.Pane>
      );
    },
  },
];

export default function UserSettingsModal({ handleClose, isModalOpen }: Props) {
  return (
    <Modal onClose={handleClose} open={isModalOpen}>
      <Modal.Header>User Settings</Modal.Header>
      <Modal.Content style={{ minHeight: 450 }}>
        <Tab panes={panes} />
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={handleClose} content="close" />
      </Modal.Actions>
    </Modal>
  );
}
