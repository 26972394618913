import { useEffect } from "react";
import { Container, Divider, Header, Icon } from "semantic-ui-react";
import { Link, useRouteMatch } from "react-router-dom";
import * as shared from "../../shared/noDataStates";
import { useAllUsersQuery } from "../../../../ts-clients/query";
import MemberList from "../../shared/userList";

export default function ListMembers() {
  const { url } = useRouteMatch();
  const { data, loading, error, startPolling, stopPolling } =
    useAllUsersQuery();

  useEffect(() => {
    startPolling(1000);
    return function cleanup() {
      stopPolling();
    };
  });

  return (
    <Container style={{ marginTop: "25px" }}>
      {/* HEADER */}
      <Header as="h1">
        Users
        <Link
          className="ui positive right floated button"
          to={`${url}/addUser`}
        >
          <Icon name="plus" />
          Add User
        </Link>
      </Header>
      <Divider />

      {/* MEMBER LIST */}
      {loading && shared.renderLoading()}
      {error && shared.renderError(error)}
      {data && data.queryUser && data.queryUser.length ? (
        <MemberList
          users={data.queryUser.map(
            (u) =>
              ({
                username: u?.username || "",
                createdAt: u?.createdAt || "",
                email: u?.email || "",
                role: u?.role || "",
                name: u?.name,
                surname: u?.surname,
                phone: u?.phone,
                vendorName: undefined,
              } ?? [])
          )}
        />
      ) : (
        shared.renderEmpty()
      )}
    </Container>
  );
}
