/* eslint-disable react/jsx-curly-newline */
import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { MarkupLabel } from "MarkupTypes";
import useImageSize from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks/useImageSize";
import useSaveObjectDetection from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks-backend/useSaveObjectDetection";
import useAnnotations from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks/useAnnotations2";
import usePixels from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks/usePixels";
import useGetAnnotations from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks-backend/useGetObjectDetectionAnnotations";
import useInteractionHooks from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks/useInteractionHooks";
import { getMarkupReloadTrigger } from "../redux-state";
import useInteractions from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks/useInteractions";
import useCurrentData from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks/useCurrentData";
import useMarkuptoolState from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks/useMarkuptoolState";
import { NavigationProps } from "../../../utils";
import { ImageDatapointType } from "../../../../../types/extractedTypes";
import uglyStoreGlobalStore from "../../../../../state/uglyStore";
import useHighlighing from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks/useHighlighing";
import useGetSelectedImageResolution from "../../../../../hooks/useGetSelectedImageResolution";
import { useDeleteObjectDetectionMarkupMutation } from "../../../../../ts-clients/command";

type Props = {
  image: ImageDatapointType;
  labelList: MarkupLabel[];
  navigation: NavigationProps;
  networkId: string;
};

export default function useMarkupTool({
  image,
  labelList,
  navigation,
  networkId,
}: Props) {
  const { markup, refetch, subset } = useGetAnnotations(image.id, networkId);
  const imageSize = useImageSize(image);
  const initialMarkupLabelId = labelList.length > 0 ? labelList[0].id : "";
  const initialMarkupLabelColor =
    labelList.length > 0 ? labelList[0].color : "";
  const loadedAnnotations = markup?.annotations;

  const { selectedResolution } = useGetSelectedImageResolution({ image });

  const saveObjectDetection = useSaveObjectDetection({
    imageId: image.id,
    networkId,
    imageHeight: image.height,
    imageWidth: image.width,
    markupHeight: selectedResolution?.height || image.height,
    markupWidth: selectedResolution?.width || image.width,
  });

  const markupReloadTrigger = useSelector(getMarkupReloadTrigger);
  useEffect(() => {
    refetch();
  }, [markupReloadTrigger, refetch]);

  const {
    annotations,
    annotationActions,
    undoActions,
    undoMeta,
    segmentationsVisible,
    lastPredictionTime,
  } = useAnnotations(initialMarkupLabelId, initialMarkupLabelColor);

  const { paintActive, pixelActions, pixelCanvas } = usePixels(
    imageSize,
    annotationActions
  );

  const { callHook, setHook } = useInteractionHooks();

  useEffect(() => {
    annotationActions.setMarkupLabelIdAndColor(
      initialMarkupLabelId,
      initialMarkupLabelColor,
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMarkupLabelId]);

  // should change, once the annotations are loaded
  const loadHint = loadedAnnotations ? markup?.markupId ?? "" : null;
  useEffect(() => {
    if (loadedAnnotations === undefined) {
      annotationActions.resetAnnotations();
    } else {
      annotationActions.replaceAnnotations(loadedAnnotations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadHint, image?.id, loadedAnnotations]);

  const [deleteMarkup] = useDeleteObjectDetectionMarkupMutation({
    variables: {
      input: { ids: [markup?.markupId || ""] },
    },
  });

  const saveAndNext = useCallback(() => {
    saveObjectDetection(annotations.annotationData);
    if (navigation.hasNext) {
      navigation.goNext();
    } else {
      setTimeout(() => navigation.goPage("back"), 200);
    }
  }, [annotations.annotationData, navigation, saveObjectDetection]);

  const clearAndNext = useCallback(() => {
    deleteMarkup();
    if (navigation.hasNext) {
      navigation.goNext();
    } else {
      navigation.goPage("back");
    }
  }, [deleteMarkup, navigation]);

  const { magicWandThreshold, sausageSize } = useMarkuptoolState();

  const [currentData, currentDataActions, currentDataChangedByUser] =
    useCurrentData(
      uglyStoreGlobalStore.circleRadius,
      magicWandThreshold,
      sausageSize,
      annotations.markupLabelId,
      annotations.markupLabelColor
    );

  useEffect(() => {
    pixelActions.paintReset();
    currentDataActions.reset();
    annotationActions.resetUndo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image?.id]);

  const [highlighingActions, highlightedIds, highlightStartingPoint] =
    useHighlighing(annotations.annotationData);

  const { updateState, trigger } = useInteractions({
    annotationActions,
    annotations,
    paintActive,
    callHook,
    highlighingActions,
    highlightedIds,
    currentData,
    currentDataActions,
    undoActions,
    pixelActions,
    labelList,
    currentDataChangedByUser,
  });

  const reloadMarkup = () => refetch();

  return {
    annotationActions,
    annotations,
    callHook,
    clearAndNext,
    currentData,
    currentDataActions,
    currentDataChangedByUser,
    paintActive,
    pixelActions,
    pixelCanvas,
    reloadMarkup,
    saveAndNext,
    segmentationsVisible,
    lastPredictionTime,
    subset,
    setHook,
    trigger,
    undoMeta,
    updateState,
    highlightedIds,
    highlightStartingPoint,
  };
}
