import { useCallback, useEffect, useState } from "react";

export default function useFakeProgress(
  duration: number,
  changeMeToReset: number
) {
  const [progress, setProgress] = useState(0);

  const advance = useCallback(() => {
    setProgress((oldProgress) =>
      oldProgress < duration ? oldProgress + 1 : oldProgress
    );
  }, [duration]);

  useEffect(() => {
    const timer = setTimeout(advance, 1000);
    return () => clearTimeout(timer);
  }, [advance, progress]);

  const reset = () => setProgress(0);

  useEffect(reset, [changeMeToReset]);

  return { progress, reset };
}
