import Plot from "react-plotly.js";
import { useCallback, useState } from "react";

type ViewType = "basic" | "details";

export const DashTypes = [
  "solid",
  "dot",
  "dash",
  "longdash",
  "dashdot",
  "longdashdot",
];

type DashType = typeof DashTypes[number];

export type ChartMetric = {
  name: string;
  color?: string;
  labelName?: string;
  dash: DashType;
  history: number[];
};

type Props = {
  metrics: ChartMetric[];
  viewType: ViewType;
};

export default function MetricsChart({ metrics, viewType }: Props) {
  const data: Plotly.PlotData[] = metrics
    .map((m) => {
      const datum = {
        name: `${m.name}${m.labelName ? " [" + m.labelName + "]" : ""}`,
        y: m.history.map((value) => (value * 100).toPrecision(2)),
        line: {
          shape: "spline",
          dash: m.dash,
        },
        hoverinfo: "y+name",
      } as Plotly.PlotData;

      if (m.color) {
        datum.marker = {
          color: m.color,
        };
      }

      return datum;
    })
    .flat(1);

  const [layout, setLayout] = useState<Partial<Plotly.Layout>>({
    yaxis: {
      autorange: true,
    },
    xaxis: {
      showticklabels: false,
    },
    margin: {
      l: 30,
      r: 0,
      b: 20,
      t: 0,
      pad: 4,
    },
    width: 150,
    height: 260,
    legend: { orientation: "h" },
    plot_bgcolor: "rgba(255, 255, 255, 0);",
    paper_bgcolor: "rgba(255, 255, 255, 0);",
  });

  const measureWidth = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setLayout({
        yaxis: {
          autorange: true,
        },
        xaxis: {
          showticklabels: false,
        },
        margin: {
          l: 30,
          r: 0,
          b: 20,
          t: 0,
          pad: 4,
        },
        width: node.offsetWidth,
        height: 260,
        legend: { orientation: "h" },
        plot_bgcolor: "rgba(255, 255, 255, 0);",
        paper_bgcolor: "rgba(255, 255, 255, 0);",
      });
    }
  }, []);

  return (
    <div ref={measureWidth} style={{ width: "100%" }}>
      <Plot
        data={data}
        layout={layout}
        config={{
          displayModeBar: viewType === "basic" ? false : true,
          displaylogo: false,
        }}
      />
    </div>
  );
}
