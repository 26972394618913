/* eslint-disable react/jsx-curly-newline */
import { useState } from "react";
import { init } from "ramda";
import { useSelector, useDispatch } from "react-redux";
import {
  Header,
  Button,
  Label,
  Form,
  Grid,
  Input,
  Segment,
  Modal,
  Message,
} from "semantic-ui-react";
import cuid from "cuid";

import { useCheckResearchBoxLabelQuery } from "../../ts-clients/query";

import {
  getFileNameParts,
  getFileName,
  setFileNameParts,
} from "../../state/deviceControl";

type FileNamePart = {
  id: string;
  type: "string" | "counter";
  value: string;
};

const defaultFileNamePart: FileNamePart = {
  id: cuid(),
  type: "string",
  value: "",
};

export default function FileNamingSchema() {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState<FileNamePart>(defaultFileNamePart);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFileNameParts, setEditFileNameParts] = useState(false);
  const fileNameParts = useSelector(getFileNameParts);
  const [currentFileNamePart, setCurrentFileNamePart] =
    useState<FileNamePart>(defaultFileNamePart);

  const currentFilename = useSelector(getFileName);

  const { data: researchBoxWithLabelCount } = useCheckResearchBoxLabelQuery({
    variables: {
      label: currentFilename,
    },
    fetchPolicy: "no-cache",
  });

  const [warningVisible, setWarningVisible] = useState(true);
  const [emptyFilenameWarningVisible, setEmptyFilenameWarningVisible] =
    useState(true);

  // const [ns] = useGetNamingSchemeQuery();
  const updatePart = (parts: Array<FileNamePart>, newpart: FileNamePart) =>
    dispatch(
      setFileNameParts(
        parts
          .map((oldpart) => (oldpart.id === newpart.id ? newpart : oldpart))
          .filter((p) => p.value.length > 0)
      )
    );

  return (
    <>
      <Segment.Group>
        <Segment style={{ backgroundColor: "#f4f4f4" }}>
          <Header as="h2">
            Filename
            <Button
              floated="right"
              content="change"
              icon="edit"
              color="blue"
              size="small"
              style={{ display: editFileNameParts ? "none" : "block" }}
              onClick={() => setEditFileNameParts(!editFileNameParts)}
            />
          </Header>
        </Segment>
        <Segment>
          <Label.Group size="massive">
            {fileNameParts.length ? (
              fileNameParts.map((part) => (
                <Label
                  key={part.id}
                  content={part.value}
                  color={part.type === "string" ? "teal" : "pink"}
                  onClick={() => {
                    setEditing(part);
                    setShowEditModal(true);
                  }}
                />
              ))
            ) : (
              <Label content="nothing defined" />
            )}
            {editFileNameParts && fileNameParts.length > 0 && (
              <Button
                icon="arrow circle left"
                content="clear last"
                size="huge"
                negative
                onClick={() => dispatch(setFileNameParts(init(fileNameParts)))}
              />
            )}
          </Label.Group>
        </Segment>
        <Segment
          attached="bottom"
          style={{ display: editFileNameParts ? "block" : "none" }}
        >
          <Grid>
            <Grid.Column width="8">
              <Input
                fluid
                size="massive"
                value={currentFileNamePart.value}
                onChange={(e) =>
                  setCurrentFileNamePart({
                    ...currentFileNamePart,
                    value: e.currentTarget.value,
                  })
                }
                action={{
                  color: "teal",
                  labelPosition: "left",
                  icon: "font",
                  size: "massive",
                  content: "add string",
                  disabled: currentFileNamePart.value.toString().length === 0,
                  onClick: () => {
                    dispatch(
                      setFileNameParts([
                        ...fileNameParts,
                        {
                          id: cuid(),
                          type: "string",
                          value: currentFileNamePart.value,
                        },
                      ])
                    );
                    setCurrentFileNamePart({
                      ...currentFileNamePart,
                      value: "",
                    });
                  },
                }}
              />
            </Grid.Column>
            <Grid.Column width="4">
              <Button
                content="add counter"
                color="pink"
                size="massive"
                icon="plus circle"
                onClick={() =>
                  dispatch(
                    setFileNameParts([
                      ...fileNameParts,
                      { id: cuid(), type: "counter", value: "0" },
                    ])
                  )
                }
              />
            </Grid.Column>
            <Grid.Column width="4">
              <Button
                content="finished"
                positive
                floated="right"
                icon="save"
                size="massive"
                onClick={() => setEditFileNameParts(!editFileNameParts)}
              />
            </Grid.Column>
          </Grid>
        </Segment>
        {(researchBoxWithLabelCount?.aggregateResearchBox?.count || 0) > 0 &&
          warningVisible && (
            <Message
              warning
              header="Warning!"
              content={`There are records in your database that have the same label '${currentFilename}'! If this is intentional, you can ignore this warning.`}
              onDismiss={() => setWarningVisible(false)}
            />
          )}
        {currentFilename === "" && emptyFilenameWarningVisible && (
          <Message
            warning
            header="Warning!"
            content={`You haven't defined a filename yet! If this is intentional, you can ignore this warning.`}
            onDismiss={() => setEmptyFilenameWarningVisible(false)}
          />
        )}
      </Segment.Group>

      <Modal
        onClose={() => setShowEditModal(false)}
        closeOnEscape
        closeOnDimmerClick
        open={showEditModal}
        size="tiny"
      >
        <Modal.Header>Change Value</Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={() => {
              updatePart(fileNameParts, editing);
              setShowEditModal(false);
            }}
          >
            {editing.type === "string" && (
              <Form.Input
                value={editing.value}
                fluid
                onChange={(e) =>
                  setEditing({ ...editing, value: e.target.value })
                }
                size="massive"
                action={{
                  color: "teal",
                  content: "update",
                  size: "massive",
                  onClick: () => {
                    updatePart(fileNameParts, editing);
                    setShowEditModal(false);
                  },
                }}
              />
            )}
            {editing.type === "counter" && (
              <Form.Input
                type="number"
                value={editing.value}
                fluid
                onChange={(e) =>
                  setEditing({ ...editing, value: e.target.value })
                }
                size="massive"
                action={{
                  color: "teal",
                  content: "update",
                  size: "massive",
                  onClick: () => {
                    updatePart(fileNameParts, editing);
                    setShowEditModal(false);
                  },
                }}
              />
            )}
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
}
