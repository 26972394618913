import {
  useDeleteNotificationMutation,
  useMarkNotificationAsReadMutation,
} from "../../../ts-clients/command";
import { useQueryUnreadNotificationsQuery } from "../../../ts-clients/query";

export function useMarkAllAsRead(notificationIds: string[]) {
  const [markAsRead] = useMarkNotificationAsReadMutation();
  return () =>
    markAsRead({
      variables: {
        input: { notificationIds },
      },
    });
}

export function useMarkAsRead() {
  const [markAsRead] = useMarkNotificationAsReadMutation();
  return (notificationId: string) =>
    markAsRead({
      variables: {
        input: { notificationIds: [notificationId] },
      },
    });
}

export function useNotifications() {
  const { data } = useQueryUnreadNotificationsQuery({ pollInterval: 5000 });
  const ns = data?.queryNotification ?? [];
  return ns.flatMap((n) =>
    n?.__typename === "OneOffNotification" ||
    n?.__typename === "ProgressNotification" ||
    n?.__typename === "DenktesterNotification" ||
    n?.__typename === "MaintenanceNotification"
      ? n
      : []
  );
}

export function useDeleteNotification() {
  const [deleteNotification] = useDeleteNotificationMutation();
  return (notificationId: string) =>
    deleteNotification({
      variables: { input: { ids: [notificationId] } },
    });
}

export function useDeleteNotifications(notificationIds: string[]) {
  const [deleteNotification] = useDeleteNotificationMutation();
  return () =>
    deleteNotification({
      variables: { input: { ids: notificationIds } },
    });
}
