import { useEffect, useState } from "react";
import {
  useGetPretrainedNetworkForModuleQuery,
  useQueryPretrainedNetworkQuery,
} from "../../../../ts-clients/query";
import {
  PretrainedNetworkType,
  PretrainedNetwork,
} from "../../../../types/extractedTypes";
import makeBlankNetwork, { blankNetworkModuleMagicId } from "./blankNetwork";

export const stringIncludeFilter = (
  stringToSearch: string,
  stringToFind: string
): boolean => {
  return stringToSearch.toLowerCase().includes(stringToFind.toLowerCase());
};

const shortType = new Map<string, string>([
  ["PipelineModuleNetworkImageClassification", "Classification"],
  ["PipelineModuleNetworkImageSegmentation", "Segmentation"],
]);

export const usePretrainedNetworks = (
  networkType: PretrainedNetworkType | null
) => {
  const { data, loading } = useQueryPretrainedNetworkQuery({
    fetchPolicy: "no-cache",
  });

  const [
    selectedPretrainedNetworkModuleId,
    setSelectedPretrainedNetworkModuleId,
  ] = useState<string | null>(null);

  const pretrainedNetworksNoEmpty = (data?.queryPretrainedNetwork ?? [])
    .flatMap((n) => (n !== null ? n : []))
    .map((n) => ({
      ...n,
      networkModule: { ...n.networkModule, classLabels: [] },
    }));

  const pretrainedNetworksAvailable = (pretrainedNetworksNoEmpty ?? []).filter(
    (n) => n?.networkModule.__typename === networkType
  );

  const blankNetwork = makeBlankNetwork(
    networkType ?? "PipelineModuleNetworkImageClassification"
  );

  useEffect(() => {
    setSelectedPretrainedNetworkModuleId(null);
  }, [networkType]);

  const getPretrainedNetworks = (filterString: string): PretrainedNetwork[] => {
    const pretrainedNetworksFiltered = (
      pretrainedNetworksAvailable ?? []
    ).filter(
      (network) =>
        stringIncludeFilter(network?.networkModule.name || "", filterString) ||
        stringIncludeFilter(
          network?.networkModule.description || "",
          filterString
        ) ||
        stringIncludeFilter(
          shortType.get(network?.networkModule.__typename || "") || "",
          filterString
        )
    );

    const visibleNetworks = [blankNetwork, ...pretrainedNetworksFiltered];

    return visibleNetworks ?? [];
  };

  const preTrainedNetworksExist = pretrainedNetworksAvailable.length > 0;

  const { data: networkresult } = useGetPretrainedNetworkForModuleQuery({
    variables: { pipelineModuleId: selectedPretrainedNetworkModuleId ?? "" },
    fetchPolicy: "no-cache",
  });

  const networkArray = networkresult?.queryPretrainedNetwork ?? [];
  const pretrainedNetwork = networkArray.length > 0 ? networkArray[0] : null;

  const selectedPretrainedNetwork =
    selectedPretrainedNetworkModuleId === blankNetworkModuleMagicId &&
    networkType !== null
      ? blankNetwork
      : pretrainedNetwork;

  useEffect(() => {
    if (pretrainedNetworksAvailable.length === 0 && loading === false) {
      setSelectedPretrainedNetworkModuleId(blankNetwork.networkModule.id);
    }
  }, [pretrainedNetworksAvailable, loading, blankNetwork.networkModule.id]);

  return {
    loading,
    getPretrainedNetworks,
    preTrainedNetworksExist,
    selectedPretrainedNetworkModuleId,
    setSelectedPretrainedNetworkModuleId,
    selectedPretrainedNetwork,
  };
};
