import { useSelector } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { canMutateResearchbox } from "../../state/loginState";

type Props = {
  triggerDelete: () => void;
  triggerRename: () => void;
};

export default function ResearchBoxActions({
  triggerDelete,
  triggerRename,
}: Props) {
  const userCanMutateResearchBox = useSelector(canMutateResearchbox);

  return (
    <Dropdown
      icon="ellipsis horizontal"
      labeled
      floating
      button
      disabled={!userCanMutateResearchBox}
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={triggerRename}
          icon="edit"
          content="Rename File"
        />
        <Dropdown.Item
          onClick={triggerDelete}
          icon="trash"
          content="Delete File"
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}
