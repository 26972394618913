import { useContext, useState } from "react";
import {
  Modal,
  Button,
  Grid,
  Table,
  Confirm,
  Label,
  Header,
  Divider,
  Dropdown,
} from "semantic-ui-react";
import { NetworkDetailsMetricType } from "../../../../components/menus/settings-view/hooks/useNetworkDetails";
import { DenkboxContext } from "../../../../ctx/DenkboxProvider";
import { useResetNetworkSnapshotMutation } from "../../../../ts-clients/command";
import ControlTraining from "../../../details-researchbox/Markup/RightMenu/Tabs/parts/ControlTraining";
import MetricsChart, {
  ChartMetric,
  DashTypes,
} from "../../../details-researchbox/Markup/RightMenu/Tabs/parts/MetricsChart";
import MetricsTable from "../../../details-researchbox/Markup/RightMenu/Tabs/parts/MetricsTable";

type Props = {
  isOpen: boolean;
  close: () => void;
};

const metricsToChartData = (
  metrics: NetworkDetailsMetricType
): ChartMetric[] => {
  const colorCount = new Map<string, number>();

  metrics.forEach((m) => {
    const color = m.label ? m.label.color : "black";

    if (colorCount.has(color)) {
      colorCount.set(color, (colorCount.get(color) || 0) + 1);
    } else {
      colorCount.set(color, 0);
    }
  });

  const ret = metrics.map((m) => {
    const color = m.label ? m.label.color : "black";
    const dash = DashTypes[(colorCount.get(color) || 0) % DashTypes.length];

    return {
      dash: dash,
      name: m.name,
      history: JSON.parse(m.history) || [],
      color,
      labelName: m.label ? m.label.name : undefined,
    };
  });

  return ret;
};

export default function DetailsModal({ isOpen, close }: Props) {
  const denkboxState = useContext(DenkboxContext);

  const [resetNetworkSnapshotMutation] = useResetNetworkSnapshotMutation();
  const [showNetworkDeleteConfirm, setNetworkDeleteConfirm] = useState(false);

  const [filteredMetricIds, setFilteredMetricIds] = useState<string[]>([]);

  const startDownload = () => {
    if (denkboxState?.networkDetails.downloadURL) {
      fetch(denkboxState?.networkDetails.downloadURL, {
        method: "GET",
        headers: { "Content-Type": "application/octet-stream" },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${denkboxState?.networkDetails.name}.denk`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        });
    }
  };

  const clearNetwork = () => {
    resetNetworkSnapshotMutation({
      variables: {
        input: { networkModuleId: denkboxState?.networkDetails.id ?? "" },
      },
    });
    setNetworkDeleteConfirm(false);
  };

  return (
    <Modal onClose={close} open={isOpen} size="large">
      <Modal.Header>
        {denkboxState?.networkDetails.name}
        <div style={{ float: "right" }}>
          <ControlTraining />
          <Button.Group>
            <Button
              icon="download"
              content="Download Network"
              disabled={denkboxState?.networkDetails.downloadURL === undefined}
              onClick={startDownload}
            />
            <Button
              icon="delete"
              content="Reset Training"
              disabled={denkboxState?.networkDetails.downloadURL === undefined}
              onClick={() => setNetworkDeleteConfirm(true)}
            />
          </Button.Group>
          <Confirm
            open={showNetworkDeleteConfirm}
            onCancel={() => setNetworkDeleteConfirm(false)}
            onConfirm={clearNetwork}
            content="If you click OK, the trained network is deleted and the training can be restarted from scratch. This WILL NOT delete any annotations."
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        <p>
          {denkboxState?.networkDetails.description
            ? denkboxState?.networkDetails.description
            : "no description available"}
        </p>
        <Header as="h2" content="Training Metrics" />
        <Divider />
        <Grid>
          <Grid.Column width="6">
            <MetricsTable
              metrics={denkboxState?.networkDetails.metrics || []}
            />
          </Grid.Column>
          <Grid.Column width="10">
            <Header as="h5">All Metrics</Header>
            <Dropdown
              placeholder="filter metrics"
              multiple
              search
              selection
              options={(denkboxState?.networkDetails.metrics || []).map(
                (m) => ({
                  key: m.id,
                  value: m.id,
                  text: `${m.name}${m.label ? " [" + m.label.name + "]" : ""}`,
                })
              )}
              onChange={(_, data) =>
                setFilteredMetricIds(data.value as string[])
              }
              value={filteredMetricIds}
            />
            <MetricsChart
              metrics={metricsToChartData(
                (denkboxState?.networkDetails.metrics || []).filter(
                  (m) =>
                    filteredMetricIds.length === 0 ||
                    filteredMetricIds.indexOf(m.id) >= 0
                )
              )}
              viewType="details"
            />
          </Grid.Column>
        </Grid>{" "}
        <Header as="h2" content="Network Metrics" />
        <Divider />
        <Grid>
          <Grid.Column width="8">
            <Table compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content="Statistics" />
                  <Table.HeaderCell content="Value" />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {[
                  {
                    name: "# Training Images",
                    value: denkboxState?.networkDetails.trainingCount,
                  },
                  {
                    name: "# Validation Images",
                    value: denkboxState?.networkDetails.validationCount,
                  },
                  {
                    name: "Last Snapshot Created",
                    value:
                      denkboxState?.networkDetails.lastSnapshotTime.dateTime,
                  },
                  {
                    name: "Snapshots",
                    value: denkboxState?.networkDetails.snapshotCount,
                  },
                ].map((i) => (
                  <Table.Row key={i.name}>
                    <Table.Cell content={i.name} />
                    <Table.Cell content={i.value} />
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column width="8">
            <Table compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content="Label" colSpan="2" />
                  <Table.HeaderCell content="Images with Annotations" />
                  <Table.HeaderCell content="Images without Annotations" />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {denkboxState?.networkDetails.classLabels.map((i) => (
                  <Table.Row key={i.id}>
                    <Table.Cell>
                      <Label
                        circular
                        style={{ backgroundColor: i.color }}
                        size="tiny"
                      />
                    </Table.Cell>
                    <Table.Cell>{i.name}</Table.Cell>
                    <Table.Cell content={i.positiveMarkupCount} />
                    <Table.Cell content={i.negativeMarkupCount} />
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={close} content="close" />
      </Modal.Actions>
    </Modal>
  );
}
