import { ReactNode } from "react";
import { Card, Label } from "semantic-ui-react";

type RenderProps = {
  d: string;
  selector: ReactNode;
};

export default function UnknownDataType({ d, selector }: RenderProps) {
  return (
    <>
      {selector}
      <Card.Content>
        <Label content={`Cannot render ${d} datapoint yet.`} />
      </Card.Content>
    </>
  );
}
