import { Confirm } from "semantic-ui-react";
import { useUIType } from "../../../hooksqueries";
import useDeleteDataset from "./hooks/useDeleteDataset";

type Props = {
  visible: boolean;
  hide: () => void;
  datasetId: string;
};

export default function DatasetDeleteModal({
  visible,
  hide,
  datasetId,
}: Props) {
  const del = useDeleteDataset(datasetId);
  const runDelete = () => {
    del();
    hide();
  };

  const uiType = useUIType();

  return (
    <Confirm
      open={visible}
      header={`Deleting ${uiType !== "halm" ? "Dataset" : "Portfolio"}`}
      onCancel={hide}
      onConfirm={runDelete}
      content="Are you sure you want to delete this Dataset? This cannot be undone!"
    />
  );
}
