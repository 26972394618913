import { useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Confirm, Dropdown, Icon, Table } from "semantic-ui-react";

import { formatDate } from "../../../utils/helpers";

import { useDeleteUserMutation } from "../../../ts-clients/command";

type UserData = {
  username: string;
  createdAt: string;
  email: string;
  role: string;

  name: string | undefined | null;
  surname: string | undefined | null;
  phone: string | undefined | null;

  vendorName: string | undefined;
};

type Props = {
  users: UserData[];
};

export default function UserList({ users }: Props) {
  const { url } = useRouteMatch();
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deleteUser] = useDeleteUserMutation();

  return (
    <>
      <Table compact celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>E-mail address</Table.HeaderCell>
            <Table.HeaderCell>Registration Date</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Surname</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {users.map((user) => (
            <Table.Row key={user.username}>
              <Table.Cell>{user.username}</Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>{formatDate(user.createdAt).date}</Table.Cell>
              <Table.Cell>{user.role}</Table.Cell>
              <Table.Cell>{user.name || ""}</Table.Cell>
              <Table.Cell>{user.surname || ""}</Table.Cell>
              <Table.Cell>{user.phone || ""}</Table.Cell>
              <Table.Cell>
                <Dropdown icon="ellipsis horizontal" labeled floating button>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        history.push(`${url}/editUser/${user.username}`);
                      }}
                    >
                      <Icon name="edit" />
                      edit
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setShowDeleteModal(true)}>
                      <Icon name="trash" />
                      delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Table.Cell>
              <Confirm
                open={showDeleteModal}
                header="Deleting User"
                onCancel={() => setShowDeleteModal(false)}
                onConfirm={() => {
                  deleteUser({
                    variables: { input: { usernames: [user.username] } },
                  });
                  setShowDeleteModal(false);
                }}
                content={`Are you sure you want to delete the user '${user.username}' ? This cannot be undone!`}
              />
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
