import {
  PipelineModuleNetworkImageSegmentationProps as Props,
  BaseParams,
} from "ModuleTypes";
import { omit } from "ramda";
import { useState } from "react";
import {
  useAddPipelineModuleNetworkImageSegmentationMutation,
  AddPipelineModuleNetworkImageSegmentationInput,
  // useAddDatasetMutation,
} from "../../ts-clients/command";
import { pipelineModuleNetworkImageSegmentationDefaults } from "./defaults";
import { blankNetworkModuleMagicId } from "../../features/networks3/AddNetwork/RealAddNetwork/blankNetwork";

export default function useCreatePipelineModuleImageSegmentation() {
  const [moduleData, setModuleData] = useState<Props>(
    pipelineModuleNetworkImageSegmentationDefaults()
  );
  const [addPipelineModule] =
    useAddPipelineModuleNetworkImageSegmentationMutation();

  // const [addDataSet] = useAddDatasetMutation();

  const create = async (datasetId: string | null) => {
    const filteredModuleData: AddPipelineModuleNetworkImageSegmentationInput = {
      ...moduleData,
      classLabels: moduleData.classLabels
        .filter((c) => c.name !== "")
        .map(omit(["__typename", "id"])),
    };

    if (
      filteredModuleData.pretrainedNetworkModuleId === blankNetworkModuleMagicId
    ) {
      filteredModuleData.pretrainedNetworkModuleId = null;
    }

    const outputIds = filteredModuleData.selectedPipelineModuleOutputIDs ?? [];

    const input: AddPipelineModuleNetworkImageSegmentationInput = {
      ...filteredModuleData,
      datasetId: datasetId ?? undefined,
      selectedPipelineModuleOutputIDs:
        outputIds.length > 0 ? outputIds : undefined,
    };

    // const addDatasetResult = await addDataSet({
    //   variables: {
    //     input: {
    //       description: "fancy description",
    //       name: "name generated from stuff",
    //     },
    //   },
    // });

    const result = await addPipelineModule({
      variables: {
        input,
      },
    });
    return (
      result.data?.addPipelineModuleNetworkImageSegmentation?.datasetId ?? ""
    );
  };

  const setBaseParams = ({ moduleDescription, moduleName }: BaseParams) => {
    setModuleData((md) => ({ ...md, moduleName, moduleDescription }));
  };

  return { create, moduleData, setModuleData, setBaseParams };
}
