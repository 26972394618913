/* eslint-disable react/jsx-curly-newline */
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Checkbox, Input, Table } from "semantic-ui-react";
import { ResearchBoxOrFolderType } from "../../types/extractedTypes";
import { formatDate } from "../../utils/helpers";
import {
  useUpdateFolderMutation,
  useUpdateResearchBoxMutation,
} from "../../ts-clients/command";
import FolderRowItem from "./FolderRowItem";
import ResearchBoxRowItem from "./ResearchBoxRowItem";
import { useDispatch } from "react-redux";
import { setFilename } from "../../state/researchboxfilter";

type Props = {
  object: ResearchBoxOrFolderType | null;
  selected: boolean;
  onSelect: (value: boolean) => void;
  onRename: () => void;
  openMarkupTool?: () => void;
};

const ListRow = ({
  object,
  selected,
  onSelect,
  openMarkupTool,
  onRename,
}: Props) => {
  const location = useLocation();
  const history = useHistory();

  const [renameActive, setRenameActive] = useState(false);
  const [currentName, setCurrentName] = useState(object?.name || "");

  const [updateFolder] = useUpdateFolderMutation({
    variables: {
      input: {
        folderId: object?.id || "",
        name: currentName,
      },
    },
  });

  const [updateResearchBox] = useUpdateResearchBoxMutation({
    variables: {
      input: {
        researchBoxId: object?.id || "",
        name: currentName,
      },
    },
  });

  const dispatch = useDispatch();

  return (
    <Table.Row key={object?.id}>
      <Table.Cell collapsing>
        <Checkbox
          onClick={(_, data) => {
            onSelect(data.checked as boolean);
          }}
          checked={selected}
        />
      </Table.Cell>
      <Table.Cell>
        {object?.__typename === "Folder" && (
          <FolderRowItem
            folder={object}
            onClick={() => {
              dispatch(setFilename(""));
              history.push({
                pathname: location.pathname,
                search: `?path=${object?.parentIds
                  .split("-")
                  .slice(1)
                  .concat([object?.id])
                  .join("-")}`,
              });
            }}
          />
        )}
        {object?.__typename === "ResearchBox" && (
          <ResearchBoxRowItem
            researchBox={object}
            onClick={openMarkupTool ? () => openMarkupTool() : undefined}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        {!renameActive && (
          <p
            onDoubleClick={() => {
              setRenameActive(true);
            }}
          >
            {object?.name || ""}
          </p>
        )}
        {renameActive && (
          <Input
            value={currentName}
            onChange={(_, data) =>
              setCurrentName(data.value as unknown as string)
            }
            onKeyPress={async (e: React.KeyboardEvent) => {
              if (e.key === "Enter") {
                setRenameActive(false);
                if (object?.__typename === "Folder") {
                  await updateFolder();
                  onRename();
                } else {
                  await updateResearchBox();
                  onRename();
                }
              }
            }}
          />
        )}
      </Table.Cell>
      <Table.Cell>{formatDate(object?.createdAt || "").dateTime}</Table.Cell>
    </Table.Row>
  );
};

ListRow.defaultProps = {
  openMarkupTool: undefined,
};

export default ListRow;
