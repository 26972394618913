/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header, Divider, Grid, Segment, Menu, Label } from "semantic-ui-react";
import SensorLiveView from "./SensorLiveView";
import FileNamingScheme from "./FileNamingScheme";
import SensorSelect from "./SensorSelect";
import SensorControl from "./SensorControl";
import LastCCCheckerResult from "./LastCCCheckerResult";
import { SensorImportParams } from "../../utils/routeManager";
import { useUIType } from "../../hooksqueries";
import { getDevicePath, setRouteInfo } from "../../state/deviceControl";
import { setVisibleDataTypes } from "../../state/dataTypeDisplaySettings";
import { DataType } from "../../ts-clients/query";

type Props = {
  params: SensorImportParams;
};

export default function SensorImportRoot({ params }: Props) {
  const dispatch = useDispatch();
  const { datasetId, context } = params;
  const uiType = useUIType();
  const currentDevicePath = useSelector(getDevicePath);

  const [menuActive, setMenuActive] = useState<"sensor" | "scan">("sensor");

  useEffect(() => {
    if (currentDevicePath !== null) {
      setMenuActive("scan");
    }
  }, [currentDevicePath]);

  useEffect(() => {
    dispatch(setVisibleDataTypes([DataType.MagneticFieldScan]));
  });

  useEffect(() => {
    dispatch(setRouteInfo({ context, datasetId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetId, context]);

  return (
    <>
      <Header as="h1">
        {uiType === "ccchecker"
          ? "DENKweit Cross Connector Check"
          : "Import Data from B-Tech Sensor"}
      </Header>
      <Divider />

      <Menu size="huge">
        <Menu.Item
          active={menuActive === "sensor"}
          onClick={() => setMenuActive("sensor")}
        >
          <Label circular content="1" />
          &nbsp; Select Sensor
        </Menu.Item>
        <Menu.Item
          active={menuActive === "scan"}
          onClick={() => setMenuActive("scan")}
        >
          <Label circular content="2" />
          &nbsp;{" "}
          {uiType === "ccchecker"
            ? "Run Cross Connector Checker"
            : "Run Import from B-Tech"}
        </Menu.Item>
      </Menu>
      {menuActive === "sensor" && (
        <Segment.Group>
          <Segment style={{ backgroundColor: "#f4f4f4" }}>
            <Header as="h2">Sensor Select</Header>
          </Segment>
          <Segment>
            <SensorSelect />
          </Segment>
        </Segment.Group>
      )}
      {menuActive === "scan" && (
        <>
          <FileNamingScheme />
          <SensorControl datasetId={datasetId} />
        </>
      )}

      <Grid stackable>
        <Grid.Column largeScreen="8" widescreen="8" computer="16">
          <SensorLiveView />
        </Grid.Column>
        <Grid.Column largeScreen="8" widescreen="8" computer="16">
          <Segment.Group>
            <Segment style={{ backgroundColor: "#f4f4f4" }}>
              <Header as="h2">Last Scan Result</Header>
            </Segment>
            <Segment>
              <LastCCCheckerResult datasetId={datasetId} />
            </Segment>
          </Segment.Group>
        </Grid.Column>
      </Grid>
    </>
  );
}
