import { useSelector } from "react-redux";
import { getShowSegmentations } from "../../../state/researchboxfilter";
import { ImageDatapointType } from "../../../types/extractedTypes";
import useAnnotationThumbnails from "./hooks/useAnnotationThumbnails";
import useInteractionHooks from "./hooks/useInteractionHooks";
import ReadOnly from "./ReadOnly";

type Props = {
  datapoint: ImageDatapointType;
  onClick?: () => void;
};

export default function ReadOnlyListView({ datapoint, onClick }: Props) {
  const thumbnails = useAnnotationThumbnails(datapoint);
  const showSegmentations = useSelector(getShowSegmentations);
  const { setHook, callHook } = useInteractionHooks();
  return (
    <ReadOnly
      datapoint={datapoint}
      thumbnails={!showSegmentations ? undefined : thumbnails}
      setHook={setHook}
      callHook={callHook}
      draggable={false}
      zoomable={false}
      onClick={onClick}
    />
  );
}

ReadOnlyListView.defaultProps = {
  onClick: undefined,
};
