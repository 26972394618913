import { Header, Divider } from "semantic-ui-react";
import ControlPrediction from "../Tabs/parts/ControlPrediction";
import InfoBoxes from "../Tabs/parts/InfoBoxes";
import ControlTraining from "./parts/ControlTraining";
import useHasPredictions from "../../../../../components/menus/settings-view/hooks/useHasPredictionCheck";
import { DenkjobState } from "../../../../../ts-clients/query";
import { useContext } from "react";
import useMarkuptoolList from "../../../../../state/useMarkuptoolList";
import { ResearchBoxContext } from "../../../../../ctx/ResearchBoxProvider";
import { DenkboxContext } from "../../../../../ctx/DenkboxProvider";

type Props = {
  networkId: string;
};

const getHeadlineState = (d: DenkjobState | null): string => {
  switch (d) {
    case DenkjobState.Failed:
      return "failed";
    case DenkjobState.Finished:
      return "finished";
    case DenkjobState.Running:
    case DenkjobState.Starting:
    case DenkjobState.Stopping:
      return "running";
    default:
      return "idle";
  }
};

export default function TrainingTab({ networkId }: Props) {
  const { researchbox } = useContext(ResearchBoxContext);
  const newestPredictionCreated = useHasPredictions(
    networkId ?? "",
    researchbox?.id ?? ""
  );

  const { markuptoolList, currentListIndex } = useMarkuptoolList();

  const start = currentListIndex - 3 < 0 ? 0 : currentListIndex - 3;
  const end =
    currentListIndex + 5 >= markuptoolList.length
      ? markuptoolList.length - 1
      : currentListIndex + 5;

  const slice = markuptoolList.slice(start, end);

  const denkboxState = useContext(DenkboxContext);

  if (slice.length === 0 || !denkboxState) {
    return <></>;
  }

  return (
    <>
      <Header
        as="h4"
        content={`Training (${getHeadlineState(
          denkboxState.networkDetails.networkState.training
        )})`}
      />
      <Divider />
      <InfoBoxes
        networkId={networkId}
        boxes={denkboxState.infoMessages}
        trainingSince={denkboxState.networkDetails.networkState.runningSince}
      />
      <ControlTraining fluid />

      <ControlPrediction
        actionsAvailable={denkboxState.actionsAvailable}
        researchBoxId={researchbox?.id}
        newestPredictionCreated={newestPredictionCreated}
        visiblePredictionCreated={""}
      />
    </>
  );
}
