import { useSelector } from "react-redux";
import useAddDataset from "./useAddDataset";
import { FormData } from "../types";
import { getCurrentUser } from "../../../../state/loginState";

export default function useDatasetCreate() {
  const addDataset = useAddDataset();

  const currentUser = useSelector(getCurrentUser);

  return async (f: FormData) => {
    // default case

    addDataset({
      owner: currentUser.username,
      ...f,
    });
  };
}
