import { useRouteMatch, Switch, Route } from "react-router-dom";
import ListNetworks from "./ListNetworks";

export default function AdminUsers() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ListNetworks} />
    </Switch>
  );
}
