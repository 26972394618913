import { decode, PNGDataArray } from "fast-png";

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let r = 0;
  let g = 0;
  let b = 0;
  if (result) {
    r = parseInt(result[1], 16);
    g = parseInt(result[2], 16);
    b = parseInt(result[3], 16);
  }
  return [r, g, b];
};

export const rgbToHex = (r: number, g: number, b: number) => {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export const hexToRgbString = (hex: string) => {
  const rgb = hexToRgb(hex.substring(0, 7));
  if (hex.length === 7) return `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
  else
    return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${parseInt(
      hex.substring(7),
      16
    )})`;
};

export const hexToRgbaString = (hex: string, opacity: number) => {
  const rgb = hexToRgb(hex);
  return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${opacity * 255})`;
};

// https://www.codeproject.com/Questions/1176365/Getting-error-rangeerror-maximum-call-stack-size-e
// Calling String.fromCharCode(...encoded) calls the function with encoded.length arguments,
// but there is a maximum number of arguments it can be called with.
// This function chunks the call to prevent this error.
export const stringSegmentator = (input: Uint8Array): string => {
  const chunksize = 1024;
  let buffer = "";
  let slice: string;
  let idx = 0;
  while (idx < input.length) {
    // @ts-ignore
    slice = String.fromCharCode(...input.slice(idx, idx + chunksize));
    buffer += slice;
    idx += slice.length;
  }
  return buffer;
};

export const pngFilterWithColor = (
  imageData: PNGDataArray,
  color: number[],
  opacity: number
): Uint8ClampedArray => {
  const newData = new Uint8ClampedArray(imageData.length * 4);
  for (let i = 0; i < imageData.length; i += 1) {
    // weird, but works
    [newData[i * 4], newData[i * 4 + 1], newData[i * 4 + 2]] = color;
    newData[i * 4 + 3] = imageData[i] === 0 ? 0 : 255 * opacity;

    // works, but creates eslint error (use array-desctructuring)
    // newData[i * 4] = color[0];
    // newData[i * 4 + 1] = color[1];
    // newData[i * 4 + 2] = color[2];
    // newData[i * 4 + 3] = imageData[i] === 0 ? 0 : alpha;
  }

  return newData;
};

export function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export const b64SourceToThreeChannelImage = (
  b64string: string,
  color: number[],
  opacity: number
): Uint8ClampedArray => {
  const base64 = b64string.slice("data:image/png;base64,".length);
  const buffer = base64ToArrayBuffer(base64);
  const decoded = decode(buffer);

  return pngFilterWithColor(decoded.data, color, opacity);
};
