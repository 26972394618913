import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPageSize,
  setPageSize,
  getPage,
  setPage,
  PageSize,
  getResearchBoxCount,
} from "../../../state/researchboxfilter";

export default function useResearchBoxNavigation() {
  const selectedPageSize = useSelector(getPageSize);
  const pagesizes = useMemo(() => [8, 12, 24, 48, 96], []);
  const currentPage = useSelector(getPage);
  const researchBoxCount = useSelector(getResearchBoxCount);
  const dispatch = useDispatch();

  const rawPageCount = Math.ceil(researchBoxCount / selectedPageSize);
  const pageCount = rawPageCount > 0 ? rawPageCount : 1;

  if (currentPage > pageCount && researchBoxCount > 0) {
    dispatch(setPage(pageCount));
  }

  // const gotoPage = (page: number) => dispatch(setPage(page));
  const goNext = () => {
    if (currentPage < pageCount) {
      dispatch(setPage(currentPage + 1));
    }
  };
  const goPrev = () => {
    if (currentPage > 0) {
      dispatch(setPage(currentPage - 1));
    }
  };
  const doSetPageSize = (page: PageSize) => dispatch(setPageSize(page));

  const pages = useMemo(
    () =>
      Array.from(Array(pageCount).keys())
        .map((p) => p + 1)
        .map((p) => ({
          key: p,
          text: p,
          value: p,
        })),
    [pageCount]
  );

  const pageSizes = useMemo(
    () =>
      pagesizes.map((p) => ({
        key: p,
        text: p,
        value: p,
      })),
    [pagesizes]
  );

  const isOnFirstPage = !(currentPage > 1);
  const isOnLastPage = !(currentPage < pageCount);

  return {
    currentPage,
    doSetPageSize,
    goNext,
    goPrev,
    isOnFirstPage,
    isOnLastPage,
    pages,
    pageSizes,
    selectedPageSize,
  };
}
