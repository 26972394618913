/* eslint-disable react/no-array-index-key */
import { Message, Icon } from "semantic-ui-react";
import { InfoMessageId } from "../../../../../../components/menus/settings-view/hooks/types";
import FakeTestProgressBar from "./FakeTestProgressBar";

type MessageProps = {
  headline: string;
  items: string[];
  type: "positive" | "negative";
};

const messages = (trainingSince: string | null) =>
  new Map<InfoMessageId, MessageProps>([
    [
      "trainingRunning",
      {
        headline: "Training is Running",
        type: "positive",
        items: [
          `Started ${trainingSince ?? ""}`,
          "You can use the autoprediction feature after we found a good enough network",
        ],
      },
    ],
    [
      "predictionRunning",
      {
        headline: "Autoprediction is Running",
        type: "positive",
        items: [
          "Runs the best Network on the current Image",
          "Wait for the reload button",
        ],
      },
    ],
    [
      "testRunning",
      {
        headline: "Analyzing Dataset",
        type: "positive",
        items: [
          "Before training starts we analyze the dataset and find the best network configuration",
        ],
      },
    ],
    [
      "error",
      {
        headline: "Failed to run Network",
        type: "negative",
        items: ["DENKweit is contacted automatically. Try again in one hour."],
      },
    ],
    [
      "notEnoughData",
      {
        headline: "Not enough data",
        type: "negative",
        items: ["Annotate more datapoints to enable training."],
      },
    ],
  ]);

type Props = {
  boxes: InfoMessageId[];
  trainingSince: string | null;
  networkId: string;
};

export default function InfoBoxes({ boxes, trainingSince, networkId }: Props) {
  return (
    <>
      {boxes.includes("testRunning") && (
        <FakeTestProgressBar networkId={networkId} />
      )}
      {boxes
        .map((msgid) => ({ ...messages(trainingSince).get(msgid), msgid }))
        .filter((b) => b !== undefined)
        .map((box) => (
          <Message
            key={box.msgid}
            size="tiny"
            color={box.type === "negative" ? "red" : undefined}
          >
            <Message.Header>
              <Icon
                name={
                  box.type === "positive" ? "circle notched" : "warning circle"
                }
                loading={box.type === "positive"}
              />
              {box.headline}
            </Message.Header>
            <Message.List>
              {(box.items ?? []).map((item, idx) => (
                <Message.Item key={idx} content={item} />
              ))}
            </Message.List>
          </Message>
        ))}
    </>
  );
}
