import { Message, Icon } from "semantic-ui-react";

export default function NoFilterCriteriaPlaceholder() {
  return (
    <Message info>
      <Icon disabled name="delete" size="big" />
      No networks found with the given filter.
    </Message>
  );
}
