import { Message } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import Overview from "./Overview";
import Markup from "./Markup";
import { makeNaviation } from "./utils";
import { ResearchBoxDetailsParams } from "../../utils/routeManager";
import useQuery from "../../hooks/useQuery";
import { useContext, useEffect } from "react";
import useMarkuptoolList from "../../state/useMarkuptoolList";
import { ResearchBoxContext } from "../../ctx/ResearchBoxProvider";

type Props = {
  pageParams: ResearchBoxDetailsParams;
};

export default function ResearchBoxDetailsIndex({ pageParams }: Props) {
  const history = useHistory();

  const { setCurrentListIndex } = useMarkuptoolList();

  useEffect(() => {
    setCurrentListIndex(pageParams.listIndex);
  }, [setCurrentListIndex, pageParams.listIndex]);

  const { researchbox, researchboxCount } = useContext(ResearchBoxContext);

  console.log("researchboxCount: ", researchboxCount);

  // const goTo = useGoto();

  // useEffect(() => {
  //   if (researchboxCount === 0) {
  //     goTo("ResearchBoxList", {
  //       context: "researchboxlist",
  //       datasetId: pageParams.datasetId,
  //     });
  //   }
  // }, [researchboxCount, pageParams.datasetId, goTo]);

  const query = useQuery();

  const path = query.get("path");

  const navigation = makeNaviation(
    history,
    pageParams,
    researchboxCount,
    path ? `?path=${path}` : undefined
  );

  switch (pageParams.page) {
    case "markup":
      return (
        <Markup
          navigation={navigation}
          pageParams={pageParams}
          researchbox={researchbox}
        />
      );
    case "overview":
      return (
        <Overview
          navigation={navigation}
          researchbox={researchbox}
          pageParams={pageParams}
        />
      );
    default:
      return <Message warning content="unknown page" />;
  }
}
