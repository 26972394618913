import { useParams } from "react-router-dom";
import Networks from "../../features/networks3";
import ResearchBoxListMenu from "./ResearchBoxListMenu";
import Center from "../../features/filemanagement/DefaultPositioner";
import MenuMain from "../../components/menus/menu.main";

type Params = {
  datasetId: string;
  menuType: string;
};

export default function NetworksContainer() {
  const { datasetId } = useParams<Params>();
  return (
    <>
      <MenuMain />
      <ResearchBoxListMenu current="networks" datasetId={datasetId} />
      <Center>
        <Networks />
      </Center>
    </>
  );
}
