/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint react/jsx-props-no-spreading: off */
import { useState } from "react";
import { Form, Button } from "semantic-ui-react";
import { useParams } from "react-router";
import Menu from "./Menu";
import { useFindDatasetByIdQuery } from "../../ts-clients/query";
import { useUpdateDatasetMutation } from "../../ts-clients/command";

import { DatasetSettingsParams } from "../../utils/routeManager";
import { useUIType } from "../../hooksqueries";

export default function BaseSettings() {
  const { datasetId } = useParams<DatasetSettingsParams>();
  const [updateDataset] = useUpdateDatasetMutation();
  const [formState, setFormState] = useState({ name: "", description: "" });

  const { refetch } = useFindDatasetByIdQuery({
    variables: { datasetId },
    onCompleted: (data) =>
      setFormState({
        name: data.getDataset?.name || "",
        description: data.getDataset?.description || "",
      }),
  });

  const submit = async () => {
    await updateDataset({
      variables: {
        input: {
          filter: {
            datasetId,
          },
          ...formState,
        },
      },
    });

    refetch();
  };

  const uiType = useUIType();

  return (
    <>
      <Menu datasetId={datasetId} active="base" />
      <Form>
        <Form.Field>
          <label>{uiType !== "halm" ? "Dataset Name" : "Portfolio Name"}</label>
          <input
            placeholder={uiType !== "halm" ? "Dataset Name" : "Portfolio Name"}
            value={formState.name}
            onChange={(e) =>
              setFormState({ ...formState, name: e.currentTarget.value })
            }
          />
        </Form.Field>
        <Form.TextArea
          label={
            uiType !== "halm" ? "Dataset Description" : "Portfolio Description"
          }
          onChange={(e) =>
            setFormState({ ...formState, description: e.currentTarget.value })
          }
          value={formState.description}
        />

        <Button type="submit" positive onClick={submit}>
          save changes
        </Button>
      </Form>
    </>
  );
}
