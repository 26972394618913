import { Message, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { DetailsDatapointParams, getPath } from "../../../utils/routeManager";

export type Props = {
  page: DetailsDatapointParams;
};

export default function UnknownResultPage({ page }: Props) {
  return (
    <>
      <Button
        as={Link}
        to={getPath("ResearchBoxDetails", {
          ...page,
          context: "researchboxlist",
          page: "overview",
        })}
        content="back"
      />
      <Message content="unknown datapoint datatype -- cannot be rendered yet." />
    </>
  );
}
