import { ApolloProvider } from "@apollo/client";
import { FlagsProvider } from "react-feature-flags";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import Routes from "./ui/Routes";
import store, { history } from "./state/store";
import "./app.global.css";
import { createApolloClient } from "./apollo";
import useFeatureFlags from "./hooks/useFeatureFlags";

const client = createApolloClient();

export const App = () => {
  const flags = useFeatureFlags();
  return (
    <DndProvider backend={HTML5Backend}>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <FlagsProvider value={flags}>
            <ConnectedRouter history={history}>
              <Routes />
            </ConnectedRouter>
          </FlagsProvider>
        </Provider>
      </ApolloProvider>{" "}
    </DndProvider>
  );
};
