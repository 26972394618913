/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FolderOrResearchBoxOrder,
  FolderOrResearchBoxOrderable,
} from "../ts-clients/query";
import { RootState } from "./store";

export type PageSize = 12 | 24 | 48 | 96;

export type FeatureLabel = {
  id: string;
  name: string;
};

export type ViewType = "list-view" | "icon-view";

type StateType = {
  pageSize: PageSize;
  page: number;
  researchBoxCount: number;
  networks: string[];
  filename: string;
  showLabels: boolean;
  showSegmentations: boolean;
  labels: FeatureLabel[];
  includePositiveLabels: boolean;
  includeNegativeLabels: boolean;
  includeUnclassifiedLabels: boolean;
  viewType: ViewType;
  order: FolderOrResearchBoxOrder;
};

const name = "researchBoxFilterState";
const initialState: StateType = {
  pageSize: 12,
  page: 1,
  researchBoxCount: 0,
  networks: [],
  filename: "",
  showLabels: true,
  showSegmentations: true,
  labels: [],
  includePositiveLabels: true,
  includeNegativeLabels: true,
  includeUnclassifiedLabels: false,
  viewType: "icon-view",
  order: {
    asc: FolderOrResearchBoxOrderable.TypeForSorting,
  },
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => ({
      ...initialState,
      pageSize: state.pageSize,
      viewType: state.viewType,
    }),
    setPageSize: (state, action: PayloadAction<PageSize>) => ({
      ...state,
      pageSize: action.payload,
    }),
    setPage: (state, action: PayloadAction<number>) => ({
      ...state,
      page: action.payload,
    }),
    setResearchBoxCount: (state, action: PayloadAction<number>) => ({
      ...state,
      researchBoxCount: action.payload,
    }),
    setNetworks: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        networks: action.payload,
      };
    },
    setFilename: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        filename: action.payload,
      };
    },
    setLabels: (state, action: PayloadAction<FeatureLabel[]>) => {
      return {
        ...state,
        labels: action.payload,
      };
    },
    toggleShowLabels: (state) => {
      return {
        ...state,
        showLabels: !state.showLabels,
      };
    },
    toggleIncludePositiveLabels: (state) => {
      return {
        ...state,
        includePositiveLabels: !state.includePositiveLabels,
        includeUnclassifiedLabels: false,
      };
    },
    toggleIncludeNegativeLabels: (state) => {
      return {
        ...state,
        includeNegativeLabels: !state.includeNegativeLabels,
        includeUnclassifiedLabels: false,
      };
    },
    toggleIncludeUnclassifiedLabels: (state) => {
      return {
        ...state,
        includeUnclassifiedLabels: !state.includeUnclassifiedLabels,
      };
    },
    toggleShowSemgentations: (state) => {
      return {
        ...state,
        showSegmentations: !state.showSegmentations,
      };
    },
    setViewType: (state, action: PayloadAction<ViewType>) => {
      return {
        ...state,
        viewType: action.payload,
      };
    },
    setOrder: (state, action: PayloadAction<FolderOrResearchBoxOrder>) => {
      return {
        ...state,
        order: action.payload,
      };
    },
  },
});

export const {
  reset,
  setPageSize,
  setResearchBoxCount,
  setNetworks,
  setPage,
  setFilename,
  setLabels,
  toggleShowLabels,
  toggleIncludePositiveLabels,
  toggleIncludeNegativeLabels,
  toggleIncludeUnclassifiedLabels,
  toggleShowSemgentations,
  setViewType,
  setOrder,
} = slice.actions;

export default slice.reducer;

export const getPageSize = (state: RootState): PageSize =>
  state.researchBoxFilter.pageSize;

export const getNetworks = (state: RootState) =>
  state.researchBoxFilter.networks;

export const getFilename = (state: RootState): string =>
  state.researchBoxFilter.filename;

export const getPage = (state: RootState): number =>
  state.researchBoxFilter.page;

export const getResearchBoxCount = (state: RootState): number =>
  state.researchBoxFilter.researchBoxCount;

export const getFirstElement = (state: RootState): number => {
  return getPage(state) * getPageSize(state) - getPageSize(state) + 1;
};

export const getLastElement = (state: RootState): number => {
  return getFirstElement(state) + getPageSize(state) - 1;
};

export const getLabels = (state: RootState): FeatureLabel[] =>
  state.researchBoxFilter.labels;

export const getShowSegmentations = (state: RootState): boolean =>
  state.researchBoxFilter.showSegmentations;

export const getShowLabels = (state: RootState): boolean =>
  state.researchBoxFilter.showLabels;

export const getIncludePositiveLabels = (state: RootState): boolean =>
  state.researchBoxFilter.includePositiveLabels &&
  !state.researchBoxFilter.includeUnclassifiedLabels;

export const getIncludeNegativeLabels = (state: RootState): boolean =>
  state.researchBoxFilter.includeNegativeLabels &&
  !state.researchBoxFilter.includeUnclassifiedLabels;

export const getIncludeUnclassifiedLabels = (state: RootState): boolean =>
  state.researchBoxFilter.includeUnclassifiedLabels;

export const getViewType = (state: RootState): ViewType =>
  state.researchBoxFilter.viewType;

export const getOrder = (state: RootState) => state.researchBoxFilter.order;
