import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
}

export interface BTechButtonState {
  __typename?: 'BTechButtonState';
  status: KeystrokeStatus;
  duration: Scalars['Int'];
}

export interface BTechDeviceInfo {
  __typename?: 'BTechDeviceInfo';
  path: Scalars['String'];
  hardwareVersion: Scalars['String'];
  protocolVersion: Scalars['String'];
  bTechModel: Scalars['String'];
  sensorCount: Scalars['Int'];
  productionYear: Scalars['Int'];
  version: Scalars['Int'];
  revision: Scalars['Int'];
  sensorId: Scalars['String'];
}

export interface BTechNewRecordInfo {
  __typename?: 'BTechNewRecordInfo';
  researchBoxId: Scalars['String'];
  magneticFieldScanId: Scalars['String'];
}

export interface BTechSensorDataPacket {
  __typename?: 'BTechSensorDataPacket';
  blockNr: Scalars['Int'];
  data: Array<Vector3f>;
  baseline: Array<Vector3f>;
}

export interface BTechSensorState {
  __typename?: 'BTechSensorState';
  path: Scalars['String'];
  connected: Scalars['Boolean'];
  calibratedAt?: Maybe<Scalars['String']>;
}

export enum KeystrokeStatus {
  KeystrokeStatusStandby = 'KeystrokeStatusStandby',
  KeystrokeStatusActive = 'KeystrokeStatusActive',
  KeystrokeStatusKeyIsPressed = 'KeystrokeStatusKeyIsPressed',
  KeystrokeStatusDone = 'KeystrokeStatusDone'
}

export interface Mutation {
  __typename?: 'Mutation';
  bTechCalibrate: Scalars['Boolean'];
  bTechNewRecord: BTechNewRecordInfo;
  bTechPauseRecord: Scalars['Boolean'];
  bTechStartScanRecording: Scalars['Boolean'];
  bTechDiscardLastScan: Scalars['Boolean'];
  bTechFinalizeRecord: Scalars['Boolean'];
  bTechStartButtonScan: Scalars['Boolean'];
  bTechStartMeasurement: Scalars['Boolean'];
  bTechStopMeasurement: Scalars['Boolean'];
}


export interface MutationBTechCalibrateArgs {
  device: Scalars['String'];
}


export interface MutationBTechNewRecordArgs {
  device: Scalars['String'];
  datasetId: Scalars['String'];
  label: Scalars['String'];
}


export interface MutationBTechPauseRecordArgs {
  device: Scalars['String'];
  magneticFieldScanId: Scalars['String'];
}


export interface MutationBTechStartScanRecordingArgs {
  device: Scalars['String'];
}


export interface MutationBTechDiscardLastScanArgs {
  device: Scalars['String'];
  magneticFieldScanId: Scalars['String'];
}


export interface MutationBTechFinalizeRecordArgs {
  device: Scalars['String'];
  datasetId: Scalars['String'];
  researchBoxId: Scalars['String'];
}


export interface MutationBTechStartButtonScanArgs {
  device: Scalars['String'];
  magneticFieldScanId: Scalars['String'];
}


export interface MutationBTechStartMeasurementArgs {
  device: Scalars['String'];
}


export interface MutationBTechStopMeasurementArgs {
  device: Scalars['String'];
}

export interface Query {
  __typename?: 'Query';
  bTechDevices: Array<BTechDeviceInfo>;
}

export interface Subscription {
  __typename?: 'Subscription';
  bTechSubscribeSensorState: BTechSensorState;
  bTechSubscribeSensorData: BTechSensorDataPacket;
  bTechSubscribeButton: BTechButtonState;
}


export interface SubscriptionBTechSubscribeSensorStateArgs {
  device: Scalars['String'];
}


export interface SubscriptionBTechSubscribeSensorDataArgs {
  device: Scalars['String'];
}


export interface SubscriptionBTechSubscribeButtonArgs {
  device: Scalars['String'];
}

export interface Vector3f {
  __typename?: 'Vector3f';
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
}


export const BTechCalibrateDocument = gql`
    mutation BTechCalibrate($device: String!) {
  bTechCalibrate(device: $device)
}
    `;
export type BTechCalibrateMutationFn = Apollo.MutationFunction<BTechCalibrateMutation, BTechCalibrateMutationVariables>;

/**
 * __useBTechCalibrateMutation__
 *
 * To run a mutation, you first call `useBTechCalibrateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBTechCalibrateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bTechCalibrateMutation, { data, loading, error }] = useBTechCalibrateMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useBTechCalibrateMutation(baseOptions?: Apollo.MutationHookOptions<BTechCalibrateMutation, BTechCalibrateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BTechCalibrateMutation, BTechCalibrateMutationVariables>(BTechCalibrateDocument, options);
      }
export type BTechCalibrateMutationHookResult = ReturnType<typeof useBTechCalibrateMutation>;
export type BTechCalibrateMutationResult = Apollo.MutationResult<BTechCalibrateMutation>;
export type BTechCalibrateMutationOptions = Apollo.BaseMutationOptions<BTechCalibrateMutation, BTechCalibrateMutationVariables>;
export const BTechNewRecordDocument = gql`
    mutation BTechNewRecord($device: String!, $datasetId: String!, $label: String!) {
  bTechNewRecord(device: $device, datasetId: $datasetId, label: $label) {
    researchBoxId
    magneticFieldScanId
  }
}
    `;
export type BTechNewRecordMutationFn = Apollo.MutationFunction<BTechNewRecordMutation, BTechNewRecordMutationVariables>;

/**
 * __useBTechNewRecordMutation__
 *
 * To run a mutation, you first call `useBTechNewRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBTechNewRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bTechNewRecordMutation, { data, loading, error }] = useBTechNewRecordMutation({
 *   variables: {
 *      device: // value for 'device'
 *      datasetId: // value for 'datasetId'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useBTechNewRecordMutation(baseOptions?: Apollo.MutationHookOptions<BTechNewRecordMutation, BTechNewRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BTechNewRecordMutation, BTechNewRecordMutationVariables>(BTechNewRecordDocument, options);
      }
export type BTechNewRecordMutationHookResult = ReturnType<typeof useBTechNewRecordMutation>;
export type BTechNewRecordMutationResult = Apollo.MutationResult<BTechNewRecordMutation>;
export type BTechNewRecordMutationOptions = Apollo.BaseMutationOptions<BTechNewRecordMutation, BTechNewRecordMutationVariables>;
export const BTechPauseRecordDocument = gql`
    mutation BTechPauseRecord($device: String!, $magneticFieldScanId: String!) {
  bTechPauseRecord(device: $device, magneticFieldScanId: $magneticFieldScanId)
}
    `;
export type BTechPauseRecordMutationFn = Apollo.MutationFunction<BTechPauseRecordMutation, BTechPauseRecordMutationVariables>;

/**
 * __useBTechPauseRecordMutation__
 *
 * To run a mutation, you first call `useBTechPauseRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBTechPauseRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bTechPauseRecordMutation, { data, loading, error }] = useBTechPauseRecordMutation({
 *   variables: {
 *      device: // value for 'device'
 *      magneticFieldScanId: // value for 'magneticFieldScanId'
 *   },
 * });
 */
export function useBTechPauseRecordMutation(baseOptions?: Apollo.MutationHookOptions<BTechPauseRecordMutation, BTechPauseRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BTechPauseRecordMutation, BTechPauseRecordMutationVariables>(BTechPauseRecordDocument, options);
      }
export type BTechPauseRecordMutationHookResult = ReturnType<typeof useBTechPauseRecordMutation>;
export type BTechPauseRecordMutationResult = Apollo.MutationResult<BTechPauseRecordMutation>;
export type BTechPauseRecordMutationOptions = Apollo.BaseMutationOptions<BTechPauseRecordMutation, BTechPauseRecordMutationVariables>;
export const BTechStartScanRecordingDocument = gql`
    mutation BTechStartScanRecording($device: String!) {
  bTechStartScanRecording(device: $device)
}
    `;
export type BTechStartScanRecordingMutationFn = Apollo.MutationFunction<BTechStartScanRecordingMutation, BTechStartScanRecordingMutationVariables>;

/**
 * __useBTechStartScanRecordingMutation__
 *
 * To run a mutation, you first call `useBTechStartScanRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBTechStartScanRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bTechStartScanRecordingMutation, { data, loading, error }] = useBTechStartScanRecordingMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useBTechStartScanRecordingMutation(baseOptions?: Apollo.MutationHookOptions<BTechStartScanRecordingMutation, BTechStartScanRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BTechStartScanRecordingMutation, BTechStartScanRecordingMutationVariables>(BTechStartScanRecordingDocument, options);
      }
export type BTechStartScanRecordingMutationHookResult = ReturnType<typeof useBTechStartScanRecordingMutation>;
export type BTechStartScanRecordingMutationResult = Apollo.MutationResult<BTechStartScanRecordingMutation>;
export type BTechStartScanRecordingMutationOptions = Apollo.BaseMutationOptions<BTechStartScanRecordingMutation, BTechStartScanRecordingMutationVariables>;
export const BTechDiscardLastScanDocument = gql`
    mutation BTechDiscardLastScan($device: String!, $magneticFieldScanId: String!) {
  bTechDiscardLastScan(device: $device, magneticFieldScanId: $magneticFieldScanId)
}
    `;
export type BTechDiscardLastScanMutationFn = Apollo.MutationFunction<BTechDiscardLastScanMutation, BTechDiscardLastScanMutationVariables>;

/**
 * __useBTechDiscardLastScanMutation__
 *
 * To run a mutation, you first call `useBTechDiscardLastScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBTechDiscardLastScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bTechDiscardLastScanMutation, { data, loading, error }] = useBTechDiscardLastScanMutation({
 *   variables: {
 *      device: // value for 'device'
 *      magneticFieldScanId: // value for 'magneticFieldScanId'
 *   },
 * });
 */
export function useBTechDiscardLastScanMutation(baseOptions?: Apollo.MutationHookOptions<BTechDiscardLastScanMutation, BTechDiscardLastScanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BTechDiscardLastScanMutation, BTechDiscardLastScanMutationVariables>(BTechDiscardLastScanDocument, options);
      }
export type BTechDiscardLastScanMutationHookResult = ReturnType<typeof useBTechDiscardLastScanMutation>;
export type BTechDiscardLastScanMutationResult = Apollo.MutationResult<BTechDiscardLastScanMutation>;
export type BTechDiscardLastScanMutationOptions = Apollo.BaseMutationOptions<BTechDiscardLastScanMutation, BTechDiscardLastScanMutationVariables>;
export const BTechFinalizeRecordDocument = gql`
    mutation BTechFinalizeRecord($device: String!, $researchBoxId: String!, $datasetId: String!) {
  bTechFinalizeRecord(device: $device, researchBoxId: $researchBoxId, datasetId: $datasetId)
}
    `;
export type BTechFinalizeRecordMutationFn = Apollo.MutationFunction<BTechFinalizeRecordMutation, BTechFinalizeRecordMutationVariables>;

/**
 * __useBTechFinalizeRecordMutation__
 *
 * To run a mutation, you first call `useBTechFinalizeRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBTechFinalizeRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bTechFinalizeRecordMutation, { data, loading, error }] = useBTechFinalizeRecordMutation({
 *   variables: {
 *      device: // value for 'device'
 *      researchBoxId: // value for 'researchBoxId'
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useBTechFinalizeRecordMutation(baseOptions?: Apollo.MutationHookOptions<BTechFinalizeRecordMutation, BTechFinalizeRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BTechFinalizeRecordMutation, BTechFinalizeRecordMutationVariables>(BTechFinalizeRecordDocument, options);
      }
export type BTechFinalizeRecordMutationHookResult = ReturnType<typeof useBTechFinalizeRecordMutation>;
export type BTechFinalizeRecordMutationResult = Apollo.MutationResult<BTechFinalizeRecordMutation>;
export type BTechFinalizeRecordMutationOptions = Apollo.BaseMutationOptions<BTechFinalizeRecordMutation, BTechFinalizeRecordMutationVariables>;
export const BTechDevicesDocument = gql`
    query BTechDevices {
  bTechDevices {
    path
    hardwareVersion
    protocolVersion
    bTechModel
    sensorCount
    productionYear
    version
    revision
    sensorId
  }
}
    `;

/**
 * __useBTechDevicesQuery__
 *
 * To run a query within a React component, call `useBTechDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBTechDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBTechDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBTechDevicesQuery(baseOptions?: Apollo.QueryHookOptions<BTechDevicesQuery, BTechDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BTechDevicesQuery, BTechDevicesQueryVariables>(BTechDevicesDocument, options);
      }
export function useBTechDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BTechDevicesQuery, BTechDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BTechDevicesQuery, BTechDevicesQueryVariables>(BTechDevicesDocument, options);
        }
export type BTechDevicesQueryHookResult = ReturnType<typeof useBTechDevicesQuery>;
export type BTechDevicesLazyQueryHookResult = ReturnType<typeof useBTechDevicesLazyQuery>;
export type BTechDevicesQueryResult = Apollo.QueryResult<BTechDevicesQuery, BTechDevicesQueryVariables>;
export const BTechSubscribeSensorDataDocument = gql`
    subscription BTechSubscribeSensorData($device: String!) {
  bTechSubscribeSensorData(device: $device) {
    blockNr
    data {
      x
      y
      z
    }
    baseline {
      x
      y
      z
    }
  }
}
    `;

/**
 * __useBTechSubscribeSensorDataSubscription__
 *
 * To run a query within a React component, call `useBTechSubscribeSensorDataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBTechSubscribeSensorDataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBTechSubscribeSensorDataSubscription({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useBTechSubscribeSensorDataSubscription(baseOptions: Apollo.SubscriptionHookOptions<BTechSubscribeSensorDataSubscription, BTechSubscribeSensorDataSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<BTechSubscribeSensorDataSubscription, BTechSubscribeSensorDataSubscriptionVariables>(BTechSubscribeSensorDataDocument, options);
      }
export type BTechSubscribeSensorDataSubscriptionHookResult = ReturnType<typeof useBTechSubscribeSensorDataSubscription>;
export type BTechSubscribeSensorDataSubscriptionResult = Apollo.SubscriptionResult<BTechSubscribeSensorDataSubscription>;
export const BTechStartMeasurementDocument = gql`
    mutation BTechStartMeasurement($device: String!) {
  bTechStartMeasurement(device: $device)
}
    `;
export type BTechStartMeasurementMutationFn = Apollo.MutationFunction<BTechStartMeasurementMutation, BTechStartMeasurementMutationVariables>;

/**
 * __useBTechStartMeasurementMutation__
 *
 * To run a mutation, you first call `useBTechStartMeasurementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBTechStartMeasurementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bTechStartMeasurementMutation, { data, loading, error }] = useBTechStartMeasurementMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useBTechStartMeasurementMutation(baseOptions?: Apollo.MutationHookOptions<BTechStartMeasurementMutation, BTechStartMeasurementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BTechStartMeasurementMutation, BTechStartMeasurementMutationVariables>(BTechStartMeasurementDocument, options);
      }
export type BTechStartMeasurementMutationHookResult = ReturnType<typeof useBTechStartMeasurementMutation>;
export type BTechStartMeasurementMutationResult = Apollo.MutationResult<BTechStartMeasurementMutation>;
export type BTechStartMeasurementMutationOptions = Apollo.BaseMutationOptions<BTechStartMeasurementMutation, BTechStartMeasurementMutationVariables>;
export const BTechStopMeasurementDocument = gql`
    mutation BTechStopMeasurement($device: String!) {
  bTechStopMeasurement(device: $device)
}
    `;
export type BTechStopMeasurementMutationFn = Apollo.MutationFunction<BTechStopMeasurementMutation, BTechStopMeasurementMutationVariables>;

/**
 * __useBTechStopMeasurementMutation__
 *
 * To run a mutation, you first call `useBTechStopMeasurementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBTechStopMeasurementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bTechStopMeasurementMutation, { data, loading, error }] = useBTechStopMeasurementMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useBTechStopMeasurementMutation(baseOptions?: Apollo.MutationHookOptions<BTechStopMeasurementMutation, BTechStopMeasurementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BTechStopMeasurementMutation, BTechStopMeasurementMutationVariables>(BTechStopMeasurementDocument, options);
      }
export type BTechStopMeasurementMutationHookResult = ReturnType<typeof useBTechStopMeasurementMutation>;
export type BTechStopMeasurementMutationResult = Apollo.MutationResult<BTechStopMeasurementMutation>;
export type BTechStopMeasurementMutationOptions = Apollo.BaseMutationOptions<BTechStopMeasurementMutation, BTechStopMeasurementMutationVariables>;
export const BTechStartButtonScanDocument = gql`
    mutation BTechStartButtonScan($device: String!, $magneticFieldScanId: String!) {
  bTechStartButtonScan(device: $device, magneticFieldScanId: $magneticFieldScanId)
}
    `;
export type BTechStartButtonScanMutationFn = Apollo.MutationFunction<BTechStartButtonScanMutation, BTechStartButtonScanMutationVariables>;

/**
 * __useBTechStartButtonScanMutation__
 *
 * To run a mutation, you first call `useBTechStartButtonScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBTechStartButtonScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bTechStartButtonScanMutation, { data, loading, error }] = useBTechStartButtonScanMutation({
 *   variables: {
 *      device: // value for 'device'
 *      magneticFieldScanId: // value for 'magneticFieldScanId'
 *   },
 * });
 */
export function useBTechStartButtonScanMutation(baseOptions?: Apollo.MutationHookOptions<BTechStartButtonScanMutation, BTechStartButtonScanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BTechStartButtonScanMutation, BTechStartButtonScanMutationVariables>(BTechStartButtonScanDocument, options);
      }
export type BTechStartButtonScanMutationHookResult = ReturnType<typeof useBTechStartButtonScanMutation>;
export type BTechStartButtonScanMutationResult = Apollo.MutationResult<BTechStartButtonScanMutation>;
export type BTechStartButtonScanMutationOptions = Apollo.BaseMutationOptions<BTechStartButtonScanMutation, BTechStartButtonScanMutationVariables>;
export const BTechSubscribeSensorStateDocument = gql`
    subscription BTechSubscribeSensorState($device: String!) {
  bTechSubscribeSensorState(device: $device) {
    path
    connected
    calibratedAt
  }
}
    `;

/**
 * __useBTechSubscribeSensorStateSubscription__
 *
 * To run a query within a React component, call `useBTechSubscribeSensorStateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBTechSubscribeSensorStateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBTechSubscribeSensorStateSubscription({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useBTechSubscribeSensorStateSubscription(baseOptions: Apollo.SubscriptionHookOptions<BTechSubscribeSensorStateSubscription, BTechSubscribeSensorStateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<BTechSubscribeSensorStateSubscription, BTechSubscribeSensorStateSubscriptionVariables>(BTechSubscribeSensorStateDocument, options);
      }
export type BTechSubscribeSensorStateSubscriptionHookResult = ReturnType<typeof useBTechSubscribeSensorStateSubscription>;
export type BTechSubscribeSensorStateSubscriptionResult = Apollo.SubscriptionResult<BTechSubscribeSensorStateSubscription>;
export type BTechCalibrateMutationVariables = Exact<{
  device: Scalars['String'];
}>;


export type BTechCalibrateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bTechCalibrate'>
);

export type BTechNewRecordMutationVariables = Exact<{
  device: Scalars['String'];
  datasetId: Scalars['String'];
  label: Scalars['String'];
}>;


export type BTechNewRecordMutation = (
  { __typename?: 'Mutation' }
  & { bTechNewRecord: (
    { __typename?: 'BTechNewRecordInfo' }
    & Pick<BTechNewRecordInfo, 'researchBoxId' | 'magneticFieldScanId'>
  ) }
);

export type BTechPauseRecordMutationVariables = Exact<{
  device: Scalars['String'];
  magneticFieldScanId: Scalars['String'];
}>;


export type BTechPauseRecordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bTechPauseRecord'>
);

export type BTechStartScanRecordingMutationVariables = Exact<{
  device: Scalars['String'];
}>;


export type BTechStartScanRecordingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bTechStartScanRecording'>
);

export type BTechDiscardLastScanMutationVariables = Exact<{
  device: Scalars['String'];
  magneticFieldScanId: Scalars['String'];
}>;


export type BTechDiscardLastScanMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bTechDiscardLastScan'>
);

export type BTechFinalizeRecordMutationVariables = Exact<{
  device: Scalars['String'];
  researchBoxId: Scalars['String'];
  datasetId: Scalars['String'];
}>;


export type BTechFinalizeRecordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bTechFinalizeRecord'>
);

export type BTechDevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type BTechDevicesQuery = (
  { __typename?: 'Query' }
  & { bTechDevices: Array<(
    { __typename?: 'BTechDeviceInfo' }
    & Pick<BTechDeviceInfo, 'path' | 'hardwareVersion' | 'protocolVersion' | 'bTechModel' | 'sensorCount' | 'productionYear' | 'version' | 'revision' | 'sensorId'>
  )> }
);

export type BTechSubscribeSensorDataSubscriptionVariables = Exact<{
  device: Scalars['String'];
}>;


export type BTechSubscribeSensorDataSubscription = (
  { __typename?: 'Subscription' }
  & { bTechSubscribeSensorData: (
    { __typename?: 'BTechSensorDataPacket' }
    & Pick<BTechSensorDataPacket, 'blockNr'>
    & { data: Array<(
      { __typename?: 'Vector3f' }
      & Pick<Vector3f, 'x' | 'y' | 'z'>
    )>, baseline: Array<(
      { __typename?: 'Vector3f' }
      & Pick<Vector3f, 'x' | 'y' | 'z'>
    )> }
  ) }
);

export type BTechStartMeasurementMutationVariables = Exact<{
  device: Scalars['String'];
}>;


export type BTechStartMeasurementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bTechStartMeasurement'>
);

export type BTechStopMeasurementMutationVariables = Exact<{
  device: Scalars['String'];
}>;


export type BTechStopMeasurementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bTechStopMeasurement'>
);

export type BTechStartButtonScanMutationVariables = Exact<{
  device: Scalars['String'];
  magneticFieldScanId: Scalars['String'];
}>;


export type BTechStartButtonScanMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bTechStartButtonScan'>
);

export type BTechSubscribeSensorStateSubscriptionVariables = Exact<{
  device: Scalars['String'];
}>;


export type BTechSubscribeSensorStateSubscription = (
  { __typename?: 'Subscription' }
  & { bTechSubscribeSensorState: (
    { __typename?: 'BTechSensorState' }
    & Pick<BTechSensorState, 'path' | 'connected' | 'calibratedAt'>
  ) }
);
