import { ImageDatapointType } from "../../../types/extractedTypes";
import { makeMyAnnotations } from "./hooks/types";
import useGetAnnotations from "./hooks-backend/useGetSegmentation2dAnnotations";
import useInteractionHooks from "./hooks/useInteractionHooks";
import ReadOnly from "./ReadOnly";

type Props = {
  datapoint: ImageDatapointType;
  networkId: string;
};

export default function ReadOnlyDetailsView({ datapoint, networkId }: Props) {
  const markup = useGetAnnotations(datapoint.id, networkId);
  // @ts-ignore
  const annotations = makeMyAnnotations(markup?.annotations);
  const { setHook, callHook } = useInteractionHooks();
  return (
    <ReadOnly
      datapoint={datapoint}
      annotations={annotations}
      setHook={setHook}
      callHook={callHook}
    />
  );
}
