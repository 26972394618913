/* eslint-disable no-unneeded-ternary */
import { useState, useEffect, useMemo } from "react";
import { compact } from "lodash";
import {
  useFindDatasetIdByPipelineIdQuery,
  useGetAllNamesAndDatatypesForDatasetQuery,
} from "../../../ts-clients/query";

export const useKeyPress = (targetKey: string) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  function downHandler({ key }: KeyboardEvent) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  // If released key is our target key then set to false
  const upHandler = ({ key }: KeyboardEvent) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
};

export const useDatasetId = (pipelineId: string) => {
  const { data } = useFindDatasetIdByPipelineIdQuery({
    variables: { pipelineId },
    fetchPolicy: "no-cache",
  });
  return data?.getPipeline?.dataset?.id ?? null;
};

export const useAllOuputLabels = (datasetId: string, onlyDatatype: string) => {
  const { data } = useGetAllNamesAndDatatypesForDatasetQuery({
    variables: {
      datasetId,
    },
    fetchPolicy: "no-cache",
  });

  const outputLabels = useMemo(
    () =>
      compact(
        data?.getDataset?.pipeline?.modules

          ?.map((m) =>
            m.outputs
              .flatMap((o) =>
                o.__typename === "PipelineModuleDatapointOutput" ? o : []
              )
              .filter((o) => o.dataType === onlyDatatype)
              .map((o) => ({ id: o.id, label: o.name }))
          )
          .flat(2) ?? []
      ),
    [data?.getDataset?.pipeline?.modules, onlyDatatype]
  );

  return outputLabels;
};
