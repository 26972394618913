import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { getPath } from "../../utils/routeManager";
import { useGetDatasetNameQuery } from "../../ts-clients/query";
import { canUseFlowdesigner } from "../../state/loginState";

type ItemType =
  | "overview"
  | "files"
  | "networks"
  | "analyze"
  | "report"
  | "settings"
  | "flow-designer";

const classsName = (c1: ItemType, c2: ItemType) =>
  c1 === c2 ? "item active" : "item";

type Props = {
  current: ItemType;
  datasetId: string;
};

export default function ResearchBoxListMenu({ current, datasetId }: Props) {
  const context = "researchboxlist";

  const datasetqueryResult = useGetDatasetNameQuery({
    variables: { datasetId },
  });
  const datasetName =
    datasetqueryResult.data?.getDataset?.name ?? "dataset name";

  const canUseFlowDesignerMenu = useSelector(canUseFlowdesigner);

  const links = useMemo(
    () => [
      {
        icon: "chevron up",
        name: "Home",
        to: getPath("Dashboard", {}),
        className: classsName(current, "overview"),
      },
      {
        name: "Files",
        icon: "folder",
        to: getPath("ResearchBoxList", {
          datasetId,
          context,
        }),
        className: classsName(current, "files"),
      },
      {
        icon: "outline chart bar",
        name: "Networks",
        to: getPath("NetworkStats", { datasetId }),
        className: classsName(current, "networks"),
      },
      {
        icon: "settings",
        name: "Settings",
        to: getPath("DatasetSettings", { datasetId, page: "base" }),
        className: classsName(current, "settings"),
      },
      {
        name: "Flow Designer",
        icon: "write",
        to: getPath("FlowDesigner", { datasetId, context }),
        className: classsName(current, "flow-designer"),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current, datasetId, datasetName]
  );

  const noFlowDesignerLinks = useMemo(
    () => links.filter((l) => l.name !== "Flow Designer"),
    [links]
  );

  return (
    <Menu
      className="center aligned"
      borderless
      inverted
      style={{
        position: "fixed",
        left: "50%",
        width: 500,
        top: 0,
        zIndex: 10002,
        margin: "0 0 0 -250px",
        backgroundColor: "unset",
        border: 0,
        boxShadow: "unset",
      }}
    >
      {canUseFlowDesignerMenu &&
        links.map((l) => (
          <Menu.Item
            key={`${l.to ?? ""}${l.name ?? ""}`}
            as={l.to ? Link : "div"}
            className={l.className}
            to={l.to}
            name={l.name}
            icon={l.icon}
            style={{ border: 0 }}
          />
        ))}
      {!canUseFlowDesignerMenu &&
        noFlowDesignerLinks.map((l) => (
          <Menu.Item
            key={`${l.to ?? ""}${l.name ?? ""}`}
            as={l.to ? Link : "div"}
            className={l.className}
            to={l.to}
            name={l.name}
            icon={l.icon}
            style={{ border: 0 }}
          />
        ))}
    </Menu>
  );
}
