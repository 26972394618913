/* eslint-disable react/jsx-one-expression-per-line */
import ResearchBoxListMenu from "./ResearchBoxListMenu";
import Center from "../../features/filemanagement/DefaultPositioner";
import { useParseRouteParams } from "../../utils/routeManager";
import DatasetOverview from "../../features/dataset-overview";
import MenuMain from "../../components/menus/menu.main";

export default function DetailsDatapointContainer() {
  const pageParams = useParseRouteParams("DatasetOverview");

  return (
    <>
      <MenuMain />
      <ResearchBoxListMenu
        current="overview"
        datasetId={pageParams.datasetId}
      />
      <Center>
        <DatasetOverview />
      </Center>
    </>
  );
}
