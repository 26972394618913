import { Divider, Container, Message } from "semantic-ui-react";
import ModuleCreate from "../../../flow-designer/fneu/ModuleCreate";
import PretrainedSelection from "./PretrainedSelection";
import ChooseMainNetworkType from "./ChooseMainNetworkType";
import Headline from "./Headline";
import { useEffect, useState } from "react";
import { useGoto } from "../../../../utils/routeManager";
import { PretrainedNetworkType } from "../../../../types/extractedTypes";
import { usePretrainedNetworks } from "./usePretrainedNetworks";

type Props = {
  datasetId: string | null;
  defaultModuleType: PretrainedNetworkType | null;
};

export default function AddNetwork({ datasetId, defaultModuleType }: Props) {
  const [networkType, setNetworkType] = useState<PretrainedNetworkType | null>(
    null
  );

  const {
    getPretrainedNetworks,
    setSelectedPretrainedNetworkModuleId,
    selectedPretrainedNetwork,
    loading,
  } = usePretrainedNetworks(networkType);

  useEffect(() => {
    setNetworkType(defaultModuleType);
  }, [defaultModuleType]);

  const goto = useGoto();

  const handleCancel = () => {
    if (datasetId === null) {
      goto("Dashboard", {});
    } else {
      if (networkType !== null) {
        setNetworkType(null);
      } else {
        goto("NetworkStats", { datasetId });
      }
    }
  };

  return (
    <>
      <Container>
        <Headline networkType={networkType} onCancel={handleCancel} />
        <Divider />
        {networkType === null && (
          <ChooseMainNetworkType
            onSelect={setNetworkType}
            isReady={!loading}
            complexNetworkCreation
          />
        )}
        {networkType !== null && (
          <>
            {selectedPretrainedNetwork !== null ? (
              <ModuleCreate
                from={selectedPretrainedNetwork}
                moduleType={networkType}
                onCancel={handleCancel}
                datasetId={datasetId}
              />
            ) : (
              <>
                <PretrainedSelection
                  onSelectModule={setSelectedPretrainedNetworkModuleId}
                  getPretrainedNetworks={getPretrainedNetworks}
                />
                <Message content="Please select a network." />
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
}
