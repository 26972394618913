import {
  PipelineModuleContrastEnhancementProps as ModuleProps,
  BaseParams,
} from "ModuleTypes";
import { Grid, Form, Header, Segment, Label } from "semantic-ui-react";
import * as Input from "./InputTypes";
import * as Base from "./BaseParams";

type Props = {
  moduleData: ModuleProps;
  onCancel: () => unknown;
  onSave: () => unknown;
  setData: (m: ModuleProps) => unknown;
  setBaseParams: (b: BaseParams) => void;
};

export default function PipelineModuleContrastEnhancement({
  moduleData,
  onCancel,
  onSave,
  setData,
  setBaseParams,
}: Props) {
  const onSubmit = () => {
    onSave();
    onCancel();
  };

  const setCurrentModuleName = (v: string) => {
    setData({ ...moduleData, moduleName: v });
  };

  const { enhancedImageName, clipLimit, tileSize } = moduleData;

  return (
    <Form onSubmit={onSubmit}>
      <Base.BaseParamsForm
        params={moduleData}
        setParams={setBaseParams}
        setCurrentModuleName={setCurrentModuleName}
      />
      <Segment>
        <Label attached="top" size="small">
          <Header>Output Name</Header>
        </Label>
        <Input.StringInput
          content={enhancedImageName}
          label="enhancedImageName"
          onChange={(v) => setData({ ...moduleData, enhancedImageName: v })}
        />
      </Segment>
      <Segment>
        <Label attached="top" size="small">
          <Header>Module Specific Params</Header>
        </Label>
        <Grid columns="2">
          <Grid.Column>
            <Input.IntInput
              content={clipLimit}
              label="clipLimit"
              onChange={(v) => setData({ ...moduleData, clipLimit: v })}
            />
          </Grid.Column>
          <Grid.Column>
            <Input.IntInput
              content={tileSize}
              label="tileSize"
              onChange={(v) => setData({ ...moduleData, tileSize: v })}
            />
          </Grid.Column>
        </Grid>
      </Segment>

      <Base.Footer onCancel={onCancel} />
    </Form>
  );
}
