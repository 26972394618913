import { Switch, Route } from "react-router-dom";
import FlowView from "./FlowView";
import ModuleCreateRouteShim from "./ModuleCreateRouteShim";
import ModuleUpdateRouteShim from "./ModuleUpdateRouteShim";
import { useGoto, getRoutePattern } from "../../utils/routeManager";

type Props = {
  datasetId: string;
};

export default function FlowIndex({ datasetId }: Props) {
  const goTo = useGoto();
  const gotoMainMainPage = () =>
    goTo("FlowDesigner", { datasetId, context: "researchboxlist" });
  return (
    <Switch>
      <Route exact path={getRoutePattern("FlowDesigner")}>
        <FlowView datasetId={datasetId} />
      </Route>
      <Route path={getRoutePattern("FlowDesignerAddModule")}>
        <ModuleCreateRouteShim onCancel={gotoMainMainPage} />
      </Route>
      <Route path={getRoutePattern("FlowDesignerEditModule")}>
        <ModuleUpdateRouteShim onCancel={gotoMainMainPage} />
      </Route>
    </Switch>
  );
}
