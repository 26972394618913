import { ModuleType } from "ModuleTypes";
import { Container, Segment, Dimmer, Loader } from "semantic-ui-react";

export const renderLoading = () => (
  <Container>
    <Segment>
      <Dimmer active>
        <Loader />
      </Dimmer>
    </Segment>
  </Container>
);

// const inputSubmittable = (f: FormData): boolean =>
//   f.name.length > 3 &&
//   Array.from(f.outputs.values()).reduce(
//     (c: boolean, p) => c && p.label.length > 0 && p.key.length > 0,
//     true
//   ) &&
//   f.parameters.reduce(
//     (c: boolean, p) =>
//       c && !Number.isNaN(p.value as number) && p.type.length > 0,
//     true
//   );

// export type ModuleType =

const niceModuleNames = new Map<ModuleType, string>([
  ["PipelineModuleCCChecker", "Cross-Connector Checker"],
  ["PipelineModuleContrastEnhancement", "Contrast Enhancement"],
  ["PipelineModuleNetworkImageClassification", "Image Classification Network"],
  ["PipelineModuleNetworkImageSegmentation", "Image Segmentation Network"],
  ["unknown-type", "unknown-type"],
  ["PipelineModuleUpload", "Upload-Modul"],
  ["PipelineModuleMarkup", "Markup-Modul"],
  ["PipelineModuleBMobile", "B-Mobile-Input-Modul"],
  ["PipelineModuleNetworkImageObjectDetection", "Object Detection"],
]);

export function getNiceModuleName(t: ModuleType): string {
  return niceModuleNames.get(t) ?? t.toString();
}

export function getNiceModuleNameFromString(s: string): string {
  const mt = s as ModuleType;
  return niceModuleNames.get(mt) ?? s;
}
