/* eslint-disable react/jsx-curly-newline */
import { useCallback, useState, useEffect } from "react";
import {
  MyBoundingBox,
  MyCircle,
  MyMagicWand,
  MyPolygon,
  MyRectangle,
  MySausage,
} from "../../hooks/types";
import { Annotations2Type } from "../../hooks/useAnnotations2";

type Props = {
  setActivePolygonId: (id: string | null) => void;
  paintActiveAnnotationId: string | null;
  selectedPolygonId: string | null;
  annotationActions: Annotations2Type["annotationActions"];
  scale: number;
};

// const removeId =
//   (paintId: string | null, selectId: string | null) =>
//   <T extends MyID>(ls: T[]): T[] =>
//     ls.filter((l) => l.id !== paintId && l.id !== selectId);

// const selectedId =
//   (selectId: string | null) =>
//   <T extends MyID>(ls: T[]): T[] =>
//     ls.filter((l) => l.id === selectId);

export default function useAnnotationsLayer({
  annotationActions,
  setActivePolygonId,
}: Props) {
  // prevent double rendering of annotations in paint mode
  // (do not render pixels AND polygon) of the same annotation
  // const rem = useMemo(
  //   () => removeId(paintActiveAnnotationId, selectedPolygonId),
  //   [paintActiveAnnotationId, selectedPolygonId]
  // );
  // const sel = useMemo(() => selectedId(selectedPolygonId), [selectedPolygonId]);

  const select = useCallback(
    (id: string) => () => setActivePolygonId(id),
    [setActivePolygonId]
  );

  const setSelectedPixel = useCallback(
    (id: string | null) => {
      setActivePolygonId(id);
    },
    [setActivePolygonId]
  );

  const [replacedCircle, setReplacedCircle] = useState<MyCircle | null>(null);
  useEffect(() => {
    if (replacedCircle) {
      annotationActions.circleReplace(replacedCircle.id, replacedCircle);
      setReplacedCircle(null);
    }
  }, [annotationActions, replacedCircle]);

  const updateCircle = useCallback((annotation: MyCircle | null) => {
    setReplacedCircle(annotation);
  }, []);

  const [replacedRectangle, setReplacedRectangle] =
    useState<MyRectangle | null>(null);

  useEffect(() => {
    if (replacedRectangle) {
      annotationActions.rectangleReplace(
        replacedRectangle.id,
        replacedRectangle
      );
      setReplacedRectangle(null);
    }
  }, [annotationActions, replacedRectangle]);

  const [replacedBoundingBox, setReplacedBoundingBox] =
    useState<MyBoundingBox | null>(null);

  useEffect(() => {
    if (replacedBoundingBox) {
      annotationActions.boundingBoxReplace(
        replacedBoundingBox.id,
        replacedBoundingBox
      );
      setReplacedBoundingBox(null);
    }
  }, [annotationActions, replacedBoundingBox]);

  const updateRectangle = useCallback((annotation: MyRectangle | null) => {
    setReplacedRectangle(annotation);
  }, []);

  const updateBoundingBox = useCallback((annotation: MyBoundingBox | null) => {
    setReplacedBoundingBox(annotation);
  }, []);

  const [replacedPolygon, setReplacedPolygon] = useState<MyPolygon | null>(
    null
  );

  useEffect(() => {
    if (replacedPolygon) {
      annotationActions.polygonReplace(replacedPolygon.id, replacedPolygon);
      setReplacedPolygon(null);
    }
  }, [annotationActions, replacedPolygon]);

  const updatePolygon = useCallback((annotation: MyPolygon | null) => {
    setReplacedPolygon(annotation);
  }, []);

  const [replacedMagicWand, setReplacedMagicWand] =
    useState<MyMagicWand | null>(null);

  useEffect(() => {
    if (replacedMagicWand) {
      annotationActions.magicWandReplace(
        replacedMagicWand.id,
        replacedMagicWand
      );
      setReplacedMagicWand(null);
    }
  }, [annotationActions, replacedMagicWand]);

  const updateMagicWand = useCallback((annotation: MyMagicWand | null) => {
    setReplacedMagicWand(annotation);
  }, []);

  const [replacedSausage, setReplacedSausage] = useState<MySausage | null>(
    null
  );

  useEffect(() => {
    if (replacedSausage) {
      annotationActions.sausageReplace(replacedSausage.id, replacedSausage);
      setReplacedSausage(null);
    }
  }, [annotationActions, replacedSausage]);

  const updateSausage = useCallback((annotation: MySausage | null) => {
    setReplacedSausage(annotation);
  }, []);

  return {
    setSelectedPixel,
    updateCircle,
    updateMagicWand,
    updatePolygon,
    updateRectangle,
    updateBoundingBox,
    updateSausage,
    select,
  };
}
