import {
  DataType,
  useGetDatapointDetailsQuery,
  useGetDatasetPropertiesQuery,
  useCheckDatasetForMarkupQuery,
} from "../../../ts-clients/query";

export type ImageResolution = {
  width: number;
  height: number;
};

type DatasetProperties = {
  dataTypes: DataType[];
  resolutions: ImageResolution[];
};

export function useCheckDatasetForMarkup(datasetId: string) {
  const { data } = useCheckDatasetForMarkupQuery({
    variables: {
      datasetId,
    },
  });

  return (data?.queryImageMarkup?.length || 0) > 0;
}

export function useDataSetProperties(datasetId: string): DatasetProperties {
  const { data } = useGetDatasetPropertiesQuery({
    variables: {
      datasetId,
    },
  });

  return {
    dataTypes:
      data?.getDataset?.aggregateDatapointTypes.map((d) => d as DataType) ?? [],
    resolutions: data?.getDataset?.aggregateImageResolutions ?? [],
  };
}

export function useDatapointImageResolutions(datapointId: string) {
  const { data } = useGetDatapointDetailsQuery({
    variables: {
      id: datapointId,
    },
  });

  const res = (data?.queryDatapoint ?? []).flatMap((d) =>
    d?.__typename === "Image" ? d : null
  );
  return res.length > 0 ? res[0]?.resolutions ?? [] : [];
}

export function useDatapoint(datapointId: string) {
  const { data } = useGetDatapointDetailsQuery({
    variables: {
      id: datapointId,
    },
  });
  const [dp] = data?.queryDatapoint ?? [];
  return dp;
}
