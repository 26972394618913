export type UIType = "common" | "ccchecker" | "halm";

export default function useUIType(): UIType {
  if (!process.env.REACT_APP_UI_TYPE) {
    return "common";
  }
  if (process.env.REACT_APP_UI_TYPE === "HALM") {
    return "halm";
  }
  return "ccchecker";
}
