import { Image } from "semantic-ui-react";
import { ResearchBoxType } from "../../types/extractedTypes";
import noimg from "../../resources/noimg.png";
import { useUIType } from "../../hooksqueries";
import { useDrag } from "react-dnd";

type Props = {
  researchBox: ResearchBoxType;
  onClick?: () => void;
};

export default function ResearchBoxRowItem({ researchBox, onClick }: Props) {
  const uiType = useUIType();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "researchbox",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: researchBox,
    canDrag: uiType !== "halm",
  }));

  return (
    <div ref={drag}>
      <Image
        style={{ opacity: isDragging ? 0.5 : 1.0 }}
        onClick={onClick}
        src={
          researchBox.datapoints
            ?.flatMap((d) => (d.__typename === "Image" ? d : []))
            .pop()?.thumbnail || noimg
        }
        size="mini"
      />
    </div>
  );
}
