import {
  PipelineModuleCCCheckerProps as ModuleProps,
  BaseParams,
} from "ModuleTypes";
import { Grid, Form, Header, Segment, Label } from "semantic-ui-react";
import * as Input from "./InputTypes";
import * as Base from "./BaseParams";

type Props = {
  moduleData: ModuleProps;
  onCancel: () => unknown;
  onSave: () => unknown;
  setData: (m: ModuleProps) => unknown;
  setBaseParams: (b: BaseParams) => unknown;
};

export default function PipelineModuleCCChecker({
  moduleData,
  onCancel,
  onSave,
  setData,
  setBaseParams,
}: Props) {
  const onSubmit = () => {
    onSave();
    onCancel();
  };

  const setCurrentModuleName = (v: string) => {
    setData({ ...moduleData, moduleName: v });
  };

  const {
    busBarCountPerCell,
    cccheckerResultName,
    cutOffRate,
    maxShift,
    minCorrValue,
    minPeakDistance,
    minPeakHeightPercentage,
    minPeakProminence,
  } = moduleData;

  return (
    <Form onSubmit={onSubmit}>
      <Base.BaseParamsForm
        params={moduleData}
        setParams={setBaseParams}
        setCurrentModuleName={setCurrentModuleName}
      />
      <Segment>
        <Label attached="top" size="small">
          <Header>Output Name</Header>
        </Label>
        <Input.StringInput
          content={cccheckerResultName}
          label="cccheckerResultName"
          onChange={(v) => setData({ ...moduleData, cccheckerResultName: v })}
        />
      </Segment>
      <Segment>
        <Label attached="top" size="small">
          <Header>Module Specific Params</Header>
        </Label>
        <Grid columns="4">
          <Grid.Column>
            <Input.IntInput
              content={busBarCountPerCell}
              label="busBarCountPerCell"
              onChange={(v) =>
                setData({ ...moduleData, busBarCountPerCell: v })
              }
            />
          </Grid.Column>
          <Grid.Column>
            <Input.IntInput
              content={cutOffRate}
              label="cutOffRate"
              onChange={(v) => setData({ ...moduleData, cutOffRate: v })}
            />
          </Grid.Column>
          <Grid.Column>
            <Input.IntInput
              content={maxShift}
              label="maxShift"
              onChange={(v) => setData({ ...moduleData, maxShift: v })}
            />
          </Grid.Column>
          <Grid.Column>
            <Input.IntInput
              content={minCorrValue}
              label="minCorrValue"
              onChange={(v) => setData({ ...moduleData, minCorrValue: v })}
            />
          </Grid.Column>

          <Grid.Column>
            <Input.IntInput
              content={minPeakDistance}
              label="minPeakDistance"
              onChange={(v) => setData({ ...moduleData, minPeakDistance: v })}
            />
          </Grid.Column>

          <Grid.Column>
            <Input.IntInput
              content={minPeakHeightPercentage}
              label="minPeakHeightPercentage"
              onChange={(v) =>
                setData({ ...moduleData, minPeakHeightPercentage: v })
              }
            />
          </Grid.Column>

          <Grid.Column>
            <Input.IntInput
              content={minPeakProminence}
              label="minPeakProminence"
              onChange={(v) => setData({ ...moduleData, minPeakProminence: v })}
            />
          </Grid.Column>
        </Grid>
      </Segment>
      <Base.Footer onCancel={onCancel} />
    </Form>
  );
}
