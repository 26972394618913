/* eslint-disable no-underscore-dangle */
import { useDispatch } from "react-redux";
import {
  MagneticFieldScan,
  useGetLastBtechResultQuery,
} from "../ts-clients/query";
import { setValidScan } from "../state/deviceControl";

export default function useGetLastBTechResult(datasetId: string) {
  const dispatch = useDispatch();
  const { data, startPolling, stopPolling } = useGetLastBtechResultQuery({
    variables: {
      datasetId,
    },
    fetchPolicy: "cache-and-network",
  });

  const dataset =
    data?.getDataset !== null && data?.getDataset !== undefined
      ? data.getDataset
      : null;

  // @ts-ignore
  const lastIndex = dataset?.researchBoxesAggregate?.count ?? 0;

  const researchbox =
    // @ts-ignore
    dataset?.researchBoxes !== null && dataset?.researchBoxes !== undefined
      ? // @ts-ignore
        dataset?.researchBoxes[0]
      : null;

  const datapoints =
    researchbox?.datapoints !== null && researchbox?.datapoints !== undefined
      ? researchbox?.datapoints
      : [];

  const magneticFieldScan: MagneticFieldScan | null =
    // @ts-ignore
    datapoints.find(({ __typename: t }) => t === "MagneticFieldScan") ?? null;

  dispatch(setValidScan((magneticFieldScan?.startIndices.length ?? 0) > 0));

  return {
    datapoints,
    researchboxIdx: lastIndex - 1,
    startPolling,
    stopPolling,
  };
}
