import { ccCheckerFlags, denkweitFlags, halmFlags } from "../featureToggles/features";
import { useUIType } from "../hooksqueries";

export default function useFeatureFlags() {
    const uiType = useUIType();
    if (uiType === 'common') {
        return denkweitFlags;
    }
    if (uiType === 'halm') {
        return halmFlags;
    }
    return ccCheckerFlags;
}