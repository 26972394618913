/* eslint-disable react/jsx-one-expression-per-line */
import { useSelector, useDispatch } from "react-redux";
import { Header, Segment, Grid, Menu, Icon } from "semantic-ui-react";
import {
  getDevicePath,
  getFileName,
  getFileNameParts,
  getSensorState,
  isValidScan,
  setFileNameParts,
  setSensorState,
  getCurrentResearchBoxId,
  getCurrentScanId,
  setCurrentScanId,
  setCurrentResarchBoxId,
} from "../../state/deviceControl";

import {
  useBTechNewRecordMutation,
  useBTechStartScanRecordingMutation,
  useBTechPauseRecordMutation,
  useBTechFinalizeRecordMutation,
  useBTechStartButtonScanMutation,
  useBTechStopMeasurementMutation,
  useBTechDiscardLastScanMutation,
  useBTechSubscribeSensorStateSubscription,
} from "../../ts-clients/btech";

import { useDeleteResearchBoxMutation } from "../../ts-clients/command";

type Props = {
  datasetId: string;
};

export default function MagneticFieldScanView({ datasetId }: Props) {
  const dispatch = useDispatch();

  const currentDevicePath = useSelector(getDevicePath);
  const currentFilename = useSelector(getFileName);
  const currentFilenameParts = useSelector(getFileNameParts);
  const isValid = useSelector(isValidScan);
  const currentScanId = useSelector(getCurrentScanId);
  const currentResearchBoxId = useSelector(getCurrentResearchBoxId);

  const menuSize = "huge";

  const sensorState = useSelector(getSensorState);

  const { data: deviceState } = useBTechSubscribeSensorStateSubscription({
    variables: {
      device: currentDevicePath || "",
    },
    skip: !currentDevicePath,
  });

  const [bTechNewRecord] = useBTechNewRecordMutation({
    variables: {
      device: currentDevicePath || "",
      datasetId,
      label: currentFilename,
    },
    onCompleted: (data) => {
      dispatch(setCurrentScanId(data.bTechNewRecord.magneticFieldScanId));
      dispatch(setCurrentResarchBoxId(data.bTechNewRecord.researchBoxId));
    },
  });

  const [bTechStartScanRecording] = useBTechStartScanRecordingMutation({
    variables: {
      device: currentDevicePath || "",
    },
  });

  const [bTechPauseRecord] = useBTechPauseRecordMutation({
    variables: {
      device: currentDevicePath || "",
      magneticFieldScanId: currentScanId || "",
    },
  });

  const [bTechFinalizeRecord] = useBTechFinalizeRecordMutation({
    variables: {
      device: currentDevicePath || "",
      researchBoxId: currentResearchBoxId || "",
      datasetId,
    },
  });

  const [abortRecord] = useDeleteResearchBoxMutation({
    variables: {
      input: {
        ids: [currentResearchBoxId || ""],
      },
    },
  });

  const [bTechStopMeasurement] = useBTechStopMeasurementMutation({
    variables: {
      device: currentDevicePath || "",
    },
  });

  const [bTechStartButtonScan] = useBTechStartButtonScanMutation({
    variables: {
      device: currentDevicePath || "",
      magneticFieldScanId: currentScanId || "",
    },
    onCompleted: () => bTechStartButtonScan(),
  });

  const [discardLastScan] = useBTechDiscardLastScanMutation({
    variables: {
      device: currentDevicePath || "",
      magneticFieldScanId: currentScanId || "",
    },
  });

  const handleSaveRecord = () => {
    dispatch(setSensorState("idle"));
    bTechFinalizeRecord();
    let lastCounterIdx: number | undefined;
    currentFilenameParts.forEach((part, idx) => {
      if (part.type === "counter") {
        lastCounterIdx = idx;
      }
    });

    if (lastCounterIdx) {
      const fileNameParts = currentFilenameParts.slice();
      fileNameParts[lastCounterIdx || 0] = {
        ...fileNameParts[lastCounterIdx || 0],
        value: String(
          parseInt(fileNameParts[lastCounterIdx || 0].value, 10) + 1
        ),
      };
      dispatch(setFileNameParts(fileNameParts));
    }
  };

  return (
    <>
      <Segment.Group>
        <Segment style={{ backgroundColor: "#f4f4f4" }}>
          <Header as="h2">Sensor Control</Header>
        </Segment>
        <Segment>
          <Grid>
            <Grid.Column width="10">
              <Menu stackable compact size={menuSize}>
                {sensorState === "idle" && (
                  <Menu.Item
                    disabled={!deviceState?.bTechSubscribeSensorState.connected}
                    name="new record"
                    onClick={() => {
                      dispatch(setSensorState("recording"));
                      bTechNewRecord();
                    }}
                  >
                    <Icon name="add" />
                    new record
                  </Menu.Item>
                )}
                {sensorState === "scanning" && (
                  <Menu.Item
                    disabled={!deviceState?.bTechSubscribeSensorState.connected}
                    onClick={() => {
                      dispatch(setSensorState("paused"));
                      bTechPauseRecord();
                    }}
                  >
                    <Icon name="pause" />
                    pause scan
                  </Menu.Item>
                )}
                {(sensorState === "recording" || sensorState === "paused") && (
                  <Menu.Item
                    disabled={!deviceState?.bTechSubscribeSensorState.connected}
                    onClick={() => {
                      dispatch(setSensorState("scanning"));
                      bTechStartScanRecording();
                    }}
                  >
                    <Icon name="play" />
                    {sensorState === "recording"
                      ? "start scan"
                      : "continue scan"}
                  </Menu.Item>
                )}
                {(sensorState === "recording" || sensorState === "paused") && (
                  <Menu.Item
                    disabled={!deviceState?.bTechSubscribeSensorState.connected}
                    onClick={() => {
                      dispatch(setSensorState("armed-scanning"));
                      bTechStartButtonScan();
                    }}
                  >
                    <Icon name="play circle" />
                    arm sensor
                  </Menu.Item>
                )}
                {(sensorState === "recording" || sensorState === "paused") && (
                  <Menu.Item
                    disabled={
                      !deviceState?.bTechSubscribeSensorState.connected ||
                      !isValid
                    }
                    onClick={handleSaveRecord}
                  >
                    <Icon name="save" />
                    finish record
                  </Menu.Item>
                )}
                {sensorState === "armed-scanning" && (
                  <Menu.Item
                    disabled={!deviceState?.bTechSubscribeSensorState.connected}
                    onClick={() => {
                      dispatch(setSensorState("recording"));
                      bTechStopMeasurement();
                    }}
                  >
                    <Icon name="pause" />
                    disarm sensor
                  </Menu.Item>
                )}
              </Menu>
            </Grid.Column>
            <Grid.Column width="6">
              {(sensorState === "recording" ||
                sensorState === "paused" ||
                sensorState === "armed-scanning") && (
                <Menu stackable compact size={menuSize}>
                  <Menu.Item
                    disabled={
                      !deviceState?.bTechSubscribeSensorState.connected ||
                      !isValid
                    }
                    size={menuSize}
                    onClick={() => discardLastScan()}
                  >
                    <Icon color="yellow" name="undo alternate" />
                    delete last scan
                  </Menu.Item>
                  <Menu.Item
                    disabled={!deviceState?.bTechSubscribeSensorState.connected}
                    size={menuSize}
                    onClick={() => {
                      dispatch(setSensorState("idle"));
                      abortRecord();
                    }}
                  >
                    <Icon color="red" name="cancel" />
                    abort
                  </Menu.Item>
                </Menu>
              )}
            </Grid.Column>
          </Grid>
        </Segment>
      </Segment.Group>
    </>
  );
}
