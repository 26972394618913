import { Link } from "react-router-dom";
import { Divider, Header, Menu, Form, Button } from "semantic-ui-react";
import { getPath } from "../../../utils/routeManager";
import useNetworkList from "./hooks/useNetworkList";
import TrainingControl from "./TrainingControl";
import NiceEmpty from "./util/NiceEmpty";

export default function NewList() {
  const {
    userCanMutateNetworks,
    datasetId,
    filterString,
    setFilterString,
    networks,
    networksExist,
  } = useNetworkList();

  return (
    <>
      <div style={{ margin: "0 19rem 0 15px", position: "relative" }}>
        <Header as="h1">
          {userCanMutateNetworks && (
            <>
              Networks
              <Button
                positive
                className="ui positive right floated button"
                to={getPath("NetworkAdd", {
                  datasetId,
                  menuType: "foo",
                })}
                content="Add New Network"
                icon="plus"
                as={Link}
              />
            </>
          )}
        </Header>
        <Divider />
        <NiceEmpty
          empty={!networksExist}
          emptyFilter={networks.length === 0}
          dataSetId={datasetId}
          userCanMutateNetworks={userCanMutateNetworks}
        >
          <TrainingControl networks={networks} datasetId={datasetId} />
        </NiceEmpty>
      </div>
      <Menu
        fixed="right"
        vertical
        style={{
          padding: "15px",
          marginTop: 40,
          backgroundColor: "#f4f4f4",
          width: "18rem",
        }}
      >
        <Header as="h4" content="Filter" />
        <Divider />
        <Form>
          <Form.Input
            label="Filter Networks"
            value={filterString}
            onChange={(_, { value }) => setFilterString(value)}
          />

          {/* dropdown mit allen Metrics, die es gibt. ich kann hier einen auswählen und der eine wird dann in der Tabelle angezeigt */}
          <Form.Dropdown />
        </Form>
      </Menu>
    </>
  );
}
