import { useDispatch } from "react-redux";
import { useLoginMutation } from "../../ts-clients/command";
import { Role } from "../../ts-clients/query";
import { setCurrentUser, setLoginState } from "../../state/loginState";

export default function useLogin(username: string, password: string) {
  const dispatch = useDispatch();
  const [login] = useLoginMutation({
    variables: {
      input: {
        username,
        password,
      },
    },
    onCompleted: (data) => {
      dispatch(
        setCurrentUser({
          username,
          role: data.login?.role || Role.Unauthorized,
          token: data.login?.token || "",
          allowedFeatures: data.login?.allowedFeatures || [],
          blockedFeatures: data.login?.blockedFeatures || [],
        })
      );
      dispatch(setLoginState("logged-in"));
    },
    onError: () => {
      dispatch(setLoginState("invalid"));
    },
  });

  const doLogin = () => {
    login();
  };

  return doLogin;
}
