import { MarkupLabel } from "MarkupTypes";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Menu, Dropdown, Button, Label } from "semantic-ui-react";
import { canMutateMarkup } from "../../../../../state/loginState";
import { InterActionAction } from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks/types";
import { InteractionHooksType } from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks/useInteractionHooks";
import { ImageInfo } from "../../hooks/useImageInfo";
import { isCursorHidden } from "../../inputImage/redux-state";
import HelpModal from "./Help";
import ImageStatus from "./ImageStatus";

type Props = {
  canUndo: boolean;
  canRedo: boolean;
  save: () => void;
  del: () => void;
  clear: () => void;
  labelList: MarkupLabel[];
  currentLabelId: string;
  setCurrentNetworkLabelIdAndColor: (
    label: string,
    color: string,
    replace: boolean
  ) => void;
  segmentationsVisible: boolean;
  toggleSegmentationsVisible: () => void;
  paintActive: string | null;
  callHook: InteractionHooksType["callHook"];
  trigger: (a: InterActionAction) => void;
  imageInfo: ImageInfo;
  lastPredictionTime: string | null;
  subset: string;
};

export default function LayerToolsIndex({
  canRedo,
  canUndo,
  save,
  clear,
  del,
  labelList,
  setCurrentNetworkLabelIdAndColor,
  currentLabelId,
  segmentationsVisible,
  toggleSegmentationsVisible,
  paintActive,
  callHook,
  trigger,
  imageInfo,
  lastPredictionTime,
  subset,
}: Props) {
  // always select the first available network label
  useEffect(() => {
    if (
      labelList.length > 0 &&
      !labelList.find((l) => l.id === currentLabelId)
    ) {
      setCurrentNetworkLabelIdAndColor(
        labelList[0].id,
        labelList[0].color,
        true
      );
    }
  }, [currentLabelId, labelList, setCurrentNetworkLabelIdAndColor]);

  const isMarkupMutable = useSelector(canMutateMarkup);

  const cursorHidden = useSelector(isCursorHidden);

  const handleAction = (a: "clear" | "delete") => {
    if (a === "clear") {
      clear();
    }
    if (a === "delete") {
      del();
    }
  };

  return (
    <Menu attached="top">
      <Menu.Item
        content="undo"
        icon="undo"
        disabled={!canUndo || !isMarkupMutable}
        onClick={() => trigger("undo")}
      />
      <Menu.Item
        content="redo"
        icon="redo"
        disabled={!canRedo || !isMarkupMutable}
        onClick={() => trigger("redo")}
      />
      <Menu.Item>
        <Button
          icon={segmentationsVisible ? "eye" : "eye slash"}
          color={segmentationsVisible ? undefined : "red"}
          disabled={paintActive !== null}
          onClick={() => toggleSegmentationsVisible()}
        />
        &nbsp;
        <Button icon="expand" onClick={() => callHook("stageResetZoom")} />
      </Menu.Item>
      {paintActive !== null && (
        <Menu.Item>
          <Button.Group>
            <Button
              icon="delete"
              onClick={() => trigger("resetPaint")}
              color="grey"
              content="Cancel"
            />
            <Button
              icon="check"
              onClick={() => trigger("paintFinish")}
              content="Finish"
              color="pink"
            />
          </Button.Group>
        </Menu.Item>
      )}
      {cursorHidden && (
        <Menu.Item>
          <Label color="red" content="cursor hidden" />
        </Menu.Item>
      )}
      <Menu.Menu position="right">
        <Menu.Item position="right">
          <ImageStatus
            lastPredictionTime={lastPredictionTime}
            imageInfo={imageInfo}
            subset={subset}
          />
        </Menu.Item>
        <Menu.Item position="right">
          <HelpModal />
        </Menu.Item>
        <Menu.Item position="right">
          <Button.Group color="green">
            <Button
              onClick={save}
              icon="save"
              content="Save &amp; Next"
              style={{ fontWeight: "bold" }}
              disabled={!isMarkupMutable}
            />
            <Dropdown
              className="button icon"
              floating
              value=""
              disabled={!isMarkupMutable}
              options={[
                {
                  key: "clear",
                  text: "Clear & Next",
                  value: "clear",
                },
                {
                  key: "delete",
                  text: "Delete & Next",
                  value: "delete",
                },
              ]}
              trigger={<></>}
              onChange={(_, { value }) =>
                handleAction(value as "clear" | "delete")
              }
            />
          </Button.Group>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
}
