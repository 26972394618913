import {
  useCopyDatasetMutation,
  CopyDatasetInput,
} from "../../../../ts-clients/command";

export default function useCopyDataset(input: CopyDatasetInput) {
  const [copyDataset] = useCopyDatasetMutation();
  return () =>
    copyDataset({
      variables: {
        input,
      },
    });
}
