import { useMemo } from "react";
import { useGetSegmentationMarkupsForImagesAndNetworkQuery } from "../../../../ts-clients/query";
import { AnnotationType } from "../../../../ts-clients/command";
import {
  MyLabeldSausage,
  MyLabeledCircle,
  MyLabeledMagicWand,
  MyLabeledPen,
  MyLabeledPixels,
  MyLabeledPolygon,
  MyLabeledRectangle,
  PlattformAnnotationsAndPredictions,
} from "../hooks/types";

export default function useGetAnnotations(imageId: string, networkId: string) {
  const { data, refetch } = useGetSegmentationMarkupsForImagesAndNetworkQuery({
    variables: {
      imageIds: [imageId],
      networkId,
    },
    fetchPolicy: "network-only",
  });

  const predictions =
    data?.queryImage
      ?.map((i) =>
        i?.predictions.flatMap((m) =>
          m.__typename === "SegmentationPrediction" ? m : []
        )
      )
      .flat(1) ?? [];

  const prediction = predictions[0];

  const markups =
    data?.queryImage
      ?.map((i) =>
        i?.markups.flatMap((m) =>
          m.__typename === "SegmentationMarkup" ? m : []
        )
      )
      .flat(1) ?? [];

  const markup = markups[0];

  const markupSubset = markup?.subset;

  const circleAnnotations: MyLabeledCircle[] = useMemo(
    () =>
      markup?.annotations
        .flatMap((a) => (a.__typename === "CircleAnnotation" ? a : []))
        .map((a) => ({
          annotationType: a.annotationType,
          markupLabelId: a.label.id,
          markupLabelName: a.label.name,
          markupLabelColor: a.label.color,
          center: { x: a.center.x, y: a.center.y },
          radius: a.radius,
        })) || [],
    [markup?.annotations]
  );

  const polygonAnnotations: MyLabeledPolygon[] = useMemo(
    () =>
      markup?.annotations
        .flatMap((a) => (a.__typename === "PolygonAnnotation" ? a : []))
        .map((a) => ({
          annotationType: a.annotationType,
          markupLabelId: a.label.id,
          markupLabelName: a.label.name,
          markupLabelColor: a.label.color,
          points: [...a.points]
            .sort((p1, p2) => p2.idx - p1.idx)
            .map((p) => ({ x: p.x, y: p.y })),
        })) || [],
    [markup?.annotations]
  );

  const penAnnotations: MyLabeledPen[] = useMemo(
    () =>
      markup?.annotations
        .flatMap((a) => (a.__typename === "PenAnnotation" ? a : []))
        .map((a) => ({
          annotationType: a.annotationType,
          bottomRight: { x: a.bottomRight.x, y: a.bottomRight.y },
          topLeft: { x: a.topLeft.x, y: a.topLeft.y },
          dataURL: a.dataURL,
          markupLabelId: a.label.id,
          markupLabelName: a.label.name,
          markupLabelColor: a.label.color,
          points: [...a.points]
            .sort((p1, p2) => p2.idx - p1.idx)
            .map((p) => ({ x: p.x, y: p.y })),
          thickness: a.thickness,
        })) || [],
    [markup?.annotations]
  );

  const rectangleAnnotations: MyLabeledRectangle[] = useMemo(
    () =>
      markup?.annotations
        .flatMap((a) => (a.__typename === "RectangleAnnotation" ? a : []))
        .map((a) => ({
          annotationType: a.annotationType,
          bottomRight: { x: a.bottomRight.x, y: a.bottomRight.y },
          topLeft: { x: a.topLeft.x, y: a.topLeft.y },
          markupLabelId: a.label.id,
          markupLabelName: a.label.name,
          markupLabelColor: a.label.color,
        })) || [],
    [markup?.annotations]
  );

  const sausageAnnotations: MyLabeldSausage[] = useMemo(
    () =>
      markup?.annotations
        .flatMap((a) => (a.__typename === "SausageAnnotation" ? a : []))
        .map((a) => {
          const plist = [...a.points];
          plist.sort((p1, p2) => p1.idx - p2.idx);
          return {
            annotationType: a.annotationType,
            bottomRight: { x: a.bottomRight.x, y: a.bottomRight.y },
            topLeft: { x: a.topLeft.x, y: a.topLeft.y },
            dataURL: a.dataURL,
            markupLabelId: a.label.id,
            markupLabelName: a.label.name,
            markupLabelColor: a.label.color,
            points: plist.map((p) => ({ x: p.x, y: p.y })),
            radius: a.radius,
          };
        }) ?? [],
    [markup?.annotations]
  );

  const magicwandAnnotations: MyLabeledMagicWand[] = useMemo(
    () =>
      markup?.annotations
        .flatMap((a) => (a.__typename === "MagicwandAnnotation" ? a : []))
        .map((a) => ({
          annotationType: a.annotationType,
          bottomRight: { x: a.bottomRight.x, y: a.bottomRight.y },
          topLeft: { x: a.topLeft.x, y: a.topLeft.y },
          dataURL: a.dataURL,
          markupLabelId: a.label.id,
          markupLabelName: a.label.name,
          markupLabelColor: a.label.color,
          center: { x: a.center.x, y: a.center.y },
          threshold: a.threshold,
        })) || [],
    [markup?.annotations]
  );

  const pixelAnnotations: MyLabeledPixels[] = useMemo(() => {
    const markupss: MyLabeledPixels[] =
      markup?.annotations
        .flatMap((a) => (a.__typename === "PixelAnnotation" ? a : []))
        .map((a) => ({
          annotationType: a.annotationType,
          bottomRight: { x: a.bottomRight.x, y: a.bottomRight.y },
          topLeft: { x: a.topLeft.x, y: a.topLeft.y },
          dataURL: a.dataURL,
          markupLabelId: a.label.id,
          markupLabelName: a.label.name,
          markupLabelColor: a.label.color,
        })) || [];
    const predicts: MyLabeledPixels[] =
      prediction?.objects.map((a) => ({
        annotationType: AnnotationType.Positive,
        bottomRight: { x: a.bottomRight.x, y: a.bottomRight.y },
        topLeft: { x: a.topLeft.x, y: a.topLeft.y },
        dataURL: a.dataURL,
        markupLabelId: a.label.id,
        markupLabelName: a.label.name,
        markupLabelColor: a.label.color,
        isPrediction: true,
        probability: a.probability,
      })) || [];
    return markupss.concat(predicts);
  }, [markup?.annotations, prediction?.objects]);

  const markupId = markup?.id || "";

  const annotations: PlattformAnnotationsAndPredictions = useMemo(
    () => ({
      circleAnnotations,
      polygonAnnotations,
      penAnnotations,
      rectangleAnnotations,
      boundingBoxAnnotations: [],
      sausageAnnotations,
      magicwandAnnotations,
      pixelAnnotations,
      networkId: markup?.network.id || "",
      imageHeight: 0,
      imageWidth: 0,
      imageIds: [imageId],
      markupHeight: 0,
      markupWidth: 0,
    }),
    [
      circleAnnotations,
      imageId,
      magicwandAnnotations,
      markup?.network.id,
      penAnnotations,
      pixelAnnotations,
      polygonAnnotations,
      rectangleAnnotations,
      sausageAnnotations,
    ]
  );

  const result = useMemo(
    () => ({ markupId, annotations, subset: markupSubset }),
    [annotations, markupId, markupSubset]
  );

  const returnresult =
    markups.length === 0 && predictions.length === 0 ? undefined : result;

  return { markup: returnresult, refetch };
}
