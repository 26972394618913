import { Point } from "MarkupTypes";
import { reduce } from "ramda";

export const pointArrayToNumberArray = (points: Point[]): number[][] => {
  const newpoits: number[][] = points.map((x) => [x.x, x.y]);
  return newpoits;
};

export const pointArrayToIntArray = (points: Point[]): number[] => {
  const newpoits = reduce(
    (acc: Array<number>, elm: Point) => {
      acc.push(elm.x, elm.y);
      return acc;
    },
    [],
    points
  );
  return newpoits;
};

export const numberArrayToPointArray = (points: number[][]): Point[] => {
  const newpoints: Point[] = points.map((p) => ({ x: p[0], y: p[1] }));
  return newpoints;
};

export const numberArrayToIntArray = (points: number[][]): number[] => {
  const newpoits = reduce(
    (acc: Array<number>, elm: number[]) => {
      acc.push(elm[0], elm[1]);
      return acc;
    },
    [],
    points
  );
  return newpoits;
};

export const linearNumberArrayToPointArray = (points: number[]): Point[] => {
  let newPointArray: Point[] = [];
  for (let i = 0; i < points.length; i += 2) {
    newPointArray = [...newPointArray, { x: points[i], y: points[i + 1] }];
  }
  return newPointArray;
};

// needs to have an odd number of elements, the two elements surrounding the middle element must be different from the middle element
const weights = [2, 4, 6, 8, 10, 4, 3, 2, 1];
const middleOver = weights.length;
const upAndDOwn = (middleOver - 1) / 2;

export const makeSausage = (
  points: Point[],
  size: number,
  leftright: boolean
): Point[] => {
  let i;
  let j;
  let lowerBound;
  let upperBound;
  let weightSum;
  let workX;
  let workY;
  let newX;
  let newY;
  let len;
  const workPoints: Point[] = [];
  const newpoints: Point[] = [];

  // apply a weighted average over the points
  const maxIndexForFull = points.length - upAndDOwn;
  for (i = 0; i < points.length; i += 1) {
    workX = 0;
    workY = 0;
    weightSum = 0;
    if (i < upAndDOwn) {
      lowerBound = -i;
    } else {
      lowerBound = -upAndDOwn;
    }
    if (i >= maxIndexForFull) {
      upperBound = points.length - 1 - i;
    } else {
      upperBound = upAndDOwn;
    }
    for (j = lowerBound; j <= upperBound; j += 1) {
      workX += weights[j + upAndDOwn] * points[i + j].x;
      workY += weights[j + upAndDOwn] * points[i + j].y;
      weightSum += weights[j + upAndDOwn];
    }
    workX /= weightSum;
    workY /= weightSum;
    workPoints.push({ x: workX, y: workY });
  }
  for (i = 1; i < workPoints.length; i += 1) {
    newY = workPoints[i].x - workPoints[i - 1].x;
    newX = workPoints[i - 1].y - workPoints[i].y;
    len = (newX ** 2 + newY ** 2) ** 0.5;
    if (len !== 0) {
      newX = ((newX / len) * size) / 2;
      newY = ((newY / len) * size) / 2;
      if (leftright) {
        if (i === 1) {
          newpoints.push({
            x: points[i - 1].x + newX,
            y: points[i - 1].y + newY,
          });
        }
        newpoints.push({
          x: points[i].x + newX,
          y: points[i].y + newY,
        });
      } else {
        if (i === 1) {
          newpoints.push({
            x: points[i - 1].x - newX,
            y: points[i - 1].y - newY,
          });
        }
        newpoints.push({
          x: points[i].x - newX,
          y: points[i].y - newY,
        });
      }
    } else {
      console.log("WARNING: Points with zero distance detected");
    }
  }
  return newpoints;
};
