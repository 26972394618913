import { useDispatch } from "react-redux";
import { Header, Message, Button, Icon, Radio, Grid } from "semantic-ui-react";
import { triggerMarkupReload } from "../../../inputImage/redux-state";
import { DenkboxAvailableActions } from "../../../../../../components/menus/settings-view/hooks/useDenkBox";
import { useContext, useState } from "react";
import { DenkboxContext } from "../../../../../../ctx/DenkboxProvider";
import useMarkuptoolList from "../../../../../../state/useMarkuptoolList";
import useDeepCompareEffect from "use-deep-compare-effect";

type Props = {
  actionsAvailable: DenkboxAvailableActions;
  researchBoxId: string | undefined;
  newestPredictionCreated: string;
  visiblePredictionCreated: string;
};

export default function ControlPrediction({
  actionsAvailable,
  researchBoxId,
  newestPredictionCreated,
  visiblePredictionCreated,
}: Props) {
  const dispatch = useDispatch();

  const reloadMarkup = () => dispatch(triggerMarkupReload());

  const denkboxState = useContext(DenkboxContext);

  const [autoPredictionActive, setAutoPredictionActive] = useState(false);

  const { markuptoolList, currentListIndex } = useMarkuptoolList();

  const start = currentListIndex - 3 < 0 ? 0 : currentListIndex - 3;
  const end =
    currentListIndex + 5 >= markuptoolList.length
      ? markuptoolList.length - 1
      : currentListIndex + 5;

  const slice = markuptoolList.slice(start, end);

  useDeepCompareEffect(() => {
    if (denkboxState && autoPredictionActive) {
      denkboxState.actions.startPrediction(slice);
    }
  }, [slice, autoPredictionActive]);

  if (!denkboxState) {
    return <></>;
  }

  return (
    <>
      <Header as="h5">Autoprediction</Header>
      {!denkboxState.networkDetails.stats.networkExists && (
        <Message size="tiny">
          <Message.Header>
            <Icon name="warning circle" />
            Disabled
          </Message.Header>
          <Message.List>
            <Message.Item>
              Autoprediction is enabled as soon as there is a trained network
            </Message.Item>
          </Message.List>
        </Message>
      )}

      <p>
        Enable the switch to automatically predict images. Press reload to show
        updated predictions.
      </p>
      <Grid>
        <Grid.Row>
          <Grid.Column width="6">
            <Radio
              style={{ marginTop: 7 }}
              slider
              onChange={(_, data) => {
                setAutoPredictionActive(data.checked || false);
                if (!data.checked) {
                  denkboxState.actions.stopPrediction();
                }
              }}
              disabled={
                !actionsAvailable.startPrediction || researchBoxId === undefined
              }
              content="predict"
            />
          </Grid.Column>
          <Grid.Column width="10">
            <Button
              fluid
              size="tiny"
              disabled={
                visiblePredictionCreated !== null ||
                newestPredictionCreated === visiblePredictionCreated
              }
              icon="sync"
              color="pink"
              loading={
                visiblePredictionCreated === null && autoPredictionActive
              }
              onClick={reloadMarkup}
              content="predictions"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
