import moment from "moment";
import { reduce } from "ramda";
import { Point } from "MarkupTypes";
import { useState, useEffect } from "react";
import { compact, uniq } from "lodash";
import { DataType } from "../ts-clients/query";
import { ImageDatapointType } from "../types/extractedTypes";
import useGetSelectedImageResolution from "../hooks/useGetSelectedImageResolution";

export function removeLastDirectoryPartOf(theUrl: string) {
  const arr = theUrl.split("/");
  arr.pop();
  return arr.join("/");
}

export function toggleArrayValue<T>(arrayList: T[], arrayValue: T): T[] {
  return arrayList.includes(arrayValue)
    ? arrayList.filter((el) => el !== arrayValue)
    : [...arrayList, arrayValue];
}

export const formatTime = (s: number) => {
  const dtFormat = new Intl.DateTimeFormat("de-DE", {
    // timeZone: 'UTC',
    year: "numeric",
    month: "numeric",
    day: "numeric",
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  return dtFormat.format(new Date(s * 1e3));
};

export const now = () => moment().format("YYYY-MM-DDTHH:mm:ssZ");

type FormattedDate = {
  time: string;
  date: string;
  dateTime: string;
  timeAgo: string;
};

export const formatDate = (dt: string | undefined): FormattedDate => {
  if (dt === undefined) {
    return {
      date: "--",
      dateTime: "--",
      time: "--",
      timeAgo: "--",
    };
  }
  const m = moment(dt);
  const time = m.format("HH:mm:ss");
  const date = m.format("YYYY-MM-DD");
  return {
    time,
    date,
    dateTime: `${date} ${time}`,
    timeAgo: m.fromNow(),
  };
};

export const switchArrayItems = <T>(a: T[], x: number, y: number): T[] => {
  // eslint-disable-next-line prefer-destructuring
  a[x] = a.splice(y, 1, a[x])[0];
  return a;
};

// https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
export const hashStringToNumber = (s: string): number =>
  /* tslint:disable:no-bitwise */
  // eslint-disable-next-line no-bitwise
  Array.from(s).reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0);

export const pointArrayToIntArray = (points: Point[]): number[] => {
  const newpoits = reduce(
    (acc: number[], elm: Point) => {
      acc.push(elm.x, elm.y);
      return acc;
    },
    [],
    points
  );
  return newpoits;
};

// Zerlegt einen langen Array in mehrere kurze Arrays der Größe size:
// partitionArray([1,2,3,4,5,6,7,8,9], 4) => [[1,2,3,4],[5,6,7,8],[9]]
export const partitionArray = <T>(array: T[], size: number) =>
  array
    .map((_, i: number) => (i % size === 0 ? array.slice(i, i + size) : null))
    .filter((e) => e);

export type BoundingBox = {
  topLeft: Point;
  bottomRight: Point;
};

export const getBoundingBox = (ps: Point[]): BoundingBox =>
  ps.reduce(
    (b, point) => {
      if (point.x < b.topLeft.x) {
        b.topLeft.x = point.x;
      }
      if (point.x > b.bottomRight.x) {
        b.bottomRight.x = point.x;
      }
      if (point.y < b.topLeft.y) {
        b.topLeft.y = point.y;
      }
      if (point.y > b.bottomRight.y) {
        b.bottomRight.y = point.y;
      }
      return b;
    },
    {
      topLeft: {
        x: 1.7976931348623157e308,
        y: 1.7976931348623157e308,
      },
      bottomRight: {
        x: 0,
        y: 0,
      },
    }
  );

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function getDataTypesForResearchbox(
  r:
    | {
        datapoints?:
          | {
              producedBy: {
                dataType: DataType;
              };
            }[]
          | null;
      }
    | undefined
): DataType[] {
  // Collect list of visible data types
  return uniq(compact(r?.datapoints?.map((d) => d.producedBy.dataType) || []));
}

export function useUrlFromSelectedResolution(
  d: ImageDatapointType
): string | null {
  const { selectedResolution } = useGetSelectedImageResolution({ image: d });

  const availableResolutions =
    d.resolutions.map((res) => ({
      width: res.width,
      height: res.height,
      url: res.binaryURL,
    })) ?? [];

  // what to do, if no resolution is selected
  if (selectedResolution === undefined) {
    return availableResolutions.length > 0 ? availableResolutions[0].url : null;
  }

  const matchingResolution = availableResolutions.find(
    (r) =>
      r.height === selectedResolution.height &&
      r.width === selectedResolution.width
  );

  if (matchingResolution !== undefined) {
    return matchingResolution.url;
  }

  return availableResolutions.length > 0 ? availableResolutions[0].url : null;
}

// Berechne den euklidischen Abstand zwischen zwei Punkten
export const distance = (p1: Point, p2: Point) =>
  Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);

// ist ein Punkt weit genug von einem anderen entfernt, sodass er eingefügt werden soll?
export const shouldAdd = (p1: Point, p2: Point, minDistance: number): boolean =>
  distance(p1, p2) >= minDistance;

export const getContainCheck = (pointA: Point, pointB: Point) => {
  const bottomRight: Point = {
    x: pointA.x < pointB.x ? pointB.x : pointA.x,
    y: pointA.y < pointB.y ? pointB.y : pointA.y,
  };
  const topLeft: Point = {
    x: pointA.x < pointB.x ? pointA.x : pointB.x,
    y: pointA.y < pointB.y ? pointA.y : pointB.y,
  };

  const checkPoint = (candidate: Point) => {
    return (
      candidate.x >= topLeft.x &&
      candidate.x <= bottomRight.x &&
      candidate.y >= topLeft.y &&
      candidate.y <= bottomRight.y
    );
  };

  const checkRectangle = (r: BoundingBox) =>
    checkPoint(r.topLeft) && checkPoint(r.bottomRight);

  const checkPoints = (ps: Point[]) => checkRectangle(getBoundingBox(ps));

  return { checkPoint, checkPoints, checkRectangle };
};
