import { Message, Button, Header, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { CcCheckerDatapointType } from "../../../types/extractedTypes";
import GraphPerCell from "../../datapoint-viewer/CcCheckerResult/GraphPerCell";
import MetricPanel from "../../datapoint-viewer/CcCheckerResult/MetricPanel";
import SingleGraph from "../../datapoint-viewer/CcCheckerResult/SingleGraph";
import {
  getPath,
  DataPointDetailsPage,
  DetailsDatapointParams,
} from "../../../utils/routeManager";

type RenderProps = {
  d: CcCheckerDatapointType;
};

type ViewType = "byCell" | "allAtOnce" | "asMetric";
const defaultViewType: ViewType = "byCell";

const renderFunctions = new Map<ViewType, (r: RenderProps) => JSX.Element>([
  ["byCell", GraphPerCell],
  ["allAtOnce", SingleGraph],
  [
    "asMetric",
    (r: RenderProps) => (
      <div style={{ height: 200 }}>
        <MetricPanel d={r.d} />
      </div>
    ),
  ],
]);

const linkMaker = (p: DetailsDatapointParams) => (t: DataPointDetailsPage) =>
  getPath("DetailsDatapoint", { ...p, page: t });

export type Params = {
  page: DetailsDatapointParams;
  datapoint: CcCheckerDatapointType;
};

export default function CcCheckerResultPage({ datapoint, page }: Params) {
  const viewType =
    page.page === null ? defaultViewType : (page.page as ViewType);

  if (!datapoint) {
    return (
      <>
        <Button
          as={Link}
          to={getPath("ResearchBoxDetails", {
            ...page,
            context: "researchboxlist",
            page: "overview",
          })}
          content="back"
        />
        <Message warning content="datapoint not found" />
      </>
    );
  }

  const makeLink = linkMaker(page);
  const RenderFunction =
    renderFunctions.get(viewType) ||
    renderFunctions.get(defaultViewType) ||
    (() => <Message content="unkown render type" warning />);

  const backLink =
    page.context === "sensorimport"
      ? getPath("SensorImport", page)
      : getPath("ResearchBoxDetails", { ...page, page: "overview" });

  return (
    <>
      <Header as="h1">
        Datpoint Details
        <Button.Group floated="right">
          <Button
            as={Link}
            active={viewType === "byCell"}
            to={makeLink("byCell")}
            content="by cell"
          />
          <Button
            as={Link}
            active={viewType === "allAtOnce"}
            to={makeLink("allAtOnce")}
            content="all at once"
          />
          <Button
            as={Link}
            active={viewType === "asMetric"}
            to={makeLink("asMetric")}
            content="as metric"
          />
          <Button as={Link} to={backLink} content="back" icon="arrow up" />
        </Button.Group>
      </Header>
      <Divider />
      <RenderFunction d={datapoint} />
    </>
  );
}
