import {
  PipelineModuleCCCheckerProps,
  PipelineModuleContrastEnhancementProps,
  PipelineModuleNetworkImageClassificationProps,
  PipelineModuleNetworkImageSegmentationProps,
  PipelineModuleNetworkImageObjectDetectionProps,
} from "ModuleTypes";
import { getRandomCoordinates } from "./shared";

export const ccheckerDefaults = (): PipelineModuleCCCheckerProps => ({
  busBarCountPerCell: 0,
  cccheckerResultName: "",
  cutOffRate: 0,
  maxShift: 0,
  minCorrValue: 0,
  minPeakDistance: 0,
  minPeakHeightPercentage: 0,
  minPeakProminence: 0,
  moduleDescription: "",
  moduleName: "",
  position: getRandomCoordinates(),
  pretrainedNetworkModuleId: null,
});

export const pipelineModuleContrastEnhancementDefaults =
  (): PipelineModuleContrastEnhancementProps => ({
    moduleDescription: "",
    moduleName: "",
    position: getRandomCoordinates(),
    clipLimit: 0,
    enhancedImageName: "",
    tileSize: 0,
    pretrainedNetworkModuleId: null,
  });

export const pipelineModuleNetworkImageClassificationDefaults =
  (): PipelineModuleNetworkImageClassificationProps => ({
    moduleDescription: "",
    moduleName: "",
    position: getRandomCoordinates(),
    // @ts-ignore
    classLabels: [{ name: "", color: "#999" }],
    networkName: "",
    selectedPipelineModuleOutputIDs: [],
    pretrainedNetworkModuleId: null,
  });

export const pipelineModuleNetworkImageSegmentationDefaults =
  (): PipelineModuleNetworkImageSegmentationProps => ({
    moduleDescription: "",
    moduleName: "",
    position: getRandomCoordinates(),
    // @ts-ignore
    classLabels: [{ name: "", color: "#999" }],
    networkName: "",
    selectedPipelineModuleOutputIDs: [],
  });

export const pipelineModuleNetworkImageObjectDetectionDefaults =
  (): PipelineModuleNetworkImageObjectDetectionProps => ({
    moduleDescription: "",
    moduleName: "",
    position: getRandomCoordinates(),
    // @ts-ignore
    classLabels: [{ name: "", color: "#999" }],
    networkName: "",
    selectedPipelineModuleOutputIDs: [],
  });
