/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useState } from "react";
import { Point } from "MarkupTypes";
import {
  getBoundingBox,
  pointArrayToIntArray,
} from "../../../../../../utils/helpers";
import { getPolygonConfig } from "../../../../../datapoint-viewer/ImageWithSegmentation2d/Layers/AnnotationTypes/lowlevel/util";
import { AnnotationType } from "../../../../../../ts-clients/command";
import { hexToRgbString } from "../../../../../../utils/pixeltools";

type Props = {
  points: Point[];
  type: AnnotationType | undefined;
  labelColor: string;
  iconSize: number;
  active: boolean;
};

const getColors = (active: boolean, highlighted: boolean) => {
  if (active) {
    if (highlighted) {
      return { fill: "#acc", stroke: "transparent" };
    }
    return { fill: "#acc", stroke: "transparent" };
  }

  if (highlighted) {
    return { fill: "#E0E1E2", stroke: "transparent" };
  }
  return { fill: "#e5e5e5", stroke: "transparent" };
};

export default function MarkupIconPolygon({
  points,
  iconSize,
  type,
  labelColor,
  active,
}: Props) {
  const [isHighlighted, setHighlighted] = useState<boolean>(false);
  const bbLarge = getBoundingBox(points);
  const shrink = Math.max(
    bbLarge.bottomRight.x - bbLarge.topLeft.x,
    bbLarge.bottomRight.y - bbLarge.topLeft.y
  );

  if (type === undefined) {
    return null;
  }
  const style = getPolygonConfig(
    type,
    "Polygon",
    false,
    false,
    false,
    false,
    true,
    labelColor,
    1
  );

  const renderPoints = points.map((p) => ({
    x: ((p.x - bbLarge.topLeft.x + 2) / shrink) * (iconSize - 4),
    y: ((p.y - bbLarge.topLeft.y + 2) / shrink) * (iconSize - 4),
  }));

  const bbSmall = getBoundingBox(renderPoints);
  const moveX = (iconSize - bbSmall.bottomRight.x) / 2;
  const moveY = (iconSize - bbSmall.bottomRight.y) / 2;

  const renderPointsCentered = pointArrayToIntArray(
    renderPoints.map((p) => ({
      x: p.x + moveX,
      y: p.y + moveY,
    }))
  ).join(",");

  const colors = getColors(active, isHighlighted);

  return (
    <svg
      height={iconSize}
      width={iconSize}
      onMouseOver={() => setHighlighted(true)}
      onMouseOut={() => setHighlighted(false)}
    >
      <defs>
        <radialGradient id="c1" cx="0.5" cy="0.5" r=".7">
          <stop offset="0" stopColor="#fff" />
          <stop offset="1" stopColor="#444" />
        </radialGradient>
      </defs>

      <rect
        x="0"
        y="0"
        rx={4}
        ry={4}
        height={iconSize}
        width={iconSize}
        style={{
          fill: colors.fill,
          stroke: colors.stroke,
        }}
      />
      <polygon
        points={renderPointsCentered}
        style={{
          fill: hexToRgbString(style.fillColor),
          opacity: style.opacity,
        }}
      />
      {active && (
        <rect
          x="0"
          y="0"
          rx={4}
          ry={4}
          height={iconSize}
          width={iconSize}
          style={{
            fill: "url(#c1)",
            opacity: 0.15,
          }}
        />
      )}
    </svg>
  );
}
