import useHook from "../../../hooks/moduleCreators-new-2021-04/useUpdatePipelineModuleNetworkImageObjectDetection";
import ModuleForm from "./forms/FormPipelineModuleNetworkImageObjectDetection";

type Props = {
  moduleId: string;
  dataSetId: string;
  onCancel: () => unknown;
};

export default function PMNetworkImageObjectDetectionUpdate({
  moduleId,
  onCancel,
  dataSetId,
}: Props) {
  const { moduleData, setBaseParams, setModuleData, update } =
    useHook(moduleId);

  const onSubmit = () => {
    update();
    onCancel();
  };

  return (
    <ModuleForm
      moduleData={moduleData}
      onCancel={onCancel}
      onSave={onSubmit}
      setBaseParams={setBaseParams}
      setData={setModuleData}
      moduleId={moduleId}
      datasetId={dataSetId}
      mode="update"
    />
  );
}
