import { PixelDrawMode } from "MarkupTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  isPniActive,
  enablePni,
  increaseMagicWandThreshold,
  decreaseMagicWandThreshold,
  getMagicWandThreshold,
  setSausageSize,
  getSausageSize,
  getPixelDrawMode,
  setPixelDrawMode as setPDM,
} from "../../../details-researchbox/Markup/inputImage/redux-state";

// polygontype swich aktiv ja/nein, + settings
export default function useMarkuptoolState() {
  const dispatch = useDispatch();
  const pniActive = useSelector(isPniActive);
  const sausageSize = useSelector(getSausageSize);
  const pixelDrawMode = useSelector(getPixelDrawMode);

  const setPixelDrawMode = (d: PixelDrawMode) => dispatch(setPDM(d));

  const magicWandThreshold = useSelector(getMagicWandThreshold);

  const doIncreaseMagicWandThreshold = () =>
    dispatch(increaseMagicWandThreshold());

  const doDecreaseMagicWandThreshold = () => {
    if (magicWandThreshold > 1) {
      dispatch(decreaseMagicWandThreshold());
    }
  };

  const increaseSausageSize = () => dispatch(setSausageSize(sausageSize + 1));

  const decreaseSausageSize = () => {
    if (sausageSize > 1) {
      dispatch(setSausageSize(sausageSize - 1));
    }
  };

  return {
    pniActive,
    pixelDrawMode,
    enablePni,
    magicWandThreshold,
    increaseMagicWandThreshold: doIncreaseMagicWandThreshold,
    decreaseMagicWandThreshold: doDecreaseMagicWandThreshold,
    sausageSize,
    increaseSausageSize,
    decreaseSausageSize,
    setPixelDrawMode,
  };
}
