import { useEffect, useCallback, useMemo } from "react";
import { ToolType } from "MarkupTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  AddBoundingBoxAnnotationInput,
  AddCircleAnnotationInput,
  AddImageSegmentationMarkupInput,
  AddMagicwandAnnotationInput,
  AddPenAnnotationInput,
  AddPixelAnnotationInput,
  AddPolygonAnnotationInput,
  AddRectangleAnnotationInput,
  AddSausageAnnotationInput,
  AnnotationType,
} from "../../../../ts-clients/command";
import useUndo from "./useUndo";
import {
  // assertLabelsExist,
  CurrentDataType,
  makeMyAnnotations,
  MyAnnotations,
  MyBoundingBox,
  MyCircle,
  MyMagicWand,
  MyPen,
  MyPixels,
  MyPolygon,
  MyRectangle,
  MySausage,
} from "./types";
import {
  toggleSegmentationsVisible as toggleSeg,
  getSegmentationsVisible,
  getSelectedPolygonId,
  setAnnotationsChanged,
} from "../../../details-researchbox/Markup/inputImage/redux-state";
import * as helpers from "./annotationHelper2";
import {
  circleToPoly,
  freeformToPoly,
  magicWandToPoly,
  rectangleToPoly,
  sausageToPoly,
} from "./annotationHelper";

const makeDefaultState = (markupLabelId: string, markupLabelColor: string) => ({
  markupLabelId,
  markupLabelColor,
  annotationData: makeMyAnnotations(undefined),
});

export default function useAnnotations(
  initialMarkupLabelId: string,
  initialMarkupLabelColor: string
) {
  const dispatch = useDispatch();
  // const [labelList, setLabelList] = useState<MarkupLabel[]>([]);

  const segmentationsVisible = useSelector(getSegmentationsVisible);
  const toggleSegmentationsVisible = useCallback(
    () => dispatch(toggleSeg()),
    [dispatch]
  );

  const [undostate, undoactions, undoMeta] = useUndo(
    makeDefaultState(initialMarkupLabelId, initialMarkupLabelColor)
  );

  const selectedPolygonId = useSelector(getSelectedPolygonId);

  const memoedactions = useMemo(() => ({ ...undoactions }), [undoactions]);

  const resetUndo = useCallback(() => {
    undoactions.reset(
      makeDefaultState(
        undostate.present.markupLabelId,
        undostate.present.markupLabelColor
      )
    );
  }, [
    undoactions,
    undostate.present.markupLabelId,
    undostate.present.markupLabelColor,
  ]);

  const annotationsChanged = undostate.past.length > 0;
  useEffect(() => {
    dispatch(setAnnotationsChanged(annotationsChanged));
  }, [annotationsChanged, dispatch]);

  const { set: remember, replace, reset } = undoactions;

  useEffect(() => {
    if (selectedPolygonId !== null) {
      const markupLabelId = helpers.findMarkupLabelIdForAnnotationId(
        undostate.present.annotationData,
        selectedPolygonId
      );
      if (
        markupLabelId !== undefined &&
        markupLabelId !== undostate.present.markupLabelId
      ) {
        replace({
          ...undostate.present,
          markupLabelId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolygonId]);

  const addAnnotationUndoStepWith = useCallback(
    (ms: MyAnnotations): void => {
      remember({
        markupLabelId: undostate.present.markupLabelId,
        markupLabelColor: undostate.present.markupLabelColor,
        annotationData: ms,
      });
    },
    [
      remember,
      undostate.present.markupLabelId,
      undostate.present.markupLabelColor,
    ]
  );

  // const replaceAnnotationUndoStepWith = useCallback(
  //   (ms: MyAnnotations): void => {
  //     replace({
  //       markupLabelId: undostate.present.markupLabelId,
  //       annotationData: ms,
  //     });
  //   },
  //   [replace, undostate.present.markupLabelId]
  // );

  const setMarkupLabelIdAndColor = useCallback(
    (id: string, color: string, replaceValue: boolean) => {
      if (id === undostate.present.markupLabelId) {
        return;
      }
      if (replaceValue) {
        replace({
          ...undostate.present,
          markupLabelId: id,
          markupLabelColor: color,
        });
      } else {
        remember({
          ...undostate.present,
          markupLabelId: id,
          markupLabelColor: color,
        });
      }
    },
    [remember, replace, undostate.present]
  );

  const reInitialize = useCallback(
    (a: AddImageSegmentationMarkupInput) => {
      replace({
        markupLabelId: undostate.present.markupLabelId,
        markupLabelColor: undostate.present.markupLabelColor,
        //@ts-ignore
        annotationData: makeMyAnnotations(a),
      });
    },
    [
      replace,
      undostate.present.markupLabelId,
      undostate.present.markupLabelColor,
    ]
  );

  const resetAnnotations = useCallback(
    () =>
      reset({
        markupLabelId: undostate.present.markupLabelId,
        markupLabelColor: undostate.present.markupLabelColor,
        annotationData: makeMyAnnotations(undefined),
      }),
    [reset, undostate.present.markupLabelId, undostate.present.markupLabelColor]
  );

  const circleReplace = useCallback(
    (annotationId: string, i: MyCircle) =>
      addAnnotationUndoStepWith(
        helpers.findAndReplace(
          undostate.present.annotationData,
          annotationId,
          helpers.getCirclePosition,
          helpers.replaceCircle(i)
        )
      ),
    [addAnnotationUndoStepWith, undostate.present.annotationData]
  );

  const polygonReplace = useCallback(
    (annotationId: string, i: MyPolygon) =>
      addAnnotationUndoStepWith(
        helpers.findAndReplace(
          undostate.present.annotationData,
          annotationId,
          helpers.getPolygonPosition,
          helpers.replacePolygon(i)
        )
      ),
    [addAnnotationUndoStepWith, undostate.present.annotationData]
  );

  const sausageReplace = useCallback(
    (annotationId: string, i: MySausage) =>
      addAnnotationUndoStepWith(
        helpers.findAndReplace(
          undostate.present.annotationData,
          annotationId,
          helpers.getSausagePosition,
          helpers.replaceSausage(i)
        )
      ),
    [addAnnotationUndoStepWith, undostate.present.annotationData]
  );

  const penReplace = useCallback(
    (annotationId: string, i: MyPen) =>
      addAnnotationUndoStepWith(
        helpers.findAndReplace(
          undostate.present.annotationData,
          annotationId,
          helpers.getPenPosition,
          helpers.replacePen(i)
        )
      ),
    [addAnnotationUndoStepWith, undostate.present.annotationData]
  );

  const pixelsReplace = useCallback(
    (annotationId: string, i: MyPixels) => {
      const updateA = (a: MyAnnotations) =>
        helpers.pixelsAdd(i, a, i.markupLabelId, i.markupLabelColor);
      const newdata = helpers.replaceMyAnnotationsNew(
        undostate.present.annotationData,
        annotationId,
        updateA
      );
      addAnnotationUndoStepWith(newdata);
    },
    [addAnnotationUndoStepWith, undostate.present.annotationData]
  );

  const rectangleReplace = useCallback(
    (annotationId: string, i: MyRectangle) =>
      addAnnotationUndoStepWith(
        helpers.findAndReplace(
          undostate.present.annotationData,
          annotationId,
          helpers.getRectanglePosition,
          helpers.replaceRectangle(i)
        )
      ),
    [addAnnotationUndoStepWith, undostate.present.annotationData]
  );

  const boundingBoxReplace = useCallback(
    (annotationId: string, i: MyBoundingBox) =>
      addAnnotationUndoStepWith(
        helpers.findAndReplace(
          undostate.present.annotationData,
          annotationId,
          helpers.getBoundingBoxPosition,
          helpers.replaceBoundingBox(i)
        )
      ),
    [addAnnotationUndoStepWith, undostate.present.annotationData]
  );

  const magicWandReplace = useCallback(
    (annotationId: string, i: MyMagicWand) =>
      addAnnotationUndoStepWith(
        helpers.findAndReplace(
          undostate.present.annotationData,
          annotationId,
          helpers.getMagicWandPosition,
          helpers.replaceMagicWand(i)
        )
      ),
    [addAnnotationUndoStepWith, undostate.present.annotationData]
  );

  const annotationDelete = useCallback(
    (annotationId: string) => {
      addAnnotationUndoStepWith(
        helpers.findAndDelete(
          undostate.present.annotationData,
          annotationId,
          false
        )
      );
    },
    [addAnnotationUndoStepWith, undostate.present.annotationData]
  );
  const annotationsDelete = useCallback(
    (annotationIds: string[]) => {
      addAnnotationUndoStepWith(
        helpers.findAndDeleteAll(
          undostate.present.annotationData,
          annotationIds,
          false
        )
      );
    },
    [addAnnotationUndoStepWith, undostate.present.annotationData]
  );

  // const annotationDeleteWithoutUndo = useCallback(
  //   (annotationId: string) => {
  //     console.log('delete', annotationId);
  //     replaceAnnotationUndoStepWith(
  //       helpers.findAndDelete(
  //         undostate.present.annotationData,
  //         annotationId,
  //         false
  //       )
  //     );
  //   },
  //   [replaceAnnotationUndoStepWith, undostate.present.annotationData]
  // );

  const circleAdd = useCallback(
    (c: AddCircleAnnotationInput) => {
      const nd = helpers.circleAdd(
        c,
        undostate.present.annotationData,
        undostate.present.markupLabelId,
        undostate.present.markupLabelColor
      );
      addAnnotationUndoStepWith(nd);
    },
    [
      addAnnotationUndoStepWith,
      undostate.present.annotationData,
      undostate.present.markupLabelId,
      undostate.present.markupLabelColor,
    ]
  );

  const penAdd = useCallback(
    (c: AddPenAnnotationInput) => {
      addAnnotationUndoStepWith(
        helpers.penAdd(
          c,
          undostate.present.annotationData,
          undostate.present.markupLabelId,
          undostate.present.markupLabelColor
        )
      );
    },
    [
      addAnnotationUndoStepWith,
      undostate.present.annotationData,
      undostate.present.markupLabelId,
      undostate.present.markupLabelColor,
    ]
  );

  const polygonAdd = useCallback(
    (c: AddPolygonAnnotationInput) => {
      addAnnotationUndoStepWith(
        helpers.polygonAdd(
          c,
          undostate.present.annotationData,
          undostate.present.markupLabelId,
          undostate.present.markupLabelColor
        )
      );
    },
    [
      addAnnotationUndoStepWith,
      undostate.present.annotationData,
      undostate.present.markupLabelId,
      undostate.present.markupLabelColor,
    ]
  );

  const rectangleAdd = useCallback(
    (c: AddRectangleAnnotationInput) => {
      addAnnotationUndoStepWith(
        helpers.rectangleAdd(
          c,
          undostate.present.annotationData,
          undostate.present.markupLabelId,
          undostate.present.markupLabelColor
        )
      );
    },
    [
      addAnnotationUndoStepWith,
      undostate.present.annotationData,
      undostate.present.markupLabelId,
      undostate.present.markupLabelColor,
    ]
  );

  const boundingBoxAdd = useCallback(
    (c: AddBoundingBoxAnnotationInput) => {
      addAnnotationUndoStepWith(
        helpers.boundingBoxAdd(
          c,
          undostate.present.annotationData,
          undostate.present.markupLabelId,
          undostate.present.markupLabelColor
        )
      );
    },
    [
      addAnnotationUndoStepWith,
      undostate.present.annotationData,
      undostate.present.markupLabelId,
      undostate.present.markupLabelColor,
    ]
  );

  const magicWandAdd = useCallback(
    (c: AddMagicwandAnnotationInput) => {
      addAnnotationUndoStepWith(
        helpers.magicWandAdd(
          c,
          undostate.present.annotationData,
          undostate.present.markupLabelId,
          undostate.present.markupLabelColor
        )
      );
    },
    [
      addAnnotationUndoStepWith,
      undostate.present.annotationData,
      undostate.present.markupLabelId,
      undostate.present.markupLabelColor,
    ]
  );

  const pixelsAdd = useCallback(
    (c: AddPixelAnnotationInput) => {
      addAnnotationUndoStepWith(
        helpers.pixelsAdd(
          c,
          undostate.present.annotationData,
          undostate.present.markupLabelId,
          undostate.present.markupLabelColor
        )
      );
    },
    [
      addAnnotationUndoStepWith,
      undostate.present.annotationData,
      undostate.present.markupLabelId,
      undostate.present.markupLabelColor,
    ]
  );

  const sausageAdd = useCallback(
    (c: AddSausageAnnotationInput) => {
      addAnnotationUndoStepWith(
        helpers.sausageAdd(
          c,
          undostate.present.annotationData,
          undostate.present.markupLabelId,
          undostate.present.markupLabelColor
        )
      );
    },
    [
      addAnnotationUndoStepWith,
      undostate.present.annotationData,
      undostate.present.markupLabelId,
      undostate.present.markupLabelColor,
    ]
  );

  const applyMarkup = useCallback(
    (
      currentData: CurrentDataType,
      selectedSegmentation2DTool: ToolType,
      annotationType: AnnotationType
    ) => {
      switch (selectedSegmentation2DTool) {
        case "MagicWand":
          magicWandAdd({ ...currentData.magicwand, annotationType });
          break;
        case "Rectangle":
          rectangleAdd({ ...currentData.reactangle, annotationType });
          break;
        case "BoundingBox":
          boundingBoxAdd({ ...currentData.boundingBox, annotationType });
          break;
        case "Sausage":
          if (currentData.sausage.points.length > 1) {
            sausageAdd({ ...currentData.sausage, annotationType });
          }
          break;
        case "Polygon":
          if (currentData.polygon.points.length > 2) {
            polygonAdd({ ...currentData.polygon, annotationType });
          }
          break;
        case "FreeForm":
          if (currentData.polygon.points.length > 2) {
            polygonAdd({
              points: freeformToPoly({ ...currentData.polygon }),
              annotationType,
              markupLabelId: currentData.polygon.markupLabelId,
            });
          }
          break;
        case "Circle":
          // unused; this is handled in the onClick event in useStage
          break;

        default:
          console.log(
            "cannot add annotation of this type yet:",
            selectedSegmentation2DTool
          );
      }
    },
    [boundingBoxAdd, magicWandAdd, polygonAdd, rectangleAdd, sausageAdd]
  );

  const replaceAnnotationType = useCallback(
    (annotationType: AnnotationType, annotationId: string) =>
      addAnnotationUndoStepWith(
        helpers.replaceAnnotationType(
          undostate.present.annotationData,
          annotationId,
          annotationType
        )
      ),
    [addAnnotationUndoStepWith, undostate.present.annotationData]
  );

  const replaceAnnotationMarkupLabel = useCallback(
    (markupLabelId: string, markupLabelColor: string, myAnnotationId: string) =>
      addAnnotationUndoStepWith(
        helpers.replaceAnnotationMarkupLabel(
          undostate.present.annotationData,
          markupLabelId,
          markupLabelColor,
          myAnnotationId
        )
      ),
    [addAnnotationUndoStepWith, undostate.present.annotationData]
  );

  const getAnnotationPolygon = useCallback(
    (annotationId: string | null): AddPolygonAnnotationInput | null => {
      if (annotationId === null) {
        return null;
      }

      const circle = helpers.getCircle(
        annotationId,
        undostate.present.annotationData
      );
      if (circle !== null) {
        return {
          annotationType: circle.annotationType,
          points: circleToPoly(circle),
          markupLabelId: circle.markupLabelId,
        };
      }
      const rectangle = helpers.getRectangle(
        annotationId,
        undostate.present.annotationData
      );
      if (rectangle !== null) {
        return {
          annotationType: rectangle.annotationType,
          points: rectangleToPoly(rectangle),
          markupLabelId: rectangle.markupLabelId,
        };
      }
      const boundingBox = helpers.getBoundingBox(
        annotationId,
        undostate.present.annotationData
      );
      if (boundingBox !== null) {
        return {
          annotationType: boundingBox.annotationType,
          points: rectangleToPoly(boundingBox),
          markupLabelId: boundingBox.markupLabelId,
        };
      }
      const sausage = helpers.getSausage(
        annotationId,
        undostate.present.annotationData
      );
      if (sausage !== null) {
        return {
          annotationType: sausage.annotationType,
          points: sausageToPoly(sausage),
          markupLabelId: sausage.markupLabelId,
        };
      }
      const magicWand = helpers.getMagicWand(
        annotationId,
        undostate.present.annotationData
      );
      if (magicWand !== null) {
        return {
          annotationType: magicWand.annotationType,
          points: magicWandToPoly(magicWand),
          markupLabelId: magicWand.markupLabelId,
        };
      }

      const polygon = helpers.getPolygon(
        annotationId,
        undostate.present.annotationData
      );
      if (polygon !== null) {
        return {
          annotationType: polygon.annotationType,
          points: polygon.points,
          markupLabelId: polygon.markupLabelId,
        };
      }
      return null;
    },
    [undostate.present.annotationData]
  );

  const getAnnotationCreated = useCallback(
    (annotationId: string | null) => {
      if (annotationId === null) {
        return null;
      }
      return helpers.getCreateTime(
        undostate.present.annotationData,
        annotationId
      );
    },
    [undostate.present.annotationData]
  );

  const getAnnotationPixels = useCallback(
    (annotationId: string | null): AddPixelAnnotationInput | null => {
      if (annotationId === null) {
        return null;
      }
      const result = helpers.getPixels(
        annotationId,
        undostate.present.annotationData
      );
      return result ?? null;
    },
    [undostate.present.annotationData]
  );

  const getAnnotationFlavour = useCallback(
    (annotationId: string | null) => {
      if (annotationId === null) {
        return null;
      }
      return helpers.getAnnotationFlavour(
        undostate.present.annotationData,
        annotationId
      );
    },
    [undostate.present.annotationData]
  );

  const circleGet = useCallback(
    (annotationId: string) => {
      return helpers.getCircle(annotationId, undostate.present.annotationData);
    },
    [undostate.present.annotationData]
  );

  const sausageGet = useCallback(
    (annotationId: string) => {
      return helpers.getSausage(annotationId, undostate.present.annotationData);
    },
    [undostate.present.annotationData]
  );

  const magicWandGet = useCallback(
    (annotationId: string) => {
      return helpers.getMagicWand(
        annotationId,
        undostate.present.annotationData
      );
    },
    [undostate.present.annotationData]
  );

  const pixelsGet = useCallback(
    (annotationId: string) => {
      return helpers.getPixels(annotationId, undostate.present.annotationData);
    },
    [undostate.present.annotationData]
  );

  // const containsPredictions = useMemo(() => {
  //   return (
  //     undostate.present.annotationData.boundingBoxAnnotations.find(
  //       (a) => a.isPrediction
  //     ) !== undefined ||
  //     undostate.present.annotationData.circleAnnotations.find(
  //       (a) => a.isPrediction
  //     ) !== undefined ||
  //     undostate.present.annotationData.magicwandAnnotations.find(
  //       (a) => a.isPrediction
  //     ) !== undefined ||
  //     undostate.present.annotationData.penAnnotations.find(
  //       (a) => a.isPrediction
  //     ) !== undefined ||
  //     undostate.present.annotationData.pixelAnnotations.find(
  //       (a) => a.isPrediction
  //     ) !== undefined ||
  //     undostate.present.annotationData.polygonAnnotations.find(
  //       (a) => a.isPrediction
  //     ) !== undefined ||
  //     undostate.present.annotationData.rectangleAnnotations.find(
  //       (a) => a.isPrediction
  //     ) !== undefined ||
  //     undostate.present.annotationData.sausageAnnotations.find(
  //       (a) => a.isPrediction
  //     ) !== undefined
  //   );
  // }, [undostate.present]);

  const lastPredictionTime = useMemo(
    () => helpers.getLastPredictionTime(undostate.present.annotationData),
    [undostate.present.annotationData]
  );

  const actions = useMemo(
    () => ({
      annotationDelete,
      annotationsDelete,
      applyMarkup,
      boundingBoxAdd,
      boundingBoxReplace,
      circleAdd,
      circleGet,
      circleReplace,
      getAnnotationCreated,
      getAnnotationFlavour,
      getAnnotationPixels,
      getAnnotationPolygon,
      magicWandAdd,
      magicWandGet,
      magicWandReplace,
      penAdd,
      penReplace,
      pixelsAdd,
      pixelsGet,
      pixelsReplace,
      polygonAdd,
      polygonReplace,
      rectangleAdd,
      rectangleReplace,
      replaceAnnotationMarkupLabel,
      replaceAnnotations: reInitialize,
      replaceAnnotationType,
      resetAnnotations,
      resetUndo,
      sausageAdd,
      sausageGet,
      sausageReplace,
      setMarkupLabelIdAndColor,
      toggleSegmentationsVisible,
    }),
    [
      annotationDelete,
      annotationsDelete,
      applyMarkup,
      boundingBoxAdd,
      boundingBoxReplace,
      circleAdd,
      circleGet,
      circleReplace,
      getAnnotationCreated,
      getAnnotationFlavour,
      getAnnotationPixels,
      getAnnotationPolygon,
      magicWandAdd,
      magicWandGet,
      magicWandReplace,
      penAdd,
      penReplace,
      pixelsAdd,
      pixelsGet,
      pixelsReplace,
      polygonAdd,
      polygonReplace,
      rectangleAdd,
      rectangleReplace,
      replaceAnnotationMarkupLabel,
      reInitialize,
      replaceAnnotationType,
      resetAnnotations,
      resetUndo,
      sausageAdd,
      sausageGet,
      sausageReplace,
      setMarkupLabelIdAndColor,
      toggleSegmentationsVisible,
    ]
  );

  const result = useMemo(
    () => ({
      annotations: undostate.present,
      annotationActions: actions,
      undoActions: memoedactions,
      undoMeta,
      segmentationsVisible,
      lastPredictionTime,
    }),
    [
      actions,
      memoedactions,
      segmentationsVisible,
      undoMeta,
      undostate.present,
      lastPredictionTime,
    ]
  );

  return result;
}

export type Annotations2Type = ReturnType<typeof useAnnotations>;
