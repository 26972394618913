import { ImageSize } from "MarkupTypes";
import { useCallback } from "react";
import { useAddImageClassificationMarkupMutation } from "../../../../../../ts-clients/command";

export default function useSaveVectorData(
  imageId: string,
  networkId: string,
  markupSize: ImageSize
) {
  const [m] = useAddImageClassificationMarkupMutation();

  const mutation = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (vectorData: any[]) => {
      const activeMarkupLabelIds = vectorData
        .filter((v) => v.active)
        .map((v) => v.id);

      const inactiveMarkupLabelIds = vectorData
        .filter((v) => !v.active)
        .map((v) => v.id);

      m({
        variables: {
          input: {
            markupHeight: markupSize.height,
            markupWidth: markupSize.width,
            imageIds: [imageId],
            networkId,
            annotations: [
              ...activeMarkupLabelIds
                .flatMap((d) => (d !== "" ? d : []))
                .map((markupLabelId) => ({
                  markupLabelId,
                  value: 1,
                })),
              ...inactiveMarkupLabelIds
                .flatMap((d) => (d !== "" ? d : []))
                .map((markupLabelId) => ({
                  markupLabelId,
                  value: 0,
                })),
            ],
          },
        },
      });
    },
    [imageId, m, markupSize.height, markupSize.width, networkId]
  );
  return mutation;
}
