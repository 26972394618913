import { Segment, Loader, Dimmer, Container } from "semantic-ui-react";
import { ApolloError } from "@apollo/client";

export const renderLoading = () => (
  <Container>
    <Segment>
      <Dimmer active>
        <Loader />
      </Dimmer>
    </Segment>
  </Container>
);

export const renderError = (error: ApolloError) => (
  <Container>
    <Segment>{error.message}</Segment>
  </Container>
);

export const renderEmpty = () => (
  <Container>
    <Segment>
      <p>Nothing to display here.</p>
    </Segment>
  </Container>
);
