/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-one-expression-per-line */
import { Fragment } from "react";
import { MarkupLabel } from "MarkupTypes";
import { Table, Icon, Segment, Button, Popup } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import MarkupIcon from "./MarkupIcon";
import AnnotationTypeSwitch from "./AnnotationTypeSwitch";
import MoveDropdown from "./MoveDropdown";
import {
  setSelectedSegmentation2DTool,
  setSelectedPolygonId,
  setActivePolygonId,
  getSelectedPolygonId,
  isPniActive,
  getSegmentationsVisible,
} from "../../inputImage/redux-state";
import { annotationDataToDisplayList } from "./util";
import { AnnotationType } from "../../../../../ts-clients/command";
import ModeIcon from "./ModeIcon";
import {
  canMutateMarkup,
  canUsePositiveNegativeIgnore,
} from "../../../../../state/loginState";
import { MyAnnotations } from "../../../../datapoint-viewer/ImageWithSegmentation2d/hooks/types";
import { useUIType } from "../../../../../hooksqueries";
import { MarkupModuleType } from "../../hooks/types";

const getShortcutInfo = (index: number) => {
  const indexPlusOne = index + 1;
  if (indexPlusOne <= 10) {
    return `${indexPlusOne % 10}`;
  } else if (indexPlusOne <= 20) {
    return `Shift-${indexPlusOne % 10}`;
  }
  return "";
};

const annotationTypeList: {
  t: AnnotationType;
  n: string;
}[] = [
  { n: "P", t: AnnotationType.Positive },
  { n: "N", t: AnnotationType.Negative },
  { n: "I", t: AnnotationType.Ignore },
];

type Props = {
  annotationData: MyAnnotations | undefined;
  currentNetworkLabelId: string;
  dataType: MarkupModuleType;
  labelList: MarkupLabel[];
  onDelete: (annotationId: string) => void;
  paintActive: string | null;
  paintFinish: () => void;
  paintStart: (annotationId: string) => void;
  replacePolygonLabel: (l: string, lColor: string, id: string) => void;
  replacePolygonType: (t: AnnotationType, id: string) => void;
  setCurrentNetworkLabelIdAndColor: (
    label: string,
    color: string,
    replace: boolean
  ) => void;
};

export default function MarkupList({
  annotationData,
  currentNetworkLabelId,
  dataType,
  labelList,
  onDelete,
  paintActive,
  paintFinish,
  paintStart,
  replacePolygonLabel,
  replacePolygonType,
  setCurrentNetworkLabelIdAndColor,
}: Props) {
  const dispatch = useDispatch();

  const selectedPolygonID = useSelector(getSelectedPolygonId);
  const pniActive = useSelector(isPniActive);
  const segmentationsVisible = useSelector(getSegmentationsVisible);

  const isMarkupMutable = useSelector(canMutateMarkup);
  const displayList = annotationDataToDisplayList(
    annotationData,
    selectedPolygonID
  );

  const select = (polygonId: string) => {
    if (isMarkupMutable) {
      dispatch(setSelectedSegmentation2DTool("None"));
      dispatch(setSelectedPolygonId(polygonId));
    }
  };

  const mouseOver = (polygonId: string) => {
    if (selectedPolygonID !== polygonId) {
      dispatch(setActivePolygonId(polygonId));
    }
  };

  const switchToMarkupLabel = (
    markupLabelId: string,
    markupLabelColor: string
  ) => {
    setCurrentNetworkLabelIdAndColor(markupLabelId, markupLabelColor, false);
    dispatch(setSelectedPolygonId(null));
  };

  const mouseOut = () => dispatch(setActivePolygonId(null));

  if (selectedPolygonID !== null) {
    document.getElementById(selectedPolygonID)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  const uiType = useUIType();

  const allowPositiveNegativeIgnore = useSelector(canUsePositiveNegativeIgnore);
  const usePositiveNegativeIgnore =
    dataType === "PipelineModuleNetworkImageSegmentation";

  const allowPixelEdit = dataType === "PipelineModuleNetworkImageSegmentation";

  const showMoveBetweenLabelsIcon = labelList.length > 1;

  return (
    <>
      {labelList.map((label, idx) => {
        const isCurrentLabel = label.id === currentNetworkLabelId;
        const displayItems = isCurrentLabel
          ? displayList.get(label.id) ?? []
          : [];
        const itemCount = (displayList.get(label.id) ?? []).length;

        return (
          <Fragment key={label.id}>
            <Segment
              compact
              attached
              onClick={() => switchToMarkupLabel(label.id, label.color)}
              style={{
                cursor: "pointer",
                padding: 9,
                position: "sticky",
                zIndex: 1,
                borderLeft: `16px solid ${label.color}`,
                backgroundColor:
                  label.id === currentNetworkLabelId ? "#E0E1E2" : "#fff",
              }}
            >
              <Icon name={isCurrentLabel ? "caret down" : "caret right"} />
              <span style={{ fontWeight: isCurrentLabel ? 900 : 400 }}>
                {label.name} ({itemCount})
              </span>
              {idx <= 20 ? (
                <>
                  {" "}
                  <kbd>{getShortcutInfo(idx)}</kbd>
                </>
              ) : (
                <></>
              )}
            </Segment>
            {(displayItems.length ?? 0) === 0
              ? null
              : isCurrentLabel && (
                  <div>
                    <Table attached className="ultracompact">
                      <Table.Body>
                        {displayItems.map((p) => {
                          const isSelectedAnnotation =
                            p.id === selectedPolygonID;
                          return (
                            <Table.Row
                              onMouseEnter={() => mouseOver(p.id)}
                              onMouseLeave={mouseOut}
                              key={p.id}
                              id={p.id}
                              style={{
                                backgroundColor: isSelectedAnnotation
                                  ? "#ffc986"
                                  : undefined,
                              }}
                            >
                              <Table.Cell
                                style={{
                                  width: "100%",
                                  margin: 0,
                                  borderLeft: isSelectedAnnotation
                                    ? "3px solid gray"
                                    : "3px solid transparent",
                                }}
                              >
                                <div
                                  onClick={() => select(p.id)}
                                  onKeyPress={() => select(p.id)}
                                  role="button"
                                  style={{ height: 28 }}
                                >
                                  <MarkupIcon
                                    p={p}
                                    selectedPolygonID={selectedPolygonID}
                                  />
                                  <ModeIcon dataType={p.previewAs} />
                                </div>
                              </Table.Cell>
                              <Table.Cell
                                textAlign="right"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <Popup
                                  content={
                                    p.isPrediction
                                      ? "Prediction by DENKweit AI"
                                      : "User Annotation"
                                  }
                                  trigger={
                                    <Button>
                                      <Icon
                                        fitted
                                        name={
                                          p.isPrediction ? "microchip" : "user"
                                        }
                                        color={p.isPrediction ? "pink" : "blue"}
                                      ></Icon>
                                      {p.isPrediction && p.probability
                                        ? `${(p.probability * 100).toFixed(0)}%`
                                        : ""}
                                    </Button>
                                  }
                                />
                                {uiType !== "halm" &&
                                  allowPositiveNegativeIgnore &&
                                  usePositiveNegativeIgnore && (
                                    <AnnotationTypeSwitch
                                      types={annotationTypeList}
                                      enabled={
                                        pniActive &&
                                        segmentationsVisible &&
                                        isMarkupMutable
                                      }
                                      currentType={p.annotationType}
                                      setType={(t) =>
                                        replacePolygonType(t, p.id)
                                      }
                                    />
                                  )}

                                {allowPixelEdit &&
                                  (paintActive === p.id ? (
                                    <Button
                                      icon="check"
                                      color="pink"
                                      onClick={paintFinish}
                                    />
                                  ) : (
                                    <Button
                                      icon="pencil"
                                      disabled={
                                        !segmentationsVisible ||
                                        !isMarkupMutable
                                      }
                                      onClick={() => paintStart(p.id)}
                                    />
                                  ))}

                                {showMoveBetweenLabelsIcon && (
                                  <MoveDropdown
                                    labels={labelList.filter(
                                      (l) => l.id !== currentNetworkLabelId
                                    )}
                                    set={(l, color) =>
                                      replacePolygonLabel(l, color, p.id)
                                    }
                                  />
                                )}
                                <Button
                                  icon="delete"
                                  onClick={() => onDelete(p.id)}
                                  color="red"
                                  disabled={
                                    !segmentationsVisible || !isMarkupMutable
                                  }
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </div>
                )}
          </Fragment>
        );
      })}
    </>
  );
}
