/* eslint-disable react/jsx-one-expression-per-line */
import { useSelector } from "react-redux";
import { Header, Divider } from "semantic-ui-react";
import { getVisibleDataTypes } from "../../../state/dataTypeDisplaySettings";
import { getPartsForDataTypes } from "./helper";
import { useDatapoint, useDatapointImageResolutions } from "./hooks";
import { formatDate } from "../../../utils/helpers";
import SettingsViewParts from "./parts";

type Props = {
  datapointId: string;
};

export default function SettingsViewForDatapoint({ datapointId }: Props) {
  const dataTypes = useSelector(getVisibleDataTypes);
  const parts = getPartsForDataTypes(dataTypes);
  const resolutions = useDatapointImageResolutions(datapointId);

  const datapoint = useDatapoint(datapointId);

  const [created, updated] = [
    datapoint?.createdAt ?? "",
    datapoint?.updatedAt ?? "",
  ]
    .flatMap((d) => (d !== undefined ? d : []))
    .map(formatDate);

  return (
    <>
      <Header as="h4">Researchbox Details</Header>
      <Divider />
      <p>
        <strong>Created: {created.timeAgo}</strong>
        <br />
        {created.dateTime}
      </p>
      <p>
        <strong>Last Updated: {updated.timeAgo}</strong>
        <br />
        {updated.dateTime}
      </p>
      <p>
        <strong>Produced by:</strong>
        <br />
        {datapoint?.producedBy.name}
      </p>
      <p>
        <strong>Datatype:</strong>
        <br />
        {datapoint?.producedBy.dataType}
      </p>
      <p>
        <strong>ID:</strong>
        <br />
        {datapoint?.id}
      </p>
      <Header as="h4">View Settings</Header>
      <Divider />
      <SettingsViewParts
        parts={parts}
        resolutions={resolutions}
        context="datapoint"
      />
    </>
  );
}
