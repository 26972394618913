import { MarkupModule, ModuleIO } from "./types";
import { useAllMarkupModulesQuery } from "../../../../ts-clients/query";
import { useDeepCompareMemoize } from "use-deep-compare-effect";

export default function useMarkupModules(pipelineId: string): {
  markupModules: MarkupModule[];
  loading: boolean;
} {
  const { data, loading } = useAllMarkupModulesQuery({
    variables: {
      pipelineId,
    },
    fetchPolicy: "network-only",
  });

  const ms = useDeepCompareMemoize(
    data?.getPipeline?.modules?.flatMap((m) =>
      m.__typename === "PipelineModuleNetworkImageClassification" ||
      m.__typename === "PipelineModuleNetworkImageSegmentation" ||
      m.__typename === "PipelineModuleNetworkImageInstanceSegmentation" ||
      m.__typename === "PipelineModuleNetworkImageObjectDetection"
        ? m
        : []
    ) ?? []
  );

  const result = useDeepCompareMemoize(
    ms.map((module) => {
      const moduledata = {
        id: module?.id ?? "",
        description: module?.description ?? "",
        name: module?.name ?? "",
      };

      const inputDatapointLabels = module.inputs
        .map((i) => i.edges.map((e) => e.from.name))
        .flat(1);

      const inputs: ModuleIO[] = module.inputs.map((i) => ({
        dataType: i.dataType,
        id: i.id,
        name: i.name,
        pipelineModuleId: module.id,
      }));

      const outputs: ModuleIO[] = module.outputs.map((o) => ({
        dataType: o.dataType,
        id: o.id,
        name: o.name,
        pipelineModuleId: module.id,
      }));

      const allTargetModules = module.outputs
        .map((o) => o.edges.map((e) => e.to.module.id))
        .flat();
      const firstTargetModule = allTargetModules[0] ?? "";

      return {
        ...moduledata,
        inputs,
        outputs,
        targetModuleId: firstTargetModule,
        inputDatapointLabels,
        typename:
          module?.__typename || "PipelineModuleNetworkImageSegmentation",
      };
    }) ?? []
  );

  return { markupModules: result, loading };
}
