import { useState, useEffect } from "react";
import { Flags } from "react-feature-flags";
import {
  PipelineModuleNetworkImageObjectDetectionProps as ModuleProps,
  BaseParams,
} from "ModuleTypes";
import {
  Form,
  Header,
  Segment,
  Label,
  Grid,
  Input as SemanticInput,
} from "semantic-ui-react";
import { useSelector } from "react-redux";
import { CirclePicker } from "react-color";
import * as Input from "./InputTypes";
import * as Base from "./BaseParams";
import { useAllOuputLabels } from "../hooks";
import { canUseNetworkAdvanced } from "../../../../state/loginState";
import { useUIType } from "../../../../hooksqueries";
import { MarkupLabel } from "MarkupTypes";
import getRandomColor from "./types";
import { omit } from "ramda";

type Props = {
  moduleData: ModuleProps;
  datasetId: string | null;
  moduleId: string;
  onCancel: () => unknown;
  onSave: () => unknown;
  setData: (m: ModuleProps) => unknown;
  setBaseParams: (b: BaseParams) => void;
  mode: "create" | "update";
};

export default function FormPipelineModuleNetwokObjectDetection({
  moduleData,
  moduleId,
  onCancel,
  onSave,
  setData,
  setBaseParams,
  datasetId,
  mode,
}: Props) {
  const { selectedPipelineModuleOutputIDs, classLabels, networkName } =
    moduleData;

  const [networkNameManuallyChanged, setNetworkNameManuallyChanged] =
    useState(false);
  const [mouseHovers, setMouseHovers] = useState(false);
  const [defaultInputChanged, setDefaultInputChanged] = useState(false);
  const [classLabelManuallyChanged, setClassLabelManuallyChanged] =
    useState(false);

  useEffect(() => {
    if (moduleId !== "") {
      setClassLabelManuallyChanged(true);
      setNetworkNameManuallyChanged(true);
    }
  }, [moduleId]);

  useEffect(() => {
    const newData: ModuleProps = { ...moduleData };

    const objcopy = classLabels.map(omit(["__typename"]));
    if (
      !classLabelManuallyChanged ||
      (classLabels.length > 0 && classLabels[0].name.length === 0)
    ) {
      objcopy[0] = { ...objcopy[0], name: moduleData.moduleName };
      if (!objcopy[0].color || objcopy[0].color === "#999") {
        objcopy[0].color = getRandomColor();
      }
      newData.classLabels = objcopy;
    }

    if (!networkNameManuallyChanged) {
      newData.networkName = moduleData.moduleName;
    }
    setData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleData.moduleName]);

  const setClassLabels = (v: MarkupLabel[]) => {
    setData({ ...moduleData, classLabels: v });
    setClassLabelManuallyChanged(true);
  };

  const setModuleName = (v: string) => {
    setData({ ...moduleData, moduleName: v });
  };

  const setNetworkName = (v: string) => {
    setData({ ...moduleData, networkName: v });
    setNetworkNameManuallyChanged(true);
  };

  const outputlabels = useAllOuputLabels(datasetId ?? "", "Image");

  useEffect(() => {
    if (moduleId === "" && outputlabels.length > 0 && !defaultInputChanged) {
      setData({
        ...moduleData,
        selectedPipelineModuleOutputIDs: [outputlabels[0].id],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultInputChanged, moduleId, outputlabels.length, setData]);

  const nameSet = moduleData.moduleName !== "";
  const networkSet = moduleData.networkName !== "";
  const inputSet =
    (datasetId !== null &&
      moduleData.selectedPipelineModuleOutputIDs.length !== 0) ||
    datasetId === null;
  let labelSet = false;
  if (classLabels.length > 0) {
    if (classLabels[0].name !== "") {
      labelSet = true;
    }
  }

  const warningText = `${nameSet ? "" : "A module name is required. "}${
    networkSet ? "" : "An output name is required. "
  }${inputSet ? "" : "No input has been selected. "}${
    labelSet ? "" : "The first label needs a name. "
  }`;

  const uiType = useUIType();

  const canUseAdvancedNetworkFeatures =
    useSelector(canUseNetworkAdvanced) || uiType === "halm";

  return (
    <Form>
      <Base.BaseParamsForm
        params={moduleData}
        setParams={setBaseParams}
        setCurrentModuleName={setModuleName}
        markNameField={mouseHovers && !nameSet}
      />
      {canUseAdvancedNetworkFeatures && (
        <>
          <Flags
            authorizedFlags={["manualInputAndOutput"]}
            renderOn={() =>
              datasetId !== null ? (
                <Grid columns="2">
                  <Grid.Column verticalAlign="bottom">
                    <label>Select Inputs</label>
                    <Input.IOSelect
                      disabled={mode === "update"}
                      content={selectedPipelineModuleOutputIDs}
                      data={outputlabels}
                      error={mouseHovers && !inputSet}
                      label="Select outputs of other modules as training input"
                      onChange={(v) => {
                        setDefaultInputChanged(true);
                        setData({
                          ...moduleData,
                          selectedPipelineModuleOutputIDs: v,
                        });
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column verticalAlign="bottom">
                    <label>Output Name</label>
                    <Form.Field error={mouseHovers && !networkSet}>
                      <SemanticInput
                        value={networkName}
                        onChange={(_, { value }) => setNetworkName(value)}
                      >
                        <input />
                      </SemanticInput>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              ) : (
                <Grid columns="1">
                  <Grid.Column verticalAlign="bottom">
                    <label>Output Name</label>
                    <Form.Field error={mouseHovers && !networkSet}>
                      <SemanticInput
                        value={networkName}
                        onChange={(_, { value }) => setNetworkName(value)}
                      >
                        <input />
                      </SemanticInput>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              )
            }
          />
          <Segment>
            <Label attached="top" size="small">
              <Header>Class Labels</Header>
            </Label>
            <Input.MarkupLabelInput
              content={classLabels}
              label={"\u00A0"}
              onChange={(v) => setClassLabels(v)}
              markFirstLabel={mouseHovers && !labelSet}
            />
          </Segment>
        </>
      )}
      {!canUseAdvancedNetworkFeatures && (
        <Form.Field welcome-tour-create-network="3">
          <label>Network Color</label>
          <CirclePicker
            color={classLabels.length > 0 ? classLabels[0].color : undefined}
            circleSize={27}
            circleSpacing={10}
            onChange={(v) =>
              setData({
                ...moduleData,
                classLabels: [{ ...classLabels[0], color: v.hex }],
              })
            }
            width="700px"
          />
        </Form.Field>
      )}

      <Base.Footer
        onCancel={onCancel}
        onSave={onSave}
        warningText={warningText}
        setMouseHovers={setMouseHovers}
        moduleId={moduleId}
      />
    </Form>
  );
}
