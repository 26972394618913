import MagneticFieldScan from "./MagneticFieldScan";
import UnknownDataType from "./UnknownDataType";
import ImagePreview from "./ImageWithSegmentation2d/ReadOnlyThumbnail";
import CcCheckerResult from "./CcCheckerResult/MetricPanel";
import { DataType } from "../../ts-clients/query";
import {
  CcCheckerDatapointType,
  DatapointType,
  ImageDatapointType,
  MagneticFieldScanType,
} from "../../types/extractedTypes";

type Params = {
  datapoint: DatapointType;
  onClick?: () => void;
};

export default function Preview({ datapoint, onClick }: Params) {
  switch (datapoint.producedBy.dataType) {
    case DataType.Image:
      return (
        <ImagePreview
          datapoint={datapoint as ImageDatapointType}
          onClick={onClick}
        />
      );
    case DataType.MagneticFieldScan:
      return <MagneticFieldScan d={datapoint as MagneticFieldScanType} />;
    case DataType.CcCheckerResult:
      return <CcCheckerResult d={datapoint as CcCheckerDatapointType} />;
    default:
      return <UnknownDataType d={datapoint} />;
  }
}

Preview.defaultProps = {
  onClick: undefined,
};
