import { ImageSize, Point } from "MarkupTypes";
import { FileInfo } from "../features/filemanagement/types/FileManagementTypes";

export const highDefault = -999999999;
export const lowDefault = 999999999;

export type ImageData = {
  data: Uint8ClampedArray;
  width: number;
  height: number;
  bytes: number;
};

export type ImageBounds = {
  lowestX: number;
  highestX: number;
  lowestY: number;
  highestY: number;
};

type SmoothValueHelper = {
  up: number;
  down: number;
};

type StoreType = {
  cursor: Point;
  uploadQueue: FileInfo[];
  sourceImageSize: ImageSize;
  imageData: ImageData;
  notificationId: string;
  paintLayer: string;
  canvas?: HTMLCanvasElement;
  canvasBounds: ImageBounds;
  circleRadius: number;
  smoothvalue: SmoothValueHelper;
};

const uglyStoreGlobalStore: StoreType = {
  cursor: { x: 0, y: 0 },
  uploadQueue: [],
  notificationId: "",
  sourceImageSize: {
    height: 0,
    width: 0,
  },
  imageData: {
    bytes: 4,
    data: new Uint8ClampedArray(),
    height: 0,
    width: 0,
  },
  paintLayer: "",
  canvasBounds: {
    lowestX: lowDefault,
    highestX: highDefault,
    lowestY: lowDefault,
    highestY: highDefault,
  },
  circleRadius: 20,
  smoothvalue: {
    down: 0,
    up: 0,
  },
};

export default uglyStoreGlobalStore;
