/* eslint-disable react/display-name */
import { useEffect, useMemo } from "react";
import { Divider, Card, Header } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setVisibleDataTypes } from "../../../state/dataTypeDisplaySettings";
import TopMenu from "../TopMenu";
import Preview from "../../datapoint-viewer";
import { DataType } from "../../../ts-clients/query";
import { NavigationProps } from "../utils";
import { getPath, ResearchBoxDetailsParams } from "../../../utils/routeManager";
import { getDataTypesForResearchbox } from "../../../utils/helpers";
import { DatapointType, ResearchBoxType } from "../../../types/extractedTypes";
import RightMenu from "./RightMenu";

type Props = {
  navigation: NavigationProps;
  researchbox: ResearchBoxType | null | undefined;
  pageParams: ResearchBoxDetailsParams;
};

const showDataTypes = [
  DataType.Image,
  DataType.MagneticFieldScan,
  DataType.Metric,
];

export const makeRenderCard =
  (pageParams: ResearchBoxDetailsParams) => (d: DatapointType) => {
    const { id, producedBy } = d;
    return (
      <Card key={id}>
        <div style={{ width: "100%", height: 290, overflow: "hidden" }}>
          <Preview datapoint={d} />
        </div>
        <Card.Content>
          <Card.Header>
            <Link
              to={getPath("DetailsDatapoint", {
                context: pageParams.context,
                datapointId: id,
                datasetId: pageParams.datasetId,
                listIndex: pageParams.listIndex,
                page: "asMetric",
              })}
            >
              {producedBy.name || <em>unlabeled</em>}
            </Link>
          </Card.Header>
        </Card.Content>
      </Card>
    );
  };

export default function DetailsOverview({
  researchbox,
  navigation,
  pageParams,
}: Props) {
  const dispatch = useDispatch();
  const renderCard = makeRenderCard(pageParams);

  const dataTypes = useMemo(
    () => (researchbox ? getDataTypesForResearchbox(researchbox) : []),
    [researchbox]
  );

  const displayableDatapoints =
    researchbox?.datapoints?.filter((d) =>
      showDataTypes.includes(d.producedBy.dataType)
    ) ?? [];

  useEffect(() => {
    dispatch(setVisibleDataTypes(dataTypes));
  }, [dataTypes, dispatch]);

  return (
    <>
      <Header as="h1">
        Overview
        <TopMenu current="overview" navigation={navigation} />
      </Header>
      <Divider />
      <Card.Group>{displayableDatapoints.map(renderCard)}</Card.Group>
      <RightMenu />
    </>
  );
}
