import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "semantic-ui-react";
import { useParams } from "react-router";
import Menu from "./Menu";
import { useGetOutputsQuery } from "../../ts-clients/query";
import {
  fillList,
  Output,
  getOutputDisplayPriority,
  moveUp,
  moveDown,
} from "../../state/datasetSettings";

import { DatasetSettingsParams } from "../../utils/routeManager";

export default function Visualization() {
  const { datasetId } = useParams<DatasetSettingsParams>();
  const dispatch = useDispatch();

  const { data: outputs } = useGetOutputsQuery({ variables: { datasetId } });

  const myoutputs = useMemo(
    () =>
      outputs?.getDataset?.pipeline?.modules?.reduce((carry, item) => {
        const newoutputs: Array<Output> = item.outputs
          .filter((o) => o.dataType === "Image")
          .map((o) => ({
            id: o.id,
            label: o.name,
          }));

        return [...carry, ...newoutputs];
      }, new Array<Output>()) || [],
    [outputs]
  );

  useEffect(() => {
    dispatch(fillList(myoutputs));
  }, [myoutputs, dispatch]);

  const priorityList = useSelector(getOutputDisplayPriority);

  return (
    <>
      <Menu datasetId={datasetId} active="visualisation" />

      <Table collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={4}>
              Select display preference by moving items
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {priorityList.map((o, i) => (
            <Table.Row key={o.id}>
              <Table.Cell>{i + 1}</Table.Cell>
              <Table.Cell>{o.label}</Table.Cell>
              <Table.Cell textAlign="right">
                <Button.Group>
                  <Button
                    size="tiny"
                    icon="arrow up"
                    disabled={i === 0}
                    onClick={() => dispatch(moveUp(o.id))}
                  />
                  <Button
                    size="tiny"
                    icon="arrow down"
                    disabled={i === priorityList.length - 1}
                    onClick={() => dispatch(moveDown(o.id))}
                  />
                </Button.Group>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
