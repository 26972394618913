import { ModuleType } from "ModuleTypes";
import { useCallback } from "react";
import { generatePath, useLocation } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import { PretrainedNetworkType } from "../types/extractedTypes";

export type Context = "sensorimport" | "researchboxlist";
type Cuid = string;
type ListIndex = number;

export type ResearchBoxDetailsPages = "overview" | "markup";
export type DataPointDetailsPage = "byCell" | "allAtOnce" | "asMetric" | null;
export type DatasetSettingsPage = "base" | "visualisation" | "ui";
export type DatasetOverviewPage =
  | "files"
  | "uploadfs"
  | "uploadsensor"
  | "dashboard";

export type ResearchBoxDetailsParams = {
  datasetId: Cuid;
  listIndex: ListIndex;
  page: ResearchBoxDetailsPages;
  context: Context;
};

export type DatasetOverviewParams = {
  datasetId: Cuid;
};

export type ResearchBoxListParams = {
  datasetId: Cuid;
  context: Context;
};

export type UploadFromFileSystemParams = {
  datasetId: Cuid;
};

export type SensorImportParams = {
  datasetId: Cuid;
  context: Context;
};

export type DetailsDatapointParams = {
  datasetId: Cuid;
  listIndex: ListIndex;
  datapointId: Cuid;
  page: DataPointDetailsPage;
  context: Context;
};

export type FlowDesignerParams = {
  datasetId: Cuid;
  context: Context;
};

export type FlowDesignerAddModuleParams = {
  datasetId: Cuid;
  pipelineId: Cuid;
  moduleType: ModuleType;
};

export type FlowDesignerEditModuleParams = {
  datasetId: Cuid;
  moduleId: Cuid;
};

export type DatasetSettingsParams = {
  datasetId: Cuid;
  page: DatasetSettingsPage;
};

export type DatasetAnalyzeParams = {
  datasetId: Cuid;
};

export type NetworkStatsParams = {
  datasetId: Cuid;
};

export type NetworkAddParams = {
  datasetId: Cuid;
  menuType: string;
};

export type NetworkCreateWizardParams = {
  moduleType: PretrainedNetworkType;
};

export type NetworkEditParams = {
  datasetId: Cuid;
  moduleId: Cuid;
  menuType: string;
};

export type NetworkListParams = {
  datasetId: Cuid;
  menuType: string;
};

export type UnknownParams = unknown;

type RouteParams = {
  DatasetOverview: DatasetOverviewParams;
  ResearchBoxDetails: ResearchBoxDetailsParams;
  ResearchBoxList: ResearchBoxListParams;
  SensorImport: SensorImportParams;
  DetailsDatapoint: DetailsDatapointParams;
  Login: UnknownParams;
  Unknown: UnknownParams;
  DatasetAdd: UnknownParams;
  Dashboard: UnknownParams;
  FlowDesignerAddModule: FlowDesignerAddModuleParams;
  FlowDesignerEditModule: FlowDesignerEditModuleParams;
  DatasetSettings: DatasetSettingsParams;
  UploadFromFileSystem: UploadFromFileSystemParams;
  FlowDesigner: FlowDesignerParams;
  Analyze: DatasetAnalyzeParams;
  NetworkList: NetworkListParams;
  NetworkEdit: NetworkEditParams;
  NetworkAdd: NetworkAddParams;
  NetworkCreateWizard: NetworkCreateWizardParams;
  NetworkStats: NetworkStatsParams;
};

export type RouteName = keyof RouteParams;

type ParamType<T> = T extends "ResearchBoxDetails"
  ? ResearchBoxDetailsParams
  : T extends "DatasetOverview"
  ? DatasetOverviewParams
  : T extends "ResearchBoxList"
  ? ResearchBoxListParams
  : T extends "SensorImport"
  ? SensorImportParams
  : T extends "DetailsDatapoint"
  ? DetailsDatapointParams
  : T extends "Login"
  ? UnknownParams
  : T extends "Unknown"
  ? UnknownParams
  : T extends "DatasetAdd"
  ? UnknownParams
  : T extends "Dashboard"
  ? UnknownParams
  : T extends "FlowDesignerAddModule"
  ? FlowDesignerAddModuleParams
  : T extends "FlowDesignerEditModule"
  ? FlowDesignerEditModuleParams
  : T extends "DatasetSettings"
  ? DatasetSettingsParams
  : T extends "UploadFromFileSystem"
  ? UploadFromFileSystemParams
  : T extends "FlowDesigner"
  ? FlowDesignerParams
  : T extends "Analyze"
  ? NetworkListParams
  : T extends "NetworkEdit"
  ? NetworkEditParams
  : T extends "NetworkAdd"
  ? NetworkAddParams
  : T extends "NetworkStats"
  ? NetworkStatsParams
  : T extends "NetworkCreateWizard"
  ? NetworkCreateWizardParams
  : never;

const defaultRouteName: RouteName = "Login";

const routes = new Map<RouteName, string>([
  ["DatasetOverview", "/dashboard/:datasetId"],
  [
    "ResearchBoxDetails",
    "/details/researchbox/:datasetId/:listIndex/:page/:context",
  ],
  ["ResearchBoxList", "/researchboxes/:context/:datasetId"],
  ["SensorImport", "/sensorimport/:datasetId/:context"],
  [
    "DetailsDatapoint",
    "/details/datapoint/:datasetId/:listIndex/:datapointId/:page/:context",
  ],
  ["Login", "/"],
  ["Dashboard", "/dashboard"],
  ["DatasetAdd", "/datasets/add"],
  [
    "FlowDesignerAddModule",
    "/flow-designer/:datasetId/addModule/:pipelineId/:moduleType",
  ],
  ["FlowDesignerEditModule", "/flow-designer/:datasetId/editModule/:moduleId"],
  ["DatasetSettings", "/datasetsettings/:datasetId/:page"],
  ["UploadFromFileSystem", "/uploadfs/:datasetId"],
  ["FlowDesigner", "/flow-designer/:datasetId/:context"],
  ["Analyze", "/analyze/:datasetId/:context"],
  ["NetworkEdit", "/networks/edit/:menuType/:datasetId/:moduleId"],
  ["NetworkAdd", "/networks/add/:menuType/:datasetId"],
  ["NetworkCreateWizard", "/networkcreate/:moduleType"],
  ["NetworkStats", "/networks/stats/:datasetId"],
]);

const getRoutePattern = (r: RouteName) => routes.get(r) ?? defaultRouteName;

// const changeUser =(key: K, value: User[K])
const getPath = <K extends keyof RouteParams>(name: K, params: ParamType<K>) =>
  // const getPath = (name: RouteName, params: RouteParams[RouteName]) =>
  generatePath(
    getRoutePattern(name),
    params as
      | { [key: string]: string | number | boolean | undefined }
      | undefined
  );

const useGoto = () => {
  const history = useHistory();
  const cb = useCallback(
    <T extends RouteName>(name: T, params: ParamType<T>, search?: string) =>
      history.push({ pathname: getPath(name, params), search }),
    [history]
  );
  return cb;
};

type CheckablePages = "sensorimport";

const usePageChecker = () => {
  const location = useLocation();
  return (p: CheckablePages): boolean => {
    return location.pathname.includes(p);
  };
};

const useParseRouteParams = <T extends RouteName>(r: T): ParamType<T> => {
  const params = useParams<{ [key: string]: string }>();

  switch (r) {
    case "DatasetOverview":
      return {
        datasetId: (params?.datasetId as Cuid) ?? "",
      } as ParamType<T>;
    case "DetailsDatapoint":
      return {
        context: (params?.context as Context) ?? "",
        datapointId: (params?.datapointId as Cuid) ?? "",
        datasetId: (params?.datasetId as Cuid) ?? "",
        listIndex: (parseInt(params?.listIndex ?? 0, 10) as ListIndex) ?? "",
        page: (params?.page as DataPointDetailsPage) ?? "",
      } as ParamType<T>;

    case "ResearchBoxDetails":
      return {
        context: (params?.context as Context) ?? "",
        datasetId: (params?.datasetId as Cuid) ?? "",
        listIndex: (parseInt(params?.listIndex ?? 0, 10) as ListIndex) ?? "",
        page: (params?.page as ResearchBoxDetailsPages) ?? "",
      } as ParamType<T>;

    case "ResearchBoxList":
      return {
        context: (params?.context as Context) ?? "",
        datasetId: (params?.datasetId as Cuid) ?? "",
      } as ParamType<T>;

    case "SensorImport":
      return {
        context: (params?.context as Context) ?? "",
        datasetId: (params?.datasetId as Cuid) ?? "",
      } as ParamType<T>;

    case "DatasetAdd":
      return {} as ParamType<T>;

    case "Dashboard":
      return {} as ParamType<T>;

    case "FlowDesignerAddModule":
      return {
        datasetId: (params?.datasetId as Cuid) ?? "",
        pipelineId: (params?.pipelineId as Cuid) ?? "",
        moduleType: (params?.moduleType as ModuleType) ?? "",
      } as ParamType<T>;

    case "FlowDesignerEditModule":
      return {
        datasetId: (params?.datasetId as Cuid) ?? "",
        moduleId: (params?.moduleId as Cuid) ?? "",
      } as ParamType<T>;

    case "DatasetSettings":
      return {
        datasetId: (params?.datasetId as Cuid) ?? "",
        page: (params?.page as DatasetSettingsPage) ?? "",
      } as ParamType<T>;

    case "UploadFromFileSystem":
      return {
        datasetId: (params?.datasetId as Cuid) ?? "",
      } as ParamType<T>;

    case "FlowDesigner":
      return {
        datasetId: (params?.datasetId as Cuid) ?? "",
        context: (params?.context as Context) ?? "",
      } as ParamType<T>;

    case "Analyze":
      return {
        datasetId: (params?.datasetId as Cuid) ?? "",
        context: (params?.context as Context) ?? "",
      } as ParamType<T>;

    case "NetworkEdit":
      return {
        datasetId: (params?.datasetId as Cuid) ?? "",
        moduleId: (params?.moduleId as Cuid) ?? "",
        menuType: (params?.menuType as string) ?? "",
      } as ParamType<T>;

    case "NetworkAdd":
      return {
        datasetId: (params?.datasetId as Cuid) ?? "",
        menuType: (params?.menuType as string) ?? "",
      } as ParamType<T>;

    case "NetworkCreateWizardParams":
      return {
        moduleType: (params?.moduleType as ModuleType) ?? "",
      } as ParamType<T>;

    case "NetworkStats":
      return {
        datasetId: (params?.datasetId as Cuid) ?? "",
      } as ParamType<T>;

    case "Login":
      return {} as ParamType<T>;
    case "Unknown":
      return {} as ParamType<T>;
    default:
      return {} as never;
  }
};

export {
  useGoto,
  getPath,
  usePageChecker,
  getRoutePattern,
  useParseRouteParams,
  defaultRouteName,
};
