import { useEffect } from "react";
import { useUserWithDatasetsQuery } from "../../../../ts-clients/query";
import { Dataset } from "../types";

export default function useDatasetList(username: string) {
  const { data, refetch } = useUserWithDatasetsQuery({
    variables: {
      username,
    },
    pollInterval: 2000,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const result: Dataset[] =
    data?.getUser?.datasets?.map((dataset) => ({
      id: dataset.id,
      name: dataset.name,
      description: dataset.description,
      thumbnail: dataset.thumbnail ? dataset.thumbnail : undefined,
      researchBoxCount: dataset.objectsAggregate?.count || 0,
    })) || [];

  return { result, refetch };
}
