import { Point } from "MarkupTypes";
import { useEffect, useState } from "react";
import { getContainCheck } from "../../../../utils/helpers";
import { MyAnnotations } from "./types";

export default function useHighlighing(annotations: MyAnnotations) {
  const [pointA, setPointA] = useState<Point | null>(null);
  const [pointB, setPointB] = useState<Point | null>(null);
  const [highlightedAnnotationIDs, setHighlightedAnnotationIds] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (pointA !== null && pointB !== null) {
      const check = getContainCheck(pointA, pointB);

      const highlightedCircleIds = annotations.circleAnnotations
        .filter((c) => check.checkPoint(c.center))
        .map((c) => c.id);

      const highlightedMagicWandIds = annotations.magicwandAnnotations
        .filter(check.checkRectangle)
        .map((m) => m.id);

      const highlightedPenIds = annotations.penAnnotations
        .filter(check.checkRectangle)
        .map((m) => m.id);

      const highlightedPixelIds = annotations.pixelAnnotations
        .filter(check.checkRectangle)
        .map((m) => m.id);

      const highlightedRectangleIds = annotations.rectangleAnnotations
        .filter(check.checkRectangle)
        .map((m) => m.id);

      const highlightedBoundingBoxIds = annotations.boundingBoxAnnotations
        .filter(check.checkRectangle)
        .map((m) => m.id);

      const highlightedSausageIds = annotations.sausageAnnotations
        .filter(check.checkRectangle)
        .map((m) => m.id);

      const highlightedPolygonIds = annotations.polygonAnnotations
        .filter((p) => check.checkPoints(p.points))
        .map((m) => m.id);

      setHighlightedAnnotationIds([
        ...highlightedCircleIds,
        ...highlightedMagicWandIds,
        ...highlightedPenIds,
        ...highlightedPixelIds,
        ...highlightedRectangleIds,
        ...highlightedBoundingBoxIds,
        ...highlightedSausageIds,
        ...highlightedPolygonIds,
      ]);

      clearHighlightRectangle();
    }
  }, [
    annotations.boundingBoxAnnotations,
    annotations.circleAnnotations,
    annotations.magicwandAnnotations,
    annotations.penAnnotations,
    annotations.pixelAnnotations,
    annotations.polygonAnnotations,
    annotations.rectangleAnnotations,
    annotations.sausageAnnotations,
    pointA,
    pointB,
  ]);

  const clearHighlightRectangle = () => {
    setPointA(null);
    setPointB(null);
  };

  const unhighlight = () => {
    clearHighlightRectangle();
    setHighlightedAnnotationIds([]);
  };

  const startHighlight = (p: Point) => {
    setPointA(p);
  };

  const endHighlight = (p: Point) => {
    setPointB(p);
  };

  const actions = {
    startHighlight,
    endHighlight,
    unhighlight,
  };
  return [actions, highlightedAnnotationIDs, pointA] as const;
}

type HighlightingType = ReturnType<typeof useHighlighing>;
export type HighlightingActions = HighlightingType[0];
export type HighlightedIds = HighlightingType[1];
