import { createContext, ReactNode } from "react";
import useDenkBox, {
  DenkBoxReturnType,
} from "../components/menus/settings-view/hooks/useDenkBox";

export const DenkboxContext = createContext<DenkBoxReturnType | undefined>(
  undefined
);

type Props = {
  children: ReactNode;
  networkId: string;
  datasetId?: string;
  pollInterval?: number;
};

export default function DenkboxProvider({
  datasetId,
  networkId,
  pollInterval,
  children,
}: Props) {
  const ret = useDenkBox(networkId, datasetId, pollInterval);

  return (
    <DenkboxContext.Provider value={ret}>{children}</DenkboxContext.Provider>
  );
}
