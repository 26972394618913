/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import { useState } from "react";
import { Form, Button, Modal } from "semantic-ui-react";
import { useUIType } from "../../hooksqueries";
import { useAddFolderMutation } from "../../ts-clients/command";

type FormData = {
  name: string;
  description: string;
};

const defaultFormData: FormData = {
  name: "",
  description: "",
};

const isDataValid = (f: FormData) => f.name.length > 0;

type Props = {
  datasetId: string;
  folderIds: string[];
  visible: boolean;
  hide: () => void;
};

export default function FolderAdd({
  visible,
  datasetId,
  folderIds,
  hide,
}: Props) {
  const [formData, setFormData] = useState<FormData>(defaultFormData);

  const [addFolder] = useAddFolderMutation({
    variables: {
      input: {
        name: formData.name,
        description: formData.description,
        parent: {
          datasetId: folderIds.length === 0 ? datasetId : undefined,
          folderId:
            folderIds.length > 0 ? folderIds[folderIds.length - 1] : undefined,
        },
      },
    },
  });

  const save = () => {
    addFolder();
    setFormData(defaultFormData);
    hide();
  };

  const setName = (name: string) => setFormData((data) => ({ ...data, name }));
  const setDescription = (description: string) =>
    setFormData((data) => ({ ...data, description }));

  const uiType = useUIType();

  return (
    <Modal onClose={hide} open={visible} as={Form} onSubmit={save}>
      <Modal.Header>
        Create New {uiType === "halm" ? "Dataset" : "Folder"}
      </Modal.Header>
      <Modal.Content>
        <Form.Field>
          <label>Name*</label>
          <Form.Input
            placeholder={`Name of ${uiType === "halm" ? "dataset" : "folder"}`}
            value={formData.name}
            onChange={(_, { value }) => setName(value)}
          />
        </Form.Field>
        <Form.TextArea
          label="Description"
          placeholder={`An optional description of the new ${
            uiType === "halm" ? "dataset" : "folder"
          } ...`}
          value={formData.description}
          onChange={(_, { value }) =>
            setDescription(value as unknown as string)
          }
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={!isDataValid(formData)}
          positive
          content={`Create ${uiType === "halm" ? "dataset" : "folder"}`}
        />
        <Button onClick={hide} content="Cancel" />
      </Modal.Actions>
    </Modal>
  );
}
