type Props = {
  haystack: string;
  needle: string;
};

export default function HighlightSubstring({
  needle: stringToFind,
  haystack: stringToSearch,
}: Props) {
  const index = stringToSearch
    .toLowerCase()
    .indexOf(stringToFind.toLowerCase());
  if (index === -1) {
    return <>{stringToSearch}</>;
  }
  const firstPart = stringToSearch.substring(0, index);
  const secondPart = stringToSearch.substring(
    index,
    index + stringToFind.length
  );
  const thirdPart = stringToSearch.substring(index + stringToFind.length);
  return (
    <>
      {firstPart}
      <u>{secondPart}</u>
      {thirdPart}
    </>
  );
}
