import useHook from "../../../hooks/moduleCreators-new-2021-04/PMCCCheckerCreate";
import ModuleForm from "./forms/FormPipelineModuleCCChecker";

type Props = {
  datasetId: string | null;
  onCancel: () => unknown;
};

export default function PMCCCheckerCreate({ datasetId, onCancel }: Props) {
  const { create, moduleData, setModuleData, setBaseParams } = useHook();

  const onSubmit = () => {
    create(datasetId);
    onCancel();
  };

  return (
    <ModuleForm
      moduleData={moduleData}
      onCancel={onCancel}
      onSave={onSubmit}
      setBaseParams={setBaseParams}
      setData={setModuleData}
    />
  );
}
