import { useState } from "react";
import useCopyDataset from "./useCopyDataset";

type CopyInput = {
  targetDatasetName: string;
  targetDatasetDescription: string;
};

export default function useDatasetCopy(
  datasetId: string,
  targetUsername: string,
  defaultData: CopyInput
) {
  const [copyData, setCopyData] = useState<CopyInput>(defaultData);

  const copyDefault = useCopyDataset({
    datasetId,
    targetUsername,
    ...copyData,
  });

  return { copy: copyDefault, copyData, setCopyData };
}
