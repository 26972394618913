import { Switch, Route } from "react-router-dom";
import AddNetwork from "./AddNetwork/AddNetwork";
import EditNetwork from "./EditNetwork";
import NewList from "./NewList";
import { getRoutePattern } from "../../utils/routeManager";

export default function NetworksRoot() {
  return (
    <Switch>
      <Route path={getRoutePattern("NetworkAdd")} component={AddNetwork} />
      <Route path={getRoutePattern("NetworkEdit")} component={EditNetwork} />
      <Route path={getRoutePattern("NetworkStats")} component={NewList} />
    </Switch>
  );
}
