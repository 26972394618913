import { Tab } from "semantic-ui-react";
import ImageInfo from "./Tabs/ImageInfoTab";
import TrainingMenu from "./Tabs/TrainingTab";
import StatsTab from "./Tabs/StatsTab";
import { useState } from "react";
import { ImageDatapointType } from "../../../../types/extractedTypes";
import NetworkDetails from "./Tabs/parts/NetworkDetails";

type Props = {
  image: ImageDatapointType;
  currentNetworkId: string;
};

const panes = [
  {
    menuItem: "Training",
  },
  {
    menuItem: "Stats",
  },
  {
    menuItem: "Image Info",
  },
];

export default function MarkupToolRightMenuWithTraining({
  image,
  currentNetworkId,
}: Props) {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        onTabChange={(_, { activeIndex }) =>
          setCurrentTab(activeIndex as number)
        }
      />
      {currentTab === 0 && <TrainingMenu networkId={currentNetworkId} />}
      {currentTab === 1 && <StatsTab />}
      {currentTab === 2 && (
        <ImageInfo image={image} currentNetworkId={currentNetworkId} />
      )}
      <br />
      <NetworkDetails />
    </>
  );
}
