// import { useParams } from "react-router-dom";
import AddNetwork from "../../features/networks3/AddNetwork/CreateNetworkWizard";
// import ResearchBoxListMenu from "./ResearchBoxListMenu";
import Center from "../../features/filemanagement/DefaultPositioner";
import MenuMain from "../../components/menus/menu.main";

// type Params = {};

export default function NetworkCreateWizardContainer() {
  //   const {} = useParams<Params>();
  return (
    <>
      <MenuMain />
      {/* <ResearchBoxListMenu current="networks" datasetId={datasetId} /> */}
      <Center>
        <AddNetwork />
      </Center>
    </>
  );
}
