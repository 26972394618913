/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
import { useEffect, useState } from "react";
import { Menu, Dropdown, Icon, Image } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import SettingsModal from "../settings/UserSettingsModal";
import AboutModal from "./AboutModal";
import styles from "./Menu.css";
import denklight from "../../resources/halm_DENK_dunkel_w.png";

import {
  getCurrentUser,
  currentUserIsAdmin,
  isLoggedIn,
  setCurrentUser,
  currentUserIsVendor,
} from "../../state/loginState";
import Notifactions from "./notifications";
import { Role, useAllUsersLazyQuery } from "../../ts-clients/query";
import { useCreateTokenMutation } from "../../ts-clients/command";
import useLogout from "../../hooks/useLogout";
import { useGoto } from "../../utils/routeManager";

function closeWindow() {
  if (process.env.REACT_APP_ELECTRON) {
    const remote = global.require("electron"); // require('electron');
    const window = remote.BrowserWindow.getFocusedWindow();
    if (window != null) {
      window.close();
    }
  }
}

function minimizeWindow() {
  if (process.env.REACT_APP_ELECTRON) {
    const remote = global.require("electron"); // require('electron');
    const window = remote.BrowserWindow.getFocusedWindow();
    if (window != null) {
      window.minimize();
    }
  }
}

function maximizeWindow() {
  if (process.env.REACT_APP_ELECTRON) {
    const remote = global.require("electron"); // require('electron');
    const window = remote.BrowserWindow.getFocusedWindow();
    if (window != null) {
      if (window.isMaximized()) {
        window.unmaximize();
      } else {
        window.maximize();
      }
    }
  }
}

export default function MenuMain(): JSX.Element {
  const location = useLocation();
  const guessActive = (path: string) => location.pathname.startsWith(path);

  const logoutNow = useLogout();
  const [isSettingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
  const [isAboutModalOpen, setAboutModalOpen] = useState<boolean>(false);
  const currentUser = useSelector(getCurrentUser);
  const loggedIn = useSelector(isLoggedIn);
  const isAdmin = useSelector(currentUserIsAdmin);
  const isVendor = useSelector(currentUserIsVendor);

  const dispatch = useDispatch();

  const [queryUsers, { data: allUsers }] = useAllUsersLazyQuery();

  const [createToken] = useCreateTokenMutation();

  useEffect(() => {
    if (isAdmin || isVendor) {
      queryUsers();
    }
  }, [isAdmin, isVendor, queryUsers]);

  const hideSettingsModal = () => {
    setSettingsModalOpen(false);
  };

  const hideAboutModal = () => {
    setAboutModalOpen(false);
  };

  const goto = useGoto();

  return (
    <>
      <SettingsModal
        isModalOpen={isSettingsModalOpen}
        handleClose={hideSettingsModal}
      />
      <AboutModal isModalOpen={isAboutModalOpen} handleClose={hideAboutModal} />
      <Menu
        inverted
        style={{ paddingLeft: "150px", zIndex: 10001 }}
        fixed="top"
        // className={styles.mainMenu}
      >
        <Image
          onClick={() => goto("Dashboard", {})}
          src={denklight}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: 7,
            left: 12,
            height: 24,
          }}
        />

        <Menu.Menu position="right" className={styles.mainDropdown}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loggedIn && <Notifactions />}

            {loggedIn && (isAdmin || isVendor) && (
              <>
                <Dropdown labeled item pointing icon="users">
                  <Dropdown.Menu>
                    {allUsers?.queryUser?.map((u) => (
                      <Dropdown.Item
                        icon={
                          currentUser.username === u?.username
                            ? "checkmark"
                            : undefined
                        }
                        key={u?.username || ""}
                        text={u?.username || ""}
                        onClick={async () => {
                          const ret = await createToken({
                            variables: {
                              input: {
                                role: isAdmin ? Role.Admin : Role.Vendor,
                                username: u?.username || "",
                                queryAll: false,
                              },
                            },
                          });

                          dispatch(
                            setCurrentUser({
                              ...currentUser,
                              username: u?.username || "",
                              token: ret.data?.createToken?.token || "",
                              allowedFeatures:
                                ret.data?.createToken?.allowedFeatures || [],
                              blockedFeatures:
                                ret.data?.createToken?.blockedFeatures || [],
                            })
                          );
                          goto("Dashboard", {});
                        }}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Menu.Item onClick={() => goto("Dashboard", {})} icon="home" />
                <Dropdown labeled item pointing icon="wrench">
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={Link}
                      key="Users"
                      to="/admin/users"
                      active={guessActive("/admin/users")}
                    >
                      Users
                    </Dropdown.Item>
                    {isAdmin && (
                      <Dropdown.Item
                        as={Link}
                        key="Backups"
                        to="/admin/backups"
                        active={guessActive("/admin/backups")}
                      >
                        Backups
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      as={Link}
                      key="Networks"
                      to="/admin/networks"
                      active={guessActive("/admin/networks")}
                    >
                      Networks
                    </Dropdown.Item>
                    {isAdmin && (
                      <Dropdown.Item
                        as={Link}
                        key="Metadata"
                        to="/admin/metadata"
                        active={guessActive("/admin/metadata")}
                      >
                        Metadata Info
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}

            {loggedIn && (
              <Dropdown labeled item pointing icon="user">
                <Dropdown.Menu>
                  <Dropdown.Item
                    text={currentUser.username}
                    key="account"
                    icon="user"
                    disabled
                  />
                  <Dropdown.Item
                    text="Settings"
                    key="settings"
                    icon="settings"
                    onClick={() => {
                      setSettingsModalOpen(true);
                    }}
                  />
                  <Dropdown.Item
                    text="About"
                    key="About"
                    icon="info"
                    onClick={() => {
                      setAboutModalOpen(true);
                    }}
                  />
                  <Dropdown.Divider />
                  <Dropdown.Item
                    text="Log Out"
                    key="logout"
                    icon="log out"
                    onClick={logoutNow}
                  />
                </Dropdown.Menu>
              </Dropdown>
            )}
            {process.env.REACT_APP_ELECTRON && (
              <Icon
                id="min-button"
                bordered
                link
                name="window minimize"
                onClick={minimizeWindow}
                className={styles.mainMenuButton}
              />
            )}
            {process.env.REACT_APP_ELECTRON && (
              <Icon
                id="max-button"
                bordered
                link
                name="window maximize"
                onClick={maximizeWindow}
                className={styles.mainMenuButton}
              />
            )}
            {process.env.REACT_APP_ELECTRON && (
              <Icon
                id="close-button"
                bordered
                link
                name="close"
                onClick={closeWindow}
                className={styles.mainMenuButton}
              />
            )}
          </div>
        </Menu.Menu>
      </Menu>
      <ToastContainer />
    </>
  );
}
