import { useIdleTimer } from "react-idle-timer";
import useLogout from "./useLogout";

export default function useAutoLogout() {
  const logoutNow = useLogout();
  useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: logoutNow,
    debounce: 500,
  });
  return { logoutNow };
}
