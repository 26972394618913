/* eslint-disable react/jsx-wrap-multilines */
import { useState } from "react";
import { useSelector } from "react-redux";
import { BaseParams } from "ModuleTypes";
import { Button, Popup, Confirm } from "semantic-ui-react";
import * as Input from "./InputTypes";
import { useDeletePipelineModuleMutation } from "../../../../ts-clients/command";
import JoyRide from "react-joyride";
import { getIsActive, WelcomeTour } from "../../../../state/tourState";

type Props = {
  params: BaseParams;
  setParams: (p: BaseParams) => unknown;
  setCurrentModuleName: (v: string) => void;
  // eslint-disable-next-line react/require-default-props
  markNameField?: boolean;
};

export const BaseParamsForm = ({
  params,
  markNameField = false,
  setParams,
  setCurrentModuleName,
}: Props) => {
  const { moduleDescription, moduleName } = params;

  const isActive = useSelector(getIsActive);

  return (
    <>
      <JoyRide steps={WelcomeTour.createNetwork} run={isActive} continuous />
      <div welcome-tour-create-network="1">
        <Input.StringInput
          content={moduleName}
          error={markNameField}
          label="Network Name"
          onChange={(v) => {
            params.moduleName = v;
            setParams({ ...params, moduleName: v });
            setCurrentModuleName(v);
          }}
        />
      </div>
      <div welcome-tour-create-network="2">
        <Input.LongStringInput
          content={moduleDescription}
          label="Network Description"
          onChange={(v) => setParams({ ...params, moduleDescription: v })}
        />
      </div>
    </>
  );
};

function ModuleDeletionConfirmBox(props: {
  moduleId: string;
  onCancel: (() => void) | undefined;
}) {
  const { moduleId, onCancel } = props;

  const [openBool, setOpenBool] = useState(false);

  const [deleteModuleMutation] = useDeletePipelineModuleMutation();
  const closeAndDelete = () => {
    deleteModuleMutation({
      variables: {
        input: {
          ids: [moduleId],
        },
      },
    })
      .then(onCancel)
      .catch(console.log);
    setOpenBool(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpenBool(true)}
        icon="trash"
        content="Delete Module"
        negative
      />
      <Confirm
        header="Delete Module?"
        content="Are you sure you want to delete this module?"
        cancelButton="Cancel"
        confirmButton="Delete Module"
        open={openBool}
        onCancel={() => setOpenBool(false)}
        onConfirm={closeAndDelete}
      />
    </>
  );
}

interface FooterProps {
  warningText?: string;
  moduleId?: string;
  setMouseHovers?: (value: boolean) => void;
  onCancel?: () => void;
  onSave?: () => void;
}

const defaultProps: FooterProps = {
  warningText: "",
  moduleId: "",
  setMouseHovers: () => {},
  onCancel: () => {},
  onSave: () => {},
};

export const Footer = ({
  warningText,
  moduleId,
  setMouseHovers = () => {},
  onCancel,
  onSave,
}: FooterProps) => {
  return (
    <div style={{ float: "right" }} welcome-tour-create-network="4">
      <Button onClick={onCancel} content="Cancel" basic />
      {moduleId !== "" && (
        <>
          <ModuleDeletionConfirmBox
            moduleId={moduleId ?? ""}
            onCancel={onCancel}
          />
        </>
      )}
      <Popup
        hoverable
        onOpen={() => setMouseHovers(true)}
        onClose={() => setMouseHovers(false)}
        disabled={warningText === ""}
        trigger={
          <span>
            <Button
              onClick={onSave}
              content="Save Network"
              positive
              disabled={warningText !== ""}
            />
          </span>
        }
      >
        {warningText}
      </Popup>
    </div>
  );
};

Footer.defaultProps = defaultProps;
