/* eslint-disable react/jsx-curly-newline */
import { Button, Header, Checkbox } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

import {
  getMagneticFieldSettings,
  setMagneticFieldSettings,
} from "../../../../state/dataTypeDisplaySettings";

export default function MagneticFieldDataSettings() {
  const dispatch = useDispatch();
  const s = useSelector(getMagneticFieldSettings);
  return (
    <>
      <Header as="h5">Magnetic Field Scan</Header>
      <Button.Group fluid>
        <Button
          content="X"
          active={s.component === "x"}
          onClick={() =>
            dispatch(setMagneticFieldSettings({ ...s, component: "x" }))
          }
        />
        <Button
          content="Y"
          active={s.component === "y"}
          onClick={() =>
            dispatch(setMagneticFieldSettings({ ...s, component: "y" }))
          }
        />
        <Button
          content="Z"
          active={s.component === "z"}
          onClick={() =>
            dispatch(setMagneticFieldSettings({ ...s, component: "z" }))
          }
        />
        <Button
          content="‖B‖²"
          active={s.component === "abs"}
          onClick={() =>
            dispatch(setMagneticFieldSettings({ ...s, component: "abs" }))
          }
        />
      </Button.Group>
      {/* <Button.Group fluid style={{ marginTop: 15 }}>
        <Button
          content="Surface"
          active={s.plotType === 'surface'}
          onClick={() =>
            dispatch(setMagneticFieldSettings({ ...s, plotType: 'surface' }))
          }
        />
        <Button
          content="Heatmap"
          active={s.plotType === 'heatmap'}
          onClick={() =>
            dispatch(setMagneticFieldSettings({ ...s, plotType: 'heatmap' }))
          }
        />
      </Button.Group> */}
      <br />
      <br />
      <Checkbox
        toggle
        label="subtract baseline"
        checked={s.subtractBaseline}
        onClick={() =>
          dispatch(
            setMagneticFieldSettings({
              ...s,
              subtractBaseline: !s.subtractBaseline,
            })
          )
        }
      />
      <br />
      <br />
      <Checkbox
        toggle
        label="show only last scan"
        checked={s.showOnlyLastScan}
        onClick={() =>
          dispatch(
            setMagneticFieldSettings({
              ...s,
              showOnlyLastScan: !s.showOnlyLastScan,
            })
          )
        }
      />
    </>
  );
}
