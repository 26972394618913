import { DocumentNode, Operation } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";

export const isBTechOperation = (operation: Operation) =>
  operation.operationName.includes("BTech");

export const isOnlineOperation =
  (isInBrowser: boolean) => (operation: Operation) => {
    const isOnline = operation.getContext().Online || isInBrowser;
    console.log("is online?", isOnline);
    return isOnline;
  };

export const isSubscription = ({ query }: { query: DocumentNode }) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === "OperationDefinition" &&
    definition.operation === "subscription"
  );
};

export const isMutation = ({ query }: { query: DocumentNode }) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === "OperationDefinition" &&
    definition.operation === "mutation"
  );
};
