import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CursorType, PixelDrawMode, ToolType } from "MarkupTypes";
import { AnnotationType } from "../../../../ts-clients/command";
import { RootState } from "../../../../state/store";

type StateType = {
  cursorType: CursorType;
  cursorHidden: boolean;
  pixelDrawMode: PixelDrawMode;
  pniActive: boolean;
  segmentationsVisible: boolean;
  magicWandThreshold: number;
  sausageSize: number;
  annotationType: AnnotationType;
  selectedSegmentation2DTool: ToolType;
  selectedPolygonId: string | null;
  annotationDeleteCandidate: string | null;
  activePolygonId: string | null;
  hasFocus: boolean;
  annotationsChanged: boolean;
  markupReloadTrigger: number;
};

const name = "segmentation2d-state";

const initialState: StateType = {
  cursorType: "auto",
  cursorHidden: false,
  pixelDrawMode: "draw",
  segmentationsVisible: true,
  pniActive: false,
  sausageSize: 30,
  magicWandThreshold: 15,
  annotationType: AnnotationType.Positive,
  selectedSegmentation2DTool: "None",
  selectedPolygonId: null,
  activePolygonId: null,
  hasFocus: true,
  annotationDeleteCandidate: null,
  annotationsChanged: false,
  markupReloadTrigger: 0,
};

const appSlice = createSlice({
  name,
  initialState,
  reducers: {
    setPixelDrawMode: (state, action: PayloadAction<PixelDrawMode>) => ({
      ...state,
      pixelDrawMode: action.payload,
    }),
    togglePixelDrawMode: (state) => ({
      ...state,
      pixelDrawMode: state.pixelDrawMode === "draw" ? "erase" : "draw",
    }),

    toggleCursorHidden: (state) => ({
      ...state,
      cursorHidden: !state.cursorHidden,
    }),

    increaseMagicWandThreshold: (state) => ({
      ...state,
      magicWandThreshold: state.magicWandThreshold + 1,
    }),
    decreaseMagicWandThreshold: (state) => ({
      ...state,
      magicWandThreshold: state.magicWandThreshold - 1,
    }),

    setSausageSize: (state, action: PayloadAction<number>) => ({
      ...state,
      sausageSize: action.payload,
    }),
    setCursorType: (state, action: PayloadAction<CursorType>) => ({
      ...state,
      cursorType: action.payload,
    }),
    setAnnotationType: (state, action: PayloadAction<AnnotationType>) => ({
      ...state,
      annotationType: action.payload,
    }),
    toggleSegmentationsVisible: (state) => ({
      ...state,
      segmentationsVisible: !state.segmentationsVisible,
    }),
    enablePni: (state) => ({
      ...state,
      pniActive: true,
    }),
    setSelectedSegmentation2DTool: (
      state,
      action: PayloadAction<ToolType>
    ) => ({
      ...state,
      selectedSegmentation2DTool: action.payload,
    }),
    setSelectedPolygonId: (state, action: PayloadAction<string | null>) => {
      const selected =
        state.selectedSegmentation2DTool === "None" &&
        action.payload !== state.selectedPolygonId
          ? action.payload
          : null;
      return {
        ...state,
        selectedPolygonId: selected,
      };
    },
    setActivePolygonId: (state, action: PayloadAction<string | null>) => ({
      ...state,
      activePolygonId: action.payload,
    }),
    setFocus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      hasFocus: action.payload,
    }),

    setAnnotationsChanged: (state, action: PayloadAction<boolean>) => ({
      ...state,
      annotationsChanged: action.payload,
    }),

    triggerMarkupReload: (state) => ({
      ...state,
      markupReloadTrigger: Math.random(),
    }),
  },
});

export const {
  decreaseMagicWandThreshold,
  enablePni,
  increaseMagicWandThreshold,
  setActivePolygonId,
  setAnnotationsChanged,
  setAnnotationType,
  setCursorType,
  setPixelDrawMode,
  setSausageSize,
  setSelectedPolygonId,
  setSelectedSegmentation2DTool,
  togglePixelDrawMode,
  toggleSegmentationsVisible,
  triggerMarkupReload,
  toggleCursorHidden,
} = appSlice.actions;

export default appSlice.reducer;

export const getCursorType = (state: RootState) => {
  return state.segmentation2dState.cursorType;
};
export const isCursorHidden = (state: RootState) => {
  return state.segmentation2dState.cursorHidden;
};

export const isPniActive = (state: RootState) => {
  return state.segmentation2dState.pniActive;
};

export const getMagicWandThreshold = (state: RootState) => {
  return state.segmentation2dState.magicWandThreshold;
};

export const getAnnotationType = (state: RootState) => {
  return state.segmentation2dState.annotationType;
};

export const getSelectedSegmentation2DTool = (state: RootState) => {
  return state.segmentation2dState.selectedSegmentation2DTool;
};

export const getSelectedPolygonId = (state: RootState) => {
  return state.segmentation2dState.selectedPolygonId;
};

export const getActivePolygonId = (state: RootState) => {
  return state.segmentation2dState.activePolygonId;
};

export const getSausageSize = (state: RootState) => {
  return state.segmentation2dState.sausageSize;
};

export const getSegmentationsVisible = (state: RootState) => {
  return state.segmentation2dState.segmentationsVisible;
};

export const getPixelDrawMode = (state: RootState) => {
  return state.segmentation2dState.pixelDrawMode;
};
export const hasFocus = (state: RootState) => {
  return state.segmentation2dState.hasFocus;
};

export const getAnnotationsChanged = (state: RootState) => {
  return state.segmentation2dState.annotationsChanged;
};
export const getMarkupReloadTrigger = (state: RootState) => {
  return state.segmentation2dState.markupReloadTrigger;
};
