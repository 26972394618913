import { useCallback, useMemo } from "react";
import { InterActionAction } from "./types";
import useInteractionAction from "./useInteraction";
import { InteractionHooksType } from "./useInteractionHooks";

type Props = {
  callHook: InteractionHooksType["callHook"];
};

export default function useInteractionsReadOnly({ callHook }: Props) {
  const actZoomIn = useCallback(() => callHook("stageZoomIn"), [callHook]);
  const actZoomOut = useCallback(() => callHook("stageZoomOut"), [callHook]);

  const interActions = useMemo(
    () =>
      new Map<InterActionAction, () => void>([
        ["zoom-in", actZoomIn],
        ["zoom-out", actZoomOut],
      ]),
    [actZoomIn, actZoomOut]
  );

  const { updateState } = useInteractionAction(interActions);

  return updateState;
}
