import { Link } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import useQuery from "../../hooks/useQuery";
import { getPath } from "../../utils/routeManager";

type Props = {
  visible: boolean;
  datasetId: string;
  listIndex: number;
  setVisible: (value: boolean) => void;
};

const CreateNetworkModal = ({
  visible,
  datasetId,
  listIndex,
  setVisible,
}: Props) => {
  const query = useQuery();
  const path = query.get("path");

  return (
    <Modal open={visible} onClose={() => setVisible(false)}>
      <Modal.Header>No Networks Found</Modal.Header>
      <Modal.Content>Do you want to create a new network?</Modal.Content>
      <Modal.Actions>
        <Button
          content="Yes"
          as={Link}
          to={`${getPath("NetworkAdd", {
            datasetId,
            menuType: "datasetMenu",
          })}?listIndex=${listIndex}${path ? `&path=${path}` : ""}`}
          positive
          onClick={() => setVisible(false)}
        />
        <Button
          content="No"
          as={Link}
          negative
          to={`${getPath("ResearchBoxDetails", {
            datasetId,
            listIndex,
            context: "researchboxlist",
            page: "overview",
          })}${path ? `?path=${path}` : ""}`}
          onClick={() => setVisible(false)}
        />
        <Button content="Close" onClick={() => setVisible(false)} />
      </Modal.Actions>
    </Modal>
  );
};

export default CreateNetworkModal;
