import { useCallback, useState } from "react";
import { VectorMarkup } from "../types";

export default function useLabelList() {
  const [vector, setVector] = useState<VectorMarkup[]>([]);

  const toggle = useCallback((labelid: string) => {
    setVector((ml) =>
      ml.map(
        (m): VectorMarkup =>
          m.id === labelid
            ? { ...m, mode: "userAnnotated", active: !m.active }
            : m
      )
    );
  }, []);

  const enableAll = useCallback(
    () =>
      setVector((ml) =>
        ml.map(
          (m): VectorMarkup => ({ ...m, active: true, mode: "userAnnotated" })
        )
      ),
    []
  );

  const disableAll = useCallback(
    () =>
      setVector((ml) =>
        ml.map(
          (m): VectorMarkup => ({ ...m, active: false, mode: "userAnnotated" })
        )
      ),
    []
  );

  return { vector, toggle, enableAll, disableAll, setVector };
}
