import { useUpdateFolderMutation } from "../../../ts-clients/command";

export default function useRenameFolder(folderId: string, name: string) {
  const [updateFolder] = useUpdateFolderMutation();
  return () =>
    updateFolder({
      variables: {
        input: {
          folderId,
          name,
        },
      },
    });
}
