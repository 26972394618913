import { useSelector } from "react-redux";
import { Modal, Button } from "semantic-ui-react";
import { useGoto } from "../../utils/routeManager";
import { getRouteInfo } from "../../state/deviceControl";

export default function SensorWarning() {
  const goTo = useGoto();
  const routeInfo = useSelector(getRouteInfo);
  return (
    <Modal dimmer="inverted" open>
      <Modal.Header>Warning</Modal.Header>
      <Modal.Content>
        You have unsaved work. Please click &quot;finish record&quot; before
        leaving this page.
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => goTo("SensorImport", routeInfo)}>Go Back</Button>
      </Modal.Actions>
    </Modal>
  );
}
