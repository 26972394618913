import { Modal, Button } from "semantic-ui-react";
import { useQueryBuildInfoQuery } from "../../ts-clients/query";

export default function AboutModal(props: {
  handleClose: () => void;
  isModalOpen: boolean;
}): JSX.Element {
  const { handleClose, isModalOpen } = props;
  const { data } = useQueryBuildInfoQuery();

  return (
    <>
      {/* <Button icon="paper plane" color="blue" fluid onClick={handleClose} /> */}
      <Modal open={isModalOpen}>
        <Modal.Header>About this Software</Modal.Header>
        <Modal.Content>
          <p>{`Version: ${data?.queryBuildInfo?.versionTag}`}</p>
          <p>{`Build Date: ${data?.queryBuildInfo?.buildDate}`}</p>
          <p>{`Commit Hash: ${data?.queryBuildInfo?.gitCommitHash}`}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose} basic>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
