/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-newline */
import { useState } from "react";
import { Card, Image, Checkbox, Input } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { getOutputDisplayPriority } from "../../state/datasetSettings";
import { formatTime } from "../../utils/helpers";
import { getPreviewDataPoint } from "../details-researchbox/utils";
import DatapointPreview from "../datapoint-viewer";
import noimg from "../../resources/noimg.png";
import ResearchBoxActions from "./ResearchBoxActions";
import {
  ImageDatapointType,
  ResearchBoxType,
} from "../../types/extractedTypes";
import useAnnotationLabels from "../datapoint-viewer/ImageWithSegmentation2d/hooks/useAnnotationLabels";
import CircleLabel from "../datapoint-viewer/ImageWithSegmentation2d/Layers/AnnotationTypes/lowlevel/CircleLabel";
import { useUpdateResearchBoxMutation } from "../../ts-clients/command";
import { useDrag } from "react-dnd";
import { useUIType } from "../../hooksqueries";

export type Props = {
  selected?: boolean | undefined;
  researchBox: ResearchBoxType;
  triggerDelete: () => unknown;
  loading: boolean;
  onSelect: (value: boolean) => void;
  onRename: () => void;
  openMarkupTool: () => void;
};

const getCardColor = (hastDataPoint: boolean) =>
  hastDataPoint ? undefined : "red";

export default function ResearchBoxCard({
  selected,
  researchBox,
  loading,
  triggerDelete,
  onSelect,
  onRename,
  openMarkupTool,
}: Props) {
  const uiType = useUIType();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "researchbox",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: researchBox,
    canDrag: uiType !== "halm",
  }));

  const previewDatapoint = getPreviewDataPoint(
    useSelector(getOutputDisplayPriority),
    researchBox.datapoints || []
  );

  const created = Date.parse(researchBox.createdAt);
  const cardColor = getCardColor(previewDatapoint !== null);

  const { scalarLabels, segmentationLabels, objectDetectionLabels } =
    useAnnotationLabels(previewDatapoint as ImageDatapointType);

  const [cardFocused, setCardFocused] = useState(false);

  const [renameActive, setRenameActive] = useState(false);
  const [currentName, setCurrentName] = useState(researchBox?.name || "");

  const [updateResearchBox] = useUpdateResearchBoxMutation({
    variables: {
      input: {
        researchBoxId: researchBox?.id || "",
        name: currentName,
      },
    },
  });

  return (
    <Card
      color={cardColor}
      style={{ opacity: loading ? 0.6 : 1 }}
      onMouseEnter={() => setCardFocused(true)}
      onMouseLeave={() => setCardFocused(false)}
    >
      <div
        ref={drag}
        style={{ width: "100%", height: 290, opacity: isDragging ? 0.5 : 1.0 }}
      >
        {previewDatapoint ? (
          <DatapointPreview
            datapoint={previewDatapoint}
            onClick={() => {
              openMarkupTool();
            }}
          />
        ) : (
          <Image src={noimg} />
        )}
      </div>
      <Checkbox
        checked={selected}
        style={{
          visibility: cardFocused || selected ? "visible" : "hidden",
          alignSelf: "flex-end",
          position: "absolute",
          right: "5px",
          top: "5px",
        }}
        onClick={(e, data) => {
          e.stopPropagation();
          onSelect(data.checked || false);
        }}
      />
      <Card.Content textAlign="left">
        <Card.Header style={{ overflow: "hidden" }}>
          {!renameActive && (
            <a
              href={undefined}
              style={{ cursor: "pointer" }}
              onClick={openMarkupTool}
            >
              {researchBox.name}
            </a>
          )}
          {renameActive && (
            <Input
              value={currentName}
              onChange={(_, data) =>
                setCurrentName(data.value as unknown as string)
              }
              onKeyPress={async (e: React.KeyboardEvent) => {
                if (e.key === "Enter") {
                  setRenameActive(false);
                  await updateResearchBox();
                  onRename();
                }
              }}
            />
          )}
        </Card.Header>
        <Card.Meta>
          <span className="date">{formatTime(created / 1000)}</span>
        </Card.Meta>
        {[...scalarLabels, ...segmentationLabels, ...objectDetectionLabels].map(
          (l) => (
            <CircleLabel l={l} key={l.id} />
          )
        )}
        <div style={{ position: "absolute", bottom: "15px", right: "15px" }}>
          <ResearchBoxActions
            triggerDelete={triggerDelete}
            triggerRename={() => setRenameActive(true)}
          />
        </div>
      </Card.Content>
    </Card>
  );
}
