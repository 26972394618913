import { useParams } from "react-router-dom";
import { Container } from "semantic-ui-react";
import { useGoto, NetworkEditParams } from "../../../utils/routeManager";
import EditModule from "../../flow-designer/fneu/ModuleUpdate";

export default function EditNetwork() {
  const { moduleId, datasetId } = useParams<NetworkEditParams>();
  const goTo = useGoto();
  return (
    <Container>
      <EditModule
        datasetId={datasetId}
        onCancel={() => goTo("NetworkStats", { datasetId })}
        moduleId={moduleId}
      />
    </Container>
  );
}
