import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { switchArrayItems } from "../utils/helpers";

type OutputID = string;

export type Output = {
  id: OutputID;
  label: string;
};

export type Outputlist = Output[];

type StateType = {
  outputDisplayPriority: Outputlist;
};

const name = "datasetSettings";
const initialState: StateType = {
  outputDisplayPriority: [],
};

const appSlice = createSlice({
  name,
  initialState,
  reducers: {
    fillList: (state, action: PayloadAction<Outputlist>) => {
      state.outputDisplayPriority = action.payload.reduce((carry, item) => {
        return carry.find((i) => i.id === item.id) !== undefined
          ? carry
          : [...carry, item];
      }, state.outputDisplayPriority);
    },
    moveUp: (state, action: PayloadAction<OutputID>) => {
      const p = state.outputDisplayPriority;
      const x = p.findIndex((i) => i.id === action.payload);
      const y = x - 1;
      if (p[y]) {
        state.outputDisplayPriority = switchArrayItems(p, x, y);
      }
    },
    moveDown: (state, action: PayloadAction<OutputID>) => {
      const p = state.outputDisplayPriority;
      const x = p.findIndex((i) => i.id === action.payload);
      const y = x + 1;
      if (p[y]) {
        state.outputDisplayPriority = switchArrayItems(p, x, y);
      }
    },
  },
});

export const { fillList, moveUp, moveDown } = appSlice.actions;

export default appSlice.reducer;

export const getOutputDisplayPriority = (state: RootState) => {
  return state.datasetSettings.outputDisplayPriority;
};
