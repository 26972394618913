
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Cuid": [
      "MarkupLabel",
      "PipelineModuleInput",
      "PipelineModuleEdge",
      "Pipeline",
      "Dataset",
      "ProductionTag",
      "ImageResolution",
      "ImageMetadataField",
      "NetworkSnapshot",
      "Binary",
      "EncryptedBinary",
      "Metric",
      "NetworkConfig",
      "Denkjob",
      "Denkbox",
      "Gpu",
      "SegmentationMap2D",
      "Point2D",
      "BoundingBoxAnnotation",
      "BoundingBoxPrediction",
      "ScalarAnnotation",
      "ScalarPrediction",
      "SegmentationPredictionObject",
      "PretrainedNetwork"
    ],
    "Ownable": [
      "MarkupLabel",
      "PipelineModuleInput",
      "PipelineModuleEdge",
      "Pipeline",
      "Dataset",
      "ProductionTag",
      "ImageResolution",
      "ImageMetadataField",
      "NetworkSnapshot",
      "Binary",
      "EncryptedBinary",
      "Metric",
      "NetworkConfig",
      "Denkjob",
      "SegmentationMap2D",
      "Point2D",
      "BoundingBoxAnnotation",
      "BoundingBoxPrediction",
      "ScalarAnnotation",
      "ScalarPrediction",
      "SegmentationPredictionObject"
    ],
    "ReadOnly": [
      "Denkbox",
      "Gpu",
      "PretrainedNetwork"
    ],
    "Timestamped": [
      "PipelineModuleInput",
      "PipelineModuleEdge",
      "Pipeline",
      "Dataset",
      "User",
      "ProductionTag",
      "ImageResolution",
      "ImageMetadataField",
      "NetworkSnapshot",
      "Binary",
      "EncryptedBinary",
      "Metric",
      "NetworkConfig",
      "Denkjob",
      "Denkbox",
      "Gpu",
      "SegmentationPredictionObject",
      "PretrainedNetwork"
    ],
    "Notification": [
      "ProgressNotification",
      "DenktesterNotification",
      "MaintenanceNotification",
      "OneOffNotification"
    ],
    "NetworkModule": [
      "PipelineModuleNetworkImageSegmentation",
      "PipelineModuleNetworkImageClassification",
      "PipelineModuleNetworkImageObjectDetection",
      "PipelineModuleNetworkImageInstanceSegmentation",
      "PipelineModuleNetworkImageSuperResolution",
      "PipelineModuleNetworkImageOCR",
      "PipelineModuleNetworkImageAnomalyDetection",
      "PipelineModuleNetworkImageObjectCounting"
    ],
    "ImageMarkup": [
      "SegmentationMarkup",
      "ClassificationMarkup",
      "InstanceSegmentationMarkup",
      "InstanceSegmentationPrediction",
      "ObjectDetectionMarkup"
    ],
    "Datapoint": [
      "Image",
      "CCCheckerResult",
      "MagneticFieldScan"
    ],
    "PipelineModuleOutput": [
      "PipelineModuleDatapointOutput",
      "PipelineModuleArtifactOutput"
    ],
    "PipelineModule": [
      "PipelineModuleUpload",
      "PipelineModuleBMobile",
      "PipelineModuleCCChecker",
      "PipelineModuleContrastEnhancement",
      "PipelineModuleNetworkImageSegmentation",
      "PipelineModuleNetworkImageClassification",
      "PipelineModuleNetworkImageObjectDetection",
      "PipelineModuleNetworkImageInstanceSegmentation",
      "PipelineModuleNetworkImageSuperResolution",
      "PipelineModuleNetworkImageOCR",
      "PipelineModuleNetworkImageAnomalyDetection",
      "PipelineModuleNetworkImageObjectCounting"
    ],
    "FolderOrResearchBox": [
      "Folder",
      "ResearchBox"
    ],
    "ImagePrediction": [
      "SegmentationPrediction",
      "ClassificationPrediction",
      "ObjectDetectionPrediction"
    ],
    "SegmentationImageAnnotation": [
      "CircleAnnotation",
      "PolygonAnnotation",
      "PenAnnotation",
      "RectangleAnnotation",
      "SausageAnnotation",
      "MagicwandAnnotation",
      "PixelAnnotation"
    ],
    "ModuleJobRequest": [
      "PipelineModuleJobRequestCCChecker",
      "PipelineModuleJobRequestContrastEnhancement"
    ]
  }
};
      export default result;
    