import { DisplayItem } from "../util";
import MarkupIconPixel from "./MarkupIconPixel";
import MarkupIconPolygon from "./MarkupIconPolygon";

type Props = {
  p: DisplayItem;
  selectedPolygonID: string | null;
};

export default function MarkupIconIndex({ p, selectedPolygonID }: Props) {
  return p.previewAs === "polygon" ? (
    <MarkupIconPolygon
      labelColor={p.labelColor}
      points={p.points}
      iconSize={28}
      type={p.isPrediction ? undefined : p.annotationType}
      active={p.id === selectedPolygonID}
    />
  ) : (
    <MarkupIconPixel
      dataURL={p.dataURL || ""}
      labelColor={p.labelColor}
      iconSize={28}
      type={p.isPrediction ? undefined : p.annotationType}
      // active={p.id === selectedPolygonID}
    />
  );
}
