import { useSelector } from "react-redux";
import {
  QueryFilteredResearchBoxIdsQuery,
  QueryFilteredResearchBoxIdsDocument,
  QueryFilteredResearchBoxIdsQueryVariables,
} from "../ts-clients/query";
import {
  getFilename,
  getLabels,
  getNetworks,
  getIncludePositiveLabels,
  getIncludeNegativeLabels,
  getIncludeUnclassifiedLabels,
} from "../state/researchboxfilter";
import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";

export default function useQueryFilteredResearchBoxIdsByDatasetIdAndFolderId() {
  const filenameFilter = useSelector(getFilename);
  const networkIDs = useSelector(getNetworks);
  const labelFilter = useSelector(getLabels);
  const includePositives = useSelector(getIncludePositiveLabels);
  const includeNegatives = useSelector(getIncludeNegativeLabels);
  const includeUnclassified = useSelector(getIncludeUnclassifiedLabels);

  const client = useApolloClient();

  const cb = useCallback(
    async (path: string) => {
      const variables: QueryFilteredResearchBoxIdsQueryVariables = {
        filter: {
          parentIds:
            filenameFilter === "" && networkIDs.length == 0
              ? { eq: path }
              : undefined,
          name:
            filenameFilter === ""
              ? undefined
              : { regexp: `/.*${filenameFilter}.*/` },
          containsMarkupForNetworkId:
            networkIDs.length > 0 && !includeUnclassified
              ? { in: networkIDs }
              : undefined,
          not:
            networkIDs.length > 0 && includeUnclassified
              ? { containsMarkupForNetworkId: { in: networkIDs } }
              : undefined,
          and:
            labelFilter.length > 0 &&
            !includeUnclassified &&
            (includePositives || includeNegatives)
              ? [
                  {
                    or: [
                      includePositives
                        ? {
                            containsPositiveMarkupForLabelId: {
                              in: labelFilter.map((l) => l.id),
                            },
                          }
                        : undefined,
                      includeNegatives
                        ? {
                            containsNegativeMarkupForLabelId: {
                              in: labelFilter.map((l) => l.id),
                            },
                          }
                        : undefined,
                    ].flatMap((o) => (o ? o : [])),
                  },
                ]
              : undefined,
        },
      };

      const result = await client.query<
        QueryFilteredResearchBoxIdsQuery,
        QueryFilteredResearchBoxIdsQueryVariables
      >({
        query: QueryFilteredResearchBoxIdsDocument,
        variables,
        fetchPolicy: "network-only",
      });

      const ret = [
        ...(result.data.queryResearchBox?.map((r) => r?.id || "") || []),
      ].sort((a, b) => (a < b ? -1 : 1));

      return ret;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      client,
      filenameFilter,
      includeNegatives,
      includePositives,
      includeUnclassified,
      labelFilter,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(networkIDs),
    ]
  );

  return cb;
}
