import { useParams } from "react-router";
import ModuleUpdate from "./fneu/ModuleUpdate";
import { FlowDesignerEditModuleParams } from "../../utils/routeManager";

type Props = {
  onCancel: () => unknown;
};

export default function ModuleUpdateRouteShim({ onCancel }: Props) {
  const { moduleId, datasetId } = useParams<FlowDesignerEditModuleParams>();
  return (
    <ModuleUpdate
      moduleId={moduleId}
      datasetId={datasetId}
      onCancel={onCancel}
    />
  );
}
