import { Vector2d } from "MarkupTypes";
import { useEffect, useState } from "react";
import { sausageToPoly } from "../../hooks/annotationHelper";
import { MySausage } from "../../hooks/types";
import DragDot from "./lowlevel/DragDot";
import NewPolygon from "./lowlevel/NewPoly";
import { addVector, getPolygonConfig, getDistance } from "./lowlevel/util";

type Props = {
  c: MySausage;
  selectedMarkupLabelId?: string | null;
  labelColor: string;
  temporary?: boolean;
  select?: (id: string) => void;
  isSelected?: boolean;
  isHighlighted: boolean;
  update: (c: MySausage) => void;
  scale: number;
};

export default function Sausage({
  c,
  selectedMarkupLabelId = null,
  labelColor,
  temporary = false,
  select,
  isSelected = false,
  isHighlighted,
  update,
  scale,
}: Props) {
  const [myPoints, setMyPoints] = useState<Vector2d[]>([]);

  const [radius, setRadius] = useState(0);

  useEffect(() => {
    setRadius(c.radius);
  }, [c.radius]);

  const sausageWithCustomRadius = { ...c, radius };
  const points = sausageToPoly({
    ...sausageWithCustomRadius,
    points: myPoints,
  });

  useEffect(() => {
    setMyPoints(c.points);
  }, [c.points]);

  const getConfig = (isMouseOver: boolean) =>
    getPolygonConfig(
      c.annotationType,
      "Sausage",
      isMouseOver,
      isSelected,
      isHighlighted,
      temporary,
      selectedMarkupLabelId === c.markupLabelId,
      labelColor,
      scale
    );

  const movePolygon = (dist: Vector2d) =>
    setMyPoints((ps) => ps.map((p) => addVector(p, dist)));

  const updatePoint = (idx: number) => (p: Vector2d) => {
    setMyPoints((ps) => {
      ps.splice(idx, 1, p);
      return ps;
    });
  };

  const dragEnd = () =>
    update({ ...sausageWithCustomRadius, points: myPoints });

  const middlePoint = points[Math.floor(points.length / 2)];
  const lastPoint = myPoints[myPoints.length - 1];

  const changeRadiusTemporary = ({ x, y }: Vector2d) => {
    setRadius(getDistance(x, y, lastPoint.x, lastPoint.y) * 2);
  };

  const selectHandler = () => {
    if (select) {
      select(c.id);
    }
  };

  return (
    <>
      <NewPolygon
        getConfig={getConfig}
        points={points}
        select={selectHandler}
        isSelected={isSelected || isHighlighted}
        movePolygon={movePolygon}
      />
      {isSelected && (
        <>
          {myPoints.map(
            (p, idx) =>
              (idx === 0 || idx === myPoints.length - 1) && (
                <DragDot
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  position={p}
                  dragEnd={dragEnd}
                  dragTo={updatePoint(idx)}
                  scale={scale}
                />
              )
          )}
          {middlePoint && (
            <DragDot
              // eslint-disable-next-line react/no-array-index-key
              position={middlePoint}
              dragEnd={dragEnd}
              dragTo={changeRadiusTemporary}
            />
          )}
        </>
      )}
    </>
  );
}

Sausage.defaultProps = {
  temporary: false,
  select: undefined,
  isSelected: false,
  selectedMarkupLabelId: null,
};
