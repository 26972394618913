/* eslint-disable react/jsx-wrap-multilines */
import {
  Divider,
  Icon,
  List,
  Message,
  Progress,
  Popup,
  SemanticICONS,
} from "semantic-ui-react";
import { useMarkAsRead, useDeleteNotification } from "./hooks";
import {
  MaintenanceNotification,
  Notification,
  ProgressNotification,
} from "../../../ts-clients/query";

type NotificationType =
  | "ProgressNotification"
  | "OneOffNotification"
  | "MaintenanceNotification"
  | "DenktesterNotification";

type Props = {
  notification: Notification;
  notificationType: NotificationType;
};

const getIcon = (t: NotificationType): SemanticICONS => {
  if (t === "OneOffNotification") {
    return "bullhorn";
  }
  return "check";
};

const isLoading = (n: Notification, t: NotificationType): boolean => {
  if (t === "MaintenanceNotification") {
    return !(n as MaintenanceNotification).finished;
  }
  return (
    t === "ProgressNotification" &&
    (n as ProgressNotification).currentProgress <
      (n as ProgressNotification).maxProgress
  );
};

export default function NotifactionEntry({
  notification: n,
  notificationType: t,
}: Props) {
  const markAsRead = useMarkAsRead();
  const deleteNotification = useDeleteNotification();

  return (
    <List.Item key={n.id}>
      <div>
        <Message
          icon
          size="small"
          positive={!n.read && !n.error}
          error={!!n.error}
        >
          <Icon
            name={isLoading(n, t) ? "circle notched" : getIcon(t)}
            loading={isLoading(n, t)}
          />
          <Message.Content>
            <Message.Header>
              {n.title}
              <div style={{ float: "right" }}>
                <Popup
                  content="mark as read"
                  trigger={
                    <Icon link name="check" onClick={() => markAsRead(n.id)} />
                  }
                />
                <Popup
                  content="dismiss"
                  trigger={
                    <Icon
                      link
                      name="delete"
                      onClick={() => deleteNotification(n.id)}
                    />
                  }
                />
              </div>
            </Message.Header>
            <Divider />
            {n.message}
            {t === "ProgressNotification" && (
              <Progress
                size="small"
                progress="percent"
                percent={Math.round(
                  (100 * (n as ProgressNotification).currentProgress) /
                    (n as ProgressNotification).maxProgress
                )}
                color="blue"
              />
            )}
            {n.error && (
              <>
                <Divider />
                {n.error}
              </>
            )}
          </Message.Content>
        </Message>
      </div>
    </List.Item>
  );
}
