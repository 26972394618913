import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataType } from "../ts-clients/query";
import { RootState } from "./store";

type MagneticFieldSettings = {
  component: "x" | "y" | "z" | "abs";
  plotType: "surface" | "heatmap";
  subtractBaseline: boolean;
  showOnlyLastScan: boolean;
};

export const resolutionOptions: ResolutionOption[] = [
  "mini",
  "tiny",
  "small",
  "medium",
  "large",
  "raw",
];
export type ResolutionOption =
  | "mini"
  | "tiny"
  | "small"
  | "medium"
  | "large"
  | "raw";

type ImageSettings = {
  selectedResolutionOption: ResolutionOption;
};

type StateType = {
  visibleDataTypes: DataType[];
  magneticField: MagneticFieldSettings;
  image: ImageSettings;
};

const name = "appState";
const initialState: StateType = {
  visibleDataTypes: [],
  magneticField: {
    component: "x",
    plotType: "heatmap",
    subtractBaseline: true,
    showOnlyLastScan: true,
  },
  image: {
    selectedResolutionOption: "small",
  },
};

const appSlice = createSlice({
  name,
  initialState,
  reducers: {
    setMagneticFieldSettings: (
      state,
      action: PayloadAction<MagneticFieldSettings>
    ) => ({
      ...state,
      magneticField: action.payload,
    }),
    setImageSettings: (state, action: PayloadAction<ImageSettings>) => ({
      ...state,
      image: action.payload,
    }),
    setVisibleDataTypes: (state, action: PayloadAction<DataType[]>) => ({
      ...state,
      visibleDataTypes: action.payload,
    }),
  },
});

export const {
  setMagneticFieldSettings,
  setImageSettings,
  setVisibleDataTypes,
} = appSlice.actions;

export default appSlice.reducer;

export const getMagneticFieldSettings = (state: RootState) => {
  return state.dataTypeDisplaySettings.magneticField;
};

export const getImageSettings = (state: RootState) => {
  return state.dataTypeDisplaySettings.image;
};

export const getVisibleDataTypes = (state: RootState) => {
  return state.dataTypeDisplaySettings.visibleDataTypes;
};
