import { Header, Message, Table } from "semantic-ui-react";
import { NetworkDetailsMetricType } from "../../../../../../components/menus/settings-view/hooks/useNetworkDetails";
type Props = {
  //  metrics: MetricsType[];
  metrics: NetworkDetailsMetricType;
};

export default function MetricsTable({ metrics }: Props) {
  if (metrics.length === 0) {
    return <Message info content="No metrics exist yet." />;
  }

  return (
    <>
      <Header as="h5">Best Network</Header>
      <Table celled style={{ background: "rgba(0,0,0,0)" }} compact>
        <Table.Body>
          {metrics
            .filter((metric) => metric.show && !metric.label)
            .map((metric) => (
              <Table.Row key={metric.id} columns={3} style={{ height: "10px" }}>
                <Table.Cell collapsing>{metric.name}</Table.Cell>
                {/* <Table.Cell collapsing verticalAlign="middle" width={8}>
                <Progress
                  percent={metric.bestValue * 100}
                  size="tiny"
                  style={{ margin: '0' }}
                />
              </Table.Cell> */}
                <Table.Cell collapsing textAlign="right">
                  {/* <Label horizon1tal> */}
                  {`${Math.round(metric.bestValue * 100)}%`}
                  {/* </Label>{' '} */}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
