import CcCheckerResult from "./CcCheckerResult";
import Image from "./Image";
import Unknown from "./Unkown";
import MagneticFieldScan from "./MagneticFieldScan";
import { DataType } from "../../ts-clients/query";
import { DetailsDatapointParams } from "../../utils/routeManager";
import {
  CcCheckerDatapointType,
  ImageDatapointType,
  MagneticFieldScanType,
} from "../../types/extractedTypes";
import useSourceSelector from "../details-researchbox/Markup/hooks/useSourceSelector";
import { usePipelineId } from "../../hooks/usePipelineId";
import { ResearchBoxContext } from "../../ctx/ResearchBoxProvider";
import { useContext, useEffect } from "react";
import useMarkuptoolList from "../../state/useMarkuptoolList";
// import useInputDatapoint from "../details-researchbox/Markup/hooks/useInputDatapoint";

type Props = {
  pageParams: DetailsDatapointParams;
};

export default function DatapointPageRenderer({ pageParams }: Props) {
  // const dispatch = useDispatch();
  // const {
  //   data: d,
  //   startPolling,
  //   stopPolling,
  // } = useGetDatapointDetailsQuery({
  //   variables: {
  //     id: pageParams.datapointId,
  //   },
  // });

  // useEffect(() => {
  //   startPolling(2000);
  //   return function cleanup() {
  //     stopPolling();
  //   };
  // });

  const { setCurrentListIndex } = useMarkuptoolList();

  useEffect(() => {
    setCurrentListIndex(pageParams.listIndex);
  }, [setCurrentListIndex, pageParams.listIndex]);

  const { researchbox } = useContext(ResearchBoxContext);
  const pipelineId = usePipelineId(pageParams.datasetId);

  const { source } = useSourceSelector(pipelineId);
  // const inputDatapoint = useInputDatapoint(

  //   source.markupModule,
  //   researchbox,
  //   source.inputDatapointNames
  // );

  const inputDatapoint = researchbox?.datapoints?.find(
    (d) => d.id === pageParams.datapointId
  );

  const dataType = inputDatapoint?.producedBy.dataType;

  const networkId = source.markupModule?.id ?? "";
  // const datapoint = (d?.queryDatapoint || [])[0];

  // const dataType = datapoint?.producedBy.dataType;
  // React.useEffect(() => {
  //   const a = typeof dataType !== 'undefined' ? [dataType] : [];
  //   dispatch(setVisibleDataTypes(a));
  // }, [dataType, dispatch]);

  if (dataType === undefined) {
    return <Unknown page={pageParams} />;
  }

  switch (dataType) {
    case DataType.Image:
      return (
        <Image
          datapoint={inputDatapoint as ImageDatapointType}
          networkId={networkId}
          page={pageParams}
        />
      );
    case DataType.CcCheckerResult:
      return (
        <CcCheckerResult
          datapoint={inputDatapoint as CcCheckerDatapointType}
          page={pageParams}
        />
      );
    case DataType.MagneticFieldScan:
      return (
        <MagneticFieldScan
          datapoint={inputDatapoint as MagneticFieldScanType}
          page={pageParams}
        />
      );
    default:
      return <Unknown page={pageParams} />;
  }
}
