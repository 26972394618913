import { Group } from "react-konva";
// import { Annotations2Type } from "../hooks/useAnnotations2";

// type Props = {
//   annotations: Annotations2Type["annotations"]["annotationData"];
//   scale: number;
// };

export default function SegmentationDataIndex() {
  return (
    <Group>
      {/* {annotations.pixelAnnotations.map((c) => (
        <AnnotationTypes.Pixel key={c.id} c={c} select={() => {}} />
      ))}
      {annotations.circleAnnotations.map((c) => (
        <AnnotationTypes.Circle
          key={c.id}
          c={c}
          select={() => {}}
          update={() => {}}
          scale={scale}
        />
      ))}
      {annotations.polygonAnnotations.map((c) => (
        <AnnotationTypes.Polygon
          key={c.id}
          c={c}
          select={() => {}}
          update={() => {}}
          scale={scale}
        />
      ))}
      {annotations.magicwandAnnotations.map((c) => (
        <AnnotationTypes.MagicWand
          key={c.id}
          c={c}
          select={() => {}}
          update={() => {}}
          scale={scale}
        />
      ))}
      {annotations.rectangleAnnotations.map((c) => (
        <AnnotationTypes.Rectangle
          key={c.id}
          c={c}
          select={() => {}}
          update={() => {}}
          scale={scale}
        />
      ))}
      {annotations.sausageAnnotations.map((c) => (
        <AnnotationTypes.Sausage
          key={c.id}
          c={c}
          select={() => {}}
          update={() => {}}
          scale={scale}
        />
      ))} */}
    </Group>
  );
}
