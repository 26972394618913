import { useCallback, useEffect, useState } from "react";
import { AnnotationType } from "../../../../../ts-clients/command";
import { hexToRgbString } from "../../../../../utils/pixeltools";
import useGeneratePattern from "../../../../../wasm/image-tools/hooks/useGeneratePattern";

type MakePixelImageResult = {
  resultImage: HTMLImageElement;
  context: CanvasRenderingContext2D | null;
};

const useGetPixelImage = () => {
  const { loading, generate } = useGeneratePattern();

  const makePixelImage = useCallback(
    async (
      annotationType: AnnotationType,
      width: number,
      height: number,
      isPrediction: boolean,
      color: string,
      dataURL: string
    ): Promise<MakePixelImageResult | undefined> => {
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      if (ctx && generate) {
        let pattern: string | CanvasPattern;
        if (annotationType !== AnnotationType.Positive || isPrediction) {
          const patternImage = await generate(
            isPrediction
              ? "AI"
              : annotationType === AnnotationType.Negative
              ? "Negative"
              : "Ignore",
            color
          );
          pattern =
            ctx.createPattern(patternImage, "repeat") || hexToRgbString(color);
        } else {
          pattern = hexToRgbString(color);
        }

        const annotationImage = new Image();
        const tmpCanvas = document.createElement("canvas");
        const tmpctx = tmpCanvas.getContext("2d");
        annotationImage.width = width;
        annotationImage.height = height;
        await new Promise((f) => {
          annotationImage.onload = () => {
            tmpCanvas.width = annotationImage.width;
            tmpCanvas.height = annotationImage.height;

            if (tmpctx) {
              tmpctx.drawImage(annotationImage, 0, 0, width, height);
              const data = tmpctx.getImageData(
                0,
                0,
                annotationImage.width,
                annotationImage.height
              );

              for (let i = 0; i < data.data.length / 4; ++i) {
                if (data.data[i * 4] === 0) data.data[i * 4 + 3] = 0;
              }

              ctx.putImageData(data, 0, 0);
            }

            f(annotationImage.height);
          };
          annotationImage.src = dataURL;
        });

        ctx.globalCompositeOperation = "source-in";

        ctx.fillStyle = pattern;
        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.scale(0.2, 0.2);
        ctx.fill();
        ctx.scale(5.0, 5.0);

        return new Promise((resolve) => {
          const resultImage = new Image();
          resultImage.onload = () => {
            resolve({ resultImage, context: tmpctx });
          };
          resultImage.src = canvas.toDataURL();
        });
      }
      return undefined;
    },
    [generate]
  );

  return { loading, makePixelImage: !generate ? undefined : makePixelImage };
};

type Props = {
  annotationType: AnnotationType;
  width: number;
  height: number;
  isPrediction: boolean;
  color: string;
  dataURL: string;
};

type StateType = {
  image: HTMLImageElement | undefined;
  renderContext: CanvasRenderingContext2D | undefined | null;
};

export const useGetPixelImageHook = ({
  annotationType,
  width,
  height,
  isPrediction,
  color,
  dataURL,
}: Props) => {
  const [state, setState] = useState<StateType>({
    image: undefined,
    renderContext: undefined,
  });

  const { loading, makePixelImage } = useGetPixelImage();

  useEffect(() => {
    const make = async () => {
      if (makePixelImage) {
        const res = await makePixelImage(
          annotationType,
          width,
          height,
          isPrediction,
          color,
          dataURL
        );

        setState({ image: res?.resultImage, renderContext: res?.context });
      }
    };
    if (!loading && makePixelImage) {
      make();
    }
  }, [
    loading,
    makePixelImage,
    annotationType,
    width,
    height,
    isPrediction,
    color,
    dataURL,
  ]);

  return { ...state };
};

export default useGetPixelImage;
