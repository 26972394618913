import { useDrag, useDrop } from "react-dnd";
import { Icon } from "semantic-ui-react";
import { useUIType } from "../../hooksqueries";
import {
  useMoveFolderMutation,
  useMoveResearchBoxMutation,
} from "../../ts-clients/command";
import {
  FolderType,
  ResearchBoxOrFolderType,
} from "../../types/extractedTypes";

type Props = {
  folder: FolderType;
  onClick: () => void;
};

export default function FolderRowItem({ folder, onClick }: Props) {
  const uiType = useUIType();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "folder",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: folder,
    canDrag: uiType !== "halm",
  }));

  const [moveFolder] = useMoveFolderMutation();
  const [moveResearchBox] = useMoveResearchBoxMutation();

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ["folder", "researchbox"],
    drop: (item: ResearchBoxOrFolderType) => {
      if (item.__typename === "Folder") {
        moveFolder({
          variables: {
            input: {
              folderIds: [item.id],
              target: { folderId: folder?.id || "" },
            },
          },
        });
      } else {
        moveResearchBox({
          variables: {
            input: {
              researchBoxIds: [item.id],
              target: { folderId: folder?.id },
            },
          },
        });
      }
    },
    canDrop: (item) => {
      return item.id !== folder?.id;
    },
    collect: (monitor) => {
      return {
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver() && monitor.canDrop(),
      };
    },
  }));
  return (
    <div ref={drag}>
      <div ref={drop}>
        <Icon
          name="folder"
          size="big"
          link
          onClick={onClick}
          color={isOver ? "violet" : canDrop ? "pink" : undefined}
          style={{
            opacity: isDragging ? 0.5 : 1.0,
          }}
        />
      </div>
    </div>
  );
}
