/* eslint-disable react/jsx-one-expression-per-line */
import { useState } from "react";
import { Card, Header, Divider, Button } from "semantic-ui-react";
import { useSelector } from "react-redux";
import DatasetCard from "./DatasetCard";
import DatasetAddModal from "./DatasetAddModal";
import DatasetCopyModal from "./DatasetCopyModal";
import { Dataset } from "./types";
import DatasetDeleteModal from "./DatasetDeleteModal";
import { canMutateDataset, getCurrentUser } from "../../../state/loginState";
import { useUIType } from "../../../hooksqueries";

const defaultDataset: Dataset = {
  description: "",
  id: "",
  name: "",
  researchBoxCount: 0,
  thumbnail: undefined,
};

type Props = {
  datasets: Dataset[];
  refetch: () => void;
};

export default function DatasetList({ datasets, refetch }: Props) {
  const currentUser = useSelector(getCurrentUser);
  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [copyDataset, setCopyDataset] = useState<Dataset | null>(null);
  const [deleteDataset, setDeleteDataset] = useState<string | null>(null);

  const userCanMutateDataset = useSelector(canMutateDataset);

  const uiType = useUIType();

  return (
    <>
      <Header as="h2">
        My {uiType === "halm" ? "Portfolios" : "Datasets"}
        <Button
          disabled={!userCanMutateDataset}
          icon="plus"
          positive
          floated="right"
          content={`Add ${uiType === "halm" ? "Portfolio" : "Dataset"}`}
          onClick={() => setAddModalVisible(true)}
        />
      </Header>
      <Divider />
      <Card.Group>
        {datasets.map((dataset) => (
          <DatasetCard
            key={dataset.showOnlyTag?.id || dataset.id}
            dataset={dataset}
            // startCopy={() => setCopyDataset(dataset)}
            startDelete={() => setDeleteDataset(dataset.id)}
          />
        ))}
      </Card.Group>
      <DatasetAddModal
        visible={isAddModalVisible}
        hide={() => setAddModalVisible(false)}
        message={
          datasets.length === 0
            ? `Do you want to create your first ${
                uiType === "halm" ? "portfolio" : "dataset"
              } now?`
            : undefined
        }
        onCreate={refetch}
      />
      <DatasetCopyModal
        dataset={copyDataset ?? defaultDataset}
        hide={() => setCopyDataset(null)}
        targetUsername={currentUser.username}
        visible={copyDataset !== null}
      />
      <DatasetDeleteModal
        datasetId={deleteDataset ?? ""}
        hide={() => setDeleteDataset(null)}
        visible={deleteDataset !== null}
      />
    </>
  );
}
