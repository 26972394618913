/* eslint-disable import/no-cycle */
import {
  FileInfo,
  FlatFileInfo,
} from "../features/filemanagement/types/FileManagementTypes";

const flattenFileInfo = (f: FileInfo): FlatFileInfo => ({
  id: f.id,
  datasetId: f.datasetId,
  fileName: f.file.name,
});

export default flattenFileInfo;
