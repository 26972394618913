import { ImageSize, Point } from "MarkupTypes";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AddPoint2DInput,
  AddPixelAnnotationInput,
} from "../../../../ts-clients/command";
import uglyStoreGlobalStore, {
  ImageBounds,
  highDefault,
  lowDefault,
} from "../../../../state/uglyStore";
import { Annotations2Type } from "./useAnnotations2";
import { DrawMode, drawPolygon } from "./utils";

type LeanPixelInput = Omit<AddPixelAnnotationInput, "annotationType"> & {
  markupLabelColor: string;
};

type CropResult = {
  topLeft: AddPoint2DInput;
  bottomRight: AddPoint2DInput;
};

const opacity = 0.8;
const color = "#E5268D";
const colorStringRGBA = `${color}${Math.round(opacity * 255.0)
  .toString(16)
  .toUpperCase()}`;

function cropImageFromCanvasWithKnownBounds(
  ctx: CanvasRenderingContext2D,
  bounds: ImageBounds
): CropResult {
  const { canvas } = ctx;
  const lowX =
    bounds.lowestX === lowDefault || bounds.lowestX < 0
      ? 0
      : Math.floor(bounds.lowestX);
  const lowY =
    bounds.lowestY === lowDefault || bounds.lowestY < 0
      ? 0
      : Math.floor(bounds.lowestY);
  const highX =
    bounds.highestX === highDefault || bounds.highestX > canvas.width
      ? canvas.width
      : Math.ceil(bounds.highestX);
  const highY =
    bounds.highestY === highDefault || bounds.highestY > canvas.height
      ? canvas.height
      : Math.ceil(bounds.highestY);

  const w = highX - lowX + 1;
  const h = highY - lowY + 1;

  const cut = ctx.getImageData(lowX, lowY, w, h);
  canvas.width = w;
  canvas.height = h;
  ctx.putImageData(cut, 0, 0);
  return {
    topLeft: { x: lowX, y: lowY },
    bottomRight: {
      x: highX,
      y: highY,
    },
  };
}

function scaleImage(
  ctx: CanvasRenderingContext2D,
  bounds: CropResult,
  scaleX: number,
  scaleY: number
): CropResult {
  const lowX = bounds.topLeft.x * scaleX;
  const lowY = bounds.topLeft.y * scaleY;
  const highX = bounds.bottomRight.x * scaleX;
  const highY = bounds.bottomRight.y * scaleY;
  ctx.scale(scaleX, scaleY);
  return {
    topLeft: { x: lowX, y: lowY },
    bottomRight: {
      x: highX,
      y: highY,
    },
  };
}

export default function usePixels(
  imageSize: ImageSize,
  annotationActions: Annotations2Type["annotationActions"]
) {
  const [paintAnnotationId, setPaintAnnotationId] = useState<string | null>(
    null
  );

  const [pixelCanvas, setPixelCanvas] = useState<HTMLCanvasElement | undefined>(
    undefined
  );

  const [smallestSize, setSmallestSize] = useState({ x: 0, y: 0 });

  const updateMyCanvas = useCallback(() => {
    if (uglyStoreGlobalStore.canvas === undefined) {
      setPixelCanvas(undefined);
      return;
    }
    const c = document.createElement("canvas");
    c.width = imageSize.width;
    c.height = imageSize.height;
    const ctx = c.getContext("2d");
    if (ctx === null) {
      return;
    }
    ctx.drawImage(uglyStoreGlobalStore.canvas, 0, 0);
    setPixelCanvas(c);
  }, [imageSize.height, imageSize.width]);

  const resetPaintLayer = useCallback(() => {
    uglyStoreGlobalStore.canvas = undefined;
    updateMyCanvas();
  }, [updateMyCanvas]);

  const paintStart = (annotationId: string) =>
    setPaintAnnotationId(annotationId);

  const paintReset = useCallback(() => {
    setPaintAnnotationId(null);
    resetPaintLayer();
  }, [resetPaintLayer]);

  useEffect(() => {
    if (paintAnnotationId === null) {
      return;
    }

    // if the source layer is a polygon
    const polygon = annotationActions.getAnnotationPolygon(paintAnnotationId);
    if (polygon !== null) {
      uglyStoreGlobalStore.canvasBounds = {
        lowestX: lowDefault,
        highestX: highDefault,
        lowestY: lowDefault,
        highestY: highDefault,
      };
      for (let i = 0; i < polygon.points.length; i += 1) {
        if (polygon.points[i].x < uglyStoreGlobalStore.canvasBounds.lowestX) {
          uglyStoreGlobalStore.canvasBounds.lowestX = polygon.points[i].x;
        }
        if (polygon.points[i].x > uglyStoreGlobalStore.canvasBounds.highestX) {
          uglyStoreGlobalStore.canvasBounds.highestX = polygon.points[i].x;
        }
        if (polygon.points[i].y < uglyStoreGlobalStore.canvasBounds.lowestY) {
          uglyStoreGlobalStore.canvasBounds.lowestY = polygon.points[i].y;
        }
        if (polygon.points[i].y > uglyStoreGlobalStore.canvasBounds.highestY) {
          uglyStoreGlobalStore.canvasBounds.highestY = polygon.points[i].y;
        }
      }

      uglyStoreGlobalStore.canvas = document.createElement("canvas");
      uglyStoreGlobalStore.canvas.width = imageSize.width;
      uglyStoreGlobalStore.canvas.height = imageSize.height;
      drawPolygon(
        uglyStoreGlobalStore.canvas,
        polygon.points,
        "draw",
        colorStringRGBA,
        {
          x: 0,
          y: 0,
        },
        false
      );
      updateMyCanvas();
    }

    // if the source layer is already a pixel layer
    const pixels = annotationActions.getAnnotationPixels(paintAnnotationId);
    if (pixels !== null) {
      uglyStoreGlobalStore.canvasBounds.lowestX = pixels.topLeft.x;
      uglyStoreGlobalStore.canvasBounds.highestX = pixels.bottomRight.x;
      uglyStoreGlobalStore.canvasBounds.lowestY = pixels.topLeft.y;
      uglyStoreGlobalStore.canvasBounds.highestY = pixels.bottomRight.y;
      uglyStoreGlobalStore.canvas = document.createElement("canvas");
      uglyStoreGlobalStore.canvas.width = imageSize.width;
      uglyStoreGlobalStore.canvas.height = imageSize.height;
      const context = uglyStoreGlobalStore.canvas.getContext("2d");
      if (context !== null) {
        const img = new Image();
        img.onload = () => {
          const tmpCanvas = document.createElement("canvas");
          const width = pixels.bottomRight.x - pixels.topLeft.x + 1;
          const height = pixels.bottomRight.y - pixels.topLeft.y + 1;
          tmpCanvas.width = width;
          tmpCanvas.height = height;
          const tmpctx = tmpCanvas.getContext("2d");
          if (tmpctx) {
            tmpctx.drawImage(img, 0, 0);
            const data = tmpctx.getImageData(
              0,
              0,
              tmpCanvas.width,
              tmpCanvas.height
            );
            for (let i = 0; i < data.data.length / 4; ++i) {
              if (data.data[i * 4] === 0) data.data[i * 4 + 3] = 0;
            }
            context.putImageData(data, pixels.topLeft.x, pixels.topLeft.y);
          }
          context.globalCompositeOperation = "source-in";
          context.fillStyle = colorStringRGBA;
          context.rect(pixels.topLeft.x, pixels.topLeft.y, width, height);
          context.fill();
          updateMyCanvas();
        };
        img.src = pixels.dataURL;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paintAnnotationId]);

  const paintApplyPolygon = useCallback(
    (points: Point[], mode: DrawMode) => {
      let colorToUse = "#000000FF";
      if (uglyStoreGlobalStore.canvas) {
        if (mode !== "erase") {
          colorToUse = colorStringRGBA;
          for (let i = 0; i < points.length; i += 1) {
            if (points[i].x < uglyStoreGlobalStore.canvasBounds.lowestX) {
              uglyStoreGlobalStore.canvasBounds.lowestX = points[i].x;
            }
            if (points[i].x > uglyStoreGlobalStore.canvasBounds.highestX) {
              uglyStoreGlobalStore.canvasBounds.highestX = points[i].x;
            }
            if (points[i].y < uglyStoreGlobalStore.canvasBounds.lowestY) {
              uglyStoreGlobalStore.canvasBounds.lowestY = points[i].y;
            }
            if (points[i].y > uglyStoreGlobalStore.canvasBounds.highestY) {
              uglyStoreGlobalStore.canvasBounds.highestY = points[i].y;
            }
          }
        }

        const context = uglyStoreGlobalStore.canvas.getContext("2d");
        if (context !== null) {
          drawPolygon(
            uglyStoreGlobalStore.canvas,
            points,
            mode,
            colorToUse,
            {
              x: 0,
              y: 0,
            },
            false
          );
          updateMyCanvas();
        }
      }
    },
    [updateMyCanvas]
  );

  type PaintImageCroppedResult = {
    fullAnnotation: LeanPixelInput | null;
    smallestAnnotation: LeanPixelInput | null;
  };

  const getPaintImageCropped = useCallback(
    (
      markupLabelId: string,
      markupLabelColor: string
    ): PaintImageCroppedResult => {
      if (uglyStoreGlobalStore.canvas) {
        const context = uglyStoreGlobalStore.canvas.getContext("2d");
        if (context !== null) {
          const scalingFactorX =
            smallestSize.x / uglyStoreGlobalStore.canvas.width;
          const scalingFactorY =
            smallestSize.y / uglyStoreGlobalStore.canvas.height;

          // canvas is changed in place to contain the cropped results
          const cropResult = cropImageFromCanvasWithKnownBounds(
            context,
            uglyStoreGlobalStore.canvasBounds
          );
          const dataURL = uglyStoreGlobalStore.canvas.toDataURL();
          // canvasToDataURLBinary(uglyStoreGlobalStore.canvas) || "";

          const fullAnnotation = {
            ...cropResult,
            markupLabelId,
            markupLabelColor,
            dataURL,
          };

          const scaleResult = scaleImage(
            context,
            cropResult,
            scalingFactorX,
            scalingFactorY
          );
          const smallestDataURL = uglyStoreGlobalStore.canvas.toDataURL();
          // canvasToDataURLBinary(uglyStoreGlobalStore.canvas) || "";

          const smallestAnnotation = {
            ...scaleResult,
            markupLabelId,
            markupLabelColor,
            dataURL: smallestDataURL,
          };

          return { fullAnnotation, smallestAnnotation };
        }
      }
      return { fullAnnotation: null, smallestAnnotation: null };
    },
    [smallestSize.x, smallestSize.y]
  );

  const pixelActions = useMemo(
    () => ({
      paintStart,
      paintReset,
      paintApplyPolygon,
      getPaintImageCropped,
    }),
    [getPaintImageCropped, paintApplyPolygon, paintReset]
  );

  return {
    pixelActions,
    paintActive: paintAnnotationId,
    pixelCanvas,
    setSmallestSize,
  };
}

type PixelsType = ReturnType<typeof usePixels>;
export type PixelActions = PixelsType["pixelActions"];
