import { useSelector } from "react-redux";
import {
  getImageSettings,
  resolutionOptions,
} from "../state/dataTypeDisplaySettings";
import { ImageDatapointType } from "../types/extractedTypes";

type Props = {
  image: ImageDatapointType;
};

const useGetSelectedImageResolution = ({ image }: Props) => {
  const { selectedResolutionOption } = useSelector(getImageSettings);

  const sortedResolutions = [...image.resolutions].sort(
    (a, b) => a.height * a.width - b.height * b.width
  );

  const options = resolutionOptions.slice(
    resolutionOptions.length - sortedResolutions.length
  );

  const selectedResolutionIndex = options.indexOf(selectedResolutionOption);

  const selectedResolution =
    sortedResolutions[
      selectedResolutionIndex >= 0 ? selectedResolutionIndex : 0
    ];

  return { selectedResolution };
};

export default useGetSelectedImageResolution;
