import { Label, Popup } from "semantic-ui-react";
import { ImageInfo } from "../../hooks/useImageInfo";

type Props = {
  imageInfo: ImageInfo;
  lastPredictionTime: string | null;
  subset: string;
};

export default function ImageStatus({
  imageInfo,
  lastPredictionTime,
  subset,
}: Props) {
  return (
    <Label.Group size="large">
      <Popup
        content={imageInfo.hasMarkup ? "annotated" : "not annotated"}
        trigger={
          <Label
            content={imageInfo.hasMarkup ? "annotated" : "not annotated"}
            style={{ marginTop: 0 }}
          />
        }
      />
      <Popup
        content={
          lastPredictionTime !== null ? "has AI predictions" : "no predictions"
        }
        trigger={
          <Label
            content={
              lastPredictionTime !== null
                ? "has AI predictions"
                : "no predictions"
            }
            style={{ marginTop: 0 }}
          />
        }
      />
      <Popup
        content="subset"
        trigger={<Label content={subset} style={{ marginTop: 0 }} />}
      />
    </Label.Group>
  );
}
