import {
  AnnotationFlavour,
  makeMyBoundingBox,
  makeMyCircle,
  makeMyMagicWand,
  makeMyPen,
  makeMyPixels,
  makeMyPolygon,
  makeMyRectangle,
  makeMySausage,
  MyAnnotations,
  MyBoundingBox,
  MyCircle,
  MyMagicWand,
  MyPen,
  MyPixels,
  MyPolygon,
  MyRectangle,
  MySausage,
  ObjectWithCreated,
} from "./types";

import {
  AddCircleAnnotationInput,
  AddMagicwandAnnotationInput,
  AddPolygonAnnotationInput,
  AddRectangleAnnotationInput,
  AddSausageAnnotationInput,
  AddPixelAnnotationInput,
  AddPenAnnotationInput,
  AnnotationType,
  AddBoundingBoxAnnotationInput,
} from "../../../../ts-clients/command";
import { flatten } from "ramda";

export type IndexFinder = (ms: MyAnnotations, annotationId: string) => number;
export type PositionFinder = (
  annotationId: string,
  annotationData: MyAnnotations
) => number | null;

const getCreatedAt = (
  ms: ObjectWithCreated[],
  annotationId: string
): string | null =>
  ms.reduce((prev, next) => {
    return next.id === annotationId ? next.createdAt : prev;
  }, null as string | null);

const getCircleIndex = (ms: MyAnnotations, annotationId: string) =>
  ms.circleAnnotations.findIndex((c) => c.id === annotationId);

const getPolygonIndex = (ms: MyAnnotations, annotationId: string) =>
  ms.polygonAnnotations.findIndex((c) => c.id === annotationId);

const getPenIndex = (ms: MyAnnotations, annotationId: string) =>
  ms.penAnnotations.findIndex((c) => c.id === annotationId);

const getPixelIndex = (ms: MyAnnotations, annotationId: string) =>
  ms.pixelAnnotations.findIndex((c) => c.id === annotationId);

const getSausageIndex = (ms: MyAnnotations, annotationId: string) =>
  ms.sausageAnnotations.findIndex((c) => c.id === annotationId);

const getMagicWandIndex = (ms: MyAnnotations, annotationId: string) =>
  ms.magicwandAnnotations.findIndex((c) => c.id === annotationId);

const getRectangleIndex = (ms: MyAnnotations, annotationId: string) =>
  ms.rectangleAnnotations.findIndex((c) => c.id === annotationId);

const getBoundingBoxIndex = (ms: MyAnnotations, annotationId: string) =>
  ms.boundingBoxAnnotations.findIndex((c) => c.id === annotationId);

const getOverallIndex = (ms: MyAnnotations, annotationId: string) =>
  [
    getCircleIndex,
    getPolygonIndex,
    getPenIndex,
    getPixelIndex,
    getSausageIndex,
    getMagicWandIndex,
    getRectangleIndex,
  ].reduce((prev, f) => {
    const idx = f(ms, annotationId);
    return idx > -1 ? idx : prev;
  }, -1);

const getCircleFlavour = (
  ms: MyAnnotations,
  annotationId: string
): AnnotationFlavour | null =>
  ms.circleAnnotations.findIndex((c) => c.id === annotationId) > -1
    ? "Circle"
    : null;

const getPolygonFlavour = (
  ms: MyAnnotations,
  annotationId: string
): AnnotationFlavour | null =>
  ms.polygonAnnotations.findIndex((c) => c.id === annotationId) > -1
    ? "Polygon"
    : null;

const getPenFlavour = (
  ms: MyAnnotations,
  annotationId: string
): AnnotationFlavour | null =>
  ms.penAnnotations.findIndex((c) => c.id === annotationId) > -1 ? "Pen" : null;

const getPixelFlavour = (
  ms: MyAnnotations,
  annotationId: string
): AnnotationFlavour | null =>
  ms.pixelAnnotations.findIndex((c) => c.id === annotationId) > -1
    ? "Pixel"
    : null;

const getSausageFlavour = (
  ms: MyAnnotations,
  annotationId: string
): AnnotationFlavour | null =>
  ms.sausageAnnotations.findIndex((c) => c.id === annotationId) > -1
    ? "Sausage"
    : null;

const getMagicWandFlavour = (
  ms: MyAnnotations,
  annotationId: string
): AnnotationFlavour | null =>
  ms.magicwandAnnotations.findIndex((c) => c.id === annotationId) > -1
    ? "MagicWand"
    : null;

const getRectangleFlavour = (
  ms: MyAnnotations,
  annotationId: string
): AnnotationFlavour | null =>
  ms.rectangleAnnotations.findIndex((c) => c.id === annotationId) > -1
    ? "Rectangle"
    : null;

const getBoundingBoxFlavour = (
  ms: MyAnnotations,
  annotationId: string
): AnnotationFlavour | null =>
  ms.boundingBoxAnnotations.findIndex((c) => c.id === annotationId) > -1
    ? "BoundingBox"
    : null;

export const getAnnotationFlavour = (
  ms: MyAnnotations,
  annotationId: string
): AnnotationFlavour | null =>
  [
    getCircleFlavour,
    getPolygonFlavour,
    getPenFlavour,
    getPixelFlavour,
    getSausageFlavour,
    getMagicWandFlavour,
    getRectangleFlavour,
    getBoundingBoxFlavour,
  ].reduce((prev, f) => {
    const flavour = f(ms, annotationId);
    return flavour !== null ? flavour : prev;
  }, null as AnnotationFlavour | null);

export const getPositionOverall = (
  annotationId: string,
  annotationData: MyAnnotations
) => getOverallIndex(annotationData, annotationId);

export const getCirclePosition = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getCircleIndex(annotationData, annotationId);
  return pos === -1 ? null : pos;
};

export const getPolygonPosition = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getPolygonIndex(annotationData, annotationId);
  return pos === -1 ? null : pos;
};

export const getPenPosition = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getPenIndex(annotationData, annotationId);
  return pos === -1 ? null : pos;
};

export const getPixelPosition = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getPixelIndex(annotationData, annotationId);
  return pos === -1 ? null : pos;
};

export const getSausagePosition = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getSausageIndex(annotationData, annotationId);
  return pos === -1 ? null : pos;
};

export const getMagicWandPosition = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getMagicWandIndex(annotationData, annotationId);
  return pos === -1 ? null : pos;
};

export const getRectanglePosition = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getRectangleIndex(annotationData, annotationId);
  return pos === -1 ? null : pos;
};

export const getBoundingBoxPosition = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getBoundingBoxIndex(annotationData, annotationId);
  return pos === -1 ? null : pos;
};

export const getCircle = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getCirclePosition(annotationId, annotationData);
  return pos === null ? null : annotationData?.circleAnnotations[pos] ?? null;
};

export const getRectangle = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getRectanglePosition(annotationId, annotationData);
  return pos === null
    ? null
    : annotationData?.rectangleAnnotations[pos] ?? null;
};
export const getBoundingBox = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getBoundingBoxPosition(annotationId, annotationData);
  return pos === null
    ? null
    : annotationData?.boundingBoxAnnotations[pos] ?? null;
};
export const getPixels = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getPixelPosition(annotationId, annotationData);
  return pos === null ? null : annotationData?.pixelAnnotations[pos] ?? null;
};

export const getMagicWand = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getMagicWandPosition(annotationId, annotationData);
  return pos === null
    ? null
    : annotationData?.magicwandAnnotations[pos] ?? null;
};

export const getPolygon = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getPolygonPosition(annotationId, annotationData);
  return pos === null ? null : annotationData?.polygonAnnotations[pos] ?? null;
};

export const getPen = (annotationId: string, annotationData: MyAnnotations) => {
  const pos = getPenPosition(annotationId, annotationData);
  return pos === null ? null : annotationData?.penAnnotations[pos] ?? null;
};

export const getSausage = (
  annotationId: string,
  annotationData: MyAnnotations
) => {
  const pos = getSausagePosition(annotationId, annotationData);
  return pos === null ? null : annotationData?.sausageAnnotations[pos] ?? null;
};

export const replaceCircle =
  (i: MyCircle) =>
  (idx: number, annotationData: MyAnnotations): MyAnnotations => {
    const { circleAnnotations } = annotationData;
    const copy = [...circleAnnotations];
    copy.splice(idx, 1, i);
    return {
      ...annotationData,
      circleAnnotations: copy,
    };
  };

export const replacePolygon =
  (i: MyPolygon) =>
  (idx: number, annotationData: MyAnnotations): MyAnnotations => {
    const { polygonAnnotations } = annotationData;
    const copy = [...polygonAnnotations];
    copy.splice(idx, 1, i);
    return {
      ...annotationData,
      polygonAnnotations: copy,
    };
  };

export const replacePen =
  (i: MyPen) =>
  (idx: number, annotationData: MyAnnotations): MyAnnotations => {
    const { penAnnotations } = annotationData;
    const copy = [...penAnnotations];
    copy.splice(idx, 1, i);
    return {
      ...annotationData,
      penAnnotations: copy,
    };
  };

export const replacePixels =
  (i: MyPixels) =>
  (idx: number, annotationData: MyAnnotations): MyAnnotations => {
    const { pixelAnnotations } = annotationData;
    const copy = [...pixelAnnotations];
    copy.splice(idx, 1, i);
    return {
      ...annotationData,
      pixelAnnotations: copy,
    };
  };

export const replaceRectangle =
  (i: MyRectangle) =>
  (idx: number, annotationData: MyAnnotations): MyAnnotations => {
    const { rectangleAnnotations } = annotationData;
    const copy = [...rectangleAnnotations];
    copy.splice(idx, 1, i);
    return {
      ...annotationData,
      rectangleAnnotations: copy,
    };
  };

export const replaceBoundingBox =
  (i: MyBoundingBox) =>
  (idx: number, annotationData: MyAnnotations): MyAnnotations => {
    const { boundingBoxAnnotations } = annotationData;
    const copy = [...boundingBoxAnnotations];
    copy.splice(idx, 1, i);
    return {
      ...annotationData,
      boundingBoxAnnotations: copy,
    };
  };

export const replaceSausage =
  (i: MySausage) =>
  (idx: number, annotationData: MyAnnotations): MyAnnotations => {
    const { sausageAnnotations } = annotationData;
    const copy = [...sausageAnnotations];
    copy.splice(idx, 1, i);
    return {
      ...annotationData,
      sausageAnnotations: copy,
    };
  };

export const replaceMagicWand =
  (i: MyMagicWand) =>
  (idx: number, annotationData: MyAnnotations): MyAnnotations => {
    const { magicwandAnnotations } = annotationData;
    const copy = [...magicwandAnnotations];
    copy.splice(idx, 1, i);
    return {
      ...annotationData,
      magicwandAnnotations: copy,
    };
  };

export const annotationAdd = (
  annotationData: MyAnnotations,
  updateFkt: (annotationData: MyAnnotations) => MyAnnotations
) => {
  return updateFkt(annotationData);
};

export const circleAdd = (
  c: AddCircleAnnotationInput,
  annotationData: MyAnnotations,
  markupLabelId: string,
  markupLabelColor: string
) => {
  // @ts-ignore
  const newcircle = makeMyCircle(c, markupLabelId, markupLabelColor);

  const res = annotationAdd(
    annotationData,
    (ad: MyAnnotations): MyAnnotations => ({
      ...ad,
      circleAnnotations: [...ad.circleAnnotations, newcircle],
    })
  );

  return res;
};

export const magicWandAdd = (
  c: AddMagicwandAnnotationInput,
  annotationData: MyAnnotations,
  markupLabelId: string,
  markupLabelColor: string
) =>
  annotationAdd(
    annotationData,
    (ad: MyAnnotations): MyAnnotations => ({
      ...ad,
      magicwandAnnotations: [
        ...ad.magicwandAnnotations,
        // @ts-ignore
        makeMyMagicWand(c, markupLabelId, markupLabelColor),
      ],
    })
  );

export const sausageAdd = (
  c: AddSausageAnnotationInput,
  annotationData: MyAnnotations,
  markupLabelId: string,
  markupLabelColor: string
) =>
  annotationAdd(
    annotationData,
    (ad: MyAnnotations): MyAnnotations => ({
      ...ad,
      sausageAnnotations: [
        ...ad.sausageAnnotations,
        // @ts-ignore
        makeMySausage(c, markupLabelId, markupLabelColor),
      ],
    })
  );

export const rectangleAdd = (
  c: AddRectangleAnnotationInput,
  annotationData: MyAnnotations,
  markupLabelId: string,
  markupLabelColor: string
) =>
  annotationAdd(
    annotationData,
    (ad: MyAnnotations): MyAnnotations => ({
      ...ad,
      rectangleAnnotations: [
        ...ad.rectangleAnnotations,
        // @ts-ignore
        makeMyRectangle(c, markupLabelId, markupLabelColor),
      ],
    })
  );

export const boundingBoxAdd = (
  c: AddBoundingBoxAnnotationInput,
  annotationData: MyAnnotations,
  markupLabelId: string,
  markupLabelColor: string
) =>
  annotationAdd(
    annotationData,
    (ad: MyAnnotations): MyAnnotations => ({
      ...ad,
      boundingBoxAnnotations: [
        ...ad.boundingBoxAnnotations,
        // @ts-ignore
        makeMyBoundingBox(c, markupLabelId, markupLabelColor),
      ],
    })
  );

export const pixelsAdd = (
  c: AddPixelAnnotationInput,
  annotationData: MyAnnotations,
  markupLabelId: string,
  markupLabelColor: string
) => {
  const ret = {
    ...annotationData,
    pixelAnnotations: [
      ...annotationData.pixelAnnotations,
      // @ts-ignore
      makeMyPixels(c, markupLabelId, markupLabelColor),
    ],
  };
  return ret;
};

export const polygonAdd = (
  c: AddPolygonAnnotationInput,
  annotationData: MyAnnotations,
  markupLabelId: string,
  markupLabelColor: string
) =>
  annotationAdd(
    annotationData,
    (ad: MyAnnotations): MyAnnotations => ({
      ...ad,
      polygonAnnotations: [
        ...ad.polygonAnnotations,
        // @ts-ignore
        makeMyPolygon(c, markupLabelId, markupLabelColor),
      ],
    })
  );

export const penAdd = (
  c: AddPenAnnotationInput,
  annotationData: MyAnnotations,
  markupLabelId: string,
  markupLabelColor: string
) =>
  annotationAdd(
    annotationData,
    (ad: MyAnnotations): MyAnnotations => ({
      ...ad,
      // @ts-ignore
      penAnnotations: [
        ...ad.penAnnotations,
        // @ts-ignore
        makeMyPen(c, markupLabelId, markupLabelColor),
      ],
    })
  );

const differsTo = (id2: string) => (t: { id: string }) => t.id !== id2;

const delFromAnnotations = (
  annotationData: MyAnnotations,
  myAnnotationId: string,
  butKeepPixelsEvenIfIDMatches: boolean
): MyAnnotations => {
  return {
    ...annotationData,

    circleAnnotations: annotationData.circleAnnotations.filter(
      differsTo(myAnnotationId)
    ),

    magicwandAnnotations: annotationData.magicwandAnnotations.filter(
      differsTo(myAnnotationId)
    ),

    pixelAnnotations: butKeepPixelsEvenIfIDMatches
      ? annotationData.pixelAnnotations
      : annotationData.pixelAnnotations.filter(differsTo(myAnnotationId)),

    polygonAnnotations: annotationData.polygonAnnotations.filter(
      differsTo(myAnnotationId)
    ),

    rectangleAnnotations: annotationData.rectangleAnnotations.filter(
      differsTo(myAnnotationId)
    ),

    boundingBoxAnnotations: annotationData.boundingBoxAnnotations.filter(
      differsTo(myAnnotationId)
    ),

    sausageAnnotations: annotationData.sausageAnnotations.filter(
      differsTo(myAnnotationId)
    ),

    penAnnotations: annotationData.penAnnotations.filter(
      differsTo(myAnnotationId)
    ),
  };
};

const delAllFromAnnotations = (
  annotationData: MyAnnotations,
  myAnnotationIds: string[],
  butKeepPixelsEvenIfIDMatches: boolean
): MyAnnotations => {
  return {
    ...annotationData,

    circleAnnotations: annotationData.circleAnnotations.filter(
      (c) => !myAnnotationIds.includes(c.id)
    ),

    magicwandAnnotations: annotationData.magicwandAnnotations.filter(
      (c) => !myAnnotationIds.includes(c.id)
    ),

    pixelAnnotations: butKeepPixelsEvenIfIDMatches
      ? annotationData.pixelAnnotations
      : annotationData.pixelAnnotations.filter(
          (c) => !myAnnotationIds.includes(c.id)
        ),

    polygonAnnotations: annotationData.polygonAnnotations.filter(
      (c) => !myAnnotationIds.includes(c.id)
    ),

    rectangleAnnotations: annotationData.rectangleAnnotations.filter(
      (c) => !myAnnotationIds.includes(c.id)
    ),

    boundingBoxAnnotations: annotationData.boundingBoxAnnotations.filter(
      (c) => !myAnnotationIds.includes(c.id)
    ),

    sausageAnnotations: annotationData.sausageAnnotations.filter(
      (c) => !myAnnotationIds.includes(c.id)
    ),

    penAnnotations: annotationData.penAnnotations.filter(
      (c) => !myAnnotationIds.includes(c.id)
    ),
  };
};

export const findAndDelete = (
  annotationData: MyAnnotations,
  annotationId: string,
  butKeepPixelsEvenIfIDMatches: boolean
) =>
  delFromAnnotations(
    annotationData,
    annotationId,
    butKeepPixelsEvenIfIDMatches
  );

export const findAndDeleteAll = (
  annotationData: MyAnnotations,
  annotationIds: string[],
  butKeepPixelsEvenIfIDMatches: boolean
) =>
  delAllFromAnnotations(
    annotationData,
    annotationIds,
    butKeepPixelsEvenIfIDMatches
  );

export const findAndReplace = (
  annotationData: MyAnnotations,
  annotationId: string,
  positionFinderFkt: PositionFinder,
  replaceFkt: (index: number, annotationData: MyAnnotations) => MyAnnotations
) => {
  // in which layer/index is the thing that should be replaced?
  const pos = positionFinderFkt(annotationId, annotationData);

  if (pos === null) {
    return annotationData;
  }

  return replaceFkt(pos, annotationData);
};

export const replaceMyAnnotationsNew = (
  annotationData: MyAnnotations,
  markupLabelId: string,
  addFunction: (annotationData: MyAnnotations) => MyAnnotations
) => addFunction(findAndDelete(annotationData, markupLabelId, false));

const replaceAnnotationTypeSingle = (
  d: MyAnnotations,
  annotationType: AnnotationType,
  myAnnotationId: string
): MyAnnotations => ({
  ...d,
  circleAnnotations: d.circleAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
  magicwandAnnotations: d.magicwandAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
  pixelAnnotations: d.pixelAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
  polygonAnnotations: d.polygonAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
  rectangleAnnotations: d.rectangleAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
  sausageAnnotations: d.sausageAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
  penAnnotations: d.penAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
});

const replaceAnnotationMarkupLabelSingle = (
  d: MyAnnotations,
  markupLabelId: string,
  markupLabelColor: string,
  myAnnotationId: string
) => ({
  ...d,
  circleAnnotations: d.circleAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, markupLabelId, markupLabelColor }
  ),
  magicwandAnnotations: d.magicwandAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, markupLabelId, markupLabelColor }
  ),
  pixelAnnotations: d.pixelAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, markupLabelId, markupLabelColor }
  ),
  polygonAnnotations: d.polygonAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, markupLabelId, markupLabelColor }
  ),
  rectangleAnnotations: d.rectangleAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, markupLabelId, markupLabelColor }
  ),
  sausageAnnotations: d.sausageAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, markupLabelId, markupLabelColor }
  ),
  boundingBoxAnnotations: d.boundingBoxAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, markupLabelId, markupLabelColor }
  ),
});

export const findMarkupLabelIdForAnnotationId = (
  d: MyAnnotations,
  annotationId: string
) => {
  let labelId = undefined;

  labelId = d.boundingBoxAnnotations.find(
    (b) => b.id === annotationId
  )?.markupLabelId;
  if (labelId !== undefined) {
    return labelId;
  }

  labelId = d.circleAnnotations.find(
    (b) => b.id === annotationId
  )?.markupLabelId;
  if (labelId !== undefined) {
    return labelId;
  }

  labelId = d.magicwandAnnotations.find(
    (b) => b.id === annotationId
  )?.markupLabelId;
  if (labelId !== undefined) {
    return labelId;
  }

  labelId = d.penAnnotations.find((b) => b.id === annotationId)?.markupLabelId;
  if (labelId !== undefined) {
    return labelId;
  }

  labelId = d.pixelAnnotations.find(
    (b) => b.id === annotationId
  )?.markupLabelId;
  if (labelId !== undefined) {
    return labelId;
  }

  labelId = d.polygonAnnotations.find(
    (b) => b.id === annotationId
  )?.markupLabelId;
  if (labelId !== undefined) {
    return labelId;
  }

  labelId = d.rectangleAnnotations.find(
    (b) => b.id === annotationId
  )?.markupLabelId;
  if (labelId !== undefined) {
    return labelId;
  }

  labelId = d.sausageAnnotations.find(
    (b) => b.id === annotationId
  )?.markupLabelId;
  if (labelId !== undefined) {
    return labelId;
  }

  return undefined;
};

export const getCreateTime = (d: MyAnnotations, annotationId: string) => {
  let c: string | null;
  c = getCreatedAt(d.circleAnnotations, annotationId);
  if (c !== null) {
    return c;
  }
  c = getCreatedAt(d.magicwandAnnotations, annotationId);
  if (c !== null) {
    return c;
  }
  c = getCreatedAt(d.penAnnotations, annotationId);
  if (c !== null) {
    return c;
  }
  c = getCreatedAt(d.pixelAnnotations, annotationId);
  if (c !== null) {
    return c;
  }
  c = getCreatedAt(d.polygonAnnotations, annotationId);
  if (c !== null) {
    return c;
  }
  c = getCreatedAt(d.rectangleAnnotations, annotationId);
  if (c !== null) {
    return c;
  }
  c = getCreatedAt(d.sausageAnnotations, annotationId);
  if (c !== null) {
    return c;
  }
  c = getCreatedAt(d.boundingBoxAnnotations, annotationId);
  if (c !== null) {
    return c;
  }
  return null;
};

export const getLastPredictionTime = (d: MyAnnotations) =>
  flatten([
    d.boundingBoxAnnotations.map((d) => (d.isPrediction ? d.createdAt : null)),
    d.circleAnnotations.map((d) => (d.isPrediction ? d.createdAt : null)),
    d.magicwandAnnotations.map((d) => (d.isPrediction ? d.createdAt : null)),
    d.penAnnotations.map((d) => (d.isPrediction ? d.createdAt : null)),
    d.pixelAnnotations.map((d) => (d.isPrediction ? d.createdAt : null)),
    d.polygonAnnotations.map((d) => (d.isPrediction ? d.createdAt : null)),
    d.rectangleAnnotations.map((d) => (d.isPrediction ? d.createdAt : null)),
    d.sausageAnnotations.map((d) => (d.isPrediction ? d.createdAt : null)),
  ]).reduce((prev, cur) => (cur !== null ? cur : prev), null);

export const replaceAnnotationType = (
  annotationData: MyAnnotations,
  annotationId: string,
  annotationType: AnnotationType
) => replaceAnnotationTypeSingle(annotationData, annotationType, annotationId);

export const replaceAnnotationMarkupLabel = (
  annotationData: MyAnnotations,
  markupLabelId: string,
  markupLabelColor: string,
  myAnnotationId: string
) =>
  replaceAnnotationMarkupLabelSingle(
    annotationData,
    markupLabelId,
    markupLabelColor,
    myAnnotationId
  );
