import { useState, useCallback } from "react";
import useInterval from "../../../../../hooks/useInterval";

const brake = (speed: number, decelerate: number) => {
  if (speed > 0) {
    return decelerate > Math.abs(speed) ? 0 : speed - decelerate;
  }
  return decelerate > Math.abs(speed) ? 0 : speed + decelerate;
};

export function useSmoothValue(initValue: number) {
  const [acceleration, setAcceleration] = useState(0);
  const [value, setVal] = useState(initValue);
  const [speed, setSpeed] = useState(0);

  useInterval(() => {
    if (speed !== 0) {
      setVal((v) => (v + speed < 0 ? 0 : v + speed));
      setSpeed((s) => brake(s, acceleration));
    }
    setAcceleration((a) => {
      const a2 = a * 0.95;
      const a3 = a > 0.1 ? a - 0.1 : 0;
      return a - a2 > 0.1 ? a2 : a3;
    });
  }, 50);

  const goDown = (acc: number, spd: number) => {
    setAcceleration((a) => a + 1);
    if (spd > 0) {
      setSpeed(-acc);
    } else {
      setSpeed((s) => s - acc);
    }
  };

  const goUp = (acc: number, spd: number) => {
    setAcceleration((a) => a + 1);
    if (spd < 0) {
      setSpeed(acc);
    } else {
      setSpeed((s) => s + acc);
    }
  };

  const up = () => {
    goUp(acceleration, speed);
  };

  const down = useCallback(() => {
    goDown(acceleration, speed);
  }, [acceleration, speed]);

  return { value, up, down };
}
