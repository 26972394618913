import {
  useStartTrainingMutation,
  useStopTrainingMutation,
} from "../../../../ts-clients/command";

export default function useTraining() {
  const [startTraining] = useStartTrainingMutation();

  const [stopTraining] = useStopTrainingMutation();

  const start = (networkId: string) =>
    startTraining({ variables: { input: { moduleId: networkId } } });

  const stop = (networkId: string) =>
    stopTraining({ variables: { input: { moduleId: networkId } } });

  return {
    start,
    stop,
  };
}
