import { Point, Contours, ImageSize } from "MarkupTypes";
import { unnest, pipe } from "ramda";
import { simplifyContours, traceContours, floodFill } from "./magic-wand";

export function magicWandToPolygon(
  point: Point,
  colorThreshold: number,
  imageData: ImageData,
  originalImageSize: ImageSize
): Point[] {
  if (
    point.x > originalImageSize.width ||
    point.y > originalImageSize.height ||
    point.x < 0 ||
    point.y < 0
  ) {
    return [];
  }

  const wFactor = originalImageSize.width / imageData.width;
  const hFactor = originalImageSize.height / imageData.height;

  const newX = point.x / wFactor;
  const newY = point.y / hFactor;

  const mySimplify = (contours: Array<Contours>) =>
    simplifyContours(contours, 3, 30);

  return unnest(
    pipe(
      traceContours,
      mySimplify
    )(floodFill(imageData, Math.round(newX), Math.round(newY), colorThreshold))
      .filter((contour) => !contour.inner)
      .map((contour) =>
        contour.points.map((p) => ({ x: p.x * wFactor, y: p.y * hFactor }))
      )
  );
}

export default function useMagicWand(
  point: Point,
  colorThreshold: number,
  imageData: ImageData,
  originalImageSize: ImageSize
): Point[] {
  return magicWandToPolygon(
    point,
    colorThreshold,
    imageData,
    originalImageSize
  );
}
