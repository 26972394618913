import { BaseParams } from "ModuleTypes";
import { useEffect, useState } from "react";
import { useUpdatePipelineModuleNetworkImageClassificationMutation } from "../../ts-clients/command";
import { useGetPipelineModuleNetworkImageClassificationQuery } from "../../ts-clients/query";
import { pipelineModuleNetworkImageClassificationDefaults as defaults } from "./defaults";

export default function useUpdatePipelineModuleNetworkImageClassificationUpdate(
  pipelineModuleId: string
) {
  const [moduleData, setModuleData] = useState(defaults());

  const { data } = useGetPipelineModuleNetworkImageClassificationQuery({
    variables: {
      id: pipelineModuleId,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const d = data?.getPipelineModuleNetworkImageClassification;
    const outputs = d?.outputs ?? [];
    const firstOuputName = outputs.length > 0 ? outputs[0].name : "";

    setModuleData({
      moduleDescription: d?.description ?? "",
      moduleName: d?.name ?? "",
      position: { x: d?.layoutPosition.x ?? 0, y: d?.layoutPosition.y ?? 0 },
      networkName: firstOuputName,
      classLabels:
        d?.classLabels.map((l) => ({
          id: l.id,
          name: l.name,
          color: l.color,
        })) ?? [],
      selectedPipelineModuleOutputIDs:
        d?.inputs.map((i) => i.edges.map((e) => e.from.id)).flat(2) ?? [],
      pretrainedNetworkModuleId: null,
    });
  }, [data]);

  const setBaseParams = ({ moduleDescription, moduleName }: BaseParams) => {
    setModuleData((m) => ({ ...m, moduleName, moduleDescription }));
  };

  const [m] = useUpdatePipelineModuleNetworkImageClassificationMutation();

  const update = () => {
    m({
      variables: {
        input: {
          filter: {
            moduleId: pipelineModuleId,
          },
          name: moduleData.moduleName,
          description: moduleData.moduleDescription,
          networkName: moduleData.networkName,
          classLabels: moduleData.classLabels
            .filter((c) => c.name !== "")
            .map((c) => ({
              id: c.id,
              name: c.name,
              color: c.color,
            })),
        },
      },
    });
  };

  return {
    update,
    moduleData,
    setModuleData,
    setBaseParams,
  };
}
