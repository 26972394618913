/* eslint-disable react/jsx-one-expression-per-line */
import { useParams } from "react-router-dom";
import DatasetSettings from "../../features/datasetsettings";
import ResearchBoxListMenu from "./ResearchBoxListMenu";
import { DatasetSettingsParams } from "../../utils/routeManager";
import Center from "../../features/filemanagement/DefaultPositioner";
import MenuMain from "../../components/menus/menu.main";

export default function HomePage() {
  const { datasetId } = useParams<DatasetSettingsParams>();
  return (
    <>
      <MenuMain />
      <ResearchBoxListMenu current="settings" datasetId={datasetId} />
      <Center>
        <DatasetSettings />
      </Center>
    </>
  );
}
