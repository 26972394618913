/* eslint-disable react/jsx-one-expression-per-line */
import { Table } from "semantic-ui-react";
import { GetAllDenkBoxesQuery } from "../../../ts-clients/query";

type WorkerListType = {
  data: GetAllDenkBoxesQuery;
};

export default function WorkerList({ data }: WorkerListType) {
  return (
    <Table inverted striped>
      <Table.Header>
        <Table.Row>
          <Table.Cell>ID</Table.Cell>
          <Table.Cell>DockerId</Table.Cell>
          <Table.Cell>Usage</Table.Cell>
          <Table.Cell>Free Memory</Table.Cell>
        </Table.Row>
      </Table.Header>
      {data &&
        data.queryDenkbox &&
        data.queryDenkbox.map((denkbox) => (
          <Table.Body key={denkbox?.id ?? ""}>
            <Table.Row>
              <Table.Cell>{denkbox?.id ?? ""}</Table.Cell>
              <Table.Cell>{denkbox?.dockerId ?? ""}</Table.Cell>
              <Table.Cell>
                {
                  // @ts-ignore
                  denkbox?.usage ?? 1
                }{" "}
                /
                {
                  // @ts-ignore
                  denkbox?.maxUsage ?? 1
                }
              </Table.Cell>
              <Table.Cell>
                {
                  // @ts-ignore
                  denkbox?.freeMemory ?? 1
                }
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ))}
    </Table>
  );
}
