import { useState } from "react";
import { Button } from "semantic-ui-react";
import DetailsModal from "../../../../../networks3/NewList/NetworkStats/DetailsModal";

export default function NetworkDetails() {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <Button
        style={{ position: "absolute", right: 15, bottom: 60, width: 216 }}
        fluid
        color="blue"
        content="Show Network Details"
        onClick={() => setShowDetails(true)}
      />
      <DetailsModal isOpen={showDetails} close={() => setShowDetails(false)} />
    </>
  );
}
