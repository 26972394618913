/* eslint-disable react/jsx-one-expression-per-line */

export default function Tipps() {
  return (
    <div style={{ minHeight: 770, padding: "15px 0" }}>
      <ul>
        <li>
          In user settings/ui some elements of this page can be custimized.
        </li>
        <li>
          When you have selected a tool and want to zoom, you can press
          <kbd>Ctrl</kbd> and then scoll.
        </li>
      </ul>
    </div>
  );
}
