/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import { useState, useMemo, useEffect } from "react";
import Plot from "react-plotly.js";
import { Card, Icon, Pagination, Dropdown } from "semantic-ui-react";
import { CcCheckerDatapointType } from "../../../types/extractedTypes";
import { CellData, parseCCCheckerResultJson } from "./util";

type Props = {
  d: CcCheckerDatapointType;
};

const renderChart = (cellData: CellData) => {
  return (
    <Plot
      style={{ width: "100%", height: "100%" }}
      data={[
        {
          // main data
          x: cellData.Data.map((_, i) => (i + cellData.FirstIdx) * 2.5),
          y: cellData.Data,
          name: "By",
          type: "scatter",
          mode: "lines",
        },
        {
          // peak data
          x: cellData.Peaks.map((x) => x * 2.5),
          y: cellData.Peaks.map((x) => cellData.Data[x - cellData.FirstIdx]),
          name: "Peaks",
          type: "scatter",
          mode: "markers",
          marker: { color: "red", size: 4 },
        },
      ]}
      layout={{
        margin: {
          t: 10,
          l: 45,
          r: 15,
          b: 35,
        },
        xaxis: { title: "s [mm]" },
        yaxis: { title: "By [\u00b5T]" },
        showlegend: false,
      }}
      config={{
        showLink: false,
        editable: false,
        displayModeBar: false,
        displaylogo: false,
      }}
    />
  );
};

const renderCard = (celldata: CellData, index: string) => (
  <Card
    key={index}
    color={celldata.IsOkay ? "green" : "red"}
    style={{ overflow: "hidden" }}
  >
    <div style={{ width: "100%", height: 290 }}>{renderChart(celldata)}</div>
    <Card.Content>
      <Card.Header>
        #{index}{" "}
        {celldata.IsOkay ? (
          <Icon name="check" color="green" />
        ) : (
          <Icon name="exclamation" color="red" />
        )}
        {celldata.IsOkay ? "good" : "has errors"}
      </Card.Header>
    </Card.Content>
  </Card>
);

export default function DataPointImage({ d }: Props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const ccResults = useMemo(
    () => parseCCCheckerResultJson(d.groups),
    [d.groups]
  );
  const pageCount = Math.ceil(ccResults.length / pageSize);

  useEffect(() => {
    if (currentPage > pageCount) {
      setCurrentPage(pageCount);
    }
    if (currentPage < 1) {
      setCurrentPage(1);
    }
  }, [currentPage, pageCount]);

  const firstIdx = (currentPage - 1) * pageSize;
  const lastIdx = firstIdx + pageSize;
  return (
    <>
      <div
        className="ui center aligned container"
        style={{ margin: "35px 0 35px 0" }}
      >
        <Pagination
          defaultActivePage={currentPage}
          totalPages={pageCount}
          onPageChange={(_, { activePage }) =>
            setCurrentPage(parseInt(activePage as string, 10))
          }
        />{" "}
        <Dropdown
          placeholder="Set Page Size"
          selection
          value={pageSize}
          onChange={(_, { value }) =>
            setPageSize(parseInt(value as string, 10))
          }
          options={[6, 8, 10, 12, 16, 20, 24].map((n) => ({
            key: n,
            text: `${n} items per page`,
            value: n,
          }))}
        />
      </div>
      <Card.Group>
        {ccResults
          .slice(firstIdx, lastIdx)
          .map((p, idx) => renderCard(p, (idx + firstIdx + 1).toString()))}
      </Card.Group>
    </>
  );
}
