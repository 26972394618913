import { useMemo } from "react";
import {
  AddImageObjectDetectionMarkupInput,
  AddPoint2DInput,
  useAddImageObjectDetectionMarkupMutation,
} from "../../../../ts-clients/command";

import { MyAnnotations } from "../hooks/types";
import { polygonToDataURL } from "../hooks/utils";

type Props = {
  imageId: string;
  networkId: string;
  imageWidth: number;
  imageHeight: number;
  markupWidth: number;
  markupHeight: number;
};

const r = (n: number): number => Math.round(n);

const rp = (p: AddPoint2DInput): AddPoint2DInput => ({
  x: r(p.x),
  y: r(p.y),
});

const resize_number = (n: number, original_max_size: number, new_max_size: number): number => (n/original_max_size)*new_max_size

const resize_point = (p: AddPoint2DInput, original_max_size_x: number, new_max_size_x: number, original_max_size_y: number, new_max_size_y: number): AddPoint2DInput => ({
  x: resize_number(p.x, original_max_size_x, new_max_size_x),
  y: resize_number(p.y, original_max_size_y, new_max_size_y),
});

export function prepareForBackend(
  s: MyAnnotations,
  imageIds: string[],
  networkId: string,
  imageWidth: number,
  imageHeight: number,
  markupWidth: number,
  markupHeight: number
): AddImageObjectDetectionMarkupInput {
  return {
    imageIds,
    networkId,
    imageHeight,
    imageWidth,
    markupHeight,
    markupWidth,
    annotations: s.boundingBoxAnnotations.map((c) => {
      const { dataURL } = polygonToDataURL([
        resize_point(c.topLeft,imageWidth,markupWidth,imageHeight,markupHeight),
        { x: resize_number(c.bottomRight.x,imageWidth,markupWidth),y: resize_number(c.topLeft.y,imageHeight,markupHeight) },
        resize_point(c.bottomRight,imageWidth,markupWidth,imageHeight,markupHeight),
        { x: resize_number(c.topLeft.x,imageWidth,markupWidth), y: resize_number(c.bottomRight.y,imageHeight,markupHeight) },
      ]);
      return {
        annotationType: c.annotationType,
        topLeft: rp(resize_point(c.topLeft,imageWidth,markupWidth,imageHeight,markupHeight)),
        bottomRight: rp(resize_point(c.bottomRight,imageWidth,markupWidth,imageHeight,markupHeight)),
        markupLabelId: c.markupLabelId,
        dataURL,
      };
    }),
  };
}

export default function useSaveObjectDetection(props: Props) {
  const [m] = useAddImageObjectDetectionMarkupMutation();
  return useMemo(
    () => async (annotations: MyAnnotations) => {
      m({
        variables: {
          input: {
            ...prepareForBackend(
              annotations,
              [props.imageId],
              props.networkId,
              props.imageWidth,
              props.imageHeight,
              props.markupWidth,
              props.markupHeight
            ),
          },
        },
      });
    },
    [
      m,
      props.imageHeight,
      props.imageId,
      props.imageWidth,
      props.networkId,
      props.markupHeight,
      props.markupWidth,
    ]
  );
}
