/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect } from "react";
import { Dropdown, Grid, Button, Table } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import {
  getDevicePath,
  setDevicePath,
  getSensorId,
  setSensorId,
  isConnected,
} from "../../state/deviceControl";
import {
  useBTechCalibrateMutation,
  useBTechDevicesQuery,
  useBTechSubscribeSensorStateSubscription,
} from "../../ts-clients/btech";

export default function SensorSelect() {
  const currentDevicePath = useSelector(getDevicePath);
  const currentSensorId = useSelector(getSensorId);
  const dispatch = useDispatch();

  const { data: deviceState } = useBTechSubscribeSensorStateSubscription({
    variables: {
      device: currentDevicePath || "",
    },
    skip: !currentDevicePath,
    fetchPolicy: "no-cache",
  });

  const [bTechCalibrate, { loading: calibrationLoading }] =
    useBTechCalibrateMutation({
      variables: {
        device: currentDevicePath || "",
      },
    });

  const {
    data: devices,
    loading: devicesLoading,
    refetch,
  } = useBTechDevicesQuery({
    onCompleted: (data) => {
      if (!currentDevicePath && data.bTechDevices.length > 0) {
        dispatch(setDevicePath(data.bTechDevices[0].path as string));
      }
    },
  });

  const currentDevice = devices?.bTechDevices.find(
    (d) => d.path === currentDevicePath
  );

  useEffect(() => {
    if (currentDevice?.sensorId !== currentSensorId) {
      dispatch(setSensorId(currentDevice?.sensorId || null));
    }
  }, [currentDevice?.sensorId, dispatch, currentSensorId]);

  const deviceOptions =
    devices?.bTechDevices.map((d) => ({
      key: d.path,
      text: d.hardwareVersion,
      value: d.path,
    })) || [];

  return (
    <>
      <Grid>
        <Grid.Column width="11">
          {" "}
          <Dropdown
            placeholder="Select Sensor"
            selection
            fluid
            value={currentDevice?.path}
            onChange={(_, d) => {
              dispatch(setDevicePath(d.value as string));
            }}
            options={deviceOptions}
            disabled={devicesLoading || calibrationLoading}
          />
        </Grid.Column>
        <Grid.Column width="5" textAlign="right">
          <Button
            active={!isConnected}
            disabled={devicesLoading || calibrationLoading}
            icon="search"
            content="Discover"
            color="blue"
            fluid
            onClick={() => refetch()}
          />
        </Grid.Column>
      </Grid>
      {currentDevice && (
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Hardware Type</Table.Cell>
              <Table.Cell>
                {currentDevice.hardwareVersion} ({currentDevice.protocolVersion}
                )
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
            <Table.Row>
              <Table.Cell>Sensor ID</Table.Cell>
              <Table.Cell>{currentSensorId}</Table.Cell>
              <Table.Cell />
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last Calibration</Table.Cell>
              <Table.Cell>
                {deviceState?.bTechSubscribeSensorState.calibratedAt || "Never"}
              </Table.Cell>
              <Table.Cell>
                <Button
                  floated="right"
                  icon="balance scale"
                  size="small"
                  content="Calibrate"
                  color="blue"
                  disabled={
                    calibrationLoading ||
                    !deviceState?.bTechSubscribeSensorState.connected
                  }
                  onClick={() =>
                    bTechCalibrate({
                      variables: { device: currentDevicePath || "" },
                    })
                  }
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </>
  );
}
