export const allFlags = [
  { name: "flowDesigner", isActive: true },
  { name: "complexNetworkCreation", isActive: true },
  { name: "manualInputAndOutput", isActive: true },
  { name: "networksEntryInDatasetOverview", isActive: true },
  { name: "filterNetwork", isActive: true },
  { name: "filterClassLabels", isActive: true },
  { name: "filterTags", isActive: true },
  { name: "showTraining", isActive: true },
];

export const noFlags = [
  { name: "flowDesigner", isActive: false },
  { name: "complexNetworkCreation", isActive: false },
  { name: "manualInputAndOutput", isActive: false },
  { name: "networksEntryInDatasetOverview", isActive: false },
  { name: "filterNetwork", isActive: false },
  { name: "filterClassLabels", isActive: false },
  { name: "filterTags", isActive: false },
  { name: "showTraining", isActive: false },
];

export const denkweitFlags = [
  { name: "flowDesigner", isActive: true },
  { name: "complexNetworkCreation", isActive: true },
  { name: "manualInputAndOutput", isActive: true },
  { name: "networksEntryInDatasetOverview", isActive: false },
  { name: "filterNetwork", isActive: true },
  { name: "filterClassLabels", isActive: true },
  { name: "filterTags", isActive: true },
  { name: "showTraining", isActive: true },
];

export const ccCheckerFlags = [
  { name: "flowDesigner", isActive: false },
  { name: "complexNetworkCreation", isActive: false },
  { name: "manualInputAndOutput", isActive: true },
  { name: "networksEntryInDatasetOverview", isActive: false },
  { name: "filterNetwork", isActive: false },
  { name: "filterClassLabels", isActive: false },
  { name: "filterTags", isActive: false },
  { name: "showTraining", isActive: false },
];

export const halmFlags = [
  { name: "flowDesigner", isActive: false },
  { name: "complexNetworkCreation", isActive: false },
  { name: "manualInputAndOutput", isActive: false },
  { name: "networksEntryInDatasetOverview", isActive: true },
  { name: "filterNetwork", isActive: true },
  { name: "filterClassLabels", isActive: true },
  { name: "filterTags", isActive: false },
  { name: "showTraining", isActive: false },
];

// see https://github.com/romaindso/react-feature-flags
/*

e.g.

<Flags authorizedFlags={['adminOnly']}
  renderOn={() => <h1>For admin</h1>}
  renderOff={() => <h1>For customers</h1>}
/>

or

<Flags authorizedFlags={['adminOnly']}>
  <h1>For admin</h1>
</Flags>

or


<Flags
  exactFlags
  authorizedFlags={['flagA', 'flagB']}
  renderOn={() => <h1>visible when flagA AND flagB are active</h1>}
/>

or


<Flags
  exactFlags
  authorizedFlags={['flagA', 'flagB']}
  renderOn={(activeFlags) => <SomeComponent />}
/>
*/
