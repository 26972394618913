import { useDeleteClassificationMarkupMutation } from "../../../../../../ts-clients/command";

export default function useDeleteVectorData() {
  const [m] = useDeleteClassificationMarkupMutation();

  const mutation = (classificationMarkupId: string) => {
    m({
      variables: {
        input: {
          ids: [classificationMarkupId],
        },
      },
    });
  };
  return mutation;
}
