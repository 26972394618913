import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
// import useDeepCompareEffect from "use-deep-compare-effect";
import { AnnotationType } from "../../../../ts-clients/command";
import {
  setAnnotationType,
  setSelectedSegmentation2DTool,
} from "../inputImage/redux-state";
import { MarkupModule, ModuleIO } from "./types";
import useMarkupModules from "./useMarkupModules";

export default function useSourceSelector(pipelineId: string) {
  const [input, setInput] = useState<ModuleIO | null>(null);
  const [output, setOutput] = useState<ModuleIO | null>(null);

  const [markupModule, setMarkupModule] = useState<MarkupModule | null>(null);

  const { markupModules, loading } = useMarkupModules(pipelineId);

  // when the markupmodules are loaded
  // select the first module automatically
  useEffect(() => {
    if (markupModules.length > 0) {
      setMarkupModule(markupModules[0]);
    }
  }, [markupModules]);

  // when a markup module is selected
  useEffect(() => {
    if (markupModule) {
      if (markupModule.inputs.length === 1) {
        setInput(markupModule.inputs[0]);
      }
      if (markupModule.outputs.length === 1) {
        setOutput(markupModule.outputs[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupModule?.id ?? ""]);

  // if the network changes, reset some stuff to defaults
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAnnotationType(AnnotationType.Positive));
    dispatch(setSelectedSegmentation2DTool("None"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupModule?.id]);

  const result = useMemo(
    () => ({
      loading,
      forSelectorDropdown: {
        setMarkupModule,
        setInput,
        markupModules,
        inputs: markupModule?.inputs ?? [],
        selectedInputId: input?.id ?? "",
        selectedMarkupModuleId: markupModule?.id ?? "",
      },
      source: {
        inputDatapointNames: markupModule?.inputDatapointLabels ?? [],
        markupModule,
        input,
        output,
      },
    }),
    [input, markupModule, markupModules, output, loading]
  );

  return result;
}
