import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Confirm, Input, Progress, Form } from "semantic-ui-react";
import { getAnnotationsChanged } from "./Markup/inputImage/redux-state";
import { NavigationProps, Page } from "./utils";

type Props = {
  current: Page;
  navigation: NavigationProps;
};

type NavDirection = "prev" | "next" | "up" | "overview" | null;

const parse = (s: string) => {
  const parsed = parseInt(s, 10);
  const foo = isNaN(parsed) ? 1 : parsed;
  return foo;
};

export default function DatasetMenu({ current, navigation }: Props) {
  const annotationsChanged = useSelector(getAnnotationsChanged);

  const [navIntention, setNavIntention] = useState<NavDirection>(null);

  const [navigationTotal, setNavigationTotal] = useState<number>(1);
  const [jumpToPageValue, setJumpToPageValue] = useState<string>("1");

  const doNav = (n: NavDirection) => {
    switch (n) {
      case "next":
        navigation.goNext();
        break;
      case "prev":
        navigation.goPrev();
        break;
      case "up":
        navigation.goPage("back");
        break;
      case "overview":
        navigation.goPage("overview");
        break;
      default:
        break;
    }
  };

  const tryNext = () => {
    if (!annotationsChanged) {
      doNav("next");
    } else {
      setNavIntention("next");
    }
  };

  const tryPrev = () => {
    if (!annotationsChanged) {
      doNav("prev");
    } else {
      setNavIntention("prev");
    }
  };
  const tryUp = () => {
    if (!annotationsChanged) {
      doNav("up");
    } else {
      setNavIntention("up");
    }
  };

  const tryOverview = () => {
    if (!annotationsChanged) {
      doNav("overview");
    } else {
      setNavIntention("overview");
    }
  };

  const reallyGo = () => {
    doNav(navIntention);
    setNavIntention(null);
  };

  const cancelGo = () => setNavIntention(null);

  useEffect(() => {
    if (navigation.progress.total !== 0) {
      setNavigationTotal(navigation.progress.total);
    }
  }, [navigation.progress.total]);

  useEffect(() => {
    setJumpToPageValue((navigation.progress.position + 1).toString());
  }, [navigation.progress.position]);

  const goToPage = () => navigation.jumpTo(parse(jumpToPageValue));
  const goFirst = () => navigation.jumpTo(0);
  const goLast = () => navigation.jumpTo(navigationTotal);

  const jumpToPageValueIllegal =
    parse(jumpToPageValue) < 1 ||
    parse(jumpToPageValue) > navigation.progress.total;

  return (
    <>
      <Button.Group floated="right" style={{ marginLeft: 15 }}>
        <Button
          icon="left arrow"
          onClick={tryPrev}
          disabled={!navigation.hasPrev}
        />
        <Button
          icon="right arrow"
          onClick={tryNext}
          disabled={!navigation.hasNext}
        />
        <Button onClick={tryUp} icon="arrow up" />
      </Button.Group>

      <Button.Group floated="right" style={{ marginLeft: 15 }}>
        <Button
          icon="angle double left"
          onClick={goFirst}
          disabled={!navigation.hasPrev}
        />
        <Button
          icon="angle double right"
          onClick={goLast}
          disabled={!navigation.hasNext}
        />
      </Button.Group>

      <Form onSubmit={goToPage} style={{ float: "right" }} size="small">
        <Input
          value={jumpToPageValue}
          error={jumpToPageValueIllegal}
          type="number"
          style={{ width: 120, marginLeft: 15 }}
          onChange={(_, { value }) => {
            setJumpToPageValue(value);
          }}
        />
      </Form>

      <Button.Group floated="right">
        <Button
          icon="image"
          active={current === "overview"}
          onClick={tryOverview}
          content="Overview"
        />
        <Button
          onClick={() => navigation.goPage("markup")}
          active={current === "markup"}
          content="Annotation-Tool"
          icon="paint brush"
        />
      </Button.Group>

      <Progress
        total={navigationTotal}
        value={navigation.progress.position + 1}
        style={{
          width: "100%",
          display: "inline-flex",
          margin: 0,
          top: 3,
        }}
        progress="ratio"
      />
      <Confirm
        header="Leave Image?"
        content="If you click 'Leave', all your unsaved changes on this image will be lost."
        cancelButton="Stay"
        confirmButton="Leave"
        open={navIntention != null}
        onCancel={cancelGo}
        onConfirm={reallyGo}
      />
    </>
  );
}
