type Props = {
  children: React.ReactNode;
};

export default function DefaultPositioner({ children }: Props) {
  return (
    <div style={{ margin: "0 15px 0 15px", top: 57, position: "relative" }}>
      {children}
    </div>
  );
}
