/* eslint-disable react/jsx-one-expression-per-line */
import { Menu } from "semantic-ui-react";
import DetailsDatapointPageRenderer from "../../features/details-datapoint";
import ResearchBoxListMenu from "./ResearchBoxListMenu";
import Center from "../../features/filemanagement/DatasetPositioner";
import { useParseRouteParams } from "../../utils/routeManager";
import ViewSettings from "../../components/menus/settings-view/ForDatapoint";
import ModuleSettings from "../../components/menus/settings-module";
import MenuMain from "../../components/menus/menu.main";
import ResearchBoxProvider from "../../ctx/ResearchBoxProvider";

export default function DetailsDatapointContainer() {
  const pageParams = useParseRouteParams("DetailsDatapoint");

  return (
    <>
      <MenuMain />
      <ResearchBoxListMenu current="files" datasetId={pageParams.datasetId} />
      <Center>
        <ResearchBoxProvider>
          <DetailsDatapointPageRenderer pageParams={pageParams} />
        </ResearchBoxProvider>
      </Center>

      <Menu
        fixed="right"
        vertical
        style={{
          padding: "15px",
          marginTop: 40,
          backgroundColor: "#f4f4f4",
          width: "18rem",
        }}
      >
        <ModuleSettings datapointId={pageParams.datapointId} />
        <ViewSettings datapointId={pageParams.datapointId} />
      </Menu>
    </>
  );
}
