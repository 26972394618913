import NoNetworksPlaceholder from "./NoNetworksPlaceholder";
import NoFilterCriteriaPlaceholder from "./NoFilterCriteriaPlaceholder";

type Props = {
  children: JSX.Element;
  empty: boolean;
  emptyFilter: boolean;
  dataSetId: string;
  userCanMutateNetworks: boolean;
};

export default function NiceEmpty({
  dataSetId,
  userCanMutateNetworks,
  children,
  empty,
  emptyFilter,
}: Props) {
  if (empty) {
    return (
      <NoNetworksPlaceholder
        datasetId={dataSetId}
        menuType="foo"
        userCanMutateNetworks={userCanMutateNetworks}
      />
    );
  }
  if (emptyFilter) {
    return <NoFilterCriteriaPlaceholder />;
  }
  return children;
}
