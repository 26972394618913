import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDrag, useDrop } from "react-dnd";

import { Card, Checkbox, Dropdown, Grid, Icon, Input } from "semantic-ui-react";
import {
  FolderType,
  ResearchBoxOrFolderType,
} from "../../types/extractedTypes";
import useFolderModifier from "./hooks/useFolderModifier";
import {
  useMoveFolderMutation,
  useMoveResearchBoxMutation,
  useUpdateFolderMutation,
} from "../../ts-clients/command";
import { useUIType } from "../../hooksqueries";
import { formatTime } from "../../utils/helpers";
import { useDispatch } from "react-redux";
import { setFilename, setPage } from "../../state/researchboxfilter";

export const FolderStatesHalm = ["editable", "locked", "finished"];
export type FolderStateHalm = typeof FolderStatesHalm[number];

type Props = {
  folder?: FolderType | null;
  selected?: boolean | undefined;
  startCopy: () => void;
  startDelete: () => void;
  startMove: () => void;
  onRename: () => void;
  onSelect: (value: boolean) => void;
};

const FolderCard = ({
  folder,
  selected,
  startCopy,
  startDelete,
  onRename,
  onSelect,
}: Props) => {
  const uiType = useUIType();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "folder",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: folder,
    canDrag: uiType !== "halm",
  }));

  const [moveFolder] = useMoveFolderMutation();
  const [moveResearchBox] = useMoveResearchBoxMutation();

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ["folder", "researchbox"],
    drop: (item: ResearchBoxOrFolderType) => {
      if (item.__typename === "Folder") {
        moveFolder({
          variables: {
            input: {
              folderIds: [item.id],
              target: { folderId: folder?.id || "" },
            },
          },
        });
      } else {
        moveResearchBox({
          variables: {
            input: {
              researchBoxIds: [item.id],
              target: { folderId: folder?.id },
            },
          },
        });
      }
    },
    canDrop: (item) => {
      return item.id !== folder?.id;
    },
    collect: (monitor) => {
      return {
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver() && monitor.canDrop(),
      };
    },
  }));

  const created = Date.parse(folder?.createdAt ?? "");

  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  const {
    rename,
    setCurrentFolderName,
    currentFolderName,
    activeModal,
    setActiveModal,
  } = useFolderModifier(folder);

  const hideModal = () => setActiveModal("none");
  const showRename = () => setActiveModal("rename");

  const [cardFocused, setCardFocused] = useState(false);

  const runRename = () => {
    rename();
    hideModal();
    onRename();
  };

  const openFolder = () => {
    dispatch(setFilename(""));
    dispatch(setPage(1));
    history.push({
      pathname: location.pathname,
      search: `?path=${folder?.parentIds
        .split("-")
        .slice(1)
        .concat([folder?.id])
        .join("-")}`,
    });
  };

  const [updateFolder] = useUpdateFolderMutation();

  const thumbnails =
    folder?.objects
      .flatMap((o) => (o.__typename === "ResearchBox" ? o : []))
      .flatMap((rb) =>
        rb.datapoints && rb.datapoints.length > 0 ? rb.datapoints[0] : undefined
      )
      .flatMap((dp) => (dp?.__typename === "Image" ? dp.thumbnail : [])) || [];

  return (
    <Card
      key={folder?.id}
      onMouseEnter={() => setCardFocused(true)}
      onMouseLeave={() => setCardFocused(false)}
    >
      <div
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1.0,
          width: "100%",
          height: 290,
          display: thumbnails.length === 0 ? "flex" : undefined,
          alignItems: thumbnails.length === 0 ? "center" : undefined,
          justifyContent: thumbnails.length === 0 ? "center" : undefined,
        }}
      >
        <div
          style={thumbnails.length > 0 ? { height: "100%" } : undefined}
          ref={drop}
          onClick={openFolder}
        >
          {thumbnails.length > 0 && (
            <Grid
              columns={2}
              celled="internally"
              style={{
                height: thumbnails.length >= 3 ? "100%" : "50%",
                margin: 0,
              }}
            >
              <Grid.Row>
                {thumbnails.slice(0, 2).map((t, idx) => (
                  <Grid.Column
                    key={idx}
                    style={{
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url(${t})`,
                      width: "50%",
                      height: "100%",
                    }}
                  />
                ))}
              </Grid.Row>
              {thumbnails.length >= 3 && (
                <Grid.Row>
                  {thumbnails.slice(2, thumbnails.length).map((t, idx) => (
                    <Grid.Column
                      key={idx}
                      style={{
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${t})`,
                        width: "50%",
                        height: "100%",
                      }}
                    />
                  ))}
                </Grid.Row>
              )}
            </Grid>
          )}
          <Icon
            name="folder"
            size="massive"
            link
            className="centered"
            color={isOver ? "violet" : canDrop ? "pink" : undefined}
            style={{
              opacity: thumbnails.length > 0 ? "30%" : undefined,
              position: "absolute",
              left: "50%",
              width: "150px",
              marginLeft: "-75px",
              top: "50%",
              height: "150px",
              marginTop: "-105px",
            }}
          />
        </div>
      </div>
      <Checkbox
        checked={selected}
        style={{
          visibility: cardFocused || selected ? "visible" : "hidden",
          alignSelf: "flex-end",
          position: "absolute",
          right: "5px",
          top: "5px",
        }}
        onChange={(e, data) => {
          e.stopPropagation();
          onSelect(data.checked || false);
        }}
      />
      <Card.Content>
        <Card.Header>
          {activeModal === "rename" ? (
            <div>
              <Input
                value={currentFolderName}
                onChange={(_, { value }) => setCurrentFolderName(value)}
              />
              <Icon link name="check" onClick={runRename} />
              <Icon link name="delete" onClick={hideModal} />
            </div>
          ) : (
            <a
              href={undefined}
              style={{ cursor: "pointer" }}
              onClick={openFolder}
            >
              {folder?.name}
            </a>
          )}
        </Card.Header>
        <Card.Meta>
          <span className="date">{formatTime(created / 1000)}</span>
        </Card.Meta>
        <Card.Description>
          {folder?.description === "" ? "no description" : folder?.description}
        </Card.Description>
        <div style={{ position: "absolute", bottom: "15px", right: "15px" }}>
          <Dropdown icon="ellipsis horizontal" labeled floating button>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={showRename}
                icon="edit"
                content={`Rename ${uiType === "halm" ? "Dataset" : "Folder"}`}
              />
              <Dropdown.Item
                onClick={startDelete}
                icon="trash"
                content={`Delete ${uiType === "halm" ? "Dataset" : "Folder"}`}
              />
              <Dropdown.Item
                onClick={startCopy}
                icon="copy"
                content={`Copy ${uiType === "halm" ? "Dataset" : "Folder"}`}
              />
            </Dropdown.Menu>
          </Dropdown>
          {uiType === "halm" && (
            <Icon
              name={folder?.state === "locked" ? "lock" : "lock open"}
              link
              onClick={() => {
                updateFolder({
                  variables: {
                    input: {
                      folderId: folder?.id || "",
                      state: folder?.state === "locked" ? "unlocked" : "locked",
                    },
                  },
                });
              }}
            />
            // <Dropdown icon={folder?.state === "locked" ? "lock" : "open"} labeled floating button>
            //   <Dropdown.Menu>
            //     {FolderStatesHalm.map((state) => (
            //       <Dropdown.Item
            //         key={state}
            //         icon={folder?.state === state ? "checkmark" : "delete"}
            //         content={`Set ${state}`}
            //         value={state}
            //         onClick={() => {
            //           updateFolder({variables: {
            //             input: {
            //               folderId: folder?.id || '',
            //               state,
            //             }
            //           }})
            //         }}
            //       />
            //     ))}
            //   </Dropdown.Menu>
            // </Dropdown>
          )}
        </div>
      </Card.Content>
    </Card>
  );
};

FolderCard.defaultProps = {
  folder: undefined,
  selected: undefined,
};

export default FolderCard;
