import { decode, encode } from "fast-png";
import { Vector2d } from "MarkupTypes";
import { useMemo } from "react";
import { AnnotationType } from "../../../../ts-clients/command";
import { ImageDatapointType } from "../../../../types/extractedTypes";
import {
  base64ToArrayBuffer,
  hexToRgb,
  pngFilterWithColor,
} from "../../../../utils/pixeltools";

type DrawnPixelMap = {
  id: string;
  label: string;
  color: string;
  annotationType: AnnotationType;
  dataURL: string;
  topLeft: Vector2d;
  bottomRight: Vector2d;
  markupLabelId: string;
};

export type Thumbnail = {
  id: string;
  data: Uint8Array;
  width: number;
  height: number;
};

export default function useAnnotationThumbnails(
  datapoint: ImageDatapointType
): Thumbnail[] {
  const markups = useMemo(() => datapoint.markups ?? [], [datapoint.markups]);

  const segmentationMarkups = useMemo(
    () =>
      markups
        //@ts-ignore
        .flatMap((m) => (m.__typename === "SegmentationMarkup" ? m : []))
        //@ts-ignore
        .map((seg) => seg.segmentationMaps),
    [markups]
  );

  const smallest = useMemo(
    () =>
      datapoint.resolutions.length > 0
        ? //@ts-ignore
          datapoint.resolutions.reduce((acc, loc) =>
            acc.height < loc.height ? acc : loc
          )
        : datapoint,
    [datapoint]
  );

  const widthScaling = smallest.width / datapoint.width;
  const heightScaling = smallest.height / datapoint.height;

  const segAnnotations = useMemo(
    () => segmentationMarkups.flat(1) ?? [],
    [segmentationMarkups]
  );

  const pixelMaps: DrawnPixelMap[] = useMemo(
    () =>
      //@ts-ignore
      segAnnotations.map((a) => ({
        id: a.id,
        label: a.label.name,
        color: a.label.color,
        annotationType: AnnotationType.Negative,
        dataURL: a.thumbnail,
        topLeft: {
          x: 0.0,
          y: 0.0,
        },
        bottomRight: {
          x: datapoint.width * (1 / widthScaling),
          y: datapoint.height * (1 / heightScaling),
        },
        markupLabelId: "",
      })),
    [
      datapoint.height,
      datapoint.width,
      heightScaling,
      segAnnotations,
      widthScaling,
    ]
  );

  const opacity = 0.5;

  const result = useMemo(
    () =>
      pixelMaps.map((c) => {
        const base64 = c.dataURL.slice("data:image/png;base64,".length);
        const buffer = base64ToArrayBuffer(base64);
        let decoded = decode(buffer);
        if (decoded.channels > 1) {
          const newData = decoded.data.filter(
            (_, s_idx) => s_idx % decoded.channels === 0
          );
          decoded = { ...decoded, channels: 1, data: newData };
        }

        const newPng = pngFilterWithColor(
          decoded.data,
          hexToRgb(c.color),
          opacity
        );

        return {
          data: encode({
            data: newPng,
            height: decoded.height,
            width: decoded.width,
            channels: 4,
          }),
          width: decoded.width,
          height: decoded.height,
          id: c.id,
        };
      }),
    [pixelMaps]
  );

  return result;
}
