import { useMemo } from "react";
import { ImageSize } from "MarkupTypes";
import useImage from "use-image";
import { Image as KonvaImage } from "react-konva";
import { stringSegmentator } from "../../../../../utils/pixeltools";
import { Thumbnail } from "../../hooks/useAnnotationThumbnails";

type Props = {
  thumbnail: Thumbnail;
  imageSize: ImageSize;
};

export default function PixelScaledWithColor({ thumbnail, imageSize }: Props) {
  const imgData = useMemo(
    () =>
      `data:image/png;base64,${window.btoa(stringSegmentator(thumbnail.data))}`,
    [thumbnail.data]
  );

  const [i] = useImage(imgData, "anonymous");
  return (
    <KonvaImage
      image={i}
      x={0}
      y={0}
      scale={{
        x: imageSize.height / thumbnail.height,
        y: imageSize.width / thumbnail.width,
      }}
    />
  );
}
