import { useState } from "react";
import { Container, Form, Button, Header, Divider } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import roleOptions from "./roles";
import featureOptions from "./uiFeatures";
import { useGetUserByUsernameQuery } from "../../../ts-clients/query";

import {
  useUpdateUserMutation,
  Role,
  UiFeature,
} from "../../../ts-clients/command";

type Params = {
  username: string;
};

type UserInput = {
  email?: string;
  password?: string;
  role?: Role;
  name?: string;
  surname?: string;
  phone?: string;
  allowedFeatures?: UiFeature[];
  blockedFeatures?: UiFeature[];
  trainingQuota?: number;
  inferenceQuota?: number;
};

type PwType = {
  pw1: string;
  pw2: string;
};

const isPwOk = (p: PwType) => p.pw2 === p.pw1 && p.pw1.length > 0;

export default function EditUser() {
  const history = useHistory();
  const { username } = useParams<Params>();

  const [updateUserMutation] = useUpdateUserMutation();

  const [user, setUserData] = useState<UserInput>({});

  useGetUserByUsernameQuery({
    variables: { username },
    onCompleted: (data) => {
      setUserData({
        email: data.getUser?.email,
        role: data.getUser?.role,
        name: data.getUser?.name || undefined,
        surname: data.getUser?.surname || undefined,
        phone: data.getUser?.phone || undefined,
        allowedFeatures: data.getUser?.allowedFeatures,
        blockedFeatures: data.getUser?.blockedFeatures,
        trainingQuota: data.getUser?.trainingQuota,
        inferenceQuota: data.getUser?.inferenceQuota,
      });
    },
    fetchPolicy: "no-cache",
  });

  const [pw, setPw] = useState<PwType>({ pw1: "", pw2: "" });

  const saveAccount = async () => {
    await updateUserMutation({
      variables: {
        input: {
          filter: { username },
          ...user,
        },
      },
    });

    history.goBack();
  };

  return (
    <Container style={{ marginTop: "25px" }}>
      {/* HEADER */}
      <Header as="h1">Edit User</Header>
      <Divider />

      {/* EDIT MEMBER FORM */}
      <Form>
        <Form.Input
          label="Username"
          placeholder="Username"
          value={username}
          disabled
        />

        <Form.Input
          error={!isPwOk(pw)}
          label="Password"
          type="password"
          placeholder="Password"
          value={pw.pw1}
          onChange={(_e, { value }) => {
            setPw({ ...pw, pw1: value });
            setUserData({ ...user, password: value });
          }}
        />

        <Form.Input
          error={!isPwOk(pw)}
          label="Password again"
          type="password"
          placeholder="Password again"
          value={pw.pw2}
          onChange={(_e, { value }) => {
            setPw({ ...pw, pw2: value });
          }}
        />
        <Form.Dropdown
          label="Role"
          placeholder="Select Role"
          fluid
          selection
          options={roleOptions}
          value={user.role}
          onChange={(_, { value }) =>
            setUserData({
              ...user,
              role: value as unknown as Role,
            })
          }
        />

        <Form.Dropdown
          label="Allowed Features"
          placeholder="Select allowed Features (default: *)"
          fluid
          selection
          multiple
          options={featureOptions}
          value={user.allowedFeatures || []}
          onChange={(_e, { value }) =>
            setUserData({
              ...user,
              allowedFeatures: value as unknown as UiFeature[],
            })
          }
        />

        <Form.Dropdown
          label="Blocked Features"
          placeholder="Select blocked Features (default: None)"
          fluid
          selection
          multiple
          options={featureOptions}
          value={user.blockedFeatures || []}
          onChange={(_, { value }) =>
            setUserData({
              ...user,
              blockedFeatures: value as unknown as UiFeature[],
            })
          }
        />
        <Form.Input
          label="E-Mail"
          placeholder="E-Mail"
          type="email"
          value={user.email || ""}
          onChange={(_e, { value }) => setUserData({ ...user, email: value })}
        />
        <Divider />
        <Form.Input
          label="Training Quota"
          placeholder="How many training jobs can this user run?"
          type="number"
          value={user.trainingQuota || 0}
          onChange={(_e, { value }) =>
            setUserData({ ...user, trainingQuota: parseInt(value) })
          }
        />
        <Form.Input
          label="Inference Quota"
          placeholder="How many inference jobs (auto-prediction, test-prediction) can this user run?"
          type="number"
          value={user.inferenceQuota || 0}
          onChange={(_e, { value }) =>
            setUserData({ ...user, inferenceQuota: parseInt(value) })
          }
        />
        <Divider />
        <Form.Input
          label="Name"
          placeholder="Name"
          value={user.name || ""}
          onChange={(_e, { value }) => setUserData({ ...user, name: value })}
        />
        <Form.Input
          label="Surname"
          placeholder="Surname"
          value={user.surname || ""}
          onChange={(_e, { value }) => setUserData({ ...user, surname: value })}
        />
        <Form.Input
          label="Phone"
          placeholder="Phone"
          type="tel"
          value={user.phone || ""}
          onChange={(_e, { value }) => setUserData({ ...user, phone: value })}
        />
        <Button onClick={() => saveAccount()} positive content="submit" />
        <Button
          negative
          onClick={() => {
            history.goBack();
          }}
          content="cancel"
        />
      </Form>
    </Container>
  );
}
