import { Button, Dropdown } from "semantic-ui-react";
import { MarkupLabel } from "MarkupTypes";
import { useSelector } from "react-redux";
import { getSegmentationsVisible } from "../../inputImage/redux-state";
import { canMutateMarkup } from "../../../../../state/loginState";

type Props = {
  labels: MarkupLabel[];
  set: (labelId: string, labelColor: string) => void;
};

export default function MoveDropdown({ labels, set }: Props) {
  const segmentationsVisible = useSelector(getSegmentationsVisible);
  const isMarkupMutable = useSelector(canMutateMarkup);

  return (
    <Dropdown
      inline
      disabled={!segmentationsVisible || !isMarkupMutable}
      direction="left"
      icon="exchange"
      button
      as={Button}
    >
      <Dropdown.Menu>
        {labels.map((i) => (
          <Dropdown.Item
            key={i.id}
            content={`move to ${i.name}`}
            onClick={() => set(i.id, i.color)}
            style={{
              borderLeft: `5px solid ${i.color}`,
            }}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
