import { useDeleteSegmentationMarkupMutation } from "../../../../ts-clients/command";

export default function useDeleteSegmentationMarkup(markupId: string) {
  const [m] = useDeleteSegmentationMarkupMutation({
    variables: {
      input: {
        ids: [markupId],
      },
    },
  });
  return m;
}
