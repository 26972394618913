import { Grid, Card, Divider, Header, Message } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import useQueryNetworkDetailsForDataset from "../../hooks/useQueryNetworkDetailsForDataset";
import { useUIType } from "../../hooksqueries";
import { useGoto } from "../../utils/routeManager";
import ChooseMainNetworkType from "../networks3/AddNetwork/RealAddNetwork/ChooseMainNetworkType";
import useDatasetList from "./DatasetList/hooks/useDatasetList";

import DataSetList from "./DatasetList";
import { getCurrentUser } from "../../state/loginState";
import JoyRide from "react-joyride";
import { getIsActive, setActive, WelcomeTour } from "../../state/tourState";
import { useEffect, useState } from "react";
import { reset } from "../../state/researchboxfilter";
import { getTrainingState } from "../networks3/NewList/TrainingControl/utils";
import TrainingControl from "../networks3/NewList/TrainingControl";

export default function DashboardIndex() {
  const dispatch = useDispatch();

  // reset filterstate
  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  const uiType = useUIType();
  const goTo = useGoto();
  const networkDetails = useQueryNetworkDetailsForDataset()
    .sort((a, b) => {
      const aTrainingStats = getTrainingState(a);
      const bTrainingStats = getTrainingState(b);

      if (aTrainingStats.trainingRunning === bTrainingStats.trainingRunning) {
        if (!aTrainingStats.trainingAvailable) {
          return 1;
        } else {
          return -1;
        }
      } else {
        if (!aTrainingStats.trainingRunning) {
          return 1;
        }
        return -1;
      }
    })
    .slice(0, 4);

  const currentUser = useSelector(getCurrentUser);
  const { result: datasets, refetch } = useDatasetList(currentUser.username);

  const isActive = useSelector(getIsActive);

  const [displayWelcomeMessage, setDisplayWelcomeMessage] = useState(true);

  if (uiType === "halm") {
    return (
      <Card fluid>
        <Card.Content>
          <DataSetList datasets={datasets} refetch={refetch} />
        </Card.Content>
      </Card>
    );
  }

  return (
    <>
      <JoyRide steps={WelcomeTour.dashboard} run={isActive} continuous />
      {displayWelcomeMessage && (
        <Message onDismiss={() => setDisplayWelcomeMessage(false)}>
          <Message.Header>Welcome to DENKweit AI</Message.Header>
          The platform to create powerful AIs in no time. Checkout the
          quickstart section below to get started right away or{" "}
          <a style={{ cursor: "pointer" }} onClick={() => {}}>
            schedule
          </a>{" "}
          a live demo. Click{" "}
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(setActive(true));
            }}
            welcome-tour-dashboard="1"
          >
            here
          </a>{" "}
          to take a tour.
        </Message>
      )}
      <Grid columns={datasets.length === 0 ? "1" : "2"}>
        {datasets.length > 0 && (
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <DataSetList datasets={datasets} refetch={refetch} />
              </Card.Content>
            </Card>
          </Grid.Column>
        )}
        <Grid.Column>
          {networkDetails.length > 0 && (
            <Card fluid>
              <Card.Content>
                <Header as="h2">My Networks</Header>
                <Divider />
                <TrainingControl networks={networkDetails} />
              </Card.Content>
            </Card>
          )}
          <Card fluid>
            <Card.Content welcome-tour-dashboard="2">
              <Header as="h2">Quickstart</Header>
              <Divider />
              <ChooseMainNetworkType
                isReady
                onSelect={(typename) =>
                  goTo("NetworkCreateWizard", { moduleType: typename })
                }
                complexNetworkCreation
                carousel
              />
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    </>
  );
}
