/* eslint-disable react/jsx-wrap-multilines */
import { AnnotationLabel } from "MarkupTypes";
import { Icon, Label, Popup } from "semantic-ui-react";

type Props = {
  l: AnnotationLabel;
};

export default function CircleLabel({ l }: Props) {
  const { value, color, name, type } = l;

  const showNumber =
    type === "SegmentationMarkup" || type === "ObjectDetectionMarkup";

  const checkIcon = value > 0 ? "check" : "minus";
  const labelContent = showNumber ? value : <Icon name={checkIcon} fitted />;

  const popupContentClassification = value > 0 ? `has ${name}` : `no ${name}`;
  const opacity = value > 0 ? 1 : 0.35;

  const label = (
    <Label
      style={{
        color: "#ffffff",
        backgroundColor: color,
        opacity,
        cursor: "default",
      }}
      size="small"
      circular
      content={labelContent}
    />
  );

  const popupContent = showNumber
    ? ` ${name}`
    : ` ${popupContentClassification}`;

  return (
    <Popup
      content={
        <>
          {label}
          {popupContent}
        </>
      }
      position="top center"
      size="small"
      trigger={label}
    />
  );
}
