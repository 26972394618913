import { useMemo } from "react";
import { ImageDatapointType } from "../../../../types/extractedTypes";

export default function useImageSize(i: ImageDatapointType) {
  return useMemo(
    () => ({
      width: i?.width ?? 0,
      height: i?.height ?? 0,
    }),
    [i]
  );
}
