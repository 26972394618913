/* eslint-disable react/jsx-curly-newline */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Message } from "semantic-ui-react";
import { getCurrentUser } from "../../state/loginState";
import { useSetUserProfile, useGetUserProfile, UserProfile } from "./hooks";

const defaultUserData: UserProfile = {
  email: "",
  name: "",
  phone: "",
  surname: "",
};

export default function ChangeProfile(): JSX.Element {
  const [formData, setFormData] = useState<UserProfile>(defaultUserData);
  const currentuser = useSelector(getCurrentUser);
  const username = currentuser?.username ?? "";

  const { save, success } = useSetUserProfile(username, formData);
  const userProfile = useGetUserProfile(username);

  useEffect(() => {
    setFormData(userProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userProfile)]);

  return (
    <Form onSubmit={() => save()}>
      <Message
        content="Your profile has been updated."
        positive
        hidden={!success}
      />
      <Form.Input
        label="E-Mail"
        value={formData.email}
        onChange={(_, { value: email }) =>
          setFormData((s) => ({ ...s, email }))
        }
      />
      <Form.Input
        label="Name"
        value={formData.name}
        onChange={(_, { value: name }) => setFormData((s) => ({ ...s, name }))}
      />
      <Form.Input
        label="Surname"
        value={formData.surname}
        onChange={(_, { value: surname }) =>
          setFormData((s) => ({ ...s, surname }))
        }
      />
      <Form.Input
        label="Phone"
        value={formData.phone}
        onChange={(_, { value: phone }) =>
          setFormData((s) => ({ ...s, phone }))
        }
      />
      <div style={{ textAlign: "right" }}>
        <Form.Button content="save" positive />
      </div>
    </Form>
  );
}
