import { useParams } from "react-router";
import { Header, Divider, Container } from "semantic-ui-react";
import ModuleCreate from "./fneu/ModuleCreate";
import { FlowDesignerAddModuleParams } from "../../utils/routeManager";
import { getNiceModuleName } from "./shared";

type Props = {
  onCancel: () => unknown;
};

export default function ModuleCreateRouteShim({ onCancel }: Props) {
  const { moduleType, datasetId } = useParams<FlowDesignerAddModuleParams>();
  const niceModuleName = getNiceModuleName(moduleType);
  return (
    <Container>
      <Header as="h2">{`Create ${niceModuleName}`}</Header>
      <Divider />
      <ModuleCreate
        moduleType={moduleType}
        onCancel={onCancel}
        datasetId={datasetId}
        from={null}
      />
    </Container>
  );
}
