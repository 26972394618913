import { first } from "lodash";
import { SortConfig } from "NetworkList";
import { useState } from "react";
import { Table, Button, Label } from "semantic-ui-react";
import { Network } from "../../../../types/extractedTypes";
import { useGoto } from "../../../../utils/routeManager";
import { getNiceModuleNameFromString } from "../../../flow-designer/shared";
import SortHeaders from "./SortHeaders";
import useTraining from "./useTraining";
import { getTrainingState } from "./utils";
import Details from "../NetworkStats/DetailsModal";
import DenkboxProvider from "../../../../ctx/DenkboxProvider";

type Props = {
  networks: Network[];
  datasetId?: string;
};

const getLastUpdated = (n: Network) =>
  n.snapshots.reduce((prev, cur) => {
    return cur.updatedAt > prev ? cur.updatedAt : prev;
  }, "");

export default function TrainingControl({ networks, datasetId }: Props) {
  const { start, stop } = useTraining();

  const [networkIdForDetails, setNetworkIdForDetails] = useState<string | null>(
    null
  );

  const [sortConfig, setSortConfig] = useState<SortConfig>({
    column: undefined,
    ascending: true,
  });

  const sortedNetworks = networks.sort((a, b) => {
    switch (sortConfig.column) {
      case "labelCount":
        return a.classLabels.length > b.classLabels.length ? 1 : -1;
      case "lastChange": {
        return getLastUpdated(a) > getLastUpdated(b) ? 1 : -1;
      }
      case "name":
        return a.name.localeCompare(b.name);
      case "networkType":
        return a.__typename.localeCompare(b.__typename);
      case "selectedMetric":
        return 0;
      // return a.metrics.find(m => m.id === "")
      default:
        return 0;
    }
  });
  if (!sortConfig.ascending) {
    sortedNetworks.reverse();
  }

  const goTo = useGoto();

  return (
    <>
      <DenkboxProvider
        datasetId={datasetId}
        networkId={networkIdForDetails || ""}
        pollInterval={5000}
      >
        <Details
          isOpen={!!networkIdForDetails}
          close={() => setNetworkIdForDetails(null)}
        />
      </DenkboxProvider>
      <Table sortable>
        <SortHeaders setSortConfig={setSortConfig} sortConfig={sortConfig} />
        <Table.Body>
          {sortedNetworks.map((s) => {
            const stats = {
              name: s.name,
              type: getNiceModuleNameFromString(s.__typename),
              id: s.id,
              labels: s.classLabels.length,
              validation: s.validationMarkups?.count ?? 0,
              training: s.trainingMarkups?.count ?? 0,
              lastUpdated: getLastUpdated(s),
            };

            const score =
              first(
                first(s.snapshots)
                  ?.metrics.filter((m) => m.isNetworkScore)
                  .map((m) => m.bestValue.toPrecision(2))
              ) || "--";

            const {
              trainingActive,
              trainingRunColor,
              trainingRunState,
              trainingAvailable,
              trainingRunStateLong,
            } = getTrainingState(s);

            return (
              <Table.Row key={s.id}>
                <Table.Cell
                  content={
                    <Label
                      content={trainingRunState}
                      color={trainingRunColor}
                      size="small"
                    />
                  }
                />
                <Table.Cell content={s.name} />
                <Table.Cell content={stats.type} />

                <Table.Cell textAlign="right" content={stats.training} />
                <Table.Cell textAlign="right" content={stats.validation} />
                <Table.Cell textAlign="right" content={stats.labels} />
                <Table.Cell textAlign="right" content={score} />
                <Table.Cell content={stats.lastUpdated} />

                <Table.Cell
                  content={datasetId ? trainingRunStateLong : trainingRunState}
                />
                <Table.Cell textAlign="right">
                  <Button.Group size="tiny">
                    <Button
                      icon="play"
                      color={trainingRunColor}
                      onClick={() => start(s.id)}
                      disabled={trainingActive || !trainingAvailable}
                    />
                    <Button
                      icon="stop"
                      onClick={() => stop(s.id)}
                      disabled={!trainingActive || !trainingAvailable}
                    />
                  </Button.Group>{" "}
                  <Button.Group basic size="tiny">
                    <Button
                      icon="info"
                      onClick={() => setNetworkIdForDetails(s.id)}
                    />
                    {datasetId && (
                      <Button
                        size="tiny"
                        icon="edit"
                        basic
                        onClick={() =>
                          goTo("NetworkEdit", {
                            moduleId: s.id,
                            datasetId,
                            menuType: "foo",
                          })
                        }
                      />
                    )}
                  </Button.Group>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
