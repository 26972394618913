import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
}

export interface AddBoundingBoxAnnotationInput {
  annotationType: AnnotationType;
  markupLabelId: Scalars['String'];
  topLeft: AddPoint2DInput;
  bottomRight: AddPoint2DInput;
  dataURL: Scalars['String'];
}

export interface AddCircleAnnotationInput {
  annotationType: AnnotationType;
  markupLabelId: Scalars['String'];
  center: AddPoint2DInput;
  radius: Scalars['Int'];
}

export interface AddDatasetInput {
  name: Scalars['String'];
  description: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
}

export interface AddDatasetPayload {
  __typename?: 'AddDatasetPayload';
  datasetId: Scalars['String'];
  pipelineId: Scalars['String'];
  imageOutputLabelId: Scalars['String'];
}

export interface AddFolderInput {
  name: Scalars['String'];
  description: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  parent: ParentInput;
  state?: Maybe<Scalars['String']>;
}

export interface AddFolderPayload {
  __typename?: 'AddFolderPayload';
  folderId: Scalars['String'];
  pipelineId: Scalars['String'];
}

export interface AddImageClassificationMarkupInput {
  imageIds: Array<Scalars['String']>;
  networkId: Scalars['String'];
  markupWidth: Scalars['Int'];
  markupHeight: Scalars['Int'];
  annotations: Array<AddScalarAnnotationInput>;
}

export interface AddImageClassificationMarkupPayload {
  __typename?: 'AddImageClassificationMarkupPayload';
  markupId: Scalars['String'];
}

export interface AddImageClassificationPredictionInput {
  imageIds: Array<Scalars['String']>;
  networkId: Scalars['String'];
  predictedClasses: Array<ClassificationPredictionInput>;
}

export interface AddImageClassificationPredictionPayload {
  __typename?: 'AddImageClassificationPredictionPayload';
  predictionId: Scalars['String'];
}

export interface AddImageMetadataFieldInput {
  imageId: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface AddImageMetadataFieldPayload {
  __typename?: 'AddImageMetadataFieldPayload';
  imageMetadataFieldId: Scalars['String'];
}

export interface AddImageObjectDetectionMarkupInput {
  imageIds: Array<Scalars['String']>;
  networkId: Scalars['String'];
  imageWidth: Scalars['Int'];
  imageHeight: Scalars['Int'];
  markupWidth: Scalars['Int'];
  markupHeight: Scalars['Int'];
  annotations: Array<AddBoundingBoxAnnotationInput>;
}

export interface AddImageObjectDetectionMarkupPayload {
  __typename?: 'AddImageObjectDetectionMarkupPayload';
  markupId: Scalars['String'];
}

export interface AddImageObjectDetectionPredictionInput {
  imageIds: Array<Scalars['String']>;
  networkId: Scalars['String'];
  predictions: Array<ObjectDectionPredictionInput>;
}

export interface AddImageObjectDetectionPredictionPayload {
  __typename?: 'AddImageObjectDetectionPredictionPayload';
  predictionId: Scalars['String'];
}

export interface AddImageSegmentationMarkupInput {
  imageIds: Array<Scalars['String']>;
  networkId: Scalars['String'];
  imageWidth: Scalars['Int'];
  imageHeight: Scalars['Int'];
  markupWidth: Scalars['Int'];
  markupHeight: Scalars['Int'];
  circleAnnotations: Array<AddCircleAnnotationInput>;
  polygonAnnotations: Array<AddPolygonAnnotationInput>;
  penAnnotations: Array<AddPenAnnotationInput>;
  rectangleAnnotations: Array<AddRectangleAnnotationInput>;
  sausageAnnotations: Array<AddSausageAnnotationInput>;
  magicwandAnnotations: Array<AddMagicwandAnnotationInput>;
  pixelAnnotations: Array<AddPixelAnnotationInput>;
}

export interface AddImageSegmentationMarkupPayload {
  __typename?: 'AddImageSegmentationMarkupPayload';
  markupId: Scalars['String'];
}

export interface AddImageSegmentationPredictionInput {
  imageIds: Array<Scalars['String']>;
  networkId: Scalars['String'];
  predictedSegmentationMaps: Array<SegmentationMapPredictionInput>;
}

export interface AddImageSegmentationPredictionPayload {
  __typename?: 'AddImageSegmentationPredictionPayload';
  predictionId: Scalars['String'];
}

export interface AddMagicwandAnnotationInput {
  annotationType: AnnotationType;
  markupLabelId: Scalars['String'];
  topLeft: AddPoint2DInput;
  bottomRight: AddPoint2DInput;
  center: AddPoint2DInput;
  threshold: Scalars['Int'];
  dataURL: Scalars['String'];
}

export interface AddPenAnnotationInput {
  annotationType: AnnotationType;
  markupLabelId: Scalars['String'];
  topLeft: AddPoint2DInput;
  bottomRight: AddPoint2DInput;
  thickness: Scalars['Int'];
  points: Array<AddPoint2DInput>;
  dataURL: Scalars['String'];
}

export interface AddPipelineModuleCcCheckerInput {
  pipelineId: Scalars['String'];
  moduleName: Scalars['String'];
  moduleDescription: Scalars['String'];
  position: PipelineModulePosition;
  cccheckerResultName: Scalars['String'];
  busBarCountPerCell: Scalars['Int'];
  maxShift: Scalars['Int'];
  minCorrValue: Scalars['Float'];
  cutOffRate: Scalars['Float'];
  minPeakHeightPercentage: Scalars['Float'];
  minPeakDistance: Scalars['Float'];
  minPeakProminence: Scalars['Float'];
}

export interface AddPipelineModuleCcCheckerPayload {
  __typename?: 'AddPipelineModuleCCCheckerPayload';
  moduleId: Scalars['String'];
}

export interface AddPipelineModuleContrastEnhancementInput {
  pipelineId: Scalars['String'];
  moduleName: Scalars['String'];
  moduleDescription: Scalars['String'];
  position: PipelineModulePosition;
  enhancedImageName: Scalars['String'];
  clipLimit: Scalars['Float'];
  tileSize: Scalars['Int'];
}

export interface AddPipelineModuleContrastEnhancementPayload {
  __typename?: 'AddPipelineModuleContrastEnhancementPayload';
  moduleId: Scalars['String'];
}

export interface AddPipelineModuleEdgeInput {
  pipelineModuleOutputId: Scalars['String'];
  pipelineModuleInputId: Scalars['String'];
}

export interface AddPipelineModuleEdgePayload {
  __typename?: 'AddPipelineModuleEdgePayload';
  edgeId: Scalars['String'];
}

export interface AddPipelineModuleJobRequestCcCheckerInput {
  /** hasgasg */
  pipelineModuleId: Scalars['String'];
  researchBoxIds: Array<Scalars['String']>;
  busBarCountPerCell: Scalars['Int'];
  maxShift: Scalars['Int'];
  minCorrValue: Scalars['Float'];
  cutOffRate: Scalars['Float'];
  minPeakHeightPercentage: Scalars['Float'];
  minPeakDistance: Scalars['Float'];
  minPeakProminence: Scalars['Float'];
}

export interface AddPipelineModuleJobRequestCcCheckerPayload {
  __typename?: 'AddPipelineModuleJobRequestCCCheckerPayload';
  pipelineModuleJobRequestId: Scalars['String'];
}

export interface AddPipelineModuleJobRequestContrastEnhancementInput {
  pipelineModuleId: Scalars['String'];
  researchBoxIds: Array<Scalars['String']>;
  clipLimit: Scalars['Float'];
  tileSize: Scalars['Int'];
}

export interface AddPipelineModuleJobRequestContrastEnhancementPayload {
  __typename?: 'AddPipelineModuleJobRequestContrastEnhancementPayload';
  pipelineModuleJobRequestId: Scalars['String'];
}

export interface AddPipelineModuleNetworkImageClassificationInput {
  datasetId?: Maybe<Scalars['String']>;
  moduleName: Scalars['String'];
  moduleDescription: Scalars['String'];
  position: PipelineModulePosition;
  selectedPipelineModuleOutputIDs?: Maybe<Array<Scalars['String']>>;
  classLabels: Array<MarkupLabelInput>;
  networkName: Scalars['String'];
  pretrainedNetworkModuleId?: Maybe<Scalars['String']>;
}

export interface AddPipelineModuleNetworkImageClassificationPayload {
  __typename?: 'AddPipelineModuleNetworkImageClassificationPayload';
  moduleId: Scalars['String'];
  datasetId: Scalars['String'];
}

export interface AddPipelineModuleNetworkImageObjectDetectionInput {
  datasetId?: Maybe<Scalars['String']>;
  moduleName: Scalars['String'];
  moduleDescription: Scalars['String'];
  position: PipelineModulePosition;
  selectedPipelineModuleOutputIDs?: Maybe<Array<Scalars['String']>>;
  classLabels: Array<MarkupLabelInput>;
  networkName: Scalars['String'];
  pretrainedNetworkModuleId?: Maybe<Scalars['String']>;
}

export interface AddPipelineModuleNetworkImageObjectDetectionPayload {
  __typename?: 'AddPipelineModuleNetworkImageObjectDetectionPayload';
  moduleId: Scalars['String'];
  datasetId: Scalars['String'];
}

export interface AddPipelineModuleNetworkImageSegmentationInput {
  datasetId?: Maybe<Scalars['String']>;
  moduleName: Scalars['String'];
  moduleDescription: Scalars['String'];
  position: PipelineModulePosition;
  selectedPipelineModuleOutputIDs?: Maybe<Array<Scalars['String']>>;
  classLabels: Array<MarkupLabelInput>;
  networkName: Scalars['String'];
  pretrainedNetworkModuleId?: Maybe<Scalars['String']>;
}

export interface AddPipelineModuleNetworkImageSegmentationPayload {
  __typename?: 'AddPipelineModuleNetworkImageSegmentationPayload';
  moduleId: Scalars['String'];
  datasetId: Scalars['String'];
}

export interface AddPixelAnnotationInput {
  annotationType: AnnotationType;
  markupLabelId: Scalars['String'];
  topLeft: AddPoint2DInput;
  bottomRight: AddPoint2DInput;
  dataURL: Scalars['String'];
}

export interface AddPoint2DInput {
  x: Scalars['Int'];
  y: Scalars['Int'];
}

export interface AddPolygonAnnotationInput {
  annotationType: AnnotationType;
  markupLabelId: Scalars['String'];
  points: Array<AddPoint2DInput>;
}

export interface AddPretrainedNetworkInput {
  networkId: Scalars['String'];
  thumbnail: Scalars['Upload'];
}

export interface AddPretrainedNetworkPayload {
  __typename?: 'AddPretrainedNetworkPayload';
  pretrainedNetworkId: Scalars['String'];
}

export interface AddProgressNotificationInput {
  title: Scalars['String'];
  message: Scalars['String'];
  maxProgress: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
}

export interface AddProgressNotificationPayload {
  __typename?: 'AddProgressNotificationPayload';
  notificationId: Scalars['String'];
}

export interface AddRectangleAnnotationInput {
  annotationType: AnnotationType;
  markupLabelId: Scalars['String'];
  topLeft: AddPoint2DInput;
  bottomRight: AddPoint2DInput;
}

export interface AddSausageAnnotationInput {
  annotationType: AnnotationType;
  markupLabelId: Scalars['String'];
  topLeft: AddPoint2DInput;
  bottomRight: AddPoint2DInput;
  points: Array<AddPoint2DInput>;
  radius: Scalars['Int'];
  dataURL: Scalars['String'];
}

export interface AddScalarAnnotationInput {
  markupLabelId: Scalars['String'];
  value: Scalars['Float'];
}

export interface AddUploadModuleOutputInput {
  dataType: DataType;
  label: Scalars['String'];
}

export interface AddUserInput {
  username: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
  role?: Maybe<Role>;
  vendorName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  trainingQuota?: Maybe<Scalars['Int']>;
  inferenceQuota?: Maybe<Scalars['Int']>;
}

export interface AddUserPayload {
  __typename?: 'AddUserPayload';
  username: Scalars['String'];
}

export enum AnnotationType {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
  Ignore = 'IGNORE'
}

export interface BackupPayload {
  __typename?: 'BackupPayload';
  code: Scalars['String'];
  message: Scalars['String'];
  taskId: Scalars['String'];
  notificationId: Scalars['String'];
}

export interface ChangePasswordInput {
  username?: Maybe<Scalars['String']>;
  newPassword: Scalars['String'];
}

export interface ChangePasswordPayload {
  __typename?: 'ChangePasswordPayload';
  success: Scalars['Boolean'];
}

export interface ClassificationPredictionInput {
  probability: Scalars['Float'];
  markupLabelId: Scalars['String'];
}

export interface CopyDatasetInput {
  datasetId: Scalars['String'];
  targetUsername: Scalars['String'];
  targetDatasetName?: Maybe<Scalars['String']>;
  targetDatasetDescription?: Maybe<Scalars['String']>;
}

export interface CopyDatasetPayload {
  __typename?: 'CopyDatasetPayload';
  notificationId: Scalars['String'];
}

export interface CopyFolderInput {
  folderIds: Array<Scalars['String']>;
  target: ParentTargetInput;
}

export interface CopyFolderPayload {
  __typename?: 'CopyFolderPayload';
  notificationId: Scalars['String'];
}

export interface CreateTokenInput {
  username: Scalars['String'];
  role: Role;
  queryAll: Scalars['Boolean'];
}

export interface CreateTokenPayload {
  __typename?: 'CreateTokenPayload';
  token: Scalars['String'];
  allowedFeatures: Array<UiFeature>;
  blockedFeatures: Array<UiFeature>;
}

export enum DataType {
  Image = 'Image',
  MagneticFieldScan = 'MagneticFieldScan',
  SegmentationMarkup = 'SegmentationMarkup',
  ObjectDetectionMarkup = 'ObjectDetectionMarkup',
  ClassificationMarkup = 'ClassificationMarkup',
  Metric = 'Metric',
  ImageSegmentationNetworkSnapshotPytorch = 'ImageSegmentationNetworkSnapshotPytorch',
  ImageClassificationNetworkSnapshotPytorch = 'ImageClassificationNetworkSnapshotPytorch',
  ImageAnomalyDetectionNetworkSnapshotPytorch = 'ImageAnomalyDetectionNetworkSnapshotPytorch',
  ImageSegmentationNetworkSnapshotOnnx = 'ImageSegmentationNetworkSnapshotOnnx',
  ImageClassificationNetworkSnapshotOnnx = 'ImageClassificationNetworkSnapshotOnnx',
  ImageAnomalyDetectionNetworkSnapshotOnnx = 'ImageAnomalyDetectionNetworkSnapshotOnnx',
  CcCheckerResult = 'CCCheckerResult',
  ImageObjectDetectionNetworkSnapshotPytorch = 'ImageObjectDetectionNetworkSnapshotPytorch',
  ImageObjectDetectionNetworkSnapshotOnnx = 'ImageObjectDetectionNetworkSnapshotOnnx'
}

export interface DeleteBackupInput {
  ids: Array<Scalars['String']>;
}

export interface DeleteBackupPayload {
  __typename?: 'DeleteBackupPayload';
  success: Scalars['Boolean'];
}

export interface DeleteClassificationMarkupInput {
  ids: Array<Scalars['String']>;
}

export interface DeleteClassificationMarkupPayload {
  __typename?: 'DeleteClassificationMarkupPayload';
  success: Scalars['Boolean'];
}

export interface DeleteDatasetInput {
  ids: Array<Scalars['String']>;
}

export interface DeleteDatasetPayload {
  __typename?: 'DeleteDatasetPayload';
  notificationId: Scalars['String'];
}

export interface DeleteFolderInput {
  ids: Array<Scalars['String']>;
}

export interface DeleteFolderPayload {
  __typename?: 'DeleteFolderPayload';
  notificationId: Scalars['String'];
}

export interface DeleteImageInput {
  ids: Array<Scalars['String']>;
}

export interface DeleteImagePayload {
  __typename?: 'DeleteImagePayload';
  success: Scalars['Boolean'];
}

export interface DeleteNotificationInput {
  ids: Array<Scalars['String']>;
}

export interface DeleteNotificationPayload {
  __typename?: 'DeleteNotificationPayload';
  success: Scalars['Boolean'];
}

export interface DeleteObjectDetectionMarkupInput {
  ids: Array<Scalars['String']>;
}

export interface DeleteObjectDetectionMarkupPayload {
  __typename?: 'DeleteObjectDetectionMarkupPayload';
  success: Scalars['Boolean'];
}

export interface DeletePipelineModuleEdgeInput {
  ids: Array<Scalars['String']>;
}

export interface DeletePipelineModuleEdgePayload {
  __typename?: 'DeletePipelineModuleEdgePayload';
  success: Scalars['Boolean'];
}

export interface DeletePipelineModuleInput {
  ids: Array<Scalars['String']>;
}

export interface DeletePipelineModulePayload {
  __typename?: 'DeletePipelineModulePayload';
  notificationId: Scalars['String'];
}

export interface DeletePretrainedNetworkInput {
  ids: Array<Scalars['String']>;
}

export interface DeletePretrainedNetworkPayload {
  __typename?: 'DeletePretrainedNetworkPayload';
  success: Scalars['Boolean'];
}

export interface DeleteResearchBoxInput {
  ids: Array<Scalars['String']>;
}

export interface DeleteResearchBoxPayload {
  __typename?: 'DeleteResearchBoxPayload';
  notificationId: Scalars['String'];
}

export interface DeleteSegmentationMarkupInput {
  ids: Array<Scalars['String']>;
}

export interface DeleteSegmentationMarkupPayload {
  __typename?: 'DeleteSegmentationMarkupPayload';
  success: Scalars['Boolean'];
}

export interface DeleteUserInput {
  usernames: Array<Scalars['String']>;
}

export interface DeleteUserPayload {
  __typename?: 'DeleteUserPayload';
  notificationId: Scalars['String'];
}

export interface FileInput {
  id: Scalars['String'];
  file: Scalars['Upload'];
  imageLabel: Scalars['String'];
  metadata?: Maybe<Array<MetadataInput>>;
  fileName?: Maybe<Scalars['String']>;
}

export interface GetMinioCredentialsPayload {
  __typename?: 'GetMinioCredentialsPayload';
  accessKey: Scalars['String'];
  secretKey: Scalars['String'];
}

export interface GpuInput {
  index: Scalars['Int'];
  maxMemory: Scalars['Int'];
  usage: Scalars['Int'];
}

export interface KeepaliveDenkboxInput {
  denkboxId: Scalars['String'];
}

export interface KeepaliveDenkboxPayload {
  __typename?: 'KeepaliveDenkboxPayload';
  success: Scalars['Boolean'];
}

export interface LoginInput {
  username: Scalars['String'];
  password: Scalars['String'];
}

export interface LoginPayload {
  __typename?: 'LoginPayload';
  allowedFeatures: Array<UiFeature>;
  blockedFeatures: Array<UiFeature>;
  role: Role;
  token: Scalars['String'];
}

export interface MarkNotificationAsReadInput {
  notificationIds: Array<Scalars['String']>;
}

export interface MarkNotificationAsReadPayload {
  __typename?: 'MarkNotificationAsReadPayload';
  success: Scalars['Boolean'];
}

export interface MarkupEvent {
  __typename?: 'MarkupEvent';
  markupId: Scalars['String'];
  type: MarkupEventType;
}

export enum MarkupEventType {
  Add = 'ADD',
  Update = 'UPDATE',
  Delete = 'DELETE'
}

export interface MarkupLabelInput {
  name: Scalars['String'];
  color: Scalars['String'];
}

export interface MetadataInput {
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface MoveFolderInput {
  folderIds: Array<Scalars['String']>;
  target: ParentTargetInput;
}

export interface MoveFolderPayload {
  __typename?: 'MoveFolderPayload';
  success: Scalars['Boolean'];
}

export interface MoveResearchBoxInput {
  researchBoxIds: Array<Scalars['String']>;
  target: ParentTargetInput;
}

export interface MoveResearchBoxPayload {
  __typename?: 'MoveResearchBoxPayload';
  success: Scalars['Boolean'];
}

export interface Mutation {
  __typename?: 'Mutation';
  backup?: Maybe<BackupPayload>;
  restore?: Maybe<RestorePayload>;
  login?: Maybe<LoginPayload>;
  createToken?: Maybe<CreateTokenPayload>;
  getMinioCredentials?: Maybe<GetMinioCredentialsPayload>;
  renewToken?: Maybe<RenewTokenPayload>;
  changePassword?: Maybe<ChangePasswordPayload>;
  startSync?: Maybe<StartSyncPayload>;
  uploadFile?: Maybe<UploadFilePayload>;
  addUser?: Maybe<AddUserPayload>;
  addDataset?: Maybe<AddDatasetPayload>;
  addFolder?: Maybe<AddFolderPayload>;
  addImageMetadataField?: Maybe<AddImageMetadataFieldPayload>;
  addPipelineModuleEdge?: Maybe<AddPipelineModuleEdgePayload>;
  addPipelineModuleContrastEnhancement?: Maybe<AddPipelineModuleContrastEnhancementPayload>;
  addPipelineModuleCCChecker?: Maybe<AddPipelineModuleCcCheckerPayload>;
  addPipelineModuleNetworkImageSegmentation?: Maybe<AddPipelineModuleNetworkImageSegmentationPayload>;
  addPipelineModuleNetworkImageObjectDetection?: Maybe<AddPipelineModuleNetworkImageObjectDetectionPayload>;
  addPipelineModuleNetworkImageClassification?: Maybe<AddPipelineModuleNetworkImageClassificationPayload>;
  addPipelineModuleJobRequestContrastEnhancement?: Maybe<AddPipelineModuleJobRequestContrastEnhancementPayload>;
  addPipelineModuleJobRequestCCChecker?: Maybe<AddPipelineModuleJobRequestCcCheckerPayload>;
  addPretrainedNetwork?: Maybe<AddPretrainedNetworkPayload>;
  setMarkupSubset?: Maybe<SetMarkupSubsetPayload>;
  addImageSegmentationMarkup?: Maybe<AddImageSegmentationMarkupPayload>;
  addImageObjectDetectionMarkup?: Maybe<AddImageObjectDetectionMarkupPayload>;
  addImageClassificationMarkup?: Maybe<AddImageClassificationMarkupPayload>;
  addImageSegmentationPrediction?: Maybe<AddImageSegmentationPredictionPayload>;
  addImageObjectDetectionPrediction?: Maybe<AddImageObjectDetectionPredictionPayload>;
  addImageClassificationPrediction?: Maybe<AddImageClassificationPredictionPayload>;
  publishPipelineModule?: Maybe<PublishPipelineModulePayload>;
  deleteBackup?: Maybe<DeleteBackupPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  deleteDataset?: Maybe<DeleteDatasetPayload>;
  deleteFolder?: Maybe<DeleteFolderPayload>;
  deleteResearchBox?: Maybe<DeleteResearchBoxPayload>;
  deleteImage?: Maybe<DeleteImagePayload>;
  deletePipelineModule?: Maybe<DeletePipelineModulePayload>;
  deletePipelineModuleEdge?: Maybe<DeletePipelineModuleEdgePayload>;
  deletePretrainedNetwork?: Maybe<DeletePretrainedNetworkPayload>;
  deleteSegmentationMarkup?: Maybe<DeleteSegmentationMarkupPayload>;
  deleteClassificationMarkup?: Maybe<DeleteClassificationMarkupPayload>;
  deleteObjectDetectionMarkup?: Maybe<DeleteObjectDetectionMarkupPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateDataset?: Maybe<UpdateDatasetPayload>;
  updateFolder?: Maybe<UpdateFolderPayload>;
  updateResearchBox?: Maybe<UpdateResearchBoxPayload>;
  updatePipelineModulePosition?: Maybe<UpdatePipelineModulePositionPayload>;
  updatePipelineModuleContrastEnhancement?: Maybe<UpdatePipelineModuleContrastEnhancementPayload>;
  updatePipelineModuleCCChecker?: Maybe<UpdatePipelineModuleCcCheckerPayload>;
  updatePipelineModuleNetworkImageSegmentation?: Maybe<UpdatePipelineModuleNetworkImageSegmentationPayload>;
  updatePipelineModuleNetworkImageObjectDetection?: Maybe<UpdatePipelineModuleNetworkImageObjectDetectionPayload>;
  updatePipelineModuleNetworkImageClassification?: Maybe<UpdatePipelineModuleNetworkImageClassificationPayload>;
  copyDataset?: Maybe<CopyDatasetPayload>;
  copyFolder?: Maybe<CopyFolderPayload>;
  moveFolder?: Maybe<MoveFolderPayload>;
  moveResearchBox?: Maybe<MoveResearchBoxPayload>;
  addProgressNotification?: Maybe<AddProgressNotificationPayload>;
  markNotificationAsRead?: Maybe<MarkNotificationAsReadPayload>;
  deleteNotification?: Maybe<DeleteNotificationPayload>;
  startTraining?: Maybe<StartTrainingPayload>;
  stopTraining?: Maybe<StopTrainingPayload>;
  registerDenkbox?: Maybe<RegisterDenkboxPayload>;
  keepaliveDenkbox?: Maybe<KeepaliveDenkboxPayload>;
  startInference?: Maybe<StartInferencePayload>;
  stopInference?: Maybe<StopInferencePayload>;
  resetNetworkSnapshot?: Maybe<ResetNetworkSnapshotPayload>;
}


export interface MutationRestoreArgs {
  input: RestoreInput;
}


export interface MutationLoginArgs {
  input: LoginInput;
}


export interface MutationCreateTokenArgs {
  input: CreateTokenInput;
}


export interface MutationChangePasswordArgs {
  input: ChangePasswordInput;
}


export interface MutationUploadFileArgs {
  input: UploadFileInput;
}


export interface MutationAddUserArgs {
  input: AddUserInput;
}


export interface MutationAddDatasetArgs {
  input: AddDatasetInput;
}


export interface MutationAddFolderArgs {
  input: AddFolderInput;
}


export interface MutationAddImageMetadataFieldArgs {
  input: AddImageMetadataFieldInput;
}


export interface MutationAddPipelineModuleEdgeArgs {
  input: AddPipelineModuleEdgeInput;
}


export interface MutationAddPipelineModuleContrastEnhancementArgs {
  input: AddPipelineModuleContrastEnhancementInput;
}


export interface MutationAddPipelineModuleCcCheckerArgs {
  input: AddPipelineModuleCcCheckerInput;
}


export interface MutationAddPipelineModuleNetworkImageSegmentationArgs {
  input: AddPipelineModuleNetworkImageSegmentationInput;
}


export interface MutationAddPipelineModuleNetworkImageObjectDetectionArgs {
  input: AddPipelineModuleNetworkImageObjectDetectionInput;
}


export interface MutationAddPipelineModuleNetworkImageClassificationArgs {
  input: AddPipelineModuleNetworkImageClassificationInput;
}


export interface MutationAddPipelineModuleJobRequestContrastEnhancementArgs {
  input: AddPipelineModuleJobRequestContrastEnhancementInput;
}


export interface MutationAddPipelineModuleJobRequestCcCheckerArgs {
  input: AddPipelineModuleJobRequestCcCheckerInput;
}


export interface MutationAddPretrainedNetworkArgs {
  input: AddPretrainedNetworkInput;
}


export interface MutationSetMarkupSubsetArgs {
  input: SetMarkupSubsetInput;
}


export interface MutationAddImageSegmentationMarkupArgs {
  input: AddImageSegmentationMarkupInput;
}


export interface MutationAddImageObjectDetectionMarkupArgs {
  input: AddImageObjectDetectionMarkupInput;
}


export interface MutationAddImageClassificationMarkupArgs {
  input: AddImageClassificationMarkupInput;
}


export interface MutationAddImageSegmentationPredictionArgs {
  input: AddImageSegmentationPredictionInput;
}


export interface MutationAddImageObjectDetectionPredictionArgs {
  input: AddImageObjectDetectionPredictionInput;
}


export interface MutationAddImageClassificationPredictionArgs {
  input: AddImageClassificationPredictionInput;
}


export interface MutationPublishPipelineModuleArgs {
  input: PublishPipelineModuleInput;
}


export interface MutationDeleteBackupArgs {
  input: DeleteBackupInput;
}


export interface MutationDeleteUserArgs {
  input: DeleteUserInput;
}


export interface MutationDeleteDatasetArgs {
  input: DeleteDatasetInput;
}


export interface MutationDeleteFolderArgs {
  input: DeleteFolderInput;
}


export interface MutationDeleteResearchBoxArgs {
  input: DeleteResearchBoxInput;
}


export interface MutationDeleteImageArgs {
  input: DeleteImageInput;
}


export interface MutationDeletePipelineModuleArgs {
  input: DeletePipelineModuleInput;
}


export interface MutationDeletePipelineModuleEdgeArgs {
  input: DeletePipelineModuleEdgeInput;
}


export interface MutationDeletePretrainedNetworkArgs {
  input: DeletePretrainedNetworkInput;
}


export interface MutationDeleteSegmentationMarkupArgs {
  input: DeleteSegmentationMarkupInput;
}


export interface MutationDeleteClassificationMarkupArgs {
  input: DeleteClassificationMarkupInput;
}


export interface MutationDeleteObjectDetectionMarkupArgs {
  input: DeleteObjectDetectionMarkupInput;
}


export interface MutationUpdateUserArgs {
  input: UpdateUserInput;
}


export interface MutationUpdateDatasetArgs {
  input: UpdateDatasetInput;
}


export interface MutationUpdateFolderArgs {
  input: UpdateFolderInput;
}


export interface MutationUpdateResearchBoxArgs {
  input: UpdateResearchBoxInput;
}


export interface MutationUpdatePipelineModulePositionArgs {
  input: UpdatePipelineModulePositionInput;
}


export interface MutationUpdatePipelineModuleContrastEnhancementArgs {
  input: UpdatePipelineModuleContrastEnhancementInput;
}


export interface MutationUpdatePipelineModuleCcCheckerArgs {
  input: UpdatePipelineModuleCcCheckerInput;
}


export interface MutationUpdatePipelineModuleNetworkImageSegmentationArgs {
  input: UpdatePipelineModuleNetworkImageSegmentationInput;
}


export interface MutationUpdatePipelineModuleNetworkImageObjectDetectionArgs {
  input: UpdatePipelineModuleNetworkImageObjectDetectionInput;
}


export interface MutationUpdatePipelineModuleNetworkImageClassificationArgs {
  input: UpdatePipelineModuleNetworkImageClassificationInput;
}


export interface MutationCopyDatasetArgs {
  input: CopyDatasetInput;
}


export interface MutationCopyFolderArgs {
  input: CopyFolderInput;
}


export interface MutationMoveFolderArgs {
  input: MoveFolderInput;
}


export interface MutationMoveResearchBoxArgs {
  input: MoveResearchBoxInput;
}


export interface MutationAddProgressNotificationArgs {
  input: AddProgressNotificationInput;
}


export interface MutationMarkNotificationAsReadArgs {
  input: MarkNotificationAsReadInput;
}


export interface MutationDeleteNotificationArgs {
  input: DeleteNotificationInput;
}


export interface MutationStartTrainingArgs {
  input: StartTrainingInput;
}


export interface MutationStopTrainingArgs {
  input: StopTrainingInput;
}


export interface MutationRegisterDenkboxArgs {
  input: RegisterDenkboxInput;
}


export interface MutationKeepaliveDenkboxArgs {
  input: KeepaliveDenkboxInput;
}


export interface MutationStartInferenceArgs {
  input: StartInferenceInput;
}


export interface MutationStopInferenceArgs {
  input: StopInferenceInput;
}


export interface MutationResetNetworkSnapshotArgs {
  input: ResetNetworkSnapshotInput;
}

export interface ObjectDectionPredictionInput {
  markupLabelId: Scalars['String'];
  topLeft: AddPoint2DInput;
  bottomRight: AddPoint2DInput;
  probability: Scalars['Float'];
}

export interface ParentInput {
  datasetId?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
}

export interface ParentTargetInput {
  datasetId?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
}

export interface PipelineModulePosition {
  x: Scalars['Int'];
  y: Scalars['Int'];
}

export interface PublishPipelineModuleInput {
  moduleId: Scalars['String'];
}

export interface PublishPipelineModulePayload {
  __typename?: 'PublishPipelineModulePayload';
  success: Scalars['Boolean'];
}

export interface Query {
  __typename?: 'Query';
  whatIsTheMeaningOfLifeTheUniverseAndEverything: Scalars['String'];
}

export interface RegisterDenkboxGpuPayload {
  __typename?: 'RegisterDenkboxGPUPayload';
  index: Scalars['Int'];
  id: Scalars['String'];
}

export interface RegisterDenkboxInput {
  dockerId: Scalars['String'];
  host: Scalars['String'];
  gpus: Array<GpuInput>;
}

export interface RegisterDenkboxPayload {
  __typename?: 'RegisterDenkboxPayload';
  denkboxId: Scalars['String'];
  gpus: Array<RegisterDenkboxGpuPayload>;
}

export interface RenewTokenPayload {
  __typename?: 'RenewTokenPayload';
  token: Scalars['String'];
}

export interface ResetNetworkSnapshotInput {
  networkModuleId: Scalars['String'];
}

export interface ResetNetworkSnapshotPayload {
  __typename?: 'ResetNetworkSnapshotPayload';
  success: Scalars['Boolean'];
}

export interface RestoreInput {
  backupId: Scalars['String'];
}

export interface RestorePayload {
  __typename?: 'RestorePayload';
  success: Scalars['Boolean'];
}

export enum Role {
  Unauthorized = 'UNAUTHORIZED',
  Tenant = 'TENANT',
  Vendor = 'VENDOR',
  Admin = 'ADMIN'
}

export interface SegmentationMapObjectInput {
  topLeft: AddPoint2DInput;
  bottomRight: AddPoint2DInput;
  probability: Scalars['Float'];
}

export interface SegmentationMapPredictionInput {
  markupLabelId: Scalars['String'];
  map: Scalars['Upload'];
  objects: Array<SegmentationMapObjectInput>;
}

export interface SetMarkupSubsetInput {
  markupIds: Array<Scalars['String']>;
  subset: SubsetType;
}

export interface SetMarkupSubsetPayload {
  __typename?: 'SetMarkupSubsetPayload';
  success: Scalars['Boolean'];
}

export interface StartInferenceInput {
  moduleId: Scalars['String'];
  denkboxId?: Maybe<Scalars['String']>;
  researchBoxIds: Array<Scalars['String']>;
}

export interface StartInferencePayload {
  __typename?: 'StartInferencePayload';
  denkjobId: Scalars['String'];
}

export interface StartSyncPayload {
  __typename?: 'StartSyncPayload';
  success: Scalars['Boolean'];
}

export interface StartTrainingInput {
  moduleId: Scalars['String'];
  denkboxId?: Maybe<Scalars['String']>;
}

export interface StartTrainingPayload {
  __typename?: 'StartTrainingPayload';
  denkjobId: Scalars['String'];
}

export interface StopInferenceInput {
  moduleId: Scalars['String'];
}

export interface StopInferencePayload {
  __typename?: 'StopInferencePayload';
  success: Scalars['Boolean'];
}

export interface StopTrainingInput {
  moduleId: Scalars['String'];
}

export interface StopTrainingPayload {
  __typename?: 'StopTrainingPayload';
  success: Scalars['Boolean'];
}

export interface SubscribeMarkupsForNetworkInput {
  networkId: Scalars['String'];
}

export interface SubscribeMarkupsForNetworkPayload {
  __typename?: 'SubscribeMarkupsForNetworkPayload';
  events: Array<MarkupEvent>;
}

export interface Subscription {
  __typename?: 'Subscription';
  subscribeMarkupsForNetwork?: Maybe<SubscribeMarkupsForNetworkPayload>;
}


export interface SubscriptionSubscribeMarkupsForNetworkArgs {
  input: SubscribeMarkupsForNetworkInput;
}

export enum SubsetType {
  Training = 'TRAINING',
  Validation = 'VALIDATION'
}

export enum UiFeature {
  NetworkMutate = 'NETWORK_MUTATE',
  NetworkTraining = 'NETWORK_TRAINING',
  NetworkDownload = 'NETWORK_DOWNLOAD',
  NetworkAdvanced = 'NETWORK_ADVANCED',
  MarkupMutate = 'MARKUP_MUTATE',
  MarkupPositiveNegativeIgnore = 'MARKUP_POSITIVE_NEGATIVE_IGNORE',
  UploadFiles = 'UPLOAD_FILES',
  ResearchboxMutate = 'RESEARCHBOX_MUTATE',
  FolderMutate = 'FOLDER_MUTATE',
  DatasetMutate = 'DATASET_MUTATE',
  FlowDesigner = 'FLOW_DESIGNER'
}

export interface UpdateDatasetFilter {
  datasetId: Scalars['String'];
}

export interface UpdateDatasetInput {
  filter: UpdateDatasetFilter;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}

export interface UpdateDatasetPayload {
  __typename?: 'UpdateDatasetPayload';
  success: Scalars['Boolean'];
}

export interface UpdateFolderInput {
  folderId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
}

export interface UpdateFolderPayload {
  __typename?: 'UpdateFolderPayload';
  success: Scalars['Boolean'];
}

export interface UpdateMarkupLabelInput {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
}

export interface UpdatePipelineModuleCcCheckerFilter {
  moduleId: Scalars['String'];
}

export interface UpdatePipelineModuleCcCheckerInput {
  filter: UpdatePipelineModuleCcCheckerFilter;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  cccheckerResultName?: Maybe<Scalars['String']>;
  busBarCountPerCell?: Maybe<Scalars['Int']>;
  maxShift?: Maybe<Scalars['Int']>;
  minCorrValue?: Maybe<Scalars['Float']>;
  cutOffRate?: Maybe<Scalars['Float']>;
  minPeakHeightPercentage?: Maybe<Scalars['Float']>;
  minPeakDistance?: Maybe<Scalars['Float']>;
  minPeakProminence?: Maybe<Scalars['Float']>;
}

export interface UpdatePipelineModuleCcCheckerPayload {
  __typename?: 'UpdatePipelineModuleCCCheckerPayload';
  success: Scalars['Boolean'];
}

export interface UpdatePipelineModuleContrastEnhancementFilter {
  moduleId: Scalars['String'];
}

export interface UpdatePipelineModuleContrastEnhancementInput {
  filter: UpdatePipelineModuleContrastEnhancementFilter;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enhancedImageName?: Maybe<Scalars['String']>;
  clipLimit?: Maybe<Scalars['Float']>;
  tileSize?: Maybe<Scalars['Int']>;
}

export interface UpdatePipelineModuleContrastEnhancementPayload {
  __typename?: 'UpdatePipelineModuleContrastEnhancementPayload';
  success: Scalars['Boolean'];
}

export interface UpdatePipelineModuleNetworkImageClassificationFilter {
  moduleId: Scalars['String'];
}

export interface UpdatePipelineModuleNetworkImageClassificationInput {
  filter: UpdatePipelineModuleNetworkImageClassificationFilter;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  networkName?: Maybe<Scalars['String']>;
  classLabels?: Maybe<Array<UpdateMarkupLabelInput>>;
}

export interface UpdatePipelineModuleNetworkImageClassificationPayload {
  __typename?: 'UpdatePipelineModuleNetworkImageClassificationPayload';
  success: Scalars['Boolean'];
}

export interface UpdatePipelineModuleNetworkImageObjectDetectionFilter {
  moduleId: Scalars['String'];
}

export interface UpdatePipelineModuleNetworkImageObjectDetectionInput {
  filter: UpdatePipelineModuleNetworkImageObjectDetectionFilter;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  networkName?: Maybe<Scalars['String']>;
  classLabels?: Maybe<Array<UpdateMarkupLabelInput>>;
}

export interface UpdatePipelineModuleNetworkImageObjectDetectionPayload {
  __typename?: 'UpdatePipelineModuleNetworkImageObjectDetectionPayload';
  success: Scalars['Boolean'];
}

export interface UpdatePipelineModuleNetworkImageSegmentationFilter {
  moduleId: Scalars['String'];
}

export interface UpdatePipelineModuleNetworkImageSegmentationInput {
  filter: UpdatePipelineModuleNetworkImageSegmentationFilter;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  networkName?: Maybe<Scalars['String']>;
  classLabels?: Maybe<Array<UpdateMarkupLabelInput>>;
}

export interface UpdatePipelineModuleNetworkImageSegmentationPayload {
  __typename?: 'UpdatePipelineModuleNetworkImageSegmentationPayload';
  success: Scalars['Boolean'];
}

export interface UpdatePipelineModulePositionFilter {
  moduleId: Scalars['String'];
}

export interface UpdatePipelineModulePositionInput {
  filter: UpdatePipelineModulePositionFilter;
  position: PipelineModulePosition;
}

export interface UpdatePipelineModulePositionPayload {
  __typename?: 'UpdatePipelineModulePositionPayload';
  success: Scalars['Boolean'];
}

export interface UpdateResearchBoxInput {
  researchBoxId: Scalars['String'];
  name: Scalars['String'];
}

export interface UpdateResearchBoxPayload {
  __typename?: 'UpdateResearchBoxPayload';
  success: Scalars['Boolean'];
}

export interface UpdateUserFilter {
  username: Scalars['String'];
}

export interface UpdateUserInput {
  filter: UpdateUserFilter;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
  allowedFeatures?: Maybe<Array<UiFeature>>;
  blockedFeatures?: Maybe<Array<UiFeature>>;
  trainingQuota?: Maybe<Scalars['Int']>;
  inferenceQuota?: Maybe<Scalars['Int']>;
}

export interface UpdateUserPayload {
  __typename?: 'UpdateUserPayload';
  success: Scalars['Boolean'];
}


export interface UploadFileInput {
  datasetId: Scalars['String'];
  folderId?: Maybe<Scalars['String']>;
  notificationId?: Maybe<Scalars['String']>;
  file: FileInput;
}

export interface UploadFilePayload {
  __typename?: 'UploadFilePayload';
  id: Scalars['String'];
  name: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
}


export const AddImageClassificationMarkupDocument = gql`
    mutation AddImageClassificationMarkup($input: AddImageClassificationMarkupInput!) {
  addImageClassificationMarkup(input: $input) {
    markupId
  }
}
    `;
export type AddImageClassificationMarkupMutationFn = Apollo.MutationFunction<AddImageClassificationMarkupMutation, AddImageClassificationMarkupMutationVariables>;

/**
 * __useAddImageClassificationMarkupMutation__
 *
 * To run a mutation, you first call `useAddImageClassificationMarkupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddImageClassificationMarkupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addImageClassificationMarkupMutation, { data, loading, error }] = useAddImageClassificationMarkupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddImageClassificationMarkupMutation(baseOptions?: Apollo.MutationHookOptions<AddImageClassificationMarkupMutation, AddImageClassificationMarkupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddImageClassificationMarkupMutation, AddImageClassificationMarkupMutationVariables>(AddImageClassificationMarkupDocument, options);
      }
export type AddImageClassificationMarkupMutationHookResult = ReturnType<typeof useAddImageClassificationMarkupMutation>;
export type AddImageClassificationMarkupMutationResult = Apollo.MutationResult<AddImageClassificationMarkupMutation>;
export type AddImageClassificationMarkupMutationOptions = Apollo.BaseMutationOptions<AddImageClassificationMarkupMutation, AddImageClassificationMarkupMutationVariables>;
export const AddDatasetDocument = gql`
    mutation AddDataset($input: AddDatasetInput!) {
  addDataset(input: $input) {
    datasetId
    pipelineId
    imageOutputLabelId
  }
}
    `;
export type AddDatasetMutationFn = Apollo.MutationFunction<AddDatasetMutation, AddDatasetMutationVariables>;

/**
 * __useAddDatasetMutation__
 *
 * To run a mutation, you first call `useAddDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDatasetMutation, { data, loading, error }] = useAddDatasetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDatasetMutation(baseOptions?: Apollo.MutationHookOptions<AddDatasetMutation, AddDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDatasetMutation, AddDatasetMutationVariables>(AddDatasetDocument, options);
      }
export type AddDatasetMutationHookResult = ReturnType<typeof useAddDatasetMutation>;
export type AddDatasetMutationResult = Apollo.MutationResult<AddDatasetMutation>;
export type AddDatasetMutationOptions = Apollo.BaseMutationOptions<AddDatasetMutation, AddDatasetMutationVariables>;
export const AddFolderDocument = gql`
    mutation AddFolder($input: AddFolderInput!) {
  addFolder(input: $input) {
    folderId
  }
}
    `;
export type AddFolderMutationFn = Apollo.MutationFunction<AddFolderMutation, AddFolderMutationVariables>;

/**
 * __useAddFolderMutation__
 *
 * To run a mutation, you first call `useAddFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFolderMutation, { data, loading, error }] = useAddFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFolderMutation(baseOptions?: Apollo.MutationHookOptions<AddFolderMutation, AddFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFolderMutation, AddFolderMutationVariables>(AddFolderDocument, options);
      }
export type AddFolderMutationHookResult = ReturnType<typeof useAddFolderMutation>;
export type AddFolderMutationResult = Apollo.MutationResult<AddFolderMutation>;
export type AddFolderMutationOptions = Apollo.BaseMutationOptions<AddFolderMutation, AddFolderMutationVariables>;
export const AddImageMetadataFieldDocument = gql`
    mutation AddImageMetadataField($input: AddImageMetadataFieldInput!) {
  addImageMetadataField(input: $input) {
    imageMetadataFieldId
  }
}
    `;
export type AddImageMetadataFieldMutationFn = Apollo.MutationFunction<AddImageMetadataFieldMutation, AddImageMetadataFieldMutationVariables>;

/**
 * __useAddImageMetadataFieldMutation__
 *
 * To run a mutation, you first call `useAddImageMetadataFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddImageMetadataFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addImageMetadataFieldMutation, { data, loading, error }] = useAddImageMetadataFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddImageMetadataFieldMutation(baseOptions?: Apollo.MutationHookOptions<AddImageMetadataFieldMutation, AddImageMetadataFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddImageMetadataFieldMutation, AddImageMetadataFieldMutationVariables>(AddImageMetadataFieldDocument, options);
      }
export type AddImageMetadataFieldMutationHookResult = ReturnType<typeof useAddImageMetadataFieldMutation>;
export type AddImageMetadataFieldMutationResult = Apollo.MutationResult<AddImageMetadataFieldMutation>;
export type AddImageMetadataFieldMutationOptions = Apollo.BaseMutationOptions<AddImageMetadataFieldMutation, AddImageMetadataFieldMutationVariables>;
export const AddImageObjectDetectionMarkupDocument = gql`
    mutation AddImageObjectDetectionMarkup($input: AddImageObjectDetectionMarkupInput!) {
  addImageObjectDetectionMarkup(input: $input) {
    markupId
  }
}
    `;
export type AddImageObjectDetectionMarkupMutationFn = Apollo.MutationFunction<AddImageObjectDetectionMarkupMutation, AddImageObjectDetectionMarkupMutationVariables>;

/**
 * __useAddImageObjectDetectionMarkupMutation__
 *
 * To run a mutation, you first call `useAddImageObjectDetectionMarkupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddImageObjectDetectionMarkupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addImageObjectDetectionMarkupMutation, { data, loading, error }] = useAddImageObjectDetectionMarkupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddImageObjectDetectionMarkupMutation(baseOptions?: Apollo.MutationHookOptions<AddImageObjectDetectionMarkupMutation, AddImageObjectDetectionMarkupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddImageObjectDetectionMarkupMutation, AddImageObjectDetectionMarkupMutationVariables>(AddImageObjectDetectionMarkupDocument, options);
      }
export type AddImageObjectDetectionMarkupMutationHookResult = ReturnType<typeof useAddImageObjectDetectionMarkupMutation>;
export type AddImageObjectDetectionMarkupMutationResult = Apollo.MutationResult<AddImageObjectDetectionMarkupMutation>;
export type AddImageObjectDetectionMarkupMutationOptions = Apollo.BaseMutationOptions<AddImageObjectDetectionMarkupMutation, AddImageObjectDetectionMarkupMutationVariables>;
export const AddPipelineModuleCcCheckerDocument = gql`
    mutation AddPipelineModuleCCChecker($input: AddPipelineModuleCCCheckerInput!) {
  addPipelineModuleCCChecker(input: $input) {
    moduleId
  }
}
    `;
export type AddPipelineModuleCcCheckerMutationFn = Apollo.MutationFunction<AddPipelineModuleCcCheckerMutation, AddPipelineModuleCcCheckerMutationVariables>;

/**
 * __useAddPipelineModuleCcCheckerMutation__
 *
 * To run a mutation, you first call `useAddPipelineModuleCcCheckerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPipelineModuleCcCheckerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPipelineModuleCcCheckerMutation, { data, loading, error }] = useAddPipelineModuleCcCheckerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPipelineModuleCcCheckerMutation(baseOptions?: Apollo.MutationHookOptions<AddPipelineModuleCcCheckerMutation, AddPipelineModuleCcCheckerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPipelineModuleCcCheckerMutation, AddPipelineModuleCcCheckerMutationVariables>(AddPipelineModuleCcCheckerDocument, options);
      }
export type AddPipelineModuleCcCheckerMutationHookResult = ReturnType<typeof useAddPipelineModuleCcCheckerMutation>;
export type AddPipelineModuleCcCheckerMutationResult = Apollo.MutationResult<AddPipelineModuleCcCheckerMutation>;
export type AddPipelineModuleCcCheckerMutationOptions = Apollo.BaseMutationOptions<AddPipelineModuleCcCheckerMutation, AddPipelineModuleCcCheckerMutationVariables>;
export const AddPipelineModuleContrastEnhancementDocument = gql`
    mutation AddPipelineModuleContrastEnhancement($input: AddPipelineModuleContrastEnhancementInput!) {
  addPipelineModuleContrastEnhancement(input: $input) {
    moduleId
  }
}
    `;
export type AddPipelineModuleContrastEnhancementMutationFn = Apollo.MutationFunction<AddPipelineModuleContrastEnhancementMutation, AddPipelineModuleContrastEnhancementMutationVariables>;

/**
 * __useAddPipelineModuleContrastEnhancementMutation__
 *
 * To run a mutation, you first call `useAddPipelineModuleContrastEnhancementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPipelineModuleContrastEnhancementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPipelineModuleContrastEnhancementMutation, { data, loading, error }] = useAddPipelineModuleContrastEnhancementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPipelineModuleContrastEnhancementMutation(baseOptions?: Apollo.MutationHookOptions<AddPipelineModuleContrastEnhancementMutation, AddPipelineModuleContrastEnhancementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPipelineModuleContrastEnhancementMutation, AddPipelineModuleContrastEnhancementMutationVariables>(AddPipelineModuleContrastEnhancementDocument, options);
      }
export type AddPipelineModuleContrastEnhancementMutationHookResult = ReturnType<typeof useAddPipelineModuleContrastEnhancementMutation>;
export type AddPipelineModuleContrastEnhancementMutationResult = Apollo.MutationResult<AddPipelineModuleContrastEnhancementMutation>;
export type AddPipelineModuleContrastEnhancementMutationOptions = Apollo.BaseMutationOptions<AddPipelineModuleContrastEnhancementMutation, AddPipelineModuleContrastEnhancementMutationVariables>;
export const AddPipelineModuleEdgeDocument = gql`
    mutation AddPipelineModuleEdge($input: AddPipelineModuleEdgeInput!) {
  addPipelineModuleEdge(input: $input) {
    edgeId
  }
}
    `;
export type AddPipelineModuleEdgeMutationFn = Apollo.MutationFunction<AddPipelineModuleEdgeMutation, AddPipelineModuleEdgeMutationVariables>;

/**
 * __useAddPipelineModuleEdgeMutation__
 *
 * To run a mutation, you first call `useAddPipelineModuleEdgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPipelineModuleEdgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPipelineModuleEdgeMutation, { data, loading, error }] = useAddPipelineModuleEdgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPipelineModuleEdgeMutation(baseOptions?: Apollo.MutationHookOptions<AddPipelineModuleEdgeMutation, AddPipelineModuleEdgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPipelineModuleEdgeMutation, AddPipelineModuleEdgeMutationVariables>(AddPipelineModuleEdgeDocument, options);
      }
export type AddPipelineModuleEdgeMutationHookResult = ReturnType<typeof useAddPipelineModuleEdgeMutation>;
export type AddPipelineModuleEdgeMutationResult = Apollo.MutationResult<AddPipelineModuleEdgeMutation>;
export type AddPipelineModuleEdgeMutationOptions = Apollo.BaseMutationOptions<AddPipelineModuleEdgeMutation, AddPipelineModuleEdgeMutationVariables>;
export const AddPipelineModuleJobRequestCcCheckerDocument = gql`
    mutation AddPipelineModuleJobRequestCCChecker($input: AddPipelineModuleJobRequestCCCheckerInput!) {
  addPipelineModuleJobRequestCCChecker(input: $input) {
    pipelineModuleJobRequestId
  }
}
    `;
export type AddPipelineModuleJobRequestCcCheckerMutationFn = Apollo.MutationFunction<AddPipelineModuleJobRequestCcCheckerMutation, AddPipelineModuleJobRequestCcCheckerMutationVariables>;

/**
 * __useAddPipelineModuleJobRequestCcCheckerMutation__
 *
 * To run a mutation, you first call `useAddPipelineModuleJobRequestCcCheckerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPipelineModuleJobRequestCcCheckerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPipelineModuleJobRequestCcCheckerMutation, { data, loading, error }] = useAddPipelineModuleJobRequestCcCheckerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPipelineModuleJobRequestCcCheckerMutation(baseOptions?: Apollo.MutationHookOptions<AddPipelineModuleJobRequestCcCheckerMutation, AddPipelineModuleJobRequestCcCheckerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPipelineModuleJobRequestCcCheckerMutation, AddPipelineModuleJobRequestCcCheckerMutationVariables>(AddPipelineModuleJobRequestCcCheckerDocument, options);
      }
export type AddPipelineModuleJobRequestCcCheckerMutationHookResult = ReturnType<typeof useAddPipelineModuleJobRequestCcCheckerMutation>;
export type AddPipelineModuleJobRequestCcCheckerMutationResult = Apollo.MutationResult<AddPipelineModuleJobRequestCcCheckerMutation>;
export type AddPipelineModuleJobRequestCcCheckerMutationOptions = Apollo.BaseMutationOptions<AddPipelineModuleJobRequestCcCheckerMutation, AddPipelineModuleJobRequestCcCheckerMutationVariables>;
export const AddPipelineModuleJobRequestContrastEnhancementDocument = gql`
    mutation AddPipelineModuleJobRequestContrastEnhancement($input: AddPipelineModuleJobRequestContrastEnhancementInput!) {
  addPipelineModuleJobRequestContrastEnhancement(input: $input) {
    pipelineModuleJobRequestId
  }
}
    `;
export type AddPipelineModuleJobRequestContrastEnhancementMutationFn = Apollo.MutationFunction<AddPipelineModuleJobRequestContrastEnhancementMutation, AddPipelineModuleJobRequestContrastEnhancementMutationVariables>;

/**
 * __useAddPipelineModuleJobRequestContrastEnhancementMutation__
 *
 * To run a mutation, you first call `useAddPipelineModuleJobRequestContrastEnhancementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPipelineModuleJobRequestContrastEnhancementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPipelineModuleJobRequestContrastEnhancementMutation, { data, loading, error }] = useAddPipelineModuleJobRequestContrastEnhancementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPipelineModuleJobRequestContrastEnhancementMutation(baseOptions?: Apollo.MutationHookOptions<AddPipelineModuleJobRequestContrastEnhancementMutation, AddPipelineModuleJobRequestContrastEnhancementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPipelineModuleJobRequestContrastEnhancementMutation, AddPipelineModuleJobRequestContrastEnhancementMutationVariables>(AddPipelineModuleJobRequestContrastEnhancementDocument, options);
      }
export type AddPipelineModuleJobRequestContrastEnhancementMutationHookResult = ReturnType<typeof useAddPipelineModuleJobRequestContrastEnhancementMutation>;
export type AddPipelineModuleJobRequestContrastEnhancementMutationResult = Apollo.MutationResult<AddPipelineModuleJobRequestContrastEnhancementMutation>;
export type AddPipelineModuleJobRequestContrastEnhancementMutationOptions = Apollo.BaseMutationOptions<AddPipelineModuleJobRequestContrastEnhancementMutation, AddPipelineModuleJobRequestContrastEnhancementMutationVariables>;
export const AddPipelineModuleNetworkImageClassificationDocument = gql`
    mutation AddPipelineModuleNetworkImageClassification($input: AddPipelineModuleNetworkImageClassificationInput!) {
  addPipelineModuleNetworkImageClassification(input: $input) {
    moduleId
    datasetId
  }
}
    `;
export type AddPipelineModuleNetworkImageClassificationMutationFn = Apollo.MutationFunction<AddPipelineModuleNetworkImageClassificationMutation, AddPipelineModuleNetworkImageClassificationMutationVariables>;

/**
 * __useAddPipelineModuleNetworkImageClassificationMutation__
 *
 * To run a mutation, you first call `useAddPipelineModuleNetworkImageClassificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPipelineModuleNetworkImageClassificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPipelineModuleNetworkImageClassificationMutation, { data, loading, error }] = useAddPipelineModuleNetworkImageClassificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPipelineModuleNetworkImageClassificationMutation(baseOptions?: Apollo.MutationHookOptions<AddPipelineModuleNetworkImageClassificationMutation, AddPipelineModuleNetworkImageClassificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPipelineModuleNetworkImageClassificationMutation, AddPipelineModuleNetworkImageClassificationMutationVariables>(AddPipelineModuleNetworkImageClassificationDocument, options);
      }
export type AddPipelineModuleNetworkImageClassificationMutationHookResult = ReturnType<typeof useAddPipelineModuleNetworkImageClassificationMutation>;
export type AddPipelineModuleNetworkImageClassificationMutationResult = Apollo.MutationResult<AddPipelineModuleNetworkImageClassificationMutation>;
export type AddPipelineModuleNetworkImageClassificationMutationOptions = Apollo.BaseMutationOptions<AddPipelineModuleNetworkImageClassificationMutation, AddPipelineModuleNetworkImageClassificationMutationVariables>;
export const AddPipelineModuleNetworkImageObjectDetectionDocument = gql`
    mutation AddPipelineModuleNetworkImageObjectDetection($input: AddPipelineModuleNetworkImageObjectDetectionInput!) {
  addPipelineModuleNetworkImageObjectDetection(input: $input) {
    moduleId
    datasetId
  }
}
    `;
export type AddPipelineModuleNetworkImageObjectDetectionMutationFn = Apollo.MutationFunction<AddPipelineModuleNetworkImageObjectDetectionMutation, AddPipelineModuleNetworkImageObjectDetectionMutationVariables>;

/**
 * __useAddPipelineModuleNetworkImageObjectDetectionMutation__
 *
 * To run a mutation, you first call `useAddPipelineModuleNetworkImageObjectDetectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPipelineModuleNetworkImageObjectDetectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPipelineModuleNetworkImageObjectDetectionMutation, { data, loading, error }] = useAddPipelineModuleNetworkImageObjectDetectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPipelineModuleNetworkImageObjectDetectionMutation(baseOptions?: Apollo.MutationHookOptions<AddPipelineModuleNetworkImageObjectDetectionMutation, AddPipelineModuleNetworkImageObjectDetectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPipelineModuleNetworkImageObjectDetectionMutation, AddPipelineModuleNetworkImageObjectDetectionMutationVariables>(AddPipelineModuleNetworkImageObjectDetectionDocument, options);
      }
export type AddPipelineModuleNetworkImageObjectDetectionMutationHookResult = ReturnType<typeof useAddPipelineModuleNetworkImageObjectDetectionMutation>;
export type AddPipelineModuleNetworkImageObjectDetectionMutationResult = Apollo.MutationResult<AddPipelineModuleNetworkImageObjectDetectionMutation>;
export type AddPipelineModuleNetworkImageObjectDetectionMutationOptions = Apollo.BaseMutationOptions<AddPipelineModuleNetworkImageObjectDetectionMutation, AddPipelineModuleNetworkImageObjectDetectionMutationVariables>;
export const AddPipelineModuleNetworkImageSegmentationDocument = gql`
    mutation AddPipelineModuleNetworkImageSegmentation($input: AddPipelineModuleNetworkImageSegmentationInput!) {
  addPipelineModuleNetworkImageSegmentation(input: $input) {
    moduleId
    datasetId
  }
}
    `;
export type AddPipelineModuleNetworkImageSegmentationMutationFn = Apollo.MutationFunction<AddPipelineModuleNetworkImageSegmentationMutation, AddPipelineModuleNetworkImageSegmentationMutationVariables>;

/**
 * __useAddPipelineModuleNetworkImageSegmentationMutation__
 *
 * To run a mutation, you first call `useAddPipelineModuleNetworkImageSegmentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPipelineModuleNetworkImageSegmentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPipelineModuleNetworkImageSegmentationMutation, { data, loading, error }] = useAddPipelineModuleNetworkImageSegmentationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPipelineModuleNetworkImageSegmentationMutation(baseOptions?: Apollo.MutationHookOptions<AddPipelineModuleNetworkImageSegmentationMutation, AddPipelineModuleNetworkImageSegmentationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPipelineModuleNetworkImageSegmentationMutation, AddPipelineModuleNetworkImageSegmentationMutationVariables>(AddPipelineModuleNetworkImageSegmentationDocument, options);
      }
export type AddPipelineModuleNetworkImageSegmentationMutationHookResult = ReturnType<typeof useAddPipelineModuleNetworkImageSegmentationMutation>;
export type AddPipelineModuleNetworkImageSegmentationMutationResult = Apollo.MutationResult<AddPipelineModuleNetworkImageSegmentationMutation>;
export type AddPipelineModuleNetworkImageSegmentationMutationOptions = Apollo.BaseMutationOptions<AddPipelineModuleNetworkImageSegmentationMutation, AddPipelineModuleNetworkImageSegmentationMutationVariables>;
export const AddPretrainedNetworkDocument = gql`
    mutation AddPretrainedNetwork($input: AddPretrainedNetworkInput!) {
  addPretrainedNetwork(input: $input) {
    pretrainedNetworkId
  }
}
    `;
export type AddPretrainedNetworkMutationFn = Apollo.MutationFunction<AddPretrainedNetworkMutation, AddPretrainedNetworkMutationVariables>;

/**
 * __useAddPretrainedNetworkMutation__
 *
 * To run a mutation, you first call `useAddPretrainedNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPretrainedNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPretrainedNetworkMutation, { data, loading, error }] = useAddPretrainedNetworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPretrainedNetworkMutation(baseOptions?: Apollo.MutationHookOptions<AddPretrainedNetworkMutation, AddPretrainedNetworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPretrainedNetworkMutation, AddPretrainedNetworkMutationVariables>(AddPretrainedNetworkDocument, options);
      }
export type AddPretrainedNetworkMutationHookResult = ReturnType<typeof useAddPretrainedNetworkMutation>;
export type AddPretrainedNetworkMutationResult = Apollo.MutationResult<AddPretrainedNetworkMutation>;
export type AddPretrainedNetworkMutationOptions = Apollo.BaseMutationOptions<AddPretrainedNetworkMutation, AddPretrainedNetworkMutationVariables>;
export const AddImageSegmentationMarkupDocument = gql`
    mutation AddImageSegmentationMarkup($input: AddImageSegmentationMarkupInput!) {
  addImageSegmentationMarkup(input: $input) {
    markupId
  }
}
    `;
export type AddImageSegmentationMarkupMutationFn = Apollo.MutationFunction<AddImageSegmentationMarkupMutation, AddImageSegmentationMarkupMutationVariables>;

/**
 * __useAddImageSegmentationMarkupMutation__
 *
 * To run a mutation, you first call `useAddImageSegmentationMarkupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddImageSegmentationMarkupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addImageSegmentationMarkupMutation, { data, loading, error }] = useAddImageSegmentationMarkupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddImageSegmentationMarkupMutation(baseOptions?: Apollo.MutationHookOptions<AddImageSegmentationMarkupMutation, AddImageSegmentationMarkupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddImageSegmentationMarkupMutation, AddImageSegmentationMarkupMutationVariables>(AddImageSegmentationMarkupDocument, options);
      }
export type AddImageSegmentationMarkupMutationHookResult = ReturnType<typeof useAddImageSegmentationMarkupMutation>;
export type AddImageSegmentationMarkupMutationResult = Apollo.MutationResult<AddImageSegmentationMarkupMutation>;
export type AddImageSegmentationMarkupMutationOptions = Apollo.BaseMutationOptions<AddImageSegmentationMarkupMutation, AddImageSegmentationMarkupMutationVariables>;
export const AddUserDocument = gql`
    mutation AddUser($input: AddUserInput!) {
  addUser(input: $input) {
    username
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const CreateTokenDocument = gql`
    mutation CreateToken($input: CreateTokenInput!) {
  createToken(input: $input) {
    token
    allowedFeatures
    blockedFeatures
  }
}
    `;
export type CreateTokenMutationFn = Apollo.MutationFunction<CreateTokenMutation, CreateTokenMutationVariables>;

/**
 * __useCreateTokenMutation__
 *
 * To run a mutation, you first call `useCreateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTokenMutation, { data, loading, error }] = useCreateTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateTokenMutation, CreateTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTokenMutation, CreateTokenMutationVariables>(CreateTokenDocument, options);
      }
export type CreateTokenMutationHookResult = ReturnType<typeof useCreateTokenMutation>;
export type CreateTokenMutationResult = Apollo.MutationResult<CreateTokenMutation>;
export type CreateTokenMutationOptions = Apollo.BaseMutationOptions<CreateTokenMutation, CreateTokenMutationVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    token
    role
    allowedFeatures
    blockedFeatures
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RenewTokenDocument = gql`
    mutation RenewToken {
  renewToken {
    token
  }
}
    `;
export type RenewTokenMutationFn = Apollo.MutationFunction<RenewTokenMutation, RenewTokenMutationVariables>;

/**
 * __useRenewTokenMutation__
 *
 * To run a mutation, you first call `useRenewTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewTokenMutation, { data, loading, error }] = useRenewTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRenewTokenMutation(baseOptions?: Apollo.MutationHookOptions<RenewTokenMutation, RenewTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenewTokenMutation, RenewTokenMutationVariables>(RenewTokenDocument, options);
      }
export type RenewTokenMutationHookResult = ReturnType<typeof useRenewTokenMutation>;
export type RenewTokenMutationResult = Apollo.MutationResult<RenewTokenMutation>;
export type RenewTokenMutationOptions = Apollo.BaseMutationOptions<RenewTokenMutation, RenewTokenMutationVariables>;
export const BackupDocument = gql`
    mutation Backup {
  backup {
    code
    message
    taskId
  }
}
    `;
export type BackupMutationFn = Apollo.MutationFunction<BackupMutation, BackupMutationVariables>;

/**
 * __useBackupMutation__
 *
 * To run a mutation, you first call `useBackupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backupMutation, { data, loading, error }] = useBackupMutation({
 *   variables: {
 *   },
 * });
 */
export function useBackupMutation(baseOptions?: Apollo.MutationHookOptions<BackupMutation, BackupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BackupMutation, BackupMutationVariables>(BackupDocument, options);
      }
export type BackupMutationHookResult = ReturnType<typeof useBackupMutation>;
export type BackupMutationResult = Apollo.MutationResult<BackupMutation>;
export type BackupMutationOptions = Apollo.BaseMutationOptions<BackupMutation, BackupMutationVariables>;
export const RestoreDocument = gql`
    mutation Restore($input: RestoreInput!) {
  restore(input: $input) {
    success
  }
}
    `;
export type RestoreMutationFn = Apollo.MutationFunction<RestoreMutation, RestoreMutationVariables>;

/**
 * __useRestoreMutation__
 *
 * To run a mutation, you first call `useRestoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreMutation, { data, loading, error }] = useRestoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreMutation(baseOptions?: Apollo.MutationHookOptions<RestoreMutation, RestoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreMutation, RestoreMutationVariables>(RestoreDocument, options);
      }
export type RestoreMutationHookResult = ReturnType<typeof useRestoreMutation>;
export type RestoreMutationResult = Apollo.MutationResult<RestoreMutation>;
export type RestoreMutationOptions = Apollo.BaseMutationOptions<RestoreMutation, RestoreMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    success
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CopyDatasetDocument = gql`
    mutation CopyDataset($input: CopyDatasetInput!) {
  copyDataset(input: $input) {
    notificationId
  }
}
    `;
export type CopyDatasetMutationFn = Apollo.MutationFunction<CopyDatasetMutation, CopyDatasetMutationVariables>;

/**
 * __useCopyDatasetMutation__
 *
 * To run a mutation, you first call `useCopyDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyDatasetMutation, { data, loading, error }] = useCopyDatasetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyDatasetMutation(baseOptions?: Apollo.MutationHookOptions<CopyDatasetMutation, CopyDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyDatasetMutation, CopyDatasetMutationVariables>(CopyDatasetDocument, options);
      }
export type CopyDatasetMutationHookResult = ReturnType<typeof useCopyDatasetMutation>;
export type CopyDatasetMutationResult = Apollo.MutationResult<CopyDatasetMutation>;
export type CopyDatasetMutationOptions = Apollo.BaseMutationOptions<CopyDatasetMutation, CopyDatasetMutationVariables>;
export const CopyFolderDocument = gql`
    mutation CopyFolder($input: CopyFolderInput!) {
  copyFolder(input: $input) {
    notificationId
  }
}
    `;
export type CopyFolderMutationFn = Apollo.MutationFunction<CopyFolderMutation, CopyFolderMutationVariables>;

/**
 * __useCopyFolderMutation__
 *
 * To run a mutation, you first call `useCopyFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyFolderMutation, { data, loading, error }] = useCopyFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyFolderMutation(baseOptions?: Apollo.MutationHookOptions<CopyFolderMutation, CopyFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyFolderMutation, CopyFolderMutationVariables>(CopyFolderDocument, options);
      }
export type CopyFolderMutationHookResult = ReturnType<typeof useCopyFolderMutation>;
export type CopyFolderMutationResult = Apollo.MutationResult<CopyFolderMutation>;
export type CopyFolderMutationOptions = Apollo.BaseMutationOptions<CopyFolderMutation, CopyFolderMutationVariables>;
export const DeleteBackupDocument = gql`
    mutation DeleteBackup($input: DeleteBackupInput!) {
  deleteBackup(input: $input) {
    success
  }
}
    `;
export type DeleteBackupMutationFn = Apollo.MutationFunction<DeleteBackupMutation, DeleteBackupMutationVariables>;

/**
 * __useDeleteBackupMutation__
 *
 * To run a mutation, you first call `useDeleteBackupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBackupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBackupMutation, { data, loading, error }] = useDeleteBackupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBackupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBackupMutation, DeleteBackupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBackupMutation, DeleteBackupMutationVariables>(DeleteBackupDocument, options);
      }
export type DeleteBackupMutationHookResult = ReturnType<typeof useDeleteBackupMutation>;
export type DeleteBackupMutationResult = Apollo.MutationResult<DeleteBackupMutation>;
export type DeleteBackupMutationOptions = Apollo.BaseMutationOptions<DeleteBackupMutation, DeleteBackupMutationVariables>;
export const DeleteClassificationMarkupDocument = gql`
    mutation DeleteClassificationMarkup($input: DeleteClassificationMarkupInput!) {
  deleteClassificationMarkup(input: $input) {
    success
  }
}
    `;
export type DeleteClassificationMarkupMutationFn = Apollo.MutationFunction<DeleteClassificationMarkupMutation, DeleteClassificationMarkupMutationVariables>;

/**
 * __useDeleteClassificationMarkupMutation__
 *
 * To run a mutation, you first call `useDeleteClassificationMarkupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassificationMarkupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassificationMarkupMutation, { data, loading, error }] = useDeleteClassificationMarkupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClassificationMarkupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassificationMarkupMutation, DeleteClassificationMarkupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassificationMarkupMutation, DeleteClassificationMarkupMutationVariables>(DeleteClassificationMarkupDocument, options);
      }
export type DeleteClassificationMarkupMutationHookResult = ReturnType<typeof useDeleteClassificationMarkupMutation>;
export type DeleteClassificationMarkupMutationResult = Apollo.MutationResult<DeleteClassificationMarkupMutation>;
export type DeleteClassificationMarkupMutationOptions = Apollo.BaseMutationOptions<DeleteClassificationMarkupMutation, DeleteClassificationMarkupMutationVariables>;
export const DeleteDatasetDocument = gql`
    mutation DeleteDataset($input: DeleteDatasetInput!) {
  deleteDataset(input: $input) {
    notificationId
  }
}
    `;
export type DeleteDatasetMutationFn = Apollo.MutationFunction<DeleteDatasetMutation, DeleteDatasetMutationVariables>;

/**
 * __useDeleteDatasetMutation__
 *
 * To run a mutation, you first call `useDeleteDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDatasetMutation, { data, loading, error }] = useDeleteDatasetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDatasetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDatasetMutation, DeleteDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDatasetMutation, DeleteDatasetMutationVariables>(DeleteDatasetDocument, options);
      }
export type DeleteDatasetMutationHookResult = ReturnType<typeof useDeleteDatasetMutation>;
export type DeleteDatasetMutationResult = Apollo.MutationResult<DeleteDatasetMutation>;
export type DeleteDatasetMutationOptions = Apollo.BaseMutationOptions<DeleteDatasetMutation, DeleteDatasetMutationVariables>;
export const DeleteFolderDocument = gql`
    mutation DeleteFolder($input: DeleteFolderInput!) {
  deleteFolder(input: $input) {
    notificationId
  }
}
    `;
export type DeleteFolderMutationFn = Apollo.MutationFunction<DeleteFolderMutation, DeleteFolderMutationVariables>;

/**
 * __useDeleteFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderMutation, { data, loading, error }] = useDeleteFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFolderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFolderMutation, DeleteFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFolderMutation, DeleteFolderMutationVariables>(DeleteFolderDocument, options);
      }
export type DeleteFolderMutationHookResult = ReturnType<typeof useDeleteFolderMutation>;
export type DeleteFolderMutationResult = Apollo.MutationResult<DeleteFolderMutation>;
export type DeleteFolderMutationOptions = Apollo.BaseMutationOptions<DeleteFolderMutation, DeleteFolderMutationVariables>;
export const DeleteObjectDetectionMarkupDocument = gql`
    mutation DeleteObjectDetectionMarkup($input: DeleteObjectDetectionMarkupInput!) {
  deleteObjectDetectionMarkup(input: $input) {
    success
  }
}
    `;
export type DeleteObjectDetectionMarkupMutationFn = Apollo.MutationFunction<DeleteObjectDetectionMarkupMutation, DeleteObjectDetectionMarkupMutationVariables>;

/**
 * __useDeleteObjectDetectionMarkupMutation__
 *
 * To run a mutation, you first call `useDeleteObjectDetectionMarkupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteObjectDetectionMarkupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteObjectDetectionMarkupMutation, { data, loading, error }] = useDeleteObjectDetectionMarkupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteObjectDetectionMarkupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteObjectDetectionMarkupMutation, DeleteObjectDetectionMarkupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteObjectDetectionMarkupMutation, DeleteObjectDetectionMarkupMutationVariables>(DeleteObjectDetectionMarkupDocument, options);
      }
export type DeleteObjectDetectionMarkupMutationHookResult = ReturnType<typeof useDeleteObjectDetectionMarkupMutation>;
export type DeleteObjectDetectionMarkupMutationResult = Apollo.MutationResult<DeleteObjectDetectionMarkupMutation>;
export type DeleteObjectDetectionMarkupMutationOptions = Apollo.BaseMutationOptions<DeleteObjectDetectionMarkupMutation, DeleteObjectDetectionMarkupMutationVariables>;
export const DeletePipelineModuleDocument = gql`
    mutation DeletePipelineModule($input: DeletePipelineModuleInput!) {
  deletePipelineModule(input: $input) {
    notificationId
  }
}
    `;
export type DeletePipelineModuleMutationFn = Apollo.MutationFunction<DeletePipelineModuleMutation, DeletePipelineModuleMutationVariables>;

/**
 * __useDeletePipelineModuleMutation__
 *
 * To run a mutation, you first call `useDeletePipelineModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePipelineModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePipelineModuleMutation, { data, loading, error }] = useDeletePipelineModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePipelineModuleMutation(baseOptions?: Apollo.MutationHookOptions<DeletePipelineModuleMutation, DeletePipelineModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePipelineModuleMutation, DeletePipelineModuleMutationVariables>(DeletePipelineModuleDocument, options);
      }
export type DeletePipelineModuleMutationHookResult = ReturnType<typeof useDeletePipelineModuleMutation>;
export type DeletePipelineModuleMutationResult = Apollo.MutationResult<DeletePipelineModuleMutation>;
export type DeletePipelineModuleMutationOptions = Apollo.BaseMutationOptions<DeletePipelineModuleMutation, DeletePipelineModuleMutationVariables>;
export const DeletePipelineModuleEdgeDocument = gql`
    mutation DeletePipelineModuleEdge($input: DeletePipelineModuleEdgeInput!) {
  deletePipelineModuleEdge(input: $input) {
    success
  }
}
    `;
export type DeletePipelineModuleEdgeMutationFn = Apollo.MutationFunction<DeletePipelineModuleEdgeMutation, DeletePipelineModuleEdgeMutationVariables>;

/**
 * __useDeletePipelineModuleEdgeMutation__
 *
 * To run a mutation, you first call `useDeletePipelineModuleEdgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePipelineModuleEdgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePipelineModuleEdgeMutation, { data, loading, error }] = useDeletePipelineModuleEdgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePipelineModuleEdgeMutation(baseOptions?: Apollo.MutationHookOptions<DeletePipelineModuleEdgeMutation, DeletePipelineModuleEdgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePipelineModuleEdgeMutation, DeletePipelineModuleEdgeMutationVariables>(DeletePipelineModuleEdgeDocument, options);
      }
export type DeletePipelineModuleEdgeMutationHookResult = ReturnType<typeof useDeletePipelineModuleEdgeMutation>;
export type DeletePipelineModuleEdgeMutationResult = Apollo.MutationResult<DeletePipelineModuleEdgeMutation>;
export type DeletePipelineModuleEdgeMutationOptions = Apollo.BaseMutationOptions<DeletePipelineModuleEdgeMutation, DeletePipelineModuleEdgeMutationVariables>;
export const DeletePretrainedNetworkDocument = gql`
    mutation DeletePretrainedNetwork($input: DeletePretrainedNetworkInput!) {
  deletePretrainedNetwork(input: $input) {
    success
  }
}
    `;
export type DeletePretrainedNetworkMutationFn = Apollo.MutationFunction<DeletePretrainedNetworkMutation, DeletePretrainedNetworkMutationVariables>;

/**
 * __useDeletePretrainedNetworkMutation__
 *
 * To run a mutation, you first call `useDeletePretrainedNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePretrainedNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePretrainedNetworkMutation, { data, loading, error }] = useDeletePretrainedNetworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePretrainedNetworkMutation(baseOptions?: Apollo.MutationHookOptions<DeletePretrainedNetworkMutation, DeletePretrainedNetworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePretrainedNetworkMutation, DeletePretrainedNetworkMutationVariables>(DeletePretrainedNetworkDocument, options);
      }
export type DeletePretrainedNetworkMutationHookResult = ReturnType<typeof useDeletePretrainedNetworkMutation>;
export type DeletePretrainedNetworkMutationResult = Apollo.MutationResult<DeletePretrainedNetworkMutation>;
export type DeletePretrainedNetworkMutationOptions = Apollo.BaseMutationOptions<DeletePretrainedNetworkMutation, DeletePretrainedNetworkMutationVariables>;
export const DeleteResearchBoxDocument = gql`
    mutation DeleteResearchBox($input: DeleteResearchBoxInput!) {
  deleteResearchBox(input: $input) {
    notificationId
  }
}
    `;
export type DeleteResearchBoxMutationFn = Apollo.MutationFunction<DeleteResearchBoxMutation, DeleteResearchBoxMutationVariables>;

/**
 * __useDeleteResearchBoxMutation__
 *
 * To run a mutation, you first call `useDeleteResearchBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResearchBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResearchBoxMutation, { data, loading, error }] = useDeleteResearchBoxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteResearchBoxMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResearchBoxMutation, DeleteResearchBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResearchBoxMutation, DeleteResearchBoxMutationVariables>(DeleteResearchBoxDocument, options);
      }
export type DeleteResearchBoxMutationHookResult = ReturnType<typeof useDeleteResearchBoxMutation>;
export type DeleteResearchBoxMutationResult = Apollo.MutationResult<DeleteResearchBoxMutation>;
export type DeleteResearchBoxMutationOptions = Apollo.BaseMutationOptions<DeleteResearchBoxMutation, DeleteResearchBoxMutationVariables>;
export const DeleteSegmentationMarkupDocument = gql`
    mutation DeleteSegmentationMarkup($input: DeleteSegmentationMarkupInput!) {
  deleteSegmentationMarkup(input: $input) {
    success
  }
}
    `;
export type DeleteSegmentationMarkupMutationFn = Apollo.MutationFunction<DeleteSegmentationMarkupMutation, DeleteSegmentationMarkupMutationVariables>;

/**
 * __useDeleteSegmentationMarkupMutation__
 *
 * To run a mutation, you first call `useDeleteSegmentationMarkupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSegmentationMarkupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSegmentationMarkupMutation, { data, loading, error }] = useDeleteSegmentationMarkupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSegmentationMarkupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSegmentationMarkupMutation, DeleteSegmentationMarkupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSegmentationMarkupMutation, DeleteSegmentationMarkupMutationVariables>(DeleteSegmentationMarkupDocument, options);
      }
export type DeleteSegmentationMarkupMutationHookResult = ReturnType<typeof useDeleteSegmentationMarkupMutation>;
export type DeleteSegmentationMarkupMutationResult = Apollo.MutationResult<DeleteSegmentationMarkupMutation>;
export type DeleteSegmentationMarkupMutationOptions = Apollo.BaseMutationOptions<DeleteSegmentationMarkupMutation, DeleteSegmentationMarkupMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    notificationId
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const SetMarkupSubsetDocument = gql`
    mutation SetMarkupSubset($input: SetMarkupSubsetInput!) {
  setMarkupSubset(input: $input) {
    success
  }
}
    `;
export type SetMarkupSubsetMutationFn = Apollo.MutationFunction<SetMarkupSubsetMutation, SetMarkupSubsetMutationVariables>;

/**
 * __useSetMarkupSubsetMutation__
 *
 * To run a mutation, you first call `useSetMarkupSubsetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMarkupSubsetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMarkupSubsetMutation, { data, loading, error }] = useSetMarkupSubsetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMarkupSubsetMutation(baseOptions?: Apollo.MutationHookOptions<SetMarkupSubsetMutation, SetMarkupSubsetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMarkupSubsetMutation, SetMarkupSubsetMutationVariables>(SetMarkupSubsetDocument, options);
      }
export type SetMarkupSubsetMutationHookResult = ReturnType<typeof useSetMarkupSubsetMutation>;
export type SetMarkupSubsetMutationResult = Apollo.MutationResult<SetMarkupSubsetMutation>;
export type SetMarkupSubsetMutationOptions = Apollo.BaseMutationOptions<SetMarkupSubsetMutation, SetMarkupSubsetMutationVariables>;
export const MoveFolderDocument = gql`
    mutation MoveFolder($input: MoveFolderInput!) {
  moveFolder(input: $input) {
    success
  }
}
    `;
export type MoveFolderMutationFn = Apollo.MutationFunction<MoveFolderMutation, MoveFolderMutationVariables>;

/**
 * __useMoveFolderMutation__
 *
 * To run a mutation, you first call `useMoveFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveFolderMutation, { data, loading, error }] = useMoveFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveFolderMutation(baseOptions?: Apollo.MutationHookOptions<MoveFolderMutation, MoveFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveFolderMutation, MoveFolderMutationVariables>(MoveFolderDocument, options);
      }
export type MoveFolderMutationHookResult = ReturnType<typeof useMoveFolderMutation>;
export type MoveFolderMutationResult = Apollo.MutationResult<MoveFolderMutation>;
export type MoveFolderMutationOptions = Apollo.BaseMutationOptions<MoveFolderMutation, MoveFolderMutationVariables>;
export const MoveResearchBoxDocument = gql`
    mutation MoveResearchBox($input: MoveResearchBoxInput!) {
  moveResearchBox(input: $input) {
    success
  }
}
    `;
export type MoveResearchBoxMutationFn = Apollo.MutationFunction<MoveResearchBoxMutation, MoveResearchBoxMutationVariables>;

/**
 * __useMoveResearchBoxMutation__
 *
 * To run a mutation, you first call `useMoveResearchBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveResearchBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveResearchBoxMutation, { data, loading, error }] = useMoveResearchBoxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveResearchBoxMutation(baseOptions?: Apollo.MutationHookOptions<MoveResearchBoxMutation, MoveResearchBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveResearchBoxMutation, MoveResearchBoxMutationVariables>(MoveResearchBoxDocument, options);
      }
export type MoveResearchBoxMutationHookResult = ReturnType<typeof useMoveResearchBoxMutation>;
export type MoveResearchBoxMutationResult = Apollo.MutationResult<MoveResearchBoxMutation>;
export type MoveResearchBoxMutationOptions = Apollo.BaseMutationOptions<MoveResearchBoxMutation, MoveResearchBoxMutationVariables>;
export const AddProgressNotificationDocument = gql`
    mutation AddProgressNotification($input: AddProgressNotificationInput!) {
  addProgressNotification(input: $input) {
    notificationId
  }
}
    `;
export type AddProgressNotificationMutationFn = Apollo.MutationFunction<AddProgressNotificationMutation, AddProgressNotificationMutationVariables>;

/**
 * __useAddProgressNotificationMutation__
 *
 * To run a mutation, you first call `useAddProgressNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProgressNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProgressNotificationMutation, { data, loading, error }] = useAddProgressNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProgressNotificationMutation(baseOptions?: Apollo.MutationHookOptions<AddProgressNotificationMutation, AddProgressNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProgressNotificationMutation, AddProgressNotificationMutationVariables>(AddProgressNotificationDocument, options);
      }
export type AddProgressNotificationMutationHookResult = ReturnType<typeof useAddProgressNotificationMutation>;
export type AddProgressNotificationMutationResult = Apollo.MutationResult<AddProgressNotificationMutation>;
export type AddProgressNotificationMutationOptions = Apollo.BaseMutationOptions<AddProgressNotificationMutation, AddProgressNotificationMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation DeleteNotification($input: DeleteNotificationInput!) {
  deleteNotification(input: $input) {
    success
  }
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, options);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export const MarkNotificationAsReadDocument = gql`
    mutation MarkNotificationAsRead($input: MarkNotificationAsReadInput!) {
  markNotificationAsRead(input: $input) {
    success
  }
}
    `;
export type MarkNotificationAsReadMutationFn = Apollo.MutationFunction<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;

/**
 * __useMarkNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReadMutation, { data, loading, error }] = useMarkNotificationAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkNotificationAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>(MarkNotificationAsReadDocument, options);
      }
export type MarkNotificationAsReadMutationHookResult = ReturnType<typeof useMarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationResult = Apollo.MutationResult<MarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;
export const PublishPipelineModuleDocument = gql`
    mutation PublishPipelineModule($input: PublishPipelineModuleInput!) {
  publishPipelineModule(input: $input) {
    success
  }
}
    `;
export type PublishPipelineModuleMutationFn = Apollo.MutationFunction<PublishPipelineModuleMutation, PublishPipelineModuleMutationVariables>;

/**
 * __usePublishPipelineModuleMutation__
 *
 * To run a mutation, you first call `usePublishPipelineModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPipelineModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPipelineModuleMutation, { data, loading, error }] = usePublishPipelineModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishPipelineModuleMutation(baseOptions?: Apollo.MutationHookOptions<PublishPipelineModuleMutation, PublishPipelineModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishPipelineModuleMutation, PublishPipelineModuleMutationVariables>(PublishPipelineModuleDocument, options);
      }
export type PublishPipelineModuleMutationHookResult = ReturnType<typeof usePublishPipelineModuleMutation>;
export type PublishPipelineModuleMutationResult = Apollo.MutationResult<PublishPipelineModuleMutation>;
export type PublishPipelineModuleMutationOptions = Apollo.BaseMutationOptions<PublishPipelineModuleMutation, PublishPipelineModuleMutationVariables>;
export const StartSyncDocument = gql`
    mutation StartSync {
  startSync {
    success
  }
}
    `;
export type StartSyncMutationFn = Apollo.MutationFunction<StartSyncMutation, StartSyncMutationVariables>;

/**
 * __useStartSyncMutation__
 *
 * To run a mutation, you first call `useStartSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSyncMutation, { data, loading, error }] = useStartSyncMutation({
 *   variables: {
 *   },
 * });
 */
export function useStartSyncMutation(baseOptions?: Apollo.MutationHookOptions<StartSyncMutation, StartSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSyncMutation, StartSyncMutationVariables>(StartSyncDocument, options);
      }
export type StartSyncMutationHookResult = ReturnType<typeof useStartSyncMutation>;
export type StartSyncMutationResult = Apollo.MutationResult<StartSyncMutation>;
export type StartSyncMutationOptions = Apollo.BaseMutationOptions<StartSyncMutation, StartSyncMutationVariables>;
export const StartInferenceDocument = gql`
    mutation StartInference($input: StartInferenceInput!) {
  startInference(input: $input) {
    denkjobId
  }
}
    `;
export type StartInferenceMutationFn = Apollo.MutationFunction<StartInferenceMutation, StartInferenceMutationVariables>;

/**
 * __useStartInferenceMutation__
 *
 * To run a mutation, you first call `useStartInferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartInferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startInferenceMutation, { data, loading, error }] = useStartInferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartInferenceMutation(baseOptions?: Apollo.MutationHookOptions<StartInferenceMutation, StartInferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartInferenceMutation, StartInferenceMutationVariables>(StartInferenceDocument, options);
      }
export type StartInferenceMutationHookResult = ReturnType<typeof useStartInferenceMutation>;
export type StartInferenceMutationResult = Apollo.MutationResult<StartInferenceMutation>;
export type StartInferenceMutationOptions = Apollo.BaseMutationOptions<StartInferenceMutation, StartInferenceMutationVariables>;
export const StopInferenceDocument = gql`
    mutation StopInference($input: StopInferenceInput!) {
  stopInference(input: $input) {
    success
  }
}
    `;
export type StopInferenceMutationFn = Apollo.MutationFunction<StopInferenceMutation, StopInferenceMutationVariables>;

/**
 * __useStopInferenceMutation__
 *
 * To run a mutation, you first call `useStopInferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopInferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopInferenceMutation, { data, loading, error }] = useStopInferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopInferenceMutation(baseOptions?: Apollo.MutationHookOptions<StopInferenceMutation, StopInferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopInferenceMutation, StopInferenceMutationVariables>(StopInferenceDocument, options);
      }
export type StopInferenceMutationHookResult = ReturnType<typeof useStopInferenceMutation>;
export type StopInferenceMutationResult = Apollo.MutationResult<StopInferenceMutation>;
export type StopInferenceMutationOptions = Apollo.BaseMutationOptions<StopInferenceMutation, StopInferenceMutationVariables>;
export const ResetNetworkSnapshotDocument = gql`
    mutation resetNetworkSnapshot($input: ResetNetworkSnapshotInput!) {
  resetNetworkSnapshot(input: $input) {
    success
  }
}
    `;
export type ResetNetworkSnapshotMutationFn = Apollo.MutationFunction<ResetNetworkSnapshotMutation, ResetNetworkSnapshotMutationVariables>;

/**
 * __useResetNetworkSnapshotMutation__
 *
 * To run a mutation, you first call `useResetNetworkSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetNetworkSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetNetworkSnapshotMutation, { data, loading, error }] = useResetNetworkSnapshotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetNetworkSnapshotMutation(baseOptions?: Apollo.MutationHookOptions<ResetNetworkSnapshotMutation, ResetNetworkSnapshotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetNetworkSnapshotMutation, ResetNetworkSnapshotMutationVariables>(ResetNetworkSnapshotDocument, options);
      }
export type ResetNetworkSnapshotMutationHookResult = ReturnType<typeof useResetNetworkSnapshotMutation>;
export type ResetNetworkSnapshotMutationResult = Apollo.MutationResult<ResetNetworkSnapshotMutation>;
export type ResetNetworkSnapshotMutationOptions = Apollo.BaseMutationOptions<ResetNetworkSnapshotMutation, ResetNetworkSnapshotMutationVariables>;
export const StartTrainingDocument = gql`
    mutation StartTraining($input: StartTrainingInput!) {
  startTraining(input: $input) {
    denkjobId
  }
}
    `;
export type StartTrainingMutationFn = Apollo.MutationFunction<StartTrainingMutation, StartTrainingMutationVariables>;

/**
 * __useStartTrainingMutation__
 *
 * To run a mutation, you first call `useStartTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTrainingMutation, { data, loading, error }] = useStartTrainingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartTrainingMutation(baseOptions?: Apollo.MutationHookOptions<StartTrainingMutation, StartTrainingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartTrainingMutation, StartTrainingMutationVariables>(StartTrainingDocument, options);
      }
export type StartTrainingMutationHookResult = ReturnType<typeof useStartTrainingMutation>;
export type StartTrainingMutationResult = Apollo.MutationResult<StartTrainingMutation>;
export type StartTrainingMutationOptions = Apollo.BaseMutationOptions<StartTrainingMutation, StartTrainingMutationVariables>;
export const StopTrainingDocument = gql`
    mutation StopTraining($input: StopTrainingInput!) {
  stopTraining(input: $input) {
    success
  }
}
    `;
export type StopTrainingMutationFn = Apollo.MutationFunction<StopTrainingMutation, StopTrainingMutationVariables>;

/**
 * __useStopTrainingMutation__
 *
 * To run a mutation, you first call `useStopTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopTrainingMutation, { data, loading, error }] = useStopTrainingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopTrainingMutation(baseOptions?: Apollo.MutationHookOptions<StopTrainingMutation, StopTrainingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopTrainingMutation, StopTrainingMutationVariables>(StopTrainingDocument, options);
      }
export type StopTrainingMutationHookResult = ReturnType<typeof useStopTrainingMutation>;
export type StopTrainingMutationResult = Apollo.MutationResult<StopTrainingMutation>;
export type StopTrainingMutationOptions = Apollo.BaseMutationOptions<StopTrainingMutation, StopTrainingMutationVariables>;
export const UpdateDatasetDocument = gql`
    mutation UpdateDataset($input: UpdateDatasetInput!) {
  updateDataset(input: $input) {
    success
  }
}
    `;
export type UpdateDatasetMutationFn = Apollo.MutationFunction<UpdateDatasetMutation, UpdateDatasetMutationVariables>;

/**
 * __useUpdateDatasetMutation__
 *
 * To run a mutation, you first call `useUpdateDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDatasetMutation, { data, loading, error }] = useUpdateDatasetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDatasetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDatasetMutation, UpdateDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDatasetMutation, UpdateDatasetMutationVariables>(UpdateDatasetDocument, options);
      }
export type UpdateDatasetMutationHookResult = ReturnType<typeof useUpdateDatasetMutation>;
export type UpdateDatasetMutationResult = Apollo.MutationResult<UpdateDatasetMutation>;
export type UpdateDatasetMutationOptions = Apollo.BaseMutationOptions<UpdateDatasetMutation, UpdateDatasetMutationVariables>;
export const UpdateFolderDocument = gql`
    mutation UpdateFolder($input: UpdateFolderInput!) {
  updateFolder(input: $input) {
    success
  }
}
    `;
export type UpdateFolderMutationFn = Apollo.MutationFunction<UpdateFolderMutation, UpdateFolderMutationVariables>;

/**
 * __useUpdateFolderMutation__
 *
 * To run a mutation, you first call `useUpdateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderMutation, { data, loading, error }] = useUpdateFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFolderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFolderMutation, UpdateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFolderMutation, UpdateFolderMutationVariables>(UpdateFolderDocument, options);
      }
export type UpdateFolderMutationHookResult = ReturnType<typeof useUpdateFolderMutation>;
export type UpdateFolderMutationResult = Apollo.MutationResult<UpdateFolderMutation>;
export type UpdateFolderMutationOptions = Apollo.BaseMutationOptions<UpdateFolderMutation, UpdateFolderMutationVariables>;
export const UpdatePipelineModuleCcCheckerDocument = gql`
    mutation UpdatePipelineModuleCCChecker($input: UpdatePipelineModuleCCCheckerInput!) {
  updatePipelineModuleCCChecker(input: $input) {
    success
  }
}
    `;
export type UpdatePipelineModuleCcCheckerMutationFn = Apollo.MutationFunction<UpdatePipelineModuleCcCheckerMutation, UpdatePipelineModuleCcCheckerMutationVariables>;

/**
 * __useUpdatePipelineModuleCcCheckerMutation__
 *
 * To run a mutation, you first call `useUpdatePipelineModuleCcCheckerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePipelineModuleCcCheckerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePipelineModuleCcCheckerMutation, { data, loading, error }] = useUpdatePipelineModuleCcCheckerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePipelineModuleCcCheckerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePipelineModuleCcCheckerMutation, UpdatePipelineModuleCcCheckerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePipelineModuleCcCheckerMutation, UpdatePipelineModuleCcCheckerMutationVariables>(UpdatePipelineModuleCcCheckerDocument, options);
      }
export type UpdatePipelineModuleCcCheckerMutationHookResult = ReturnType<typeof useUpdatePipelineModuleCcCheckerMutation>;
export type UpdatePipelineModuleCcCheckerMutationResult = Apollo.MutationResult<UpdatePipelineModuleCcCheckerMutation>;
export type UpdatePipelineModuleCcCheckerMutationOptions = Apollo.BaseMutationOptions<UpdatePipelineModuleCcCheckerMutation, UpdatePipelineModuleCcCheckerMutationVariables>;
export const UpdatePipelineModuleContrastEnhancementDocument = gql`
    mutation UpdatePipelineModuleContrastEnhancement($input: UpdatePipelineModuleContrastEnhancementInput!) {
  updatePipelineModuleContrastEnhancement(input: $input) {
    success
  }
}
    `;
export type UpdatePipelineModuleContrastEnhancementMutationFn = Apollo.MutationFunction<UpdatePipelineModuleContrastEnhancementMutation, UpdatePipelineModuleContrastEnhancementMutationVariables>;

/**
 * __useUpdatePipelineModuleContrastEnhancementMutation__
 *
 * To run a mutation, you first call `useUpdatePipelineModuleContrastEnhancementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePipelineModuleContrastEnhancementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePipelineModuleContrastEnhancementMutation, { data, loading, error }] = useUpdatePipelineModuleContrastEnhancementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePipelineModuleContrastEnhancementMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePipelineModuleContrastEnhancementMutation, UpdatePipelineModuleContrastEnhancementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePipelineModuleContrastEnhancementMutation, UpdatePipelineModuleContrastEnhancementMutationVariables>(UpdatePipelineModuleContrastEnhancementDocument, options);
      }
export type UpdatePipelineModuleContrastEnhancementMutationHookResult = ReturnType<typeof useUpdatePipelineModuleContrastEnhancementMutation>;
export type UpdatePipelineModuleContrastEnhancementMutationResult = Apollo.MutationResult<UpdatePipelineModuleContrastEnhancementMutation>;
export type UpdatePipelineModuleContrastEnhancementMutationOptions = Apollo.BaseMutationOptions<UpdatePipelineModuleContrastEnhancementMutation, UpdatePipelineModuleContrastEnhancementMutationVariables>;
export const UpdatePipelineModuleNetworkImageClassificationDocument = gql`
    mutation UpdatePipelineModuleNetworkImageClassification($input: UpdatePipelineModuleNetworkImageClassificationInput!) {
  updatePipelineModuleNetworkImageClassification(input: $input) {
    success
  }
}
    `;
export type UpdatePipelineModuleNetworkImageClassificationMutationFn = Apollo.MutationFunction<UpdatePipelineModuleNetworkImageClassificationMutation, UpdatePipelineModuleNetworkImageClassificationMutationVariables>;

/**
 * __useUpdatePipelineModuleNetworkImageClassificationMutation__
 *
 * To run a mutation, you first call `useUpdatePipelineModuleNetworkImageClassificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePipelineModuleNetworkImageClassificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePipelineModuleNetworkImageClassificationMutation, { data, loading, error }] = useUpdatePipelineModuleNetworkImageClassificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePipelineModuleNetworkImageClassificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePipelineModuleNetworkImageClassificationMutation, UpdatePipelineModuleNetworkImageClassificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePipelineModuleNetworkImageClassificationMutation, UpdatePipelineModuleNetworkImageClassificationMutationVariables>(UpdatePipelineModuleNetworkImageClassificationDocument, options);
      }
export type UpdatePipelineModuleNetworkImageClassificationMutationHookResult = ReturnType<typeof useUpdatePipelineModuleNetworkImageClassificationMutation>;
export type UpdatePipelineModuleNetworkImageClassificationMutationResult = Apollo.MutationResult<UpdatePipelineModuleNetworkImageClassificationMutation>;
export type UpdatePipelineModuleNetworkImageClassificationMutationOptions = Apollo.BaseMutationOptions<UpdatePipelineModuleNetworkImageClassificationMutation, UpdatePipelineModuleNetworkImageClassificationMutationVariables>;
export const UpdatePipelineModuleNetworkImageObjectDetectionDocument = gql`
    mutation UpdatePipelineModuleNetworkImageObjectDetection($input: UpdatePipelineModuleNetworkImageObjectDetectionInput!) {
  updatePipelineModuleNetworkImageObjectDetection(input: $input) {
    success
  }
}
    `;
export type UpdatePipelineModuleNetworkImageObjectDetectionMutationFn = Apollo.MutationFunction<UpdatePipelineModuleNetworkImageObjectDetectionMutation, UpdatePipelineModuleNetworkImageObjectDetectionMutationVariables>;

/**
 * __useUpdatePipelineModuleNetworkImageObjectDetectionMutation__
 *
 * To run a mutation, you first call `useUpdatePipelineModuleNetworkImageObjectDetectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePipelineModuleNetworkImageObjectDetectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePipelineModuleNetworkImageObjectDetectionMutation, { data, loading, error }] = useUpdatePipelineModuleNetworkImageObjectDetectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePipelineModuleNetworkImageObjectDetectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePipelineModuleNetworkImageObjectDetectionMutation, UpdatePipelineModuleNetworkImageObjectDetectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePipelineModuleNetworkImageObjectDetectionMutation, UpdatePipelineModuleNetworkImageObjectDetectionMutationVariables>(UpdatePipelineModuleNetworkImageObjectDetectionDocument, options);
      }
export type UpdatePipelineModuleNetworkImageObjectDetectionMutationHookResult = ReturnType<typeof useUpdatePipelineModuleNetworkImageObjectDetectionMutation>;
export type UpdatePipelineModuleNetworkImageObjectDetectionMutationResult = Apollo.MutationResult<UpdatePipelineModuleNetworkImageObjectDetectionMutation>;
export type UpdatePipelineModuleNetworkImageObjectDetectionMutationOptions = Apollo.BaseMutationOptions<UpdatePipelineModuleNetworkImageObjectDetectionMutation, UpdatePipelineModuleNetworkImageObjectDetectionMutationVariables>;
export const UpdatePipelineModuleNetworkImageSegmentationDocument = gql`
    mutation UpdatePipelineModuleNetworkImageSegmentation($input: UpdatePipelineModuleNetworkImageSegmentationInput!) {
  updatePipelineModuleNetworkImageSegmentation(input: $input) {
    success
  }
}
    `;
export type UpdatePipelineModuleNetworkImageSegmentationMutationFn = Apollo.MutationFunction<UpdatePipelineModuleNetworkImageSegmentationMutation, UpdatePipelineModuleNetworkImageSegmentationMutationVariables>;

/**
 * __useUpdatePipelineModuleNetworkImageSegmentationMutation__
 *
 * To run a mutation, you first call `useUpdatePipelineModuleNetworkImageSegmentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePipelineModuleNetworkImageSegmentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePipelineModuleNetworkImageSegmentationMutation, { data, loading, error }] = useUpdatePipelineModuleNetworkImageSegmentationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePipelineModuleNetworkImageSegmentationMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePipelineModuleNetworkImageSegmentationMutation, UpdatePipelineModuleNetworkImageSegmentationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePipelineModuleNetworkImageSegmentationMutation, UpdatePipelineModuleNetworkImageSegmentationMutationVariables>(UpdatePipelineModuleNetworkImageSegmentationDocument, options);
      }
export type UpdatePipelineModuleNetworkImageSegmentationMutationHookResult = ReturnType<typeof useUpdatePipelineModuleNetworkImageSegmentationMutation>;
export type UpdatePipelineModuleNetworkImageSegmentationMutationResult = Apollo.MutationResult<UpdatePipelineModuleNetworkImageSegmentationMutation>;
export type UpdatePipelineModuleNetworkImageSegmentationMutationOptions = Apollo.BaseMutationOptions<UpdatePipelineModuleNetworkImageSegmentationMutation, UpdatePipelineModuleNetworkImageSegmentationMutationVariables>;
export const UpdatePipelineModulePositionDocument = gql`
    mutation UpdatePipelineModulePosition($input: UpdatePipelineModulePositionInput!) {
  updatePipelineModulePosition(input: $input) {
    success
  }
}
    `;
export type UpdatePipelineModulePositionMutationFn = Apollo.MutationFunction<UpdatePipelineModulePositionMutation, UpdatePipelineModulePositionMutationVariables>;

/**
 * __useUpdatePipelineModulePositionMutation__
 *
 * To run a mutation, you first call `useUpdatePipelineModulePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePipelineModulePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePipelineModulePositionMutation, { data, loading, error }] = useUpdatePipelineModulePositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePipelineModulePositionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePipelineModulePositionMutation, UpdatePipelineModulePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePipelineModulePositionMutation, UpdatePipelineModulePositionMutationVariables>(UpdatePipelineModulePositionDocument, options);
      }
export type UpdatePipelineModulePositionMutationHookResult = ReturnType<typeof useUpdatePipelineModulePositionMutation>;
export type UpdatePipelineModulePositionMutationResult = Apollo.MutationResult<UpdatePipelineModulePositionMutation>;
export type UpdatePipelineModulePositionMutationOptions = Apollo.BaseMutationOptions<UpdatePipelineModulePositionMutation, UpdatePipelineModulePositionMutationVariables>;
export const UpdateResearchBoxDocument = gql`
    mutation UpdateResearchBox($input: UpdateResearchBoxInput!) {
  updateResearchBox(input: $input) {
    success
  }
}
    `;
export type UpdateResearchBoxMutationFn = Apollo.MutationFunction<UpdateResearchBoxMutation, UpdateResearchBoxMutationVariables>;

/**
 * __useUpdateResearchBoxMutation__
 *
 * To run a mutation, you first call `useUpdateResearchBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResearchBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResearchBoxMutation, { data, loading, error }] = useUpdateResearchBoxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResearchBoxMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResearchBoxMutation, UpdateResearchBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResearchBoxMutation, UpdateResearchBoxMutationVariables>(UpdateResearchBoxDocument, options);
      }
export type UpdateResearchBoxMutationHookResult = ReturnType<typeof useUpdateResearchBoxMutation>;
export type UpdateResearchBoxMutationResult = Apollo.MutationResult<UpdateResearchBoxMutation>;
export type UpdateResearchBoxMutationOptions = Apollo.BaseMutationOptions<UpdateResearchBoxMutation, UpdateResearchBoxMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    success
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($input: UploadFileInput!) {
  uploadFile(input: $input) {
    id
    name
    message
    success
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export type AddImageClassificationMarkupMutationVariables = Exact<{
  input: AddImageClassificationMarkupInput;
}>;


export type AddImageClassificationMarkupMutation = (
  { __typename?: 'Mutation' }
  & { addImageClassificationMarkup?: Maybe<(
    { __typename?: 'AddImageClassificationMarkupPayload' }
    & Pick<AddImageClassificationMarkupPayload, 'markupId'>
  )> }
);

export type AddDatasetMutationVariables = Exact<{
  input: AddDatasetInput;
}>;


export type AddDatasetMutation = (
  { __typename?: 'Mutation' }
  & { addDataset?: Maybe<(
    { __typename?: 'AddDatasetPayload' }
    & Pick<AddDatasetPayload, 'datasetId' | 'pipelineId' | 'imageOutputLabelId'>
  )> }
);

export type AddFolderMutationVariables = Exact<{
  input: AddFolderInput;
}>;


export type AddFolderMutation = (
  { __typename?: 'Mutation' }
  & { addFolder?: Maybe<(
    { __typename?: 'AddFolderPayload' }
    & Pick<AddFolderPayload, 'folderId'>
  )> }
);

export type AddImageMetadataFieldMutationVariables = Exact<{
  input: AddImageMetadataFieldInput;
}>;


export type AddImageMetadataFieldMutation = (
  { __typename?: 'Mutation' }
  & { addImageMetadataField?: Maybe<(
    { __typename?: 'AddImageMetadataFieldPayload' }
    & Pick<AddImageMetadataFieldPayload, 'imageMetadataFieldId'>
  )> }
);

export type AddImageObjectDetectionMarkupMutationVariables = Exact<{
  input: AddImageObjectDetectionMarkupInput;
}>;


export type AddImageObjectDetectionMarkupMutation = (
  { __typename?: 'Mutation' }
  & { addImageObjectDetectionMarkup?: Maybe<(
    { __typename?: 'AddImageObjectDetectionMarkupPayload' }
    & Pick<AddImageObjectDetectionMarkupPayload, 'markupId'>
  )> }
);

export type AddPipelineModuleCcCheckerMutationVariables = Exact<{
  input: AddPipelineModuleCcCheckerInput;
}>;


export type AddPipelineModuleCcCheckerMutation = (
  { __typename?: 'Mutation' }
  & { addPipelineModuleCCChecker?: Maybe<(
    { __typename?: 'AddPipelineModuleCCCheckerPayload' }
    & Pick<AddPipelineModuleCcCheckerPayload, 'moduleId'>
  )> }
);

export type AddPipelineModuleContrastEnhancementMutationVariables = Exact<{
  input: AddPipelineModuleContrastEnhancementInput;
}>;


export type AddPipelineModuleContrastEnhancementMutation = (
  { __typename?: 'Mutation' }
  & { addPipelineModuleContrastEnhancement?: Maybe<(
    { __typename?: 'AddPipelineModuleContrastEnhancementPayload' }
    & Pick<AddPipelineModuleContrastEnhancementPayload, 'moduleId'>
  )> }
);

export type AddPipelineModuleEdgeMutationVariables = Exact<{
  input: AddPipelineModuleEdgeInput;
}>;


export type AddPipelineModuleEdgeMutation = (
  { __typename?: 'Mutation' }
  & { addPipelineModuleEdge?: Maybe<(
    { __typename?: 'AddPipelineModuleEdgePayload' }
    & Pick<AddPipelineModuleEdgePayload, 'edgeId'>
  )> }
);

export type AddPipelineModuleJobRequestCcCheckerMutationVariables = Exact<{
  input: AddPipelineModuleJobRequestCcCheckerInput;
}>;


export type AddPipelineModuleJobRequestCcCheckerMutation = (
  { __typename?: 'Mutation' }
  & { addPipelineModuleJobRequestCCChecker?: Maybe<(
    { __typename?: 'AddPipelineModuleJobRequestCCCheckerPayload' }
    & Pick<AddPipelineModuleJobRequestCcCheckerPayload, 'pipelineModuleJobRequestId'>
  )> }
);

export type AddPipelineModuleJobRequestContrastEnhancementMutationVariables = Exact<{
  input: AddPipelineModuleJobRequestContrastEnhancementInput;
}>;


export type AddPipelineModuleJobRequestContrastEnhancementMutation = (
  { __typename?: 'Mutation' }
  & { addPipelineModuleJobRequestContrastEnhancement?: Maybe<(
    { __typename?: 'AddPipelineModuleJobRequestContrastEnhancementPayload' }
    & Pick<AddPipelineModuleJobRequestContrastEnhancementPayload, 'pipelineModuleJobRequestId'>
  )> }
);

export type AddPipelineModuleNetworkImageClassificationMutationVariables = Exact<{
  input: AddPipelineModuleNetworkImageClassificationInput;
}>;


export type AddPipelineModuleNetworkImageClassificationMutation = (
  { __typename?: 'Mutation' }
  & { addPipelineModuleNetworkImageClassification?: Maybe<(
    { __typename?: 'AddPipelineModuleNetworkImageClassificationPayload' }
    & Pick<AddPipelineModuleNetworkImageClassificationPayload, 'moduleId' | 'datasetId'>
  )> }
);

export type AddPipelineModuleNetworkImageObjectDetectionMutationVariables = Exact<{
  input: AddPipelineModuleNetworkImageObjectDetectionInput;
}>;


export type AddPipelineModuleNetworkImageObjectDetectionMutation = (
  { __typename?: 'Mutation' }
  & { addPipelineModuleNetworkImageObjectDetection?: Maybe<(
    { __typename?: 'AddPipelineModuleNetworkImageObjectDetectionPayload' }
    & Pick<AddPipelineModuleNetworkImageObjectDetectionPayload, 'moduleId' | 'datasetId'>
  )> }
);

export type AddPipelineModuleNetworkImageSegmentationMutationVariables = Exact<{
  input: AddPipelineModuleNetworkImageSegmentationInput;
}>;


export type AddPipelineModuleNetworkImageSegmentationMutation = (
  { __typename?: 'Mutation' }
  & { addPipelineModuleNetworkImageSegmentation?: Maybe<(
    { __typename?: 'AddPipelineModuleNetworkImageSegmentationPayload' }
    & Pick<AddPipelineModuleNetworkImageSegmentationPayload, 'moduleId' | 'datasetId'>
  )> }
);

export type AddPretrainedNetworkMutationVariables = Exact<{
  input: AddPretrainedNetworkInput;
}>;


export type AddPretrainedNetworkMutation = (
  { __typename?: 'Mutation' }
  & { addPretrainedNetwork?: Maybe<(
    { __typename?: 'AddPretrainedNetworkPayload' }
    & Pick<AddPretrainedNetworkPayload, 'pretrainedNetworkId'>
  )> }
);

export type AddImageSegmentationMarkupMutationVariables = Exact<{
  input: AddImageSegmentationMarkupInput;
}>;


export type AddImageSegmentationMarkupMutation = (
  { __typename?: 'Mutation' }
  & { addImageSegmentationMarkup?: Maybe<(
    { __typename?: 'AddImageSegmentationMarkupPayload' }
    & Pick<AddImageSegmentationMarkupPayload, 'markupId'>
  )> }
);

export type AddUserMutationVariables = Exact<{
  input: AddUserInput;
}>;


export type AddUserMutation = (
  { __typename?: 'Mutation' }
  & { addUser?: Maybe<(
    { __typename?: 'AddUserPayload' }
    & Pick<AddUserPayload, 'username'>
  )> }
);

export type CreateTokenMutationVariables = Exact<{
  input: CreateTokenInput;
}>;


export type CreateTokenMutation = (
  { __typename?: 'Mutation' }
  & { createToken?: Maybe<(
    { __typename?: 'CreateTokenPayload' }
    & Pick<CreateTokenPayload, 'token' | 'allowedFeatures' | 'blockedFeatures'>
  )> }
);

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginPayload' }
    & Pick<LoginPayload, 'token' | 'role' | 'allowedFeatures' | 'blockedFeatures'>
  )> }
);

export type RenewTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RenewTokenMutation = (
  { __typename?: 'Mutation' }
  & { renewToken?: Maybe<(
    { __typename?: 'RenewTokenPayload' }
    & Pick<RenewTokenPayload, 'token'>
  )> }
);

export type BackupMutationVariables = Exact<{ [key: string]: never; }>;


export type BackupMutation = (
  { __typename?: 'Mutation' }
  & { backup?: Maybe<(
    { __typename?: 'BackupPayload' }
    & Pick<BackupPayload, 'code' | 'message' | 'taskId'>
  )> }
);

export type RestoreMutationVariables = Exact<{
  input: RestoreInput;
}>;


export type RestoreMutation = (
  { __typename?: 'Mutation' }
  & { restore?: Maybe<(
    { __typename?: 'RestorePayload' }
    & Pick<RestorePayload, 'success'>
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword?: Maybe<(
    { __typename?: 'ChangePasswordPayload' }
    & Pick<ChangePasswordPayload, 'success'>
  )> }
);

export type CopyDatasetMutationVariables = Exact<{
  input: CopyDatasetInput;
}>;


export type CopyDatasetMutation = (
  { __typename?: 'Mutation' }
  & { copyDataset?: Maybe<(
    { __typename?: 'CopyDatasetPayload' }
    & Pick<CopyDatasetPayload, 'notificationId'>
  )> }
);

export type CopyFolderMutationVariables = Exact<{
  input: CopyFolderInput;
}>;


export type CopyFolderMutation = (
  { __typename?: 'Mutation' }
  & { copyFolder?: Maybe<(
    { __typename?: 'CopyFolderPayload' }
    & Pick<CopyFolderPayload, 'notificationId'>
  )> }
);

export type DeleteBackupMutationVariables = Exact<{
  input: DeleteBackupInput;
}>;


export type DeleteBackupMutation = (
  { __typename?: 'Mutation' }
  & { deleteBackup?: Maybe<(
    { __typename?: 'DeleteBackupPayload' }
    & Pick<DeleteBackupPayload, 'success'>
  )> }
);

export type DeleteClassificationMarkupMutationVariables = Exact<{
  input: DeleteClassificationMarkupInput;
}>;


export type DeleteClassificationMarkupMutation = (
  { __typename?: 'Mutation' }
  & { deleteClassificationMarkup?: Maybe<(
    { __typename?: 'DeleteClassificationMarkupPayload' }
    & Pick<DeleteClassificationMarkupPayload, 'success'>
  )> }
);

export type DeleteDatasetMutationVariables = Exact<{
  input: DeleteDatasetInput;
}>;


export type DeleteDatasetMutation = (
  { __typename?: 'Mutation' }
  & { deleteDataset?: Maybe<(
    { __typename?: 'DeleteDatasetPayload' }
    & Pick<DeleteDatasetPayload, 'notificationId'>
  )> }
);

export type DeleteFolderMutationVariables = Exact<{
  input: DeleteFolderInput;
}>;


export type DeleteFolderMutation = (
  { __typename?: 'Mutation' }
  & { deleteFolder?: Maybe<(
    { __typename?: 'DeleteFolderPayload' }
    & Pick<DeleteFolderPayload, 'notificationId'>
  )> }
);

export type DeleteObjectDetectionMarkupMutationVariables = Exact<{
  input: DeleteObjectDetectionMarkupInput;
}>;


export type DeleteObjectDetectionMarkupMutation = (
  { __typename?: 'Mutation' }
  & { deleteObjectDetectionMarkup?: Maybe<(
    { __typename?: 'DeleteObjectDetectionMarkupPayload' }
    & Pick<DeleteObjectDetectionMarkupPayload, 'success'>
  )> }
);

export type DeletePipelineModuleMutationVariables = Exact<{
  input: DeletePipelineModuleInput;
}>;


export type DeletePipelineModuleMutation = (
  { __typename?: 'Mutation' }
  & { deletePipelineModule?: Maybe<(
    { __typename?: 'DeletePipelineModulePayload' }
    & Pick<DeletePipelineModulePayload, 'notificationId'>
  )> }
);

export type DeletePipelineModuleEdgeMutationVariables = Exact<{
  input: DeletePipelineModuleEdgeInput;
}>;


export type DeletePipelineModuleEdgeMutation = (
  { __typename?: 'Mutation' }
  & { deletePipelineModuleEdge?: Maybe<(
    { __typename?: 'DeletePipelineModuleEdgePayload' }
    & Pick<DeletePipelineModuleEdgePayload, 'success'>
  )> }
);

export type DeletePretrainedNetworkMutationVariables = Exact<{
  input: DeletePretrainedNetworkInput;
}>;


export type DeletePretrainedNetworkMutation = (
  { __typename?: 'Mutation' }
  & { deletePretrainedNetwork?: Maybe<(
    { __typename?: 'DeletePretrainedNetworkPayload' }
    & Pick<DeletePretrainedNetworkPayload, 'success'>
  )> }
);

export type DeleteResearchBoxMutationVariables = Exact<{
  input: DeleteResearchBoxInput;
}>;


export type DeleteResearchBoxMutation = (
  { __typename?: 'Mutation' }
  & { deleteResearchBox?: Maybe<(
    { __typename?: 'DeleteResearchBoxPayload' }
    & Pick<DeleteResearchBoxPayload, 'notificationId'>
  )> }
);

export type DeleteSegmentationMarkupMutationVariables = Exact<{
  input: DeleteSegmentationMarkupInput;
}>;


export type DeleteSegmentationMarkupMutation = (
  { __typename?: 'Mutation' }
  & { deleteSegmentationMarkup?: Maybe<(
    { __typename?: 'DeleteSegmentationMarkupPayload' }
    & Pick<DeleteSegmentationMarkupPayload, 'success'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'DeleteUserPayload' }
    & Pick<DeleteUserPayload, 'notificationId'>
  )> }
);

export type SetMarkupSubsetMutationVariables = Exact<{
  input: SetMarkupSubsetInput;
}>;


export type SetMarkupSubsetMutation = (
  { __typename?: 'Mutation' }
  & { setMarkupSubset?: Maybe<(
    { __typename?: 'SetMarkupSubsetPayload' }
    & Pick<SetMarkupSubsetPayload, 'success'>
  )> }
);

export type MoveFolderMutationVariables = Exact<{
  input: MoveFolderInput;
}>;


export type MoveFolderMutation = (
  { __typename?: 'Mutation' }
  & { moveFolder?: Maybe<(
    { __typename?: 'MoveFolderPayload' }
    & Pick<MoveFolderPayload, 'success'>
  )> }
);

export type MoveResearchBoxMutationVariables = Exact<{
  input: MoveResearchBoxInput;
}>;


export type MoveResearchBoxMutation = (
  { __typename?: 'Mutation' }
  & { moveResearchBox?: Maybe<(
    { __typename?: 'MoveResearchBoxPayload' }
    & Pick<MoveResearchBoxPayload, 'success'>
  )> }
);

export type AddProgressNotificationMutationVariables = Exact<{
  input: AddProgressNotificationInput;
}>;


export type AddProgressNotificationMutation = (
  { __typename?: 'Mutation' }
  & { addProgressNotification?: Maybe<(
    { __typename?: 'AddProgressNotificationPayload' }
    & Pick<AddProgressNotificationPayload, 'notificationId'>
  )> }
);

export type DeleteNotificationMutationVariables = Exact<{
  input: DeleteNotificationInput;
}>;


export type DeleteNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteNotification?: Maybe<(
    { __typename?: 'DeleteNotificationPayload' }
    & Pick<DeleteNotificationPayload, 'success'>
  )> }
);

export type MarkNotificationAsReadMutationVariables = Exact<{
  input: MarkNotificationAsReadInput;
}>;


export type MarkNotificationAsReadMutation = (
  { __typename?: 'Mutation' }
  & { markNotificationAsRead?: Maybe<(
    { __typename?: 'MarkNotificationAsReadPayload' }
    & Pick<MarkNotificationAsReadPayload, 'success'>
  )> }
);

export type PublishPipelineModuleMutationVariables = Exact<{
  input: PublishPipelineModuleInput;
}>;


export type PublishPipelineModuleMutation = (
  { __typename?: 'Mutation' }
  & { publishPipelineModule?: Maybe<(
    { __typename?: 'PublishPipelineModulePayload' }
    & Pick<PublishPipelineModulePayload, 'success'>
  )> }
);

export type StartSyncMutationVariables = Exact<{ [key: string]: never; }>;


export type StartSyncMutation = (
  { __typename?: 'Mutation' }
  & { startSync?: Maybe<(
    { __typename?: 'StartSyncPayload' }
    & Pick<StartSyncPayload, 'success'>
  )> }
);

export type StartInferenceMutationVariables = Exact<{
  input: StartInferenceInput;
}>;


export type StartInferenceMutation = (
  { __typename?: 'Mutation' }
  & { startInference?: Maybe<(
    { __typename?: 'StartInferencePayload' }
    & Pick<StartInferencePayload, 'denkjobId'>
  )> }
);

export type StopInferenceMutationVariables = Exact<{
  input: StopInferenceInput;
}>;


export type StopInferenceMutation = (
  { __typename?: 'Mutation' }
  & { stopInference?: Maybe<(
    { __typename?: 'StopInferencePayload' }
    & Pick<StopInferencePayload, 'success'>
  )> }
);

export type ResetNetworkSnapshotMutationVariables = Exact<{
  input: ResetNetworkSnapshotInput;
}>;


export type ResetNetworkSnapshotMutation = (
  { __typename?: 'Mutation' }
  & { resetNetworkSnapshot?: Maybe<(
    { __typename?: 'ResetNetworkSnapshotPayload' }
    & Pick<ResetNetworkSnapshotPayload, 'success'>
  )> }
);

export type StartTrainingMutationVariables = Exact<{
  input: StartTrainingInput;
}>;


export type StartTrainingMutation = (
  { __typename?: 'Mutation' }
  & { startTraining?: Maybe<(
    { __typename?: 'StartTrainingPayload' }
    & Pick<StartTrainingPayload, 'denkjobId'>
  )> }
);

export type StopTrainingMutationVariables = Exact<{
  input: StopTrainingInput;
}>;


export type StopTrainingMutation = (
  { __typename?: 'Mutation' }
  & { stopTraining?: Maybe<(
    { __typename?: 'StopTrainingPayload' }
    & Pick<StopTrainingPayload, 'success'>
  )> }
);

export type UpdateDatasetMutationVariables = Exact<{
  input: UpdateDatasetInput;
}>;


export type UpdateDatasetMutation = (
  { __typename?: 'Mutation' }
  & { updateDataset?: Maybe<(
    { __typename?: 'UpdateDatasetPayload' }
    & Pick<UpdateDatasetPayload, 'success'>
  )> }
);

export type UpdateFolderMutationVariables = Exact<{
  input: UpdateFolderInput;
}>;


export type UpdateFolderMutation = (
  { __typename?: 'Mutation' }
  & { updateFolder?: Maybe<(
    { __typename?: 'UpdateFolderPayload' }
    & Pick<UpdateFolderPayload, 'success'>
  )> }
);

export type UpdatePipelineModuleCcCheckerMutationVariables = Exact<{
  input: UpdatePipelineModuleCcCheckerInput;
}>;


export type UpdatePipelineModuleCcCheckerMutation = (
  { __typename?: 'Mutation' }
  & { updatePipelineModuleCCChecker?: Maybe<(
    { __typename?: 'UpdatePipelineModuleCCCheckerPayload' }
    & Pick<UpdatePipelineModuleCcCheckerPayload, 'success'>
  )> }
);

export type UpdatePipelineModuleContrastEnhancementMutationVariables = Exact<{
  input: UpdatePipelineModuleContrastEnhancementInput;
}>;


export type UpdatePipelineModuleContrastEnhancementMutation = (
  { __typename?: 'Mutation' }
  & { updatePipelineModuleContrastEnhancement?: Maybe<(
    { __typename?: 'UpdatePipelineModuleContrastEnhancementPayload' }
    & Pick<UpdatePipelineModuleContrastEnhancementPayload, 'success'>
  )> }
);

export type UpdatePipelineModuleNetworkImageClassificationMutationVariables = Exact<{
  input: UpdatePipelineModuleNetworkImageClassificationInput;
}>;


export type UpdatePipelineModuleNetworkImageClassificationMutation = (
  { __typename?: 'Mutation' }
  & { updatePipelineModuleNetworkImageClassification?: Maybe<(
    { __typename?: 'UpdatePipelineModuleNetworkImageClassificationPayload' }
    & Pick<UpdatePipelineModuleNetworkImageClassificationPayload, 'success'>
  )> }
);

export type UpdatePipelineModuleNetworkImageObjectDetectionMutationVariables = Exact<{
  input: UpdatePipelineModuleNetworkImageObjectDetectionInput;
}>;


export type UpdatePipelineModuleNetworkImageObjectDetectionMutation = (
  { __typename?: 'Mutation' }
  & { updatePipelineModuleNetworkImageObjectDetection?: Maybe<(
    { __typename?: 'UpdatePipelineModuleNetworkImageObjectDetectionPayload' }
    & Pick<UpdatePipelineModuleNetworkImageObjectDetectionPayload, 'success'>
  )> }
);

export type UpdatePipelineModuleNetworkImageSegmentationMutationVariables = Exact<{
  input: UpdatePipelineModuleNetworkImageSegmentationInput;
}>;


export type UpdatePipelineModuleNetworkImageSegmentationMutation = (
  { __typename?: 'Mutation' }
  & { updatePipelineModuleNetworkImageSegmentation?: Maybe<(
    { __typename?: 'UpdatePipelineModuleNetworkImageSegmentationPayload' }
    & Pick<UpdatePipelineModuleNetworkImageSegmentationPayload, 'success'>
  )> }
);

export type UpdatePipelineModulePositionMutationVariables = Exact<{
  input: UpdatePipelineModulePositionInput;
}>;


export type UpdatePipelineModulePositionMutation = (
  { __typename?: 'Mutation' }
  & { updatePipelineModulePosition?: Maybe<(
    { __typename?: 'UpdatePipelineModulePositionPayload' }
    & Pick<UpdatePipelineModulePositionPayload, 'success'>
  )> }
);

export type UpdateResearchBoxMutationVariables = Exact<{
  input: UpdateResearchBoxInput;
}>;


export type UpdateResearchBoxMutation = (
  { __typename?: 'Mutation' }
  & { updateResearchBox?: Maybe<(
    { __typename?: 'UpdateResearchBoxPayload' }
    & Pick<UpdateResearchBoxPayload, 'success'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & Pick<UpdateUserPayload, 'success'>
  )> }
);

export type UploadFileMutationVariables = Exact<{
  input: UploadFileInput;
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile?: Maybe<(
    { __typename?: 'UploadFilePayload' }
    & Pick<UploadFilePayload, 'id' | 'name' | 'message' | 'success'>
  )> }
);
