/* eslint-disable react/jsx-one-expression-per-line */
import { Handle, HandleType, Position } from "react-flow-renderer";
import { Label, Segment, Grid, SemanticWIDTHS } from "semantic-ui-react";
import { PipelineModule } from "../../ts-clients/query";

type Props = {
  data: PipelineModule;
};

type Config = {
  position: Position;
  type: string;
  bottom: undefined | number;
  top: undefined | number;
  handleColor: string;
};

type PipelineModuleIo = {
  id: string;
  name: string;
};

type IOType = "input" | "output";

const colors = [
  "#4ECDC4",
  "#556270",
  "#C7F464",
  "#FF6B6B",
  "#C44D58",
  "#4ECDC4",
];

const getConfig = (type: IOType): Config =>
  type === "input"
    ? {
        position: Position.Top,
        type: "target",
        bottom: undefined,
        top: -38,
        handleColor: "#059",
      }
    : {
        position: Position.Bottom,
        type: "source",
        bottom: -8,
        top: undefined,
        handleColor: "#958",
      };

const renderIOLabels = (os: PipelineModuleIo[]) => (
  <Grid
    columns={os.length as SemanticWIDTHS}
    style={{
      display: "flex",
      justifyContent: "space-evenly",
      textAlign: "center",
    }}
  >
    {os.map((o, i) => (
      <Grid.Column
        key={o.id}
        style={{
          padding: 0,
          height: "30px",
          lineHeight: "30px",
          overflow: "hidden",
          backgroundColor: colors[i % colors.length],
        }}
      >
        {o.name}
      </Grid.Column>
    ))}
  </Grid>
);

const renderHandle = (o: PipelineModuleIo, c: Config) => (
  <Handle
    key={o.id}
    type={c.type as HandleType}
    position={c.position}
    id={o.id}
    style={{
      width: 15,
      height: 15,
      top: c.top,
      bottom: c.bottom,
      background: c.handleColor,
    }}
  />
);

const renderIOHandleGrid = (os: PipelineModuleIo[], c: Config) => (
  <Grid
    columns={os.length as SemanticWIDTHS}
    style={{
      display: "flex",
      justifyContent: "space-evenly",
    }}
  >
    {os.map((o) => (
      <Grid.Column
        key={o.id}
        style={{
          padding: 0,
        }}
      >
        {renderHandle(o, c)}
      </Grid.Column>
    ))}
  </Grid>
);

const renderEmptyIO = (type: IOType) =>
  type === "input" ? (
    <Label attached="top" content="no inputs" />
  ) : (
    <Label attached="bottom" content="no outputs" />
  );

const renderIO = (os: PipelineModuleIo[], type: IOType) => {
  if (os.length === 0) {
    return renderEmptyIO(type);
  }
  return (
    <>
      {renderIOLabels(os)}
      {renderIOHandleGrid(os, getConfig(type))}
    </>
  );
};

const renderMainContent = (name: string) => (
  <>
    <br />
    <br />
    <div style={{ clear: "both" }} />
    {name}
    <br />
    <br />
    <br />
  </>
);

export default function PipelineModuleNode({ data }: Props) {
  const { inputs, outputs, name } = data;
  return (
    <Segment>
      {renderIO(inputs || [], "input")}
      {renderMainContent(name)}
      {renderIO(outputs || [], "output")}
    </Segment>
  );
}
