import { useState } from "react";
import { FolderType } from "../../../types/extractedTypes";
import useRenameFolder from "./useRenameFolder";

type ActiveModal = "rename" | "delete" | "copy" | "move" | "none";

export default function useFolderModifier(folder?: FolderType | null) {
  const [activeModal, setActiveModal] = useState<ActiveModal>("none");
  const [currentFolderName, setCurrentFolderName] = useState(folder?.name);

  const renameDefault = useRenameFolder(
    folder?.id || "",
    currentFolderName || ""
  );

  return {
    rename: renameDefault,
    setCurrentFolderName,
    currentFolderName,
    activeModal,
    setActiveModal,
  };
}
