import { DenkjobState } from "../../../../ts-clients/query";
import { DenkBoxNetworkState, InfoMessageId } from "./types";

export function getInfoMessages(
  networkState: DenkBoxNetworkState,
  hasSufficientData: boolean
) {
  const infoMessages: InfoMessageId[] = [];

  if (!hasSufficientData) {
    infoMessages.push("notEnoughData");
  }
  if (
    networkState.prediction === DenkjobState.Failed ||
    networkState.testing === DenkjobState.Failed ||
    networkState.training === DenkjobState.Failed
  ) {
    infoMessages.push("error");
  }

  if (
    networkState.prediction &&
    [
      DenkjobState.Running,
      DenkjobState.Starting,
      DenkjobState.Stopping,
    ].includes(networkState.prediction)
  ) {
    infoMessages.push("predictionRunning");
  }
  if (
    networkState.testing &&
    [
      DenkjobState.Running,
      DenkjobState.Starting,
      DenkjobState.Stopping,
    ].includes(networkState.testing)
  ) {
    infoMessages.push("testRunning");
  }
  if (
    networkState.training &&
    [
      DenkjobState.Running,
      DenkjobState.Starting,
      DenkjobState.Stopping,
    ].includes(networkState.training)
  ) {
    infoMessages.push("trainingRunning");
  }

  return infoMessages;
}
