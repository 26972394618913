import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";

function useLocalStorage<T>(
  key: string,
  initialValue: T | (() => T)
): [T, Dispatch<SetStateAction<T>>] {
  // Get from local storage then

  // parse stored json or return initialValue

  const readValue = () => {
    // Prevent build error "window is undefined" but keep keep working

    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);

      return initialValue;
    }
  };

  // State to store our value

  // Pass initial state function to useState so logic is only executed once

  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that ...

  // ... persists the new value to localStorage.

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (value) => {
      // Prevent build error "window is undefined" but keeps working

      if (typeof window === "undefined") {
        console.warn(
          `Tried setting localStorage key “${key}” even though environment is not a client`
        );
      }

      try {
        // Allow value to be a function so we have the same API as useState

        const newValue = value instanceof Function ? value(storedValue) : value;

        // Save to local storage

        window.localStorage.setItem(key, JSON.stringify(newValue));

        // Save state

        setStoredValue(newValue);

        // We dispatch a custom event so every useLocalStorage hook are notified

        window.dispatchEvent(new Event("local-storage"));
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, JSON.stringify(storedValue)]
  );

  useEffect(() => {
    setStoredValue(readValue());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue());
    };

    // this only works for other documents, not the current one

    window.addEventListener("storage", handleStorageChange);

    // this is a custom event, triggered in writeValueToLocalStorage

    window.addEventListener("local-storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);

      window.removeEventListener("local-storage", handleStorageChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memorizedStoredValue = useMemo(
    () => storedValue,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(storedValue)]
  );

  return [memorizedStoredValue, setValue];
}

export default useLocalStorage;
