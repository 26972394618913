import { useState } from "react";
import { Dataset } from "../types";
import useRenameDataset from "./useRenameDataset";

type ActiveModal = "rename" | "delete" | "copy" | "none";

export default function useDatasetModifier(dataset: Dataset) {
  const [activeModal, setActiveModal] = useState<ActiveModal>("none");
  const [currentDatasetName, setCurrentDatasetName] = useState(dataset.name);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renameDefault = useRenameDataset(dataset.id, currentDatasetName);

  return {
    rename: renameDefault,
    setCurrentDatasetName,
    currentDatasetName,
    activeModal,
    setActiveModal,
  };
}
