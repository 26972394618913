import { Vector2d } from "MarkupTypes";
import { useEffect, useState } from "react";
import { Circle } from "react-konva/lib/ReactKonvaCore";
import { MyPolygon } from "../../hooks/types";
import NewPolygon from "./lowlevel/NewPoly";
import DragDot from "./lowlevel/DragDot";
import { addVector, getPolygonConfig } from "./lowlevel/util";
import { hexToRgbString } from "../../../../../utils/pixeltools";

type Props = {
  c: MyPolygon;
  selectedMarkupLabelId?: string | null;
  labelColor: string;
  temporary?: boolean;
  select?: (id: string) => void;
  isSelected?: boolean;
  isHighlighted: boolean;
  update: (c: MyPolygon) => void;
  scale: number;
};

export default function Polygon({
  c,
  selectedMarkupLabelId = null,
  labelColor,
  temporary = false,
  select,
  isSelected = false,
  update,
  scale,
  isHighlighted,
}: Props) {
  const getConfig = (isMouseOver: boolean) =>
    getPolygonConfig(
      c.annotationType,
      "Polygon",
      isMouseOver,
      isSelected,
      isHighlighted,
      temporary,
      selectedMarkupLabelId === c.markupLabelId,
      labelColor,
      scale
    );

  const [isDragging, setDragging] = useState(false);
  const [myPoints, setMyPoints] = useState<Vector2d[]>([]);

  useEffect(() => {
    setMyPoints(c.points);
  }, [c.points]);

  const movePolygon = (dist: Vector2d) =>
    update({ ...c, points: c.points.map((p) => addVector(p, dist)) });

  const updatePoint = (idx: number) => (p: Vector2d) => {
    setMyPoints((ps) => {
      const newp = [...ps];
      newp.splice(idx, 1, p);
      return newp;
    });
  };

  const applyChanges = () => update({ ...c, points: myPoints });

  const config = getConfig(false);

  const selectHandler = () => {
    if (select) {
      select(c.id);
    }
  };

  return (
    <>
      <NewPolygon
        points={myPoints}
        getConfig={getConfig}
        select={selectHandler}
        isSelected={isSelected || isHighlighted}
        movePolygon={movePolygon}
        setDragging={setDragging}
      />
      {isSelected &&
        !isDragging &&
        myPoints.map((p, idx) => (
          <DragDot
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            position={p}
            dragTo={updatePoint(idx)}
            dragEnd={applyChanges}
            scale={scale}
          />
        ))}
      {temporary &&
        myPoints.map((p, idx) => (
          <Circle
            // eslint-disable-next-line react/no-array-index-key
            key={`${idx}_temp`}
            radius={3}
            x={p.x}
            y={p.y}
            fill={hexToRgbString(config.fillColor)}
          />
        ))}
    </>
  );
}

Polygon.defaultProps = {
  temporary: false,
  select: undefined,
  isSelected: false,
  selectedMarkupLabelId: null,
};
