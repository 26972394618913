import { canMutateNetwork } from "../../../../state/loginState";
import { useSelector } from "react-redux";
import { NetworkListParams } from "../../../../utils/routeManager";
import { useParams } from "react-router";
import { useState } from "react";
import useQueryNetworkDetailsForDataset from "../../../../hooks/useQueryNetworkDetailsForDataset";

import { getNiceModuleNameFromString } from "../../../flow-designer/shared";
import { stringIncludeFilter } from "../../util/tools";

export default function useNetworkList() {
  const { datasetId, menuType } = useParams<NetworkListParams>();
  const userCanMutateNetworks = useSelector(canMutateNetwork);
  const [filterString, setFilterString] = useState<string>("");

  const networks = useQueryNetworkDetailsForDataset(datasetId);

  const networksExist = networks.length > 0;

  const networksFiltered =
    networks?.filter(
      (network) =>
        stringIncludeFilter(network?.name || "", filterString) ||
        stringIncludeFilter(network?.description || "", filterString) ||
        stringIncludeFilter(
          getNiceModuleNameFromString(network?.__typename || ""),
          filterString
        ) ||
        network?.classLabels.some((l) =>
          stringIncludeFilter(l.name, filterString)
        )
    ) ?? [];

  return {
    userCanMutateNetworks,
    datasetId,
    menuType,
    filterString,
    setFilterString,
    networks: networksFiltered,
    networksExist,
  };
}
