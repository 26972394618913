import { Position, ModuleType } from "ModuleTypes";
import { useGetPipelineModuleTypeQuery } from "../../ts-clients/query";

const rndValue = (max: number) => Math.round(Math.random() * max);

export function getRandomCoordinates(): Position {
  return {
    x: rndValue(400),
    y: rndValue(200),
  };
}

export function useGetPipelineModuleType(moduleId: string): ModuleType {
  const unknownType = "unknown-type";
  const { data } = useGetPipelineModuleTypeQuery({
    variables: {
      id: moduleId,
    },
  });

  const foundModules = data?.queryPipelineModule ?? [];
  return foundModules.length === 0
    ? unknownType
    : foundModules[0]?.__typename ?? unknownType;
}
