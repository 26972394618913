import { Role } from "../../../ts-clients/command";

const roles = [
  {
    key: Role.Unauthorized,
    text: "Unauthorized",
    value: Role.Unauthorized,
  },
  {
    key: Role.Admin,
    text: "Admin",
    value: Role.Admin,
  },
  {
    key: Role.Tenant,
    text: "Tenant",
    value: Role.Tenant,
  },
  {
    key: Role.Vendor,
    text: "Vendor",
    value: Role.Vendor,
  },
];

export default roles;
