import { ImageSize, MarkupLabel } from "MarkupTypes";
import { useCallback, useEffect } from "react";
import useSaveVectorData from "./useSaveVectorData";
import useLoadVectorData from "./useLoadVectorData";
import useDeleteVectorData from "./useDeleteVectorData";
import useLabelList from "./useLabelList";

const thresholdForActiveLabel = 50;

export default function useVectorData(
  imageId: string,
  networkId: string,
  markupSize: ImageSize,
  labelList: MarkupLabel[]
) {
  const saveVectorData = useSaveVectorData(imageId, networkId, markupSize);
  const delVectorData = useDeleteVectorData();
  const { loadedVectorList, markupId, subset, lastPredictionTime } =
    useLoadVectorData(imageId, networkId, thresholdForActiveLabel, labelList);
  const { disableAll, enableAll, toggle, vector, setVector } = useLabelList();

  // const containsPredictions = useMemo(
  //   () => loadedVectorList.filter((v) => v.mode === "predicted").length > 0,
  //   [loadedVectorList]
  // );

  const saveVector = useCallback(
    () => saveVectorData(vector),
    [saveVectorData, vector]
  );

  const deleteVector = useCallback(
    () => delVectorData(markupId),
    [delVectorData, markupId]
  );

  useEffect(() => {
    setVector(loadedVectorList);
  }, [loadedVectorList, setVector]);

  return {
    saveVector,
    deleteVector,
    vector,
    toggle,
    enableAll,
    disableAll,
    markupId,
    lastPredictionTime,
    subset,
  };
}
