import MetricsTable from "./parts/MetricsTable";
import Stats from "./parts/Stats";
import MetricsChart from "./parts/MetricsChart";
import { DenkboxContext } from "../../../../../ctx/DenkboxProvider";
import { useContext } from "react";

export default function StatsTab() {
  const denkboxState = useContext(DenkboxContext);

  if (!denkboxState) {
    return <></>;
  }

  const { networkDetails } = denkboxState;

  const networkMetrics = networkDetails.metrics.filter((m) => !m.label);
  const metricsForChart = networkMetrics.map((m) => ({
    dash: "solid",
    name: m.name,
    history: JSON.parse(m.history) || [],
  }));

  return (
    <>
      <br />
      <MetricsChart metrics={metricsForChart} viewType="basic" />
      <MetricsTable metrics={networkMetrics} />
      <Stats stats={networkDetails.stats} />
    </>
  );
}
