import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLoggedIn } from "../state/loginState";

type Props<P> = {
  component: React.ComponentType<P>;
};

export default function PrivateRoute<P>({
  component: Component,
  ...rest
}: Props<P>): JSX.Element {
  const loggedIn = useSelector(isLoggedIn);
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? (
          // @ts-ignore
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}
