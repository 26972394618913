/* eslint-disable react/jsx-one-expression-per-line */
import ResearchBoxDetails from "../../features/details-researchbox";
import ResearchBoxListMenu from "./ResearchBoxListMenu";
import Center from "../../features/filemanagement/DatasetPositioner";
import { useParseRouteParams } from "../../utils/routeManager";
import MenuMain from "../../components/menus/menu.main";
import ResearchBoxProvider from "../../ctx/ResearchBoxProvider";

export default function ResearchBoxDetailsContainer() {
  const pageParams = useParseRouteParams("ResearchBoxDetails");
  return (
    <>
      <MenuMain />
      <ResearchBoxListMenu current="files" datasetId={pageParams.datasetId} />
      <Center>
        <ResearchBoxProvider>
          <ResearchBoxDetails pageParams={pageParams} />
        </ResearchBoxProvider>
      </Center>
    </>
  );
}
