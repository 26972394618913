import { useDispatch } from "react-redux";
import { clearLogin } from "../state/loginState";
import { useGoto } from "../utils/routeManager";

export default function useLogout() {
  const dispatch = useDispatch();
  const goTo = useGoto();

  const logoutNow = () => {
    dispatch(clearLogin());
    goTo("Login", {});
  };

  return logoutNow;
}
