/* eslint-disable import/no-cycle */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import appState from "./appState";
import tourState from "./tourState";
import researchBoxFilter from "./researchboxfilter";
import loginReducer from "./loginState";
import dataTypeDisplaySettings from "./dataTypeDisplaySettings";
import deviceControlReducer from "./deviceControl";
import uploadReducer from "./upload";
import datasetSettings from "./datasetSettings";
import segmentation2dState from "../features/details-researchbox/Markup/inputImage/redux-state";

export default function createRootReducer(history: History) {
  return combineReducers({
    appState,
    tourState,
    datasetSettings,
    dataTypeDisplaySettings,
    deviceControl: deviceControlReducer,
    login: loginReducer,
    researchBoxFilter,
    router: connectRouter(history),
    segmentation2dState,
    upload: uploadReducer,
  });
}
