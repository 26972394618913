/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { MarkupLabel } from "ModuleTypes";
import { CirclePicker, ChromePicker } from "react-color";
import { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  TextArea,
  Grid,
  Label,
  Dropdown,
  Popup,
} from "semantic-ui-react";

type DefaultProps = {
  label: string;
};

type MarkupLabelProps = DefaultProps & {
  content: MarkupLabel[];
  onChange: (value: MarkupLabel[]) => unknown;
  markFirstLabel?: boolean;
};

type StringProps = DefaultProps & {
  content: string;
  onChange: (value: string) => unknown;
  error?: boolean;
};

type StringArrayProps = DefaultProps & {
  content: string[];
  onChange: (value: string[]) => unknown;
};

type NumberProps = DefaultProps & {
  content: number;
  onChange: (value: number) => unknown;
};

type BooleanProps = DefaultProps & {
  content: boolean;
  onChange: (value: boolean) => unknown;
};

type MarkupLabelRowProps = {
  label: MarkupLabel;
  index: number;
  onLabelChange: (label: MarkupLabel) => unknown;
};

type IOSelectorProps = DefaultProps & {
  data: { id: string; label: string }[];
  content: string[];
  onChange: (id: string[]) => unknown;
  error?: boolean;
  disabled: boolean;
};

function ColorLabel(props: {
  label: MarkupLabel;
  currentColor: string;
  setCurrentColor: (color: string) => void;
  onLabelChange: (label: MarkupLabel) => void;
}) {
  const { label, currentColor, setCurrentColor, onLabelChange } = props;
  return (
    <Popup
      hoverable
      style={{
        padding: "0em",
      }}
      trigger={
        <Label
          tag
          disabled={false}
          style={{ backgroundColor: currentColor }}
          content="Color"
        />
      }
    >
      <ChromePicker
        color={currentColor}
        disableAlpha
        onChange={(v) => setCurrentColor(v.hex)}
        onChangeComplete={(v) => onLabelChange({ ...label, color: v.hex })}
      />
    </Popup>
  );
}

function MarkupLabelRow({ onLabelChange, label }: MarkupLabelRowProps) {
  const [currentColor, setCurrentColor] = useState(label.color);
  useEffect(() => {
    setCurrentColor(label.color);
  }, [label.color]);
  return (
    <Grid>
      <Grid.Column width="6">
        <Input
          onChange={(_, { value }) => onLabelChange({ ...label, name: value })}
          value={label.name}
          labelPosition="right"
          // disabled={index === 0 && labelFromName}
        >
          <input />
          <ColorLabel
            label={label}
            onLabelChange={onLabelChange}
            currentColor={currentColor}
            setCurrentColor={setCurrentColor}
          />
        </Input>
      </Grid.Column>
      <Grid.Column width="10" verticalAlign="middle">
        <div onMouseLeave={() => setCurrentColor(label.color)}>
          <CirclePicker
            color={label.color}
            circleSize={27}
            circleSpacing={10}
            onSwatchHover={(v) => setCurrentColor(v.hex)}
            width="700px"
            onChangeComplete={(v) => onLabelChange({ ...label, color: v.hex })}
          />
        </div>
      </Grid.Column>
    </Grid>
  );
}

export function IOSelect({
  content,
  data,
  onChange,
  label,
  error = false,
  disabled,
}: IOSelectorProps) {
  return (
    <Dropdown
      placeholder={label}
      fluid
      multiple
      disabled={disabled}
      selection
      error={error}
      value={content}
      onChange={(_, { value }) => onChange(value as string[])}
      options={data.map((c) => ({
        key: c.id,
        text: c.label,
        value: c.id,
      }))}
    />
  );
}

export function MarkupLabelInput({
  label,
  content,
  markFirstLabel = false,
  onChange,
}: MarkupLabelProps) {
  // useage of Object.assign and displaycontent is to
  // avoid mutating the content variable which is even more
  // undesirable

  const handleChange = (idx: number, lbl: MarkupLabel) => {
    onChange(Object.assign([], content, { [idx]: lbl }));
  };
  const [lastItem] = content.slice(-1);
  const displaycontent =
    !lastItem || (lastItem && lastItem.name.length > 0)
      ? [...content, { name: "", color: "" }]
      : content;

  return (
    <Form.Field error={markFirstLabel}>
      <label>{label}</label>
      {displaycontent.map((lbl, idx) => (
        <MarkupLabelRow
          key={idx}
          // @ts-ignore
          label={lbl}
          index={idx}
          onLabelChange={(l: MarkupLabel) => handleChange(idx, l)}
        />
      ))}
    </Form.Field>
  );
}

export function StringInput({
  label,
  content,
  onChange,
  error = false,
}: StringProps) {
  return (
    <Form.Field error={error}>
      <label>{label}</label>
      <Input
        key={label}
        onChange={(_, { value }) => onChange(value)}
        value={content}
      />
    </Form.Field>
  );
}

export function LongStringInput({
  label,
  content,
  onChange,
  error = false,
}: StringProps) {
  return (
    <Form.Field error={error}>
      <label>{label}</label>
      <TextArea
        key={label}
        onChange={(_, { value }) => onChange(value as string)}
        value={content}
      />
    </Form.Field>
  );
}

export function StringArrayInput({
  label,
  content,
  onChange,
}: StringArrayProps) {
  return (
    <Form.Field>
      <label>{label}</label>
      {content.map((c, idx) => (
        <Input
          key={label + idx}
          onChange={(_, { value }) => {
            const arr = content.slice();
            arr[idx] = value;
            onChange(arr);
          }}
          value={c}
          action={
            idx === content.length - 1 && (
              <Button
                content="add row"
                icon="add circle"
                onClick={() => {
                  const arr = content.slice();
                  arr.push("");
                  onChange(arr);
                }}
              />
            )
          }
        />
      ))}
    </Form.Field>
  );
}

export function IntInput({ label, content, onChange }: NumberProps) {
  return (
    <Form.Input
      key={label}
      type="number"
      label={label}
      onChange={(_, { value }) => onChange(parseInt(value, 10))}
      value={content}
    />
  );
}

export function FloatInput({ label, content, onChange }: NumberProps) {
  return (
    <Form.Input
      key={label}
      label={label}
      type="number"
      onChange={(_, { value }) => onChange(parseFloat(value))}
      value={content}
    />
  );
}

export function BooleanInput({ label, content, onChange }: BooleanProps) {
  return (
    <Form.Checkbox
      key={label}
      label={label}
      checked={content}
      onChange={(_, { checked }) => onChange(checked || false)}
    />
  );
}
