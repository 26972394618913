/* eslint-disable react/jsx-one-expression-per-line */
import { Link } from "react-router-dom";
import { Menu, Image } from "semantic-ui-react";
import SensorImport from "../../features/import-sensor";
import ResearchBoxListMenu from "./ResearchBoxListMenu";
import Center from "../../features/filemanagement/DatasetPositioner";
import { getPath, useParseRouteParams } from "../../utils/routeManager";
import denklight from "../../resources/denk-logo-light.svg";
import ViewSettings from "../../components/menus/settings-view/ForSensorImport";
import MenuMain from "../../components/menus/menu.main";

export default function SensorImportPage() {
  const pageParams = useParseRouteParams("SensorImport");

  return (
    <>
      <MenuMain />
      <Menu vertical fixed="left" inverted>
        <div>
          <Image
            src={denklight}
            height={26}
            style={{ margin: "45px 15px 50px 15px" }}
          />
        </div>
        <Link className="item" to={getPath("Dashboard", {})}>
          &#0171; Datasets
        </Link>
        <ResearchBoxListMenu current="files" datasetId={pageParams.datasetId} />
      </Menu>

      <Center>
        <SensorImport params={pageParams} />
      </Center>
      <Menu
        fixed="right"
        vertical
        style={{
          padding: "15px",
          marginTop: 40,
          backgroundColor: "#f4f4f4",
          width: "18rem",
        }}
      >
        <ViewSettings />
      </Menu>
    </>
  );
}
