import { Divider, Header } from "semantic-ui-react";
import ResolutionSelector from "../../../../../components/menus/settings-view/parts/ResolutionSelector";
import { ImageDatapointType } from "../../../../../types/extractedTypes";
import useImageInfo from "../../hooks/useImageInfo";

type Props = {
  image: ImageDatapointType;
  currentNetworkId: string;
};

export default function ImageInfoTab({ image, currentNetworkId }: Props) {
  const { created, hasMarkup, updated, name } = useImageInfo(currentNetworkId);

  return (
    <>
      <Header as="h4">Image</Header>
      <Divider />
      <p>
        <strong>Filename:</strong>
        <br />
        <span style={{ wordWrap: "break-word" }}>{name}</span>
      </p>
      <p>
        <strong>Created: {created.timeAgo}</strong>
        <br />
        {created.dateTime}
      </p>
      <p>
        <strong>Last Updated: {updated.timeAgo}</strong>
        <br />
        {updated.dateTime}
      </p>
      <p>
        <strong>State:</strong>
        <br />
        {hasMarkup ? "annotated for current network" : "not annotated"}
      </p>
      <Divider />
      {image && <ResolutionSelector resolutions={image.resolutions} />}
    </>
  );
}
