import { useState } from "react";
import { Vector2d } from "MarkupTypes";
import { KonvaEventObject } from "konva/lib/Node";
import { Line } from "react-konva/lib/ReactKonvaCore";
import { pointArrayToIntArray } from "../../../hooks/pointFunctions";
import { PolygonConfig } from "./util";

import { useGeneratePatternHook } from "../../../../../../wasm/image-tools/hooks/useGeneratePattern";
import { AnnotationType } from "../../../../../../ts-clients/command";

type Props = {
  points: Array<Vector2d>;
  getConfig: (highlighted: boolean) => PolygonConfig;
  select?: () => void;
  isSelected: boolean;
  movePolygon?: (dist: Vector2d) => void;
  open?: boolean;
  setDragging?: (b: boolean) => void;
};

export default function NewPoly({
  points,
  getConfig,
  select,
  isSelected,
  movePolygon,
  open,
  setDragging,
}: Props) {
  const [highlighted, setHighlighted] = useState(false);
  const goIn = () => setHighlighted(true);
  const goOut = () => setHighlighted(false);
  const config = getConfig(highlighted);

  const dragEnd = ({ target }: KonvaEventObject<DragEvent>) => {
    if (movePolygon !== undefined) {
      movePolygon({ x: target.x(), y: target.y() });
    }
    target.x(0);
    target.y(0);
    if (setDragging !== undefined) {
      setDragging(false);
    }
  };

  const dragStart = () => {
    if (setDragging !== undefined) {
      setDragging(true);
    }
  };

  const { image } = useGeneratePatternHook(
    config.isPrediction
      ? "AI"
      : config.type === AnnotationType.Negative
      ? "Negative"
      : "Ignore",
    config.fillColor
  );

  return (
    <Line
      closed={!open}
      dash={config.dash}
      draggable={isSelected && movePolygon !== undefined}
      fill={
        config.type === AnnotationType.Positive ? config.fillColor : undefined
      }
      fillPatternRepeat={
        config.type !== AnnotationType.Positive ? "repeat" : undefined
      }
      fillPatternImage={
        config.type !== AnnotationType.Positive ? image : undefined
      }
      fillPatternScale={
        config.type !== AnnotationType.Positive ? { x: 0.2, y: 0.2 } : undefined
      }
      globalCompositeOperation={config.composition}
      onClick={(evt) => {
        if (select) select();
        evt.cancelBubble = true;
      }}
      onDragEnd={dragEnd}
      onDragStart={dragStart}
      onMouseOut={goOut}
      onMouseOver={goIn}
      opacity={config.opacity}
      points={pointArrayToIntArray(points)}
      stroke={config.strokeColor}
      strokeWidth={config.strokeWidth}
      onFocus={() => {}}
      onBlur={() => {}}
    />
  );
}

NewPoly.defaultProps = {
  movePolygon: undefined,
  select: undefined,
  open: false,
  setDragging: () => {},
};
