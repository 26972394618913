/* eslint-disable react/jsx-curly-newline */
import { Form, Message } from "semantic-ui-react";
import useUIConfig, {
  MarkupToolIconStyle,
  ResearchBoxViewSelectMode,
} from "../../state/useUIConfig";

type IconOptions = {
  id: MarkupToolIconStyle;
  name: string;
};

type ResearchBoxViewOptions = {
  id: ResearchBoxViewSelectMode;
  name: string;
};

const markupToolIconStyleOptions: IconOptions[] = [
  { id: "big", name: "Icons only" },
  { id: "list", name: "Icon List with Names" },
];
const researchBoxViewOptions: ResearchBoxViewOptions[] = [
  { id: "selectFirst", name: "Always show overview" },
  { id: "selectSmart", name: "Try to select more useful page automatically." },
];

export default function ChangeUI() {
  const {
    getMarkupToolIconStyle,
    getResearchBoxViewSelect,
    setMarkupToolIconStyle,
    setResearchBoxViewSelect,
    getMarkupToolOneClickShapes,
    setMarkupToolOneClickShapes,
    getMarkupToolResetZoomOnDoubleClick,
    setMarkupToolResetZoomOnDoubleClick,
    getRememberLastUrl,
    setRememberLastUrl,
    getFileListZoomWithMouse,
    setFileListZoomWithMouse,
  } = useUIConfig();
  return (
    <Form>
      <Message
        content="Changes on this page take effect immediately, thus no save button."
        info
      />
      <Form.Dropdown
        label="Display Type of Tool Icons in the Markup Tool"
        selection
        value={getMarkupToolIconStyle()}
        options={markupToolIconStyleOptions.map((i) => ({
          key: i.id,
          text: i.name,
          value: i.id,
        }))}
        onChange={(_, { value }) =>
          setMarkupToolIconStyle(value as MarkupToolIconStyle)
        }
      />
      <Form.Dropdown
        label="Display Type of Tool Icons in the Markup Tool"
        selection
        value={getResearchBoxViewSelect()}
        options={researchBoxViewOptions.map((i) => ({
          key: i.id,
          text: i.name,
          value: i.id,
        }))}
        onChange={(_, { value }) =>
          setResearchBoxViewSelect(value as ResearchBoxViewSelectMode)
        }
      />

      <Form.Checkbox
        label="Markup Tool: use one-click shape creation, when possible"
        checked={getMarkupToolOneClickShapes()}
        onChange={(_, { checked }) =>
          setMarkupToolOneClickShapes(checked ?? true)
        }
      />

      <Form.Checkbox
        label="Markup Tool: use double-click to reset zoom"
        checked={getMarkupToolResetZoomOnDoubleClick()}
        onChange={(_, { checked }) =>
          setMarkupToolResetZoomOnDoubleClick(checked ?? true)
        }
      />
      <Form.Checkbox
        label="Global: remember the last page and go back there, after logging in"
        checked={getRememberLastUrl()}
        onChange={(_, { checked }) => setRememberLastUrl(checked ?? true)}
      />
      <Form.Checkbox
        label="File List: allow zoom with mouse"
        checked={getFileListZoomWithMouse()}
        onChange={(_, { checked }) => setFileListZoomWithMouse(checked ?? true)}
      />
    </Form>
  );
}
