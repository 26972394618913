import { ReactNode } from "react";
import Scalar from "./ouputVector";
import Segmentation2D from "./outputSegmentation2D";
import ObjectDetection from "./outputObjectDetection";
import Unknown from "./outputUnknown";
import { NavigationProps } from "../../utils";
import { ImageDatapointType } from "../../../../types/extractedTypes";
import { MarkupModuleType, SourceType } from "../hooks/types";
import useNetworkModuleClassLabels from "../hooks/useNetworkModuleClassLabels";

// import InputNone from "../inputNone";

type Props = {
  image: ImageDatapointType;
  source: SourceType;
  selector: ReactNode;
  navigation: NavigationProps;
  outputDataType: MarkupModuleType | undefined;
  deleteResearchbox: () => void;
};

export default function InputImage({
  image,
  source,
  selector,
  navigation,
  outputDataType,
  deleteResearchbox,
}: Props) {
  const labelList = useNetworkModuleClassLabels(source.markupModule?.id ?? "");

  // if (displayMode === "loading" || displayMode === "noInput" || !outputDataType)
  //   return <InputNone selector={selector} messageType="loading" />;
  // if (displayMode === "noOutput")
  //   return <InputNone selector={selector} messageType="noOutput" />;

  switch (outputDataType) {
    case "PipelineModuleNetworkImageSegmentation":
      return (
        <Segmentation2D
          image={image}
          networkId={source.markupModule?.id || ""}
          labelList={labelList}
          selector={selector}
          navigation={navigation}
          deleteResearchbox={deleteResearchbox}
        />
      );

    case "PipelineModuleNetworkImageClassification":
      return (
        <Scalar
          input={image}
          networkId={source.markupModule?.id || ""}
          labelList={labelList}
          selector={selector}
          navigation={navigation}
          deleteResearchbox={deleteResearchbox}
        />
      );
    case "PipelineModuleNetworkImageObjectDetection":
      return (
        <ObjectDetection
          image={image}
          networkId={source.markupModule?.id || ""}
          labelList={labelList}
          selector={selector}
          navigation={navigation}
          deleteResearchbox={deleteResearchbox}
        />
      );
    default:
      return (
        source.inputDatapointNames && (
          <Unknown d={source.inputDatapointNames[0]} selector={selector} />
        )
      );
  }
}
