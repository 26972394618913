import {
  useStartTrainingMutation,
  useStopTrainingMutation,
  useStartInferenceMutation,
  useStopInferenceMutation,
} from "../../../../ts-clients/command";

export default function useDenkBoxControl(networkId: string) {
  const [doStartTraining] = useStartTrainingMutation({
    variables: { input: { moduleId: networkId } },
  });

  const [doStopTraining] = useStopTrainingMutation({
    variables: { input: { moduleId: networkId } },
  });

  const [doStopInference] = useStopInferenceMutation({
    variables: { input: { moduleId: networkId } },
    errorPolicy: "ignore",
  });

  const [doStartInferenceMutation] = useStartInferenceMutation();

  const startInference = (researchBoxIds: string[]) =>
    doStartInferenceMutation({
      variables: {
        input: { moduleId: networkId, researchBoxIds },
      },
    });

  const stopInference = () => doStopInference();
  const startTraining = () => doStartTraining();
  const stopTraining = () => doStopTraining();

  return {
    startTraining,
    stopTraining,
    startInference,
    stopInference,
  };
}
