import { DataType } from "../../../ts-clients/query";

export type PartList = {
  image: boolean;
  mf: boolean;
  markup: boolean;
};

export function getPartsForDataTypes(dataTypes: DataType[]): PartList {
  return {
    image: dataTypes.includes(DataType.Image),
    mf: dataTypes.includes(DataType.MagneticFieldScan),
    markup:
      dataTypes.includes(DataType.ClassificationMarkup) ||
      dataTypes.includes(DataType.ObjectDetectionMarkup) ||
      dataTypes.includes(DataType.SegmentationMarkup),
  };
}
