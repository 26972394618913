import {
  PretrainedNetwork,
  PretrainedNetworkType,
} from "../../../../types/extractedTypes";
import blankNetworkThumbnail from "./img/from_scratch.png";

export const blankNetworkModuleMagicId = "blank-network-module";
export const blankNetworkMagicId = "blank-network";

export default function makeBlankNetwork(
  networkType: PretrainedNetworkType
): PretrainedNetwork {
  return {
    __typename: "PretrainedNetwork",
    id: blankNetworkMagicId,
    networkModule: {
      __typename: networkType,
      classLabels: [],
      id: blankNetworkModuleMagicId,
      createdAt: "",
      description: "",
      name: "start from scratch",
      owner: "",
      updatedAt: "",
    },
    thumbnail: blankNetworkThumbnail,
    createdAt: "",
    updatedAt: "",
  };
}
