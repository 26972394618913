import {
  PipelineModuleNetworkImageSegmentationProps as Props,
  BaseParams,
} from "ModuleTypes";
import { useState } from "react";
import {
  useAddPipelineModuleNetworkImageObjectDetectionMutation,
  AddPipelineModuleNetworkImageObjectDetectionInput,
} from "../../ts-clients/command";
import { pipelineModuleNetworkImageSegmentationDefaults } from "./defaults";
import { blankNetworkModuleMagicId } from "../../features/networks3/AddNetwork/RealAddNetwork/blankNetwork";

export default function useCreatePipelineModuleObjectDetection() {
  const [moduleData, setModuleData] = useState<Props>(
    pipelineModuleNetworkImageSegmentationDefaults()
  );
  const [m] = useAddPipelineModuleNetworkImageObjectDetectionMutation();

  const create = async (datasetId: string | null) => {
    const filteredModuleData = moduleData;
    filteredModuleData.classLabels = filteredModuleData.classLabels.filter(
      (c) => c.name !== ""
    );

    if (
      filteredModuleData.pretrainedNetworkModuleId === blankNetworkModuleMagicId
    ) {
      filteredModuleData.pretrainedNetworkModuleId = null;
    }

    const input: AddPipelineModuleNetworkImageObjectDetectionInput = {
      ...filteredModuleData,
      datasetId: datasetId ?? undefined,
      selectedPipelineModuleOutputIDs:
        filteredModuleData.selectedPipelineModuleOutputIDs.length > 0
          ? filteredModuleData.selectedPipelineModuleOutputIDs
          : undefined,
    };

    const result = await m({
      variables: {
        input,
      },
    });
    return (
      result.data?.addPipelineModuleNetworkImageObjectDetection?.datasetId ?? ""
    );
  };

  const setBaseParams = ({ moduleDescription, moduleName }: BaseParams) => {
    setModuleData((md) => ({ ...md, moduleName, moduleDescription }));
  };

  return { create, moduleData, setModuleData, setBaseParams };
}
