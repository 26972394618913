import { createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
// import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { createUploadLink } from "apollo-upload-client";
import store from "./state/store";
import { getCurrentUser } from "./state/loginState";

export const isWeb = (isWebAsString: string): boolean =>
  isWebAsString === "true";

const getAuthToken = () => getCurrentUser(store.getState()).token;

const DGRAPH_URL =
  process.env.REACT_APP_DGRAPH_URL_FRONTEND &&
  process.env.REACT_APP_DGRAPH_URL_FRONTEND !== ""
    ? process.env.REACT_APP_DGRAPH_URL_FRONTEND
    : "http://localhost:8080/graphql";

const WEBSOCKET_URL =
  process.env.REACT_APP_WEBSOCKET_URL &&
  process.env.REACT_APP_WEBSOCKET_URL !== ""
    ? process.env.REACT_APP_WEBSOCKET_URL
    : "ws://localhost:8080";

const COMMAND_URL =
  process.env.REACT_APP_COMMAND_URL && process.env.REACT_APP_COMMAND_URL !== ""
    ? process.env.REACT_APP_COMMAND_URL
    : "http://localhost:8085/command";

const makeWSLink = (clientUri: string) =>
  new WebSocketLink({
    uri: clientUri,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: () => ({
        "X-Dug-Auth": getAuthToken(),
      }),
    },
  });

const makeBTechWsLink = () => makeWSLink("ws://localhost:8085/btech");

const makeDgraphWsLink = () => makeWSLink(`${WEBSOCKET_URL}/graphql`);

const makeCommandHttpLink = () => {
  const uri = COMMAND_URL;
  return createUploadLink({ uri });
};

const httpAuthLink = () =>
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      "X-Dug-Auth": getAuthToken(),
    },
  }));

export const getAuthenticatedLinks = () => {
  return {
    btech: {
      http: from([
        httpAuthLink(),
        createUploadLink({ uri: "http://localhost:8085/btech" }),
      ]),
      ws: makeBTechWsLink(),
    },
    command: {
      http: from([httpAuthLink(), makeCommandHttpLink()]),
    },
    dgraph: {
      http: from([
        httpAuthLink(),
        // createPersistedQueryLink(),
        createHttpLink({ uri: DGRAPH_URL }),
      ]),
      ws: makeDgraphWsLink(),
    },
  };
};

export const getUnauthenticatedLinks = () => {
  return {
    command: {
      http: from([makeCommandHttpLink()]),
    },
  };
};
