/* eslint-disable react/jsx-one-expression-per-line */
import { useState } from "react";
import { Modal, Button, Tab } from "semantic-ui-react";
import useKeyboard from "../../../../../../hooks/useKeyboard";
import Shortcuts from "./Shortcuts";
import Tipps from "./Tipps";

const renderShortcuts = () => <Shortcuts />;
const renderTipps = () => <Tipps />;

export default function HelpModal() {
  const [open, setOpen] = useState(false);

  useKeyboard({
    onShortcut: (k) => {
      if (k === "h") {
        setOpen(true);
      }
    },
  });

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button icon="question" />}
    >
      <Modal.Header>Annotation Tool Help</Modal.Header>
      <Modal.Content>
        <Tab
          panes={[
            { menuItem: "Keyboard Shortcuts", render: renderShortcuts },
            { menuItem: "Tipps", render: renderTipps },
          ]}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={() => setOpen(false)} content="Close" />
      </Modal.Actions>
    </Modal>
  );
}
