import {
  Container,
  Header,
  Divider,
  Button,
  Segment,
  Grid,
  Progress,
} from "semantic-ui-react";

export default function AnalyzeRoot() {
  return (
    <Container style={{ marginTop: "15px" }}>
      <Header as="h1">Analyze Data</Header>
      <Divider />
      <Segment>
        <Grid>
          <Grid.Column width="4">
            <Button content="Generate Line-Scans" />
          </Grid.Column>
          <Grid.Column width="12">
            <Progress
              percent={Math.round((350 / 1900) * 100)}
              autoSuccess
              progress
              label="350/1900 images analyzed"
            />
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Column width="4">
            <Button content="Analyze Cross-Connectors" />
          </Grid.Column>
          <Grid.Column width="12">
            <Progress
              percent={Math.round((800 / 1400) * 100)}
              autoSuccess
              progress
              label="200/1400 images analyzed"
            />
          </Grid.Column>
        </Grid>
      </Segment>
    </Container>
  );
}
