import { useUpdateDatasetMutation } from "../../../../ts-clients/command";

export default function useRenameDataset(datasetId: string, name: string) {
  const [updateDataset] = useUpdateDatasetMutation();
  return () =>
    updateDataset({
      variables: {
        input: {
          filter: { datasetId },
          name,
        },
      },
    });
}
