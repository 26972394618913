import { DenkjobState } from "../../../../ts-clients/query";
import { DenkBoxNetworkState } from "./types";
import useDelay from "./useDelay";
import useNetworkDetails from "./useNetworkDetails";

export default function useNetworkDetailsWithFakeState(
  datasetId?: string,
  networkId?: string,
  pollInterval?: number,
  skip?: boolean
) {
  const networkDetails = useNetworkDetails(
    datasetId,
    networkId,
    pollInterval,
    skip
  );

  const [trainingStarting, startTraining] = useDelay(5000);
  const [trainingStopping, stopTraining] = useDelay(5000);
  const [predictionStarting, startPrediction] = useDelay(5000);
  const [predictionStopping, stopPrediction] = useDelay(5000);

  let fakePredictionState: DenkjobState | null = null;
  if (predictionStarting) {
    fakePredictionState = DenkjobState.Starting;
  }
  if (predictionStopping) {
    fakePredictionState = DenkjobState.Stopping;
  }
  let fakeTrainingState: DenkjobState | null = null;
  if (trainingStarting) {
    fakeTrainingState = DenkjobState.Starting;
  }
  if (trainingStopping) {
    fakeTrainingState = DenkjobState.Stopping;
  }

  const fakeNetworkState: DenkBoxNetworkState = {
    prediction:
      fakePredictionState ?? networkDetails.networkState?.prediction ?? null,
    testing: networkDetails.networkState?.testing ?? null,
    training:
      fakeTrainingState ?? networkDetails.networkState?.training ?? null,
    runningSince: networkDetails.networkState?.runningSince ?? null,
  };

  const fakeActions = {
    startTraining,
    stopTraining,
    startPrediction,
    stopPrediction,
  };

  return {
    ...networkDetails,
    networkState: fakeNetworkState,
    fakeActions,
  };
}

export type UseNetworkDetailsWithFakeStateReturnType = ReturnType<
  typeof useNetworkDetailsWithFakeState
>;
