import { useCallback, useEffect } from "react";
import { KeyboardShortcut } from "MarkupTypes";

const keys: KeyboardShortcut[] = [
  "c",
  "r",
  "s",
  "f",
  "m",
  "b",
  "p",
  "v",
  "z",
  "e",
  "h",
  "t",
  "Digit1",
  "Digit2",
  "Digit3",
  "Digit4",
  "Digit5",
  "Digit6",
  "Digit7",
  "Digit8",
  "Digit9",
  "Digit0",
  "u",
  "o",
  "i",
  "Enter",
];

type Props = {
  onEnter?: () => void;
  onEscape?: () => void;
  onForward?: () => void;
  onBackward?: () => void;
  onDelete?: () => void;
  onShortcut?: (k: KeyboardShortcut, alt: boolean) => void;
};

export default function useKeyboard({
  onEnter,
  onEscape,
  onBackward,
  onForward,
  onDelete,
  onShortcut,
}: Props) {
  const keyPressHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.target instanceof HTMLInputElement) {
        return;
      }
      if (event.key === "Escape" && typeof onEscape !== "undefined") {
        onEscape();
      } else if (event.key === "Enter" && typeof onEnter !== "undefined") {
        onEnter();
      } else if (
        event.ctrlKey &&
        event.key === "z" &&
        typeof onBackward !== "undefined"
      ) {
        onBackward();
      } else if (
        event.ctrlKey &&
        event.key === "y" &&
        typeof onForward !== "undefined"
      ) {
        onForward();
      } else if (event.key === "Delete" && typeof onDelete !== "undefined") {
        onDelete();
      } else if (
        keys.includes(event.code as KeyboardShortcut) &&
        !!onShortcut
      ) {
        onShortcut(event.code as KeyboardShortcut, event.shiftKey);
      }
    },
    [onEnter, onEscape, onBackward, onForward, onDelete, onShortcut]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyPressHandler, false);
    return function cleanUp() {
      window.removeEventListener("keydown", keyPressHandler, false);
    };
  }, [keyPressHandler]);
}
