import { ResearchBoxOrFolderType } from "../../types/extractedTypes";
import FolderCard from "./FolderCard";
import ResearchBoxCard from "./ResearchBoxCard";

type Props = {
  object: ResearchBoxOrFolderType | null;
  selected: boolean;
  loading: boolean;
  onSelect: (value: boolean) => void;
  onRename: () => void;
  triggerCopy: () => void;
  triggerDelete: () => void;
  openMarkupTool?: () => void;
};

const ObjectCard = ({
  object,
  selected,
  loading,
  onSelect,
  onRename,
  openMarkupTool,
  triggerCopy,
  triggerDelete,
}: Props) => {
  if (object?.__typename === "Folder") {
    return (
      <FolderCard
        key={object?.id}
        folder={object}
        startCopy={triggerCopy}
        startDelete={triggerDelete}
        startMove={() => {}}
        onRename={onRename}
        onSelect={onSelect}
        selected={selected}
      />
    );
  }
  if (object?.__typename === "ResearchBox") {
    return (
      <ResearchBoxCard
        key={object?.id}
        loading={loading}
        triggerDelete={triggerDelete}
        researchBox={object}
        onRename={onRename}
        onSelect={onSelect}
        selected={selected}
        openMarkupTool={() => {
          if (openMarkupTool) {
            openMarkupTool();
          }
        }}
      />
    );
  }
  return null;
};

ObjectCard.defaultProps = {
  openMarkupTool: undefined,
};

export default ObjectCard;
