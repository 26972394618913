import { PrefillData } from "FormTypes";
import { useEffect } from "react";
import useHook from "../../../hooks/moduleCreators-new-2021-04/useCreatePipelineModuleImageSegmentation";
import { useGoto } from "../../../utils/routeManager";
import ModuleForm from "./forms/FormPipelineModuleNetworkImageSegmentation";

type Props = {
  datasetId: string | null;
  onCancel: () => unknown;
  prefillData: PrefillData | null;
};

export default function PMNetworkImageSegmentationCreate({
  datasetId,
  onCancel,
  prefillData,
}: Props) {
  const { create, moduleData, setModuleData, setBaseParams } = useHook();

  const goTo = useGoto();

  const onSubmit = async () => {
    const newDatasetId = await create(datasetId);
    if (datasetId === null) {
      goTo("ResearchBoxList", {
        datasetId: newDatasetId,
        context: "researchboxlist",
      });
    } else {
      goTo("NetworkStats", {
        datasetId: newDatasetId,
      });
    }
  };

  useEffect(() => {
    if (prefillData !== null) {
      setBaseParams({
        ...moduleData,
        moduleDescription: prefillData.description,
        moduleName: prefillData.name,
      });
      setModuleData({
        ...moduleData,
        pretrainedNetworkModuleId: prefillData.pretrainedNetworkModuleId,
        classLabels: prefillData.classLabels,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefillData]);

  return (
    <ModuleForm
      datasetId={datasetId}
      moduleData={moduleData}
      onCancel={onCancel}
      onSave={onSubmit}
      setBaseParams={setBaseParams}
      setData={setModuleData}
      moduleId=""
      mode="create"
    />
  );
}
