import { RetryLink } from "@apollo/client/link/retry";
import { ApolloClient, InMemoryCache, split, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import store from "./state/store";
import * as util from "./apollo-util";
import * as tests from "./apollo-tests";
import { clearLogin } from "./state/loginState";

import generatedIntrospection from "./ts-clients/introspection-result";

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  console.error(
    "[GraphQL error]: Error on GQL and Operation (see message): ",
    operation
  );
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      if (message.indexOf("Access denied") !== -1) {
        store.dispatch(clearLogin());
      }
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const retryLink = new RetryLink();

const createApolloClient = () => {
  // Lokal scheint alles zu gehen.
  // Vermutlich müssen wir das nochmal anfassen, damit es auch im Browser geht.
  const links = util.getAuthenticatedLinks();

  const commandLink = from([retryLink, errorLink, links.command.http]);

  const queryLink = split(
    tests.isSubscription,
    from([links.dgraph.ws]),
    from([retryLink, errorLink, links.dgraph.http])
  );

  const btechLink = split(
    tests.isSubscription,
    from([links.btech.ws]),
    from([retryLink, errorLink, links.btech.http])
  );

  const dugLink = split(
    tests.isBTechOperation,
    btechLink,
    split(tests.isMutation, commandLink, queryLink)
  );

  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
    }),
    link: dugLink,
  });
};

const client = createApolloClient();

export { createApolloClient, client };
