import { ToolType } from "MarkupTypes";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import magicWand from "./img/magic-wand-svgrepo-com.svg";
import rectangle from "./img/rec.svg";
import polygon from "./img/poly.svg";
import sausage from "./img/fatline.svg";
import circle from "./img/circle.svg";
import freeForm from "./img/free.svg";
import highLight from "./img/highlight-svgrepo-com.svg";
// import pen from './img/draw.svg';
import nav from "./img/nav.svg";
import useUIConfig from "../../../../../state/useUIConfig";
import {
  useWindowDimensions,
  partitionArray,
} from "../../../../../utils/helpers";
import {
  getSelectedSegmentation2DTool,
  setSelectedSegmentation2DTool,
  setSelectedPolygonId,
  getSegmentationsVisible,
} from "../../inputImage/redux-state";
import { canMutateMarkup } from "../../../../../state/loginState";

const segmentation2DTools: ToolType[] = [
  "None",
  "Highlight",
  "Circle",
  "FreeForm",
  "MagicWand",
  "Polygon",
  "Rectangle",
  "Sausage",
  // 'Pen',
];
const objectDetectionTools: ToolType[] = [
  "None",
  "Highlight",
  "BoundingBox",
  // 'Pen',
];

const icons = new Map<ToolType, string>([
  ["None", nav],
  ["Highlight", highLight],
  ["Circle", circle],
  ["FreeForm", freeForm],
  ["MagicWand", magicWand],
  ["Polygon", polygon],
  ["Rectangle", rectangle],
  ["BoundingBox", rectangle],
  ["Sausage", sausage],
  // ['Pen', pen],
]);

type ToolSet = "segmentation2d" | "objectDetection";

const toolSets = new Map<ToolSet, ToolType[]>([
  ["segmentation2d", segmentation2DTools],
  ["objectDetection", objectDetectionTools],
]);

const getIconSize = (windowWidth: number): number => {
  if (windowWidth > 1900) {
    return 32;
  }
  if (windowWidth > 1700) {
    return 28;
  }
  return 24;
};

type Props = {
  toolSet: ToolSet;
};

export default function MarkupToolsIndex({ toolSet }: Props) {
  const { width: windowWidth } = useWindowDimensions();
  const dispatch = useDispatch();
  const { getMarkupToolIconStyle } = useUIConfig();
  const currentTool = useSelector(getSelectedSegmentation2DTool);
  const segmentationsVisible = useSelector(getSegmentationsVisible);

  const tools = toolSets.get(toolSet) ?? [];
  const iconSize = getIconSize(windowWidth);

  const sel = (i: ToolType) => {
    dispatch(setSelectedPolygonId(null));
    dispatch(setSelectedSegmentation2DTool(i));
  };
  const isMarkupMutable = useSelector(canMutateMarkup);

  if (getMarkupToolIconStyle() === "list") {
    return (
      <Button.Group vertical fluid style={{ marginBottom: 10 }}>
        {tools.map((i) => (
          <Button
            size="medium"
            key={i}
            active={i === currentTool}
            onClick={() => sel(i)}
            disabled={!segmentationsVisible || !isMarkupMutable}
          >
            <img src={icons.get(i)} alt="" height={24} />
            <span
              style={{
                paddingLeft: "10px",
                height: "24px",
                lineHeight: "24px",
              }}
            >
              {i}
            </span>
          </Button>
        ))}
      </Button.Group>
    );
  }

  const toolRows = partitionArray(Array.from(tools), 4);
  return (
    <>
      {toolRows.map((row, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Button.Group key={idx} style={{ marginBottom: 10 }} size="huge" fluid>
          {row?.map((i) => (
            <Button
              key={i}
              size="medium"
              style={{ padding: 12 }}
              active={i === currentTool}
              onClick={() => sel(i)}
              disabled={!segmentationsVisible || !isMarkupMutable}
            >
              <img src={icons.get(i)} alt="" height={iconSize} />
            </Button>
          ))}
        </Button.Group>
      ))}
    </>
  );
}
