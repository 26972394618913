/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  FolderOrResearchBoxOrderable,
  ResearchBoxOrderable,
  useQueryFilteredFoldersOrResearchBoxesQuery,
  useQueryFilteredResearchBoxesQuery,
} from "../ts-clients/query";
import {
  getFilename,
  getLabels,
  getNetworks,
  getIncludePositiveLabels,
  getIncludeNegativeLabels,
  getIncludeUnclassifiedLabels,
  getOrder,
} from "../state/researchboxfilter";
import useQuery from "../hooks/useQuery";

export default function useFindPaginatedFoldersAndResearchBoxesByDatasetIdAndFolderId() {
  const filenameFilter = useSelector(getFilename);
  const networkIDs = useSelector(getNetworks);
  const labelFilter = useSelector(getLabels);
  const includePositives = useSelector(getIncludePositiveLabels);
  const includeNegatives = useSelector(getIncludeNegativeLabels);
  const includeUnclassified = useSelector(getIncludeUnclassifiedLabels);
  const order = useSelector(getOrder);
  return (
    datasetId: string,
    first: number,
    offset: number,
    excludeFolders?: boolean
  ) => {
    const query = useQuery();
    const pathParam = query.get("path");
    const path = pathParam ? [datasetId, pathParam].join("-") : datasetId;

    const {
      data: noNetworkFilterData,
      startPolling: noNetworkFilterStartPolling,
      stopPolling: noNetworkFilterStopPolling,
      loading: noNetworkFilterStopLoading,
      refetch: noNetworkFilterStopRefetch,
    } = useQueryFilteredFoldersOrResearchBoxesQuery({
      variables: {
        filter: {
          parentIds: filenameFilter === "" ? { eq: path } : undefined,
          name:
            filenameFilter === ""
              ? undefined
              : { regexp: `/.*${filenameFilter}.*/` },
          typeForSorting: excludeFolders ? { eq: "ResearchBox" } : undefined,
        },
        first,
        offset,
        order: { ...order, then: { asc: FolderOrResearchBoxOrderable.Id } },
      },
      fetchPolicy: "cache-and-network",
    });

    const { data, startPolling, stopPolling, loading, refetch } =
      useQueryFilteredResearchBoxesQuery({
        variables: {
          filter: {
            name:
              filenameFilter === ""
                ? undefined
                : { regexp: `/.*${filenameFilter}.*/` },
            containsMarkupForNetworkId:
              networkIDs.length > 0 && !includeUnclassified
                ? { in: networkIDs }
                : undefined,
            not:
              networkIDs.length > 0 && includeUnclassified
                ? { containsMarkupForNetworkId: { in: networkIDs } }
                : undefined,
            and:
              labelFilter.length > 0 &&
              !includeUnclassified &&
              (includePositives || includeNegatives)
                ? [
                    {
                      or: [
                        includePositives
                          ? {
                              containsPositiveMarkupForLabelId: {
                                in: labelFilter.map((l) => l.id),
                              },
                            }
                          : undefined,
                        includeNegatives
                          ? {
                              containsNegativeMarkupForLabelId: {
                                in: labelFilter.map((l) => l.id),
                              },
                            }
                          : undefined,
                      ].flatMap((o) => (o ? o : [])),
                    },
                  ]
                : undefined,
          },
          first,
          offset,
          order: {
            asc: order.asc
              ? (order.asc as unknown as ResearchBoxOrderable)
              : undefined,
            desc: order.desc
              ? (order.desc as unknown as ResearchBoxOrderable)
              : undefined,
            then: { asc: ResearchBoxOrderable.Id },
          },
        },
        fetchPolicy: "cache-and-network",
      });

    const useResearchBoxQuery = networkIDs.length > 0;

    const ret = useMemo(
      () => ({
        folderCount: useResearchBoxQuery
          ? 0
          : noNetworkFilterData?.queryFolderOrResearchBox?.filter(
              (o) => o?.__typename === "Folder"
            ).length || 0,
        totalCount: useResearchBoxQuery
          ? data?.aggregateResearchBox?.count || 0
          : noNetworkFilterData?.aggregateFolderOrResearchBox?.count || 0,
        objects: useResearchBoxQuery
          ? data?.queryResearchBox || []
          : noNetworkFilterData?.queryFolderOrResearchBox?.filter((o) =>
              excludeFolders ? o?.__typename === "ResearchBox" : true
            ) || [],
      }),
      [
        useResearchBoxQuery,
        noNetworkFilterData?.queryFolderOrResearchBox,
        noNetworkFilterData?.aggregateFolderOrResearchBox?.count,
        data?.aggregateResearchBox?.count,
        data?.queryResearchBox,
        excludeFolders,
      ]
    );

    return {
      ret,
      startPolling: useResearchBoxQuery
        ? startPolling
        : noNetworkFilterStartPolling,
      stopPolling: useResearchBoxQuery
        ? stopPolling
        : noNetworkFilterStopPolling,
      refetch: useResearchBoxQuery ? refetch : noNetworkFilterStopRefetch,
      loading: useResearchBoxQuery ? loading : noNetworkFilterStopLoading,
    };
  };
}
