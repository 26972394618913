import { Link } from "react-router-dom";
import { Header, Divider, Button, Segment } from "semantic-ui-react";
import MagneticFieldScan from "../../datapoint-viewer/MagneticFieldScan";
import { DetailsDatapointParams, getPath } from "../../../utils/routeManager";
import { MagneticFieldScanType } from "../../../types/extractedTypes";

export type Params = {
  page: DetailsDatapointParams;
  datapoint: MagneticFieldScanType;
};

export default function MagneticFieldScanPage({ datapoint, page }: Params) {
  const backLink =
    page.context === "sensorimport"
      ? getPath("SensorImport", page)
      : getPath("ResearchBoxDetails", { ...page, page: "overview" });

  return (
    <>
      <Header as="h1">
        Magnetic Field Scan Details
        <Button as={Link} floated="right" to={backLink} content="back" />
      </Header>
      <Divider />
      <Segment
        style={{
          width: "100%",
          height: "calc(100vh - 140px)",
          overflow: "hidden",
        }}
      >
        <MagneticFieldScan d={datapoint} />
      </Segment>
    </>
  );
}
