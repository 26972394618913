/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-curly-newline */
import { useState } from "react";
import { ModuleType } from "ModuleTypes";
import ReactFlow, { Elements, Controls } from "react-flow-renderer";
import { random } from "lodash";

import {
  Header,
  Divider,
  Button,
  Loader,
  Segment,
  Dropdown,
} from "semantic-ui-react";
import PipelineModuleNode from "./PipelineModuleNode";
import {
  useSubscribePipelineSubscription,
  SubscribePipelineSubscription,
} from "../../ts-clients/query";
import { useGoto } from "../../utils/routeManager";
import { useKeyPress } from "./hooks";

import {
  useUpdatePipelineModulePosition,
  useAddPipelineModuleEdge,
  useRemovePipelineModuleEdge,
} from "../../hooks";

type Props = {
  datasetId: string;
};

const getNodes = (d: SubscribePipelineSubscription): Elements => {
  const nodes: Elements =
    d.getDataset?.pipeline?.modules?.map((m) => ({
      id: m.id || "unknownid",
      type: "moduleNode",
      data: m,
      position: {
        x: m.layoutPosition.x || random(10, 600),
        y: m.layoutPosition.y || random(10, 600),
      },
    })) || [];

  return nodes;
};

const getEdges = (d: SubscribePipelineSubscription): Elements => {
  const edges: Elements =
    d.getDataset?.pipeline?.edges?.map((e) => ({
      id: e.id,
      source: e.from.module.id,
      target: e.to.module.id,
      sourceHandle: e.from.id,
      targetHandle: e.to.id,
    })) || [];

  return edges;
};

export default function FlowView({ datasetId }: Props) {
  const goto = useGoto();
  const updateModulePosition = useUpdatePipelineModulePosition();

  const [selectedEdge, setSelectedEdge] = useState<string | null>(null);
  const deletePressed = useKeyPress("Delete");

  const { data: pipeline, loading } = useSubscribePipelineSubscription({
    variables: {
      datasetId,
    },
  });

  const addPipelineEdge = useAddPipelineModuleEdge();

  const removePipelineEdge = useRemovePipelineModuleEdge();

  if (deletePressed && selectedEdge !== null) {
    removePipelineEdge(selectedEdge);
    setSelectedEdge(null);
  }

  if (loading) {
    return (
      <Segment style={{ height: 600 }}>
        <Loader />
      </Segment>
    );
  }

  /**
   * Connect two modules (represented by module-ids given by arguments) by an edge
   * @param {string} pipelineModuleOutputId
   * @param {string} pipelineModuleInputId
   * @todo Do this only if (1) both are on different modules (2) both have the same data type (3) both have the same ioType
   * @todo --> disable invalid connection points (different color)
    // -
   */
  const connectModules = (
    pipelineModuleOutputId: string,
    pipelineModuleInputId: string
  ) => {
    addPipelineEdge({ pipelineModuleOutputId, pipelineModuleInputId });
  };

  const nodeTypes = {
    moduleNode: PipelineModuleNode,
  };

  const nodes = pipeline ? getNodes(pipeline) : [];
  const edges = pipeline ? getEdges(pipeline) : [];

  const pipelineId = pipeline?.getDataset?.pipeline?.id ?? "";

  const onClick = (id: string) => {
    const edge = edges.find((e) => e.id === id);
    if (edge) {
      setSelectedEdge(id);
    } else {
      goto("FlowDesignerEditModule", { datasetId, moduleId: id });
    }
  };

  const openForm = (moduleType: ModuleType) =>
    goto("FlowDesignerAddModule", {
      datasetId,
      moduleType,
      pipelineId,
    });

  return (
    <>
      <Header as="h1">
        Flow Designer
        <Dropdown
          text="Add New Module"
          style={{ float: "right" }}
          as={Button}
          pointing="top right"
          color="green"
        >
          <Dropdown.Menu>
            <Dropdown.Header>Neural Networks</Dropdown.Header>
            <Dropdown.Item
              text="Classification Network"
              onClick={() =>
                openForm("PipelineModuleNetworkImageClassification")
              }
            />
            <Dropdown.Item
              text="Segmentation Network"
              onClick={() => openForm("PipelineModuleNetworkImageSegmentation")}
            />
            <Dropdown.Divider />
            <Dropdown.Header>Filters</Dropdown.Header>
            <Dropdown.Item
              text="Contrast Enhancement"
              onClick={() => openForm("PipelineModuleContrastEnhancement")}
            />
            <Dropdown.Divider />
            <Dropdown.Header>B-Tech</Dropdown.Header>
            <Dropdown.Item
              text="Cross Connector Checker"
              onClick={() => openForm("PipelineModuleCCChecker")}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Header>
      <Divider />
      <div style={{ height: 1200 }}>
        {pipeline && (
          <ReactFlow
            elements={nodes.concat(edges)}
            onNodeDragStop={(_, { id, position }) =>
              updateModulePosition({
                layoutPosition: {
                  x: Math.round(position.x),
                  y: Math.round(position.y),
                },
                pipelineModuleId: id,
              })
            }
            snapGrid={[10, 10]}
            snapToGrid
            nodeTypes={nodeTypes}
            onConnect={({ sourceHandle, targetHandle }) => {
              connectModules(sourceHandle as string, targetHandle as string);
            }}
            onElementClick={(_, { id }) => onClick(id)}
            onPaneClick={() => {
              setSelectedEdge(null);
            }}
          >
            <Controls />
          </ReactFlow>
        )}
      </div>
    </>
  );
}
