import { KonvaEventObject } from "konva/lib/Node";
import { Vector2d } from "MarkupTypes";
import { Circle } from "react-konva/lib/ReactKonvaCore";

type Props = {
  position: Vector2d;
  dragTo: (p: Vector2d) => void;
  dragEnd: () => void;
  scale?: number;
  opacity?: number;
};

export default function DragDot({
  scale,
  position,
  opacity,
  dragTo,
  dragEnd,
}: Props) {
  const onDragMove = ({ target }: KonvaEventObject<DragEvent>) =>
    dragTo({ x: target.x(), y: target.y() });

  const realscale = scale ?? 1;
  return (
    <Circle
      radius={6 / realscale ** 0.7}
      x={position.x}
      y={position.y}
      fill="red"
      draggable
      onDragMove={onDragMove}
      onDragEnd={dragEnd}
      opacity={opacity}
    />
  );
}

DragDot.defaultProps = {
  scale: 1,
  opacity: 1,
};
