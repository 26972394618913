/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect } from "react";
import { Container } from "semantic-ui-react";
import * as shared from "../../shared/noDataStates";
import { useGetAllDenkBoxesQuery } from "../../../../ts-clients/query";
import WorkerList from "../../shared/workerList";

export default function Workers() {
  const { data, loading, error, stopPolling, startPolling } =
    useGetAllDenkBoxesQuery();

  useEffect(() => {
    startPolling(1000);
    return function cleanup() {
      stopPolling();
    };
  });

  return (
    <Container>
      <h1>Workers</h1>
      {loading && shared.renderLoading()}
      {error && shared.renderError(error)}

      {data && data.queryDenkbox && data.queryDenkbox.length ? (
        <WorkerList data={data} />
      ) : (
        shared.renderEmpty()
      )}
    </Container>
  );
}
