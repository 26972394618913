import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAllMarkupModulesWithNetworksForDatasetQuery } from "../../ts-clients/query";
import {
  getNetworks,
  setNetworks,
  getFilename,
  setFilename,
  getLabels,
  setLabels,
  toggleIncludePositiveLabels,
  toggleIncludeNegativeLabels,
  getIncludePositiveLabels,
  getIncludeNegativeLabels,
  getIncludeUnclassifiedLabels,
  toggleIncludeUnclassifiedLabels,
} from "../../state/researchboxfilter";
import { ResearchBoxListParams } from "../../utils/routeManager";

export default function useResearchBoxFilters(params: ResearchBoxListParams) {
  const { datasetId } = params;
  const selectedNetworks = useSelector(getNetworks);
  const filename = useSelector(getFilename);
  const selectedLabels = useSelector(getLabels);
  const includePositives = useSelector(getIncludePositiveLabels);
  const includeNegatives = useSelector(getIncludeNegativeLabels);
  const includeUnclassified = useSelector(getIncludeUnclassifiedLabels);
  const dispatch = useDispatch();

  const { data: markupModulesWithNetworks } =
    useAllMarkupModulesWithNetworksForDatasetQuery({
      variables: {
        datasetId,
      },
    });

  // put all Labels into a list
  const labelList = useMemo(
    () =>
      markupModulesWithNetworks?.getDataset?.pipeline?.modules
        ?.flatMap((m) =>
          m.__typename === "PipelineModuleNetworkImageClassification" ||
          m.__typename === "PipelineModuleNetworkImageInstanceSegmentation" ||
          m.__typename === "PipelineModuleNetworkImageObjectDetection" ||
          m.__typename === "PipelineModuleNetworkImageSegmentation"
            ? m
            : []
        )
        .filter(
          (m) =>
            selectedNetworks.length === 0 ||
            // @ts-ignore
            selectedNetworks.find((id) => id === m.id)
        )
        .map((m) =>
          // @ts-ignore
          m?.classLabels
            ? // @ts-ignore
              m.classLabels.map((cl) => ({ id: cl.id, name: cl.name }))
            : []
        )
        .flat(1) ?? [],
    [markupModulesWithNetworks?.getDataset?.pipeline?.modules, selectedNetworks]
  );

  const setSearchFileName = (f: string) => dispatch(setFilename(f));
  const clearSearchFilename = () => setSearchFileName("");

  const doSetNetworks = (networkIds: string[]) =>
    dispatch(setNetworks(networkIds));

  const networkOptions = useMemo(
    () =>
      markupModulesWithNetworks?.getDataset?.pipeline?.modules
        ?.flatMap((m) =>
          m.__typename === "PipelineModuleNetworkImageClassification" ||
          m.__typename === "PipelineModuleNetworkImageInstanceSegmentation" ||
          m.__typename === "PipelineModuleNetworkImageObjectDetection" ||
          m.__typename === "PipelineModuleNetworkImageSegmentation"
            ? m
            : []
        )
        .map((m) => ({
          // @ts-ignore
          key: m?.id || "",
          // @ts-ignore
          text: m?.name || "",
          // @ts-ignore
          value: m?.id || "",
          // @ts-ignore
          icon: selectedNetworks.includes(m?.id) ? "check" : "times",
        })) || [],
    [markupModulesWithNetworks?.getDataset?.pipeline?.modules, selectedNetworks]
  );

  const doSetLabels = (sel: string[]) =>
    Array.isArray(sel)
      ? dispatch(
          setLabels(
            labelList
              ?.filter((n) => (sel as Array<string>).find((v) => v === n.id))
              .map((label) => ({
                id: label.id,
                name: label.name,
              })) || []
          )
        )
      : () => {};

  const selectedLabelIds = useMemo(
    () => selectedLabels.map((n) => n.id),
    [selectedLabels]
  );

  const labelOptions = useMemo(
    () =>
      labelList.map((label) => ({
        key: label.id,
        text: `${label.name}`,
        value: label.id,
        icon: selectedLabelIds.includes(label.id) ? "check" : "times",
      })) || [],
    [labelList, selectedLabelIds]
  );

  const togglePositives = () => dispatch(toggleIncludePositiveLabels());
  const toggleNegatives = () => dispatch(toggleIncludeNegativeLabels());
  const toggleUnclassified = () => dispatch(toggleIncludeUnclassifiedLabels());

  return {
    clearSearchFilename,
    doSetLabels,
    doSetNetworks,
    filename,
    includeNegatives,
    includePositives,
    includeUnclassified,
    labelOptions,
    networkOptions,
    selectedLabelIds,
    selectedNetworks,
    setSearchFileName,
    toggleNegatives,
    togglePositives,
    toggleUnclassified,
  };
}
