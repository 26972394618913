import Konva from "konva";
import { ImageSize, Point, Vector2d } from "MarkupTypes";

export const getPointerPosition = (stage: Konva.Stage | null): Point => {
  const nullpoint = { x: 0, y: 0 };
  if (stage === null) {
    return nullpoint;
  }
  // the function will return pointer position relative to the passed node
  const transform = stage.getAbsoluteTransform().copy();
  // to detect relative position we need to invert transform
  transform.invert();
  // get pointer (say mouse or touch) position
  const pos = stage.getStage().getPointerPosition();

  if (pos === null) {
    return nullpoint;
  }

  // now we find relative point
  return transform.point(pos);
};

export const getInitialOffSet = (
  canvasSize: ImageSize,
  imageSize: ImageSize,
  initialZoomFactor: Vector2d
) => ({
  x: Math.ceil((canvasSize.width - imageSize.width * initialZoomFactor.x) / 2),
  y: Math.ceil(
    (canvasSize.height - imageSize.height * initialZoomFactor.y) / 2
  ),
});

const calcOrientation = (imageSize: ImageSize): string => {
  const aspectRatio = imageSize.width / imageSize.height;
  let orientation = "";

  if (aspectRatio > 1) {
    orientation = "landscape";
  } else if (aspectRatio < 1) {
    orientation = "portrait";
  } else {
    orientation = "square";
  }

  return orientation;
};

const isValidImageSize = (imageSize: ImageSize): boolean => {
  return imageSize.height > 0 && imageSize.width > 0;
};

const calcScaledImageSize = (
  canvasSize: ImageSize,
  imageSize: ImageSize
): ImageSize => {
  if (!isValidImageSize(imageSize)) {
    return {
      width: 0,
      height: 0,
    };
  }

  const orientation = calcOrientation(imageSize);
  const aspectRatio = imageSize.width / imageSize.height;

  let imageWidth = 0;
  let imageHeight = 0;

  if (orientation === "landscape") {
    imageWidth = canvasSize.width;
    imageHeight = canvasSize.width / aspectRatio;
  }

  if (orientation === "portrait") {
    imageWidth = canvasSize.width * aspectRatio;
    imageHeight = canvasSize.height;
  }

  if (orientation === "square") {
    imageWidth = canvasSize.width;
    imageHeight = canvasSize.height;
  }

  return {
    width: imageWidth,
    height: imageHeight,
  };
};

export const calcZoomFactor = (
  canvasSize: ImageSize,
  imageSize: ImageSize
): Vector2d => {
  if (
    canvasSize.width === 0 ||
    canvasSize.height === 0 ||
    imageSize.width === 0 ||
    imageSize.height === 0
  ) {
    return {
      x: 1,
      y: 1,
    };
  }

  const scaledImageSize = calcScaledImageSize(canvasSize, imageSize);

  const factor = Math.min(
    scaledImageSize.height / imageSize.height,
    scaledImageSize.width / imageSize.width
  );

  return {
    x: factor,
    y: factor,
  };
};
