/* eslint-disable import/no-cycle */
import { createHashHistory } from "history";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createLogger } from "redux-logger";
import createRootReducer from "./rootReducer";

export const history = createHashHistory();
const rootReducer = createRootReducer(history);

const router = routerMiddleware(history);

const logger = createLogger({
  level: "info",
  collapsed: true,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(router).concat(logger),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;

export const currentPath = (state: RootState) =>
  `${state.router.location.pathname}${state.router.location.search}`;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
