import { Point, ToolType } from "MarkupTypes";
import { useState } from "react";
import { Circle, Layer } from "react-konva";
import Rectangle from "./AnnotationTypes/Rectangle";
import useCursor from "../hooks/useCursor";
import { AnnotationType } from "../../../../ts-clients/command";
import { CurrentDataType } from "../hooks/types";
import { getDash, getStrokeWidth } from "./AnnotationTypes/lowlevel/util";
import uglyStore from "../../../../state/uglyStore";
import useInterval from "../../../../hooks/useInterval";
import { useSmoothValue } from "./hooks/useSmoothValue";
import { BoundingBox } from "./AnnotationTypes";

type Props = {
  tool: ToolType;
  visible: boolean;
  highlightStartingPoint: Point | null;
  currentData: CurrentDataType;
  showCurrentAnnotation: boolean;
  annotationType: AnnotationType;
  scale: number;
  currentColor: string;
};

const MiniCircle = ({ p }: { p: Point }) => (
  <Circle x={p.x} radius={20} y={p.y} />
);

const MiniCircleLayer = ({ p }: { p: Point }) => (
  <Layer>
    <MiniCircle p={p} />
  </Layer>
);

export default function Brush({
  scale,
  tool,
  visible,
  currentData,
  showCurrentAnnotation,
  annotationType,
  highlightStartingPoint,
  currentColor,
}: Props) {
  const { getCursor } = useCursor();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const [lastUp, setLastUp] = useState(0);
  const [lastDown, setLastDown] = useState(0);

  const { value: circleRadius, up, down } = useSmoothValue(30);

  // update cursor position with 60Hz
  // this "trick" is used to not have the cursor position
  // in any react store. this prevents frequent stage
  // rerendering because of mouse movements
  useInterval(() => {
    setPosition(getCursor());
    if (tool === "Circle") {
      if (lastUp !== uglyStore.smoothvalue.up) {
        setLastUp(uglyStore.smoothvalue.up);
        up();
      }

      if (lastDown !== uglyStore.smoothvalue.down) {
        setLastDown(uglyStore.smoothvalue.down);
        down();
      }
      uglyStore.circleRadius = circleRadius;
    }
  }, 16);

  if (!visible) {
    return null;
  }

  if (tool === "None") {
    return null;
  }

  if (tool === "Highlight" && highlightStartingPoint !== null) {
    return (
      <Layer>
        <Rectangle
          // @ts-ignore
          c={{
            annotationType,
            topLeft: highlightStartingPoint,
            bottomRight: position,
            markupLabelId: "moot",
            createdAt: "",
            id: "",
          }}
          temporary
          isSelected={false}
          select={() => {}}
          scale={1}
          update={() => {}}
        />
        <MiniCircle p={position} />
      </Layer>
    );
  }

  if (
    tool === "MagicWand" ||
    tool === "FreeForm" ||
    tool === "Polygon" ||
    tool === "Brush" ||
    tool === "Sausage" ||
    tool === "Highlight"
  ) {
    // ohne diesen unsichtbaren Circle bekomme ich nach dem klick die Position nicht ordentlich
    return <MiniCircleLayer p={position} />;
  }

  if (tool === "Rectangle") {
    if (showCurrentAnnotation) {
      if (
        currentData.reactangle.topLeft.x === Number.MAX_SAFE_INTEGER ||
        currentData.reactangle.topLeft.y === Number.MAX_SAFE_INTEGER
      ) {
        return null;
      }
      return (
        <Layer>
          <Rectangle
            // @ts-ignore
            c={{
              annotationType,
              topLeft: currentData.reactangle.topLeft,
              bottomRight: position,
              markupLabelId: "moot",
              createdAt: "",
              id: "",
            }}
            temporary
            isSelected={false}
            select={() => {}}
            scale={1}
            update={() => {}}
          />
          <MiniCircle p={position} />
        </Layer>
      );
    }
    return <MiniCircleLayer p={position} />;
  }

  if (tool === "BoundingBox") {
    if (showCurrentAnnotation) {
      if (
        currentData.boundingBox.topLeft.x === Number.MAX_SAFE_INTEGER ||
        currentData.boundingBox.topLeft.y === Number.MAX_SAFE_INTEGER
      ) {
        return null;
      }
      return (
        <Layer>
          <BoundingBox
            // @ts-ignore
            c={{
              annotationType,
              topLeft: currentData.boundingBox.topLeft,
              bottomRight: position,
              markupLabelId: "moot",
              createdAt: "",
              id: "",
            }}
            temporary
            isSelected={false}
            select={() => {}}
            scale={1}
            update={() => {}}
            labelColor={currentColor}
          />
          <MiniCircle p={position} />
        </Layer>
      );
    }
    return <MiniCircleLayer p={position} />;
  }

  return (
    <Layer>
      <Circle
        x={position.x}
        radius={circleRadius / 2}
        y={position.y}
        stroke="black"
        strokeWidth={getStrokeWidth(scale, "Circle")}
        dash={getDash("Rectangle", scale)}
      />
    </Layer>
  );
}
