import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Container, Divider, Header, Button } from "semantic-ui-react";
import { useQueryImageMetadataQuery } from "../../../../ts-clients/query";
import { useCreateTokenMutation } from "../../../../ts-clients/command";
import * as shared from "../../shared/noDataStates";
import { getCurrentUser, setCurrentUser } from "../../../../state/loginState";

export default function MetaData() {
  const [score, setScore] = useState(new Map<string, number>());
  const [scoreNames, setScoreNames] = useState(new Map<string, string[]>());
  const [metaOwners, setMetaOwnsers] = useState(new Map<string, string[]>());
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  // X-Dug-Auth
  const { data, loading, error, startPolling, stopPolling } =
    useQueryImageMetadataQuery();

  const [createTokenMutation] = useCreateTokenMutation();

  useEffect(() => {
    startPolling(2000);
    return function cleanup() {
      stopPolling();
    };
  });

  useEffect(() => {
    createTokenMutation({
      variables: {
        input: {
          username: currentUser.username,
          role: currentUser.role,
          queryAll: true,
        },
      },
    }).then((token) => {
      dispatch(
        setCurrentUser({
          ...currentUser,
          token: token.data?.createToken?.token ?? "",
        })
      );
    });

    return function cleanup() {
      createTokenMutation({
        variables: {
          input: {
            username: currentUser.username,
            role: currentUser.role,
            queryAll: false,
          },
        },
      }).then((token) => {
        dispatch(
          setCurrentUser({
            ...currentUser,
            token: token.data?.createToken?.token ?? "",
          })
        );
      });
    };
  }, [createTokenMutation, currentUser, dispatch]);

  useEffect(() => {
    const scoresCalc = new Map<string, number>();
    const scoresCalcNames = new Map<string, string[]>();
    const owners = new Map<string, string[]>();
    scoresCalc.set("images without metadata", 0);
    data?.queryImage?.map((image) => {
      if (image && image?.metadata.length < 1) {
        scoresCalc.set(
          "images without metadata",
          scoresCalc.has("images without metadata")
            ? (scoresCalc.get("images without metadata") ?? 0) + 1
            : 1
        );

        const getName = scoresCalcNames.get("images without metadata");

        if (getName) {
          scoresCalcNames.set("images without metadata", [
            ...getName,
            image.filename,
          ]);
        } else {
          scoresCalcNames.set("images without metadata", [image.filename]);
          owners.set("images without metadata", [""]);
        }
      }
      image?.metadata.map((meta) => {
        scoresCalc.set(
          `${meta.key}: ${meta.value}`,
          scoresCalc.has(`${meta.key}: ${meta.value}`)
            ? (scoresCalc.get(`${meta.key}: ${meta.value}`) ?? 0) + 1
            : 1
        );
        const getName = scoresCalcNames.get(`${meta.key}: ${meta.value}`);
        const getOwner = owners.get(`${meta.key}: ${meta.value}`);
        if (getName && getOwner) {
          scoresCalcNames.set(`${meta.key}: ${meta.value}`, [
            ...getName,
            image.filename,
          ]);
          if (!getOwner.includes(meta.owner)) {
            owners.set(`${meta.key}: ${meta.value}`, [...getOwner, meta.owner]);
          }
        } else {
          scoresCalcNames.set(`${meta.key}: ${meta.value}`, [image.filename]);
          owners.set(`${meta.key}: ${meta.value}`, [meta.owner]);
        }
        return meta;
      });
      return image;
    });

    setScore(scoresCalc);
    setScoreNames(scoresCalcNames);
    setMetaOwnsers(owners);
  }, [data, loading, error]);

  const download = (key: string) => {
    const getKey = scoreNames.get(key);
    if (getKey) {
      const dataKey = new Blob([getKey.join("\n")], { type: "text/plain" });
      const link = document.createElement("a");
      link.download = `${key}.txt`;
      link.href = window.URL.createObjectURL(dataKey);
      link.click();
    }
  };

  const downloadCSV = () => {
    const getTable = Array.from(score, ([key, value], k) => {
      const getKey = scoreNames.get(key);
      const getOwners = metaOwners.get(key);
      if (getKey) {
        return `${k},${key}, ${
          getOwners ? getOwners.join(",") : ""
        },${value},${getKey.join(",")}`;
      }
      return "";
    });

    const dataKey = new Blob(
      [`#,key: value, owners,#entries,filenames\n${getTable.join("\n")}`],
      { type: "text/plain" }
    );
    const link = document.createElement("a");
    link.download = `image_metadata.csv`;
    link.href = window.URL.createObjectURL(dataKey);
    link.click();
  };

  return (
    <Container style={{ marginTop: "25px" }}>
      <Header as="h1">Image Metadata</Header>
      <Divider />
      {loading && shared.renderLoading()}
      {error && shared.renderError(error)}
      <Table compact celled striped color="green">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing textAlign="center">
              #
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Key: Value</Table.HeaderCell>
            <Table.HeaderCell collapsing textAlign="center">
              Owners
            </Table.HeaderCell>
            <Table.HeaderCell collapsing textAlign="center">
              # Entries
            </Table.HeaderCell>
            <Table.HeaderCell collapsing textAlign="center">
              Download Filenames
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Array.from(score, ([key, value], k) => (
            <Table.Row key={key}>
              <Table.Cell collapsing textAlign="center">
                {k + 1}
              </Table.Cell>
              <Table.Cell>{key}</Table.Cell>
              <Table.Cell collapsing>
                {metaOwners.get(key) ? metaOwners.get(key)?.join(", ") : ""}
              </Table.Cell>
              <Table.Cell collapsing textAlign="center">
                {value}
              </Table.Cell>
              <Table.Cell collapsing textAlign="center">
                <Button size="small" onClick={() => download(key)}>
                  Download
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="6">
              <Button
                floated="right"
                icon
                primary
                size="small"
                onClick={() => downloadCSV()}
              >
                Download as CSV
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Container>
  );

  // return shared.renderEmpty();
}
