import { useMemo, useState } from "react";

type InteractionHook =
  | "stageZoomIn"
  | "stageZoomOut"
  | "paintApplyPolgon"
  | "stageResetZoom"
  | "saveAndNext";

type InteractionHookFkt = () => void;

export default function useInteractionHooks() {
  const defaultHooks = useMemo(
    () =>
      new Map<InteractionHook, InteractionHookFkt>([
        ["stageZoomIn", () => {}],
        ["stageZoomOut", () => {}],
        ["stageResetZoom", () => {}],
        ["saveAndNext", () => {}],
      ]),
    []
  );

  const [hooks, setHooks] = useState(defaultHooks);

  const setHook = useMemo(
    () => (h: InteractionHook, i: InteractionHookFkt) => {
      setHooks((hks) => {
        hks.set(h, i);
        return hks;
      });
    },
    []
  );

  const unsetHook = useMemo(
    () => (h: InteractionHook) => {
      setHooks((hks) => {
        hks.set(h, () => {});
        return hks;
      });
    },
    []
  );

  const callHook = useMemo(
    () => (h: InteractionHook) => {
      const fkt = hooks.get(h);
      if (fkt !== undefined) {
        fkt();
      }
    },
    [hooks]
  );

  const result = useMemo(
    () => ({ setHook, callHook, unsetHook }),
    [callHook, setHook, unsetHook]
  );

  return result;
}

export type InteractionHooksType = ReturnType<typeof useInteractionHooks>;
