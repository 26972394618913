import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Step } from "react-joyride";
import { RootState } from "./store";

type WelcomeTourType = {
  dashboard: Step[];
  createNetwork: Step[];
  fileList: Step[];
  markuptool: Step[];
  markuptoolSegmentation: Step[];
};

export const WelcomeTour: WelcomeTourType = {
  dashboard: [
    {
      content: "Whenever you see this dot, you can click it for more info.",
      target: '[welcome-tour-dashboard="1"]',
    },
    {
      content:
        "This is your quickstart section. Select the use-case which you're interested to continue.",
      target: '[welcome-tour-dashboard="2"]',
      disableBeacon: true,
      spotlightClicks: true,
    },
  ],
  createNetwork: [
    {
      content: "Enter a name for your first network.",
      target: '[welcome-tour-create-network="1"]',
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      content: "If you want, you can also add a description.",
      target: '[welcome-tour-create-network="2"]',
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      content: "Finally, choose a color for your network.",
      target: '[welcome-tour-create-network="3"]',
      spotlightClicks: true,
      disableBeacon: true,
    },
    {
      content: "Click Save to create your network.",
      target: '[welcome-tour-create-network="4"]',
      spotlightClicks: true,
      disableBeacon: true,
    },
  ],
  fileList: [
    {
      content: "Start by uploading your files.",
      target: '[welcome-tour-file-list="1"]',
      spotlightClicks: true,
      disableBeacon: true,
    },
    {
      content: "You can also create folders to manage your data.",
      target: '[welcome-tour-file-list="2"]',
      disableBeacon: true,
    },
    {
      content:
        "You can switch between icon and list-view. The latter is useful for searching large amounts of files.",
      target: '[welcome-tour-file-list="3"]',
      disableBeacon: true,
    },
    {
      content: "Click on any image to open the annotation tool.",
      target: '[welcome-tour-file-list="4"]',
      spotlightClicks: true,
      disableBeacon: true,
      placement: "center",
    },
  ],
  markuptool: [
    {
      content:
        "These are different tools which you can use to annotate your data.",
      target: '[welcome-tour-markuptool="1"]',
      disableBeacon: true,
    },
  ],
  markuptoolSegmentation: [
    {
      content: "Let's start by using the rectangle tool.",
      target: '[welcome-tour-markuptool-segmentation="1"]',
      spotlightClicks: true,
      disableBeacon: true,
    },
  ],
};

type StateType = {
  isActive: boolean;
  // continuous: boolean;
  // isOpen: boolean;
  // steps: Step[];
  // currentStep: number;
};

// user ui states w/o markuptool //
const name = "tourState";
const initialState: StateType = {
  isActive: false,
  // continuous: false,
  // isOpen: false,
  // steps: [],
  // currentStep: 0,
};

const appSlice = createSlice({
  name,
  initialState,
  reducers: {
    setActive: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isActive: action.payload,
      // isOpen: !action.payload ? false : state.isOpen,
    }),
    // setOpen: (state, action: PayloadAction<boolean>) => ({
    //   ...state,
    //   isOpen: action.payload,
    //   isActive: !state.isActive && action.payload ? true : state.isActive,
    // }),
    // setContinuous: (state, action: PayloadAction<boolean>) => ({
    //   ...state,
    //   continuous: action.payload,
    // }),
    // setSteps: (state, action: PayloadAction<Step[]>) => ({
    //   ...state,
    //   steps: [...action.payload],
    // }),
    // setCurrentStep: (state, action: PayloadAction<number>) => ({
    //   ...state,
    //   currentStep: action.payload,
    // }),
    // incrementStep: (state) => ({
    //   ...state,
    //   currentStep: state.currentStep + 1,
    // }),
    // decrementStep: (state) => ({
    //   ...state,
    //   currentStep: state.currentStep + 1,
    // }),
  },
});

export const {
  setActive,
  // setOpen,
  // setContinuous,
  // setSteps,
  // setCurrentStep,
  // incrementStep,
  // decrementStep,
} = appSlice.actions;

export default appSlice.reducer;

export const getIsActive = (state: RootState) => {
  return state.tourState.isActive;
};

// export const getIsOpen = (state: RootState) => {
//   return state.tourState.isOpen;
// };

// export const getContinuous = (state: RootState) => {
//   return state.tourState.continuous;
// };

// export const getSteps = (state: RootState) => {
//   return state.tourState.steps;
// };

// export const getCurrentStep = (state: RootState) => {
//   return state.tourState.currentStep;
// };
