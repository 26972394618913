import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";
import { getBTechData, BTechComponents, emptyBTechData } from "./tools";
import { getMagneticFieldSettings } from "../../state/dataTypeDisplaySettings";
import { MagneticFieldScanType } from "../../types/extractedTypes";

type RenderProps = {
  d: MagneticFieldScanType;
};

export default function MagneticFieldScanAsPlot({ d }: RenderProps) {
  const mfSettings = useSelector(getMagneticFieldSettings);
  const [bTechData, setBTechData] = useState<BTechComponents>(emptyBTechData);

  const startRow =
    mfSettings.showOnlyLastScan && d.startIndices
      ? // @ts-ignore
        d.startIndices.slice().sort((a, b) => a - b)[d.startIndices.length - 1]
      : undefined;

  useEffect(() => {
    if (d && d.id !== undefined) {
      getBTechData(d.binaryURL, mfSettings.subtractBaseline, startRow)
        .then(setBTechData)
        .catch(console.log);
    }
  }, [
    d,
    d.binaryURL,
    d.updatedAt,
    mfSettings.subtractBaseline,
    startRow,
    d.height,
  ]);

  const displayData = bTechData[mfSettings.component];

  return (
    <Plot
      style={{ width: "100%", height: "100%" }}
      data={[
        {
          // @ts-ignore
          x: [...Array(d.width).keys()].map((_, i) => i * 2.5),
          // @ts-ignore
          y: [...Array(d.height).keys()].map((_, i) => i / 30),
          z: displayData,
          type: mfSettings.plotType,
          zsmooth: "best",
          xaxis: "x",
          yaxis: "y",
          name: "Bx",
        },
      ]}
      layout={{
        margin: {
          t: 10,
          l: 40,
          r: 20,
          b: 40,
        },
        xaxis: {
          title: "s / mm",
          showticklabels: true,
        },
        yaxis: {
          title: "t / s",
          autorange: "reversed",
          showticklabels: true,
        },
      }}
      config={{
        displayModeBar: false,
      }}
    />
  );
}
