/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Card, Image, Icon, Input, Dropdown } from "semantic-ui-react";
import { useSelector } from "react-redux";
// import DatapointPreview from "../../datapoint-viewer";
import { useGoto } from "../../../utils/routeManager";
import noimg from "../../../resources/noimg.png";
import { Dataset } from "./types";
import { canMutateDataset } from "../../../state/loginState";
import useDatasetModifier from "./hooks/useDatasetModifier";
// import { getPreviewDataPoint } from "../../details-researchbox/utils";
// import { getOutputDisplayPriority } from "../../../state/datasetSettings";
import { useUIType } from "../../../hooksqueries";
// import GetSampleBoxDatapoints from "./hooks/useResearchBoxesByDatasetId";

type Props = {
  dataset: Dataset;
  startDelete: () => void;
};

const DatasetCard = ({ dataset, startDelete }: Props) => {
  const {
    rename,
    setCurrentDatasetName,
    currentDatasetName,
    activeModal,
    setActiveModal,
  } = useDatasetModifier(dataset);

  const userCanMutateDataset = useSelector(canMutateDataset);

  const hideModal = () => setActiveModal("none");
  const showRename = () => setActiveModal("rename");

  const goTo = useGoto();

  const runRename = () => {
    rename();
    hideModal();
  };

  const openDataset = () => {
    goTo("ResearchBoxList", {
      context: "researchboxlist",
      datasetId: dataset.id,
    });
  };
  const uiType = useUIType();

  return (
    <>
      <Card key={dataset.id}>
        <Image
          centered
          src={dataset.thumbnail ? dataset.thumbnail : noimg}
          onClick={openDataset}
          style={{
            width: "100%",
            height: 290,
            objectFit: "contain",
          }}
        />
        <Card.Content>
          <Card.Header>
            {activeModal === "rename" ? (
              <div>
                <Input
                  value={currentDatasetName}
                  onChange={(_, { value }) => setCurrentDatasetName(value)}
                />
                <Icon link name="check" onClick={runRename} />
                <Icon link name="delete" onClick={hideModal} />
              </div>
            ) : (
              <a onClick={openDataset}>{dataset.name}</a>
            )}
          </Card.Header>
          <Card.Description>
            {dataset?.description || <em>no description available</em>}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Icon name="images" />
          contains{" "}
          <b>
            <em>{dataset.researchBoxCount}</em>
          </b>{" "}
          elements
          <div style={{ float: "right" }}>
            <Dropdown
              disabled={!userCanMutateDataset}
              icon="ellipsis horizontal"
              labeled
              floating
              button
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={showRename}
                  icon="edit"
                  content={`Rename ${
                    uiType !== "halm" ? "Dataset" : "Portfolio"
                  }`}
                />
                <Dropdown.Item
                  onClick={startDelete}
                  icon="trash"
                  content={`Delete ${
                    uiType !== "halm" ? "Dataset" : "Portfolio"
                  }`}
                />
                {/* <Dropdown.Item
                  onClick={startCopy}
                  icon="copy"
                  content="Copy Dataset"
                /> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Content>
      </Card>
    </>
  );
};

export default DatasetCard;
