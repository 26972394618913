/* eslint-disable react/jsx-curly-newline */
import { Form } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import {
  getImageSettings,
  ResolutionOption,
  resolutionOptions,
  setImageSettings,
} from "../../../../state/dataTypeDisplaySettings";
import { ImageResolution } from "../hooks";

type Props = {
  resolutions: ImageResolution[];
};

export default function ResolutionSelector({ resolutions }: Props) {
  const dispatch = useDispatch();
  const { selectedResolutionOption } = useSelector(getImageSettings);

  const sortedResolutions = [...resolutions].sort(
    (a, b) => a.height * a.width - b.height * b.width
  );

  const slicedOptions = resolutionOptions.slice(
    resolutionOptions.length - sortedResolutions.length
  );
  const options = slicedOptions.map((option, idx) => ({
    key: option,
    value: option,
    text: `${option} (${sortedResolutions[idx].width}x${sortedResolutions[idx].height})`,
  }));

  const selectedResolutionOptionIndex = slicedOptions.indexOf(
    selectedResolutionOption
  );

  return (
    <Form.Dropdown
      placeholder="Resolution"
      label="Resolution"
      fluid
      button
      onChange={(_, { value }) =>
        dispatch(
          setImageSettings({
            selectedResolutionOption: value as unknown as ResolutionOption,
          })
        )
      }
      options={options}
      value={
        selectedResolutionOptionIndex >= 0
          ? selectedResolutionOption
          : options[0].value
      }
    />
  );
}
