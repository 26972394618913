import { Button, Header } from "semantic-ui-react";
import { ModuleType } from "ModuleTypes";
import { getNiceModuleName } from "../../../flow-designer/shared";

type Props = {
  networkType: ModuleType | null;
  onCancel: () => void;
};
export default function Headline({ networkType, onCancel }: Props) {
  if (networkType === null) {
    return (
      <Header as="h1">
        Choose Network Type
        <Button
          floated="right"
          icon="arrow left"
          content="back"
          basic
          onClick={onCancel}
        />
      </Header>
    );
  }
  return (
    <Header as="h1">
      Create New {getNiceModuleName(networkType)}
      <Button
        floated="right"
        icon="arrow left"
        content="back"
        basic
        onClick={onCancel}
      />
    </Header>
  );
}
