import { freeformToPoly } from "../../hooks/annotationHelper";
import { MyPolygon } from "../../hooks/types";
import Polygon from "./lowlevel/NewPoly";
import { getPolygonConfig } from "./lowlevel/util";

type Props = {
  c: MyPolygon;
  selectedMarkupLabelId?: string | null;
  labelColor: string;
  temporary?: boolean;
  select?: () => void;
  isSelected?: boolean;
  isHighlighted: boolean;
  scale: number;
};

export default function FreeForm({
  c,
  selectedMarkupLabelId = null,
  labelColor,
  temporary = false,
  select,
  isSelected = false,
  scale,
  isHighlighted,
}: Props) {
  const points = freeformToPoly(c);

  const getConfig = (isMouseOver: boolean) =>
    getPolygonConfig(
      c.annotationType,
      "Polygon",
      isMouseOver,
      isSelected,
      isHighlighted,
      temporary,
      selectedMarkupLabelId === c.markupLabelId,
      labelColor,
      scale
    );

  return (
    <Polygon
      points={points}
      getConfig={getConfig}
      select={select}
      isSelected={isSelected}
    />
  );
}

FreeForm.defaultProps = {
  temporary: false,
  select: undefined,
  isSelected: false,
  selectedMarkupLabelId: null,
};
