/* eslint-disable react/jsx-one-expression-per-line */
import { useSelector } from "react-redux";
import { Container, Card, Image } from "semantic-ui-react";
import { canUseNetworkAdvanced } from "../../../../state/loginState";
import anomaly from "./img/anomaly.png";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import image_classification_advanced from "./img/image_classification_advanced.png";
import image_classification from "./img/image_classification.png";

import semantic_segmentation from "./img/semantic_segmentation.png";
import semantic_segmentation_advanced from "./img/semantic_segmentation_advanced.png";

import instance_segmentation from "./img/instance_segmentation.png";
import object_counting from "./img/object_counting.png";
import object_detection from "./img/object_detection.png";
import ocr from "./img/ocr.png";
import superresolution from "./img/superresolution.png";
import { PretrainedNetworkType } from "../../../../types/extractedTypes";

type Props = {
  complexNetworkCreation: boolean;
  carousel?: boolean;
  isReady: boolean;
  onSelect: (t: PretrainedNetworkType) => void;
};

type NetworkDesciption = {
  title: string;
  active: boolean;
  type: PretrainedNetworkType;
  visible: boolean;
  img: string;
  description: JSX.Element | string;
  features: {
    annotationSpeed: number;
    trainingResources: number;
    objectDetails: number;
  };
};

// const percentToColor = (p: number): "red" | "yellow" | "green" => {
//   if (p <= 40) {
//     return "green";
//   }
//   if (p < 75) {
//     return "yellow";
//   }
//   return "red";
// };

// const MyProgress = ({ p }: { p: number }) => (
//   <Progress
//     style={{ top: 7 }}
//     size="tiny"
//     percent={p}
//     color={percentToColor(p)}
//   />
// );

function makeNetworkTypes(complex: boolean, networkAdvanced: boolean) {
  const networkTypes: NetworkDesciption[] = [
    {
      title: "Image Classification",
      features: {
        annotationSpeed: 0,
        objectDetails: 0,
        trainingResources: 0,
      },
      type: "PipelineModuleNetworkImageClassification",
      active: true,
      visible: true,
      img: networkAdvanced
        ? image_classification_advanced
        : image_classification,
      description: (
        <>A network that determines which objects are present on an image.</>
      ),
    },
    {
      title: "Image Segmentation",
      features: {
        annotationSpeed: 50,
        objectDetails: 50,
        trainingResources: 50,
      },
      type: "PipelineModuleNetworkImageSegmentation",
      active: true,
      visible: true,
      img: networkAdvanced
        ? semantic_segmentation_advanced
        : semantic_segmentation,
      description: (
        <>
          A network that predicts to which class a pixel belongs. Overlapping
          instances <b>cannot</b> be seperated.
        </>
      ),
    },

    {
      title: "Object Detection",
      features: {
        annotationSpeed: 25,
        objectDetails: 25,
        trainingResources: 25,
      },
      type: "PipelineModuleNetworkImageObjectDetection",
      active: true,
      visible: true,
      img: object_detection,
      description: (
        <>
          A network that finds objects on images and predicts their bounding
          boxes. Overlapping instances <b>can</b> be seperated.
        </>
      ),
    },

    {
      title: "Object Counting",
      features: {
        annotationSpeed: 25,
        objectDetails: 25,
        trainingResources: 25,
      },
      type: "PipelineModuleNetworkImageObjectCounting",
      active: false,
      visible: complex,
      img: object_counting,
      description: (
        <>
          A network that detects <b>how many</b> instances of an object are
          present on an image. Overlapping instances <b>can</b> be seperated.
        </>
      ),
    },
    {
      title: "Instance Segmentation",
      features: {
        annotationSpeed: 75,
        objectDetails: 100,
        trainingResources: 100,
      },
      type: "PipelineModuleNetworkImageInstanceSegmentation",
      active: false,
      visible: complex,
      img: instance_segmentation,
      description: (
        <>
          A network that finds objects on images with pixel accuracy and
          bounding box prediction. Overlapping instances <b>can</b> be
          seperated.
        </>
      ),
    },
    {
      title: "Anomaly Detection",
      features: {
        annotationSpeed: 0,
        objectDetails: 50,
        trainingResources: 25,
      },
      type: "PipelineModuleNetworkImageAnomalyDetection",
      active: false,
      visible: complex,
      img: anomaly,
      description: (
        <>
          A network that detects any <b>deviations from a norm</b> on an image.
        </>
      ),
    },
    {
      title: "Text Detection",
      features: {
        annotationSpeed: 0,
        objectDetails: 0,
        trainingResources: 0,
      },
      type: "PipelineModuleNetworkImageOCR",
      active: false,
      visible: complex,
      img: ocr,
      description: <>A network that finds characters or words on an image.</>,
    },
    {
      title: "Super Resolution",
      features: {
        annotationSpeed: 0,
        objectDetails: 0,
        trainingResources: 0,
      },
      type: "PipelineModuleNetworkImageSuperResolution",
      active: false,
      visible: complex,
      img: superresolution,
      description: (
        <>
          Upscale a low resolution image to higher resolutions with the help of
          our AI.
        </>
      ),
    },
  ];
  return networkTypes;
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function ChooseMainNetworkType({
  onSelect,
  complexNetworkCreation,
  carousel,
  isReady,
}: Props) {
  const networkAdvanced = useSelector(canUseNetworkAdvanced);
  const networkTypes = makeNetworkTypes(
    complexNetworkCreation,
    networkAdvanced
  );

  const cards = networkTypes.map((nt) =>
    nt.visible ? (
      <Card
        style={{ opacity: isReady ? (nt.active ? undefined : 0.5) : 0.5 }}
        key={nt.type}
        link={nt.active}
        onClick={nt.active ? () => onSelect(nt.type) : undefined}
      >
        <Image src={nt.img} wrapped ui={false} style={{ minHeight: 357 }} />
        <Card.Content>
          <Card.Header content={nt.title} />
          <Card.Description content={nt.description} />
        </Card.Content>
        {/* <Card.Content extra>
      <Grid columns="2" className="cramped">
        <Grid.Column textAlign="right">Annotation-Speed:</Grid.Column>
        <Grid.Column>
          <MyProgress p={nt.features.annotationSpeed} />
        </Grid.Column>
        <Grid.Column textAlign="right">
          Training-Ressources:
        </Grid.Column>
        <Grid.Column>
          <MyProgress p={nt.features.trainingResources} />
        </Grid.Column>
        <Grid.Column textAlign="right">Object-Details:</Grid.Column>
        <Grid.Column>
          <MyProgress p={nt.features.objectDetails} />
        </Grid.Column>
      </Grid>
    </Card.Content> */}
      </Card>
    ) : null
  );

  return (
    <Container style={{ marginTop: "15px" }}>
      {!carousel && <Card.Group itemsPerRow={3}>{cards}</Card.Group>}
      {carousel && (
        <Carousel
          responsive={responsive}
          infinite
          autoPlay={true}
          autoPlaySpeed={5000}
          showDots={true}
        >
          {cards}
        </Carousel>
      )}
    </Container>
  );
}
