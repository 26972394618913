import { useRef, useEffect } from "react";
import { AnnotationType } from "../../../../../../ts-clients/command";

type Props = {
  dataURL: string;
  iconSize: number;
  type: AnnotationType | undefined;
  labelColor: string;
};

// angepasste version von
// https://stackoverflow.com/questions/1255512/how-to-draw-a-rounded-rectangle-using-html-canvas
function roundRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radiusinput: number
) {
  const radius = {
    tl: radiusinput,
    tr: radiusinput,
    br: radiusinput,
    bl: radiusinput,
  };
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radius.br,
    y + height
  );
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  ctx.fillStyle = "#e5e5e5";
  ctx.fill();
}

export default function MarkupIconPixel({
  iconSize,
  type,
  dataURL,
  labelColor,
}: Props) {
  // const [isHighlighted, setHighlighted] = React.useState<boolean>(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current !== null) {
      const context = canvasRef.current.getContext("2d");
      if (context !== null) {
        const img = new Image();
        img.src = dataURL;
        img.onload = () => {
          const ratio = img.width / img.height;
          const width = ratio > 1 ? iconSize : iconSize * ratio;
          const height = ratio > 1 ? iconSize / ratio : iconSize;
          const padLeft = (iconSize - width) / 2;
          const padHeight = (iconSize - height) / 2;

          const w = width - 4 > 1 ? width - 4 : 1;
          const h = height - 4 > 1 ? height - 4 : 1;

          context.clearRect(0, 0, iconSize, iconSize);
          context.drawImage(img, padLeft + 2, padHeight + 2, w, h);
          const data = context.getImageData(padLeft + 2, padHeight + 2, w, h);

          for (let i = 0; i < data.data.length / 4; ++i) {
            if (data.data[i * 4] === 0) data.data[i * 4 + 3] = 0;
          }

          context.putImageData(data, padLeft + 2, padHeight + 2);

          context.globalCompositeOperation = "source-in";
          context.fillStyle = labelColor;
          context.rect(padLeft + 2, padHeight + 2, w, h);
          context.fill();
          context.globalCompositeOperation = "destination-over";
          roundRect(context, 0, 0, iconSize, iconSize, 3);
        };
      }
    }
  }, [iconSize, dataURL, type, labelColor]);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <canvas
      ref={canvasRef}
      height={iconSize}
      width={iconSize}
      // onMouseOver={() => setHighlighted(true)}
      // onMouseOut={() => setHighlighted(false)}
    />
  );
}
