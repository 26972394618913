import useDenkBoxControl from "./useDenkBoxControl";
import { getInfoMessages } from "./helper";

import useNetworkDetailsWithFakeState from "./useNetworkDetailsWithFakeState";

export default function useDenkBox(
  networkId: string,
  datasetId?: string,
  pollInterval?: number,
  skip?: boolean
) {
  const denkboxControl = useDenkBoxControl(networkId);

  const networkDetails = useNetworkDetailsWithFakeState(
    datasetId,
    networkId,
    pollInterval,
    skip
  );

  const startTraining = () => {
    denkboxControl.startTraining();
    networkDetails.fakeActions.startTraining();
  };
  const stopTraining = () => {
    denkboxControl.stopTraining();
    networkDetails.fakeActions.startTraining();
  };
  const startPrediction = (researchBoxIds: string[]) => {
    denkboxControl.startInference(researchBoxIds);
    networkDetails.fakeActions.startPrediction();
  };
  const stopPrediction = () => {
    denkboxControl.stopInference();
    networkDetails.fakeActions.stopPrediction();
  };

  const infoMessages = getInfoMessages(
    networkDetails.networkState,
    networkDetails.stats.hasSufficientData
  );

  const actionsAvailable = {
    startTraining:
      networkDetails.stats.hasSufficientData &&
      !networkDetails.stats.trainingRunning,
    stopTraining: networkDetails.stats.trainingRunning,
    startPrediction: networkDetails.stats.networkExists,
    stopPrediction: networkDetails.stats.predictionRunning,
  };

  const actions = {
    startTraining,
    stopTraining,
    startPrediction,
    stopPrediction,
  };

  return {
    networkDetails,
    actions,
    actionsAvailable,
    infoMessages,
  };
}

export type DenkBoxReturnType = ReturnType<typeof useDenkBox>;
export type DenkboxActions = DenkBoxReturnType["actions"];
export type DenkboxAvailableActions = DenkBoxReturnType["actionsAvailable"];
export type NetworkStats = DenkBoxReturnType["networkDetails"]["stats"];
