import {
  useAddDatasetMutation,
  AddDatasetInput,
} from "../../../../ts-clients/command";

export default function useAddDataset() {
  const [mutate] = useAddDatasetMutation();
  return (input: AddDatasetInput) =>
    mutate({
      variables: {
        input,
      },
    });
}
