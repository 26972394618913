import useHook from "../../../hooks/moduleCreators-new-2021-04/PMNetworkImageClassificationUpdate";
import ModuleForm from "./forms/FormPipelineModuleNetworkImageClassification";

type Props = {
  moduleId: string;
  datasetId: string | null;
  onCancel: () => unknown;
};

export default function PMNetworkImageClassificationUpdate({
  moduleId,
  datasetId,
  onCancel,
}: Props) {
  const { moduleData, setBaseParams, setModuleData, update } =
    useHook(moduleId);

  const onSubmit = () => {
    update();
    onCancel();
  };

  return (
    <ModuleForm
      moduleData={moduleData}
      datasetId={datasetId}
      onCancel={onCancel}
      onSave={onSubmit}
      setBaseParams={setBaseParams}
      setData={setModuleData}
      moduleId={moduleId}
      mode="update"
    />
  );
}
