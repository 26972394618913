/* eslint-disable react/jsx-one-expression-per-line */
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useDeleteFolderMutation,
  useDeleteResearchBoxMutation,
} from "../../../ts-clients/command";
import useFindPaginatedFoldersAndResearchBoxesByDatasetIdAndFolderId from "../../../hooksqueries/useFindPaginatedFoldersAndResearchBoxesByDatasetIdAndFolderId";
import {
  getFirstElement,
  getLastElement,
  setResearchBoxCount,
} from "../../../state/researchboxfilter";
import { FolderType, ResearchBoxType } from "../../../types/extractedTypes";

type DeleteCandidateType = ResearchBoxType | FolderType | null;

export default function useResearchBoxList(datasetId: string) {
  const dispatch = useDispatch();

  const [deleteCandidates, setDeleteCandidates] = useState<
    DeleteCandidateType[]
  >([]);

  const firstIdx = useSelector(getFirstElement) - 1;

  const query = useFindPaginatedFoldersAndResearchBoxesByDatasetIdAndFolderId();

  const {
    ret: response,
    loading,
    startPolling,
    stopPolling,
    refetch,
  } = query(
    datasetId,
    useSelector(getLastElement) - useSelector(getFirstElement) + 1,
    useSelector(getFirstElement) - 1
  );

  useEffect(() => {
    dispatch(setResearchBoxCount(response.totalCount));
  }, [dispatch, response.totalCount]);

  const boxIds = deleteCandidates.flatMap((c) =>
    c?.__typename === "ResearchBox" ? c.id : []
  );

  const folderIds = deleteCandidates.flatMap((c) =>
    c?.__typename === "Folder" ? c.id : []
  );

  const [deleteResearchBox] = useDeleteResearchBoxMutation({
    variables: {
      input: {
        ids: boxIds,
      },
    },
  });

  const [deleteFolder] = useDeleteFolderMutation({
    variables: {
      input: {
        ids: folderIds,
      },
    },
  });

  const handleDelete = async () => {
    if (boxIds.length > 0) {
      await deleteResearchBox();
    }
    if (folderIds.length > 0) {
      await deleteFolder();
    }
    setDeleteCandidates([]);
  };

  return {
    loading,
    folderCount: response.folderCount,
    objects: response.objects,
    deleteCandidates,
    firstIdx,
    handleDelete,
    setDeleteCandidates,
    startPolling,
    stopPolling,
    refetch,
  };
}
