import { Column, SortConfig } from "NetworkList";
import { Table } from "semantic-ui-react";

const columns: Column[] = [
  { span: 1, sortable: true, name: "", type: "trainingState", key: "1" },
  { span: 1, sortable: true, name: "Name", type: "name", key: "2" },
  {
    span: 1,
    sortable: true,
    name: "Network Type",
    type: "networkType",
    key: "3",
  },
  {
    span: 1,
    sortable: true,
    name: "# Training Set",
    type: "trainingSetSize",
    key: "4",
  },
  {
    span: 1,
    sortable: true,
    name: "# Validation Set",
    type: "validationSetSize",
    key: "5",
  },
  {
    span: 1,
    sortable: true,
    name: "Label Count",
    type: "labelCount",
    key: "6",
  },
  { span: 1, sortable: true, name: "Score", type: "selectedMetric", key: "7" },
  {
    span: 1,
    sortable: true,
    name: "Last Changed",
    type: "lastChange",
    key: "8",
  },
  {
    span: 1,
    sortable: true,
    name: "Training State",
    type: "trainingState",
    key: "9",
  },
  { span: 1, sortable: false, name: "", type: undefined, key: "10" },
];

type Props = {
  sortConfig: SortConfig;
  setSortConfig: React.Dispatch<React.SetStateAction<SortConfig>>;
};

export default function SortHeaders({ sortConfig, setSortConfig }: Props) {
  return (
    <Table.Header>
      <Table.Row>
        {columns.map((c) => (
          <Table.HeaderCell
            key={c.key}
            colSpan={c.span}
            content={c.name}
            sorted={
              c.sortable && sortConfig.column === c.type
                ? sortConfig.ascending
                  ? "ascending"
                  : "descending"
                : undefined
            }
            onClick={() =>
              c.sortable &&
              setSortConfig((d) => ({
                column: c.type,
                ascending: c.type === d.column ? !d.ascending : true,
              }))
            }
          />
        ))}
      </Table.Row>
    </Table.Header>
  );
}
