import { useDeletePipelineModuleEdgeMutation } from "../ts-clients/command";

export default function useRemovePiplineModuleEddge() {
  const [deleteEdge] = useDeletePipelineModuleEdgeMutation();

  return (pipelineModuleEdgeId: string) =>
    deleteEdge({
      variables: {
        input: {
          ids: [pipelineModuleEdgeId],
        },
      },
    });
}
