import { ImageDataFromB64 } from "@denkweit/image-tools";
import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";

export interface IImageTools {
  init(): void;
  change_color(
    image_data: Uint8Array,
    red: number,
    green: number,
    blue: number,
    alpha: number
  ): Uint8Array;
  erode(
    image_data: Uint8Array,
    width: number,
    height: number,
    kernel_size?: number
  ): Uint8Array;
  dilate(
    image_data: Uint8Array,
    width: number,
    height: number,
    kernel_size?: number
  ): Uint8Array;
  btoa(base64: string): Uint8Array;
  base64_to_png_to_array(b64String: string): ImageDataFromB64;
  generate_pattern(
    pattern_kind: number,
    color: string,
    opacity: number
  ): string;
}

type ImageToolsType = {
  loading: boolean;
  wasm: IImageTools | undefined;
};

const init: ImageToolsType = {
  loading: true,
  wasm: undefined,
};

const useImageToolsImpl = () => {
  const [wasm, setWasm] = useState<IImageTools>();

  useEffect(() => {
    const load = async () => {
      import("@denkweit/image-tools")
        .then((wasm) => {
          wasm.init();
          return wasm;
        })
        .then(setWasm)
        .then(() => console.log("image-tools loaded"));
    };
    if (!wasm) {
      load();
    }
  }, [wasm]);

  return { loading: !wasm, wasm };
};

export const useImageTools = singletonHook(init, useImageToolsImpl);
