import cuid from "cuid";
import { AnnotationLabel, DataType } from "MarkupTypes";
import { useMemo } from "react";
import { ImageDatapointType } from "../../../../types/extractedTypes";

type AnnotationLike = {
  __typename?: string;
  value?: number;
  label: {
    name: string;
    color: string;
  };
};

const dpToAnnotationLabelMaker =
  (d: DataType) =>
  (dp: AnnotationLike[]): AnnotationLabel[] =>
    dp.map((a) => ({
      id: cuid(),
      name: a.label.name,
      color: a.label.color,
      value: a.value !== undefined ? a.value : 1,
      type: d,
    }));

const makeSegmentationLabels = dpToAnnotationLabelMaker("SegmentationMarkup");

const makeScalarLabels = dpToAnnotationLabelMaker("ClassificationMarkup");

const makeObjectDetectionLabels = dpToAnnotationLabelMaker(
  "ObjectDetectionMarkup"
);

export default function useAnnotationLabels(
  datapoint: ImageDatapointType | null
) {
  const markups = useMemo(() => datapoint?.markups ?? [], [datapoint?.markups]);

  const scalarLabels = useMemo(
    () =>
      markups
        //@ts-ignore
        .flatMap((m) => (m.__typename === "ClassificationMarkup" ? m : []))
        .reduce(
          //@ts-ignore
          (acc, cur) => acc.concat(makeScalarLabels(cur.annotations)),
          [] as AnnotationLabel[]
        ),
    [markups]
  );

  const segmentationLabels = useMemo(
    () =>
      markups
        //@ts-ignore
        .flatMap((m) => (m.__typename === "SegmentationMarkup" ? m : []))
        .reduce(
          //@ts-ignore
          (acc, cur) =>
            acc.concat([cur.annotations].map(makeSegmentationLabels).flat()),
          [] as AnnotationLabel[]
        ),
    [markups]
  );

  const objectDetectionLabels = useMemo(
    () =>
      markups
        //@ts-ignore
        .flatMap((m) => (m.__typename === "ObjectDetectionMarkup" ? m : []))
        .reduce(
          //@ts-ignore
          (acc, cur) =>
            acc.concat([cur.annotations].map(makeObjectDetectionLabels).flat()),
          [] as AnnotationLabel[]
        ),
    [markups]
  );

  const groupedLabels = useMemo(
    () =>
      //@ts-ignore
      segmentationLabels.reduce((acc, cur) => {
        const existingIndex = acc.findIndex(
          //@ts-ignore
          (c) => c.color === cur.color && c.name === cur.name
        );
        if (existingIndex > -1) {
          acc[existingIndex].value += 1;
        } else {
          acc.push(cur);
        }
        return acc;
      }, [] as AnnotationLabel[]),
    [segmentationLabels]
  );

  const groupedObjectDetectionLabels = useMemo(
    () =>
      //@ts-ignore
      objectDetectionLabels.reduce((acc, cur) => {
        const existingIndex = acc.findIndex(
          //@ts-ignore
          (c) => c.color === cur.color && c.name === cur.name
        );
        if (existingIndex > -1) {
          acc[existingIndex].value += 1;
        } else {
          acc.push(cur);
        }
        return acc;
      }, [] as AnnotationLabel[]),
    [objectDetectionLabels]
  );

  const result = useMemo(
    () => ({
      scalarLabels,
      segmentationLabels: groupedLabels,
      objectDetectionLabels: groupedObjectDetectionLabels,
    }),
    [groupedLabels, scalarLabels, groupedObjectDetectionLabels]
  );

  return result;
}
