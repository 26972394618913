import { useState } from "react";
import { Container, Form, Button, Header, Divider } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import roleOptions from "./roles";
import { useAddUserMutation, Role } from "../../../ts-clients/command";

type PwType = {
  pw1: string;
  pw2: string;
};

const defaultUser: UserInput = {
  email: "",
  username: "",
  password: "",
  role: Role.Unauthorized,
  name: undefined,
  surname: undefined,
  phone: undefined,
  vendorName: undefined,
  trainingQuota: 0,
  inferenceQuota: 0,
};

type UserInput = {
  username: string;
  email: string;
  password: string;
  role: Role;
  name: string | undefined;
  surname: string | undefined;
  phone: string | undefined;
  vendorName: string | undefined;
  trainingQuota: number | undefined;
  inferenceQuota: number | undefined;
};

// check if Password is ok
const isPwOk = (p: PwType) => {
  if (p.pw1.length < 8) {
    return {
      content: "Password must have a minimum length of 8",
      pointing: "below",
    };
  }
  if (p.pw1 !== p.pw2) {
    return {
      content: "Passwords do not match",
      pointing: "below",
    };
  }
  return false;
};

const isEmailOk = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email.toLowerCase())) {
    return { content: "Please enter a valid email address", pointing: "below" };
  }
  return false;
};

const isPhoneNumberOk = (phone: string) => {
  const re = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  if (!re.test(phone.toLowerCase())) {
    return { content: "Please enter a valid phone number", pointing: "below" };
  }
  return false;
};

export default function AddUser() {
  const history = useHistory();

  const [createAccountMutation] = useAddUserMutation();
  const [pw, setPw] = useState<PwType>({ pw1: "", pw2: "" });
  const [user, setUserData] = useState<UserInput>({
    ...defaultUser,
  });

  const saveAccount = async () => {
    await createAccountMutation({
      variables: {
        input: user,
      },
    });

    history.goBack();
  };

  return (
    <Container style={{ marginTop: "25px" }}>
      {/* HEADER */}
      <Header as="h1">Add User</Header>
      <Divider />

      {/* EDIT MEMBER FORM */}
      <Form>
        <Form.Input
          label="Username"
          placeholder="Username"
          value={user.username}
          onChange={(_e, { value }) =>
            setUserData({ ...user, username: value })
          }
        />
        <Form.Input
          error={isPwOk(pw)}
          label="Password"
          type="password"
          placeholder="Password"
          value={pw.pw1}
          onChange={(_e, { value }) => {
            setPw({ ...pw, pw1: value });
            setUserData({ ...user, password: value });
          }}
        />
        <Form.Input
          error={!!isPwOk(pw)}
          label="Repeat Password"
          type="password"
          placeholder="Repeat Password"
          value={pw.pw2}
          onChange={(_e, { value }) => {
            setPw({ ...pw, pw2: value });
          }}
        />
        <Form.Dropdown
          label="Role"
          placeholder="Select Role"
          fluid
          selection
          options={roleOptions}
          onChange={(_, { value }) =>
            setUserData({
              ...user,
              role: value as unknown as Role,
            })
          }
        />
        <Form.Input
          error={isEmailOk(user.email)}
          label="E-Mail"
          placeholder="E-Mail"
          type="email"
          value={user.email}
          onChange={(_e, { value }) => setUserData({ ...user, email: value })}
        />
        <Divider />
        <Form.Input
          label="Training Quota"
          placeholder="How many training jobs can this user run?"
          type="number"
          value={user.trainingQuota || 0}
          onChange={(_e, { value }) =>
            setUserData({ ...user, trainingQuota: parseInt(value) })
          }
        />
        <Form.Input
          label="Inference Quota"
          placeholder="How many inference jobs (auto-prediction, test-prediction) can this user run?"
          type="number"
          value={user.inferenceQuota || 0}
          onChange={(_e, { value }) =>
            setUserData({ ...user, inferenceQuota: parseInt(value) })
          }
        />
        <Divider />
        <Form.Input
          label="Name"
          placeholder="Name"
          value={user.name || ""}
          onChange={(_e, { value }) => setUserData({ ...user, name: value })}
        />

        <Form.Input
          label="Surname"
          placeholder="Surname"
          value={user.surname || ""}
          onChange={(_e, { value }) => setUserData({ ...user, surname: value })}
        />
        <Form.Input
          error={!!user.phone && isPhoneNumberOk(user.phone)}
          label="Phone"
          placeholder="Phone"
          type="tel"
          value={user.phone || ""}
          onChange={(_e, { value }) => setUserData({ ...user, phone: value })}
        />

        <Button onClick={saveAccount} positive content="Submit" />
        <Button negative onClick={history.goBack} content="cancel" />
      </Form>
    </Container>
  );
}
