import { useMemo } from "react";
import { AnnotationType } from "../../../../ts-clients/command";
import { useGetObjectDetectionMarkupForImagesAndNetworkQuery } from "../../../../ts-clients/query";
import {
  MyLabeledBoundingBox,
  PlattformAnnotationsAndPredictions,
} from "../hooks/types";

export default function useGetAnnotations(imageId: string, networkId: string) {
  const { data, refetch } = useGetObjectDetectionMarkupForImagesAndNetworkQuery(
    {
      variables: {
        imageIds: [imageId],
        networkId,
      },
      fetchPolicy: "network-only",
    }
  );

  const predictions =
    data?.queryImage
      ?.map((i) =>
        i?.predictions.flatMap((m) =>
          m.__typename === "ObjectDetectionPrediction" ? m : []
        )
      )
      .flat(1) ?? [];

  const prediction = predictions[0];

  const markups =
    data?.queryImage
      ?.map((i) =>
        i?.markups.flatMap((m) =>
          m.__typename === "ObjectDetectionMarkup" ? m : []
        )
      )
      .flat(1) ?? [];

  const markup = markups[0];
  const boundingBoxAnnotations: MyLabeledBoundingBox[] = useMemo(() => {
    const annotations: MyLabeledBoundingBox[] =
      markup?.annotations
        .flatMap((a) => (a.__typename === "BoundingBoxAnnotation" ? a : []))
        .map((a) => ({
          annotationType: a.annotationType,
          bottomRight: { x: a.bottomRight.x, y: a.bottomRight.y },
          topLeft: { x: a.topLeft.x, y: a.topLeft.y },
          markupLabelId: a.label.id,
          markupLabelName: a.label.name,
          markupLabelColor: a.label.color,
          dataURL: "",
        })) || [];
    const predictions =
      prediction?.predictions
        .flatMap((a) => (a.__typename === "BoundingBoxPrediction" ? a : []))
        .map((a) => ({
          annotationType: AnnotationType.Positive,
          bottomRight: { x: a.bottomRight.x, y: a.bottomRight.y },
          topLeft: { x: a.topLeft.x, y: a.topLeft.y },
          markupLabelId: a.label.id,
          markupLabelName: a.label.name,
          markupLabelColor: a.label.color,
          isPrediction: true,
          probability: a.probability,
          dataURL: "",
        })) || [];
    return annotations.concat(predictions);
  }, [markup?.annotations, prediction?.predictions]);

  const markupId = markup?.id || "";

  const annotations: PlattformAnnotationsAndPredictions = useMemo(
    () => ({
      rectangleAnnotations: [],
      sausageAnnotations: [],
      boundingBoxAnnotations,
      magicwandAnnotations: [],
      pixelAnnotations: [],
      networkId: markup?.network.id || "",
      imageHeight: 0,
      imageWidth: 0,
      imageIds: [imageId],
      markupHeight: 0,
      markupWidth: 0,
      circleAnnotations: [],
      penAnnotations: [],
      polygonAnnotations: [],
    }),
    [imageId, markup?.network.id, boundingBoxAnnotations]
  );

  const result = useMemo(
    () => ({ markupId, annotations }),
    [annotations, markupId]
  );

  const returnresult =
    markups.length === 0 && predictions.length === 0 ? undefined : result;

  return { markup: returnresult, refetch, subset: markup?.subset };
}
