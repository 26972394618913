import { BaseParams } from "ModuleTypes";
import { useEffect, useState } from "react";
import { useUpdatePipelineModuleContrastEnhancementMutation } from "../../ts-clients/command";
import { useGetPipelineModuleContrastEnhancementQuery } from "../../ts-clients/query";
import { pipelineModuleContrastEnhancementDefaults as defaults } from "./defaults";

export default function useUpdatePipelineModuleCCChecker(
  pipelineModuleId: string
) {
  const [moduleData, setModuleData] = useState(defaults());
  const { data } = useGetPipelineModuleContrastEnhancementQuery({
    variables: {
      id: pipelineModuleId,
    },
  });

  useEffect(() => {
    const d = data?.getPipelineModuleContrastEnhancement;
    const outputs = d?.outputs ?? [];
    const firstOuputName = outputs.length > 0 ? outputs[0].name : "";
    setModuleData({
      clipLimit: d?.clipLimit ?? 0,
      enhancedImageName: firstOuputName,
      moduleDescription: d?.description ?? "",
      moduleName: d?.name ?? "",
      position: { x: d?.layoutPosition.x ?? 0, y: d?.layoutPosition.y ?? 0 },
      tileSize: d?.tileSize ?? 0,
      pretrainedNetworkModuleId: null,
    });
  }, [data]);

  const setBaseParams = ({ moduleDescription, moduleName }: BaseParams) => {
    setModuleData((m) => ({ ...m, moduleName, moduleDescription }));
  };

  const [updateModule] = useUpdatePipelineModuleContrastEnhancementMutation();

  const update = () => {
    updateModule({
      variables: {
        input: {
          filter: {
            moduleId: pipelineModuleId,
          },
          ...moduleData,
        },
      },
    });
  };

  return { update, moduleData, setModuleData, setBaseParams };
}
