import { createContext, ReactNode } from "react";
import { useGetResearchBox } from "../hooksqueries";
import { useGetResearchBoxReturnType } from "../hooksqueries/useGetResearchBox";

export const ResearchBoxContext = createContext<useGetResearchBoxReturnType>({
  researchbox: undefined,
  researchboxCount: 0,
  refetch: () => {},
});

type Props = {
  children: ReactNode;
};

export default function ResearchBoxProvider({ children }: Props) {
  const ret = useGetResearchBox();

  return (
    <ResearchBoxContext.Provider value={ret}>
      {children}
    </ResearchBoxContext.Provider>
  );
}
