import { useMemo } from "react";
import {
  AddImageSegmentationMarkupInput,
  AddPoint2DInput,
  useAddImageSegmentationMarkupMutation,
} from "../../../../ts-clients/command";
import { Image } from "../../../../ts-clients/query";
import { magicWandToPoly, sausageToPoly } from "../hooks/annotationHelper";

import { MyAnnotations, MyMagicWand, MySausage } from "../hooks/types";
import { polygonToDataURL } from "../hooks/utils";

type Props = {
  imageId: string;
  networkId: string;
  imageWidth: number;
  imageHeight: number;
  markupWidth: number;
  markupHeight: number;
};

const r = (n: number): number => Math.round(n);

const rp = (p: AddPoint2DInput): AddPoint2DInput => ({
  x: r(p.x),
  y: r(p.y),
});

const rpa = (ps: AddPoint2DInput[]): AddPoint2DInput[] => ps.map(rp);

const convertMagicWandForBackend = (o: MyMagicWand): MyMagicWand => {
  const poly = magicWandToPoly(o).map((p) => ({
    x: Math.round(p.x),
    y: Math.round(p.y),
  }));
  return { ...o, ...polygonToDataURL(poly) };
};

const convertSausageForBackend = (o: MySausage): MySausage => {
  const poly = sausageToPoly(o).map((p) => ({
    x: Math.round(p.x),
    y: Math.round(p.y),
  }));
  return { ...o, ...polygonToDataURL(poly) };
};

export function prepareForBackend(
  s: MyAnnotations,
  imageIds: string[],
  networkId: string,
  imageWidth: number,
  imageHeight: number,
  markupWidth: number,
  markupHeight: number
): AddImageSegmentationMarkupInput {
  return {
    imageIds,
    networkId,
    imageHeight,
    imageWidth,
    markupHeight,
    markupWidth,
    circleAnnotations: s.circleAnnotations.map((c) => ({
      annotationType: c.annotationType,
      center: rp(c.center),
      radius: r(c.radius),
      markupLabelId: c.markupLabelId,
    })),
    magicwandAnnotations: s.magicwandAnnotations
      .map((c) => convertMagicWandForBackend(c))
      .map((c) => {
        return {
          annotationType: c.annotationType,
          center: rp(c.center),
          threshold: r(c.threshold),
          bottomRight: c.bottomRight,
          topLeft: c.topLeft,
          dataURL: c.dataURL,
          markupLabelId: c.markupLabelId,
        };
      }),
    penAnnotations: s.penAnnotations.map((c) => ({
      annotationType: c.annotationType,
      points: rpa(c.points),
      thickness: r(c.thickness),
      bottomRight: c.bottomRight,
      topLeft: c.topLeft,
      dataURL: c.dataURL,
      markupLabelId: c.markupLabelId,
    })),
    pixelAnnotations: s.pixelAnnotations.map((c) => {
      return {
        annotationType: c.annotationType,
        topLeft: rp(c.topLeft),
        bottomRight: rp(c.bottomRight),
        dataURL: c.dataURL,
        markupLabelId: c.markupLabelId,
      };
    }),
    polygonAnnotations: s.polygonAnnotations.map((c) => ({
      annotationType: c.annotationType,
      points: rpa(c.points),
      markupLabelId: c.markupLabelId,
    })),
    rectangleAnnotations: s.rectangleAnnotations.map((c) => ({
      annotationType: c.annotationType,
      topLeft: rp(c.topLeft),
      bottomRight: rp(c.bottomRight),
      markupLabelId: c.markupLabelId,
    })),
    sausageAnnotations: s.sausageAnnotations
      .map((c) => convertSausageForBackend(c))
      .map((c) => ({
        annotationType: c.annotationType,
        points: rpa(c.points),
        radius: r(c.radius),
        bottomRight: c.bottomRight,
        topLeft: c.topLeft,
        dataURL: c.dataURL,
        markupLabelId: c.markupLabelId,
      })),
  };
}

export default function useSaveSegmentation2d(props: Props) {
  const [m] = useAddImageSegmentationMarkupMutation();
  return useMemo(
    () => async (annotations: MyAnnotations) => {
      m({
        variables: {
          input: {
            ...prepareForBackend(
              annotations,
              [props.imageId],
              props.networkId,
              props.imageWidth,
              props.imageHeight,
              props.markupWidth,
              props.markupHeight
            ),
          },
        },
      });
    },
    [
      m,
      props.imageHeight,
      props.imageId,
      props.imageWidth,
      props.networkId,
      props.markupHeight,
      props.markupWidth,
    ]
  );
}

export function useSaveSegmentation2dSimple(image: Image) {
  const [m] = useAddImageSegmentationMarkupMutation();
  return (annotations: MyAnnotations) => {
    m({
      variables: {
        input: {
          ...prepareForBackend(
            annotations,
            [image.id],
            annotations.networkId,
            image.width,
            image.height,
            image.width,
            image.height
          ),
        },
      },
    });
  };
}
