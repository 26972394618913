/* eslint-disable react/jsx-one-expression-per-line */
import { useParams, Link } from "react-router-dom";
import { Menu, Image } from "semantic-ui-react";
import Analyze from "../../features/analyze";
import ResearchBoxListMenu from "./ResearchBoxListMenu";
import denklight from "../../resources/denk-logo-light.svg";
import { getPath } from "../../utils/routeManager";
import MenuMain from "../../components/menus/menu.main";
import DatasetPositioner from "../../features/filemanagement/DatasetPositioner";

type Params = {
  datasetId: string;
};

export default function AnalyzePage() {
  const { datasetId } = useParams<Params>();
  return (
    <>
      <MenuMain />
      <Menu vertical fixed="left" inverted>
        <div>
          <Image
            src={denklight}
            height={26}
            style={{ margin: "45px 15px 50px 15px" }}
          />
        </div>
        <Link className="item" to={getPath("Dashboard", {})}>
          &#0171; Datasets
        </Link>
        <ResearchBoxListMenu current="analyze" datasetId={datasetId} />
      </Menu>
      <DatasetPositioner>
        <Analyze />
      </DatasetPositioner>
    </>
  );
}
