/* eslint react/jsx-props-no-spreading: off */
import { Switch, Route } from "react-router-dom";
import { Container } from "semantic-ui-react";
import BaseSettings from "./BaseSettings";
import Visualisation from "./Visualisation";

export default function Routes() {
  return (
    <Container style={{ marginTop: "15px" }}>
      <Switch>
        <Route
          path="/datasetsettings/:datasetId/base"
          component={BaseSettings}
        />
        <Route
          path="/datasetsettings/:datasetId/visualisation"
          component={Visualisation}
        />
      </Switch>
    </Container>
  );
}
