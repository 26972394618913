import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useGetLastBtechResult } from "../../hooksqueries";
import Preview from "../datapoint-viewer";
import { getPath } from "../../utils/routeManager";

import { isValidScan } from "../../state/deviceControl";
import { DatapointType } from "../../types/extractedTypes";

type Props = {
  datasetId: string;
};

export default function LastCCCheckerResult({ datasetId }: Props) {
  const { datapoints, startPolling, stopPolling, researchboxIdx } =
    useGetLastBtechResult(datasetId);

  const isValid = useSelector(isValidScan);

  useEffect(() => {
    startPolling(3000);
    return function cleanup() {
      stopPolling();
    };
  });

  if (datapoints === null || datapoints === undefined) {
    return <Message info content="No displayable results found yet." />;
  }

  return isValid ? (
    <Card.Group>
      {
        // @ts-ignore
        datapoints?.map((d) => (
          <Card key={d.id}>
            <div style={{ width: "100%", height: 290, overflow: "hidden" }}>
              <Preview datapoint={d as DatapointType} key={d.id} />
            </div>
            <Card.Content>
              <Card.Header>
                <Link
                  to={getPath("DetailsDatapoint", {
                    datasetId,
                    listIndex: researchboxIdx,
                    context: "sensorimport",
                    datapointId: d.id,
                    page: "asMetric",
                  })}
                >
                  {d.producedBy.name || <em>unlabeled</em>}
                </Link>
              </Card.Header>
            </Card.Content>
          </Card>
        ))
      }
    </Card.Group>
  ) : null;
}
