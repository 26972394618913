/* eslint-disable react/jsx-curly-newline */
import { Dropdown, Input } from "semantic-ui-react";
import { MarkupModule, ModuleIO } from "../hooks/types";

export type SelectorDropdownProps = {
  setMarkupModule: React.Dispatch<React.SetStateAction<MarkupModule | null>>;
  setInput: React.Dispatch<React.SetStateAction<ModuleIO | null>>;
  markupModules: MarkupModule[];
  inputs: ModuleIO[];
  selectedInputId: string;
  selectedMarkupModuleId: string;
};

type Props = {
  p: SelectorDropdownProps;
};

export default function SourceSelector({ p }: Props) {
  if (p.markupModules.length > 1 || p.inputs.length > 1) {
    return (
      <>
        {p.markupModules.length > 1 && (
          <Dropdown
            placeholder="Select Network"
            fluid
            selection
            value={p.selectedMarkupModuleId}
            options={
              p.markupModules?.map((m) => ({
                key: m.id,
                text: m.name,
                //  description: m.description,
                value: m.id,
              })) || []
            }
            onChange={(_, { value }) =>
              p.setMarkupModule(
                p.markupModules.find((m) => m.id === value) ?? null
              )
            }
          />
        )}
        {p.inputs.length > 1 && (
          <Dropdown
            placeholder="Select Input"
            fluid
            selection
            value={p.selectedInputId}
            options={
              p.inputs.map((m) => ({
                key: m.id,
                text: m.name,
                value: m.id,
              })) || []
            }
            onChange={(_, { value }) =>
              p.setInput(p.inputs.find((m) => m.id === value) ?? null)
            }
          />
        )}
      </>
    );
  }
  if (p.markupModules.length === 1 || p.inputs.length === 1) {
    const md = p.markupModules[0] ?? { name: "no-name" };
    return <Input value={md.name} fluid disabled />;
  }

  return null;
}
