import { useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import cuid from "cuid";
import { MarkupLabel, ToolType } from "MarkupTypes";
import { InterActionAction } from "./types";
import useCursor from "./useCursor";
import useMarkuptoolState from "./useMarkuptoolState";
import usetInteractionAction from "./useInteraction";
import {
  getSelectedSegmentation2DTool,
  getAnnotationType,
  setSelectedPolygonId,
  getSelectedPolygonId,
  setSelectedSegmentation2DTool,
  togglePixelDrawMode,
  toggleCursorHidden,
} from "../../../details-researchbox/Markup/inputImage/redux-state";
import { CurrentData, CurrentDataActions } from "./useCurrentData";
import { InteractionHooksType } from "./useInteractionHooks";
import { PixelActions } from "./usePixels";
import { AnnotationType } from "../../../../ts-clients/command";
import useKeyboard from "../../../../hooks/useKeyboard";
import useCircleRadius from "./useCircleRadius";
import uglyStoreGlobalStore from "../../../../state/uglyStore";
import { Annotations2Type } from "./useAnnotations2";
import { HighlightingActions, HighlightedIds } from "./useHighlighing";

type Props = {
  annotationActions: Annotations2Type["annotationActions"];
  annotations: Annotations2Type["annotations"];
  undoActions: Annotations2Type["undoActions"];
  currentDataActions: CurrentDataActions;
  highlightedIds: HighlightedIds;
  highlighingActions: HighlightingActions;
  currentDataChangedByUser: boolean;
  currentData: CurrentData;
  callHook: InteractionHooksType["callHook"];
  pixelActions: PixelActions;
  paintActive: string | null;
  labelList: MarkupLabel[];
};

export default function useInteractions({
  annotationActions,
  annotations,
  currentDataActions,
  currentDataChangedByUser,
  currentData,
  highlighingActions,
  highlightedIds,
  undoActions,
  callHook,
  pixelActions,
  paintActive,
  labelList,
}: Props) {
  const { getCursor } = useCursor();
  const dispatch = useDispatch();

  const selectedAnnotationId = useSelector(getSelectedPolygonId);

  const {
    decreaseMagicWandThreshold,
    increaseMagicWandThreshold,
    increaseSausageSize,
    decreaseSausageSize,
    pixelDrawMode,
  } = useMarkuptoolState();

  const { decreaseCircleRadius, increaseCircleRadius } = useCircleRadius();

  const selectedSegmentation2DTool = useSelector(getSelectedSegmentation2DTool);
  const annotationType = useSelector(getAnnotationType);
  const selectedPolygonID = useSelector(getSelectedPolygonId);

  useEffect(() => {
    currentDataActions.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSegmentation2DTool]);

  const actMakePolygonCircleAtCursor = useCallback(() => {
    const center = getCursor();
    const radius = uglyStoreGlobalStore.circleRadius;

    const topLeft = {
      x: center.x - radius,
      y: center.y - radius,
    };
    const bottomRight = {
      x: center.x + radius,
      y: center.y + radius,
    };
    const circle = {
      ...currentData.circle,
      center,
      topLeft,
      bottomRight,
      annotationType,
    };

    annotationActions.circleAdd(circle);
  }, [annotationActions, annotationType, currentData.circle, getCursor]);

  const selectPolygon = useCallback(
    (p: string | null) => {
      dispatch(setSelectedPolygonId(p));
    },
    [dispatch]
  );
  const deselectPolygon = useCallback(() => {
    selectPolygon(null);
  }, [selectPolygon]);

  const deSelectTool = useCallback(
    () => dispatch(setSelectedSegmentation2DTool("None")),
    [dispatch]
  );

  const actToggleCursorHidden = useCallback(
    () => dispatch(toggleCursorHidden()),
    [dispatch]
  );

  const actSetFirstRectanglePoint = useCallback(() => {
    currentDataActions.applyClick("Rectangle", getCursor());
  }, [currentDataActions, getCursor]);

  const actSetFirstBoundingBoxPoint = useCallback(() => {
    currentDataActions.applyClick("BoundingBox", getCursor());
  }, [currentDataActions, getCursor]);

  const actPolygonAddPoint = useCallback(() => {
    currentDataActions.applyClick("Polygon", getCursor());
  }, [currentDataActions, getCursor]);

  const actSausageAddPoint = useCallback(() => {
    currentDataActions.applyClick("Sausage", getCursor());
  }, [currentDataActions, getCursor]);

  const actCreateRectangleFromCursor = useCallback(() => {
    const bottomRight = getCursor();
    if (
      currentData.reactangle.topLeft.x === bottomRight.x &&
      currentData.reactangle.topLeft.y === bottomRight.y
    ) {
      return;
    }
    annotationActions.rectangleAdd({
      ...currentData.reactangle,
      bottomRight,
      annotationType,
    });
    currentDataActions.reset();
  }, [
    annotationActions,
    annotationType,
    currentData.reactangle,
    currentDataActions,
    getCursor,
  ]);

  const makeProperTopLeftAndBottomRight = (
    pointOne: { x: number; y: number },
    pointTwo: { x: number; y: number }
  ) => {
    let minX;
    let minY;
    let maxX;
    let maxY;

    if (pointOne.x < pointTwo.x) {
      minX = pointOne.x;
      maxX = pointTwo.x;
    } else {
      minX = pointTwo.x;
      maxX = pointOne.x;
    }

    if (pointOne.y < pointTwo.y) {
      minY = pointOne.y;
      maxY = pointTwo.y;
    } else {
      minY = pointTwo.y;
      maxY = pointOne.y;
    }

    const topLeft = { x: minX, y: minY };
    const bottomRight = { x: maxX, y: maxY };

    return [topLeft, bottomRight];
  };

  const actCreateBoundingBoxFromCursor = useCallback(() => {
    const pointOne = currentData.boundingBox.topLeft;
    const pointTwo = getCursor();

    if (
      Math.round(pointOne.x) === Math.round(pointTwo.x) ||
      Math.round(pointOne.y) === Math.round(pointTwo.y)
    ) {
      return;
    }

    const [topLeft, bottomRight] = makeProperTopLeftAndBottomRight(
      pointOne,
      pointTwo
    );

    annotationActions.boundingBoxAdd({
      ...currentData.boundingBox,
      topLeft,
      bottomRight,
      annotationType,
    });
    currentDataActions.reset();
  }, [
    annotationActions,
    annotationType,
    currentData.boundingBox,
    currentDataActions,
    getCursor,
  ]);

  const actApplyCurrentMarkup = useCallback(() => {
    annotationActions.applyMarkup(
      currentData,
      selectedSegmentation2DTool,
      annotationType
    );
    currentDataActions.reset();
  }, [
    annotationActions,
    annotationType,
    currentData,
    currentDataActions,
    selectedSegmentation2DTool,
  ]);

  const actPaintApplyPolygon = useCallback(() => {
    pixelActions.paintApplyPolygon(
      currentDataActions.getAsPolygon(selectedSegmentation2DTool, getCursor()),
      pixelDrawMode
    );
    currentDataActions.reset();
  }, [
    currentDataActions,
    getCursor,
    pixelActions,
    pixelDrawMode,
    selectedSegmentation2DTool,
  ]);

  const actDecreaseCircleRadius = useCallback(
    () => decreaseCircleRadius(),
    [decreaseCircleRadius]
  );
  const actIncreaseCircleRadius = useCallback(
    () => increaseCircleRadius(),
    [increaseCircleRadius]
  );

  const actMagicWandDecreaseThrehold = useCallback(() => {
    currentDataActions.magicWandDecreaseThrehold(); // in the current selection
    decreaseMagicWandThreshold(); // for future selections
  }, [currentDataActions, decreaseMagicWandThreshold]);

  const actMagicWandIncreaseThrehold = useCallback(() => {
    currentDataActions.magicWandIncreaseThrehold(); // in the current selection
    increaseMagicWandThreshold(); // for future selections
  }, [currentDataActions, increaseMagicWandThreshold]);

  const actMagicWandSetCenter = useCallback(() => {
    currentDataActions.applyClick("MagicWand", getCursor());
  }, [currentDataActions, getCursor]);

  const actSausageDecreaseWidth = useCallback(() => {
    currentDataActions.sausageSmaller(); // in the current selection
    decreaseSausageSize(); // for future selections
  }, [currentDataActions, decreaseSausageSize]);

  const actSausageIncreaseWidth = useCallback(() => {
    currentDataActions.sausageBigger(); // in the current selection
    increaseSausageSize(); // for future selections
  }, [currentDataActions, increaseSausageSize]);

  const actZoomIn = useCallback(() => callHook("stageZoomIn"), [callHook]);
  const actZoomOut = useCallback(() => callHook("stageZoomOut"), [callHook]);

  const actClickOnEmpty = useCallback(() => {
    deselectPolygon();
  }, [deselectPolygon]);

  const actSelectedCircleDecreaseRadius = useCallback(() => {
    if (selectedAnnotationId === null) {
      return;
    }
    const c = annotationActions.circleGet(selectedAnnotationId);
    if (c !== null) {
      annotationActions.circleReplace(selectedAnnotationId, {
        ...c,
        radius: c.radius * 0.95,
      });
    }
  }, [annotationActions, selectedAnnotationId]);

  const actSelectedCircleIncreaseRadius = useCallback(() => {
    if (selectedAnnotationId === null) {
      return;
    }
    const c = annotationActions.circleGet(selectedAnnotationId);
    if (c !== null) {
      annotationActions.circleReplace(selectedAnnotationId, {
        ...c,
        radius: c.radius * 1.05,
      });
    }
  }, [annotationActions, selectedAnnotationId]);

  const actSelectedMagicWandIncreaseThreshold = useCallback(() => {
    if (selectedAnnotationId === null) {
      return;
    }
    const c = annotationActions.magicWandGet(selectedAnnotationId);
    if (c !== null) {
      annotationActions.magicWandReplace(selectedAnnotationId, {
        ...c,
        threshold: c.threshold + 1,
      });
    }
  }, [annotationActions, selectedAnnotationId]);

  const actSelectedMagicWandDecreaseThreshold = useCallback(() => {
    if (selectedAnnotationId === null) {
      return;
    }
    const c = annotationActions.magicWandGet(selectedAnnotationId);
    if (c !== null) {
      annotationActions.magicWandReplace(selectedAnnotationId, {
        ...c,
        threshold: c.threshold - 1,
      });
    }
  }, [annotationActions, selectedAnnotationId]);

  const actSelectedSausageIncreaseWidth = useCallback(() => {
    if (selectedAnnotationId === null) {
      return;
    }
    const c = annotationActions.sausageGet(selectedAnnotationId);
    if (c !== null) {
      annotationActions.sausageReplace(selectedAnnotationId, {
        ...c,
        radius: c.radius + 1,
      });
    }
  }, [annotationActions, selectedAnnotationId]);

  const actSelectedSausageDecreaseWidth = useCallback(() => {
    if (selectedAnnotationId === null) {
      return;
    }
    const c = annotationActions.sausageGet(selectedAnnotationId);
    if (c !== null) {
      annotationActions.sausageReplace(selectedAnnotationId, {
        ...c,
        radius: c.radius - 1,
      });
    }
  }, [annotationActions, selectedAnnotationId]);

  const triggerDeleteAnnotation = useCallback(() => {
    if (selectedAnnotationId === null) {
      return;
    }
    annotationActions.annotationDelete(selectedAnnotationId);
    deselectPolygon();
    pixelActions.paintReset();
  }, [annotationActions, deselectPolygon, pixelActions, selectedAnnotationId]);

  const actResetSegmentation2DTool = useCallback(() => {
    if (selectedSegmentation2DTool === "Highlight") {
      highlighingActions.unhighlight();
    }
    if (currentDataChangedByUser) {
      currentDataActions.reset();
    } else {
      deSelectTool();
    }
    pixelActions.paintReset();
  }, [
    currentDataActions,
    currentDataChangedByUser,
    deSelectTool,
    highlighingActions,
    pixelActions,
    selectedSegmentation2DTool,
  ]);

  const actResetCurrentData = useCallback(
    () => currentDataActions.reset(),
    [currentDataActions]
  );

  const actUndo = useCallback(() => {
    if (paintActive !== null) {
      pixelActions.paintReset();
    } else {
      undoActions.undo();
    }
    currentDataActions.reset();
  }, [currentDataActions, paintActive, pixelActions, undoActions]);

  const actRedo = useCallback(() => {
    if (paintActive !== null) {
      pixelActions.paintReset();
    }
    undoActions.redo();
  }, [paintActive, pixelActions, undoActions]);

  const actPaintReset = useCallback(
    () => pixelActions.paintReset(),
    [pixelActions]
  );

  const actPaintFinish = useCallback(() => {
    if (paintActive !== null) {
      const oldPixels = annotationActions.pixelsGet(paintActive);
      const annotationType2 =
        oldPixels?.annotationType ?? AnnotationType.Positive;
      const { fullAnnotation, smallestAnnotation } =
        pixelActions.getPaintImageCropped(
          annotations.markupLabelId,
          annotations.markupLabelColor
        );
      if (fullAnnotation !== null && smallestAnnotation !== null) {
        // @ts-ignore
        annotationActions.pixelsReplace(paintActive, {
          annotationType: annotationType2,
          ...fullAnnotation,
          createdAt: annotationActions.getAnnotationCreated(paintActive) || "",
          id: cuid(),
          markupLabelId: annotations.markupLabelId,
          decodedData: null,
          imageElement: undefined,
          thumbnailAnnotation: {
            ...smallestAnnotation,
            annotationType: AnnotationType.Ignore,
          },
        });
      }
    }
    pixelActions.paintReset();
  }, [
    annotationActions,
    annotations.markupLabelId,
    annotations.markupLabelColor,
    paintActive,
    pixelActions,
  ]);

  const actPaintStart = useCallback(() => {
    if (selectedPolygonID !== null) {
      pixelActions.paintStart(selectedPolygonID);
      deselectPolygon();
    }
  }, [deselectPolygon, pixelActions, selectedPolygonID]);

  const actTogglePixelDrawMode = useCallback(() => {
    if (paintActive !== null) {
      dispatch(togglePixelDrawMode());
    }
  }, [dispatch, paintActive]);

  const actDeselectCurrentAnnotation = useCallback(
    () => deselectPolygon(),
    [deselectPolygon]
  );

  const actStartSelect = useCallback(() => {
    highlighingActions.startHighlight(getCursor());
  }, [getCursor, highlighingActions]);

  const actEndSelect = useCallback(() => {
    highlighingActions.endHighlight(getCursor());
  }, [getCursor, highlighingActions]);

  const actDeleteHighlightedAnnotations = useCallback(() => {
    if (highlightedIds.length > 0) {
      annotationActions.annotationsDelete(highlightedIds);
      highlighingActions.unhighlight();
    }
  }, [annotationActions, highlighingActions, highlightedIds]);

  const actSaveAndNext = useCallback(() => {
    callHook("saveAndNext");
  }, [callHook]);

  const interActions = useMemo(
    () =>
      new Map<InterActionAction, () => void>([
        ["applyCurrentMarkup", actApplyCurrentMarkup],
        ["clickOnEmpty", actClickOnEmpty],
        ["createRectangleFromCursor", actCreateRectangleFromCursor],
        ["decreaseCircleRadius", actDecreaseCircleRadius],
        ["deselectCurrentAnnotation", actDeselectCurrentAnnotation],
        ["increaseCircleRadius", actIncreaseCircleRadius],
        ["magicWandDecreaseThrehold", actMagicWandDecreaseThrehold],
        ["magicWandIncreaseThrehold", actMagicWandIncreaseThrehold],
        ["magicWandSetCenter", actMagicWandSetCenter],
        ["makePolygonCircleAtCursor", actMakePolygonCircleAtCursor],
        ["paintApplyPolygon", actPaintApplyPolygon],
        ["polygonAddPoint", actPolygonAddPoint],
        ["resetCurrentData", actResetCurrentData],
        ["paintStart", actPaintStart],
        ["paintFinish", actPaintFinish],
        ["resetPaint", actPaintReset],
        ["resetSegmentation2DTool", actResetSegmentation2DTool],
        ["sausageAddPoint", actSausageAddPoint],
        ["sausageDecreaseWidth", actSausageDecreaseWidth],
        ["sausageIncreaseWidth", actSausageIncreaseWidth],
        ["togglePixelDrawMode", actTogglePixelDrawMode],
        ["toggleCursorHidden", actToggleCursorHidden],
        ["selectedCircleDecreaseRadius", actSelectedCircleDecreaseRadius],
        ["selectedCircleIncreaseRadius", actSelectedCircleIncreaseRadius],
        ["triggerDeleteAnnotation", triggerDeleteAnnotation],
        [
          "triggerDeleteHighlightedAnnotations",
          actDeleteHighlightedAnnotations,
        ],
        [
          "selectedMagicWandDecreaseThreshold",
          actSelectedMagicWandDecreaseThreshold,
        ],
        [
          "selectedMagicWandIncreaseThreshold",
          actSelectedMagicWandIncreaseThreshold,
        ],
        ["selectedSausageDecreaseWidth", actSelectedSausageDecreaseWidth],
        ["selectedSausageIncreaseWidth", actSelectedSausageIncreaseWidth],
        ["setFirstRectanglePoint", actSetFirstRectanglePoint],
        ["selectedCircleIncreaseRadius", actSelectedCircleIncreaseRadius],
        ["zoom-in", actZoomIn],
        ["zoom-out", actZoomOut],
        ["undo", actUndo],
        ["redo", actRedo],
        ["startSelect", actStartSelect],
        ["endSelect", actEndSelect],
        ["setFirstBoundingBoxPoint", actSetFirstBoundingBoxPoint],
        ["createBoundingBoxFromCursor", actCreateBoundingBoxFromCursor],
        ["saveAndNext", actSaveAndNext],

        // | 'adjustBrushSize'
        // | 'makePaintCircleAtCursor'
      ]),
    [
      actApplyCurrentMarkup,
      actClickOnEmpty,
      actCreateBoundingBoxFromCursor,
      actCreateRectangleFromCursor,
      actDecreaseCircleRadius,
      actDeleteHighlightedAnnotations,
      actDeselectCurrentAnnotation,
      actEndSelect,
      actIncreaseCircleRadius,
      actMagicWandDecreaseThrehold,
      actMagicWandIncreaseThrehold,
      actMagicWandSetCenter,
      actMakePolygonCircleAtCursor,
      actPaintApplyPolygon,
      actPaintFinish,
      actPaintReset,
      actPaintStart,
      actPolygonAddPoint,
      actRedo,
      actResetCurrentData,
      actResetSegmentation2DTool,
      actSausageAddPoint,
      actSausageDecreaseWidth,
      actSausageIncreaseWidth,
      actSaveAndNext,
      actSelectedCircleDecreaseRadius,
      actSelectedCircleIncreaseRadius,
      actSelectedMagicWandDecreaseThreshold,
      actSelectedMagicWandIncreaseThreshold,
      actSelectedSausageDecreaseWidth,
      actSelectedSausageIncreaseWidth,
      actSetFirstBoundingBoxPoint,
      actSetFirstRectanglePoint,
      actStartSelect,
      actToggleCursorHidden,
      actTogglePixelDrawMode,
      actUndo,
      actZoomIn,
      actZoomOut,
      triggerDeleteAnnotation,
    ]
  );

  const { updateState } = usetInteractionAction(interActions);

  const trigger = useCallback(
    (a: InterActionAction) => {
      const action = interActions.get(a);
      if (action) {
        action();
      }
    },
    [interActions]
  );

  const selectNumber = (n: number, ctrlOrShift: boolean) => {
    const selected = ctrlOrShift ? 10 + n : n;
    // select label
    const label = labelList[selected] ?? null;
    if (label !== null) {
      annotationActions.setMarkupLabelIdAndColor(label.id, label.color, false);
      deselectPolygon();
      trigger("resetPaint");
    }
  };

  const selectMarkupTool = (i: ToolType) => {
    deselectPolygon();
    dispatch(setSelectedSegmentation2DTool(i));
  };

  useKeyboard({
    onBackward: () => trigger("undo"),
    onForward: () => trigger("redo"),
    onShortcut: (k, ctrlOrShift) => {
      switch (k) {
        case "c":
          selectMarkupTool("Circle");
          break;
        case "r":
          selectMarkupTool("Rectangle");
          break;
        case "s":
          selectMarkupTool("Sausage");
          break;
        case "b":
          selectMarkupTool("Brush");
          break;
        case "f":
          selectMarkupTool("FreeForm");
          break;
        case "m":
          selectMarkupTool("MagicWand");
          break;
        case "p":
          selectMarkupTool("Polygon");
          break;
        case "o":
          selectMarkupTool("BoundingBox");
          break;
        case "i":
          selectMarkupTool("Highlight");
          break;
        case "v":
          annotationActions.toggleSegmentationsVisible();
          break;
        case "z":
          callHook("stageResetZoom");
          break;
        case "e":
          trigger("paintStart");
          break;
        case "t":
          trigger("togglePixelDrawMode");
          break;
        case "Digit1":
          selectNumber(0, ctrlOrShift);
          break;
        case "Digit2":
          selectNumber(1, ctrlOrShift);
          break;
        case "Digit3":
          selectNumber(2, ctrlOrShift);
          break;
        case "Digit4":
          selectNumber(3, ctrlOrShift);
          break;
        case "Digit5":
          selectNumber(4, ctrlOrShift);
          break;
        case "Digit6":
          selectNumber(5, ctrlOrShift);
          break;
        case "Digit7":
          selectNumber(6, ctrlOrShift);
          break;
        case "Digit8":
          selectNumber(7, ctrlOrShift);
          break;
        case "Digit9":
          selectNumber(8, ctrlOrShift);
          break;
        case "Digit0":
          selectNumber(9, ctrlOrShift);
          break;
        case "u":
          trigger("toggleCursorHidden");
          break;
        case "Enter":
          if (ctrlOrShift) {
            trigger("saveAndNext");
          }
          break;
        default:
          break;
      }
    },
  });

  return { updateState, trigger };
}

export type InteractionsType = ReturnType<typeof useInteractions>;
export type UpdateStateType = InteractionsType["updateState"];
