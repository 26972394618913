import { Menu } from "semantic-ui-react";
import ImageInfo from "./ImageInfo";

export default function MarkupToolRightMenuIndex() {
  return (
    <Menu
      fixed="right"
      vertical
      style={{
        padding: "15px",
        marginTop: 40,
        backgroundColor: "#f4f4f4",
        width: "18rem",
      }}
    >
      <ImageInfo />
    </Menu>
  );
}
