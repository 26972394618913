import { ReactNode } from "react";
import { Message, Loader, Grid } from "semantic-ui-react";

type MessageType = "noDataType" | "loading" | "noOutput";

type Props = {
  selector: ReactNode;
  messageType: MessageType;
};

const messages = new Map<MessageType, string>([
  ["loading", "Loading..."],
  ["noDataType", "No datatype selected."],
  ["noOutput", "No output. Please create a network first."],
]);

export default function InputNone({ selector, messageType }: Props) {
  return (
    <>
      {selector}
      <Grid centered padded>
        <Grid.Row>
          <Message compact info>
            <Message.Content>
              <Loader active inline />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{messages.get(messageType)}
            </Message.Content>
          </Message>
        </Grid.Row>
      </Grid>
    </>
  );
}
