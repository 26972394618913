/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
import { useMemo, useEffect, useState } from "react";
import { Card, Checkbox, Icon, Table } from "semantic-ui-react";
import { Route, Switch, useLocation } from "react-router";
import { without } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "./DeleteModal";
import { ResearchBoxListParams, useGoto } from "../../utils/routeManager";
import useResearchBoxList from "./hooks/useResearchBoxList";
import ListMenu from "./ListMenu";
import useQuery from "../../hooks/useQuery";
import {
  FolderType,
  ResearchBoxOrFolderType,
  ResearchBoxType,
} from "../../types/extractedTypes";
import { useCopyFolderMutation } from "../../ts-clients/command";
import {
  getOrder,
  getViewType,
  reset,
  setOrder,
} from "../../state/researchboxfilter";
import {
  FolderOrResearchBoxOrder,
  FolderOrResearchBoxOrderable,
  useQueryNetworksForDatasetQuery,
} from "../../ts-clients/query";
import { useSmartPageSelector } from "./hooks";
import ListRow from "./ListRow";
import ObjectCard from "./ObjectCard";
import CreateNetworkModal from "./CreateNetworkModal";
import { getIsActive, WelcomeTour } from "../../state/tourState";
import JoyRide from "react-joyride";
import { useUIType } from "../../hooksqueries";
import useQueryFilteredResearchBoxIdsByDatasetIdAndFolderId from "../../hooksqueries/useQueryFilteredResearchBoxIdsByDatasetIdAndFolderId";
import useMarkuptoolList from "../../state/useMarkuptoolList";
export type Props = {
  params: ResearchBoxListParams;
};

type SelectionCandidate = ResearchBoxType | FolderType | null;

export default function ResearchBoxList({ params }: Props) {
  const { datasetId } = params;

  const query = useQuery();
  const dispatch = useDispatch();

  const path = query.get("path");
  const folderIds = useMemo(() => path?.split("-") || [], [path]);
  const folderId =
    folderIds.length > 0 ? folderIds[folderIds.length - 1] : undefined;

  const parentIds = path ? [datasetId, path].join("-") : datasetId;

  const uiType = useUIType();

  // reset filterstate
  useEffect(() => {
    if (uiType === "halm" && folderIds.length === 0) {
      dispatch(reset());
    }
  }, [uiType, folderIds, dispatch]);

  const {
    loading,
    objects,
    folderCount,
    startPolling,
    stopPolling,
    setDeleteCandidates,
    deleteCandidates,
    handleDelete,
    refetch,
    firstIdx,
  } = useResearchBoxList(datasetId);

  const queryIdsForMarkupTool =
    useQueryFilteredResearchBoxIdsByDatasetIdAndFolderId();

  // make first query immediately
  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    startPolling(2000);
    return stopPolling;
  }, [startPolling, stopPolling]);

  const location = useLocation();

  const currentOrder = useSelector(getOrder);

  const [copyFolder] = useCopyFolderMutation();

  const [selectedObjects, setSelectedObjects] = useState<SelectionCandidate[]>(
    []
  );

  const viewType = useSelector(getViewType);

  const { data: networkData } = useQueryNetworksForDatasetQuery({
    variables: {
      datasetId,
    },
  });

  const networks = networkData?.getDataset?.pipeline?.modules?.flatMap((m) =>
    m.__typename === "PipelineModuleNetworkImageClassification" ||
    m.__typename === "PipelineModuleNetworkImageInstanceSegmentation" ||
    m.__typename === "PipelineModuleNetworkImageObjectDetection" ||
    m.__typename === "PipelineModuleNetworkImageSegmentation"
      ? m
      : []
  );

  const getSubPageFor = useSmartPageSelector();

  const [gotoNetworkModalVisible, setGotoNetworkModalVisible] = useState(false);

  const [currentListIndex, setCurrentListIndex] = useState(0);

  const { setMarkupToolList } = useMarkuptoolList();

  const goTo = useGoto();

  const openMarkupTool = async (
    listIndex: number,
    researchBox: ResearchBoxType | null
  ) => {
    if (!researchBox) {
      return;
    }

    const ids = await queryIdsForMarkupTool(parentIds);
    setMarkupToolList(ids);

    if (networks?.length === 0) {
      setCurrentListIndex(listIndex);
      setGotoNetworkModalVisible(true);
    } else {
      goTo(
        "ResearchBoxDetails",
        {
          datasetId,
          listIndex,
          context: "researchboxlist",
          page: getSubPageFor(researchBox),
        },
        path ? `?path=${path}` : undefined
      );
    }
  };

  const copy = async (o: ResearchBoxOrFolderType | null) => {
    if (!folderId) {
      if (o?.__typename === "Folder") {
        copyFolder({
          variables: {
            input: {
              folderIds: [o?.id || ""],
              target: {
                datasetId,
              },
            },
          },
        });
      }
    } else if (o?.__typename === "Folder") {
      copyFolder({
        variables: {
          input: {
            folderIds: [o?.id || ""],
            target: {
              folderId,
            },
          },
        },
      });
    }
  };

  const isActive = useSelector(getIsActive);

  return (
    <>
      <JoyRide steps={WelcomeTour.fileList} run={isActive} continuous />
      <CreateNetworkModal
        datasetId={datasetId}
        visible={gotoNetworkModalVisible}
        setVisible={setGotoNetworkModalVisible}
        listIndex={currentListIndex}
      />
      <ListMenu
        datasetId={datasetId}
        folderIds={folderIds}
        objectsSelected={selectedObjects.length > 0}
        handleDelete={() => {
          setDeleteCandidates(selectedObjects);
        }}
      />{" "}
      <div welcome-tour-file-list="4">
        {viewType === "icon-view" && (
          <Card.Group
            onClick={() => {
              setSelectedObjects([]);
            }}
          >
            {objects?.map((o, idx) => (
              <ObjectCard
                key={o?.id}
                object={o}
                loading={loading}
                onRename={refetch}
                triggerCopy={() => copy(o)}
                triggerDelete={() => setDeleteCandidates([o])}
                selected={
                  selectedObjects.findIndex((o_) => o_?.id === o?.id) !== -1
                }
                onSelect={(value) => {
                  if (value) {
                    setSelectedObjects([...selectedObjects, o]);
                  } else {
                    setSelectedObjects(without(selectedObjects, o));
                  }
                }}
                openMarkupTool={
                  o?.__typename === "ResearchBox"
                    ? () => openMarkupTool(firstIdx + idx - folderCount, o)
                    : undefined
                }
              />
            ))}
          </Card.Group>
        )}
        {viewType === "list-view" && (
          <Table sortable celled compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>
                  <Checkbox
                    onClick={(_, data) => {
                      if (data.checked) {
                        setSelectedObjects(objects);
                      } else {
                        setSelectedObjects([]);
                      }
                    }}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    currentOrder.asc ===
                    FolderOrResearchBoxOrderable.TypeForSorting
                      ? "ascending"
                      : currentOrder.desc ===
                        FolderOrResearchBoxOrderable.TypeForSorting
                      ? "descending"
                      : undefined
                  }
                  onClick={() => {
                    if (
                      currentOrder.asc ===
                      FolderOrResearchBoxOrderable.TypeForSorting
                    ) {
                      dispatch(
                        setOrder({
                          desc: FolderOrResearchBoxOrderable.TypeForSorting,
                        } as FolderOrResearchBoxOrder)
                      );
                    } else {
                      dispatch(
                        setOrder({
                          asc: FolderOrResearchBoxOrderable.TypeForSorting,
                        } as FolderOrResearchBoxOrder)
                      );
                    }
                  }}
                >
                  <Icon name="folder" />{" "}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    currentOrder.asc === FolderOrResearchBoxOrderable.Name
                      ? "ascending"
                      : currentOrder.desc === FolderOrResearchBoxOrderable.Name
                      ? "descending"
                      : undefined
                  }
                  onClick={() => {
                    if (
                      currentOrder.asc === FolderOrResearchBoxOrderable.Name
                    ) {
                      dispatch(
                        setOrder({
                          desc: FolderOrResearchBoxOrderable.Name,
                        } as FolderOrResearchBoxOrder)
                      );
                    } else {
                      dispatch(
                        setOrder({
                          asc: FolderOrResearchBoxOrderable.Name,
                        } as FolderOrResearchBoxOrder)
                      );
                    }
                  }}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    currentOrder.asc === FolderOrResearchBoxOrderable.CreatedAt
                      ? "ascending"
                      : currentOrder.desc ===
                        FolderOrResearchBoxOrderable.CreatedAt
                      ? "descending"
                      : undefined
                  }
                  onClick={() => {
                    if (
                      currentOrder.asc ===
                      FolderOrResearchBoxOrderable.CreatedAt
                    ) {
                      dispatch(
                        setOrder({
                          desc: FolderOrResearchBoxOrderable.CreatedAt,
                        } as FolderOrResearchBoxOrder)
                      );
                    } else {
                      dispatch(
                        setOrder({
                          asc: FolderOrResearchBoxOrderable.CreatedAt,
                        } as FolderOrResearchBoxOrder)
                      );
                    }
                  }}
                >
                  Created
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {objects.map((o, idx) => (
                <ListRow
                  key={o?.id}
                  object={o}
                  selected={
                    selectedObjects.findIndex((o_) => o_?.id === o?.id) !== -1
                  }
                  onSelect={(value) => {
                    if (value) {
                      setSelectedObjects([...selectedObjects, o]);
                    } else {
                      setSelectedObjects(without(selectedObjects, o));
                    }
                  }}
                  openMarkupTool={
                    o?.__typename === "ResearchBox"
                      ? () => openMarkupTool(firstIdx + idx - folderCount, o)
                      : undefined
                  }
                  onRename={refetch}
                />
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
      <DeleteModal
        open={deleteCandidates.length > 0}
        deleteCandidates={deleteCandidates}
        close={() => setDeleteCandidates([])}
        handleDelete={handleDelete}
      />
      <Switch>
        <Route
          path={`${location.pathname}/:folderId`}
          component={ResearchBoxList}
        />
      </Switch>
    </>
  );
}
