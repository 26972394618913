import { useGetNetworkModuleClassLabelsQuery } from "../../../../ts-clients/query";

export default function useNetworkModuleClassLabels(networkModuleId: string) {
  const { data } = useGetNetworkModuleClassLabelsQuery({
    variables: {
      id: networkModuleId,
    },
  });

  return [...(data?.queryNetworkModule || [])].pop()?.classLabels || [];
}
