import {
  useChangePasswordMutation,
  useUpdateUserMutation,
} from "../../ts-clients/command";
import { useGetUserByUsernameQuery } from "../../ts-clients/query";

export function useChangePassword(username: string, newPassword: string) {
  const [m, { data }] = useChangePasswordMutation({
    variables: {
      input: {
        newPassword,
        username,
      },
    },
  });

  const save = () => {
    m();
  };

  return { save, success: data?.changePassword?.success ?? false };
}

export type UserProfile = {
  email: string;
  name: string;
  phone: string;
  surname: string;
};

export function useSetUserProfile(username: string, p: UserProfile) {
  const [m, { data }] = useUpdateUserMutation({
    variables: {
      input: {
        ...p,
        filter: {
          username,
        },
      },
    },
  });

  return { save: m, success: data?.updateUser?.success ?? false };
}

export function useGetUserProfile(username: string): UserProfile {
  const { data } = useGetUserByUsernameQuery({
    variables: {
      username,
    },
    fetchPolicy: "cache-and-network",
  });
  return {
    email: data?.getUser?.email ?? "",
    name: data?.getUser?.name ?? "",
    surname: data?.getUser?.surname ?? "",
    phone: data?.getUser?.phone ?? "",
  };
}
