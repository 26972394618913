import { range } from "ramda";
import hull from "hull.js";
import {
  AddPoint2DInput,
  AnnotationType,
} from "../../../../ts-clients/command";
import { magicWandToPolygon } from "./magicWand";
import { makeSausage } from "./pointFunctions";
import uglyStore from "../../../../state/uglyStore";
import {
  LeanCircle,
  LeanMagicWand,
  LeanPolygon,
  LeanRectangle,
  LeanSausage,
  MyAnnotations,
} from "./types";

const circleToPoly = (c: LeanCircle): AddPoint2DInput[] => {
  let stepcount = Math.floor(c.radius / 1.5);
  if (stepcount < 16) {
    stepcount = 16;
  }
  const stepsize = 360 / stepcount;
  return range(0, stepcount).map((i) => ({
    x: c.center.x + Math.sin((i * stepsize * Math.PI) / 180) * c.radius,
    y: c.center.y + Math.cos((i * stepsize * Math.PI) / 180) * c.radius,
  }));
};

const magicWandToPoly = (c: LeanMagicWand): AddPoint2DInput[] =>
  magicWandToPolygon(
    c.center,
    c.threshold,
    uglyStore.imageData,
    uglyStore.sourceImageSize
  );

const sausageToPoly = (c: LeanSausage): AddPoint2DInput[] =>
  makeSausage(c.points, c.radius, true).concat(
    makeSausage(c.points, c.radius, false).reverse()
  );

const rectangleToPoly = (c: LeanRectangle): AddPoint2DInput[] => [
  c.topLeft,
  { x: c.topLeft.x, y: c.bottomRight.y },
  c.bottomRight,
  { x: c.bottomRight.x, y: c.topLeft.y },
];

const freeformToPoly = (c: LeanPolygon): AddPoint2DInput[] => {
  if (c.points.length < 4) {
    return [];
  }
  const result: AddPoint2DInput[] = hull(
    c.points.map((point) => [point.x, point.y]),
    Infinity
  ).map((p: number[]) => ({ x: p[0], y: p[1] }));
  // last point is always same as first point; remove this
  result.splice(result.length - 1, 1);
  return result;
};

// const replaceType => (d: MyAnnotations, annotationId: string, t: AnnotationType) => {
//   switch(a._t) {
//     case "Sausage": return sausageReplace(d, annotationId, d.sausageAnnotations.find(s => s.id === annotationId)?.annotationType)
//   }
// }

// const getAnnotationById = (d: MyAnnotations, annotationId:string): AnyAnnotation {
// if (d.sausageAnnotations.find(c => c.id === annotationId))
// }

const differsTo = (id2: string) => (t: { id: string }) => t.id !== id2;

const deleteAnnotation = (
  d: MyAnnotations,
  myAnnotationId: string,
  butKeepPixelsEvenIfIDMatches: boolean
): MyAnnotations => ({
  ...d,
  circleAnnotations: d.circleAnnotations.filter(differsTo(myAnnotationId)),
  magicwandAnnotations: d.magicwandAnnotations.filter(
    differsTo(myAnnotationId)
  ),
  pixelAnnotations: butKeepPixelsEvenIfIDMatches
    ? d.pixelAnnotations
    : d.pixelAnnotations.filter(differsTo(myAnnotationId)),
  polygonAnnotations: d.polygonAnnotations.filter(differsTo(myAnnotationId)),
  rectangleAnnotations: d.rectangleAnnotations.filter(
    differsTo(myAnnotationId)
  ),
  sausageAnnotations: d.sausageAnnotations.filter(differsTo(myAnnotationId)),
  penAnnotations: d.penAnnotations.filter(differsTo(myAnnotationId)),
});

const replaceAnnotationType = (
  d: MyAnnotations,
  annotationType: AnnotationType,
  myAnnotationId: string
): MyAnnotations => ({
  ...d,
  circleAnnotations: d.circleAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
  magicwandAnnotations: d.magicwandAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
  pixelAnnotations: d.pixelAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
  polygonAnnotations: d.polygonAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
  rectangleAnnotations: d.rectangleAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
  sausageAnnotations: d.sausageAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
  penAnnotations: d.penAnnotations.map((i) =>
    i.id !== myAnnotationId ? i : { ...i, annotationType }
  ),
});

export {
  circleToPoly,
  magicWandToPoly,
  rectangleToPoly,
  sausageToPoly,
  freeformToPoly,
  deleteAnnotation,
  replaceAnnotationType,
};
