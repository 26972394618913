import { Icon, Button } from "semantic-ui-react";
import { DenkjobState } from "../../../../../../ts-clients/query";
import { useContext } from "react";
import { DenkboxContext } from "../../../../../../ctx/DenkboxProvider";

type Props = {
  fluid: boolean;
};

export default function ControlTraining({ fluid }: Props) {
  const denkboxState = useContext(DenkboxContext);

  if (!denkboxState) {
    return <></>;
  }

  const trainingRunning =
    (!!denkboxState.networkDetails.networkState.training &&
      (denkboxState.networkDetails.networkState.training ===
        DenkjobState.Running ||
        denkboxState.networkDetails.networkState.training ===
          DenkjobState.Starting)) ||
    (!!denkboxState.networkDetails.networkState.testing &&
      (denkboxState.networkDetails.networkState.testing ===
        DenkjobState.Running ||
        denkboxState.networkDetails.networkState.testing ===
          DenkjobState.Starting));

  const trainingStopping =
    denkboxState.networkDetails.networkState.training !== null &&
    denkboxState.networkDetails.networkState.training === DenkjobState.Stopping;

  return (
    <Button.Group fluid={fluid}>
      <Button
        color="green"
        disabled={!denkboxState.actionsAvailable.startTraining}
        loading={trainingRunning}
        onClick={denkboxState.actions.startTraining}
      >
        <Icon name="play" />
        Start
      </Button>
      <Button
        color="red"
        disabled={!denkboxState.actionsAvailable.stopTraining}
        loading={trainingStopping}
        onClick={denkboxState.actions.stopTraining}
      >
        <Icon name="stop" />
        Stop
      </Button>
    </Button.Group>
  );
}

ControlTraining.defaultProps = {
  fluid: false,
};
