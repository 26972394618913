import {
  useUpdatePipelineModulePositionMutation,
  PipelineModulePosition,
} from "../ts-clients/command";

type Props = {
  pipelineModuleId: string;
  layoutPosition: PipelineModulePosition;
};

export default function useUpdatePipelineModulePositition() {
  const [m] = useUpdatePipelineModulePositionMutation();

  return (p: Props) => {
    m({
      variables: {
        input: {
          filter: {
            moduleId: p.pipelineModuleId,
          },
          position: p.layoutPosition,
        },
      },
    });
  };
}
