import { useDeleteDatasetMutation } from "../../../../ts-clients/command";

export default function useDeleteDataset(datasetId: string) {
  const [deleteDataset] = useDeleteDatasetMutation();
  return () => {
    deleteDataset({
      variables: {
        input: { ids: [datasetId] },
      },
    });
  };
}
