import { useQueryHasImagePredictionQuery } from "../../../../ts-clients/query";

export default function useHasPredictionCheck(
  networkId: string,
  researchBoxId: string
) {
  const { data } = useQueryHasImagePredictionQuery({
    variables: {
      networkId,
      researchBoxId,
    },
    pollInterval: 3000,
    fetchPolicy: "network-only",
  });

  const datapoints = (data?.getResearchBox?.datapoints ?? []).flatMap((d) =>
    d.__typename === "Image" ? d : []
  );
  const firstDatapoint = datapoints[0] ?? undefined;
  const predictions =
    firstDatapoint?.predictions?.flatMap((p) =>
      p.__typename === "ClassificationPrediction" ||
      p.__typename === "ObjectDetectionPrediction" ||
      p.__typename === "SegmentationPrediction"
        ? p.createdAt
        : []
    ) ?? [];
  const predictionCreated = predictions[0] ?? null;

  return predictionCreated;
}
