import { Divider, Header } from "semantic-ui-react";
import ImageMarkup from "./inputImage";
import NoMarkup from "./inputNone";
import TopMenu from "../TopMenu";
import { NavigationProps } from "../utils";
import { ResearchBoxDetailsParams } from "../../../utils/routeManager";
import SourceSelector from "./SourceSelector";
import {
  ImageDatapointType,
  ResearchBoxType,
} from "../../../types/extractedTypes";
import useSourceSelector from "./hooks/useSourceSelector";
import useInputDatapoint from "./hooks/useInputDatapoint";
// import { getIsActive, WelcomeTour } from "../../../state/tourState";
// import JoyRide from "react-joyride";
import { usePipelineId } from "../../../hooks/usePipelineId";
import RightMenu from "./RightMenu";
import { useDeleteResearchBoxMutation } from "../../../ts-clients/command";
import { ResearchBoxContext } from "../../../ctx/ResearchBoxProvider";
import { useContext, useEffect } from "react";
import useMarkuptoolList from "../../../state/useMarkuptoolList";

type Props = {
  pageParams: ResearchBoxDetailsParams;
  researchbox: ResearchBoxType | null | undefined;
  navigation: NavigationProps;
};

function whatToShow(hasInput: boolean, hasOuput: boolean, loading: boolean) {
  if (loading) {
    return "loading";
  }
  if (hasInput && hasOuput) {
    return "markupTool";
  }
  if (!hasInput && !hasOuput) {
    return "loading";
  }
  if (!hasInput) {
    return "noInput";
  }
  if (!hasOuput) {
    return "noOutput";
  }
  return "unknown";
}

export default function MarkupIndex({
  navigation,
  researchbox,
  pageParams,
}: Props) {
  const pipelineId = usePipelineId(pageParams.datasetId);

  const { source, forSelectorDropdown, loading } =
    useSourceSelector(pipelineId);
  const inputDatapoint = useInputDatapoint(
    source.markupModule,
    researchbox,
    source.inputDatapointNames
  );

  const selector = <SourceSelector p={forSelectorDropdown} />;

  const outputDataType = source.markupModule?.typename;

  const { refetch } = useContext(ResearchBoxContext);

  const { setMarkupToolList, markuptoolList, currentListIndex } =
    useMarkuptoolList();

  const [deleteResearchBoxAction] = useDeleteResearchBoxMutation({
    variables: {
      input: {
        ids: [researchbox?.id ?? ""],
      },
    },
    onCompleted: () => {
      refetch();
      setMarkupToolList(markuptoolList.filter((id) => id !== researchbox?.id));
    },
  });

  const markuptoolListLength = markuptoolList.length;

  useEffect(() => {
    if (researchbox && currentListIndex >= markuptoolListLength) {
      navigation.goPage("back");
    }
  }, [navigation, currentListIndex, markuptoolListLength, researchbox]);

  const deleteResearchbox = () => deleteResearchBoxAction();

  const hasInput = (inputDatapoint ?? null) !== null;
  const hasOuput = outputDataType !== undefined;
  const show = whatToShow(hasInput, hasOuput, loading);

  // const isActive = useSelector(getIsActive);

  if (show === "loading" || show === "noInput" || !inputDatapoint) {
    return <NoMarkup selector={selector} messageType="loading" />;
  }

  if (show === "noOutput") {
    return <NoMarkup selector={selector} messageType="noOutput" />;
  }

  return (
    <>
      {/* <JoyRide steps={WelcomeTour.markuptool} run={isActive} continuous /> */}
      <Header as="h1">
        Annotation-Tool
        <TopMenu current="markup" navigation={navigation} />
      </Header>
      <Divider />
      <ImageMarkup
        image={inputDatapoint}
        selector={selector}
        source={source}
        navigation={navigation}
        outputDataType={outputDataType}
        deleteResearchbox={deleteResearchbox}
      />
      <RightMenu
        image={inputDatapoint as ImageDatapointType}
        currentNetworkId={source.markupModule?.id ?? ""}
        datasetId={pageParams.datasetId}
        listIndex={pageParams.listIndex}
      />
    </>
  );
}
