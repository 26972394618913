import { DataType } from "../../ts-clients/query";
import useUIConfig from "../../state/useUIConfig";
import { ResearchBoxType } from "../../types/extractedTypes";

export function useSmartPageSelector() {
  const { getResearchBoxViewSelect } = useUIConfig();

  return (r: ResearchBoxType) => {
    if (getResearchBoxViewSelect() === "selectFirst") {
      return "overview";
    }

    const imageCount = (
      r.datapoints?.filter((d) => d.producedBy.dataType === DataType.Image) ??
      []
    ).length;

    // if the datapoint has at least one image, show the markup tool as default
    if (imageCount > 0) {
      return "markup";
    }
    return "overview";
  };
}
