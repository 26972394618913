import { useState } from "react";
import {
  Container,
  Card,
  Image,
  Input,
  Grid,
  Icon,
  Message,
  Button,
} from "semantic-ui-react";
import HighlightSubstring from "../../util/HighlightSubstring";
import {
  PretrainedNetwork,
  PretrainedNetworkType,
} from "../../../../types/extractedTypes";

type Props = {
  onSelectModule: (
    networkModuleId: string,
    type: PretrainedNetworkType,
    name: string
  ) => void;
  getPretrainedNetworks: (filterString: string) => PretrainedNetwork[];
};

const stringToType = new Map<string, PretrainedNetworkType>([
  [
    "PipelineModuleNetworkImageClassification",
    "PipelineModuleNetworkImageClassification",
  ],
  [
    "PipelineModuleNetworkImageSegmentation",
    "PipelineModuleNetworkImageSegmentation",
  ],
]);

const shortType = new Map<string, string>([
  ["PipelineModuleNetworkImageClassification", "Classification"],
  ["PipelineModuleNetworkImageSegmentation", "Segmentation"],
]);

export const stringIncludeFilter = (
  stringToSearch: string,
  stringToFind: string
): boolean => {
  return stringToSearch.toLowerCase().includes(stringToFind.toLowerCase());
};

export default function PretrainedSelection({
  onSelectModule,
  getPretrainedNetworks,
}: Props) {
  const [filterString, setFilterString] = useState<string>("");
  const pretrainedNetworks = getPretrainedNetworks(filterString);

  return (
    <Container style={{ marginTop: "15px" }}>
      <Grid centered padded>
        <Grid.Row>
          <Input
            value={filterString}
            placeholder="Search ..."
            icon={{ name: "search" }}
            iconPosition="left"
            onChange={(_, v) => {
              setFilterString(v.value);
            }}
            action={
              <Button basic icon="delete" onClick={() => setFilterString("")} />
            }
          />
        </Grid.Row>
      </Grid>
      &nbsp;
      {pretrainedNetworks.length > 0 ? (
        <Card.Group itemsPerRow={5} centered>
          {pretrainedNetworks?.map((network) => {
            return (
              <Card
                link
                key={network?.id}
                onClick={() =>
                  onSelectModule(
                    network?.networkModule.id || "",
                    stringToType.get(network?.networkModule.__typename || "") ||
                      "PipelineModuleNetworkImageClassification",
                    network?.networkModule.name || ""
                  )
                }
              >
                <Image src={network?.thumbnail || ""} wrapped ui={false} />
                <Card.Content>
                  <Card.Header>
                    <HighlightSubstring
                      haystack={network?.networkModule.name || ""}
                      needle={filterString}
                    />
                  </Card.Header>
                  <Card.Meta>
                    <HighlightSubstring
                      haystack={
                        shortType.get(
                          network?.networkModule.__typename || ""
                        ) || ""
                      }
                      needle={filterString}
                    />
                  </Card.Meta>
                  <Card.Description>
                    <HighlightSubstring
                      haystack={network?.networkModule.description || ""}
                      needle={filterString}
                    />
                  </Card.Description>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
      ) : (
        <Grid centered padded>
          <Grid.Row>
            <Message info>
              <Icon disabled name="delete" size="big" />
              No networks found.
            </Message>
          </Grid.Row>
        </Grid>
      )}
    </Container>
  );
}
