import { useMemo } from "react";
import {
  InterActionAction,
  LeanInteractionState,
  MarkupToolInteraction,
} from "./types";

const getClickAction = (s: LeanInteractionState): InterActionAction | null => {
  if (s.mouseMode === "ctrl" || !s.mouseOnCanvas) {
    return null;
  }

  if (s.selectedSegmentation2DTool === "Circle") {
    if (s.paintActive) {
      return "paintApplyPolygon";
    }
    return "makePolygonCircleAtCursor";
  }

  if (s.selectedSegmentation2DTool === "Rectangle") {
    if (s.currentDataChangedByUser) {
      if (s.paintActive) {
        return "paintApplyPolygon";
      }
      return "createRectangleFromCursor";
    }
    if (s.oneClickShapes) {
      return null;
    }
    return "setFirstRectanglePoint";
  }
  if (s.selectedSegmentation2DTool === "BoundingBox") {
    if (s.currentDataChangedByUser) {
      if (s.paintActive) {
        return "paintApplyPolygon";
      }
      return "createBoundingBoxFromCursor";
    }
    if (s.oneClickShapes) {
      return null;
    }
    return "setFirstBoundingBoxPoint";
  }

  if (s.selectedSegmentation2DTool === "FreeForm") {
    if (s.oneClickShapes) {
      if (s.currentDataChangedByUser) {
        if (s.paintActive) {
          return "paintApplyPolygon";
        }
        return "applyCurrentMarkup";
      }
      return null;
    }
    return null;
  }

  if (s.selectedSegmentation2DTool === "MagicWand") {
    return "magicWandSetCenter";
  }

  if (s.selectedSegmentation2DTool === "Polygon") {
    return "polygonAddPoint";
  }

  if (s.selectedSegmentation2DTool === "Sausage") {
    return "sausageAddPoint";
  }

  return "clickOnEmpty";
};

const getEnterAction = (s: LeanInteractionState): InterActionAction | null => {
  if (s.mouseMode === "ctrl") {
    return null;
  }

  if (s.paintActive) {
    if (s.selectedSegmentation2DTool === "Circle") {
      return "makePaintCircleAtCursor";
    }
    if (s.selectedSegmentation2DTool === "None") {
      return "paintFinish";
    }
    return "paintApplyPolygon";
  }
  if (s.selectedSegmentation2DTool === "Circle") {
    return "makePolygonCircleAtCursor";
  }
  if (s.currentDataChangedByUser) {
    return "applyCurrentMarkup";
  }
  return null;
};

const getMouseDownAction = (
  s: LeanInteractionState
): InterActionAction | null => {
  if (s.mouseMode === "ctrl" || !s.mouseOnCanvas) {
    return null;
  }

  if (s.selectedSegmentation2DTool === "Highlight") {
    return "startSelect";
  }

  if (s.oneClickShapes) {
    if (s.selectedSegmentation2DTool === "Rectangle") {
      return "setFirstRectanglePoint";
    }
    if (s.selectedSegmentation2DTool === "BoundingBox") {
      return "setFirstBoundingBoxPoint";
    }
    return null;
  }
  return null;
};

const getMouseUpAction = (
  s: LeanInteractionState
): InterActionAction | null => {
  if (s.mouseMode === "ctrl" || !s.mouseOnCanvas) {
    return null;
  }

  if (s.selectedSegmentation2DTool === "Highlight") {
    return "endSelect";
  }
  if (s.oneClickShapes) {
    if (s.selectedSegmentation2DTool === "Rectangle") {
      if (s.paintActive) {
        return "paintApplyPolygon";
      }
      return "createRectangleFromCursor";
    }
    if (s.selectedSegmentation2DTool === "BoundingBox") {
      if (s.paintActive) {
        return "paintApplyPolygon";
      }
      return "createBoundingBoxFromCursor";
    }
    return null;
  }
  return null;
};

const getWheelAction = (s: LeanInteractionState): InterActionAction | null => {
  if (s.selectedSegmentation2DTool === "None" || s.mouseMode === "ctrl") {
    if (s.selectedAnnotationId !== null) {
      if (s.selectedAnnotationFlavour === "Sausage") {
        if (s.scrollDirection === "up") {
          return "selectedSausageIncreaseWidth";
        }
        return "selectedSausageDecreaseWidth";
      }
      if (s.selectedAnnotationFlavour === "Circle") {
        if (s.scrollDirection === "up") {
          return "selectedCircleIncreaseRadius";
        }
        return "selectedCircleDecreaseRadius";
      }
      if (s.selectedAnnotationFlavour === "MagicWand") {
        if (s.scrollDirection === "up") {
          return "selectedMagicWandIncreaseThreshold";
        }
        return "selectedMagicWandDecreaseThreshold";
      }

      if (s.scrollDirection === "up") {
        return "zoom-in";
      }
      return "zoom-out";
    }
    if (s.scrollDirection === "up") {
      return "zoom-in";
    }
    return "zoom-out";
  }
  if (s.selectedSegmentation2DTool === "MagicWand") {
    if (s.scrollDirection === "up") {
      return "magicWandIncreaseThrehold";
    }
    return "magicWandDecreaseThrehold";
  }
  if (s.selectedSegmentation2DTool === "Sausage") {
    if (s.scrollDirection === "up") {
      return "sausageIncreaseWidth";
    }
    return "sausageDecreaseWidth";
  }
  if (s.scrollDirection === "up") {
    return "increaseCircleRadius";
  }
  return "decreaseCircleRadius";
};

const getDeleteAction = (s: LeanInteractionState): InterActionAction | null => {
  if (s.selectedSegmentation2DTool === "Highlight") {
    return "triggerDeleteHighlightedAnnotations";
  }
  if (s.selectedAnnotationId !== null) {
    return "triggerDeleteAnnotation";
  }
  return null;
};

const getEscapeAction = (s: LeanInteractionState): InterActionAction | null => {
  if (s.selectedSegmentation2DTool !== "None") {
    return "resetSegmentation2DTool";
  }
  if (s.selectedAnnotationId !== null) {
    return "deselectCurrentAnnotation";
  }

  if (s.currentDataChangedByUser) {
    return "resetCurrentData";
  }

  if (s.paintActive) {
    return "resetPaint";
  }
  return null;
};

const getAction = (
  a: MarkupToolInteraction,
  s: LeanInteractionState
): InterActionAction | null => {
  switch (a) {
    case "click":
      return getClickAction(s);
    case "enter":
      return getEnterAction(s);
    case "mouseDown":
      return getMouseDownAction(s);
    case "mouseUp":
      return getMouseUpAction(s);
    case "wheel":
      return getWheelAction(s);
    case "delete":
      return getDeleteAction(s);
    case "escape":
      return getEscapeAction(s);
    default:
      return null;
  }
};

type ActionsMap = Map<InterActionAction, () => void>;

export default function useInteractionAction(m: ActionsMap) {
  // const [pendingActionName, setPendingAction] =
  //   useState<InterActionAction | null>(null);
  const updateState = useMemo(
    () => (a: MarkupToolInteraction, s: LeanInteractionState) => {
      // setPendingAction(getAction(a, s));

      const pendingActionTemp = getAction(a, s);
      if (pendingActionTemp) {
        const action = m?.get(pendingActionTemp);
        if (action) {
          action();
        }
      }
    },
    [m]
  );

  return { updateState };
}
